import httpManager from "@/app/shared/utils/httpManager";
/**
 * Eliminar una troncal mediante su id.
 *
 * @param {Number} id Datos de la troncal.
 *
 * @return {Promise<Object>} troncal por id
 */
export default async function (id) {
  const response = await httpManager.delete(`/pbx/trunks/${id}/`);
  return response?.data;
}
