import { fetchTemplateByPortfolio } from "@/app/imports/services";
import { fetchStrategyCalcutations, fetchStrategyEmailClients } from "@/app/strategies/services";
import { fetchFilterValuesByName } from "./fetchFilterValuesByName";
import { StrategyType } from "@/app/shared/utils/enums";

export async function fetchInitialStrategyFilters(
  portfolio,
  base,
  coverage,
  type_phone,
  typeStrategy
) {
  const templates = await fetchTemplateByPortfolio(portfolio);
  return await getFiltersFromTemplates(templates, {
    portfolio,
    base,
    coverage,
    type_phone,
    typeStrategy,
  });
}

async function getFiltersFromTemplates(templates, params) {
  const calculations = await fetchStrategyCalcutations(params);
  if (params.typeStrategy == StrategyType.email) {
    const emails = await fetchStrategyEmailClients(params);
    calculations.number_emails = emails.number_emails;
    calculations.number_clients_emails = emails.number_clients;
  }

  // const emails = await fetchStrategyEmailClients(params);
  // calculations.number_emails = emails.number_emails;
  // console.log(calculations, "calculations");
  // console.log(emails, "emails");

  const custom_clients = await mapCustomFilters(
    templates.client_template,
    params.base,
    params.coverage
  );
  const custom_products = await mapCustomFilters(
    templates.account_template,
    params.base,
    params.coverage,
    true
  );

  const clients = await addAvailableValues(clientsModel, params.base, 1);

  return {
    clients,
    custom_clients,
    custom_products,
    ...calculations,
  };
}

async function mapCustomFilters(array, base, coverage, product = false) {
  let customFilters = array.filter((header) => isCustomFilter(header.name));
  customFilters = customFilters.map((item) => ({
    name: item.name,
    title: item.value,
    type: item.name.startsWith("filter_text") ? "text" : "decimal",
    values: [],
  }));
  return await addAvailableValues(customFilters, base, coverage, product);
}

async function addAvailableValues(array, base, coverage, product) {
  const promises = array.map((item) => fetchFilterValuesByName(item.name, base, coverage, product));
  const availables = await Promise.all(promises);
  return array.map((item, index) => {
    if (item.type !== "text") return item;
    return { ...item, available_values: availables[index] };
  });
}

function isCustomFilter(name) {
  return name.startsWith("filter_text") || name.startsWith("filter_dec");
}

const clientsModel = [
  { name: "groups", type: "text", values: [] },
  { name: "results", type: "text", values: [] },
  { name: "motives", type: "text", values: [] },
  { name: "submotives", type: "text", values: [] },
  { name: "agents", type: "text", values: [] },
  { name: "management_date", type: "date", values: [null, null] },
  { name: "pdp_date", type: "date", values: [null, null] },
  { name: "positive_phones", type: "number", value: 1 },
];

export const filters = {
  number_clients: 10000,
  number_phones: 12000,
  level_palette: 3,
  clients: [
    {
      name: "groups",
      type: "text",
      values: [],
      available_values: [
        { id: 15, name: "Indirecto" },
        { id: 14, name: "Directo" },
        { id: 16, name: "No contacto" },
      ],
    },
    {
      name: "results",
      type: "text",
      values: [],
      available_values: [
        { id: 16, name: "Se mudo" },
        { id: 12, name: "Promesa de Pago" },
        { id: 26, name: "contacto con tercero" },
        { id: 15, name: "Fallecido" },
        { id: 27, name: "teléfono no existe" },
        { id: 13, name: "Dice que ya pago" },
      ],
    },
    {
      name: "motives",
      type: "text",
      values: [],
      available_values: [
        { id: 20, name: "motivo dificultad pago" },
        { id: 19, name: "motivo mensaje" },
        { id: 18, name: "motivo agenda" },
      ],
    },
    {
      name: "submotives",
      type: "text",
      values: [],
      available_values: [{ id: 1, name: "Hola" }],
    },
    {
      name: "agents",
      type: "text",
      values: [],
      available_values: [
        { id: 13, full_name: "Juanito Rojas" },
        { id: 10, full_name: "Mayra Rios" },
        { id: 11, full_name: "Raulito lopez" },
        { id: 14, full_name: "Roberto Carlos" },
        { id: 12, full_name: "Nancy Aguilar" },
      ],
    },
    {
      name: "management_date",
      type: "date",
      values: [],
    },
    {
      name: "pdp_date",
      type: "date",
      values: [],
    },
  ],
  custom_clients: [],
  custom_products: [],
};
