import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener descarga realizas por el usuario
 * @return {Promise<Object>} descarga.
 */
export async function cancelDownload({
  proccessId = "",
}: {
  proccessId: string | number | undefined;
}) {
  const response = await httpManager.put(`processes/${proccessId}/cancel/`);
  return response;
}
