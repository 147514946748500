<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7703 13.7047L14.4328 12.0406C14.6359 11.8375 14.75 11.5656 14.75 11.2781C14.75 10.9906 14.6375 10.7188 14.4328 10.5156L12.6438 8.72344C12.4406 8.52031 12.1672 8.40625 11.8797 8.40625C11.5906 8.40625 11.3203 8.51875 11.1156 8.72344L9.71406 10.1234C8.97096 9.80451 8.29579 9.34626 7.725 8.77344C7.14844 8.2 6.69375 7.52969 6.37188 6.78594L7.77344 5.38594C7.97656 5.18281 8.09063 4.91094 8.09063 4.62344C8.09115 4.48163 8.06337 4.34114 8.0089 4.21021C7.95443 4.07928 7.87438 3.96054 7.77344 3.86094L5.98438 2.06719C5.78125 1.86406 5.50781 1.75 5.22031 1.75C4.93125 1.75 4.66094 1.8625 4.45625 2.06719L2.79375 3.72969C2.44688 4.07656 2.24844 4.55469 2.24844 5.04531C2.24844 5.14687 2.25625 5.24531 2.27344 5.34531C2.61406 7.41406 3.71563 9.46719 5.37188 11.125C7.03125 12.7812 9.08438 13.8813 11.1547 14.225C11.7422 14.3234 12.3469 14.1266 12.7703 13.7047ZM11.3406 13.1172C9.49844 12.8125 7.66094 11.8219 6.16875 10.3297C4.67656 8.8375 3.68594 7.00156 3.38125 5.15938C3.34219 4.92813 3.42031 4.69062 3.5875 4.52187L5.22031 2.88906L6.95313 4.62031L5.07813 6.49219L5.06406 6.50625L5.18906 6.84375C5.5653 7.86707 6.15956 8.79635 6.93066 9.56717C7.70175 10.338 8.63123 10.9319 9.65469 11.3078L9.99219 11.4328L11.8781 9.54531L13.6109 11.2766L11.9781 12.9094C11.8094 13.0781 11.5719 13.1562 11.3406 13.1172Z"
      :fill="active ? '#1677FF' : '#BFBFBF'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
