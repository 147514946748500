import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener listado de monitor de usuarios.
 * @param {object} params Parámetros opcionales.
 * @param {string} params.search Texto a buscar.
 * @param {number} params.entity Identificador de la entidad.
 * @param {number} params.portfolio Identificador de la campaña.
 * @return {Promise<Array>} Listado de monitor de usuarios.
 */
export async function fetchMonitorUsers(params) {
  const search = params?.search || "";
  const entity = params?.entity || "";
  const portfolio = params?.portfolio || "";
  const state = params?.state ?? "";
  const profile = params?.profile ?? "";

  const url = `/monitoring?search=${search}&entity=${entity}&portfolio=${portfolio}&state=${state}&profile=${profile}`;

  const response = await httpManager.get(url);
  return response.data.map((el) => ({
    ...el,
    event_management: {
      event_management_id: el.event_management?.event_management_id,
      last_event_change_time: el.last_event_change_time,
    },
    event_management_messaging: {
      event_management_id: el.event_management_messaging?.event_management_id,
      last_event_change_time: el.last_event_messaging_change_time,
    },
  }));
}
