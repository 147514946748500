import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo evento.
 * @param {Object} reminder Datos del evento.
 *
 * @return {Promise<Object>} Nuevo evento
 */
export async function addEventSchedule(reminder) {
  const url = `/schedule/events/`;
  const response = await httpManager.post(url, reminder);
  return response.data;
}
