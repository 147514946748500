import {
  SET_PROFILES,
  ADD_PROFILE_TO_LIST,
  SET_PROFILE,
  REMOVE_PROFILE,
  ADD_NEW_PROFILE,
  // UPDATE_STATE_USER,
} from "./mutation-types";

const mutations = {
  [SET_PROFILES](state, profiles) {
    state.profiles = profiles;
  },
  [ADD_PROFILE_TO_LIST](state, newProfile) {
    state.profiles.push(newProfile);
  },
  [SET_PROFILE](state, profile) {
    state.profile = profile;
  },
  [REMOVE_PROFILE](state, profileId) {
    const index = state.profiles.findIndex((profile) => {
      return profile.id == profileId;
    });
    state.profiles.splice(index, 1);
  },
  [ADD_NEW_PROFILE](state, payload) {
    const index = state.profiles.findIndex((profile) => {
      return profile.id == payload.id;
    });
    state.profiles.splice(index, 1, payload.dataProfile);
  },
};

export default mutations;
