<template>
  <img :src="path" :alt="type" class="icon" :class="getClasses" />
</template>

<script>
import icons from "../../common/icons";

const colors = ["red", "blue", "gray", "white", "black"];

export default {
  props: {
    type: {
      required: true,
      type: String,
      validator: (value) => icons.includes(value),
    },
    color: {
      required: false,
      type: String,
      default: "white",
      validator: (value) => colors.includes(value),
    },
    small: {
      required: false,
      type: Boolean,
    },
    large: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    path() {
      return new URL(`../../assets/icons/call_${this.type}.svg`, import.meta.url).href;
    },
    getClasses() {
      return {
        [`icon--${this.color}`]: true,
        "icon--small": this.small,
        "icon--large": this.large,
        "icon-disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.icon
  display: block
  width: 22px

  &--red
    filter: invert(16%) sepia(99%) saturate(3519%) hue-rotate(348deg) brightness(105%) contrast(92%)

  &--blue
    filter: invert(48%) sepia(34%) saturate(5539%) hue-rotate(190deg) brightness(100%) contrast(101%)

  &--gray
    filter: invert(57%) sepia(0%) saturate(750%) hue-rotate(176deg) brightness(98%) contrast(90%)

  &--white
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(270deg) brightness(101%) contrast(102%)

  &--black
    filter: invert(10%)

  &--small
    width: 16px

  &--large
    width: 24px

  &-disabled
    filter: invert(80%)
</style>
