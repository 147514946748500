<template>
  <div class="transfer__email">
    <a-modal
      :open="open"
      title="Transferir a un usuario"
      wrapClassName="transfer"
      @ok="handleOk"
      @cancel="handleClose"
      destroyOnClose
      centered
      :maskClosable="false"
      width="650px"
      :footer="null"
    >
      <div class="content">
        <p>Elige el usuario al que deseas transferir el correo.</p>
        <a-form class="form" layout="vertical" :model="formState">
          <a-form-item>
            <a-input v-model:value="formState.search" placeholder="Buscar por nombre">
              <template #suffix>
                <SearchOutlined />
              </template>
            </a-input>
          </a-form-item>
          <UserInformation :users="filteredUsers()" v-model:value="selectedUser" />
          <div class="footer">
            <a-button
              size="medium"
              @click="handleCampaignModal"
              :loading="transferring"
              :disabled="!selectedUser"
            >
              Continuar
            </a-button>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
  <TransferCampaign
    v-model:visible="visible"
    :campaignDefault="campaignDefault"
    :selectedThreads="props.selectedRows"
    :selectedUser="selectedUser"
    :selectedUserObject="selectedUserObject"
    @handleOk="handleOkTransferCampaign"
    type="user"
    title="Transferir a un usuario"
  />
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, computed } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import UserInformation from "../../cards/UserInformation.vue";
import TransferCampaign from "./TransferCampaign.vue";
import { DataType, IUsers } from "@/@types/emails/index";
import { emailService } from "@/app/emails/services/Email.service";

const props = defineProps<{ open: boolean; selectedRows?: DataType[] }>();

const visible = ref<boolean>(false);
// const isBtnEnabled = ref<boolean>(false);

const emit = defineEmits<{ "update:open": [open: boolean]; update: [identifier: number] }>();

const formState = reactive({
  search: "",
});

const selectedUser = ref<number>();

const users = ref<IUsers[]>([]);
const campaignDefault = ref<any>(null);

onMounted(() => fetchUsers());

const fetchUsers = async () => {
  users.value = await emailService.getUsers();
};

const filteredUsers = () => {
  return users.value.filter((user) =>
    user.name.toLowerCase().startsWith(formState.search.toLowerCase())
  );
};

const selectedUserObject = computed(() => {
  return users.value
    .filter((user) => user.name.toLowerCase().startsWith(formState.search.toLowerCase()))
    .find((el) => el.id == selectedUser.value);
});

const handleOk = () => {
  emit("update:open", false);
  // isBtnEnabled.value = false;
};
const handleClose = () => {
  emit("update:open", false);
  // isBtnEnabled.value = false;
};

const transferring = ref(false);

const handleCampaignModal = async () => {
  if (!props.selectedRows) return;
  if (!selectedUser.value) return;

  const user = filteredUsers().find((el) => Number(el.id) === selectedUser.value);

  campaignDefault.value = null;
  if (user?.campaign.length == 1) {
    campaignDefault.value = user.campaign[0].id;
  }

  visible.value = true;
};

const handleOkTransferCampaign = async (identifier: number) => {
  // transferring.value = true;

  handleClose();
  emit("update", identifier);
  // try {
  //   await emailService.assignEmailsToUser(
  //     props.selectedRows.map((r) => r.key),
  //     selectedUser.value
  //   );

  //   handleClose();
  //   emit("update");
  // } catch (error) {
  //   console.error(error);
  // }

  transferring.value = false;
};
</script>
<style lang="sass" scoped>
.content
  z-index: 2
  .form
    .footer
      padding: 17px 0 0
      display: flex
      justify-content: center
      button
        width: 200px
</style>
<style>
.content .form input {
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
  margin: 0;
}
.content .ant-card-body {
  padding: 15px;
}
</style>
