<template>
  <div>
    <!-- <template v-if="channelTypeId !== ChannelType.Voice || $route.params.channel"> -->
    <!--   <a-row v-if="campaign" class="row row__campaign"> -->
    <!--     <a-col class="row__tag"> -->
    <!--       <a-tooltip :title="campaign || null"> -->
    <!--         <a-tag class="tag-custom"> {{ campaign }} </a-tag> -->
    <!--       </a-tooltip> -->
    <!---->
    <!--       <a-tooltip :title="queueName || null"> -->
    <!--         <a-tag> {{ queueName || "..." }} </a-tag> -->
    <!--       </a-tooltip> -->
    <!---->
    <!--       <a-tooltip :title="ticket?.id ? `ID: #${ticket?.id}` : null"> -->
    <!--         <a-tag class="tag-id"> ID: #{{ ticket?.id }} </a-tag> -->
    <!--       </a-tooltip> -->
    <!--     </a-col> -->
    <!--   </a-row> -->

    <!-- <a-row class="row row__actions"> -->
    <!--   <TicketTagsList class="tags-width" withLabel :tags="ticket?.tags" /> -->
    <!---->
    <!--   <div class="ticket__actions"> -->
    <!--     <tag-list -->
    <!--       simple-tag -->
    <!--       has-selected-tickets -->
    <!--       :tickets="[ticket]" -->
    <!--       @update:ticket="$emit('update:ticket')" -->
    <!--     /> -->
    <!---->
    <!--     <Reminder -->
    <!--       :client="client?.name" -->
    <!--       :clientid="client?.id" -->
    <!--       :photo="client?.profile_picture" -->
    <!--     /> -->
    <!---->
    <!--     <SpeechButton :ticket="ticket" /> -->
    <!--   </div> -->
    <!-- </a-row> -->
    <!-- </template> -->
    <a-row class="row row__channels">
      <ChannelsList />
      <div class="ticket__actions">
        <a-popover trigger="click" v-model:visible="showPopover">
          <template #content>
            <div class="more-popover">
              <tag-list
                simple-tag
                has-selected-tickets
                :tickets="[ticket]"
                @update:ticket="$emit('update:ticket')"
              />

              <Reminder
                :client="client?.name"
                :clientid="client?.id"
                :photo="client?.profile_picture"
              />

              <SpeechButton :ticket="ticket" @click="hidePopover" />

              <BotVariablesCaptured
                :disabled="!ticket.ticket_variables.length"
                :ticketData="ticket"
                @click="hidePopover"
              />

              <SingleTransfer
                :ticket="ticket"
                @modalIsOpen="transferModalIsOpen = $event"
                :isWorkspace="true"
                :isChat="isChat"
                :inCall="inCall"
              />
            </div>
          </template>

          <a-button class="button" style="padding: 0" type="text">
            <MoreOutlined class="more-icon" />
          </a-button>
        </a-popover>
      </div>
    </a-row>

    <!-- INFO TICKET SECTION -->
    <div class="row row__information">
      <div class="row__content">
        <div class="flex-center-gap-4px border-right" v-if="hasOnlyAlias">
          <component :is="getChannelIcon(interactionSelected?.channel.id)"></component>
          <span>
            {{ alias }}
          </span>
        </div>

        <div class="flex-center-gap-4px border-right" v-else>
          <component :is="getChannelIcon(interactionSelected?.channel.id)"></component>
          <a-tooltip placement="top" v-if="phoneNumber !== null">
            <template #title>
              <span> {{ tooltipInfo }} </span>
            </template>
            <span class="identifier">
              {{ alias }}
              <span class="margin-left-6px"> {{ phoneNumber }} </span>
            </span>
          </a-tooltip>
          <span v-else> Número Aleatorio </span>
        </div>

        <div class="flex-center-gap-4px content-end">
          <a-tooltip placement="top" class="flex-center">
            <template #title v-if="hasAliasAndPhoneNumber">
              <span> Teléfono del contacto </span>
            </template>
            <UserIcon />
            <span class="row__identifier">
              {{ fbClientName ? fbClientName : phoneIdentifier }}
            </span>
          </a-tooltip>
        </div>
      </div>

      <a-row class="row__campaign">
        <a-col class="row__tag">
          <a-tooltip :title="ticket?.id ? `ID: #${ticket?.id}` : null">
            <a-tag class="tag-id"> #{{ ticket?.id }} </a-tag>
          </a-tooltip>

          <template v-if="campaign">
            <a-tooltip :title="campaign || null">
              <a-tag> {{ campaign }} </a-tag>
            </a-tooltip>

            <a-tooltip :title="queueName || null">
              <a-tag> {{ queueName || "..." }} </a-tag>
            </a-tooltip>
          </template>

          <a-tag class="tag-id">
            {{ channelTypeId === ChannelType.VoiceInbound ? "In" : "Out" }}bound
          </a-tag>
        </a-col>
      </a-row>
    </div>

    <a-row class="row row__vitiate" v-if="canVitiate">
      <a-button class="button" @click="handleVitiate">
        Llamada equivocada

        <a-tooltip title="Marcar como llamada equivocada">
          <InfoCircleOutlined />
        </a-tooltip>
      </a-button>
    </a-row>
  </div>
</template>

<script>
import { createVNode, inject } from "vue";

import { Modal, message } from "ant-design-vue";
import { InfoCircleOutlined, QuestionCircleOutlined, MoreOutlined } from "@ant-design/icons-vue";
import UserIcon from "@/app/shared/components/icons/UserIcon.vue";
import VoiceIcon from "@/app/shared/components/icons/channelIcons/VoiceIcon.vue";
import WhatsappIcon from "@/app/shared/components/icons/channelIcons/WhatsappIcon.vue";
import FacebookMsnIcon from "@/app/shared/components/icons/channelIcons/FacebookMsnIcon.vue";
import InstagramIcon from "@/app/shared/components/icons/channelIcons/InstagramIcon.vue";
import WebchatIcon from "@/app/shared/components/icons/channelIcons/WebchatIcon.vue";
import SmsIcon from "@/app/shared/components/icons/channelIcons/SmsIcon.vue";

import { ChannelType } from "@/app/shared/utils/enums";
import { vitiateIncomingCall } from "@/app/workspace/services";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

import Reminder from "@/app/workspace/components/modal/Reminder.vue";
import TagList from "@/app/workspace/components/filters/TagList.vue";
import SingleTransfer from "@/app/workspace/components/filters/SingleTransfer.vue";

// import TicketTagsList from "@/app/shared/components/lists/TicketTagsList.vue";
import SpeechButton from "@/app/workspace/components/buttons/SpeechButton.vue";
import ChannelsList from "@/app/workspace/components/lists/ChannelsList.vue";
import BotVariablesCaptured from "./BotVariablesCaptured.vue";

export default {
  components: {
    Reminder,
    TagList,
    SingleTransfer,
    InfoCircleOutlined,
    ChannelsList,
    // TicketTagsList,
    SpeechButton,
    MoreOutlined,
    BotVariablesCaptured,
    UserIcon,
    VoiceIcon,
    WhatsappIcon,
    FacebookMsnIcon,
    InstagramIcon,
    WebchatIcon,
    SmsIcon,
  },
  props: {
    isChat: { required: false, type: Boolean },
    inCall: Boolean,
  },
  data() {
    return {
      ChannelType,
      showPopover: false,
      interactionSelected: null,
      transferModalIsOpen: null,
    };
  },
  setup() {
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    return { ticket, channel };
  },
  methods: {
    hidePopover() {
      this.showPopover = false;
    },
    getTagColor(color) {
      return `background-color: #${color}`;
    },
    async handleVitiate() {
      Modal.confirm({
        title: "¿Deseas terminar la llamada y guardarla como equivocada?",
        content: "Recuerda que al guardar se finalizará la llamada inmediatamente.",
        icon: createVNode(QuestionCircleOutlined),
        centered: true,
        okText: "Guardar",
        onOk: async () => {
          try {
            const body = {
              phoneNumber: this.channel?.identifier || this.ticket?.identifier,
              ticket: Number(this.$route.query.ticket),
              asterisk_id: this.$jssip.phone.meta?.asteriskId,
            };

            this.$jssip.end({ fromTypification: true });
            EventBus.$emit(WorkspaceEvent.TICKET_CLOSED, { resolveWithoutTypification: true });
            await vitiateIncomingCall(body);
            message.success("Guardado como llamada equivocada correctamente");
            // this.$jssip.reset();
          } catch (e) {
            console.log(e);
            message.error("Ha ocurrido un error");
          }
        },
      });
    },
    getChannelIcon(type) {
      switch (type) {
        case ChannelType.Voice:
        case ChannelType.Ivr:
        case ChannelType.VoiceInbound:
          return VoiceIcon;
        case ChannelType.Whatsapp:
          return WhatsappIcon;
        case ChannelType.Messenger:
          return FacebookMsnIcon;
        case ChannelType.Instagram:
          return InstagramIcon;
        case ChannelType.Inbound:
          return WebchatIcon;
        case ChannelType.Sms:
          return SmsIcon;
        default:
          return null;
      }
    },
    getCurrentIntData() {
      if (this.interactions) {
        const int = parseInt(this.$route.query.interaction);

        const msgInteraction = this.interactions.messages.find(
          (interaction) => interaction.id === int
        );

        const voiceInteraction = this.interactions.voice.find(
          (interaction) => interaction.id === int
        );

        if (msgInteraction) {
          this.interactionSelected = { ...msgInteraction, typeInteraction: "message" };
        }

        if (voiceInteraction) {
          this.interactionSelected = { ...voiceInteraction, typeInteraction: "voice" };
        }
      }
    },
    formattedPhoneNumber(number) {
      if (number !== null) {
        const sliced = number.length <= 7 ? `+${number}` : `+${number.slice(0, 7)}` + "...";

        return {
          number,
          sliced,
        };
      }
    },
  },
  computed: {
    tooltipInfo() {
      return this.alias !== ""
        ? `Identificador de tu empresa: ${this.alias} ${this.phoneNumber}`
        : null;
    },
    interactions() {
      return this.$store.getters["getInteractionsChannel"];
    },
    currentInteraction() {
      return this.$route.query.interaction;
    },
    isVoice() {
      return (
        this.channel?.channel?.id === ChannelType.Voice ||
        this.channel?.channel?.id === ChannelType.VoiceInbound
      );
    },
    fbClientName() {
      return this.interactionSelected?.extra_information?.fullname;
    },
    // ticket() {
    //   const tickets = this.$store.getters["getWorkspaceTickets"];
    //   const ticketId = this.$route.params.ticket;
    //   return tickets.find((item) => item.id == ticketId);
    // },
    client() {
      return this.ticket?.client;
    },
    channelTypeId() {
      return this.channel?.channel?.id || this.ticket?.channel?.id;
    },
    campaign() {
      const data = this.$store.getters["getWorkspaceMessageHistory"];
      return this.ticket?.portfolio?.name || data?.ticket?.portfolio?.name;
    },
    queueName() {
      const history = this.$store.getters["getWorkspaceMessageHistory"];
      return this.ticket?.queue?.name || history?.ticket?.queue?.name;
    },
    phoneIdentifier() {
      const codeCountry = this.channel?.code_country;
      const identifier = this.channel?.identifier || this.ticket?.identifier;

      return codeCountry ? `+${codeCountry}${identifier}` : identifier;
    },
    identifier() {
      const history = this.$store.getters["getWorkspaceMessageHistory"];
      const client = history?.ticket?.client;
      const message = history?.history[0].messages[0];
      return client?.name || message?.identifier;
    },
    canVitiate() {
      return this.channelTypeId === ChannelType.VoiceInbound && !this.$route.query.client;
    },
    alias() {
      return this.interactionSelected?.channel_customer.alias || "";
    },
    phoneNumber() {
      return this.interactionSelected?.channel_customer?.data_parameters?.phone_number || null;
    },
    hasAliasAndPhoneNumber() {
      return [ChannelType.Voice, ChannelType.Whatsapp].includes(this.channelTypeId);
    },
    hasOnlyAlias() {
      return [ChannelType.Messenger, ChannelType.Instagram, ChannelType.Inbound].includes(
        this.channelTypeId
      );
    },
  },
  watch: {
    interactions() {
      this.getCurrentIntData();
    },
    currentInteraction: {
      immediate: true,
      handler: function () {
        this.getCurrentIntData();
      },
    },
    transferModalIsOpen(newValue) {
      if (newValue) {
        this.hidePopover();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.row
  padding: 8px 12px
  background-color: white

  &__tag
    display: flex
    align-items: center
    gap: 4px
    font-size: 12px

    & > *
      margin: 0

  &__content
    display: flex
    font-weight: 600
    margin-bottom: 8px

  &__information
    background-color: #F2F5F7
    border-bottom: $border-medium
    color: $gray-8

  &__channels
    border-bottom: $border-medium
    display: flex
    justify-content: space-between

  &__campaign
    font-size: 12px
    display: block
    align-items: center
    justify-content: space-between

  &__actions
    border-bottom: $border-medium
    min-height: 40px
    max-height: 40px
    padding-top: 0
    padding-bottom: 0
    display: flex
    justify-content: space-between

  &__identifier
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 230px
    text-transform: capitalize

  &__vitiate
    background-color: #f0f2f5
    display: flex
    justify-content: center
    align-items: center
    border-bottom: $border-medium

.tag
  &-custom
    background-color: #F0F5FF
    color: $geekblue-7
    border-color: #ADC6FF

  &-id
    overflow: inherit !important

.ticket
  &__tags
    padding-right: 8px
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 0 4px
    max-height: 40px

  .tags-width
    max-width: calc(100% - 54px)

  &__message
    color: $gray-6
    font-style: italic
    font-size: 12px

  &__actions
    border-left: $border-medium
    display: flex
    align-items: center
    padding-left: 8px
    gap: 8px

.color-tag
  width: 20px
  height: 8px
  border-radius: 2px
  border: $white

:deep(.ant-tag)
  overflow: hidden
  text-overflow: ellipsis

.button
  height: 24px
  padding: 0 8px

.more
  &-icon
    font-size: 20px
    color: $gray-8

  &-popover
    display: flex
    flex-direction: column
    gap: 4px

.ant-tooltip-open
  font-size: inherit !important

.border-right
  border-right: 1px solid #D9D9D9

.flex-center-gap-4px
  display: flex
  align-items: center
  gap: 4px
  flex: 1
  min-width: 0px

.content-end
  justify-content: end

.identifier
  margin-bottom: 0px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

  // &::after
  //   content: '...'
  //   position: absolute
  //   bottom: 0
  //   right: 0
  //   background: #F2F5F7
  //   color: #00000073
  //   padding-left: 2px

  span
    margin-left: 6px
    font-size: 12px
    font-weight: 500
    color: #00000073

.flex-center
  display: flex
  align-items: center

  .anticon
    margin-right: 4px
</style>
