import {
  downloadTemplateByPortfolio,
  fetchTemplateByPortfolio,
  replicateTemplateFromPortfolio,
  saveTemplateByType,
} from "@/app/imports/services";

import {
  SET_PORTFOLIO,
  SET_TEMPLATES_DATA,
  SET_ACTIVE_FIELDS,
  SET_DEFAULTS,
  SET_FIELD_ITEM,
  MOVE_FIELD_ITEM,
  REMOVE_FIELD_ITEM,
} from "./mutation-types";

const actions = {
  async fetchTemplateByPortfolio({ state, commit }) {
    commit(SET_TEMPLATES_DATA, null);
    const templates = await fetchTemplateByPortfolio(state.portfolio.id);
    commit(SET_TEMPLATES_DATA, templates);
  },
  async saveTemplateByType({ state, commit, dispatch }, type) {
    const templates = await saveTemplateByType(type, state.portfolio.id, state.activeFields);
    commit(SET_TEMPLATES_DATA, templates);
    await dispatch("setActiveFieldsTemplateByType", type);
  },
  async replicateTemplateFromPortfolio({ state }, { type, source }) {
    const to = state.portfolio.id;
    await replicateTemplateFromPortfolio(type, source, to);
  },
  async downloadTemplateByType({ state }, type) {
    const portfolio = state.portfolio.id;
    const response = await downloadTemplateByPortfolio(type, portfolio);
    return response;
  },
  setActiveFieldsTemplateByType({ state, commit }, type) {
    let activeFields = state.templates.account_template;
    if (type == "client") activeFields = state.templates.client_template;
    commit(SET_ACTIVE_FIELDS, activeFields);
    commit(SET_DEFAULTS, type);
  },
  updateTemplateActiveFields({ state, commit }, fields) {
    const activeFieldsWithDefaults = [...state.defaults, ...fields];
    commit(SET_ACTIVE_FIELDS, activeFieldsWithDefaults);
  },
  setTemplatePortfolio({ commit }, portfolio) {
    commit(SET_PORTFOLIO, portfolio);
  },
  setTemplateFieldItem({ commit }, field) {
    commit(SET_FIELD_ITEM, field);
  },
  moveTemplateFieldItem({ commit }, { from, to }) {
    commit(MOVE_FIELD_ITEM, { from, to });
  },
  removeTemplateFieldItem({ commit }, field) {
    commit(REMOVE_FIELD_ITEM, field);
  },
  clearTemplatePortfolio({ state }) {
    state.portfolio = null;
  },
};

export default actions;
