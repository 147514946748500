import { State, TypeInbox } from "@/@types/emails";

const initialState = (): State => ({
  // Menu seleccionado en inbox: Bandeja de Entrada, Asignados o Resueltos
  selectedMenu: TypeInbox.BandejaEntrada,

  // Items del menu seleccionado: Campañas e identificadores
  menuItems: [],
  openKeysMenuItems: [],
  selectedKeysMenuItems: [],

  // Campaña seleccionada en el menu
  selectedCampaign: null,

  totalEmails: 0,
  tablePage: 1,

  // Filtros de inbox emails
  filters: {
    search: "",
    read: undefined,
    tagList: [],
  },

  // Listado de hilos de correos segun menu e identificador seleccionado
  threadsInbox: [],
  fetchingThreadsInbox: false,
  selectedThreadsInbox: {
    selectedRowKeys: [],
    countSelectedRow: 0,
  },

  // Hilo de correos seleccionado
  threadActual: {
    key: null,
    id: null,
    details: [],
    tags: [],
    associatedClients: [],
    client: null,
    ticket: null,
  },
});

const state: State = initialState();

export default state;
