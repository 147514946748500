import httpManager from "@/app/shared/utils/httpManager";
/**
 * Cambiar estado de una base.
 *
 * @param {number} baseId Iedntificador de la base.
 * @param {number} status Estado a modificar.
 *
 * @return {Promise<Object>} Base editada.
 */
export async function changeBaseStatus(baseId, status) {
  const response = await httpManager.put(`/entities/portfolios/bases/${baseId}/`, { status });
  return response?.data;
}
