<template>
  <a-spin :spinning="ticketsListLoading">
    <div class="container" id="workspace-tickets-list">
      <CustomScrollbar
        v-if="tickets.length > 0"
        ref="customScrollbar"
        :height="showBanner ? 'calc(100vh - 227px)' : 'calc(100vh - 192px)'"
      >
        <div v-for="ticket in tickets" :key="ticket.id">
          <WorkspaceTicketsItem :ticket="ticket" />
        </div>

        <div style="height: 2px" ref="pageFlag" />
      </CustomScrollbar>

      <div v-else class="information__queues">
        <img src="@/app/workspace/assets/workspace1.png" alt="workspace" />
        <p>1. No se encontraron tickets</p>
      </div>

      <!-- <div class="no-data--message" v-else>
        <p>No se encontraron resultados.</p>
        <p>Por favor, prueba modificar tus filtros.</p>
      </div> -->
    </div>
  </a-spin>
</template>

<script>
import { computed, inject, ref } from "vue";
import { useStore } from "vuex";
import { useIntersectionObserver } from "@vueuse/core";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import WorkspaceTicketsItem from "@/app/workspace/components/cards/WorkspaceTicketItem.vue";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import EventBus from "@/app/shared/utils/eventBus";

export default {
  components: {
    WorkspaceTicketsItem,
    CustomScrollbar,
  },
  setup() {
    const store = useStore();

    const showBanner = inject("showBanner");
    const ticketsListLoading = inject("ticketsListLoading");

    const pageFlag = ref();

    const currentPage = computed(() => store.getters["getTicketsListPage"]);
    const hasNextPage = computed(() => store.getters["getHasNextTicketPage"]);

    useIntersectionObserver(pageFlag, ([{ isIntersecting }]) => {
      console.log({ page: currentPage.value, isIntersecting, hasNext: hasNextPage.value });
      if (!isIntersecting || !hasNextPage.value) return;

      const newPage = currentPage.value + 1;
      store.dispatch("fetchActiveWorkspaceTickets", { page: newPage });
    });

    return { showBanner, ticketsListLoading, pageFlag };
  },
  computed: {
    tickets() {
      return this.$store.getters["getWorkspaceTickets"];
    },
    hasFilter() {
      const filters = this.$store.getters["getTicketsFilters"];

      for (const key in filters) {
        if (filters[key]) return true;
      }

      return false;
    },
    // allTickets() {
    //   return this.$store.getters["getWorkspaceTickets"];
    // },
  },
  mounted() {
    EventBus.on(WorkspaceEvent.RESET_LIST, () => {
      if (this.$refs.customScrollbar)
        this.$refs.customScrollbar?.scrollToTop();
    });
  },
};
</script>

<style lang="sass" scoped>
.container
  border-top: 1px solid #D9D9D9

.information__queues
  text-align: center
  margin-top: 160px

.no-data--message
  text-align: center
  color: $gray-6
  margin-top: 35px

  p
    margin: 0
</style>
