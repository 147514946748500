<template>
  <a-table
    :columns="columns(detailedView)"
    row-key="id"
    size="small"
    :data-source="users"
    :pagination="false"
    :scroll="scrollStyle"
  >
    <template #fullname="{ record }">
      <supervision-agent-card
        v-if="record.user"
        :supervision="record"
        :detailedView="detailedView"
        @click="handleAgentClick(record)"
        @logout="showModalLogout(record)"
        @screen-monitoring="handleSupervise(record)"
      />
    </template>

    <template #nameTitle>
      Nombre y apellidos
      <a-tooltip>
        <template #title> Ordenar </template>
        <SwapOutlined :rotate="90" />
      </a-tooltip>
    </template>

    <template #state="{ record }">
      <StateCell :record="record" :detailedView="detailedView" />
    </template>

    <template #event_management="{ record }">
      <EventsCell :record="record.event_management" :detailedView="detailedView" />
    </template>

    <template #event_management_messaging="{ record }">
      <EventsCell :record="record.event_management_messaging" :detailedView="detailedView" />
    </template>

    <template #annex="{ text }">
      {{ text.split("@")[0] }}
    </template>

    <template #webphoneTitle>
      <span v-if="detailedView"> Webphone </span>

      <template v-else>
        <WebphoneIcon />
        <a-tooltip>
          <template #title> Estado del webphone </template>

          <InfoCircleOutlined class="icon" />
        </a-tooltip>
      </template>
    </template>

    <template #webphoneBody="{ text: status }">
      <span v-if="detailedView">
        {{ WebphoneText[status] }}
      </span>

      <a-tooltip v-else>
        <template #title> {{ WebphoneText[status] }} </template>

        <a-tag :color="WebphoneColor[status]?.tag" class="webphone-tag">
          <WebphoneIcon :color="WebphoneColor[status]?.hexadecimal" />
        </a-tag>
      </a-tooltip>
    </template>

    <template #menu="{ text: menu, record }">
      <span :title="getChangeName(menu)" v-if="menu">{{ getChangeName(menu) }}</span>
      <span :title="getChangeName(record.section, record.user)" v-else-if="record.section">
        {{ getChangeName(record.section, record.user) }}
      </span>
      <span :title="record.menu?.name" v-else> {{ record.menu ? record.menu.name : " -- " }}</span>
    </template>

    <template #strategy="{ record }">
      {{ formatCurrentStrategiesToString(record.current_strategies) }}
    </template>

    <template #portfolio="{ text: portfolios }">
      <a-popover title="Campañas" :class="{ 'popover-disabled': !portfolios?.length }">
        <template #content>
          <ul>
            <li v-for="portfolio in portfolios" :key="portfolio.id">{{ portfolio.name }}</li>
          </ul>
        </template>

        <span>
          <InfoCircleOutlined style="margin-right: 8px" />
          <span v-if="portfolios?.length">
            {{ portfolios?.length || 0 }} {{ portfolios?.length === 1 ? "campaña" : "campañas" }}
          </span>

          <span v-else>Sin campañas</span>
        </span>
      </a-popover>
    </template>

    <template #active_call="{ record }">
      <supervision-call-card
        show-pop
        :typeStrategy="formatCurrentStrategiesToString(record.current_strategies)"
        :data="record.active_call"
        :last_acw_date="record.last_acw_date"
        :last_waiting_time="record.last_waiting_time"
        :state="record.phone_event_management"
        :currentRecord="record"
        :detailedView="detailedView"
        @captureCurrentCall="handleCapture(record, true)"
      />
    </template>
    <template #chat="{ record }">
      <supervision-chat-card
        :callActive="record.active_call"
        :state="record.event_management"
        :data="record.last_open_ticket"
        :detailedView="detailedView"
        @capture="handleCapture(record, false)"
      />
    </template>

    <template #tickets="{ record }">
      {{ record.tickets }}
    </template>
  </a-table>

  <logout-agent-modal :visible="showModal2" :record="logoutRecord" @onClose="closeModal" />
</template>

<script>
import { defineComponent, inject } from "vue";
import { SwapOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";

import {
  EventManagement,
  WebphoneText,
  WebphoneColor,
  StrategyType,
  RolTypes,
} from "@/app/shared/utils/enums";
import { useCaptureTicket } from "@/app/supervision/composables/useCaptureTicket";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { SectionsNames } from "@/app/common/utils/enums";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { columns } from "./SupervisionTableColumns";

import LogoutAgentModal from "../modal/LogoutAgentModal.vue";
import SupervisionCallCard from "../calls/SupervisionCallCard.vue";
import SupervisionChatCard from "../calls/SupervisionChatCard.vue";
import SupervisionAgentCard from "../agent/SupervisionAgentCard.vue";
import WebphoneIcon from "@/app/shared/components/icons/WebphoneIcon.vue";
import StateCell from "./cells/StateCell.vue";
import EventsCell from "./cells/EventsCell.vue";

const status = [
  EventManagement.Online,
  EventManagement.Ringing,
  EventManagement.Talking,
  EventManagement.Acw,
  EventManagement.Typifying,
  EventManagement.Refreshment,
  EventManagement.Services,
  EventManagement.Training,
  EventManagement.Occupied,
  EventManagement.Opened,
  EventManagement.Writing,
  EventManagement.Resolved,
  EventManagement.Waiting,
  EventManagement.Inactive,
];

const StrategyName = {
  [StrategyType.predictive]: "Predictivo",
  [StrategyType.progressive]: "Progresivo",
  [StrategyType.sms]: "SMS",
  [StrategyType.ivr]: "IVR",
  [StrategyType.whatsapp]: "Whatsapp",
};

export default defineComponent({
  components: {
    SupervisionAgentCard,
    SupervisionCallCard,
    SupervisionChatCard,
    SwapOutlined,
    LogoutAgentModal,
    WebphoneIcon,
    StateCell,
    EventsCell,
    InfoCircleOutlined,
  },
  props: {
    recordAllInfo: {
      type: String,
    },
  },
  emits: ["onClick"],
  data() {
    return {
      columns,
      titleModal: "Escuchar",
      showModal: false,
      name: "No identificado",
      profilePicture: "",
      role: "",
      currentRecord: null,
      isMuted: false,
      isMinimized: false,
      showModal2: false,
      logoutRecord: null,
      timeModal: undefined,
      stateTime: undefined,
      resetTime: "00:00",
      dialing: undefined,
      stateSupervision: undefined,
      recordTable: undefined,
      SectionsNames,
      WebphoneColor,
      WebphoneText,
    };
  },
  setup() {
    const handleCapture = useCaptureTicket();
    const { scrollStyle } = useScrollResize({ headerMargin: 300 });

    const detailedView = inject("detailedView");
    const currentMonitoredUser = inject("currentMonitoredUser");

    return { handleCapture, detailedView, scrollStyle, currentMonitoredUser };
  },
  computed: {
    users() {
      return this.$store.getters["getMonitorUserList"];
    },
    isSameNumber() {
      return !!this.currentRecord?.active_call?.phone_number && this.recordTable?.phone_number;
    },
  },
  mounted() {
    EventBus.$on(WorkspaceEvent.TIME_SUPERVITION, (time) => {
      this.timeModal = time;
    });
    EventBus.$on(WorkspaceEvent.TIME_SUPERVITION, (dialing) => {
      this.dialing = dialing;
    });
    EventBus.on("DATA_USER_PHONE", (data) => {
      this.recordTable = data;
    });
  },
  methods: {
    getStrategyName(record) {
      if (status.includes(record.event_management)) {
        // Hidden IVR for PoC
        // if (record.current_ivr_strategy != null) {
        //   return "Marcador IVR";
        // }
        if (record.current_predictive_strategy != null) {
          return "Marcador Predictivo";
        }
        if (record.current_progressive_strategy != null) {
          return "Marcador Progresivo";
        }
        if (record.active_call != null) {
          return record.active_call.type_dialing;
        } else return " - ";
      } else {
        return " - ";
      }
    },
    formatCurrentStrategiesToString(currentStrategies) {
      if (!Array.isArray(currentStrategies)) return "-";

      const activeStrategiesArray = currentStrategies?.map(
        (strategy) => StrategyName[strategy.type]
      );

      return activeStrategiesArray?.join(" - ") || "-";
    },
    closeModal() {
      this.showModal2 = false;
      this.logoutRecord = null;
    },
    showModalLogout(record) {
      this.logoutRecord = record;
      this.showModal2 = true;
    },
    handleAgentClick(record) {
      this.$emit("onClick", record);
    },
    getChangeName(menu, user) {
      if (menu.name === "Entidades") {
        return "Empresas";
      }

      if (menu.scope === "section_profile")
        return user?.role?.id === RolTypes.owner ? `${menu.name} y empresa` : menu.name ;

      return menu.name;
    },
    handleSupervise(user) {
      this.currentMonitoredUser = user;
      this.$jssip.startScreenSharing(user);
    },
  },
});
</script>
<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

:deep(.ant-table-align-center.ant-table-row-cell-break-word > .ant-table-header-column > div)
  // height: 22px
:deep(th.ant-table-cell.webphone_status)
  display: flex
  justify-content: center
  align-items: center
  height: 47px
  gap: 4px

.webphone-tag
  display: grid
  place-content: center
  width: 30px
  aspect-ratio: 1/1
  margin: 0 auto

.popover-disabled
  pointer-events: none
  color: $gray-7
</style>
