import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crea una variable
 * @return {Promise<Object>} variable.
 */
export async function deleteVar(varId: number) {
  try {
    const response = await httpManager.delete(`variables/${varId}/`);
    console.log(response);
    return { success: true, data: response.data };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
}
