import { fetchAllHeaders } from "@/app/shared/services";
import { SET_HEADERS } from "./mutation-types";

const actions = {
  async fetchAllTemplateHeaders({ commit }) {
    const headers = await fetchAllHeaders();

    // TODO: Delete this after update backend
    const products = {
      ...headers.products,
      general: headers.products.general.filter((item) => item.name != "code"),
    };

    commit(SET_HEADERS, { type: "clients", data: headers.clients });
    commit(SET_HEADERS, { type: "products", data: products });
  },
};

export default actions;
