import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los canales del tenant.
 *
 * @return {Promise<Array>} Lista de canales.
 */
export async function fetchAllChannels({ toSequence } = {}) {
  let url = `/channels/`;
  if (toSequence)
    url += `?to_sequence=${toSequence}`
  const response = await httpManager.get(url);
  return response?.data?.results || [];
}
