import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener el resultado de los usuarios
 *
 * @param {string} search Identificador para la busqueda de un usuario
 *  @param {string} profile Identificador del rol del usuario.
 * @return {Promise<Array>} Listado de usuarios

 */

export async function fetchAllFilterProfile(profile = "", search = "") {
  profile = profile == 0 ? "" : profile;
  const url = `/users/?profile=${profile}&search=${search}`;
  const response = await httpManager.get(url);
  return response?.data;
}
