<template>
  <div>
    <a-form class="form" layout="vertical" @submit.prevent="handleSubmit">
      <a-form-item label="Correo electrónico" class="recover__passowrd" required>
        <a-input
          v-model:value="email"
          class="form__item"
          placeholder="Ingresar correo electronico"
          required
        />
      </a-form-item>

      <footer class="form__footer">
        <a-button
          type="primary"
          class="recover"
          html-type="submit"
          :loading="loading"
          :disabled="email === ''"
        >
          Enviar instrucciones
        </a-button>
      </footer>
    </a-form>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import httpManager from "@/app/shared/utils/httpManager";

export default {
  setup() {
    const store = useStore();
    const workspaces = computed(() => store.getters["getAllWorkspaces"]);
    return {
      workspaces,
    };
  },
  data() {
    return {
      loading: false,
      email: "",
    };
  },
  methods: {
    async handleSubmit() {
      this.$store.dispatch("setLoginProgress", 2);
      try {
        this.loading = true;
        const data = { email: this.email };
        const workspace = this.$route.params.workspace;
        httpManager.updateBaseURL(`https://${workspace}.backend.${import.meta.env.VITE_HOSTNAME}`);
        await this.$store.dispatch("recoverPassword", data);

        this.$emit("onFinish", 2);
      } catch (error) {
        this.$message.error("El correo electronico no pertenece a este equipo de trabajo");
      } finally {
        this.loading = false;
      }
    },
    handleLogin() {
      this.$router.back();
    },
  },
};
</script>

<style lang="sass" scoped>

.ant-form
  padding: 12px 0
  position: relative

.form__footer
  margin-top: 32px
  text-align: center

.recover__passowrd
  margin-top: 10px

.recover
  width: 100%
</style>
