import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los Resultados de todas las Empresas.
 *
 * @return {Promise<Array>} Lista de Resultados.
 */
export async function fetchAllResults() {
  const url = "/entities/results/";
  const response = await httpManager.get(url);

  return response?.data;
}
