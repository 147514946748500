<template>
  <ActionsPopover
    :actions="emailActions"
    @handleNoteEvent="handleNoteEvent"
    @handleReplyEvent="handleReplyEvent"
    :show="show"
    :showReply="showReply"
  />
</template>
<script setup lang="ts">
import IconArrowCorner from "@/app/common/components/atoms/IconArrowCorner.vue";
import IconDoubleArrowCorner from "@/app/common/components/atoms/IconDoubleArrowCorner.vue";
import IconArrowRightCorner from "@/app/common/components/atoms/IconArrowRightCorner.vue";
import IconBlock from "@/app/common/components/atoms/IconBlock.vue";
import { MessageOutlined } from "@ant-design/icons-vue";
import ActionsPopover from "@/app/emails/components/popovers/ActionsPopover.vue";
import { ActionsEmail } from "@/@types/emails";

defineProps({
  show: { type: Boolean, default: false },
  showReply: { type: Boolean, default: false },
});

const emailActions = [
  { label: ActionsEmail.Responder, icon: IconArrowCorner },
  { label: ActionsEmail.ResponderTodos, icon: IconDoubleArrowCorner },
  { label: ActionsEmail.Reenviar, icon: IconArrowRightCorner },
  { label: ActionsEmail.NotaInterna, icon: MessageOutlined },
  { label: ActionsEmail.Bloquear, icon: IconBlock },
];

const emit = defineEmits(["handleNoteEvent", "handleReplyEvent"]);

const handleNoteEvent = (message: boolean) => {
  emit("handleNoteEvent", message);
};

const handleReplyEvent = (action: string) => {
  emit("handleReplyEvent", action);
};
</script>
<style lang="sass" scoped>
.answer
  ul
    list-style: none
    padding-left: 0
    margin: 0
    li
      button
        width: 100%
        text-align: left
        padding: 0 5px
        display: flex
        align-items: center
        gap: 5px
        &:hover
          background: #0000000A
        svg
          height: 13px
  a
    display: none
</style>
