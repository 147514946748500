<template>
  <h2 v-if="inContactsTable">Selecciona la campaña</h2>

  <section :class="{ 'section-footer': inContactsTable }">
    <div class="form__wrapper">
      <!-- <header>
        <a-button v-if="!inContactsTable" type="link" class="button" @click="handleGoBack">
          <ArrowLeftOutlined />
          Volver
        </a-button>

        <h3>Selecciona la {{ selectText }}</h3>
      </header> -->

      <p class="paragraph">Selecciona la {{ selectText }} donde quieres crear este nuevo contacto.</p>
      <a-table
        :columns="columnsTable"
        :data-source="dataSource"
        :scroll="inContactsTable ? { y: 370 } : scrollStyle"
        :pagination="false"
        size="small"
      >
        <template #manage="{ record }">
          <a @click="handleAssign(record)">
            {{ inContactsTable ? "Crear aquí" : "Asignar aquí" }}
          </a>
        </template>
      </a-table>
    </div>

    <div v-if="inContactsTable">
      <!-- <a-divider type="horizontal" class="divider" /> -->

      <footer class="footer">
        <a-button @click="handleGoBack" class="footer__button button cancel"> Volver </a-button>
      </footer>
    </div>
  </section>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";

import { getNewClientTableColumn } from "@/app/workspace/components/tables/Client/ClientNewTableColumn";
import { clientNewTable } from "@/app/workspace/services";
import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";

export default defineComponent({
  components: {
    ArrowLeftOutlined,
  },
  props: {
    inContactsTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const { scrollStyle } = useScrollResize({ xAxis: false, headerMargin: 280 });

    const user = computed(() => store.getters["getDataUser"]);

    const isBasesEnable = computed(() => user.value?.customer?.enable_bases);

    const selectText = computed(() => (isBasesEnable.value ? "base" : "campaña"));

    const columns = getNewClientTableColumn(
      isBasesEnable.value,
      user.value?.customer?.type_business
    );

    const handleAssign = (record) => {
      emit("assign", record);
    };

    const handleGoBack = () => emit("go-back");

    return {
      user,
      isBasesEnable,
      selectText,
      columns,
      scrollStyle,
      handleAssign,
      handleGoBack,
    };
  },
  data() {
    return {
      dataSource: [],
      isCreateClientVisible: false,
      selectedItem: {},
    };
  },
  computed: {
    columnsTable() {
      const column = columnsFixedTableLeft(this.columns);
      return column;
    },
  },
  created() {
    this.clientNewTable();
  },

  methods: {
    async clientNewTable() {
      const records = await clientNewTable({ isBasesEnable: this.isBasesEnable });
      this.dataSource = records.results.map((record) => ({
        base: this.isBasesEnable ? record.name : null,
        baseId: this.isBasesEnable ? record.id : null,
        portfolio: this.isBasesEnable ? record.portfolio.name : record.name,
        portfolioId: this.isBasesEnable ? record.portfolio.id : record.id,
        entity: record.entity.name,
        entityId: record.entity.id,
      }));
    },
    handleCloseDrawer() {
      this.isCreateClientVisible = false;
      this.selectedItem = {};
    },
    handleOpenCreateClient(record) {
      this.isCreateClientVisible = true;
      this.selectedItem = record;
    },
  },
});
</script>

<style lang="sass" scoped>
h2
  text-align: center
  font-weight: 600
  font-size: 20px

.section-footer
  display: flex
  flex-direction: column
  justify-content: space-between
  height: calc(100% - 145px)

.paragraph
  margin: 0 0 8px 0
  color: $gray-7
  font-weight: 400

header
  display: flex
  align-items: center
  gap: 8px

.button
  padding: 0

  &.cancel
    padding-left: 30px
    padding-right: 30px

h3
  margin: 0
  font-size: 16px

.divider
  background-color: $gray-4
  width: calc(100% + 32px)
  position: relative
  left: -16px

.form
  max-width: 420px
  margin: 0 auto

footer
  text-align: center

.footer
  display: flex
  align-items: center
  justify-content: center
  padding: 12px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px
    width: 200px

.form__wrapper
  margin-bottom: 50px
</style>
