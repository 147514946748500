const getters = {
  getTrunkList(state) {
    return [
      {
        voip_provider: null,
        name: "Sin troncal VOIP",
        id: null,
        dialing_plans: [""],
      },
      ...state.trunksInfo.items,
    ];
  },
  getTrunkInfo(state) {
    return {
      items: state.trunksInfo.items,
      pagination: state.trunksInfo.pagination,
    };
  },
  getTrunk(state) {
    return state.trunk;
  },
};
export default getters;
