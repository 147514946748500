import httpManager from "../../../../shared/utils/httpManager";

/**
 * Obtener contacto.
 *
 * @param {Number} id
 * @return {Promise<Object>} Contacto
 */
export async function fetchContactCustomFields(id) {
  const url = `/contacts/clients/${id}/custom-fields/`;
  const response = await httpManager.get(url);

  return response.data;
}
