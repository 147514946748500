<template>
  <a-tooltip :title="isGlobalDownloading ? 'Tiene una descarga pendiente' : null">
    <a-button
      type="link"
      class="delete"
      @click="handleDownload"
      :loading="downloading"
      :disabled="isGlobalDownloading"
    >
      Descargar
    </a-button>
  </a-tooltip>
</template>

<script setup>
import { ref } from "vue";
import { message } from "ant-design-vue";

import { downloadRecord } from "@/app/supervision/services";
import { useGlobalDownloadState } from "@/app/shared/composables/useGlobalDownloadState";

const props = defineProps({
  record: Object,
});

const downloading = ref(false);
const { isGlobalDownloading } = useGlobalDownloadState();

const handleDownload = async () => {
  downloading.value = true;

  try {
    const cdr = props.record;

    const blob = await downloadRecord(cdr.id);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    const name = cdr.audio.substring(cdr.audio.lastIndexOf("/") + 1, cdr.audio.lastIndexOf("."));
    const filename = `${name}.mp3`;
    console.log(name);
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    message.success("Audio descargado correctamente");
  } catch (e) {
    message.warning("Algo salió mal, por favor, vuelve a intentarlo");
  }

  downloading.value = false;
};
</script>

<style scoped lang="sass">
.delete
  padding: 0
</style>
