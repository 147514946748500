<template>
  <SubmenuMainContainer v-model:currentMenu="currentMenu" :menus="menus">
    <template #[SectionKey.Tags]>
      <PlatformSettingsTemplate />
    </template>

    <template #[SectionKey.QuickAnswers]>
      <QuickAnswersTemplate />
    </template>

    <template #[SectionKey.SendingSettings]>
      <SendingSettingsTemplate
        v-if="customerData"
        v-model:enable_enter="customerData.enable_enter"
      />
    </template>

    <template #[SectionKey.ApiIntegration]>
      <ApiKeyTemplate v-if="customerData" :token="customerData.api_token ?? ''" />
    </template>

    <template #[SectionKey.CallbackIntegration]>
      <CallbackConfig
        v-if="customerData"
        :callbackUrl="customerData.api_url_callback ?? ''"
        :token="customerData.token_url_callback"
        :disabled="customerData.status_callback === CallbackEventStatus.Inactive"
      />
    </template>

    <template #[SectionKey.BlackList]>
      <BlackListTemplate />
    </template>

    <template #[SectionKey.UnSubscriber]>
      <UnsubscriberTemplate />
    </template>
  </SubmenuMainContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, provide } from "vue";
import { ThunderboltOutlined } from "@ant-design/icons-vue";

import { SubmenuData } from "@/@types/shared/pickers";
import { Customer } from "@/@types/global";
import { fetchLoggedBusiness } from "@/app/settings/services";
import { apiService } from "@/app/platformSettings/services/ApiService";
import { CallbackEventStatus } from "@/@types/platformSettings/callbacks";

import SubmenuMainContainer from "@/app/shared/components/organisms/SubmenuMainContainer.vue";
import PlatformSettingsTemplate from "@/app/platformSettings/components/template/PlatformSettingsTemplate.vue";
import QuickAnswersTemplate from "@/app/platformSettings/components/template/QuickAnswersTemplate.vue";
import SendingSettingsTemplate from "@/app/platformSettings/components/template/SendingSettingsTemplate.vue";
import ApiKeyTemplate from "@/app/platformSettings/components/template/ApiKeyTemplate.vue";
import CallbackConfig from "@/app/platformSettings/components/template/CallbackConfig.vue";
import BlackListTemplate from "@/app/platformSettings/components/template/BlackListTemplate.vue";
import UnsubscriberTemplate from "@/app/platformSettings/components/template/UnsubscriberTemplate.vue";
import { BlackListTabText, BlackListTabIcon } from "@/app/platformSettings/utils/enums";

import tagsImage from "@/app/platformSettings/assets/tags.svg";
import keyImage from "@/app/settingsMenu/assets/key.svg";
import codeImage from "@/app/platformSettings/assets/code.svg";
// import blockImage from "@/app/platformSettings/assets/block.svg";
import emailImage from "@/app/platformSettings/assets/email.svg";

enum MenuKey {
  Workspace = "workspace",
  ApiConfig = "api-config",
  UnSubscriber = "un-subscriber",
  BlackList = "black-list",
}

enum SectionKey {
  Tags = "tags",
  QuickAnswers = "quick-answers",
  SendingSettings = "sending-settings",
  ApiIntegration = "api-integration",
  CallbackIntegration = "callback-integration",
  UnSubscriber = "un-subscriber",
  BlackList = "black-list",
}

const currentMenu = ref(MenuKey.Workspace);
const customerData = ref<Customer>();

const fetchCustomer = async () => {
  customerData.value = await fetchLoggedBusiness();
};

const currentTabBlacklist = ref<number>(1);
provide("currentTabBlackList", currentTabBlacklist);

onMounted(() => {
  fetchCustomer();
});

const menus = computed<SubmenuData<MenuKey, SectionKey>[]>(() => [
  {
    name: "Workspace",
    key: MenuKey.Workspace,
    sections: [
      {
        title: "Etiquetas",
        key: SectionKey.Tags,
        icon: tagsImage,
      },
      {
        title: "Respuestas rápidas",
        key: SectionKey.QuickAnswers,
        icon: ThunderboltOutlined,
      },
      {
        title: "Configuración de envío de mensajes",
        key: SectionKey.SendingSettings,
        icon: ThunderboltOutlined,
      },
    ],
  },
  {
    name: "API Key & Callback",
    key: MenuKey.ApiConfig,
    sections: [
      {
        title: "Integración de API Key",
        key: SectionKey.ApiIntegration,
        icon: keyImage,
      },
      {
        title: "Integración de Callbacks",
        key: SectionKey.CallbackIntegration,
        icon: codeImage,
        isActive: customerData.value?.status_callback === CallbackEventStatus.Active,
        onActiveChange: async (active) => {
          console.log("onActiveChange", active);
          await apiService.updateCallbackIntegration(
            active ? CallbackEventStatus.Active : CallbackEventStatus.Inactive
          );
          await fetchCustomer();
        },
      },
    ],
  },
  {
    name: "Lista Negra",
    key: MenuKey.BlackList,
    sections: [
      {
        title: BlackListTabText[currentTabBlacklist.value],
        key: SectionKey.BlackList,
        // icon: require("@/app/platformSettings/assets/sms.svg"),
        icon: new URL(`../assets/${BlackListTabIcon[currentTabBlacklist.value]}`, import.meta.url)
          .href,
      },
    ],
  },
  {
    name: "Desuscriptores",
    key: MenuKey.UnSubscriber,
    sections: [
      // {
      //   title: "Lista negra",
      //   key: SectionKey.BlackList,
      //   icon: blockImage,
      // },
      {
        title: "Desuscriptores",
        key: SectionKey.UnSubscriber,
        icon: emailImage,
      },
    ],
  },
]);

onMounted(async () => {
  customerData.value = await fetchLoggedBusiness();
});
</script>

<style lang="sass" scoped>
.page
  display: flex
  height: 100%

.menu
  margin: 12px 0 !important
  padding-top: 10px !important
  border: 1px solid $gray-5 !important
  border-left: none !important
  border-radius: 0 10px 10px 0
  flex: 0 0 220px

.main
  flex: 1 1 auto
  padding: 20px

  .title
    font-size: 20px
    font-weight: 600
    line-height: 20px
    margin: 0

.divider
  margin: 20px 0
  background-color: $gray-4
</style>
