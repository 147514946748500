import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener datos del cliente dado el id de ticket.
 * @param {number} ticketId Identificador del ticket.
 * @param {boolean} preview Si está activo solo carga lo básico.
 */
export async function fetchWorkspaceClientByTicket(ticketId, preview = false) {
  const requests = [
    getPersonalDataArrayModified(ticketId), // personal
    getPersonalColumnsArray(ticketId), // personal Columns
  ];

  if (!preview) {
    const fullRequests = [
      getPersonalDataArray(ticketId), //ticket info,
      getProductsArray(ticketId), // products
      getProductsColumnsArray(ticketId), // products_columns
      getPhonesArray(ticketId), // phones
      getAddressesArray(ticketId), // addresses
      getEmailsArray(ticketId), // emails
      getContactsArray(ticketId), // contacts
      getCustomsArray(ticketId), // customs
      getCustomsColumnsArray(ticketId), // customs_columns
      getPaymentsArray(ticketId), // payments
      getPromisesArray(ticketId), //promises
      // getInfoTabEmails(ticketId), // tab emails
    ];
    requests.push(...fullRequests);
  }

  console.log(requests, "REQUEST FETCH WORKSPACE");
  const [
    personal,
    personal_columns,
    data_client,
    products,
    products_columns,
    phones,
    addresses,
    emails,
    contacts,
    customs,
    customs_columns,
    payments,
    promises,
    // tab_email,
  ] = await Promise.allSettled(requests);
  return {
    id: data_client.value?.[0]?.id,
    data_client: data_client.value,
    personal: personal.value,
    personal_columns: personal_columns.value,
    products: products.value,
    products_columns: products_columns.value,
    phones: phones.value ?? [],
    addresses: addresses.value ?? [],
    emails: emails.value ?? [],
    contacts: contacts.value ?? [],
    customs: [customs.value] ?? [],
    customs_columns: customs_columns.value ?? [],
    payments: payments.value ?? [],
    promises: promises.value ?? [],
    // tab_email: tab_email.value ?? {},
  };
}

async function getPersonalDataArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/`;
  const response = await httpManager.get(url);
  const { client, person } = response.data;
  return [{ ...person, ...client }];
}
async function getPersonalDataArrayModified(ticketId) {
  const url = `/workspace/tickets/${ticketId}/clients/general-values/`;
  const response = await httpManager.get(url);
  console.log(response, "getPersonalDataArrayModified");
  return response.data;
}

async function getProductsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/accounts`;
  const response = await httpManager.get(url);
  return response.data.results;
}

async function getProductsColumnsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/account-fields`;
  const response = await httpManager.get(url);
  return response.data.map((item) => ({
    title: item.title,
    dataIndex: item.index,
    // with: 200,
  }));
}

async function getPhonesArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/phones/`;
  const response = await httpManager.get(url);
  return response.data.results;
}

async function getAddressesArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/addresses/`;
  const response = await httpManager.get(url);
  return response.data.results;
}

export async function getEmailsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/emails/`;
  const response = await httpManager.get(url);
  return response.data.results;
}

async function getCustomsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/clients/custom-values`;
  const response = await httpManager.get(url);
  return response.data;
}

async function getCustomsColumnsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/clients/custom-fields/`;
  const response = await httpManager.get(url);
  return response.data.map((item) => ({
    title: item.title,
    dataIndex: item.index,
  }));
}

async function getPaymentsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/payments/`;
  const response = await httpManager.get(url);
  return response.data.results;
}
async function getPromisesArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/promises/`;
  const response = await httpManager.get(url);
  return response.data.results;
}
async function getPersonalColumnsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/clients/general-fields/`;
  const response = await httpManager.get(url);
  return response.data;
}
async function getContactsArray(ticketId) {
  const url = `/workspace/tickets/${ticketId}/contacts/`;
  const response = await httpManager.get(url);
  return response.data;
}

export async function getInfoTabEmails(ticketId, pagination = {}) {
  const tablePageSize = 30;

  const offset = (pagination?.current - 1 || 0) * tablePageSize;
  const pageSize = pagination?.pageSize || tablePageSize;

  const url = `/workspace/tickets/${ticketId}/email_messages/?offset=${offset}&limit=${pageSize}`;
  const { data } = await httpManager.get(url);
  const results = data.results.map((el) => ({
    ...el,
    created_at: el.last_email_message.email_created_at,
    identifier: el.last_email_message.channel_customer.identifier,
  }));
  return { ...data, results: results };
}
