import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un correo.
 *
 * @param {number} emailId Identificador del correo.
 */
export async function deleteEmailContact(emailId) {
  await httpManager.delete(`/contacts/clients/emails/${emailId}/`);
}
