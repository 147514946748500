import {
  fetchContacts,
  fetchContact,
  fetchAdvancedFilters,
  applyAdvancedFilters,
  changeStatusContact,
  updatePhonesContact,
  fetchCustomerPersonalField,
  fetchCustomerPersonalData,
  createPhoneContact,
  fetchContactCustomData,
  fetchContactCustomFields,
  createEmailContact,
  fetchEmailContact,
  updateEmailContact,
  fetchPhoneContact,
  fetchAddressContact,
  createAddressContact,
  updateAddressContact,
  deletePhoneContact,
  deleteAddressContact,
  deleteEmailContact,
  fieldsProductContact,
  fetchProductContact,
  fetchPaymentsClient,
  fetchPromisesClient,
  fetchTypificationsClient,
  fetchRecordsClient,
  fetchReferralsClient,
  createReferralsContact,
  deleteReferralsContact,
  updateReferralsContact,
  updateInformationCustom,
  updateInformationPersonal,
  filtersTypifications,
  downloadContactByFilters,
  fetchRecordTicketClient,
  fetchTabEmailContact,
  // fetchRecordMessageTicketClient,
} from "../../services";

import { fetchHistoryByTicket, fetchMessagesByTicket } from "@/app/workspace/services";

import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

import {
  REMOVE_CONTACT,
  UPDATE_PHONES_CONTACT,
  SET_PERSONAL_INFORMATION,
  SET_CUSTOMER_PERSONAL_DATA,
  ADD_PHONES_CONCTACT,
  SET_CUSTOM_FIELDS,
  SET_CUSTOM_DATA,
  ADD_EMAIL_CONTACT,
  SET_CONTACT_EMAIL,
  UPDATE_EMAIL_CONTACT,
  SET_CONTACT_PHONE,
  SET_CONTACT_ADDRESS,
  ADD_ADDRESS_CONTACT,
  UPDATE_ADDRESS_CONTACT,
  REMOVE_PHONE_CONTACT,
  REMOVE_ADDRESS_CONTACT,
  REMOVE_EMAIL_CONTACT,
  SET_PRODUCTS_FIELDS_CONTACT,
  SET_PRODUCTS_DATA,
  SET_PAYMENTS_DATA,
  SET_PROMISES_DATA,
  SET_TYPIFICATIONS_DATA,
  SET_RECORDS_DATA,
  SET_REFERRALS_DATA,
  ADD_REFERRALS_CONTACT,
  REMOVE_REFERRALS_CONTACT,
  UPDATE_REFERRALS_CONTACT,
  UPDATE_INFORMATION_CONTACT,
  UPDATE_INFORMATION_PERSONAL,
  SET_FILTERS_TYPIFICATIONS,
  SET_RECORD_TICKET_CLIENT,
  SET_RECORD_MESSAGE_TICKET,
  RESET_STATE_TICKET,
  DETAIL_RECORD_TICKET,
  SET_EMAILS_TAB,
} from "./mutation-types";

const actions = {
  async fetchContacts({ commit }, { filters, pagination }) {
    console.log(filters, 'filters fetchContacts');
    const data = await fetchContacts(filters, pagination);

    commit("SET_CONTACTS", {
      count: data.count,
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async fetchContact({ commit }, id) {
    const item = await fetchContact(id);
    commit("SET_CONTACT", item);
  },
  async fetchAdvancedFilters({ commit }, filters) {
    const item = await fetchAdvancedFilters(filters);
    commit("SET_ADVANCED_FILTER", item);
  },
  async applyAdvancedFilters({ commit }, { filters, pagination }) {
    console.log(filters, 'filters advanced');
    const items = await applyAdvancedFilters(filters, pagination);

    commit("SET_CONTACTS", {
      count: items.count,
      items: items.results,
      pagination: {
        total: items.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },

  async fetchContactsExtern({ dispatch, state }) {
    const basicFilters = state.basicFilters;
    const pagination = { ...state.contacts.pagination, current: state.currentTablePage };
    const advancedFilters = state.advancedFilters;
    const filters = { ...basicFilters, page: pagination };
    
    console.log(basicFilters, 'basic filters');
    if (advancedFilters.groups?.length > 0 && basicFilters.campaign && basicFilters.coverage === "True") {
      await dispatch('applyAdvancedFilters', { filters, pagination });
    } else {
      await dispatch('fetchContacts', { filters: basicFilters, pagination })
    }
  },

  async fetchChangeStatusContact({ dispatch, commit }, { contactId, status }) {
    return await changeStatusContact(contactId, status);
    // commit(REMOVE_CONTACT, contactId);
  },
  async updatePhonesContact({ commit }, { id, phones }) {
    const dataPhone = await updatePhonesContact(id, phones);
    commit(UPDATE_PHONES_CONTACT, dataPhone);
  },
  async fetchCustomerPersonalField({ commit }, id) {
    const item = await fetchCustomerPersonalField(id);
    commit(SET_PERSONAL_INFORMATION, item);
  },
  async fetchCustomerPersonalData({ commit }, id) {
    const item = await fetchCustomerPersonalData(id);
    commit(SET_CUSTOMER_PERSONAL_DATA, item);
  },
  async addPhonesContact({ commit }, { contactId, phones }) {
    const dataPhone = await createPhoneContact(contactId, phones);
    commit(ADD_PHONES_CONCTACT, dataPhone);
  },
  async fetchContactCustomFields({ commit }, id) {
    const item = await fetchContactCustomFields(id);
    commit(SET_CUSTOM_FIELDS, item);
  },
  async fetchContactCustomData({ commit }, id) {
    const item = await fetchContactCustomData(id);
    commit(SET_CUSTOM_DATA, item);
  },
  async fetchEmailContact({ commit }, id) {
    const data = await fetchEmailContact(id);
    commit(SET_CONTACT_EMAIL, {
      items: data.results,
    });
  },
  async createEmailContact({ commit }, { emailId, body }) {
    const item = await createEmailContact(emailId, body);
    commit(ADD_EMAIL_CONTACT, item);
  },
  async updateEmailContact({ commit }, { emailId, body }) {
    const item = await updateEmailContact(emailId, body);
    commit(UPDATE_EMAIL_CONTACT, item);
  },
  async fetchPhoneContact({ commit }, { id }) {
    const data = await fetchPhoneContact(id);
    commit(SET_CONTACT_PHONE, {
      items: data,
    });
  },
  async deletePhoneContact({ commit }, { id, client }) {
    await deletePhoneContact(id, client);
    commit(REMOVE_PHONE_CONTACT, id);
  },
  async fetchAddressContact({ commit }, id) {
    const data = await fetchAddressContact(id);
    commit(SET_CONTACT_ADDRESS, {
      items: data.results,
    });
  },
  async createAddressContact({ commit }, { addressId, body }) {
    const item = await createAddressContact(addressId, body);
    commit(ADD_ADDRESS_CONTACT, item);
  },
  async updateAddressContact({ commit }, { addressId, body }) {
    const item = await updateAddressContact(addressId, body);
    commit(UPDATE_ADDRESS_CONTACT, item);
  },
  async deleteAddressContact({ commit }, id) {
    await deleteAddressContact(id);
    commit(REMOVE_ADDRESS_CONTACT, id);
  },
  async deleteEmailContact({ commit }, id) {
    await deleteEmailContact(id);
    commit(REMOVE_EMAIL_CONTACT, id);
  },

  async fieldsProductContact({ commit }, id) {
    const item = await fieldsProductContact(id);
    commit(SET_PRODUCTS_FIELDS_CONTACT, item);
  },

  async fetchProductContact({ commit }, id) {
    const item = await fetchProductContact(id);
    commit(SET_PRODUCTS_DATA, item);
  },

  async fetchPaymentsClient({ commit }, id) {
    const item = await fetchPaymentsClient(id);
    commit(SET_PAYMENTS_DATA, item);
  },
  async fetchPromisesClient({ commit }, id) {
    const item = await fetchPromisesClient(id);
    commit(SET_PROMISES_DATA, item);
  },
  async fetchTypificationsClient({ commit }, id) {
    commit(SET_TYPIFICATIONS_DATA, []);
    const data = await fetchTypificationsClient(id);
    commit(SET_TYPIFICATIONS_DATA, {
      items: data.results,
    });
  },

  async fetchRecordsClient({ commit }, id) {
    const data = await fetchRecordsClient(id);
    console.log(data, "DATOSSS");
    commit(SET_RECORDS_DATA, {
      items: data.results,
    });
  },

  async fetchReferralsClient({ commit }, id) {
    const data = await fetchReferralsClient(id);
    commit(SET_REFERRALS_DATA, {
      items: data.results,
    });
  },
  async createReferralsContact({ commit }, { referralsId, body }) {
    const item = await createReferralsContact(referralsId, body);
    commit(ADD_REFERRALS_CONTACT, item);
  },
  async deleteReferralsContact({ commit }, id) {
    await deleteReferralsContact(id);
    commit(REMOVE_REFERRALS_CONTACT, id);
  },
  async updateReferralsContact({ commit }, { referralsId, body }) {
    const item = await updateReferralsContact(referralsId, body);
    commit(UPDATE_REFERRALS_CONTACT, item);
  },
  async updateInformationCustom({ commit }, { contactId, body }) {
    const dataCustom = await updateInformationCustom(contactId, body);
    commit(UPDATE_INFORMATION_CONTACT, [dataCustom]);
  },
  async updateInformationPersonal({ commit }, { contactId, body }) {
    const dataCustom = await updateInformationPersonal(contactId, body);
    commit(UPDATE_INFORMATION_PERSONAL, [dataCustom]);
  },
  async filtersTypifications({ commit }, contactId) {
    const item = await filtersTypifications(contactId);
    commit(SET_FILTERS_TYPIFICATIONS, item);
  },

  async downloadContactByFilters({ commit }, { filters, pagination }) {
    const data = await downloadContactByFilters(filters, pagination);
    commit("SET_CONTACTS", data);
  },

  async fetchRecordTicketClient({ commit }, ticketId) {
    const item = await fetchRecordTicketClient(ticketId);
    commit(SET_RECORD_TICKET_CLIENT, item);
    commit(DETAIL_RECORD_TICKET, item);
  },
  async fetchRecordMessageTicketClient({ commit }, { ticketId, channelPersonId }) {
    // const data = await fetchRecordMessageTicketClient(ticketId, channelPersonId);
    const history = channelPersonId
      ? await fetchHistoryByTicket(ticketId, channelPersonId)
      : await fetchMessagesByTicket(ticketId);

    commit(SET_RECORD_MESSAGE_TICKET, history);
  },
  resetRecordTicket({ commit }) {
    commit(RESET_STATE_TICKET);
  },
  async setEmailsTab({ commit }, { contactId, pagination }) {
    // const dataCustom = await fetchTabEmailContact(22927, pagination);
    const dataCustom = await fetchTabEmailContact(contactId, pagination);
    commit(SET_EMAILS_TAB, dataCustom);
  },
};

export default actions;
