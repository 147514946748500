<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8332 6.00003C11.8332 2.77837 9.22151 0.16669 5.99985 0.16669C2.77818 0.16669 0.166504 2.77837 0.166504 6.00003C0.166504 8.73575 2.04986 11.0312 4.59065 11.6616V7.78261H3.38777V6.00003H4.59065V5.23192C4.59065 3.24644 5.48917 2.32617 7.43844 2.32617C7.808 2.32617 8.44567 2.39862 8.70654 2.47109V4.08702C8.56885 4.07253 8.32973 4.06528 8.03263 4.06528C7.07612 4.06528 6.70654 4.4276 6.70654 5.36961V6.00003H8.61192L8.2846 7.78261H6.70654V11.7906C9.59489 11.4418 11.8332 8.98246 11.8332 6.00003Z"
      fill="#0866FF"
    />
    <path
      d="M8.28453 7.78258L8.61187 6H6.70649V5.36956C6.70649 4.42754 7.07605 4.06525 8.03255 4.06525C8.32966 4.06525 8.56878 4.07248 8.70647 4.08697V2.47106C8.4456 2.39859 7.80792 2.32612 7.43837 2.32612C5.48912 2.32612 4.59058 3.24641 4.59058 5.23189V6H3.3877V7.78258H4.59058V11.6616C5.04191 11.7735 5.51383 11.8333 5.99977 11.8333C6.23903 11.8333 6.47479 11.8186 6.70649 11.7906V7.78258H8.28453Z"
      fill="white"
    />
  </svg>
</template>
