<template>
  <label class="picker" :class="{ 'picker--error': error, preview: isPreview }">
    <div
      v-if="hasImage"
      class="picker__container picker__container--placeholder"
      :class="{ small: isSmall }"
    >
      <div
        v-if="isEntity || isBusiness"
        class="picker__preview"
        :class="{ image__default: selectedImage, agent: preview.includes('smile') }"
        :style="`background-image: url('${preview}'); background-size: ${
          preview.includes('http') ? 'cover' : 'cover'
        }`"
      />
      <div
        v-else-if="isProfile"
        class="picker__preview"
        :class="userData?.role.name === 'Agente' ? 'user__agente' : ''"
        :style="`background-image: url('${preview}'); background-size: ${
          preview.includes('https') ? 'cover' : 'auto'
        }`"
      />
      <div
        v-else-if="isWhatsappPicture"
        class="picker__preview"
        :class="{ image__default: selectedImage, agent: preview.includes('smile') }"
        :style="`background-image: url('${preview}'); background-size: cover`"
      />
      <div
        v-else-if="selectedImage || userData.picture?.length > 0"
        class="picker__preview"
        :class="{ image__default: selectedImage, agent: preview.includes('smile') }"
        :style="`background-image: url('${preview}'); background-size: ${
          preview.includes('http') ? 'cover' : 'cover'
        }`"
      />
    </div>

    <div v-else class="picker__container" :class="{ small: isSmall }">
      <div class="picker__content" :class="{ whatsapp: isWhatsappPicture }">
        <template v-if="isWhatsappPicture">
          <CameraOutlined class="icon" />
        </template>
        <!-- <img src="@/app/entities/assets/briefcase-blue.svg" class="picker__image" /> -->
        <!-- <a-icon class="picker__icon" type="upload" size="large" /> -->
        <!-- <span class="picker__text">Subir imagen</span> -->
      </div>
    </div>

    <input type="file" @change="handleFileChange" :accept="supportedTypes.join(',')" />
  </label>
</template>

<script>
import { defineComponent } from "vue";
import { CameraOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CameraOutlined,
  },
  props: {
    value: {
      required: false,
      type: File,
      default: null,
    },
    src: {
      required: false,
      type: String,
      default: "",
    },

    isBusiness: {
      required: false,
      type: Boolean,
    },
    selectedImage: {
      required: false,
      type: Boolean,
    },
    isEntity: {
      required: false,
      type: Boolean,
    },
    isProfile: {
      required: false,
      type: Boolean,
    },
    isWhatsappPicture: {
      required: false,
      type: Boolean,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: this.src,
      error: false,
    };
  },
  watch: {
    selectedImage(value) {
      if (value === true) {
        this.$emit("onCancelProfile", false);
      }
    },
    value() {
      if (!this.isPreview) return;

      this.imageToBase64(this.value);
    },
  },
  computed: {
    preview() {
      return this.value ? this.image : this.src ? this.src : "";
    },
    hasImage() {
      return !!this.preview;
    },
    supportedTypes() {
      return ["image/png", "image/jpeg", "image/jpg"];
    },
    userData() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!this.validateType(file)) return;
      this.imageToBase64(file);
      this.$emit("update:value", file);
      this.$emit("onSelectedImage", true);
      this.$emit("onSelectedImageEntity", true);
    },
    validateType(file) {
      if (!this.supportedTypes.includes(file.type)) {
        this.image = "";
        this.error = true;
        return false;
      }
      this.error = false;
      return true;
    },
    imageToBase64(file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => (this.image = reader.result));
      reader.readAsDataURL(file);
    },
  },
});
</script>

<style lang="sass" scoped>
.picker
  cursor: pointer

  &.preview
    pointer-events: none

  &--error &__container
    color: $red-6
    border: 1px dashed $red-6

  &__container
    width: 92px
    height: 92px
    color: $gray-7
    font-size: 12px
    border-radius: 50%
    margin: 0 auto 12px auto
    position: relative

    &:hover::after
      content: ''
      background: #0000004f
      background-image: url("../../../entities/assets/edit.svg") !important
      background-repeat: no-repeat
      background-position: center
      position: absolute
      left: 0
      right: 0
      top: 0
      width: 100%
      height: 100%
      border-radius: 50%
      zoom: 0

    &--placeholder
      color: $blue-6
      border: none

      &:hover
        border: none

      & .picker__content
        position: relative
        z-index: 2
        color: $blue-6
        border: none
        background: rgba(255, 255, 255, 0.8)

  &__preview
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-position: center
    background-repeat: no-repeat
    border-radius: 50%

  &__content
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  &__icon
    font-size: 20px
    margin-bottom: 8px

.small
  width: 64px
  height: 64px
  margin: 0 auto

label input[type="file"]
  display: none

.picker__image
  margin-top: -6px

.agent
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.user__profile
  margin: 6px
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.user__agente
  margin: 6px
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.noneFilter
  filter: none !important

.noneBackground
  background: none

.whatsapp
  background-color: $gray-3
  border: 1px dashed $gray-6
  border-radius: 50%

.icon
  font-size: 24px
</style>
