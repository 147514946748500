<template>
  <a-popover
    v-model:visible="visible"
    trigger="click"
    placement="rightBottom"
    overlay-class-name="select-popover"
  >
    <template #content>
      <div>
        <!-- Botones de acciones del evento seleccionado-->
        <div class="icon-header">
          <!--boton de editar evento-->
          <a-tooltip placement="top">
            <template #title>
              <span>Editar</span>
            </template>
            <EditReminder :record="item" @hide="hide" @update="handleUpdate" />
          </a-tooltip>
          <!--boton para resolver evento-->
          <a-tooltip placement="top">
            <template #title>
              <span>Resolver</span>
            </template>

            <CheckCircleOutlined class="icon-options" @click="handleResolveReminder(item)" />
          </a-tooltip>
          <!--boton para eliminar evento-->
          <a-tooltip placement="top">
            <template #title>
              <span>Eliminar</span>
            </template>
            <DeleteOutlined class="icon-options" @click="handleDeleteReminder(item)" />
          </a-tooltip>
          <!--boton para cerrar el popover-->
          <a-tooltip placement="top">
            <template #title>
              <span>Cerrar</span>
            </template>
            <CloseOutlined class="icon-options" @click="hide" />
          </a-tooltip>
        </div>
        <!--contenido de popover-->
        <div class="">
          <div class="flex-row">
            <div><div :style="getColor(item.color)" class="badge" /></div>
            <p class="title">{{ item.title }}</p>
          </div>
          <div class="flex-row">
            <ClockCircleOutlined class="icon-content" />
            <p class="title">
              {{ timeReminders }} {{ dateTimeToSlashFormat(item.date_schedule, false) }}
            </p>
          </div>
          <div v-if="item.ticket" class="flex-row">
            <UserOutlined class="icon-content" />
            <p class="title">
              {{ isClient }}
            </p>
          </div>

          <div class="flex-row">
            <AlignLeftOutlined class="icon-content" />
            <p class="title">{{ item.description }}</p>
          </div>
        </div>
      </div>

      <div class="footer-popover" v-if="item.ticket">
        <a-divider class="footer-divider" />

        <div class="footer-no-resolved" v-if="item.ticket_state !== TicketState.finished">
          <span class="ticket-info">Ticket ID: #{{ item.ticket }}</span>
          <a-button class="footer-button" type="link" @click="handleGoToWorkspace">
            Ir a Workspace
          </a-button>
        </div>

        <div class="footer-resolved" v-else>
          <a-button class="footer-button" type="link" @click="handleOpenDrawer">
            Ver contacto
          </a-button>
        </div>
      </div>
    </template>

    <!--lista los eventos-->
    <a-tag :color="item.color" class="tag-event" @click.stop="handleSchedule(item)">
      <CalendarOutlined v-if="item.type == TypeReminder.event" />
      <ClockCircleOutlined v-if="item.type == TypeReminder.reminder" />
      {{ timeReminders }}, {{ item.title }}
    </a-tag>
  </a-popover>

  <contact-detail-drawer
    fromSchedule
    v-if="drawerVisible"
    :itemId="item.client"
    :visible="drawerVisible"
    :campaignName="item.portfolio.name"
    @on-close-drawer="handleCloseDrawer"
  />
</template>

<script>
import { createVNode } from "vue";

import EditReminder from "@/app/schedule/components/modal/EditReminder.vue";
import ContactDetailDrawer from "@/app/contacts/components/drawers/ContactDetailDrawer.vue";
import { TypeReminder } from "@/app/schedule/utils/enum";
import { TicketState } from "@/app/shared/utils/enums";
import { dateTimeToSlashFormat } from "@/app/shared/utils/dates";

import {
  CloseOutlined,
  AlignLeftOutlined,
  UserOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "PopoverShedule",
  components: {
    EditReminder,
    ContactDetailDrawer,
    CloseOutlined,
    AlignLeftOutlined,
    UserOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    DeleteOutlined,
    CalendarOutlined,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    visible: false,
    drawerVisible: false,
    style: {
      width: "220px",
    },
    TypeReminder,
    TicketState,
    dateTimeToSlashFormat,
  }),
  computed: {
    timeReminders() {
      const timeReminder = this.item.time_schedule.split(" ")[1];
      return timeReminder.slice(0, -3);
    },
    isClient() {
      return this.item.client_name ? this.item.client_name : this.item.user_name;
    },
  },
  methods: {
    hide() {
      this.visible = false;
    },
    handleSchedule() {
      this.visible = true;
    },
    handleOpenDrawer() {
      this.hide();
      this.drawerVisible = true;
    },
    handleCloseDrawer() {
      this.drawerVisible = false;
    },
    getColor(value) {
      return {
        backgroundColor: value,
      };
    },
    handleDeleteReminder(value) {
      this.hide();
      this.$confirm({
        title: `¿Estás seguro de eliminar el recordatorio “${this.item.title}”?`,
        content:
          "Recuerda que ya no se visualizará este recordatorio en tu agenda y tampoco se te enviará ninguna notificación o alerta relacionada.",
        icon: createVNode(QuestionCircleOutlined),
        centered: true,
        onOk: () => {
          this.$store.dispatch("deleteReminder", value.id);
          this.$message.success("Se ha eliminado el recordatorio correctamente");
        },
        okText: "Eliminar",
        cancelText: "Cancelar",
        class: "test",
      });
    },
    handleGoToWorkspace() {
      this.hide();
      // this.$router.push(`/workspace/${this.item.ticket}/${this.item.channel_person}`);
      this.$router.push({
        path: "/workspace",
        query: {
          ticket: this.item.ticket,
          client: this.item.client,
          interaction: this.item.interaction_id,
        },
      });
    },
    handleResolveReminder() {
      const obj = {
        status: 4,
      };

      this.$store.dispatch("updateReminder", {
        reminderId: this.item.id,
        reminder: obj,
      });
      // this.$store.dispatch("fetchAllReminder");
      this.handleUpdate();
    },
    handleUpdate() {
      this.$emit("update");
    },
  },
};
</script>
<style lang="sass" scoped>
.select-popover
  max-width: 275px !important
  .options
    display: flex

.tag-event
  width: calc(100% - 8px)
  height: 20px
  overflow: hidden
  text-overflow: ellipsis
  border-radius: 5px

.icon-header
  display: flex
  justify-content: flex-end
  margin-bottom: $margin-tiny
  .icon-options
    margin-left: $margin-small
    font-size: $font-medium

.flex-row
  display: flex
  align-items: center
  margin-bottom: 5px

  .title
    margin-bottom: 0
    margin-left: $margin-tiny
    word-break: break-word

  .badge
    border-radius: 50%
    border: none
    width: 12px
    height: 12px

.icon-content
  color: $gray-10
  font-size: $font-small

.footer-divider
  border-color: $gray-5
  width: calc(100% + 32px)
  margin: 10px 0 10px -16px

.footer-button
  padding-left: 0
  padding-right: 0
  text-align: right

.footer-no-resolved
  display: flex
  justify-content: space-between
  align-items: center

  .ticket-info
    flex-shrink: 0
    color: $gray-8
    margin-right: 20px

.footer-resolved
  text-align: right
</style>
