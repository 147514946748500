class TokenManager {
  constructor() {
    this.ACCESS_TOKEN_KEY = "access_token";
    this.REFRESH_TOKEN_KEY = "refresh_token";
  }

  getToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  existToken() {
    return (
      localStorage.getItem(this.ACCESS_TOKEN_KEY) !== null &&
      localStorage.getItem(this.ACCESS_TOKEN_KEY) !== ""
    );
  }

  saveToken(accessToken, refreshToken) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, accessToken);
    if (refreshToken) localStorage.setItem(this.REFRESH_TOKEN_KEY, refreshToken);
  }

  removeToken() {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }
}

export const tokenManager = new TokenManager();
