<template>
  <a-table
    :data-source="datatable"
    :columns="columns"
    :pagination="false"
    :scroll="scroll"
    row-key="id"
    size="middle"
  >
  </a-table>
</template>

<script>
import { callOnHoldTableColumns } from "@/app/supervision/utils/constants";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    datatable: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      columns: callOnHoldTableColumns,
    };
  },
  computed: {
    scroll() {
      return { y: this.windowHeight / 2 - 150 };
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
});
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
