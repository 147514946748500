<template>
  <div>
    <viewer-base-drawer
      :queue="record.queue"
      :portfolio="portfolio"
      :title="record.queue_name"
      slot-text="Llamadas en espera"
      @open="handleDrawerOpened"
      @update="fetchCalls"
    >
      <template v-slot:waiting>
        <CallOnHoldTable :datatable="calls" />
      </template>
    </viewer-base-drawer>
  </div>
</template>

<script>
import { fetchCallsWaitingByQueue } from "@/app/supervision/services";
import CallOnHoldTable from "../tables/tablesDrawer/CallOnHoldTable.vue";
import ViewerBaseDrawer from "./ViewerBaseDrawer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ViewerBaseDrawer,
    CallOnHoldTable,
  },
  props: {
    record: { required: true, type: Object },
    portfolio: { required: true, type: Number },
  },
  data() {
    return {
      calls: [],
    };
  },
  methods: {
    async fetchCalls() {
      this.calls = await fetchCallsWaitingByQueue(this.record.queue);
    },
    handleDrawerOpened() {
      this.fetchCalls();
    },
  },
});
</script>

<style lang="sass" scoped>
.table__agent
  margin-top: 12px
</style>
