export const columns = [
  {
    title: "Código de cuenta",
    dataIndex: "code_account",
    width: 120,
  },
  {
    title: "Fecha de pago",
    dataIndex: "date_payment",
    width: 150,
  },
  {
    title: "Monto del pago",
    dataIndex: "amount_payment",
    width: 150,
  },
  {
    title: "Tipo de moneda",
    dataIndex: "currency_payment",
    width: 150,
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    width: 120,
  },
  {
    title: "Agencia",
    dataIndex: "agency",
    width: 180,
  },
  {
    title: "Observación",
    dataIndex: "observation",
    width: 150,
  },
];
