import httpManager from "@/app/shared/utils/httpManager";

/**
 * Guardar una gestión.
 *
 * @param {number} params.strategy_phone Telefono de la estrategia.
 *
 * @return {Promise<Object>} Datos de la gestión.
 */
export async function saveManagement(params) {
  console.log("MANAGEMENT", params);
  const url = "/workspace/managements/";
  const body = {
    group_pallete: params?.group_pallete,
    result_pallete: params?.result_pallete,
    motive_pallete: params?.motive_pallete,
    submotive_pallete: params?.submotive_pallete,
    ticket: Number(params?.ticket),
    observ_01: params?.comment || null,
    form: params?.form,
  };

  if (params?.phone_number) body.phone_number = params?.phone_number;
  if (params?.identifier) body.identifier = params?.identifier;
  if (params?.strategy_phone) body.strategy_phone = Number(params?.strategy_phone);
  // if (params?.channel_person) body.channel_person = Number(params?.channel_person);
  if (params?.interaction_id) body.interaction_id = Number(params?.interaction_id);
  if (params?.strategy) body.strategy = params?.strategy;
  if (params?.channel_customer_identifier)
    body.channel_customer_identifier = params?.channel_customer_identifier;
  if (params?.channel_customer) body.channel_customer = params?.channel_customer;
  if (params?.asterisk_id) body.asterisk_id = params?.asterisk_id;
  if (params?.type_interaction) body.type_interaction = params.type_interaction;
  if (params?.type_service) body.type_service = params.type_service;
  if (params?.ivr) body.ivr = params.ivr;
  if (params?.summary) body.summary = params.summary;
  if (params?.strategy_phone_id) body.strategy_phone = params.strategy_phone_id;

  const response = await httpManager.post(url, body);
  return response.data;
}
