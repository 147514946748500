import IconSchedule from "@/app/common/components/atoms/IconSchedule.vue";
import IconUsers from "@/app/common/components/atoms/IconUsers.vue";
import IconQueues from "@/app/common/components/atoms/IconQueues.vue";
import IconOperations from "@/app/common/components/atoms/IconOperations.vue";
import IconStrategies from "@/app/common/components/atoms/IconStrategies.vue";
import IconTickets from "@/app/common/components/atoms/IconTickets.vue";
import IconLock from "@/app/common/components/atoms/IconLock.vue";
import IconCamera from "@/app/common/components/atoms/IconCamera.vue";
import IconContactsBlue from "@/app/common/components/atoms/IconContactsBlue.vue";
import IconCredicard from "@/app/common/components/atoms/IconCredicard.vue";
import IconWhatsapp from "@/app/common/components/atoms/IconWhatsapp.vue";
import IconVoice from "@/app/common/components/atoms/IconVoice.vue";
import IconCloudCross from "@/app/common/components/atoms/IconCloudCross.vue";
import IconCloudDownload from "@/app/common/components/atoms/IconCloudDownload.vue";

const originIcon = {
  schedule: IconSchedule,
  profile: IconUsers,
  queues: IconQueues,
  portfolio: IconOperations,
  strategy: IconStrategies,
  tickets: IconTickets,
  ticket: IconTickets,
  lock: IconLock,
  camera: IconCamera,
  contactBlue: IconContactsBlue,
  billing: IconCredicard,
  templates: IconWhatsapp,
  voice: IconVoice,
  cloudCross: IconCloudCross,
  cloudDownload: IconCloudDownload,
};

export function mapIcon(origin) {
  return originIcon[origin];
}
