const getters = {
  getSelectedActiveUsersId(state) {
    return state.selectedActiveUsersId;
  },
  getStrategyInfo(state) {
    return {
      items: state.strategiesInfo.items,
      pagination: state.strategiesInfo.pagination,
    };
  },
  getTemplatesWhatsapp(state) {
    return state.templatesWhatsapp;
  },
  getAgentsStrategy(state) {
    return state.agents;
  },
  getProfilesGroupStrategy(state) {
    let profilesGroup = {};

    for (const user of state.filteredActiveUsers) {
      if (!profilesGroup[user.profile_name]) {
        profilesGroup[user.profile_name] = [
          {
            added: user.added,
            id: user.id,
            fullname: user.fullname,
            picture: user.picture,
            icon: user.icon,
            profile_name: user.profile_name,
          },
        ];
      } else {
        profilesGroup[user.profile_name].push({
          added: user.added,
          id: user.id,
          fullname: user.fullname,
          picture: user.picture,
          icon: user.icon,
          profile_name: user.profile_name,
        });
      }
    }
    return profilesGroup;
  },
};

export default getters;
