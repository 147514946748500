<template>
  <div class="button-container" v-if="type === 'management'">
    <a-tooltip
      :title="
        disabled
          ? 'Esta tipificación ya cuenta con una conclusión generada'
          : 'Genera una conclusión con inteligencia artificial.'
      "
    >
      <a-button
        class="ai-button"
        ghost
        type="primary"
        :disabled="disabled"
        :loading="loading"
        @click="handleGenerateToManagement"
      >
        <AISparksIcon :disabled="disabled" />
        <AIText :disabled="disabled"> AI </AIText>
      </a-button>
    </a-tooltip>
  </div>

  <a-tooltip title="Genera conclusión con inteligencia artificial." v-if="type === 'input'">
    <a-button type="text" class="button-input" @click="handleGenerateToChat">
      <AISparksIcon size="20" :disabled="disabled" />
      <AIText :disabled="disabled" class="text"> AI </AIText>
    </a-button>
  </a-tooltip>

  <div class="button-container history" v-if="type === 'history'">
    <a-button
      class="ai-button history"
      ghost
      type="primary"
      :disabled="disabled"
      @click="handleGenerateToHistory"
    >
      <AISparksIcon :disabled="disabled" />
      <AIText :disabled="disabled"> Generar conclusión con {{ CompanyName }} AI </AIText>
    </a-button>
  </div>
</template>

<script setup lang="ts">
import { createVNode, ref } from "vue";
import { useRoute } from "vue-router";
import { Modal, message } from "ant-design-vue";
import { InfoCircleFilled } from "@ant-design/icons-vue";

import AISparksIcon from "@/app/integrations/components/icons/AISparksIcon.vue";
import AIText from "@/app/integrations/components/labels/AIText.vue";
import { summaryService } from "@/app/workspace/services/SummaryService";
import { ResponseError } from "@/app/shared/utils/httpManager";
import EventBus from "@/app/shared/utils/eventBus";
import { CompanyName } from "@/app/shared/utils/constants";

const props = defineProps<{
  disabled: boolean;
  type: "management" | "input" | "history";
  ticketManagerId: number;
  interactionManagerId: number;
}>();

const emit = defineEmits<{
  generated: [generatedConclusion: string];
  updateDisabled: [isDisabled: boolean];
  summaryGeneratedId: [summaryGeneratedId: number];
}>();

const route = useRoute();

const loading = ref(false);
const summaryId = ref(0);

const handleGenerateToManagement = async () => {
  const ticketId = Number(route.query.ticket);
  const interactionId = Number(route.query.interaction);
  const originSummary = "workspace";

  loading.value = true;

  try {
    const response = await summaryService.getTypificationSummary(
      ticketId,
      interactionId,
      originSummary
    );
    if (response) {
      console.log(response, "RESPONSE SUMMARY COUNT");
      emit("generated", response.summary);
      emit("summaryGeneratedId", response.id);
      EventBus.emit("updateHistory");
      summaryId.value = response.id;
    }

    message.success("Se ha generado un resumen inteligente con éxito");
    emit("updateDisabled", true);
    // enableAIButton.value = true;
  } catch (error) {
    let errorMessage = "Ha ocurrido un error";
    if (error instanceof ResponseError) errorMessage = error.getErrorMessage();
    message.error(errorMessage);
  }

  loading.value = false;
};

const handleGenerateToChat = async () => {
  const interactionId = route.query.interaction;

  Modal.confirm({
    centered: true,
    icon: createVNode(InfoCircleFilled, { style: { color: "#1677FF" } }),
    title: "Genera una conclusión de tu conversación con inteligencia artificial",
    content:
      "Se procesarán 45 respuestas y se brindará una conclusión con 36 tokens, teniendo un costo aproximado de $2",
    okText: "Generar",
    onOk: async () => {
      console.log(interactionId);
    },
  });
};

const handleGenerateToHistory = async () => {
  const ticketId = props.ticketManagerId;
  const interactionId = props.interactionManagerId;

  const originSummary = "management";

  loading.value = true;

  try {
    const response = await summaryService.getTypificationSummary(
      ticketId,
      interactionId,
      originSummary
    );
    emit("generated", response.summary);
    EventBus.emit("updateChatHistory");
    message.success("Se ha generado un resumen inteligente con éxito");
    emit("updateDisabled", true);
    //enableAIButton.value = true;
  } catch (error) {
    let errorMessage = "Ha ocurrido un error";
    if (error instanceof ResponseError) errorMessage = error.getErrorMessage();
    message.error(errorMessage);
  }

  loading.value = false;
};
</script>

<style scoped lang="sass">
$hover-brightness: brightness(1.3)
$loading-opacity: opacity(0.6)

.button
  &-container
    width: fit-content
    position: relative
    z-index: 1

    &.history
      position: absolute
      width: 100%

  &-input
    padding: 0
    height: 24px
    display: flex
    align-items: center

    .text
      margin-left: 2px

.ai
  &-text
    background: linear-gradient(180deg, #D52FA1 40%, #7D2FCC 80%)
    background-clip: text
    -webkit-text-fill-color: transparent

  &-button
    background-clip: padding-box
    border: 1px solid transparent
    opacity: 1 !important
    position: relative
    background-color: white
    box-sizing: border-box
    display: flex
    align-items: center

    &.history
      width: 100%
      padding: 0
      height: 24px
      border-radius: 0
      justify-content: center

    &::before
      content: ''
      position: absolute
      display: block
      inset: -1px
      z-index: -1
      border-radius: inherit
      background: linear-gradient(180deg, #D62FA0, #762ECF)

    &:hover
      background-color: white !important
      border-color: transparent !important

      &:not(.ant-btn-loading)
        &::before
          filter: $hover-brightness

        > *
          filter: $hover-brightness

:deep(.ai-button .ant-btn-loading-icon)
  color: #D62FA0

.ant-btn-loading
  &::before
    filter: $loading-opacity

  > *
    filter: $loading-opacity
</style>
