<template>
  <a-form-item :label="label" name="entity" :required="required">
    <a-select
      :getPopupContainer="triggerNode ? (triggerNode) => triggerNode.parentNode : null"
      v-model:value="selected"
      class="select"
      placeholder="Seleccionar empresa"
      :disabled="disabled"
    >
      <a-select-option
        v-if="showAllOption"
        :disabled="blockAllEntities"
        :key="withOutEntities ? '' : defaultBehavior ? '' : 0"
      >
        <a-tooltip placement="right">
          <template #title v-if="blockAllEntities">
            Esta opción no está disponible mientras se listen “Todos los canales”.
          </template>
          Todas las empresas
        </a-tooltip>
      </a-select-option>

      <a-select-option v-for="item in entities" :key="item.id" :title="item.name">
        {{ item.name }}
      </a-select-option>

      <a-select-option v-if="withOutEntities" :key="0"> Sin empresa </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script>
import { fetchAllEntities } from "@/app/entities/services";

export default {
  props: {
    value: {
      required: true,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    blockAllEntities: {
      required: false,
      type: Boolean,
      default: false,
    },
    withOutEntities: {
      required: false,
      type: Boolean,
      default: false,
    },
    defaultBehavior: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    includeItem: {
      required: false,
    },
    toSequence: {
      required: false,
      type: Boolean,
      default: false,
    },
    triggerNode: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entities: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    showAllOption() {
      return this.showAll && this.$route.path !== "/reports/documents";
    },
  },
  async created() {
    await this.fetchEntities();
    if (this.includeItem) this.addItemEntity();
  },
  methods: {
    addItemEntity() {
      const index = this.entities.findIndex((el) => el.id == this.includeItem.id);
      if (index == -1) this.entities = [...this.entities, this.includeItem];
    },
    async fetchEntities() {
      const data = await fetchAllEntities({ toUsersAssigned: this.toSequence });
      this.entities = data.results;
    },
  },
};
</script>

<style lang="sass" scoped>
.select
  width: 100%
</style>
