<template>
  <div class="success">
    <div class="content">
      <h2>¡Canal estelar conectado! 🚀</h2>
      <h3>{{ description }}</h3>

      <p>
        Despliega tu poder y comunícate con tus contactos en cualquier rincón del universo. ¡Haz que
        se sientan atendidos sin importar en qué planeta se encuentren!
      </p>

      <a-button class="button" type="primary" @click="handleBack"> Ver en "Mis Canales" </a-button>
    </div>

    <img class="background-img" :src="successImage" alt="success" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useRouter } from "vue-router";

import successImage from "@/app/channels/assets/success.webp";

defineProps<{
  description: string;
  type: string;
}>();

const emit = defineEmits<{
  "remove-listener": [];
}>();

const router = useRouter();

const handleBack = () => {
  emit("remove-listener");
  router.push("/channels/board");
};

onMounted(() => {
  const modal = document.querySelector(".setup-modal .ant-modal-close-icon") as HTMLDivElement;

  modal.style.color = "white";
});
</script>

<style lang="sass" scoped>
.success
  background-color: #001529
  position: absolute
  inset: 0
  padding: 54px 48px
  border-radius: 8px
  height: 100%
  width: 100%
  overflow: hidden
  // display: flex
  // flex-direction: column
  // align-items: center

// :global(.setup-modal .ant-modal-close-icon)
//   color: white

.background-img
  position: absolute
  bottom: 0
  left: 0
  right: 0
  width: 100%

.button
  position: relative
  z-index: 90

.content
  color: $gray-2
  text-align: center

  h2
    font-size: 38px
    font-weight: 600
    line-height: 46px
    margin-bottom: 5px

  h3
    font-size: 20px
    line-height: 28px
    font-weight: 600
    margin-bottom: 16px
    color: $gray-3

  p
    font-size: 16px
    line-height: 24px
    margin-bottom: 52px
</style>
