import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un usuario.
 *
 * @param {number} userData Datos del agente asignado.
 */
export default async function (userData) {
  const response = await httpManager.post("/users/assigned-contacts/", userData);
  return response?.data;
}
