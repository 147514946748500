import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo telefono.
 * @param {number} contactId Identificador del contacto.
 * @param {Object} phone Datos de telefono.
 *
 * @return {Promise<Object>} Datos del telefono creado
 */
export async function createPhoneContact(contactId, phone) {
  const url = `/contacts/clients/${contactId}/phones/`;
  const response = await httpManager.post(url, phone);
  return response?.data;
}
