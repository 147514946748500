<template>
  <section
    v-if="floating || visible"
    class="overlay"
    :class="{ 'overlay-floating': floating }"
    :style="overlayDynamicStyles"
  >
    <header>
      <template v-if="floating">
        <h4>{{ action }}</h4>

        <a-button type="text" class="icon__button" @click="$emit('minimize')">
          <DownOutlined class="icon" />
        </a-button>
      </template>

      <template v-else>
        <span class="time">{{ secondsToHHMMSS(elapsedTime) }}</span>

        <span class="status"> {{ name }} | {{ status }} </span>
      </template>
    </header>

    <slot />
  </section>
</template>

<script setup>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { DownOutlined } from "@ant-design/icons-vue";

import { statuses } from "../../common/statuses";
import { secondsToHHMMSS } from "@/packages/webphone/utils/time";

const props = defineProps({
  floating: { type: Boolean, default: true },
  visible: { type: Boolean, default: false },
  action: { type: String, default: "Transferir" },
  receiver: { type: Object, default: () => ({}) },
});

const $jssip = inject("$jssip");
const store = useStore();
const route = useRoute();

const overlayDynamicStyles = computed(() => {
  const visiblePosition = props.floating ? "70px" : "0";

  return {
    top: props.visible ? visiblePosition : "100%",
  };
});

const ticket = inject("selectedTicket");

const status = computed(() => {
  return statuses[$jssip.phone.status] || "Finalizada";
});

const name = computed(() => {
  return ticket.value?.client?.name || props.receiver?.name || "No identificado";
});

const elapsedTime = computed(() => $jssip.phone.time.elapsedTime);
</script>

<style lang="sass" scoped>
.overlay
  position: absolute
  z-index: 10
  width: 100%
  height: calc(100% - 110px)
  background-color: $white

  &-floating
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
    border-radius: 12px
    height: 100%
    transition: top 1s ease

header
  display: flex
  justify-content: center
  align-items: center
  margin: 8px 12px
  position: relative

h4
  margin: 0
  color: $gray-8
  font-weight: 600
  font-size: 14px

.icon
  font-size: 16px

  &__button
    height: 22px
    width: 22px
    padding: 0
    position: absolute
    right: 0
    border-radius: 50%

.time
  position: absolute
  left: 0
  font-size: 12px
</style>
