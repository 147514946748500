import httpManager from "@/app/shared/utils/httpManager";
/**
 * Enviar Token para eliminar una empresa
 *
 * @param {Object} token token de la empresa
 *
 * @return {Promise<Object>} Datos de la empresa
 */
export async function sendEntityToken(token) {
  const body = token;
  const response = await httpManager.post("/entities/send-token/", body);
  return response.data;
}
