<template>
  <section class="quick-answers-container">
    <p>Define como los agentes envían los mensajes en el workspace.</p>
  </section>
  <div class="card-container">
    <a-card
      :class="{ 'selected-card': selectedOption === TypeSendOption.EnterOption }"
      class="custom-card"
    >
      <a-radio
        :checked="selectedOption === TypeSendOption.EnterOption"
        @change="selectOption(TypeSendOption.EnterOption)"
        ><strong>Enviar mensajes con Enter</strong></a-radio
      >
      <p>Envía el mensaje al presionar la tecla Enter en tu teclado.</p>
    </a-card>
    <a-card
      :class="{ 'selected-card': selectedOption === TypeSendOption.ClicOption }"
      class="custom-card"
    >
      <a-radio
        :checked="selectedOption === TypeSendOption.ClicOption"
        @change="selectOption(TypeSendOption.ClicOption)"
        ><strong>Enviar mensajes con clic</strong></a-radio
      >
      <p>Envía el mensaje haciendo clic en el botón Enviar.</p>
    </a-card>
  </div>
  <div class="footer">
    <a-button @click="handleClose">Cerrar</a-button>
    <a-button type="primary" @click="handleSave" :loading="loading" :disabled="!canSave">
      Guardar cambios
    </a-button>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { apiService } from "@/app/platformSettings/services/ApiService";
import { TypeSendOption } from "@/app/platformSettings/utils/texts";
import { message } from "ant-design-vue";

const props = defineProps<{
  enable_enter?: boolean;
}>();

const selectedOption = ref<TypeSendOption.EnterOption | TypeSendOption.ClicOption>(
  TypeSendOption.EnterOption
);

const loading = ref<boolean>(false);

const canSave = ref<boolean>(false);

const emit = defineEmits<{
  "update:enable_enter": [enable_enter: boolean];
}>();

const handleClose = () => {};

const handleSave = async () => {
  loading.value = true;
  try {
    await apiService.updateMessageSendOption(TypeSendOption.EnterOption === selectedOption.value);
    emit("update:enable_enter", TypeSendOption.EnterOption === selectedOption.value);
    canSave.value = false;
    setTimeout(() => {
      loading.value = false;
    }, 1000);
    message.success("El cambio se realizo correctamente.");
  } catch (error: any) {
    console.error(error.getErrorMessage() || "Ocurrio un error");
    message.error(error.getErrorMessage() || "Ocurrio un error");
  }
};
const selectOption = (option: TypeSendOption.EnterOption | TypeSendOption.ClicOption) => {
  selectedOption.value = option;
  const optionSelected = props.enable_enter
    ? TypeSendOption.EnterOption
    : TypeSendOption.ClicOption;
  canSave.value = selectedOption.value !== optionSelected ? true : false;
};
onMounted(async () => {
  selectedOption.value = props.enable_enter
    ? TypeSendOption.EnterOption
    : TypeSendOption.ClicOption;
});
</script>
<style scoped lang="sass">
.card-container
    display: flex
    justify-content: center
    align-items: center
    gap: 20px
    padding: 20px


.custom-card
    width: 800px
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 6px


.quick-answers-container
    margin-bottom: 20px

.footer
    justify-content: end
    display: flex
    gap: 10px

.selected-card
    border-color: blue
</style>
