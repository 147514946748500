import { SET_MY_ACCOUNT_VIEW_STATUS, SET_SECURITY_PERMITS, SET_BUSINESS } from "./mutation-types";

const mutations = {
  [SET_MY_ACCOUNT_VIEW_STATUS](state, settings) {
    state.settings = settings;
  },
  [SET_SECURITY_PERMITS](state, settings) {
    state.settings = settings;
  },
  [SET_BUSINESS](state, enable_bases) {
    state.enable_bases = enable_bases;
  },
};

export default mutations;
