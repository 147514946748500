<template>
  <a-drawer
    :visible="visible"
    title="Agregar email"
    destroy-on-close
    :mask-closable="false"
    placement="right"
    width="480"
    keyboard
    :body-style="{ padding: 0 }"
    @close="handleCloseDrawer"
  >
    <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
      <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
        <a-form-item label="Email" name="email">
          <a-input
            v-model:value="formState.email"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>
        <a-form-item label="Tipo" name="type_email">
          <a-input
            v-model:value="formState.type_email"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>
      </a-form>
    </custom-scrollbar>

    <div class="footer">
      <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="!changeInput">
        Agregar email
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { humanDateFormat } from "@/app/shared/utils/constants";
import { defineComponent, ref, reactive, toRaw } from "vue";

export default defineComponent({
  components: {
    CustomScrollbar,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      email: "",
      type_email: "",
    });
    const rules = {
      email: [
        { required: true, message: "Campo requerido" },
        {
          type: "email",
          message: "El correo electrónico no es válido",
        },
      ],
    };
    return {
      rules,
      formState,
      formRef,
    };
  },
  data() {
    return {
      humanDateFormat,
      changeInput: false,
    };
  },

  methods: {
    handleChangeInput() {
      this.changeInput = true;
    },
    handleCloseDrawer() {
      this.$emit("onCloseDrawerEmail");
      this.changeInput = false;
      this.resetFields();
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          const body = { ...toRaw(this.formState) };
          await this.$store.dispatch("createEmailContact", { emailId: this.itemId, body });
          this.handleCloseDrawer();
          this.$message.success("Se ha agregado información correctamente");
          this.resetFields();
        } catch (error) {
          this.$message.error("Se ha producido un error al agregar la información");
        }
      });
    },
    resetFields() {
      this.formState.email = "";
      this.formState.type_email = "";
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

:deep(.ant-calendar-picker)
  width: 100%
</style>
