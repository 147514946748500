const getters = {
  getBasesInfo(state) {
    return {
      items: state.basesInfo.items,
      pagination: state.basesInfo.pagination,
    };
  },
  getAllBases(state) {
    return state.bases;
  },
};

export default getters;
