export default [
  {
    path: "/crm",
    name: "crm",
    component: () => import(/* webpackChunkName: "crm" */ "@/app/crm/pages/CrmPage.vue"),
    redirect: "/crm/contacts",
    children: [
      {
        path: "contacts",
        component: () =>
          import(/* webpackChunkName: "crm" */ "@/app/contacts/pages/ContactsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "tickets-manager",
        component: () =>
          import(
            /* webpackChunkName: "tickets" */ "@/app/ticketsManager/pages/TicketsManagerPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "imports",
        component: () =>
          import(
            /* webpackChunkName: "imports-board" */ "@/app/imports/components/templates/ImportsTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "emails-manager",
        component: () =>
          import(
            /* webpackChunkName: "emails-manager" */ "@/app/emailsManager/pages/EmailsManagerPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "templates",
        component: () =>
          import(
            /* webpackChunkName: "imports-template" */ "@/app/imports/components/templates/TemplatesTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "templates/edit-contacts",
        component: () =>
          import(
            /* webpackChunkName: "imports-template-contacts" */ "@/app/imports/components/templates/TemplatesClientTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "templates/edit-product",
        component: () =>
          import(
            /* webpackChunkName: "imports-template-products" */ "@/app/imports/components/templates/TemplatesProductTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/imports",
  //   name: "imports",
  //   component: () =>
  //     import(/* webpackChunkName: "imports-page" */ "@/app/imports/pages/ImportsPage"),
  //   redirect: "/imports/board",
  //   children: [

  //   ],
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
];
