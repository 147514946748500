import { cloneDeep } from "lodash";
import httpManager from "@/app/shared/utils/httpManager";

import type { SearchParams, QuickAnswerCreateBody } from "@/@types/platformSettings/quickAnswers";
import { getPaginationQuery, toFormData } from "@/app/shared/utils/helpers";

class QuickActionsService {
  baseUrl = "/replies/quick-replies";

  async getList({ page = 1, title = "", queue = "" }: SearchParams) {
    const paginationQuery = getPaginationQuery(page, 6);
    const url = `${this.baseUrl}/?${paginationQuery}&title=${title}&queue=${queue}`;

    const response = await httpManager.get(url);

    return response.data;
  }

  async getDetails(id: number) {
    const url = `${this.baseUrl}/${id}/`;

    const response = await httpManager.get(url);

    return response.data;
  }

  async create(body: QuickAnswerCreateBody) {
    await httpManager.post(`${this.baseUrl}/`, toFormData(body, { avoidEmpty: true }));
  }

  async edit(id: number | undefined, body: Partial<QuickAnswerCreateBody>) {
    if (!id) return;

    const url = `${this.baseUrl}/${id}/`;

    const editBody = cloneDeep(body);

    if (typeof editBody.file === "string") delete editBody.file;

    await httpManager.put(url, toFormData(editBody, { avoidEmpty: true }));
  }

  async delete(id: number) {
    const url = `${this.baseUrl}/${id}/`;

    await httpManager.delete(url);
  }
}

export const quickAnswersService = new QuickActionsService();
