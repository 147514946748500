import moment from "moment-timezone";
import dayjs from "dayjs";

import { dateFormat, DATE_CALENDAR } from "@/app/shared/utils/constants";

import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);

export function stringToDate(string) {
  if (!string) return null;
  return moment(string, dateFormat);
}

export function getMomentDate(string) {
  if (!string) return null;
  return moment(string);
}

export function dateToString(object, format = dateFormat) {
  if (!object) return null;
  return moment(object).format(format);
}

export function dateToHumanFormat(date) {
  if (!date) return null;

  return dayjs(date).format("DD MMMM YYYY");
}

export function dateToHumanHour(date) {
  if (!date) return null;
  const jsDate = new Date(date);
  const time = jsDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return `${time} hrs.`;
}

export function dateToDurationString(date, now = new Date()) {
  const initialDate = moment(date, "YYYY/MM/DD HH:mm:ss");
  const currentDate = moment(now, "YYYY/MM/DD HH:mm:ss");

  const minutes = currentDate.diff(initialDate, "m"); // Diff in hours
  if (minutes <= 0) return "Ahora";
  if (minutes <= 1) return "1 min";
  if (minutes < 60) return `${minutes} min`;

  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hrs`;

  const days = currentDate.diff(initialDate, "d"); // Diff in days
  if (days > 1) return `${days} días`;
  return `${days} día`;
}

export function dateReminder(value) {
  if (!value) return null;
  const jsDate = new Date(value);
  const day = jsDate.toLocaleDateString([], { day: "2-digit" });
  const month = jsDate.toLocaleDateString([], { month: "2-digit" });
  const year = jsDate.getFullYear();
  return `${year}-${month}-${day}`;
}

export function hourReminder(value) {
  if (!value) return null;
  const jsDate = new Date(value);
  const time = jsDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  return time;
}

export function hourToLocalTime(value) {
  if (!value) return null;
  const jsData = new Date(value);
  const time = jsData.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h23",
  });
  return time;
}

export function hourReminderWithoutSeconds(value) {
  if (!value) return null;
  const jsData = new Date(value);
  const time = jsData.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  });
  return time;
}

export function dateToTimeString(time) {
  return moment(time, "hh:mm").format("hh:mm A");
}

export function disabledYounger(current) {
  return !dayjs(current).isBefore(defaultYounger);
}

export const defaultYounger = dayjs().subtract(18, "year");

export function dateMomentReminder(date, timezone) {
  return moment.tz(date, timezone).format("YYYY-MM-DD");
}
export function timeMomentReminder(time, timezone) {
  return moment.tz(time, timezone).format("HH:mm:ss");
}

export function disabledDate(current) {
  return current && current <= moment().subtract(1, "days");
}
export function disabledDateFuture(current) {
  return current && current >= moment().endOf("day");
}

export function dateToSeconds(date = new Date()) {
  return Math.floor(date.getTime() / 1000);
}

export function getDateWithTwoDigitsFormat(value) {
  if (!value) return null;
  const date = new Date(value);
  return date.toLocaleDateString("es-PE", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}

export function dateTimeToSlashFormat(date, withTime = true) {
  let format = "DD/MM/YYYY";
  if (withTime) format += " HH:mm:ss";
  const newDate = moment(date).format(format);
  return date ? newDate : "-";
}

export function dateTimeToMoment(date) {
  return moment(date);
}

export function dateToTenantTime(date, timezone) {
  return moment.tz(date, timezone).format("YYYY-MM-DD HH:mm:ss");
}

export function dateTimeToTime(date = "") {
  return date?.split(" ")?.[1];
}

export function millisecondsToSeconds(milliseconds = 0) {
  return Math.floor(milliseconds / 1000);
}

export function slashDateToDashed(date = "") {
  const numbers = date?.split("/")?.reverse();

  return numbers?.join("-");
}

/**
 * Transform HH:mm:ss time format to seconds
 *
 * @param {String} time
 * @return {Number}
 */
export function timeStringToSeconds(time = "00:00:00") {
  if (time) {
    const numbers = time?.split(":");
    return numbers[0] * 3600 + numbers[1] * 60 + (+numbers[2] || 0);
  } else  return 0;
}

/**
 * Transform seconds to HH:mm:ss
 *
 * @param {Number} seconds
 * @returns {String}
 */
export function secondsToTimeString(seconds = 0) {
  function formatNumber(n = 0) {
    return (n < 10 ? "0" : "") + n.toFixed(0);
  }
  const sign = seconds < 0 ? "-" : "";
  seconds = Math.abs(seconds);

  return (
    sign +
    formatNumber((seconds / 3600) | 0) +
    ":" +
    formatNumber(((seconds % 3600) / 60) | 0) +
    ":" +
    formatNumber(seconds % 60)
  );
}

export function formatSeconds(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const hoursStr = hours > 0 ? `${hours} hrs ` : "";
  const minutesStr = minutes > 0 ? `${minutes} min ` : "";
  const secondsStr = `${seconds} seg`;

  return `${hoursStr}${minutesStr}${secondsStr}`;
}

/**
 * Obtiene la diferencia entre dos fechas
 * @param {String|Date} timeOne
 * @param {String} timeTwo
 * @param {String} format
 * @returns {Number}
 */
export function getDifferenceBetweenTwoTimes(timeOne, timeTwo, format = "minute") {
  const date1 = dayjs(timeOne);
  const date2 = dayjs(timeTwo);
  const difference = date1.diff(date2, format);
  return difference;
}

export function dateCalendar(date) {
  return dayjs(date).calendar(null, DATE_CALENDAR);
}
