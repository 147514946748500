const getters = {
  /**
   * Retorna si el usuario está logeado o no.
   */
  isLoggedIn: (state) => !!state.access_token,

  getLoginProgress(state) {
    return state.step;
  },
  getEmail(state) {
    return state.temporalEmail;
  },
  getAllWorkspaces(state) {
    return state.workspaces;
  },
};

export default getters;
