<template>
  <section>
    <p class="paragraph">
      Puedes agregar o editar referidos.
      <a @click="handleOpenReferrals()"><span>Agregar Referidos</span></a>
    </p>
    <add-referrals-contact-drawer
      :visible="isAddReferralsVisible"
      @onCloseDrawerEdit="handleCloseDrawer"
      :itemId="itemId"
    />
    <div class="flex">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 700 }"
        :pagination="pagination"
        class="table__phone"
      >
        <template #phone="{ text }">
          <a class="phone" @click="handleConfirmationCall(text)">{{ text }}</a>
        </template>

        <template #address="{ text }">
          <a-tooltip>
            <template #title>{{ text }}</template>
            <p class="address">{{ text }}</p>
          </a-tooltip>
        </template>

        <template #observation="{ text }">
          <div v-if="text?.length > 29">
            <a-popover :overlayStyle="{ width: '250px' }" placement="bottomRight">
              <template #content>
                <p>{{ text }}</p>
              </template>
              <div class="flex-center">
                <p class="observation__text">{{ text }}</p>
              </div>
            </a-popover>
          </div>
          <div v-else>
            <div class="flex-center">
              <p class="observation__text">{{ text }}</p>
            </div>
          </div>
        </template>

        <template #actionScoped="{ record }">
          <div class="actions">
            <a-tooltip>
              <template #title> Editar </template>
              <UpdateReferralsContactDrawer :record="record" :itemId="itemId" />
            </a-tooltip>
            <a-tooltip>
              <template #title> Eliminar </template>
              <DeleteOutlined class="icon" @click="handleDelete(record)" />
            </a-tooltip>
          </div>
        </template>
      </a-table>

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getReferrals?.length > 2"
          >ver más ({{ getReferrals?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
</template>

<script>
import { columns } from "@/app/contacts/components/tables/referrals/ContactReferralsTableColumns";
import AddReferralsContactDrawer from "@/app/contacts/components/drawers/referrals/AddReferralsContactDrawer.vue";
import UpdateReferralsContactDrawer from "@/app/contacts/components/drawers/referrals/UpdateReferralsContactDrawer.vue";

import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";
import { debounce } from "lodash";

export default {
  components: {
    AddReferralsContactDrawer,
    DeleteOutlined,
    UpdateReferralsContactDrawer,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      isAddReferralsVisible: false,
      position: 0,
      dataTable: [],
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      openTable: false,
      eyes: false,
    };
  },
  computed: {
    columnsTable() {
      return columnsFixedTableLeft(this.columns);
    },
    contact() {
      return this.$store.getters["getContact"];
    },
    referrals() {
      return this.$store.getters["getReferralsData"].items?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          number_document: item.number_document,
          email: item.email,
          observation: item.observation,
          phone: item.phone,
          type_document: item.type_document,
          address: item.address,
          country: item.country,
        };
      });
    },
    getReferrals() {
      return this.$store.getters["getReferralsData"].items;
    },
  },
  watch: {
    getReferrals() {
      this.dataTable = this.getReferrals;
    },
  },
  created() {
    this.handleFetchReferrals();
  },
  methods: {
    handleClosePopover() {
      this.eyes = false;
    },
    handleOpenPopover() {
      this.eyes = true;
    },
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getReferrals.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      this.openTable = false;
    },
    handleCloseDrawer() {
      this.isAddReferralsVisible = false;
    },
    handleOpenReferrals() {
      this.isAddReferralsVisible = true;
    },
    handleDelete(value) {
      Modal.confirm({
        title: `¿Estás seguro de eliminar este contacto referido?`,
        content: "Recuerda que las interacciones asociadas a este referido serán finalizadas.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: async () => {
          this.$store.dispatch("deleteReferralsContact", value.id);
        },
        okText: "Eliminar",
        cancelText: "Cancelar",
      });
    },
    handleConfirmationCall: debounce(function (text) {
      Modal.confirm({
        title: `¿Estás seguro de llamar al contacto “${this.contact.fullName}”?`,
        content:
          "Recuerda que se creará un nuevo ticket y al finalizar la llamada podrás colocar una tipificación.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.handlePhoneClick(text),
        centered: true,
        okText: "Llamar",
        cancelText: "Cancelar",
      });
    }, 50),
    async handlePhoneClick(number) {
      this.$router.push("/workspace/");
      this.$jssip.clearData();
      await this.$jssip.call(number);
      this.$store.dispatch("setCallFinish", false);
    },
    handleFetchReferrals() {
      this.$store.dispatch("fetchReferralsClient", this.itemId);
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7
.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px
.actions
  display: flex

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  width: 100px !important

.scroll
  position: absolute
  right: 0
  top: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 42px
  border: 1px solid #E5E5E5
  background: #F9F9F9
  border-radius: 5px
  height: 86px
  width: 20px

.flex
  position: relative

.table__phone
  margin-right: 20px

.address
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  text-transform: capitalize
  margin: 0

:deep(.ant-pagination)
  display: none !important

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 10px

.observation__text
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  margin: 0

.flex-center
  display: flex
  align-items: center
</style>
