export const columns = [
  {
    dataIndex: "created_at",
    width: 180,
    slots: { title: "customDate", customRender: "created_at" },
    sorter: (a, b) => {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
  },
  {
    title: "Canal",
    dataIndex: ["channel", "icon"],
    width: 80,
    slots: { customRender: "channel" },
  },
  {
    dataIndex: "channel_customer_identifier",
    width: 160,
    ellipsis: true,
    slots: { customRender: "identifier", title: "identifierTitle" },
  },
  {
    title: "Teléfono del contacto",
    dataIndex: "phone_number",
    width: 180,
    slots: { customRender: "phone" },
  },
  {
    title: "Grupo",
    dataIndex: "group_name",
    width: 200,
    slots: { customRender: "group_name" },
    filters: [],
    onFilter: (value, record) => record.group_name.indexOf(value) === 0,
  },
  {
    title: "Resultado",
    dataIndex: "result_name",
    width: 180,
    slots: { customRender: "result_name" },
    filters: [],
    onFilter: (value, record) => record.result_name.indexOf(value) === 0,
  },

  {
    title: "Motivo",
    dataIndex: "motive_name",
    width: 250,
  },
  {
    title: "Submotivo",
    dataIndex: "submotive_name",
    width: 150,
    slots: { customRender: "submotive_name" },
  },
  {
    title: "Comentario",
    dataIndex: "observ_01",
    width: 150,
  },
];
