import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los canales.
 *
 * @param {number} channelId Identificador del canal.
 *
 * @return {Promise<ChannelCustomer[]>} Lista de canales.
 */
export default async function (blacklist = {}) {
  const { blacklist_phone, blacklist_code_phone, blacklist_blocked_type } = blacklist || {};
  let url = `/blacklist/channels-customers/?phone_number=${blacklist_phone}&code_phone=${blacklist_code_phone}&blocked_type=${blacklist_blocked_type}`;
  const response = await httpManager.get(url);
  return response.data;
}
