import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener un usuario mediante su id.
 *
 * @return {Promise<Object>} Usuario por id
 */

export async function downloadContactByFilters(filters = {}) {
  const entity = filters.entity;
  const campaign = filters.campaign;
  const base = filters.base;
  const search = filters.search;
  const coverage = filters.coverage || false;
  const status = filters.status || '';
  const contacts_ids = filters.contacts_ids;
  let url = `/contacts/clients/download-excel/?`;

  if (entity) url += `entity=${entity}&`;

  if (campaign) {
    url = url + `portfolio=${campaign}&`;
  }
  if (search) {
    url = url + `search=${search}&`;
  }
  if (base) {
    url = url + `base=${base}&`;
  }
  if (coverage) {
    url = url + `coverage=${coverage}&`;
  }
  if (status) {
    url = url + `status=${status}&`;
  }
  if (contacts_ids) {
    const body = { contacts_ids };
    const response = await httpManager.post(url, body, { blob: true });
    
    return response;
  } else {
    const response = await httpManager.getBlob(url);

    return response;  
  }
}