import {
  SET_TABLE_PAGE,
  SET_BASIC_FILTERS,
  SET_TICKETS_ADVANCED_FILTERS,
  SET_TICKETS,
  SET_TAG_TO_TICKETS,
  RESOLVE_TICKETS,
  RESOLVE_SINGLE_TICKET,
  EDIT_TICKET_TAGS,
  SET_TRANSFER_DATA,
  TRANSFER_TICKET_TO_AGENT,
  TRANSFER_TICKET_TO_QUEUE,
  SET_ADVANCED_FILTERS_DATA,
  SET_USERS_DATA,
  SET_TICKETS_MANAGER_CHANNELS,
  SET_LOADING,
} from "./mutation-types";
import { TicketState, ChannelType } from "@/app/shared/utils/enums";

const mutations = {
  [SET_TICKETS](state, data) {
    console.log(data, 'data SET_TICKETS');
    state.ticketsData = data;
  },
  [SET_TAG_TO_TICKETS](state, payload) {
    const { ticketsId, tagDetails } = payload;

    for (const ticketId of ticketsId) {
      const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

      const currentTicket = state.ticketsData.results[ticketIndex];

      console.log({ currentTicket, tagDetails });

      const currentTicketsTagsId = currentTicket.tags.map((t) => t.id);

      if (!currentTicketsTagsId.includes(tagDetails.id)) currentTicket.tags.push(tagDetails);
    }
  },
  [RESOLVE_TICKETS](state, ticketsId) {
    for (const ticketId of ticketsId) {
      const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

      const currentTicket = state.ticketsData.results[ticketIndex];

      currentTicket.state = TicketState.finished;
    }
  },
  [RESOLVE_SINGLE_TICKET](state, ticketId) {
    const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

    const currentTicket = state.ticketsData.results[ticketIndex];

    currentTicket.state = TicketState.finished;
  },
  [EDIT_TICKET_TAGS](state, payload) {
    const { ticketId, ticketData } = payload;

    const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

    state.ticketsData.results[ticketIndex] = ticketData;
  },
  [SET_TRANSFER_DATA](state, transferData) {
    state.transferData = transferData;
  },
  [TRANSFER_TICKET_TO_AGENT](state, payload) {
    const { ticketId, agentData } = payload;

    const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

    const currentTicket = state.ticketsData.results[ticketIndex];

    currentTicket.user_assigned = agentData;
  },
  [TRANSFER_TICKET_TO_QUEUE](state, payload) {
    const { ticketId, queueData } = payload;

    const ticketIndex = state.ticketsData.results.findIndex((ticket) => ticket.id === ticketId);

    const currentTicket = state.ticketsData.results[ticketIndex];

    currentTicket.queue = queueData.name;
  },
  [SET_TABLE_PAGE](state, tablePage) {
    state.tablePage = tablePage;
  },
  [SET_BASIC_FILTERS](state, basicFilters) {
    state.ticketsBasicFilters = basicFilters;
  },
  [SET_TICKETS_ADVANCED_FILTERS](state, ticketsAdvancedFilters) {
    state.ticketsAdvancedFilters = ticketsAdvancedFilters;
  },
  [SET_ADVANCED_FILTERS_DATA](state, data) {
    state.advancedFiltersData = data;
  },
  [SET_USERS_DATA](state, data) {
    state.usersData = data;
  },
  [SET_TICKETS_MANAGER_CHANNELS](state, data) {
    const messageIds = [
      ChannelType.Whatsapp,
      ChannelType.Messenger,
      ChannelType.Inbound,
      ChannelType.Instagram,
      ChannelType.Sms,
    ];
    const voiceIds = [ChannelType.Voice, ChannelType.VoiceInbound];
    const formattedChannels = data
      .map((channel) => {
        let name = "";

        switch (channel.id) {
          case ChannelType.Whatsapp:
            name = "WhatsApp";
            break;

          case ChannelType.Messenger:
            name = "FB Messenger";
            break;

          case ChannelType.Inbound:
            name = "Web Chat";
            break;

          case ChannelType.Instagram:
            name = "Instagram";
            break;

          case ChannelType.Sms:
            name = "Sms";
            break;

          default:
            break;
        }

        if (name) {
          return {
            value: channel.id,
            label: name,
            id: channel.id,
          };
        }
      })
      .filter(Boolean);

    if (state.ticketManagerChannels.length === 3) {
      state.ticketManagerChannels[1].id = messageIds.join();
      state.ticketManagerChannels[2].id = voiceIds.join();
      state.ticketManagerChannels = [...state.ticketManagerChannels, ...formattedChannels];
    }
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};

export default mutations;
