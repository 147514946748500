export function setChannelTitle(iconUrl = "") {
  let title = "";

  const stringLowerCased = iconUrl.toLowerCase();
  if (stringLowerCased.includes("voice")) title = "Números de teléfono";
  if (stringLowerCased.includes("whatsapp")) title = "Números de WhatsApp";
  if (stringLowerCased.includes("chat")) title = "Cuentas de Web Chat";
  if (stringLowerCased.includes("messenger")) title = "Cuentas de Facebook Messenger";
  if (stringLowerCased.includes("instagram")) title = "Cuentas de Instagram";

  return title;
}

export function groupBy(list, keyGetter) {
  if (list.length > 1) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    const collectionObject = Object.fromEntries(map);

    const newCollection = [];

    for (const key in collectionObject) {
      const object = {
        icon: key,
        title: setChannelTitle(key),
        items: collectionObject[key],
      };

      newCollection.push(object);
    }

    // console.log(newCollection);
    return newCollection;
  } else if (list.length === 1) {
    // console.log(list);
    return [
      {
        icon: list[0]?.channel?.icon,
        title: setChannelTitle(list[0]?.channel?.icon || ""),
        items: list,
      },
    ];
  } else {
    console.log("no hay nada");
  }
}
