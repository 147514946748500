import { toFormData } from "@/app/shared/utils/helpers";
import httpManager from "@/app/shared/utils/httpManager";

/**
 * @param {Number} chatbotId
 * @param {Object} body
 */

export async function createNode(chatbotId, body) {
  const formData = toFormData(body);

  const response = await httpManager.post(`/chatbots/${chatbotId}/nodes/`, formData);

  return response.data;
}
