import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los canales.
 *
 * @param {number} channelId Identificador del canal.
 *
 * @return {Promise<ChannelCustomer[]>} Lista de canales.
 */
export default async function (portfolioId) {
  const response = await httpManager.get(`/channels/channel-customer/?portfolio=${portfolioId}`);
  return response?.data;
}
