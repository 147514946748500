import axios from "@/plugins/axios";
const local = false;
/**
 * @param {Object} data - Data sent to the socket
 * @return {Promise<Object>} Data received by the socket.
 */
export async function refreshMinutesToExpire(data) {
  const url = local
    ? "http://localhost:8081/users/refresh-minutes-to-expire/"
    : `${import.meta.env.VITE_SOCKET}users/refresh-minutes-to-expire/`;

  const response = await axios.post(url, data);

  return response.data;
}
