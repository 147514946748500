const SET_HOUR_PLATFORM = "SET_HOUR_PLATFORM";
const SET_PLATFORM_DATE = "SET_PLATFORM_DATE";
const SET_ACW_STATE = "SET_ACW_STATE";
const SET_WAITING_CHANGE_STATE_MESSAGE = "SET_WAITING_CHANGE_STATE_MESSAGE";

const mutations = {
  [SET_HOUR_PLATFORM](state, hour) {
    state.hourPlatform = hour;
  },
  [SET_PLATFORM_DATE](state, date) {
    state.platformDate = date;
  },
  [SET_ACW_STATE](state, acw) {
    state.inAcw = acw;
  },
  [SET_WAITING_CHANGE_STATE_MESSAGE](state, userPlatformState) {
    state.waitingChangeStateMessage = userPlatformState;
  },
};
export default mutations;
