<template>
  <section>
    <p class="paragraph">
      Puedes agregar o editar direccion.
      <a @click="handleOpenAddAddresses()"><span>Añadir direccion</span></a>
    </p>
    <add-addresses-contact-drawer
      :visible="isAddAddressesVisible"
      @onCloseDrawerEdit="handleCloseDrawer"
      :itemId="itemId"
    />
    <div class="flex">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 700 }"
        :pagination="pagination"
        class="table__phone"
      >
        <template #actionScoped="{ record }">
          <div class="actions">
            <a-tooltip>
              <template #title> Editar </template>
              <UpdateAddressesContactDrawer :record="record" :itemId="itemId" />
            </a-tooltip>
            <a-tooltip>
              <template #title> Eliminar </template>
              <DeleteOutlined class="icon" @click="handleDelete(record)" />
            </a-tooltip>
          </div>
        </template>
      </a-table>

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getAddresses?.length > 2"
          >ver más ({{ getAddresses?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
</template>

<script>
import { columns } from "@/app/contacts/components/tables/addresses/ContactAddressesTableColumns";
import AddAddressesContactDrawer from "@/app/contacts/components/drawers/addresses/AddAddressesContactDrawer.vue";
import UpdateAddressesContactDrawer from "@/app/contacts/components/drawers/addresses/UpdateAddressesContactDrawer.vue";

import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";

export default {
  components: {
    AddAddressesContactDrawer,
    UpdateAddressesContactDrawer,
    DeleteOutlined,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      isAddAddressesVisible: false,
      position: 0,
      dataTable: [],
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      openTable: false,
    };
  },
  computed: {
    columnsTable() {
      return this.columns;
    },
    addresses() {
      return this.$store.getters["getContactAddress"].items?.map((item) => {
        return {
          id: item.id,
          address: item.address,
          type_address: item.type_address,
          department: item.department,
          district: item.district,
          province: item.province,
        };
      });
    },
    getAddresses() {
      return this.$store.getters["getContactAddress"].items;
    },
  },
  watch: {
    getAddresses() {
      this.dataTable = this.getAddresses;
    },
  },
  created() {
    this.handleFetchAddresses();
  },
  methods: {
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getAddresses.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      this.openTable = false;
    },
    handleCloseDrawer() {
      this.isAddAddressesVisible = false;
    },
    handleOpenAddAddresses() {
      this.isAddAddressesVisible = true;
    },
    handleDelete(value) {
      Modal.confirm({
        title: `¿Estás seguro de Eliminar esta direccion?`,
        content: "Recuerda que las interacciones asociadas a esta direccion serán finalizadas.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.$store.dispatch("deleteAddressContact", value.id),
        centered: true,
        okText: "Eliminar",
        cancelText: "Cancelar",
      });
    },
    handleFetchAddresses() {
      this.$store.dispatch("fetchAddressContact", this.itemId);
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7
.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px
.actions
  display: flex

.scroll
  position: absolute
  right: 0
  top: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 42px
  border: 1px solid #E5E5E5
  background: #F9F9F9
  border-radius: 5px
  height: 86px
  width: 20px

.flex
  position: relative

.table__phone
  margin-right: 20px

:deep(.ant-pagination)
  display: none !important

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 10px
</style>
