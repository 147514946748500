/*global FB*/
import { MetaAppType } from "@/app/shared/utils/enums";

const BASIC_SCOPES = "public_profile,email";
const PAGES_SCOPES = "pages_show_list,pages_manage_metadata,pages_messaging,pages_read_engagement";
const INSTAGRAM_SCOPES = "instagram_basic,instagram_manage_messages";

const MetaAppTypeName = {
  [MetaAppType.Messenger]: "Messenger",
  [MetaAppType.Instagram]: "Instagram",
};

const AppCredentials = {
  [MetaAppType.Messenger]: {
    appId: import.meta.env.VITE_MESSENGER_APP_ID,
    secret: import.meta.env.VITE_MESSENGER_APP_SECRET,
  },
  [MetaAppType.Instagram]: {
    appId: import.meta.env.VITE_INSTAGRAM_APP_ID,
    secret: import.meta.env.VITE_INSTAGRAM_APP_SECRET,
  },
};

const metaSdkVersion = import.meta.env.VITE_FACEBOOK_VERSION;

export class MetaSdkManager {
  constructor(metaAppType) {
    this.metaAppType = metaAppType;
    this.metaAppName = MetaAppTypeName[metaAppType];
    // this._sdkInstance = window[this.metaAppName]?.FB;
    this._sdkInstance = { ...FB };
    this._appCredentials = AppCredentials[metaAppType];
  }

  _getInitConfig() {
    return {
      appId: this._appCredentials.appId,
      cookie: true,
      xfbml: false,
      version: metaSdkVersion,
    };
  }

  _getLoginScopes({ includePagesScope = false, isInstagram = false } = {}) {
    let scopes = BASIC_SCOPES;
    if (includePagesScope) scopes += `,${PAGES_SCOPES}`;
    if (isInstagram) scopes += `,${INSTAGRAM_SCOPES}`;

    console.log("SCOPES", scopes);

    return scopes;
  }

  init() {
    this._sdkInstance.init(this._getInitConfig());
  }

  async login({ pageScope = false } = {}) {
    try {
      return await new Promise((resolve, reject) => {
        this.init();

        this._sdkInstance.login(
          (response) => {
            console.log(
              `MetaSdkManager/${this.metaAppName}/login() response ->`,
              JSON.stringify(response)
            );

            if (response.authResponse) resolve(response);
            else reject(response);
          },
          {
            scope: this._getLoginScopes({
              includePagesScope: pageScope,
              isInstagram: this.metaAppType === MetaAppType.Instagram,
            }),
          }
        );
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][login]`, e);
    }
  }

  async getLongAccessToken({ accessToken } = {}) {
    try {
      const url = `/oauth/access_token?grant_type=fb_exchange_token&client_id=${this._appCredentials.appId}&client_secret=${this._appCredentials.secret}&fb_exchange_token=${accessToken}`;

      return await new Promise((resolve, reject) => {
        this._sdkInstance.api(url, (response) => {
          console.log(
            `MetaSdkManager/${this.metaAppName}/getLongAccessToken() response ->`,
            response
          );

          if (response.access_token) resolve(response);
          else reject(response);
        });
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][getLongAccessToken]`, e);
    }
  }

  async getUserData({ userId } = {}) {
    try {
      const url = `${userId}`;

      return await new Promise((resolve, reject) => {
        this._sdkInstance.api(url, (response) => {
          console.log(`MetaSdkManager/${this.metaAppName}/getUserData() response ->`, response);

          if (response.id) resolve(response);
          else reject(response);
        });
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][getUserData]`, e);
    }
  }

  async _getUserSelectedFbPages({ userId, userAccessToken } = {}) {
    try {
      const url = `${userId}/accounts`;

      return await new Promise((resolve, reject) => {
        this._sdkInstance.api(
          url,
          {
            access_token: userAccessToken,
            fields: "name,access_token,picture",
          },
          (response) => {
            console.log(
              `MetaSdkManager/${this.metaAppName}/_getUserSelectedFbPages() response ->`,
              response
            );

            if (response.data.length) resolve(response);
            else reject(response);
          }
        );
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][_getUserSelectedFbPages]`, e);
    }
  }

  /**
   * The field "instagram_business_account" must be the first call to this endpoint.
   * The another fields can be found in https://developers.facebook.com/docs/instagram-api/reference/ig-user/
   */
  async _getIgBusinessPagesLinkedToAFbPage({
    pageId,
    pageAccessToken,
    fields = "instagram_business_account",
  } = {}) {
    try {
      const url = `${pageId}`;
      // const fields = "instagram_business_account";

      return await new Promise((resolve, reject) => {
        this._sdkInstance.api(url, { access_token: pageAccessToken, fields }, (response) => {
          console.log(
            `MetaSdkManager/${this.metaAppName}/getIgBusinessPagesLinkedToAFbPage() response ->`,
            response
          );

          if (response.id) resolve(response);
          else reject(response);
        });
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][getIgBusinessPagesLinkedToAFbPage]`, e);
    }
  }

  async getUserPages({ userId, userAccessToken } = {}) {
    try {
      const fbPages = await this._getUserSelectedFbPages({ userId, userAccessToken });

      if (this.metaAppType === MetaAppType.Messenger) return fbPages;

      const fbPagesWithIgPageId = { ...fbPages };

      const formattedPageDataPromises = fbPages?.data?.map(async (fbPage) => {
        try {
          const linkedIgPage = await this._getIgBusinessPagesLinkedToAFbPage({
            pageId: fbPage.id,
            pageAccessToken: fbPage.access_token,
          });

          const instagram_business_account = linkedIgPage.instagram_business_account?.id;

          const igPageUsername = await this._getIgBusinessPagesLinkedToAFbPage({
            pageId: instagram_business_account,
            pageAccessToken: fbPage.access_token,
            fields: "username",
          });

          console.log(`MetaSdkManager/${this.metaAppName}/getUserPages() response ->`, {
            fbPage,
            linkedIgPage,
            igPageUsername,
          });

          return {
            ...fbPage,
            instagram_business_account,
            ig_username: igPageUsername?.username,
          };
        } catch (error) {
          console.log(`[MetaSdkManager][${this.metaAppName}][getUserPages][Map]`, error);
        }
      });

      const formattedPageData = await Promise.all(formattedPageDataPromises);
      fbPagesWithIgPageId.data = formattedPageData?.filter((page) => Boolean(page));

      return fbPagesWithIgPageId;
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][getUserPages]`, e);
    }
  }

  async subscribePageToApp({ pageId, pageAccessToken } = {}) {
    try {
      const url = `${pageId}/subscribed_apps`;
      const fields =
        "name,picture,messages,message_reactions,message_deliveries,messaging_postbacks,message_reads,messaging_optins,phone,email";

      return await new Promise((resolve, reject) => {
        this._sdkInstance.api(
          url,
          "post",
          { access_token: pageAccessToken, subscribed_fields: fields },
          async (response) => {
            console.log(
              `MetaSdkManager/${this.metaAppName}/subscribePageToApp() response ->`,
              response
            );

            if (response.success) resolve(response);
            else reject(response);
          }
        );
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][subscribePageToApp]`, e);
    }
  }

  async unlinkPageFromApp({ pageId, pageAccessToken } = {}) {
    try {
      const url = `${pageId}/subscribed_apps?access_token=${pageAccessToken}`;

      return await new Promise((resolve, reject) => {
        this.init();

        this._sdkInstance.api(url, "delete", async (response) => {
          console.log(
            `MetaSdkManager/${this.metaAppName}/unlinkPageFromApp() response ->`,
            response
          );

          if (response.success) resolve(response);
          else reject(response);
        });
      });
    } catch (e) {
      console.error(`[MetaSdkManager][${this.metaAppName}][unlinkPageFromApp]`, e);
    }
  }
}
