<template>
  <div class="form-container">
    <header class="title">
      <h3>Formulario {{ form.name }}</h3>
      <span v-if="isFormPerProduct" class="subtitle">Por Producto</span>
    </header>

    <div v-if="isPerProduct && !hasProducts" class="message">
      <span>El cliente no tiene productos</span>
    </div>

    <DynamicInputs
      ref="dynamicInputsRef"
      v-for="product in allProducts"
      :key="typeof product === 'number' ? product : product.id"
      :product="product"
      :form="form"
      :modalKey="modalKey"
      :isPerProduct="isPerProduct"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { cloneDeep } from "lodash";

import type { Product } from "@/@types/global";
import { ElementType, Form, FormElement } from "@/@types/entities/forms";

import DynamicInputs from "@/app/workspace/components/form/DynamicInputs.vue";
import { validateObjectsArrayHasAtLeastOneKeyCompleted } from "@/app/shared/utils/helpers";
import { AnyMxRecord } from "dns";

const props = defineProps<{
  form: Form;
  pdpType: number;
  modalKey: string;
}>();

const emit = defineEmits<{
  toggleAllowSave: [allowSave: boolean];
}>();

const store = useStore();

const isPerProduct = computed(() => props.pdpType === 2);

const allProducts = computed(() =>
  isPerProduct.value ? (store.getters["getWorkspaceClient"]?.products as Product[]) : [1]
);

const hasProducts = computed(() => {
  if (typeof allProducts.value === "number") return false;

  return allProducts.value.length !== 0;
});

const isFormPerProduct = computed(
  () => isPerProduct.value && props.form.form.some((element) => element.type === ElementType.Pdp)
);

const dynamicInputsRef = ref<InstanceType<typeof DynamicInputs>[]>([]);

const allowSave = computed(() => {
  if (!isPerProduct.value) return true;
  if (!dynamicInputsRef.value.length) return true;

  /**
   * Validate if any of the others forms have at least one of their fields filled
   */
  const formStates = dynamicInputsRef.value.map((form) => {
    const cloned = cloneDeep(form.workspaceFormState);

    const keys = Object.keys(cloned).filter((k) => k.includes("header"));

    keys.forEach((k) => delete cloned[k]);

    return cloned;
  });

  return validateObjectsArrayHasAtLeastOneKeyCompleted(formStates);
});

const validateForms = async () => {
  interface FormBody {
    id: number;
    name: string;
    code: number | null;
    account_id: number | null;
    fields: FormElement[];
  }

  const forms: FormBody[] = [];

  try {
    const data = await Promise.all(dynamicInputsRef.value.map((form) => form.validateInputs()));

    data.forEach((form) => {
      forms.push({
        id: props.form.id,
        name: props.form.name,
        code: form.code,
        account_id: form.account_id,
        fields: form.fields,
      });
    });
  } catch (error) {
    throw new Error("Invalido");
  }

  return forms;
};

watch(allowSave, () => emit("toggleAllowSave", allowSave.value), { immediate: true });

const setDefaultFormsValues = (values: Record<string, any>[]) => {
  dynamicInputsRef.value.forEach((ref, idx) => {
    if (values[idx].fields) {
      values[idx].fields?.forEach((field: any) => {
        Object.assign(ref.workspaceFormState, { [field.name]: field.value });
      });
    }
  });
};

const setFormsValues = (values: Record<string, string>[]) => {
  dynamicInputsRef.value.forEach((ref, idx) => {
    Object.assign(ref.workspaceFormState, values[idx]);
  });
};

const formsValues = computed(() => dynamicInputsRef.value.map((ref) => ref.workspaceFormState));

defineExpose({ validateForms, setFormsValues, setDefaultFormsValues, formsValues });
</script>

<style scoped lang="sass">
.form-container
  position: relative

  .title
    position: sticky
    top: 0
    padding: 25px 0 16px
    z-index: 1
    background-color: $white

    .subtitle
      font-style: italic
      font-size: 12px
      color: $gray-6
      // margin-left: 4px

.form
  &-input
    margin-bottom: 16px

.message
  width: 100%
  text-align: center
</style>
