import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los perfiles.
 *
 * @return {Promise<Array>} Lista de perfiles.
 */
export default async function () {
  const response = await httpManager.get("/channels/");
  return response?.data;
}
