import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar un estado de una entidad.
 *
 * @param {number} entityId Identificador de la entidad.
 * @param {FormData} entity Datos de la entidad.
 *
 * @return {Promise<Object>} Entidad editada.
 */
export default async function (entityId, entity) {
  const response = await httpManager.patch(`/entities/${entityId}/`, entity);
  return response?.data;
}
