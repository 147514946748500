<template>
  <login-template />
</template>

<script>
import LoginTemplate from "@/app/auth/components/templates/LoginTemplate.vue";
import { useRoute, useRouter } from "vue-router";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";

export default {
  components: {
    LoginTemplate,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    if (route.query.loggedOutByAdmin) {
      const modal = Modal.info({
        title: "Un administrador ha decidido cerrar tu sesión",
        icon: createVNode(ExclamationCircleOutlined),
        onOk: () => {
          router.push("/login");
          modal.destroy();
        },
        okText: "Aceptar",
        centered: true,
      });
    }

    const hostname = window.location.hostname;
    const inLocalHost = hostname === "localhost";
    const inTenant = !(hostname.split(".").length <= 2 || hostname.split(".")[0] === "www");

    if (!inLocalHost && !inTenant) {
      localStorage.clear();
      console.log("redirige LoginPage");
      router.push("/home");
    }
  },
};
</script>
