<template>
  <div class="submenu-page">
    <a-menu
      class="menu"
      :selectedKeys="[currentMenu]"
      @update:selectedKeys="updateMenu"
      mode="inline"
    >
      <template v-for="menu in menus" :key="menu.key">
        <a-menu-item :key="menu.key" v-if="!menu.needPermissions"> {{ menu.name }} </a-menu-item>
      </template>
    </a-menu>

    <TabsContainer v-if="currentMenuData?.tabs" :tabs="currentMenuData.tabs" class="main">
      <template v-for="tab in currentMenuData?.tabs" #[tab.key]>
        <slot :name="tab.key" />
      </template>
    </TabsContainer>

    <!-- <platform-settings-template /> -->
    <MainContainer v-else class="main">
      <template v-for="section in currentMenuData?.sections" :key="section.title">
        <div
          :id="`section-${section.key}`"
          class="content-wrapper"
          :class="{ 'whole-page': currentMenuData?.wholePage }"
          v-if="!section.needPermissions"
        >
          <h2 class="title">
            <template v-if="section?.icon">
              <img
                v-if="typeof section?.icon === 'string'"
                :src="section?.icon"
                :alt="`${section.title}-icon`"
                class="icon icon__img"
              />
              <component v-else :is="section?.icon" class="icon icon__component" />
            </template>

            {{ section?.title }}

            <a-switch
              v-if="section?.onActiveChange"
              :checked="section.isActive"
              size="small"
              style="margin-left: 8px"
              @update:checked="section.onActiveChange"
            />

            <a-tooltip v-if="section?.tooltip" :title="section?.tooltip">
              <InfoCircleOutlined />
            </a-tooltip>

            <a-tag class="text" v-if="section.tag">
              {{ section.tag.label }}

              <a-tooltip v-if="section.tag.tooltip" :title="section.tag.tooltip">
                <InfoCircleOutlined />
              </a-tooltip>
            </a-tag>
          </h2>

          <a-divider class="divider" />

          <div
            class="section"
            :class="{ 'section--margin': Number(currentMenuData?.sections?.length) > 1 }"
          >
            <slot :name="section.key" />
          </div>
        </div>
      </template>
    </MainContainer>
  </div>
</template>

<script setup lang="ts" generic="T, U, V extends string | number">
import { computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { SubmenuData } from "@/@types/shared/pickers";

import MainContainer from "@/app/shared/components/organisms/MainContainer.vue";
import TabsContainer from "@/app/shared/components/organisms/TabsContainer.vue";

const props = defineProps<{
  menus: SubmenuData<T, U, V>[];
  currentMenu: T;
}>();

const emit = defineEmits<{
  "update:currentMenu": [newValue: T];
}>();

const router = useRouter();
const route = useRoute();

function isMenuType(key: unknown): key is T {
  return props.menus.some((m) => m.key === key);
}

watch(
  () => props.currentMenu,
  (newMenu) => {
    router.push({ query: { key: String(newMenu) } });
  },
  { immediate: true }
);

watch(
  () => route.query.key,
  (newQueryKey) => {
    if (isMenuType(newQueryKey)) emit("update:currentMenu", newQueryKey);
  }
);

onMounted(() => {
  const queryMenuKey = route.query.key;

  if (!queryMenuKey) return;

  if (isMenuType(queryMenuKey)) emit("update:currentMenu", queryMenuKey);
});

const updateMenu = (newValue: T[]) => emit("update:currentMenu", newValue[0]);

const currentMenuData = computed(() => props.menus.find((m) => m.key === props.currentMenu));
</script>
<style lang="sass" scoped>
$padding-border: 20px

.submenu-page
  display: grid
  height: 100%
  grid-template-columns: 220px minmax(0, 1fr)

.menu
  margin: 12px 0 !important
  padding-top: 10px !important
  border: 1px solid $gray-5 !important
  border-left: none !important
  border-radius: 0 10px 10px 0
  // flex: 0 0 220px

.content-wrapper
  display: flex
  flex-direction: column

  &.whole-page
    flex: 1 1 auto

.main
  flex: 1 1 auto
  padding: 20px
  overflow-y: scroll

  .title
    font-size: 20px
    font-weight: 600
    line-height: 24px
    margin: 0
    display: flex
    align-items: center
    padding: 0 $padding-border
    .text
      background-color: $gray-2
      font-size: $font-small
      border-radius: 4px
      border: 2px solid $gray-4
      margin-left: 7px
      padding: 0 5px 0

.divider
  margin: 16px 0
  background-color: $gray-4

.section
  padding: 0 $padding-border
  flex: 1 1 auto

  &--margin
    margin-bottom: 32px

.icon
  margin-right: 8px

  &__component
    font-size: 20px

  &__img
    height: 24px
</style>
