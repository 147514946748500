export const ImportType = {
  Clients: 1,
  Products: 2,
  ProductsDetail: 3,
  Phones: 4,
  Addresses: 5,
  Emails: 6,
  Contacts: 7,
  Payments: 8,
  ExternalTypifications: 9,

  // Procesos masivos
  AgentsAssignment: 10,
  ActivateAccounts: 11,
  InactivateAccounts: 12,
  ActiveClients: 13,
  InactiveClients: 14,
  ActivatePhones: 15,
  InactivatePhones: 16,
  ContactsSequence: 18,
};

export const ImportAssignmentType = {
  Random: 1,
  ByAssignment: 2,
  AddNewByAssignment: 3,
};

export const ImportStatus = {
  Waiting: "En espera",
  Success: "Exitoso",
  Error: "Error",
};
