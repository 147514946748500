import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una entidad.
 *
 * @param {number} id Iedntificador de la entidad.
 * @param {FormData} params Datos modificados de la entidad.
 *
 * @return {Promise<Object>} Entidad editada.
 */
export default async function (id, params) {
  const body = { ...params };

  body.rules = body.dialing_rules;
  delete body.dialing_rules;

  const response = await httpManager.put(`/pbx/dialing-plans/${id}/`, body);
  return response?.data;
}
