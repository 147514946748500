import { SET_CODECS, ADD_CODEC_TO_LIST } from "./mutation-types";

const mutations = {
  [SET_CODECS](state, codecs) {
    state.codecs = codecs;
  },
  [ADD_CODEC_TO_LIST](state, newCodec) {
    state.codecs.push(newCodec);
  },
};

export default mutations;
