import monitorStore from "@/app/supervision/store/monitor";
import viewerStore from "@/app/supervision/store/viewer";
import recordsStore from "@/app/supervision/store/records";

export default {
  modules: {
    monitorStore,
    viewerStore,
    recordsStore,
  },
};
