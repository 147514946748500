import { fetchTags, addTag, updateTag, deleteTag } from "@/app/platformSettings/services";

import { SET_TAGS } from "./mutation-types";

const actions = {
  async fetchTags({ commit }, name) {
    const tags = await fetchTags({ name });
    commit(SET_TAGS, tags);
  },
  async addTag(_, tag) {
    await addTag(tag);
  },
  async updateTag(_, { tagId, tagData }) {
    await updateTag(tagId, tagData);
  },
  async deleteTag(_, tagId) {
    await deleteTag(tagId);
  },
};

export default actions;
