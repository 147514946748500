<template>
  <a-modal
    centered
    width="900px"
    :visible="open"
    :body-style="{ height: '680px' }"
    :footer="showFooter"
    @cancel="onClose"
  >
    <h3 class="modal-name">{{ modalTitle }}</h3>

    <section class="modal-content">
      <Transition name="web-chat-fade" mode="in-out">
        <div v-if="!webChatCreatedSuccessfully">
          <!-- STEPS WHEN IS CREATE -->
          <a-steps v-if="!isEdit" progress-dot :current="currentStep">
            <a-step title="Perfil" />
            <a-step title="Visibilidad" />
            <a-step title="Adicionales" />
            <a-step title="Instalación" />
          </a-steps>

          <!-- TABS WHEN IS EDIT -->
          <div v-if="webChatLoadedSuccessfully" class="web-chat-modal-content">
            <a-tabs :class="{ 'hide-tabs': !isEdit }" v-model:activeKey="currentStep">
              <a-tab-pane :key="0" tab="Perfil">
                <Step1
                  ref="stepOneRefComponent"
                  :avatar="webChatData.avatarUrl"
                  :alias="webChatData.title"
                  :color="webChatData.color"
                  @webChatOptionsUpdated="getAndValidateStepOneData"
                />
              </a-tab-pane>
              <a-tab-pane :key="1" tab="Visibilidad">
                <Step2
                  ref="stepTwoRefComponent"
                  :domains="domainsList"
                  :showInMobile="webChatData.isResponsive"
                  :chatPosition="webChatData.position"
                  :hasWatermark="webChatData.isFreeVersion"
                  @webChatOptionsUpdated="getAndValidateStepTwoData"
                />
              </a-tab-pane>
              <a-tab-pane :key="2" tab="Adicionales">
                <Step3
                  ref="stepThreeRefComponent"
                  :resetTime="webChatData.timeOfLifeAfterLastMessage"
                  :hasWelcomeMsg="webChatData.isWelcomeMessageVisible"
                  :welcomeMsg="webChatData.welcomeMessage"
                  :goodbyeMsg="webChatData.messageToFinish"
                  @webChatOptionsUpdated="getAndValidateStepThreeData"
                />
              </a-tab-pane>
              <a-tab-pane :key="3" tab="Instalación">
                <Step4 :token="token" />
              </a-tab-pane>
            </a-tabs>
            <div>
              <div :class="{ 'tabs-spacer': isEdit }"></div>
              <ChatWindowPreview
                :color="stepOneData.color"
                :avatar="stepOneData.avatarUrl"
                :alias="stepOneData.title"
                :hasWatermark="stepTwoData.isFreeVersion"
                :hasWelcomeMsg="
                  stepThreeData?.isWelcomeMessageVisible
                    ? stepThreeData?.isWelcomeMessageVisible
                    : webChatData.isWelcomeMessageVisible
                "
                :welcomeMsg="
                  stepThreeData?.welcomeMessage
                    ? stepThreeData.welcomeMessage
                    : webChatData.welcomeMessage
                "
              />
            </div>
          </div>
        </div>

        <CompleteIntegration
          v-else
          description="Tu nueva página de WebChat se encuentra lista"
          type="inbound"
        />
      </Transition>
    </section>

    <template #footer>
      <!-- BUTTONS WHEN IS CREATE -->
      <div v-if="!isEdit" class="web-chat-modal-buttons">
        <a-button v-if="currentStep !== 3" :disabled="currentStep === 0" @click="onPreviousStep">
          Volver
        </a-button>
        <a-button
          v-if="currentStep !== 3"
          type="primary"
          @click="onNextStep"
          :disabled="canContinue"
        >
          <span> {{ labelBtnSteps }} </span>
        </a-button>
        <a-button v-else type="primary" @click="onFinish"> Cerrar </a-button>
      </div>

      <!-- BUTTONS WHEN IS EDIT -->
      <div v-else class="web-chat-modal-buttons">
        <a-button type="primary" @click="stepMetadata?.click">
          {{ stepMetadata?.label }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, toRefs, createVNode, watch, computed, onMounted } from "vue";

import { useStore } from "vuex";

import { Modal, message } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

import { ChannelType } from "@/app/shared/utils/enums";
import ChatWindowPreview from "../webChat/complements/ChatWindowPreview.vue";
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";

import Step1 from "../webChat/steps/Step1.vue";
import Step2 from "../webChat/steps/Step2.vue";
import Step3 from "../webChat/steps/Step3.vue";
import Step4 from "../webChat/steps/Step4.vue";

import { createInboundToken, updateWebChat, getWebChat } from "@/app/channels/services";

const store = useStore();

const props = defineProps({
  open: {
    required: true,
    type: Boolean,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  channelData: {
    type: Object,
    default: () => ({}),
  },
});

const { open, isEdit, channelData } = toRefs(props);

const userData = computed(() => store.getters["getDataUser"]);

const modalTitle = computed(() => (isEdit.value ? "Editar webchat" : "Crear webchat"));

// GET WEB CHAT WHEN IS EDIT
const webChatData = ref({});

onMounted(async () => {
  if (isEdit.value) {
    webChatData.value = await getWebChat(channelData.value.data_parameters.token);
    formattingDomains(webChatData.value.domains);
  }
  webChatLoadedSuccessfully.value = true;
});

// STEPS EVENTS
const currentStep = ref(0);

const canContinue = computed(() => {
  return currentStep.value === 0 ? stepOneData.value.title === "" : false;
});

const labelBtnSteps = computed(() => (currentStep.value === 2 ? "Crear" : "Continuar"));

// EVENTS BUTTONS WHEN IS CREATE
const onNextStep = async () => {
  switch (currentStep.value) {
    case 0:
      await stepOneRefComponent.value.submitForm();
      if (stepOneData.value?.isValid) return (currentStep.value += 1);
      break;

    case 1:
      await stepTwoRefComponent.value.submitForm();
      if (stepTwoData.value?.isValid) return (currentStep.value += 1);
      break;

    case 2:
      await stepThreeRefComponent.value.submitForm();
      if (stepThreeData.value?.isValid) {
        confirmCreate();
      }
      break;

    default:
      break;
  }
};

const onPreviousStep = () => {
  currentStep.value -= 1;
};

// ----- STEP 1 EVENTS
const stepOneRefComponent = ref();
const stepOneData = ref({
  avatarUrl: "",
  title: "",
  color: "#262A31",
});
const canNextStep2 = ref(false);

const getAndValidateStepOneData = (event) => {
  stepOneData.value = event;
  canNextStep2.value = stepOneData.value.title !== "";
};

watch(
  stepOneData,
  (newVal, oldVal) => {
    validateChanges(newVal, oldVal);
  },
  { deep: true }
);

// ----- STEP 2 EVENTS
const stepTwoRefComponent = ref();
const stepTwoData = ref({});
const domainsList = ref([{}]);

const getAndValidateStepTwoData = (event) => {
  stepTwoData.value = event;
};

const formattingDomains = (webChatDomains) => {
  domainsList.value = webChatDomains.split(",").map((ele) => {
    return {
      key: ele,
      value: ele,
    };
  });
};

watch(
  stepTwoData,
  (newVal, oldVal) => {
    validateChanges(newVal, oldVal);
  },
  { deep: true }
);

// ----- STEP 3 EVENTS
const stepThreeRefComponent = ref();
const stepThreeData = ref({});

const getAndValidateStepThreeData = (event) => {
  stepThreeData.value = event;
};

watch(
  stepThreeData,
  (newVal, oldVal) => {
    validateChanges(newVal, oldVal);
  },
  { deep: true }
);

const validateChanges = (newVal, oldVal) => {
  if (isEdit.value) {
    for (const key in webChatData.value) {
      if (Object.hasOwnProperty.call(newVal, key)) {
        const hasChanged = newVal[key] !== webChatData.value[key];
        if (hasChanged) {
          showModalConfirm.value = true;
          return;
        }
      }
    }
  } else {
    for (const key in newVal) {
      const hasChanged = newVal[key] !== oldVal[key];
      if (hasChanged) {
        showModalConfirm.value = true;
        return;
      }
    }
  }
};

// VALIDATE CHANGES MODAL
const showModalConfirm = ref(false);

const emit = defineEmits(["close", "update"]);

const onCloseModal = () => {
  emit("close");
};

const onClose = () => {
  // Si la data de los pasos 1 - 3 cambió y no se ha creado el token de web chat
  if (showModalConfirm.value && currentStep.value <= 2) {
    Modal.confirm({
      title: "¿Deseas descartar los cambios realizados?",
      content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
      icon: createVNode(QuestionCircleOutlined),
      onOk: () => onCloseModal(),
      centered: true,
      okText: "Sí, descartar cambios",
      cancelText: "No, seguir editando",
    });
  } else {
    onCloseModal();
  }
};

// CONFIRM CREATE
const confirmCreate = () => {
  Modal.confirm({
    title: "Confirmar creación",
    content: "Al confirmar esta acción, se creará el canal.",
    icon: null,
    onOk: async () => {
      await onUpdateCreateWebChat();
      currentStep.value += 1;
    },
    centered: true,
    okText: "Sí, crear",
    cancelText: "No, cancelar",
  });
};

// SET WEB CHAT TOKEN
const webChatToken = ref("");
webChatToken.value = channelData.value?.data_parameters?.token || "";

const onCreateWebChatToken = async () => {
  try {
    const token = await createInboundToken();
    webChatToken.value = token;
  } catch (error) {
    // const errorMessage = error?.getErrorMessage?.() || "Ha ocurrido un error";
    message.error(errorMessage);
  }
};

const token = computed(() => channelData.value?.data_parameters?.token || webChatToken.value);

const channelCreated = ref({});

const onCreateInboundScoreChannel = async () => {
  const createChPayload = {
    channel: ChannelType.Inbound,
    data_parameters: {
      token: webChatToken.value,
      color: stepOneData.value.color,
      domain: stepTwoData.value.domains.map((d) => d.value).toString(),
      welcome_message: stepThreeData.value.welcomeMessage,
      alias: stepOneData.value.title,
    },
    provider: 6,
  };

  channelCreated.value = await store.dispatch("createNewChannel", createChPayload);
};

const onUpdateInboundScoreChannel = async () => {
  const body = {
    id: channelData.value.id,
    channel: ChannelType.Inbound,
    domain: domainsList.value.map((d) => d.value).toString(),
    alias: stepOneData.value.title,
    color: webChatData.value.color,
    welcome_message: webChatData.value.welcomeMessage,
    provider: 6,
    data_parameters: {
      token: webChatToken.value,
    },
  };

  try {
    await store.dispatch("updateChannelCreate", body);
    message.success("Se han guardado los cambios correctamente de tu canal");
    emit("close");
  } catch (error) {
    const errorMessage = error?.getErrorMessage() || "Ha ocurrido un error";
    message.warning(errorMessage);
  }
};

// ---- CREATE WEB-CHAT (UPDATE)
const webChatCreatedSuccessfully = ref(false);
const showFooter = computed(() => (webChatCreatedSuccessfully.value ? null : undefined));

const onUpdateCreateWebChat = async () => {
  const payloadDomains = isEdit.value
    ? domainsList.value.map((d) => d.value).toString()
    : stepTwoData.value.domains.map((d) => d.value).toString();

  const webChatPayload = {
    ownerId: userData.value.id,
    channelId: channelCreated.value.id,
    token: channelData.value?.data_parameters?.token,
    avatarUrl: stepOneData.value.avatarUrl,
    title: stepOneData.value.title,
    color: stepOneData.value.color,

    domains: payloadDomains,
    isResponsive: stepTwoData.value.isResponsive,
    position: stepTwoData.value.position,
    isFreeVersion: stepTwoData.value.isFreeVersion,

    timeOfLifeAfterLastMessage: stepThreeData.value.timeOfLifeAfterLastMessage,
    isWelcomeMessageVisible: stepThreeData.value.isWelcomeMessageVisible,
    welcomeMessage: stepThreeData.value.welcomeMessage,
    messageToFinish: stepThreeData.value.messageToFinish,
  };

  try {
    if (isEdit.value) {
      onUpdateInboundScoreChannel();
      await updateWebChat(webChatPayload);
      emit("update");
    } else {
      // 1- se crea el token del web chat
      await onCreateWebChatToken();

      webChatPayload.token = webChatToken.value;
      // 2- se crea el canal en score con el token de web chat
      await onCreateInboundScoreChannel();

      // 3- se actualiza el web chat creado con el channel id del canal en score
      await updateWebChat(webChatPayload);
    }
  } catch (error) {
    console.log(error);
    const errorMessage = error?.getErrorMessage?.() || "Ha ocurrido un error";
    message.error(errorMessage);
    throw error;
  }
};

const onFinish = () => (webChatCreatedSuccessfully.value = true);

// ---- EDIT WEB-CHAT
const webChatLoadedSuccessfully = ref(false);

/* ------ IS EDIT----
 Factory pattern to handle functions and labels buttons when is EDIT */
const factory = (currStep) => {
  const options = {
    0: {
      label: "Guardar perfil",
      click: () => {
        onUpdateCreateWebChat();
      },
    },
    1: {
      label: "Guardar visibilidad",
      click: () => {
        onUpdateCreateWebChat();
      },
    },
    2: {
      label: "Guardar adicionales",
      click: () => {
        onUpdateCreateWebChat();
      },
    },
    3: {
      label: "Cerrar",
      click: () => {
        emit("close");
      },
    },
  };

  return options[currStep];
};

const stepMetadata = ref(factory(currentStep.value));

watch(currentStep, () => {
  stepMetadata.value = factory(currentStep.value);
});
</script>

<style lang="sass" scoped>
.modal-content
  padding: 0px 40px
.web-chat-modal-content
  margin-top: 30px
  min-height: 500px
  display: grid
  grid-template-columns: 1fr 316px
  gap: 50px

.hide-tabs:deep(.ant-tabs-nav)
  display: none

.tabs-spacer
  height: 62px

.channelsinbound
  padding: 0px 20px 40px
.steps-action
  width: 350px
  margin: auto
  margin-top: $margin-small
  margin-bottom: 50px
  text-align: end
  button
    margin-right: $margin-small
.inbountChannels
  margin: auto
  margin-top: $margin-medium
  box-sizing: border-box
  min-height: 200px
// .button
//   border-radius: 2px
.buttons-selectColor, .buttons-install
  position: relative
.buttons-selectColor
  left: 102px
.buttons-install
  left: 86px
.container
  display: flex
  flex-direction: column
  align-items: center
.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  text-align: center
  color: $gray-9
.link
  color: $blue-6
  margin-bottom: 0px
  margin-left: $margin-tiny
  font-weight: normal

// TRANSITION
.web-chat-fade-enter-active,
.web-chat-fade-leave-active
  transition: opacity 0.3s ease

.web-chat-fade-enter-from,
.web-chat-fade-leave-to
  opacity: 0
</style>
