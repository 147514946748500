import { RESET_STATE, SET_PROPERTIES, SET_AGENTS_QUEUE } from "./mutation-types";
import { initialState } from "./state";

const mutations = {
  [RESET_STATE](state) {
    const initial = initialState();
    for (const key in initial) {
      state[key] = initial[key];
    }
  },
  [SET_PROPERTIES](state, properties) {
    for (const key in properties) {
      state[key] = properties[key];
    }
  },
  [SET_AGENTS_QUEUE](state, agentsQueue) {
    state.agentsQueue = agentsQueue;
  },
};

export default mutations;
