<template>
  <div class="information">
    <div class="information__password">
      <span>
        Si consideras que no volverás a usar {{ ProductName }} de nuevo, puedes solicitar que tu
        ambiente de trabajo se elimine difinitivamente. Recuerda que después de 7 días, no podrás
        reactivar ni recuperar la información de tu empresa.
      </span>
    </div>
    <a-button class="button__delete" type="danger" @click="handleDeleteWorkTeam">
      Eliminar ambiente de trabajo
    </a-button>
  </div>

  <ModalDeleteWorkTeam
    :visible="visibleModalWorkTeam"
    @onCloseModal="handleCloseModal"
    :record="userData"
  />
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { ProductName } from "@/app/shared/utils/constants";

import ModalDeleteWorkTeam from "@/app/settingsMenu/components/modal/ModalDeleteWorkTeam.vue";

export default defineComponent({
  components: {
    ModalDeleteWorkTeam,
  },
  setup() {
    const store = useStore();

    const userData = computed(() => store.getters["getDataUser"]);

    return { userData, ProductName };
  },
  data() {
    return {
      visibleModalWorkTeam: false,
    };
  },
  methods: {
    handleCloseModal() {
      this.visibleModalWorkTeam = false;
    },
    handleDeleteWorkTeam() {
      this.visibleModalWorkTeam = true;
    },
  },
});
</script>

<style scoped lang="sass">
.text__strong
  font-weight: 600
  font-size: 16px

.text__info
  // margin-top: 30px

.input__item
  display: flex
  align-items: center
  gap: 8px

  p
    margin: 0

.inputNumber
  width: 80px
  margin-right: 8px

.disabled
  cursor: not-allowed
  color: rgba(0, 0, 0, 0.25)

.information__password
  width: 70%
  line-height: 25px

.button__delete
  background: $red-6
  color: white
  margin-top: 20px

.button__inactivity
  margin-top: 20px
  button
    margin-right: 10px
</style>
