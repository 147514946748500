import {
  fetchAllChannelsCustomers,
  fetchAllChannelsCustomersByPortfolio,
  fetchChannelCustomer,
  fetchAllWebhookProviderWhatsapp,
  createChannel,
  deleteChannel,
  updateChannelCreate,
  sendInboundInstructions,
  deleteInboundChannel,
} from "@/app/channels/services";

import { InstagramManager, MessengerManager } from "@/app/shared/utils/loadFacebook";

import {
  SET_CHANNELS_CUSTOMERS,
  SET_WEBHOOK_PROVIDER_WHATSAPP,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
} from "./mutation-types";

// import {
//   fbLogin,
//   fbLongUserAccessToken,
//   fbUserInformation,
//   fbAccounts,
//   fbSubscribeApps,
// } from "@/app/channels/utils/facebookManager";

import { ChannelType } from "@/app/shared/utils/enums";
import { ErrorTypes } from "@/app/channels/utils/enums";

const actions = {
  async fetchAllChannelsCustomers({ commit }, filters) {
    const channels = await fetchAllChannelsCustomers(filters?.channelId || "");
    commit(SET_CHANNELS_CUSTOMERS, channels);
  },
  async fetchAllWebhookProviderWhatsapp({ commit }, providerId) {
    const wewbhook = await fetchAllWebhookProviderWhatsapp(providerId);
    commit(SET_WEBHOOK_PROVIDER_WHATSAPP, wewbhook);
  },
  async createNewChannel({ commit, rootGetters }, channel) {
    const formattedBody = { ...channel };
    const { id, fullname, first_name, last_name, email, phone } = rootGetters["getDataUser"];
    const sc_user = { id, fullname, first_name, last_name, email, phone };

    formattedBody.data_parameters = { ...channel.data_parameters, sc_user };

    console.log("createNewChannel", { formattedBody });

    const newChannel = await createChannel(formattedBody);
    console.log(newChannel, "newChannel");
    commit(ADD_CHANNEL, newChannel.data);
    return newChannel;
  },
  async createNewMessengerChannel({ commit }, { channel, userData = {} }) {
    const formattedBody = { ...channel };
    const { id, fullname } = userData;
    const sc_user = { id, fullname };

    const pagesWithSCUser = channel.data_parameters.pages.map((page) => ({
      ...page,
      sc_user,
    }));

    formattedBody.data_parameters.pages = pagesWithSCUser;

    console.log("createNewMessengerChannel", { formattedBody });

    const newChannel = await createChannel(formattedBody);
    commit(ADD_CHANNEL, newChannel.data);
    return newChannel;
  },
  async deleteChannel({ commit }, channelId) {
    await deleteChannel(channelId);
    commit(REMOVE_CHANNEL, channelId);
  },
  async deleteInboundChat(_, token) {
    await deleteInboundChannel(token);
  },
  async updateChannelCreate(_, channel) {
    await updateChannelCreate(channel.id, channel);
  },

  async loginWithFacebook({ dispatch }, userData) {
    let fbAuthResponse = null;
    let responseToken = null;
    let userInformation = null;

    try {
      fbAuthResponse = await MessengerManager.login({ pageScope: true });
      responseToken = await MessengerManager.getLongAccessToken({
        accessToken: fbAuthResponse.authResponse.accessToken,
      });
      userInformation = await MessengerManager.getUserData({
        userId: fbAuthResponse.authResponse.userID,
      });
    } catch (e) {
      throw new Error("No se pudo autenticar la cuenta");
    }

    // List of checked current user pages in Facebook
    const responseAccounts = await MessengerManager.getUserPages({
      userId: fbAuthResponse.authResponse.userID,
      userAccessToken: responseToken.access_token,
    });

    console.log({ responseAccounts });

    if (!responseAccounts) {
      console.log("No has seleccionado ninguna página", responseAccounts);
      return;
    }

    // List of all users messenger channels in ScoreCloud
    const customerMessengerChannels = await fetchAllChannelsCustomers(ChannelType.Messenger);

    console.log({ customerMessengerChannels });

    // List of checked page that does not exist in ScoreCloud channels
    const filteredResponseAccounts = responseAccounts.data.filter(
      (fbPage) =>
        !customerMessengerChannels?.some(
          (messengerChannel) => messengerChannel.data_parameters.id === fbPage.id
        )
    );

    console.log({ filteredResponseAccounts });

    if (filteredResponseAccounts.length) {
      const pagesFB = filteredResponseAccounts.map((item) => {
        const obj = {
          id: item.id,
          name: item.name,
          token: item.access_token,
          picture: item.picture,
          fb_user: userInformation,
          // alias: item.name,
        };
        return obj;
      });

      const subscriptionsFb = [];

      for (const item of pagesFB) {
        subscriptionsFb.push(
          MessengerManager.subscribePageToApp({
            pageId: item.id,
            pageAccessToken: item.token,
          })
        );
      }

      try {
        await Promise.all(subscriptionsFb);
      } catch (e) {
        console.log(e);
        throw Error("No existen los permisos suficientes para conectar tu página");
      }

      const channel = {
        channel: ChannelType.Messenger,
        data_parameters: {
          pages: pagesFB,
        },
        //queue: 1,
        provider: 3,
      };

      const response = await dispatch("createNewMessengerChannel", { channel, userData });

      if (response.status == 201) {
        return response;
      } else if (response.status == 400) {
        throw new Error(response.message);
      } else {
        throw new Error("Error no es posible conectar con Facebook");
      }
    } else {
      console.log("No se seleccionó ninguna página nueva");
    }
  },

  async deleteFacebookPage({ dispatch }, channelId) {
    console.log("deleteFacebookPage ->", { channelId });
    // let fbAuthResponse = null;
    // let userInformation = null;
    //
    //
    // try {
    //   // fbAuthResponse = await MessengerManager.login({ pageScope: false });
    //   fbAuthResponse = await MessengerManager.getAuthResponse();
    //   userInformation = await MessengerManager.getUserData({
    //     userId: fbAuthResponse.authResponse.userID,
    //   });
    // } catch (e) {
    //   throw new Error("No se pudo autenticar la cuenta");
    // }
    //
    // if (!fbAuthResponse) throw new Error("Sin respuesta de autenticación");
    //
    let messengerChannelToDelete = null;

    try {
      messengerChannelToDelete = await fetchChannelCustomer(channelId);
    } catch (error) {
      throw Error("No se encontró el canal");
    }

    // if (messengerChannelToDelete?.data_parameters?.fb_user?.id === userInformation?.id) {
    try {
      // await MessengerManager.login({ pageScope: true });
      await MessengerManager.unlinkPageFromApp({
        pageId: messengerChannelToDelete.data_parameters.id,
        pageAccessToken: messengerChannelToDelete.data_parameters.token,
      });
      await dispatch("deleteChannel", channelId);
    } catch (error) {
      throw Error("No se pudo borrar el canal");
    }
    // } else {
    //   throw Error(ErrorTypes.NotMarchingFbUser);
    // }
  },

  async loginWithInstagram({ dispatch }, userData) {
    let fbAuthResponse = null;
    let responseToken = null;
    let userInformation = null;

    try {
      fbAuthResponse = await InstagramManager.login({ pageScope: true });
      responseToken = await InstagramManager.getLongAccessToken({
        accessToken: fbAuthResponse.authResponse.accessToken,
      });
      userInformation = await InstagramManager.getUserData({
        userId: fbAuthResponse.authResponse.userID,
      });
    } catch (e) {
      throw new Error("No se pudo autenticar la cuenta");
    }

    const IgPages = await InstagramManager.getUserPages({
      userId: fbAuthResponse.authResponse.userID,
      userAccessToken: responseToken.access_token,
    });

    if (!IgPages) {
      console.log("No has seleccionado ninguna página", IgPages);
      return;
    }

    // List of all users instagram channels in ScoreCloud
    const customerInstagramChannels = await fetchAllChannelsCustomers(ChannelType.Instagram);

    console.log({ customerInstagramChannels });

    // List of checked page that does not exist in ScoreCloud channels
    const filteredResponsePages = IgPages.data.filter(
      (igPage) =>
        !customerInstagramChannels.some(
          (instagramChannel) =>
            instagramChannel.data_parameters.id === igPage.instagram_business_account
        )
    );

    console.log({ filteredResponsePages });

    if (filteredResponsePages.length) {
      const pagesIG = filteredResponsePages.map((item) => {
        const obj = {
          id: item.instagram_business_account,
          linked_fb_page_id: item.id,
          name: item.ig_username,
          token: item.access_token,
          picture: item.picture,
          fb_user: userInformation,
          // alias: item.ig_username,
        };
        return obj;
      });

      const subscriptionsIg = [];

      for (const item of pagesIG) {
        subscriptionsIg.push(
          InstagramManager.subscribePageToApp({
            pageId: item.linked_fb_page_id,
            pageAccessToken: item.token,
          })
        );
      }

      try {
        await Promise.all(subscriptionsIg);
      } catch (e) {
        console.log(e);
        throw Error("No existen los permisos suficientes para conectar tu página");
      }

      const channel = {
        channel: ChannelType.Instagram,
        data_parameters: {
          pages: pagesIG,
        },
        //queue: 1,
        provider: 3,
      };

      console.log("CHANNEL DATA", { channel, userData });

      const response = await dispatch("createNewMessengerChannel", { channel, userData });

      if (response.status == 201) {
        return response;
      } else if (response.status == 400) {
        throw new Error(response.message);
      } else {
        throw new Error("Error no es posible conectar con Facebook");
      }
    } else {
      console.log("No se seleccionó ninguna página nueva");
    }
  },

  async deleteInstagramPage({ dispatch }, channelId) {
    // let fbAuthResponse = null;
    // let userInformation = null;
    //
    // console.log("deleteInstagramPage ->", { channelId });
    //
    // try {
    //   fbAuthResponse = await InstagramManager.login({ pageScope: false });
    //   userInformation = await InstagramManager.getUserData({
    //     userId: fbAuthResponse.authResponse.userID,
    //   });
    // } catch (e) {
    //   throw new Error("No se pudo autenticar la cuenta");
    // }

    let instagramChannelToDelete = null;

    try {
      instagramChannelToDelete = await fetchChannelCustomer(channelId);
    } catch (error) {
      throw Error("No se encontró el canal");
    }

    // console.log(instagramChannelToDelete, userInformation);

    // if (instagramChannelToDelete?.data_parameters?.fb_user?.id === userInformation?.id) {
    try {
      // await InstagramManager.login({ pageScope: true });
      await InstagramManager.unlinkPageFromApp({
        pageId: instagramChannelToDelete.data_parameters.linked_fb_page_id,
        pageAccessToken: instagramChannelToDelete.data_parameters.token,
      });
      await dispatch("deleteChannel", channelId);
    } catch (error) {
      throw Error("No se pudo borrar el canal");
    }
    // } else {
    //   throw Error(ErrorTypes.NotMarchingFbUser);
    // }
  },

  async sendInboundInstructions(_, body) {
    await sendInboundInstructions(body);
  },

  async fetchAllChannelsCustomersByPortfolio({ commit }, filters) {
    const channels = await fetchAllChannelsCustomersByPortfolio(filters?.portfolioId || "");
    commit(SET_CHANNELS_CUSTOMERS, channels);
  },
};

export default actions;
