const initialState = () => ({
  trunksInfo: {
    pagination: {},
    items: [],
  },
  trunk: undefined,
});

const state = initialState();
export default state;
