<template>
  <a-popover
    v-model:open="showEmojiPopover"
    :title="null"
    overlayClassName="emoji-popover"
    trigger="click"
    placement="topLeft"
    arrowPointAtCenter
  >
    <ActionButton
      type="emoji"
      @click="handleShowPicker"
      preventEvent
      :disabled="props.disabled"
      :icon-styles="props.iconStyles"
      :from-ant="iconFromAnt"
    />

    <template #content>
      <EmojiPicker
        native
        hide-search
        hide-group-icons
        disable-skin-tones
        :group-names="groupNames"
        @select="handleChangeEmoji"
      />
    </template>
  </a-popover>
</template>

<script setup lang="ts">
import { ref } from "vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import ActionButton from "@/app/workspace/components/chat/input/ActionButton.vue";
import { groupNames } from "@/app/workspace/utils/emojiPickerOptions";
import type { Emoji } from "@/@types/workspace";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  iconStyles: {
    type: Object,
    required: false,
  },
  iconFromAnt: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["onChangeEmoji", "handleShowPicker"]);

const showEmojiPopover = ref<boolean>(false);

const handleShowPicker = () => {
  if (props.disabled) return;
  console.log("is here");
  showEmojiPopover.value = true;
  emits("handleShowPicker");
};

const handleChangeEmoji = (emojiMetadata: Emoji) => {
  if (props.disabled) return;
  emits("onChangeEmoji", emojiMetadata);
  showEmojiPopover.value = false;
};
</script>

<style>
.emoji-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.v3-emoji-picker {
  box-shadow: none !important;
}

.v3-emoji-picker.v3-color-theme-light .v3-footer {
  display: none;
}
</style>
