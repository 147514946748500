import httpManager from "@/app/shared/utils/httpManager";
/**
 * Valida el token para restaurar contraseña.
 *
 * @return {Promise<object>} Aprobación del token.
 *
 * @param {Object} token Token del usuario.
 */
export async function validateToken(token) {
  const url = "/users/password/validate_token/";
  const response = await httpManager.post(url, { token }, { isPublic: true });
  return response.data;
}
