import { ADD_IMPORT, SET_IMPORTS } from "./mutation-types";

const mutations = {
  [SET_IMPORTS](state, payload) {
    state.imports = payload;
  },
  [ADD_IMPORT](state, item) {
    state.imports.push(item);
  },
};

export default mutations;
