import httpManager from "@/app/shared/utils/httpManager";

/**
 * Subir avatar
 *
 * @param {FormData} formData Datos del formulario del avatar.
 *
 * @return {Promise<Object>} Avatar creado.
 */

export interface IResponse {
  file: string;
  name: string;
}

export default async function (body: any): Promise<IResponse> {
  const response = await httpManager.upload("/channels/webchat/upload-avatar/", body);
  return response?.data;
}
