import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener el listado de etiquetas.
 *
 * @return {Promise<Array>}  Lista de etiquetas
 */
export async function fetchListTags() {
  const response = await httpManager.get("/tickets/tags/");
  return response.data?.results;
}
