import { stringToDate } from "@/app/shared/utils/dates";
export function mapTypifications(tree) {
  return tree.map((group) => {
    return {
      ...group,
      group_type: group.type,
      type: "group",
      children: group?.results.map((result) => {
        return {
          ...result,
          group: group.id,
          type: "result",
          children: result?.motives.map((motive) => {
            return {
              ...motive,
              group: group.id,
              result: result.id,
              type: "motive",
              children: motive?.submotives.map((submotive) => {
                return {
                  ...submotive,
                  group: group.id,
                  result: result.id,
                  motive: motive.id,
                  type: "submotive",
                  children: null,
                };
              }),
            };
          }),
        };
      }),
    };
  });
}

export function mapToTypifications(tree) {
  return tree.map((group, groupIndex) => ({
    // Group
    id: group?.id || undefined,
    order: groupIndex,
    type: group.group_type,
    name: group.name,
    is_expanded: group.is_expanded,
    status: group.status,
    results: group?.children.map((result, resultIndex) => ({
      // Result
      id: result?.id || undefined,
      order: resultIndex,
      name: result.name,
      code: result.code,
      status: result.status,
      pdp: result.pdp,
      is_schedule: result.is_schedule,
      is_sale: result.is_sale,
      is_expanded: result.is_expanded,
      motives: result?.children.map((motive, motiveIndex) => ({
        // Motives
        id: motive?.id || undefined,
        order: motiveIndex,
        name: motive.name,
        code: motive.code,
        is_expanded: motive.is_expanded,
        status: motive.status,
        submotives: motive?.children.map((submotive, submotiveIndex) => ({
          // Submotives
          id: submotive?.id || undefined,
          order: submotiveIndex,
          name: submotive.name,
          code: submotive.code,
          is_expanded: submotive.is_expanded,
          status: submotive.status,
        })),
      })),
    })),
  }));
}

export function getLastModification(tree) {
  let array = [];
  console.log("TREE", tree);
  tree.map((group) => {
    array.push(stringToDate(group.updated_at).valueOf());
  });
  console.log("get last modification", array);
  return new Date(Math.max.apply(null, array));
}
