import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener un usuario mediante su id.
 *
 * @param {Number} id Datos del usuario a crear.
 *
 * @param {Object} userData Datos del usuario a crear.
 *
 * @return {Promise<Object>} Usuario por id
 */
export default async function (id, userData) {
  const response = await httpManager.put(`/users/${id}/`, userData);
  return response;
}
