<template>
  <div>
    <viewer-base-drawer
      :queue="record.queue"
      :portfolio="portfolio"
      :title="record.queue_name"
      slot-text="Chats en espera"
      @open="handleDrawerOpened"
      @update="fetchChats"
    >
      <template v-slot:waiting>
        <ChatOnHoldTable :datatable="chats" />
      </template>
    </viewer-base-drawer>
  </div>
</template>

<script>
import { fetchMessagesWaitingByQueue } from "@/app/supervision/services";
import ChatOnHoldTable from "../tables/tablesDrawer/ChatOnHoldTable.vue";
import ViewerBaseDrawer from "./ViewerBaseDrawer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ViewerBaseDrawer,
    ChatOnHoldTable,
  },
  props: {
    record: { required: true, type: Object },
    portfolio: { required: true, type: Number },
  },
  data() {
    return {
      chats: [],
    };
  },
  methods: {
    async fetchChats() {
      this.chats = await fetchMessagesWaitingByQueue(this.record.queue);
    },
    handleDrawerOpened() {
      this.fetchChats();
    },
  },
});
</script>

<style lang="sass" scoped>
.table__agent
  margin-top: 12px
</style>
