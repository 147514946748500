import httpManager from "@/app/shared/utils/httpManager";

export default async function ({ id, type }) {
  let url;
  switch (type) {
    case "result":
      url = `/entities/results/${id}/remove-form/`;
      break;
    case "motive":
      url = `/entities/motives/${id}/remove-form/`;
      break;
    case "submotive":
      url = `/entities/submotives/${id}/remove-form/`;
      break;
    default:
      break;
  }
  await httpManager.post(url, {});
}
