import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nueva direccion.
 * @param {number} ticketId Identificador del ticket.
 * @param {Object} addresse Datos de direccion.
 *
 * @return {Promise<Object>} Datos de la direccion creada
 */
export async function updateAddressesClient(id, addresse) {
  const url = `/workspace/tickets/addresses/${id}/`;
  const response = await httpManager.put(url, addresse);
  return response.data;
}
