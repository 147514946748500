<template>
  <div class="inbox">
    <!-- {{ showEmail }} --- showEmail <br />
    {{ currentEmailId }} -- currentEmailId <br /> -->
    <HeadlinerCard
      :isVisibleEmail="showEmail"
      :isAssigned="isAssigned"
      :isAdmin="isAdmin"
      :currentMessage="threadSelected.details?.length > 0 ? threadSelected.details[0] : null"
      :selectedRow="threadSelected"
      @toResolved="emit('toResolved')"
    />
    <InboxTable
      v-if="!showEmail"
      :isVisibleEmail="showEmail"
      @emitEmailEvent="handleEmailEvent"
      @click="handleAssign"
      :role="isAdmin"
      :isAssigned="isAssigned"
      :data="data"
      @update="handleUpdate"
      @toResolved="emit('toResolved')"
    />
    <EmailThreadTemplate
      v-if="showEmail"
      :isAssigned="isAssigned"
      :currentEmailId="currentEmailId"
      :readBefore="readBefore"
      @close="removeMessage"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch, onMounted, provide } from "vue";
import InboxTable from "@/app/emails/components/tables/InboxTable.vue";
import HeadlinerCard from "@/app/emails/components/cards/HeadlinerCard.vue";
import EmailThreadTemplate from "@/app/emails/components/templates/EmailThreadTemplate.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { DataType } from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const showEmail = ref<boolean>(false);
const isAdmin = ref<boolean>(true);
const isAssigned = ref<boolean>(false);
provide("isAssigned", isAssigned);
// const messages = ref<ThreadDetails[]>();
// const threadSelected = ref<Thread>({
//   id: null,
//   key: null,
//   details: [],
// });
// const idThreadActual = ref<number | null>(null);

defineProps({
  data: { type: Array, required: true },
  resolveSelected: { type: Boolean, default: false },
});

const emit = defineEmits(["emitEmailEvent", "emit-assign", "update", "update:menu", "toResolved"]);
const currentEmailId = ref<number>();

const store = useStore();
const route = useRoute();
const router = useRouter();

const readBefore = ref(null);

const threadSelected = computed(() => store.getters.getThreadActual);

const handleEmailEvent = async (show: boolean, record: DataType) => {
  showEmail.value = show;
  // const messageFiltered = props.data.find((item: any) => item.key === record.key);
  // messages.value = messageFiltered.messages;
  currentEmailId.value = record.key;
  // console.log(record, 'datos de record');
  if (record.id) {
    // idThreadActual.value = record.id;
    readBefore.value = record.read;

    console.log(record, "record selected");
    // const messageDetail = await emailService.getThreadDetails(record.id);
    // store.dispatch("updateThreadActual", messageDetail);
    // threadSelected.value = messageDetail;
  }
  // else {
  //   const messageDetail = await emailService.getEmailDetails(record.key);
  //   messages.value = [{ type: ThreadDetailType.Email, element: messageDetail }];
  // }

  router.push({ query: { ...route.query, emailId: record.key } });
};

// const handleUpdateMessage = async () => {
//   if (!currentEmailId.value) return;

//   const messageDetail = await emailService.getEmailDetails(currentEmailId.value);
//   emit("update:menu");

//   threadSelected.value.details = [messageDetail];
// };

const removeMessage = () => {
  // console.log("removeMessage");
  // store.dispatch("updateThreadActual", {
  //   key: null,
  //   id: null,
  //   details: [],
  //   tags: [],
  //   associatedClients: [],
  //   client: null,
  // });
  store.dispatch("removeThreadActual");
  showEmail.value = false;
};

defineExpose({ removeMessage });

const handleUpdate = () => {
  const channelCustomerId = Number(route.query.channelCustomer);
  const assigned = route.query.assigned === "true";

  emit("update", { channelCustomerId, assigned });
  emit("update:menu");
};

const handleAssign = () => {
  emit("emit-assign", isAssigned);
};

EventBus.on("showInboxTable", () => {
  showEmail.value = false;
  isAssigned.value = false;
});
EventBus.on("assignMenu", () => {
  showEmail.value = false;
  isAssigned.value = true;
});
watch(
  () => isAssigned.value,
  () => {
    console.log(isAssigned.value, "IS INBOX TABLE ASSIGNED");
  }
);

onMounted(() => {
  handleUpdate();
});
</script>
<style lang="sass" scoped>
.inbox
  h1
    font-size: 20px
    margin: 0
</style>
