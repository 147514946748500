import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza nuevo Correo electronico.
 * @param {number} emailId Identificador del email
 * @param {Object} body Datos del correo.
 *
 * @return {Promise<Object>} Datos del correo creado
 */
export async function createAddressContact(addressId, body) {
  const response = await httpManager.post(`/contacts/clients/${addressId}/addresses/`, body);
  return response?.data;
}
