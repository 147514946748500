const getters = {
  getTemplateSelectedPortfolio(state) {
    return state.portfolio;
  },
  getTemplatesFromPortfolio(state) {
    return state.templates;
  },
  getActiveTemplateFields(state) {
    return state.activeFields;
  },
  getActiveTemplateFieldsOrdered(state) {
    const activeFields = state.activeFields;

    // Definir el orden deseado
    const order = ["code_client", "first_name", "paternal_surname", "maternal_surname"];

    // Función de comparación para el sort
    const compare = (a, b) => {
      const indexA = order.indexOf(a.name);
      const indexB = order.indexOf(b.name);

      // Si ambos elementos están en el orden, compáralos según su posición en el orden
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      // Si solo uno está en el orden, colócalo primero
      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      // Si ninguno está en el orden, deja el orden como está
      return 0;
    };

    // Aplicar modificaciones adicionales
    const modifiedFields = [...activeFields].map((field) => {
      if (
        field.name === "code_client" ||
        field.name === "first_name" ||
        field.name === "paternal_surname" ||
        field.name === "maternal_surname"
      ) {
        field.show_on_report = true;
      }

      if (field.show_on_report === undefined) {
        field.show_on_report = false;
      }

      return field;
    });

    // Ordenar el array utilizando la función de comparación y devolverlo
    return modifiedFields.sort(compare);
  },
};

export default getters;
