import { computed, onMounted } from "vue";
import { useStore } from "vuex";

import { SET_IS_GLOBAL_DOWNLOADING } from "@/app/shared/store/tenant/mutation-types";

const IS_DOWNLOADING_KEY = "isGlobalDownload";

enum DownloadState {
  NotDownloading = "0",
  Downloading = "1",
}

function setLocalDownloadState(isDownloading = false) {
  const state = isDownloading ? DownloadState.Downloading : DownloadState.NotDownloading;

  localStorage.setItem(IS_DOWNLOADING_KEY, state);
}

function isGlobalDownloading() {
  const state = localStorage.getItem(IS_DOWNLOADING_KEY);

  if (!state) return false;

  return state === DownloadState.Downloading;
}

export function useGlobalDownloadState() {
  const store = useStore();

  const isDownloading = computed<boolean>(() => store.getters["getIsGlobalDownloading"]);

  const setGlobalDownloadState = (isDownloading: boolean, { updateLocalStorage = true } = {}) => {
    if (updateLocalStorage) setLocalDownloadState(isDownloading);

    store.commit(SET_IS_GLOBAL_DOWNLOADING, isGlobalDownloading());
  };

  onMounted(() => setGlobalDownloadState(isGlobalDownloading(), { updateLocalStorage: false }));

  return { isGlobalDownloading: isDownloading, setGlobalDownloadState };
}
