import sequences from "@/app/flows/sequences/index";

export default [
  {
    path: "/flows",
    name: "flows",
    component: () => import(/* webpackChunkName: "flows-page" */ "@/app/flows/pages/FlowsPage.vue"),
    redirect: "/flows/forms",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "forms",
        component: () =>
          import(
            /* webpackChunkName: "forms" */ "@/app/entities/components/templates/FormsTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "forms/:id",
        component: () =>
          import(
            /* webpackChunkName: "form-details" */ "@/app/entities/components/templates/CreateFormTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "chatbots",
        component: () =>
          import(/* webpackChunkName: "chatbots" */ "@/app/chatbots/views/Chatbots.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "chatbots/tree/:id",
        component: () =>
          import(/* webpackChunkName: "chatbots-tree" */ "@/app/chatbots/views/Chatbot.vue"),
        meta: {
          requiresAuth: true,
          transparent: true,
        },
      },
      ...sequences.routes,
      {
        path: "templates-email",
        component: () =>
          import(
            /* webpackChunkName: "email-templates-page" */ "@/app/flows/emailTemplates/pages/EmailTemplatesPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "email-templates-list" */ "@/app/flows/emailTemplates/templates/TemplatesListTemplate.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "email-template-details" */ "@/app/flows/emailTemplates/templates/TemplateDetailsTemplate.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];
