import WorkspaceStore from "@/app/workspace/store/workspace";
import PhoneStore from "@/app/workspace/store/phone";
import StrategyStore from "@/app/workspace/store/strategy";

export default {
  modules: {
    WorkspaceStore,
    PhoneStore,
    StrategyStore,
  },
};
