<template>
  <Icon class="socialicon">
    <template #component>
      <!-- WhatsApp -->
      <svg
        v-if="type === 'WhatsApp'"
        width="40"
        height="40"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0032 3.6665H16.9965C9.64484 3.6665 3.6665 9.6465 3.6665 16.9998C3.6665 19.9165 4.6065 22.6198 6.20484 24.8148L4.54317 29.7682L9.66817 28.1298C11.7765 29.5265 14.2915 30.3332 17.0032 30.3332C24.3548 30.3332 30.3332 24.3515 30.3332 16.9998C30.3332 9.64817 24.3548 3.6665 17.0032 3.6665Z"
          fill="#25D366"
        />
        <path
          d="M24.7615 22.4946C24.4398 23.403 23.1632 24.1563 22.1448 24.3763C21.4482 24.5246 20.5382 24.643 17.4748 23.373C13.5565 21.7496 11.0332 17.768 10.8365 17.5096C10.6482 17.2513 9.25317 15.4013 9.25317 13.488C9.25317 11.5746 10.2248 10.643 10.6165 10.243C10.9382 9.91465 11.4698 9.76465 11.9798 9.76465C12.1448 9.76465 12.2932 9.77298 12.4265 9.77965C12.8182 9.79632 13.0148 9.81965 13.2732 10.438C13.5948 11.213 14.3782 13.1263 14.4715 13.323C14.5665 13.5196 14.6615 13.7863 14.5282 14.0446C14.4032 14.3113 14.2932 14.4296 14.0965 14.6563C13.8998 14.883 13.7132 15.0563 13.5165 15.2996C13.3365 15.5113 13.1332 15.738 13.3598 16.1296C13.5865 16.513 14.3698 17.7913 15.5232 18.818C17.0115 20.143 18.2182 20.5663 18.6498 20.7463C18.9715 20.8796 19.3548 20.848 19.5898 20.598C19.8882 20.2763 20.2565 19.743 20.6315 19.218C20.8982 18.8413 21.2348 18.7946 21.5882 18.928C21.9482 19.053 23.8532 19.9946 24.2448 20.1896C24.6365 20.3863 24.8948 20.4796 24.9898 20.6446C25.0832 20.8096 25.0832 21.5846 24.7615 22.4946Z"
          fill="#FAFAFA"
        />
      </svg>

      <!-- Messenger -->
      <svg
        v-if="type === 'Messenger'"
        class="messenger"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 0C5.24004 0 0 4.95169 0 11.64C0 15.1385 1.43376 18.1615 3.76867 20.2496C3.96468 20.425 4.083 20.6708 4.09104 20.9338L4.15639 23.0684C4.17727 23.7492 4.88057 24.1923 5.50361 23.9172L7.88544 22.8658C8.08735 22.7768 8.3136 22.7602 8.52636 22.8188C9.62088 23.1197 10.7858 23.28 12 23.28C18.76 23.28 24 18.3284 24 11.64C24 4.95169 18.76 0 12 0Z"
          fill="url(#paint0_radial)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.794 15.044L8.319 9.45154C8.87971 8.56185 10.0804 8.34033 10.9217 8.9713L13.7254 11.074C13.9826 11.267 14.3365 11.2659 14.5927 11.0715L18.3791 8.19789C18.8845 7.81437 19.5442 8.41917 19.2059 8.95589L15.6809 14.5484C15.1202 15.4381 13.9195 15.6596 13.0782 15.0286L10.2745 12.9259C10.0173 12.7329 9.66336 12.734 9.40723 12.9284L5.6208 15.802C5.11543 16.1855 4.45572 15.5807 4.794 15.044Z"
          fill="white"
        />
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(4.61938 23.8716) scale(26.1503)"
          >
            <stop stop-color="#0099FF" />
            <stop offset="0.609754" stop-color="#A033FF" />
            <stop offset="0.934823" stop-color="#FF5280" />
            <stop offset="1" stop-color="#FF7061" />
          </radialGradient>
        </defs>
      </svg>
      <!-- Instagram -->
      <svg
        v-if="type === 'instagram'"
        class="instagram"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M1.50002 1.63305C-0.385984 3.59205 1.61529e-05 5.67305 1.61529e-05 11.995C1.61529e-05 17.245 -0.915984 22.508 3.87802 23.747C5.37502 24.132 18.639 24.132 20.134 23.745C22.13 23.23 23.754 21.611 23.976 18.788C24.007 18.394 24.007 5.60305 23.975 5.20105C23.739 2.19405 21.888 0.461046 19.449 0.110046C18.89 0.0290464 18.778 0.00504639 15.91 4.63948e-05C5.73702 0.00504639 3.50702 -0.447954 1.50002 1.63305Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M11.998 3.13905C8.367 3.13905 4.919 2.81605 3.602 6.19605C3.058 7.59205 3.137 9.40505 3.137 12.001C3.137 14.279 3.064 16.42 3.602 17.805C4.916 21.187 8.392 20.863 11.996 20.863C15.473 20.863 19.058 21.225 20.391 17.805C20.936 16.395 20.856 14.609 20.856 12.001C20.856 8.53905 21.047 6.30405 19.368 4.62605C17.668 2.92605 15.369 3.13905 11.994 3.13905H11.998ZM11.204 4.73605C18.778 4.72405 19.742 3.88205 19.21 15.579C19.021 19.716 15.871 19.262 11.999 19.262C4.939 19.262 4.736 19.06 4.736 11.997C4.736 4.85205 5.296 4.74005 11.204 4.73405V4.73605ZM16.728 6.20705C16.141 6.20705 15.665 6.68305 15.665 7.27005C15.665 7.85705 16.141 8.33305 16.728 8.33305C17.315 8.33305 17.791 7.85705 17.791 7.27005C17.791 6.68305 17.315 6.20705 16.728 6.20705ZM11.998 7.45005C9.485 7.45005 7.448 9.48805 7.448 12.001C7.448 14.514 9.485 16.551 11.998 16.551C14.511 16.551 16.547 14.514 16.547 12.001C16.547 9.48805 14.511 7.45005 11.998 7.45005ZM11.998 9.04705C15.903 9.04705 15.908 14.955 11.998 14.955C8.094 14.955 8.088 9.04705 11.998 9.04705Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="1.54604"
            y1="22.4671"
            x2="23.8515"
            y2="3.16202"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFDD55" />
            <stop offset="0.5" stop-color="#FF543E" />
            <stop offset="1" stop-color="#C837AB" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <!-- SMS -->
      <svg
        v-if="type === 'SMS'"
        width="40"
        height="40"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 3.75C10.1299 3.75 3.75 10.1299 3.75 18C3.75 20.3971 4.54332 22.7547 5.31071 24.4832C5.60284 25.1413 5.81234 25.6136 5.95663 25.9784C6.10372 26.3503 6.15794 26.5507 6.17241 26.6748C6.21545 27.044 6.20093 27.2141 6.09599 27.5707C6.05775 27.7006 5.98226 27.8673 5.81857 28.1412C5.6539 28.4167 5.42567 28.7594 5.10092 29.2465L3.87596 31.084C3.72254 31.3141 3.70823 31.61 3.83875 31.8539C3.96926 32.0978 4.2234 32.25 4.5 32.25H18C25.8701 32.25 32.25 25.8701 32.25 18C32.25 10.1299 25.8701 3.75 18 3.75ZM12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75H24C24.4142 15.75 24.75 15.4142 24.75 15C24.75 14.5858 24.4142 14.25 24 14.25H12ZM11.25 21C11.25 20.5858 11.5858 20.25 12 20.25H18C18.4142 20.25 18.75 20.5858 18.75 21C18.75 21.4142 18.4142 21.75 18 21.75H12C11.5858 21.75 11.25 21.4142 11.25 21Z"
          fill="#00315D"
        />
      </svg>
      <!-- Telegram -->

      <svg
        v-if="type === 'Telefonica'"
        width="40"
        height="40"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.5471 8.86035C16.3772 9.78052 16.114 10.823 15.3801 11.8121C15.206 12.0466 15.0055 12.2777 14.7632 12.5323C14.6469 12.6545 14.5562 12.7464 14.3666 12.9363C13.9359 13.3674 13.5738 13.7297 13.2803 14.0231C13.138 14.1654 14.1096 16.1069 15.9952 17.9943C17.8797 19.8807 19.8211 20.853 19.9641 20.7099L21.0496 19.6238C21.6474 19.0255 21.964 18.7368 22.399 18.4514C23.3033 17.8581 24.2897 17.6968 25.1217 18.4407C27.838 20.3837 29.3751 21.5761 30.1281 22.3585C31.5969 23.8847 31.4044 26.2339 30.1365 27.5741C29.6968 28.0388 29.1393 28.5966 28.4808 29.2314C24.4978 33.2166 16.0918 30.7913 9.64947 24.3427C3.2057 17.8926 0.781739 9.48575 4.75641 5.50894C5.46998 4.78416 5.70525 4.54901 6.4002 3.86425C7.69408 2.58934 10.1517 2.38972 11.6456 3.86551C12.4315 4.64178 13.6843 6.25372 15.5471 8.86035ZM19.3446 2.93175C25.4693 3.67413 30.3257 8.53054 31.0681 14.6552L28.2695 15.1217C27.7292 10.1883 23.8115 6.27063 18.8781 5.73028L19.3446 2.93175ZM18.4114 8.53126C22.1535 8.86596 25.1339 11.8464 25.4686 15.5885L22.6611 16.0564C22.542 13.5052 20.4947 11.4579 17.9435 11.3388L18.4114 8.53126Z"
          fill="#0266C2"
        />
      </svg>

      <!-- Inbound Chat -->
      <svg
        v-if="type === 'Chat Inbound'"
        width="40"
        height="40"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.3 5.25L9.2679 5.25C8.45506 5.24999 7.79944 5.24999 7.26853 5.29336C6.7219 5.33803 6.24175 5.43239 5.79754 5.65873C5.09193 6.01825 4.51825 6.59193 4.15873 7.29754C3.93239 7.74175 3.83803 8.2219 3.79336 8.76853C3.74999 9.29944 3.74999 9.95506 3.75 10.7679L3.75 10.8V22.2L3.75 22.2321C3.74999 23.0449 3.74999 23.7006 3.79336 24.2315C3.83803 24.7781 3.93239 25.2582 4.15873 25.7025C4.51825 26.4081 5.09193 26.9817 5.79754 27.3413C6.24175 27.5676 6.7219 27.662 7.26853 27.7066C7.79944 27.75 8.45505 27.75 9.26788 27.75H9.3H23.9892C24.5727 27.75 24.794 27.7517 25.0063 27.7814C25.2054 27.8093 25.4015 27.8556 25.5921 27.9197C25.7954 27.9881 25.994 28.0855 26.5159 28.3465L31.1646 30.6708C31.3971 30.7871 31.6732 30.7746 31.8943 30.638C32.1154 30.5013 32.25 30.2599 32.25 30V10.8V10.7679C32.25 9.95505 32.25 9.29944 32.2066 8.76853C32.162 8.2219 32.0676 7.74175 31.8413 7.29754C31.4817 6.59193 30.9081 6.01825 30.2025 5.65873C29.7582 5.43239 29.2781 5.33803 28.7315 5.29336C28.2006 5.24999 27.5449 5.24999 26.7321 5.25L26.7 5.25H9.3ZM10.5 11.25C10.0858 11.25 9.75 11.5858 9.75 12C9.75 12.4142 10.0858 12.75 10.5 12.75H22.5C22.9142 12.75 23.25 12.4142 23.25 12C23.25 11.5858 22.9142 11.25 22.5 11.25H10.5ZM9.75 18C9.75 17.5858 10.0858 17.25 10.5 17.25H16.5C16.9142 17.25 17.25 17.5858 17.25 18C17.25 18.4142 16.9142 18.75 16.5 18.75H10.5C10.0858 18.75 9.75 18.4142 9.75 18Z"
          fill="#FF5F1F"
        />
      </svg>

      <!-- Instagram -->
      <svg
        v-if="type === 'Instagram'"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_10366_92588)">
          <path
            d="M5.18806 5.38127C2.43764 8.23815 3.00056 11.2729 3.00056 20.4925C3.00056 28.1488 1.66472 35.824 8.65597 37.6309C10.8391 38.1923 30.1824 38.1923 32.3626 37.6279C35.2735 36.8769 37.6418 34.5159 37.9656 30.399C38.0108 29.8244 38.0108 11.1709 37.9641 10.5846C37.6199 6.1994 34.9206 3.67211 31.3637 3.16023C30.5485 3.04211 30.3851 3.00711 26.2026 2.99981C11.367 3.00711 8.11493 2.34648 5.18806 5.38127Z"
            fill="url(#paint0_linear_10366_92588)"
          />
          <path
            d="M20.4965 7.57741C15.2013 7.57741 10.1729 7.10637 8.25232 12.0355C7.45899 14.0714 7.5742 16.7153 7.5742 20.5012C7.5742 23.8232 7.46774 26.9455 8.25232 28.9653C10.1686 33.8974 15.2377 33.4249 20.4936 33.4249C25.5642 33.4249 30.7923 33.9528 32.7363 28.9653C33.5311 26.9091 33.4144 24.3045 33.4144 20.5012C33.4144 15.4524 33.6929 12.193 31.2444 9.74595C28.7652 7.26679 25.4125 7.57741 20.4907 7.57741H20.4965ZM19.3386 9.90637C30.384 9.88887 31.7898 8.66096 31.014 25.7191C30.7384 31.7522 26.1446 31.0901 20.4979 31.0901C10.2021 31.0901 9.90607 30.7955 9.90607 20.4953C9.90607 10.0755 10.7227 9.91221 19.3386 9.90346V9.90637ZM27.3944 12.0516C26.5384 12.0516 25.8442 12.7457 25.8442 13.6018C25.8442 14.4578 26.5384 15.152 27.3944 15.152C28.2504 15.152 28.9446 14.4578 28.9446 13.6018C28.9446 12.7457 28.2504 12.0516 27.3944 12.0516ZM20.4965 13.8643C16.8317 13.8643 13.8611 16.8364 13.8611 20.5012C13.8611 24.166 16.8317 27.1366 20.4965 27.1366C24.1613 27.1366 27.1304 24.166 27.1304 20.5012C27.1304 16.8364 24.1613 13.8643 20.4965 13.8643ZM20.4965 16.1932C26.1913 16.1932 26.1986 24.8091 20.4965 24.8091C14.8032 24.8091 14.7944 16.1932 20.4965 16.1932Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_10366_92588"
            x1="5.25517"
            y1="35.7643"
            x2="37.784"
            y2="7.61103"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFDD55" />
            <stop offset="0.5" stop-color="#FF543E" />
            <stop offset="1" stop-color="#C837AB" />
          </linearGradient>
          <clipPath id="clip0_10366_92588">
            <rect width="35" height="35" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </svg>

      <!-- Multichannel -->
      <svg
        v-if="type === 'Multichannel'"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="icon/filled/application/message">
          <path
            id="Vector"
            d="M40.6186 14.8711C39.6326 12.518 38.1982 10.3791 36.3954 8.57377C34.598 6.76528 32.4628 5.32742 30.1112 4.34184C27.7022 3.32728 25.1138 2.80722 22.4999 2.81254H22.412C19.7533 2.82572 17.1825 3.35307 14.7611 4.39018C12.4297 5.38587 10.3146 6.82628 8.53408 8.6309C6.74872 10.4322 5.33078 12.5636 4.35928 14.9063C3.3508 17.3425 2.8398 19.9557 2.85635 22.5923C2.86953 25.6421 3.59902 28.67 4.96133 31.377V38.0567C4.96133 39.1729 5.8666 40.0782 6.97842 40.0782H13.6493C16.369 41.4505 19.3702 42.1726 22.4164 42.1875H22.5087C25.1366 42.1875 27.6811 41.6778 30.0805 40.6802C32.4202 39.7064 34.5473 38.2853 36.3427 36.4966C38.1532 34.6993 39.5771 32.5987 40.5746 30.2564C41.6073 27.8306 42.1347 25.251 42.1479 22.5879C42.1566 19.9117 41.6381 17.3145 40.6186 14.8711ZM13.7284 24.6094C12.5683 24.6094 11.6234 23.6646 11.6234 22.5C11.6234 21.3355 12.5683 20.3907 13.7284 20.3907C14.8886 20.3907 15.8334 21.3355 15.8334 22.5C15.8334 23.6646 14.893 24.6094 13.7284 24.6094ZM22.4999 24.6094C21.3397 24.6094 20.3949 23.6646 20.3949 22.5C20.3949 21.3355 21.3397 20.3907 22.4999 20.3907C23.6601 20.3907 24.6049 21.3355 24.6049 22.5C24.6049 23.6646 23.6601 24.6094 22.4999 24.6094ZM31.2714 24.6094C30.1112 24.6094 29.1664 23.6646 29.1664 22.5C29.1664 21.3355 30.1112 20.3907 31.2714 20.3907C32.4315 20.3907 33.3764 21.3355 33.3764 22.5C33.3764 23.6646 32.4315 24.6094 31.2714 24.6094Z"
            fill="#FFC069"
          />
        </g>
      </svg>
      <!-- Correo -->
      <svg
        v-if="type === 'Correo'"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40 9.16675V30.3334C40 31.0251 39.7166 31.6584 39.2583 32.1084C38.8083 32.5584 38.1833 32.8334 37.5 32.8334H2.5C1.80832 32.8334 1.19166 32.5584 0.741679 32.1084C0.283356 31.6584 0 31.0251 0 30.3334V9.16675C0 8.94178 0.0333152 8.71677 0.0916544 8.50843C0.208333 8.08343 0.43335 7.70007 0.741679 7.39174C1.19166 6.9501 1.80832 6.66675 2.5 6.66675H37.5C38.1833 6.66675 38.8083 6.9501 39.2583 7.39174C39.5667 7.70007 39.7917 8.08343 39.9083 8.50843C39.9667 8.71677 40 8.94178 40 9.16675Z"
          fill="#FFC536"
        />
        <path
          d="M39.2584 32.1085C38.8084 32.5585 38.1834 32.8335 37.5 32.8335H2.50005C1.80837 32.8335 1.19171 32.5585 0.74173 32.1085L13.4167 19.7502L20 13.3335L26.5834 19.7502L39.2584 32.1085Z"
          fill="#F2A916"
        />
        <path
          opacity="0.1"
          d="M39.9086 8.50843L27.4752 20.6251L22.6169 25.3584C21.9169 26.0501 20.9836 26.4251 20.0002 26.4251C19.0169 26.4251 18.0836 26.0501 17.3836 25.3584L12.5252 20.6251L0.0918884 8.50843C0.208567 8.08343 0.433584 7.70007 0.741913 7.39174C1.19189 6.9501 1.80855 6.66675 2.50023 6.66675H37.5002C38.1836 6.66675 38.8086 6.9501 39.2586 7.39174C39.5669 7.70007 39.7919 8.08343 39.9086 8.50843Z"
          fill="black"
        />
        <path
          d="M39.2584 7.39174L26.5834 19.7501L21.745 24.466C20.7741 25.4123 19.226 25.4123 18.2551 24.466L13.4167 19.7501L0.74173 7.39174C1.19171 6.9501 1.80837 6.66675 2.50005 6.66675H37.5C38.1834 6.66675 38.8084 6.9501 39.2584 7.39174Z"
          fill="#FFC536"
        />
      </svg>
    </template>
  </Icon>
</template>
<script>
import Icon from "@ant-design/icons-vue";

export default {
  name: "SocialIcon",
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="sass" scoped></style>
