import {
  SET_PORTFOLIO,
  SET_TEMPLATES_DATA,
  SET_ACTIVE_FIELDS,
  SET_DEFAULTS,
  SET_FIELD_ITEM,
  MOVE_FIELD_ITEM,
  REMOVE_FIELD_ITEM,
} from "./mutation-types";

const mutations = {
  [SET_PORTFOLIO](state, portfolio) {
    state.portfolio = portfolio;
  },
  [SET_TEMPLATES_DATA](state, templates) {
    state.templates = templates;
  },
  [SET_ACTIVE_FIELDS](state, activeFields) {
    state.activeFields = [...activeFields];
  },
  [SET_DEFAULTS](state, type) {
    const templates = state.templates;
    let defaults = templates.account_template_default;
    if (type == "client") defaults = templates.client_template_default;
    state.defaults = defaults;
  },
  [SET_FIELD_ITEM](state, item) {
    const index = state.activeFields.findIndex((field) => field.name == item.name);
    state.activeFields[index] = item;
  },
  [MOVE_FIELD_ITEM](state, { from, to }) {
    if (to.index == 0) return;
    state.activeFields.splice(from.index, 1);
    state.activeFields.splice(to.index, 0, from);
  },
  [REMOVE_FIELD_ITEM](state, item) {
    state.activeFields = state.activeFields.filter((field) => field.name !== item.name);
  },
};

export default mutations;
