export const rulesTableColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Agregar-Pov",
    dataIndex: "prefix_code",
    key: "pov",
  },
  {
    title: "Prefijo",
    dataIndex: "provider",
    key: "pre",
  },
  {
    title: "Reglas",
    dataIndex: "rule",
    key: "rules",
  },
  {
    title: "Precio",
    dataIndex: "destination_price_value",
    key: "price",
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    slots: { customRender: "actionScoped" },
    fixed: "right",
  },
];

export const trunksTableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id ",
    width: "70px",
  },
  {
    title: "Troncal",
    dataIndex: "name",
    key: "trunk",
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    slots: { customRender: "status" },
    sorter: (a, b) => {
      if (a.status < b.status) return -1;
      if (a.status > b.status) return 1;
      return 0;
    },
    width: 100,
  },
  {
    title: "Plan de marcación",
    dataIndex: "dialing_plans",
    slots: { customRender: "dialingPlan" },
  },
  {
    title: "Campañas asociadas",
    dataIndex: "portfolios",
    slots: { customRender: "portfolios" },
  },
  {
    title: "Nro entrantes asociados",
    dataIndex: "dids",
    slots: { customRender: "dids" },
  },
  {
    title: "Host",
    dataIndex: "host_server",
    key: "host",
  },
  {
    title: "Port",
    dataIndex: "port",
    key: "port",
  },
  // {
  //   title: "Context ",
  //   dataIndex: "context",
  //   key: "context",
  // },
  // {
  //   title: "Usuario",
  //   dataIndex: "username",
  //   key: "username",
  // },
  {
    title: "DTMF Mode",
    dataIndex: "dtmf_mode",
    key: "dtmf_mode",
    slots: { customRender: "dtmf_mode" },
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    fixed: "right",
  },
];

export const DIAL_PLAN_TABLE_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id ",
    width: "70px",
  },
  {
    dataIndex: "name",
    key: "name",
    slots: { title: "planMarcationTitle", customRender: "name" },
    width: 260,
  },
  {
    title: "Troncal",
    key: "trunk",
    dataIndex: ["trunk", "name"],
    width: 230,
  },
  {
    title: "País",
    key: "country",
    dataIndex: ["country", "name"],
    width: 160,
  },
  {
    title: "Tiempo de timbrado (seg)",
    key: "ringing_time",
    dataIndex: "ringing_time",
    width: 200,
  },
  {
    title: "Estado",
    dataIndex: "status",
    key: "status",
    slots: { customRender: "status" },
    width: 120,
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    fixed: "right",
    width: 160,
  },
];

export const didsTableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id ",
    width: "70px",
  },
  {
    title: "Troncal",
    dataIndex: "name",
    key: "trunk",
  },
  {
    title: `DID's`,
    dataIndex: "did_number",
    key: "did",
  },
  {
    dataIndex: "status",
    key: "status",
    slots: { title: "statusTitle", customRender: "status" },
    sorter: (a, b) => {
      if (a.status < b.status) return -1;
      if (a.status > b.status) return 1;
      return 0;
    },
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 200,
    fixed: "right",
  },
];
