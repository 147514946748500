import httpManager from "@/app/shared/utils/httpManager";
/**
 * Agregar nueva etiqueta.
 * @param {Object} tag Datos de la etiqueta.
 *
 * @return {Promise<Object>} Nueva etiqueta.
 */
export async function addTag(tag) {
  const response = await httpManager.post(`/tickets/tags/`, tag);
  return response?.data;
}
