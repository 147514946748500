<template>
  <a-select
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    :value="value"
    :placeholder="placeholder"
    :options="options"
    :filter-option="filterOption"
    show-search
    @change="handleChange"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  options: { value: string | number; label: string }[];
  value: string | number;
  placeholder: string;
}>();

const emit = defineEmits<{
  "update:value": [value: string | number];
  change: [value: string | number];
}>();

const handleChange = (value: string | number) => {
  emit("update:value", value);
  emit("change", value);
};

const filterOption = (input: string, option: { value: string | number }) => {
  const currentOption = props.options.find((opt) => opt.value === option.value);

  const optionToCompare = currentOption?.label ?? "";

  return optionToCompare.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>

<style scoped lang="sass"></style>
