<template>
  <!-- <a-tooltip :title="hasSpeech ? 'Ver speech' : 'No hay speech disponible'"> -->
  <a-button class="button-speech" :disabled="!hasSpeech" type="text" @click="handleOpenSpeechModal">
    <FileTextOutlined class="icon" />

    Ver speech
  </a-button>
  <!-- </a-tooltip> -->
</template>

<script setup lang="ts">
import { computed } from "vue";
import { FileTextOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

import type { Ticket } from "@/@types/global";

const props = defineProps<{
  ticket: Ticket;
}>();

const store = useStore();

const hasSpeech = computed<boolean>(() => Boolean(props.ticket.portfolio?.text_negotiation));

const handleOpenSpeechModal = () => {
  store.commit("SET_SPEECH_CONFIG", { visible: true, portfolio: props.ticket.portfolio.id });
};
</script>

<style lang="sass" scoped>
.button-speech
  display: flex
  align-items: center
  justify-content: flex-start
  width: 100%

.icon
  font-size: 18px
</style>
