export const SET_CLIENT = "SET_CLIENT";
export const SET_TICKETS_FILTERS = "SET_TICKETS_FILTERS";
export const SET_TICKETS = "SET_TICKETS";
export const SET_TICKETS_LIST_PAGE = "SET_TICKETS_LIST_PAGE";
export const SET_HAS_NEXT_TICKET_PAGE = "SET_HAS_NEXT_TICKET_PAGE";
export const SET_FILTERED_TICKETS = "SET_FILTERED_TICKETS";
export const SET_MESSAGE_HISTORY = "SET_MESSAGE_HISTORY";
export const SET_CLIENT_CHANNELS = "SET_CLIENT_CHANNELS";
export const UPDATE_PERSONAL_INFORMATION = "UPDATE_PERSONAL_INFORMATION";
export const ADD_EMAIL_CLIENT = "ADD_EMAIL_CLIENT";
export const ADD_SELECTED_TICKET = "ADD_SELECTED_TICKET";
export const REMOVE_SELECTED_TICKET = "REMOVE_SELECTED_TICKET";
export const SELECT_ALL_TICKETS = "SELECT_ALL_TICKETS";
export const ADD_PHONES_CLIENT = "ADD_PHONES_CLIENT";
export const REMOVE_PHONE_CLIENT = "REMOVE_PHONE_CLIENT";
export const ADD_ADDRESSES_CLIENT = "ADD_ADDRESSES_CLIENT";
export const REMOVE_ADDRESSES_CLIENT = "REMOVE_ADDRESSES_CLIENT";
export const REMOVE_EMAIL_CLIENT = "REMOVE_EMAIL_CLIENT";
export const UPDATE_ADDRESSES_CLIENT = "UPDATE_ADDRESSES_CLIENT";
export const UPDATE_EMAIL_CLIENT = "UPDATE_EMAIL_CLIENT";
export const UPDATE_PHONES_CLIENT = "UPDATE_PHONES_CLIENT";
export const UPDATE_DATA_CUSTOM = "UPDATE_DATA_CUSTOM";
export const SET_CLIENTS_WITH_SAME_NUMBER_FOUND = "SET_CLIENTS_WITH_SAME_NUMBER_FOUND";
export const SET_CLIENTS_FOUND = "SET_CLIENTS_FOUND";
export const SET_CLEAN_RESULTS = "SET_CLEAN_RESULTS";
export const SET_CONTACTS_CLIENT = "SET_CONTACTS_CLIENT";
export const UPDATE_CONTACTS_CLIENT = "UPDATE_CONTACTS_CLIENT";
export const DELETE_CONTACTS_CLIENT = "DELETE_CONTACT_CLIENT";
export const SET_CALL_FINISH = "SET_CALL_FINISH";
export const SET_TICKET_DETAIL = "SET_TICKET_DETAIL";
export const SET_DIALING_TYPE = "SET_DIALING_TYPE";
export const SET_CHANNEL_PERSON_TICKET = "SET_CHANNEL_PERSON_TICKET";
export const SET_CAPTURE = "SET_CAPTURE";
export const SET_IS_SCHEDULE = "SET_IS_SCHEDULE";
export const SET_LAST_OPENED_TICKET = "SET_LAST_OPENED_TICKET";
export const SET_INTERACTIONS_CHANNEL = "SET_INTERACTIONS_CHANNEL";
export const SET_EMAIL_HISTORY = "SET_EMAIL_HISTORY";

// Speech
export const SET_SPEECH_CONFIG = "SET_SPEECH_CONFIG";
export const SET_PORTFOLIO_DETAIL = "SET_PORTFOLIO_DETAIL";

// Tipification Table
export const SET_TYPIFICATIONS_TABLE_DATA = "SET_TYPIFICATIONS_TABLE_DATA";

export const SET_EVENT_MESSAGING = "SET_EVENT_MESSAGING";
