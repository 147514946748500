import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear un nuevo usuario.
 *
 * @param {Object} userData Datos del usuario a crear.
 *
 * @return {Promise<Object>} Nuevo usuario
 */
export default async function (profileData) {
  const body = {
    ...profileData,
  };
  const response = await httpManager.post("/profiles/", body);
  return response?.data;
}
