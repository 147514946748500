import httpManager from "@/app/shared/utils/httpManager";

export default async function ({ id, type }) {
  let url;
  switch (type) {
    case "group":
      url = `/entities/group/${id}/`;
      break;
    case "result":
      url = `/entities/results/${id}/`;
      break;
    case "motive":
      url = `/entities/motives/${id}/`;
      break;
    case "submotive":
      url = `/entities/submotives/${id}/`;
      break;
    default:
      break;
  }
  await httpManager.delete(url);
}
