import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los webhook del proveedor.
 *
 * @param {number} providerId Identificador del proveedor.
 *
 * @return {Promise<Array>} Lista de webhook.
 */
export default async function (providerId) {
  const response = await httpManager.get(`/channels/3/provider/${providerId}/webhook/`);
  return response?.data;
}
