import {
  SET_REPORT_TYPIFICATIONS,
  SET_REPORT_MANAGEMENT,
  SET_REPORT_TIME_PLATFORM,
  SET_COMMON_REPORTS,
  SET_REPORT_DETAIL_TIME_PLATFORM,
} from "./mutations-type";
import {
  fetchReportTypifications,
  fetchReportManagementProgress,
  fetchReportTimePlatform,
  fetchCommonReport,
  fetchTotalRegisterReportTimePlatform,
} from "@/app/reports/services";
import { TABLE_PAGE_SIZE_XL } from "@/app/shared/utils/constants";
const actions = {
  async fetchReportTypifications({ commit }, params) {
    const report = await fetchReportTypifications(params);
    commit(SET_REPORT_TYPIFICATIONS, report?.count);
    return report;
  },
  async fetchReportManagementProgress({ commit }, params) {
    const { query, pagination } = params;
    const report = await fetchReportManagementProgress(query, pagination);
    console.log(report);
    commit(SET_REPORT_MANAGEMENT, {
      items: report.results,
      pagination: {
        total: report.count,
        pageSize: TABLE_PAGE_SIZE_XL,
        showSizeChanger: false,
      },
    });
  },
  async fetchReportTimePlatform({ commit }, params) {
    const { query, pagination } = params;
    const report = await fetchReportTimePlatform(query, pagination);
    commit(SET_REPORT_TIME_PLATFORM, {
      items: report.results,
      pagination: {
        total: report.count,
        pageSize: TABLE_PAGE_SIZE_XL,
        showSizeChanger: false,
      },
    });
  },
  async fetchCommonReports({ commit }, params) {
    const { query, pagination } = params;
    const report = await fetchCommonReport(query, pagination);
    commit(SET_COMMON_REPORTS, {
      items: report.results,
      pagination: {
        total: report.count,
        pageSize: TABLE_PAGE_SIZE_XL,
        showSizeChanger: false,
      },
    });
  },
  async fetchAllDatailTimePlatform({ commit }, params) {
    const item = await fetchTotalRegisterReportTimePlatform(params);
    commit(SET_REPORT_DETAIL_TIME_PLATFORM, item);
  },
};

export default actions;
