import httpManager from "@/app/shared/utils/httpManager";
/**
 * Generar una estrategia por plantilla.
 *
 * @param {FormData} strategy Datos de la estrategia.
 *
 * @return {Promise<Object>} Estrategia creada.
 */
export async function generateStrategyByTemplate(strategy) {
  strategy.append("type_origin", 2);
  const response = await httpManager.upload("/strategies/", strategy);
  return response?.data;
}
