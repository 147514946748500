import httpManager from "../../../../shared/utils/httpManager";
/**
 * Obtener bases por nombre, entidad y campaña.
 *
 * @param {string} search Nombre de base a buscar.
 * @param {number} entity Identificador de la entidad.
 * @param {number} portfolio Identificador de la campaña.
 * @param {Object} pagination Paginación

 * @return {Promise<Array>} Lista de bases.
 */
import { TABLE_PAGE_SIZE } from "../../../../shared/utils/constants";

export async function fetchAllBases(
  search = "",
  entity = "",
  portfolio = "",
  pagination = {},
  status = null
) {
  entity = entity == 0 ? "" : entity;
  portfolio = portfolio == 0 ? "" : portfolio;
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  let url = `/entities/portfolios/bases/?search=${search}&entity=${entity}&portfolio=${portfolio}&offset=${offset}&limit=${pageSize}`;

  if (status) url += `&status=${status}`;

  const response = await httpManager.get(url);
  return response?.data;
}
