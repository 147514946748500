import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un canal.
 *
 * @param {number} channelId Identificador del canal.
 */
export default async function (channelId) {
  await httpManager.delete(`/channels/channel-customer/${channelId}/`);
}
