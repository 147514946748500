import {
  ADD_STRATEGY,
  MOVE_STRATEGY,
  REMOVE_STRATEGY,
  SET_STRATEGIES,
  SET_STRATEGY,
  SET_TEMPLATES_WHATSAPP,
  SET_AGENTS_STRATEGY,
  SET_TIME_STRATEGY,
  SELECT_ALL_ACTIVE_USERS,
  SET_ALL_USERS,
  SELECT_ACTIVE_USERS_BY_PROFILE,
  SET_SELECTED_ACTIVE_USERS_STRATEGY,
  SELECT_SINGLE_ACTIVE_USER,
  RESET_NEW_SELECTED_USERS,
} from "./mutation-types";

const mutations = {
  [SET_STRATEGIES](state, data) {
    state.strategiesInfo = data;
  },
  [ADD_STRATEGY](state, strategy) {
    state.strategiesInfo.items.push(strategy);
  },
  [SET_STRATEGY](state, strategy) {
    const index = state.strategiesInfo.items.findIndex((item) => strategy.id === item.id);
    state.strategiesInfo.items.splice(index, 1, strategy);
  },
  /**
   * Mover estrategía dentro del array.
   *
   * @param {number} from Índice de origen.
   * @param {number} to Índice de destino.
   */
  [MOVE_STRATEGY](state, { from, to }) {
    const item = state.strategiesInfo.items[from];
    state.strategiesInfo.items.splice(from, 1);
    state.strategiesInfo.items.splice(to, 0, item);
  },

  [SET_TEMPLATES_WHATSAPP](state, templates) {
    state.templatesWhatsapp = templates;
  },
  [SET_ALL_USERS](state, data) {
    state.agents = data;
    state.activeUsers = data;
    state.filteredActiveUsers = data;
  },
  [SET_SELECTED_ACTIVE_USERS_STRATEGY](state, selectedUsers) {
    const selectedUsersId = selectedUsers.map((selectedUser) => selectedUser.id);
    state.activeUsers = state.activeUsers.map((activeUser) => ({
      ...activeUser,
      added: selectedUsersId.includes(activeUser.id),
    }));
    state.filteredActiveUsers = state.activeUsers;
  },
  [SELECT_ALL_ACTIVE_USERS](state, status) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({ ...item, added: status }));

    state.filteredActiveUsers = updatedItems;
    state.selectedActiveUsersId = status
      ? currentActiveUsers.map((currentActiveUser) => currentActiveUser.id)
      : [];
  },
  [SELECT_ACTIVE_USERS_BY_PROFILE](state, { status, profileName }) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({
      ...item,
      added: item.profile_name === profileName ? status : item.added,
    }));

    state.filteredActiveUsers = updatedItems;
    const seletedUsersIdByProfile = currentActiveUsers
      .filter((currentActiveUser) => currentActiveUser.profileName === profileName)
      .map((user) => user.id);

    if (status) {
      state.selectedActiveUsersId.concat(seletedUsersIdByProfile);
    } else {
      state.selectedActiveUsersId = state.selectedActiveUsersId.filter(
        (userId) => !seletedUsersIdByProfile.includes(userId)
      );
    }
  },
  [SELECT_SINGLE_ACTIVE_USER](state, { status, userId }) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({
      ...item,
      added: item.id === userId ? status : item.added,
    }));

    state.filteredActiveUsers = updatedItems;
    if (status) {
      state.selectedActiveUsersId.push(userId);
    } else {
      const index = currentActiveUsers.findIndex(
        (currentActiveUser) => currentActiveUser.id === userId
      );
      state.selectedActiveUsersId.splice(index, 1);
    }
  },
  [RESET_NEW_SELECTED_USERS](state) {
    state.selectedActiveUsersId = [];
  },
  [SET_AGENTS_STRATEGY](state, data) {
    state.agents = data;
  },
  [SET_TIME_STRATEGY](state, data) {
    state.emailChannel = data;
  },

  [REMOVE_STRATEGY](state, strategyId) {
    const index = state.strategiesInfo.items.findIndex((item) => strategyId === item.id);
    state.strategiesInfo.items.splice(index, 1);
  },
};

export default mutations;
