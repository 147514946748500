import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener Busqueda de clientes nuevos.
 *
 * @param {String} searchText Texto para buscar un cliente
 * @return {Promise<Object>}  Lista de clientes encontrados
 */
export async function searchClients(searchText) {
  const url = `/workspace/search-clients/?search=${searchText}`;
  const response = await httpManager.get(url);
  return response.data;
}
