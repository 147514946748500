import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar una direccion.
 *
 * @param {number} contactId Identificador de la direccion.
 */
export async function deleteContactClient(ticketId, contactId) {
  await httpManager.delete(`/workspace/tickets/${ticketId}/contacts/${contactId}/`);
}
