import { CLEAR_FILTERS, SET_FILTERS, SET_PROPERTIES } from "./mutation-types";

const mutations = {
  [SET_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_PROPERTIES](state, properties) {
    for (const key in properties) {
      state[key] = properties[key];
    }
  },
  [CLEAR_FILTERS](state) {
    state.filters = null;
  },
};

export default mutations;
