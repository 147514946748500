<template>
  <a-table
    size="middle"
    :scroll="scrollStyle"
    :loading="loading"
    :columns="unSuscriberTableColumns"
    :dataSource="tableData?.results"
    :pagination="pagination"
    class="unsuscriber-table"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'description'">
        <div class="">
          {{ record?.description || "Sin motivo registrado" }}
          <InfoCircleOutlined />
        </div>
      </template>
      <template v-if="column.dataIndex === 'cancellation_count'">
        <div class="">{{ record?.cancellation_count }} resuscripciones</div>
      </template>
      <template v-if="column.dataIndex === 'actions'">
        <a-space class="contact-space" :size="14" style="margin-left: 10px">
          <div class="actions">
            <a-tooltip :title="validateTitle(record)">
              <a-button
                @click="showModal(record)"
                :disabled="record?.cancellation_count >= 2"
                class="transparent-button"
              >
                <ReloadOutlined v-if="record?.cancellation_count >= 2" />
                <ReloadOutlined style="color: blue" v-else />
              </a-button>
            </a-tooltip>
          </div>
        </a-space>
      </template>
    </template>
  </a-table>
  <SubscribeModal v-model:open="openSubscribeModal" :record="selectedRecord" />
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { unSuscriberTableColumns } from "@/app/platformSettings/utils/columns/unSubscribers";
import { ReloadOutlined } from "@ant-design/icons-vue";
import SubscribeModal from "@/app/platformSettings/components/modal/SubscribeModal.vue";
import { emailService } from "@/app/emails/services/Email.service";
import { cloneDeep, debounce } from "lodash";
import { EmailRecordInfo } from "@/@types/emails";
import EventBus from "@/app/shared/utils/eventBus";

const { scrollStyle } = useScrollResize({ headerMargin: 520, width: 1300 });

const loading = ref(false);
const currentTablePage = ref(1);
const selectedRecord = ref(null);
const openSubscribeModal = ref(false);
const tableData = ref(null);

const props = defineProps({
  controls: Object,
});

const validateTitle = (record: any) => {
  return record?.cancellation_count >= 2
    ? "Se ha llegado al límite de 2 resuscripciones por correo."
    : 'Resuscribir';
};

const pagination = computed(() => ({
  total: tableData.value?.count,
  current: currentTablePage.value,
  pageSize: 20,
  showSizeChanger: false,
}));

const fetchSUnsubscribeEmailData = async () => {
  loading.value = true;
  const filters = cloneDeep(props.controls);

  tableData.value = await emailService.getUnsubscribeEmails({
    filters,
    page: 1,
  });

  loading.value = false;
};

// const displayAssocited = (record: any) => {
//   if (record?.associated_persons.length > 0) {
//     return record?.associated_persons.length + "asociados";
//   }

//   return "";
// };

const showModal = (record: EmailRecordInfo) => {
  openSubscribeModal.value = true;
  selectedRecord.value = record;
};

onMounted(() => fetchSUnsubscribeEmailData());

EventBus.on("updateUnsubscribeTable", () => {
  fetchSUnsubscribeEmailData();
});

watch(
  () => props.controls,
  debounce(() => {
    fetchSUnsubscribeEmailData();
  }, 500),
  { deep: true }
);
</script>
<style scoped lang="sass">
:deep(.contact-button > span)
  display: initial
.actions
  display: flex
  gap: 5px
  justify-content: center
  color: #1677FF
.transparent-button
  background-color: transparent
  border: none
  box-shadow: none
  padding: 0
  display: inline-flex
  align-items: center
  justify-content: center
.transparent-button:hover,
.transparent-button:focus
  background-color: transparent
  border: none
  box-shadow: none
</style>
