<template>
  <section class="flex">
    <a-form layout="vertical" class="form">
      <a-form-item>
        <template #label>
          <span class="required">*</span>
          <span class="label"> Contraseña actual</span>
        </template>
        <a-input-password
          v-model:value="old_password"
          @input="handleChangeOldPassword"
          placeholder="Escribir actual contraseña"
        />
      </a-form-item>

      <a-form-item :validate-status="colorValidator">
        <template #label>
          <span class="required">*</span>
          <span class="label"> Nueva contraseña </span>
        </template>
        <a-input-password
          v-model:value="password"
          @input="handleChangeNewPassword"
          placeholder="Escribir nueva contraseña"
          class="input-password"
        />
        <template #help>
          <span v-if="comparationPassword" class="form__error"
            >Escribe una contraseña diferente a la actual</span
          >
        </template>
      </a-form-item>

      <a-form-item :validate-status="getPasswordValidationStatus">
        <template #label>
          <span class="required">*</span>
          <span class="label"> Confirmar contraseña </span>
        </template>
        <a-input-password v-model:value="confirmation" placeholder="Confirmar nueva contraseña" />
        <template #help>
          <span v-if="!thereIsMatch"> La contraseña nueva no coincide</span>
        </template>
      </a-form-item>
    </a-form>
    <div class="help">
      <div v-for="help of helps" :key="help.text" class="help__item">
        <CheckCircleOutlined
          v-if="help.active"
          class="help__icon"
          :class="getClassDynamic(help.active)"
        />
        <CloseCircleOutlined v-else class="help__icon" :class="getClassDynamic(help.active)" />
        <span>{{ help.text }}</span>
      </div>
    </div>
    <div class="image__password">
      <img :src="resetIcon" alt="contraseña" />
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent, ref, computed } from "vue";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";

import resetIcon from "@/app/settings/assets/resetPassword/icon-reset-password.svg?url";

export default defineComponent({
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  name: "RegisterPassword",
  props: {
    value: {
      required: true,
      type: String,
    },
  },
  setup() {
    const store = useStore();
    const old_password = ref("");
    const password = ref("");
    const confirmation = ref("");
    const thereIsMatch = computed(() => {
      return password.value === confirmation.value;
    });
    const isDifferentPassword = computed(() => {
      return old_password.value === password.value;
    });
    return {
      store,
      password,
      confirmation,
      thereIsMatch,
      old_password,
      isDifferentPassword,
      resetIcon,
    };
  },
  data() {
    return {
      initial: true,
      helps: [
        {
          active: false,
          text: "Al menos 8 caracteres de longitud",
          rule: (value) => value.length >= 8,
        },
        {
          active: false,
          text: "Usar mínimo un caracter en mayúscula",
          rule: (value) => /[A-Z]/.test(value),
        },
        {
          active: false,
          text: "Usar mínimo un caracter en minúscula",
          rule: (value) => /[a-z]/.test(value),
        },
        {
          active: false,
          text: "Un número, símbolo o espacio en blanco",
          rule: (value) => /[<\d\-\\[\]{|}()/¡!¿?'"@#$%&*+,.:;^~_=`> ]/.test(value),
        },
      ],
    };
  },
  watch: {
    password(value) {
      this.helps.forEach((help) => {
        help.active = help.rule(value);
      });
    },
    formIsReady(value) {
      if (value) this.$emit("setPassword", this.password);
      else this.$emit("setPassword", "");
    },
  },
  computed: {
    isPasswordStrong() {
      const actives = this.helps.map((help) => help.active);
      return actives.reduce((previous, current) => previous && current, true);
    },
    formIsReady() {
      return this.isPasswordStrong && this.thereIsMatch && !!this.confirmation;
    },
    getPasswordValidationStatus() {
      return !this.thereIsMatch ? "error" : "";
    },
    comparationPassword() {
      return this.isPasswordStrong && this.isDifferentPassword && this.password;
    },
    colorValidator() {
      return this.old_password !== "" && this.isDifferentPassword ? "error" : "";
    },
  },

  methods: {
    getClassDynamic(active) {
      const initial = this.initial ? "help__icon--initial" : "help__icon--error";
      return active ? "help__icon--success" : initial;
    },
    handleChangeNewPassword() {
      this.initial = false;
    },
    handleChangeOldPassword() {
      this.$emit("setOldPassword", this.old_password);
    },
  },
});
</script>

<style lang="sass" scoped>
.form
  width: 42%

.help
  margin: 38px 0 10px 0

  &__item
    padding: 0.325rem 0

  &__icon
    margin-right: 0.5rem

    &--initial
      color: $gray-4

    &--success
      color: #52C41A

    &--error
      color: $red-6

.item__checkbox
  margin-top: 10px

.item__information
  margin-top: -10px

.required
  color: red
  margin-right: 2px

.flex
  display: flex
  justify-content: space-around

.image__password
  margin-top: 114px

.input-password
  margin-bottom: 10px

.form__error
  color: $red-6
  margin: 0
</style>
