import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza datos del contacto
 * @param {number} contactId Identificador del contacto
 * @param {Object} body Datos del contacto.
 *
 * @return {Promise<Object>} Datos de la información personal
 */
export async function updateInformationPersonal(contactId, body) {
  const url = `/contacts/clients/${contactId}/general-values/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
