<template>
  <p>
    Utiliza tu API Key integrar tus datos de {{ ProductName }} con otras aplicaciones. Esta
    información es sensible y brinda acceso a datos de tu empresa a través de API's activas. Si se
    compromete, puedes restablecerlo, pero las aplicaciones que usen el código anterior perderán
    acceso. <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para
    conocer más.
  </p>

  <a :href="apiDocumentationUrl" target="_blank" rel="noopener noreferrer">
    <span>
      Ver documentación del API
      <img :src="externalLinkImage" alt="external-link" />
    </span>
  </a>

  <div class="key__wrapper">
    <label>API Key</label>

    <div class="input__wrapper">
      <a-input-group compact class="input">
        <a-input-password :value="apiKey" :disabled="!apiKey" placeholder="Genere su api key" />
        <a-tooltip title="Copiar">
          <a-button :disabled="!apiKey" @click="handleCopy(apiKey)">
            <template #icon>
              <CopyOutlined />
            </template>
          </a-button>
        </a-tooltip>
      </a-input-group>

      <div class="action">
        <a-button class="button" @click="generateApiKey">Generar API Key</a-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { CopyOutlined } from "@ant-design/icons-vue";

import { useCopyToClipboard } from "@/app/shared/composables/useCopyToClipboard";
import { apiService } from "@/app/platformSettings/services/ApiService";
import { ProductName } from "@/app/shared/utils/constants";

import externalLinkImage from "@/app/supervision/assets/external-link.svg?url";

const DOCS_URL = `${
  import.meta.env.VITE_WEB_DOC
}/docs/score/ajustes-en-la-plataforma/api-key-callback/`;

const apiDocumentationUrl = `https://api.${import.meta.env.VITE_HOSTNAME}/docs`;

const props = defineProps<{
  token: string;
}>();

const apiKey = ref(props.token);
const loading = ref(false);

const handleCopy = useCopyToClipboard();

const generateApiKey = async () => {
  loading.value = true;

  apiKey.value = await apiService.generateToken();

  loading.value = false;
};
</script>

<style scoped lang="sass">
p
  color: $gray-8
  margin-bottom: 16px

a
  display: inline-block

  span
    display: flex
    align-items: center

  img
    margin-left: 5px

.key__wrapper
  margin-top: 16px

.input__wrapper
  display: flex
  align-items: center
  gap: 12px

.input
  max-width: min(450px, 100%)
  display: flex !important

.action
  width: 170px

  .button
    width: 100%
</style>
