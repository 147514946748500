import generateTreeNodes from "@/app/chatbots/utils/generateTree";

const getters = {
  getChatbotsList: (state) => state.chatbots,
  paginate_chatbots: (state) => state.paginate_chatbots,
  chatbotSelected: (state) => state.chatbotSelected,
  getChatbotNodes: (state) => state.listNodesChatbot,
  getQueuesSelectOptions: (state) => state.queuesSelectOptions,
  treeNodes: (state) => {
    return generateTreeNodes([...state.nodes]);
  },
  changes: (state) => state.changes,
  isSavedNode: (state) => state.isSavedNode,
  nodes: (state) => state.nodes,
  selectedNodeId: (state) => state.selectedNodeId,
  chatbot: (state) => state.chatbot,
  filters: (state) => state.filters,
  nodeSelected: (state) => state.nodeSelected,
};

export default getters;
