import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo recordatorio.
 * @param {Object} reminder Datos del recordatorio.
 *
 * @return {Promise<Object>} Nuevo recordatorio
 */
export async function addReminder(reminder) {
  const url = `/schedule/reminders/`;
  const response = await httpManager.post(url, reminder);
  return response.data;
}
