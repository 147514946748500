import { fetchAllResults } from "@/app/entities/services";

import { SET_RESULTS } from "./mutation-types";

const actions = {
  async fetchAllResults({ commit }) {
    const data = await fetchAllResults();
    commit(SET_RESULTS, data.results);
  },
};

export default actions;
