import { ADD_DID_TO_LIST, SET_DIDS, SET_DID, ADD_NEW_DID, CLEAR_DID } from "./mutation-types";

const mutations = {
  [SET_DIDS](state, data) {
    state.didsInfo = data;
  },
  [ADD_DID_TO_LIST](state, newDid) {
    state.didsInfo.items.unshift(newDid);
  },
  [SET_DID](state, did) {
    state.did = did;
  },
  [ADD_NEW_DID](state, payload) {
    const index = state.didsInfo.items.findIndex((did) => {
      return did.id == payload.id;
    });
    state.didsInfo.items.splice(index, 1, payload.updatedDid);
  },
  [CLEAR_DID](state) {
    state.did = {};
  },
};
export default mutations;
