import {
  fetchActiveTickets,
  fetchChannelsByTicketWithoutChannelPerson,
  fetchChannelsByTicket,
  fetchWorkspaceClientByTicket,
  updatePersonalInformation,
  addPhonesClient,
  deletePhoneClient,
  addAddressesClient,
  deleteAddresseClient,
  createClientEmail,
  deleteEmailClient,
  sendWorkspaceMessage,
  updateAddressesClient,
  updatePhonesClient,
  updateEmailClient,
  updateDataCustom,
  searchClients,
  assignClient,
  assignTagToTickets,
  tranferTicketAgent,
  tranferTicketQueues,
  createNewClient,
  saveDefaultManagement,
  addContactClient,
  updateContactClient,
  deleteContactClient,
  ticketHistoryDetail,
  fetchPortfolioDetail,
  typificationsTable,
  getEmailHistory,
} from "@/app/workspace/services";

import { ticketService } from "../../services/TicketService";

import {
  SET_CLIENT,
  SET_TICKETS,
  SET_MESSAGE_HISTORY,
  UPDATE_PERSONAL_INFORMATION,
  SET_CLIENT_CHANNELS,
  SET_HAS_NEXT_TICKET_PAGE,
  ADD_PHONES_CLIENT,
  REMOVE_PHONE_CLIENT,
  ADD_ADDRESSES_CLIENT,
  REMOVE_ADDRESSES_CLIENT,
  REMOVE_EMAIL_CLIENT,
  ADD_EMAIL_CLIENT,
  UPDATE_ADDRESSES_CLIENT,
  UPDATE_PHONES_CLIENT,
  UPDATE_EMAIL_CLIENT,
  UPDATE_DATA_CUSTOM,
  SET_CLIENTS_WITH_SAME_NUMBER_FOUND,
  SET_CLIENTS_FOUND,
  SET_CLEAN_RESULTS,
  SET_CONTACTS_CLIENT,
  UPDATE_CONTACTS_CLIENT,
  DELETE_CONTACTS_CLIENT,
  SET_CALL_FINISH,
  SET_TICKET_DETAIL,
  SET_TICKETS_LIST_PAGE,
  SET_PORTFOLIO_DETAIL,
  SET_TYPIFICATIONS_TABLE_DATA,
  SET_CAPTURE,
  SET_INTERACTIONS_CHANNEL,
  SET_EMAIL_HISTORY,
  SET_EVENT_MESSAGING,
} from "./mutation-types";
import { debounce } from "lodash";

const actions = {
  async fetchWorkspaceClientByTicket({ commit }, { ticketId, preview, refresh = false }) {
    if (!refresh) commit(SET_CLIENT, null);
    const client = await fetchWorkspaceClientByTicket(ticketId, preview);
    commit(SET_CLIENT, client);
  },
  // async fetchActiveWorkspaceTickets({ commit, state }, { page = 1 } = {}) {
  //   const filters = state.ticketsFilters;
  //   const { tickets, nextPage } = await fetchActiveTickets({ ...filters, page });

  //   commit(SET_HAS_NEXT_TICKET_PAGE, Boolean(nextPage));
  //   commit(SET_TICKETS_LIST_PAGE, page);
  //   commit(SET_TICKETS, page === 1 ? tickets : [...state.tickets, ...tickets]);
  // },
  fetchActiveWorkspaceTickets: debounce(async function ({ commit, state }, { page = 1 } = {}) {
    const filters = state.ticketsFilters;
    const { tickets, nextPage } = await fetchActiveTickets({ ...filters, page });

    commit(SET_HAS_NEXT_TICKET_PAGE, Boolean(nextPage));
    commit(SET_TICKETS_LIST_PAGE, page);
    commit(SET_TICKETS, page === 1 ? tickets : [...state.tickets, ...tickets]);
  }, 100),
  /**
   * Obtener el historial de tickets con sus mensajes.
   * @param {payload} payload Parámetros para la petición.
   * @param {boolean} payload.prevent Prevenir el efecto de cargando.
   */
  async fetchHistoryByTicket({ commit, state }, payload) {
    console.log("fetchHistoryByTicket", payload);
    if (!payload?.prevent) commit(SET_MESSAGE_HISTORY, null);
    const ticketId = payload?.ticket;
    const interactionId = payload?.interaction || null;
    const history = await ticketService.getInteractionMessages(interactionId);
    console.log(history, "HISTORYYY");
    commit(SET_MESSAGE_HISTORY, history);
    const ticketInList = state.tickets.find((t) => t.id === Number(ticketId));

    if (!ticketInList) return;

    ticketInList.last_message.message = history.messages.at(-1).message;
    ticketInList.last_message.datetime = history.messages.at(-1).created_at;
    ticketInList.unread_messages = 0;
  },
  async saveDefaultManagement({ dispatch }, body) {
    await saveDefaultManagement(body);
    dispatch("fetchActiveWorkspaceTickets");
  },
  async sendWorkspaceMessage(_, payload) {
    await sendWorkspaceMessage(payload);
  },
  async fetchChannelsByTicketWithoutChannelPerson({ commit }, ticketId) {
    commit(SET_CLIENT_CHANNELS, []);
    const channels = await fetchChannelsByTicketWithoutChannelPerson(ticketId);
    commit(SET_CLIENT_CHANNELS, channels);
  },
  async fetchChannelsByTicket({ commit }, ticketId) {
    commit(SET_CLIENT_CHANNELS, []);
    const channels = await fetchChannelsByTicket(ticketId);
    commit(SET_CLIENT_CHANNELS, channels);
  },
  async updatePersonalInformation({ commit }, { ticketId, data }) {
    const updatedInformation = await updatePersonalInformation(ticketId, data);
    commit(UPDATE_PERSONAL_INFORMATION, updatedInformation);
  },
  async addAddresseClient({ commit }, { ticketId, addresses }) {
    const dataAddresse = await addAddressesClient(ticketId, addresses);
    commit(ADD_ADDRESSES_CLIENT, dataAddresse);
  },
  async updateAddresseClient({ commit }, { id, addresses }) {
    const dataAddresse = await updateAddressesClient(id, addresses);
    commit(UPDATE_ADDRESSES_CLIENT, dataAddresse);
  },
  async updateEmailClient({ commit }, { emailId, body }) {
    const dataEmail = await updateEmailClient(emailId, body);
    commit(UPDATE_EMAIL_CLIENT, dataEmail);
  },
  async updateDataCustom({ commit }, { clientId, body }) {
    const dataCustom = await updateDataCustom(clientId, body);
    commit(UPDATE_DATA_CUSTOM, [dataCustom]);
  },
  async createClientEmail({ commit }, { ticketId, body }) {
    const dataEmail = await createClientEmail(ticketId, body);
    commit(ADD_EMAIL_CLIENT, dataEmail);
  },
  async addPhonesClient({ commit }, { ticketId, phones }) {
    const dataPhone = await addPhonesClient(ticketId, phones);
    commit(ADD_PHONES_CLIENT, dataPhone);
  },
  async updatePhoneClient({ commit }, { id, phones }) {
    const dataPhone = await updatePhonesClient(id, phones);
    commit(UPDATE_PHONES_CLIENT, dataPhone);
  },
  async deleteAddresseClient({ commit }, addresseId) {
    await deleteAddresseClient(addresseId);
    commit(REMOVE_ADDRESSES_CLIENT, addresseId);
  },
  async deleteEmailClient({ commit }, emailId) {
    await deleteEmailClient(emailId);
    commit(REMOVE_EMAIL_CLIENT, emailId);
  },
  async deletePhoneClient({ commit }, { phoneId, client }) {
    await deletePhoneClient(phoneId, client);
    commit(REMOVE_PHONE_CLIENT, phoneId);
  },
  async addContactClient({ commit }, { ticketId, contact }) {
    const newContact = await addContactClient(ticketId, contact);
    commit(SET_CONTACTS_CLIENT, newContact);
  },
  async updateContactClient({ commit }, { ticketId, contact }) {
    const newContact = await updateContactClient(ticketId, contact);
    commit(UPDATE_CONTACTS_CLIENT, newContact);
  },
  async deleteContactClient({ commit }, { ticketId, contact }) {
    await deleteContactClient(ticketId, contact.id);
    commit(DELETE_CONTACTS_CLIENT, contact.id);
  },
  async searchClientsWithSameNumber({ commit }, searchNumber) {
    const data = await searchClients(searchNumber);
    commit(SET_CLIENTS_WITH_SAME_NUMBER_FOUND, data.results);
  },
  async searchClients({ commit }, searchText) {
    const data = await searchClients(searchText);
    commit(SET_CLIENTS_FOUND, data.results);
  },
  async assignClient({ dispatch }, body) {
    const data = await assignClient(body);
    await dispatch("fetchActiveWorkspaceTickets");
    return data;
  },
  async cleanResultsClient({ commit }) {
    commit(SET_CLEAN_RESULTS, []);
  },
  async tranferTicketAgent({ dispatch }, body) {
    await tranferTicketAgent(body);
    await dispatch("fetchActiveWorkspaceTickets");
  },
  async tranferTicketQueues({ dispatch }, body) {
    await tranferTicketQueues(body);
    await dispatch("fetchActiveWorkspaceTickets");
  },
  async assignTagToTickets({ dispatch }, body) {
    await assignTagToTickets(body);
    await dispatch("fetchActiveWorkspaceTickets");
  },
  async createNewClient({ dispatch }, body) {
    const data = await createNewClient(body);
    await dispatch("fetchActiveWorkspaceTickets");
    return data;
  },
  callEnded({ commit }, call) {
    commit(SET_CALL_FINISH, call);
  },
  async getTicketHistoryDetail({ commit }, ticketId) {
    const response = await ticketHistoryDetail(ticketId);
    commit(SET_TICKET_DETAIL, response);
  },
  async fetchPortfolioDetail({ commit }, portfolioId) {
    const response = await fetchPortfolioDetail(portfolioId);
    commit(SET_PORTFOLIO_DETAIL, response);
  },
  async fetchTypificationsTableData({ commit }, ticketId) {
    const response = await typificationsTable(ticketId);
    commit(SET_TYPIFICATIONS_TABLE_DATA, response);
  },
  setCaptureTicket({ commit }, data) {
    commit(SET_CAPTURE, data);
  },
  async fetchInteractionsChannels({ commit }, ticketId) {
    const data = await ticketService.getInteractionsChannels(ticketId);
    commit(SET_INTERACTIONS_CHANNEL, data);
  },
  async getEmailHistory({ commit }, emailId) {
    const response = await getEmailHistory(emailId);
    commit(SET_EMAIL_HISTORY, response);
  },
  setEventMessaging({ commit }, data) {
    commit(SET_EVENT_MESSAGING, data);
  },
};

export default actions;
