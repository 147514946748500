import { phoneNumberRegexp } from "@/app/shared/utils/regex";

export function usePhoneNumberValidation() {
  const validateInput = (event: KeyboardEvent) => {
    if (event.key === "Backspace") return;

    if (["#", "*"].includes(event.key)) return;

    if (!phoneNumberRegexp.test(event.key) || event.code === "Space") event.preventDefault();
  };

  const handlePaste = (event: ClipboardEvent) => {
    if (!event.clipboardData) return;

    const pastedText = event.clipboardData.getData("text/plain");

    if (!phoneNumberRegexp.test(pastedText)) event.preventDefault();
  };

  return { validateInput, handlePaste };
}
