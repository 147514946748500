import {
  fetchAllEntities,
  createNewEntity,
  updateEntity,
  updateStatusEntitie,
  sendEntityToken,
  deleteEntity,
} from "@/app/entities/services";
import { SET_ENTITIES, UPDATE_STATUS_ENTITY, SEND_ENTITY_TOKEN } from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchAllEntities({ commit }, params = {}) {
    const { filters, pagination } = params;
    const data = await fetchAllEntities(filters, pagination);
    commit(SET_ENTITIES, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async createNewEntity(_, newEntity) {
    await createNewEntity(newEntity);
  },
  async updateEntity(_, { entityId, entity }) {
    await updateEntity(entityId, entity);
  },
  async updateStatusEntitie({ commit }, { params, id }) {
    const updatedStatusEntity = await updateStatusEntitie(id, params);
    commit(UPDATE_STATUS_ENTITY, { id, updatedStatusEntity });
  },
  async deleteEntity(_, { entityId, tokenId }) {
    await deleteEntity(entityId, tokenId);
  },
  async sendEntityToken({ commit }, token) {
    await sendEntityToken(token);
    commit(SEND_ENTITY_TOKEN, token);
  },
};

export default actions;
