<template>
  <a-tooltip>
    <template #title v-if="tooltipMessage"> {{ tooltipMessage }} </template>
    <a-button :type="type" :class="{ btn: hasText }" @click.stop="handleClick">
      <span v-if="hasText && titlePosition === 'left'">{{ title }}</span>
      <component :is="iconComponent" class="icon" />
      <span v-if="hasText && titlePosition === 'right'">{{ title }}</span>
    </a-button>
  </a-tooltip>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import {
  SwapOutlined,
  UserSwitchOutlined,
  FolderOutlined,
  TagOutlined,
  CheckOutlined,
  LinkOutlined,
  MailOutlined,
} from "@ant-design/icons-vue";

import IconFolderArrow from "@/app/common/components/atoms/IconFolderArrow.vue";
import IconEmailOpen from "@/app/common/components/atoms/IconEmailOpen.vue";

const props = defineProps({
  type: { type: String },
  hasText: { type: Boolean, default: false },
  title: { type: String, required: false },
  icon: { type: String },
  titlePosition: { type: String, required: false },
  tooltipMessage: { type: String, required: false, default: null },
});

const iconComponent = computed(() => {
  const iconMap = {
    SwapOutlined,
    UserSwitchOutlined,
    FolderOutlined,
    TagOutlined,
    CheckOutlined,
    LinkOutlined,
    IconFolderArrow,
    MailOutlined,
    IconEmailOpen,
  };
  return iconMap[props.icon] || SwapOutlined;
});

const emit = defineEmits(["handleActionButton"]);

const handleClick = () => {
  emit("handleActionButton");
};
</script>

<style lang="sass" scoped>
.btn
  padding: 0 15px
button
  padding: 0
</style>
