import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar una direccion.
 *
 * @param {number} addressId Identificador de la direccion.
 */
export async function deleteAddresseClient(addressId) {
  await httpManager.delete(`/workspace/tickets/addresses/${addressId}/`);
}
