import {
  SET_TRUNKS,
  ADD_TRUNK_TO_LIST,
  SET_TRUNK,
  ADD_NEW_TRUNK,
  CLEAR_TRUNK,
  REMOVE_TRUNK,
} from "./mutation-types";

const mutations = {
  [SET_TRUNKS](state, data) {
    state.trunksInfo = data;
  },
  [ADD_TRUNK_TO_LIST](state, newTrunk) {
    state.trunksInfo.items.unshift(newTrunk);
  },
  [SET_TRUNK](state, trunk) {
    console.log(trunk, "SET_TRUNK");
    state.trunk = trunk;
  },
  [ADD_NEW_TRUNK](state, payload) {
    const index = state.trunksInfo.items.findIndex((trunk) => {
      return trunk.id == payload.id;
    });
    state.trunksInfo.items.splice(index, 1, payload.updatedTrunk);
  },
  [CLEAR_TRUNK](state) {
    state.trunk = {};
  },
  [REMOVE_TRUNK](state, trunkId) {
    const index = state.trunksInfo.items.findIndex((item) => trunkId == item.id);
    state.trunksInfo.items.splice(index, 1);
  },
};
export default mutations;
