const initialState = () => ({
  activeUsers: [],
  filteredActiveUsers: [],
  agentsPortfolio: [],
  selectedActiveUsersId: [],
});

const state = initialState();

export default state;
