import { createApp } from "vue";
import antd from "ant-design-vue";
import { createPinia } from "pinia";
import VueApexCharts from "vue3-apexcharts";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import { DraggablePlugin } from "@braks/revue-draggable";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import * as Sentry from "@sentry/vue";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { VueEmailPlugin } from "vue-email";

import { router } from "./router";
import { store } from "./store";
import { maskV3 } from "./plugins/mask";
import { socketIO } from "./plugins/socket";
import { jssip } from "./plugins/jssip";
import WebPhone from "./plugins/webphone";

import App from "./App.vue";
import "./registerServiceWorker";

// import "ant-design-vue/dist/antd.css";
import "ant-design-vue/dist/reset.css";

export const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/([a-zA-Z0-9_-]+)\.beexcontact\.(com|dev|io|xyz)$/,
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend(event) {
    event.tags = event.tags || {};
    event.tags.tenant = localStorage.getItem("workspace");
    event.tags.userId = localStorage.getItem("used_id");

    return event;
  },
});

const pinia = createPinia();

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

dayjs().localeData();
dayjs.locale("es");
dayjs.updateLocale("es", {
  weekdaysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
  monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
});

app.use(antd);
app.use(VueApexCharts);
app.use(VueClipboard);
app.use(DraggablePlugin);
app.use(store);
app.use(router);
app.use(socketIO);
app.use(pinia);
app.use(jssip);
app.use(WebPhone);
app.use(CKEditor);
app.use(VueEmailPlugin);

// use Vue plugin with options
app.use(VueSvgInlinePlugin, {
  attributes: {
    data: ["src"],
    remove: ["alt"],
  },
});

app.directive("mask", maskV3);
app.mount("#app");
