<template>
  <div style="margin-bottom: 16px">
    <span v-if="typeof product !== 'number'" class="product">
      Cod. Producto: {{ product.code }}
    </span>

    <a-form ref="typificationFormRef" layout="vertical" :model="workspaceFormState">
      <FormInput
        v-for="(element, index) in formActiveElements"
        ref="formInputList"
        :key="element.name"
        isWorkspace
        :input="element"
        :index="index"
        :conditionalBody="conditionalBody"
        :modalKey="modalKey"
        :isPerProduct="isPerProduct"
        :hasInputsFilled="hasInputsFilled"
      />
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, provide, watch, computed } from "vue";
import { FormInstance } from "ant-design-vue";
import { debounce } from "lodash";

import { Product } from "@/@types/global";
import {
  ConditionedAttribute,
  ElementStatus,
  ElementType,
  Form,
  FormElement,
} from "@/@types/entities/forms";
import { buildConditionalsBody } from "@/app/entities/utils/forms/buildCondition";

import FormInput from "@/app/entities/components/sections/forms/FormInput.vue";
import { validateObjectsArrayHasAtLeastOneKeyCompleted } from "@/app/shared/utils/helpers";

const props = defineProps<{
  form: Form;
  product: Product | number;
  modalKey: string;
  isPerProduct?: boolean;
}>();

const typificationFormRef = ref<FormInstance | undefined>(undefined);
const formInputList = ref<InstanceType<typeof FormInput>[]>([]);

const workspaceFormState: Record<string, string | undefined | null> = reactive({});
const conditionalBody = ref<Record<string, ConditionedAttribute>>({});

const formActiveElements = computed(() =>
  props.form.form.filter((input) => input.status === ElementStatus.Active)
);

onMounted(() => {
  formActiveElements.value.forEach((element) => {
    const elementsList = formInputList.value
      .map((ref) => ref.element)
      .filter((input) => input.type !== ElementType.Header);
    const currentInput = elementsList.find((input) => input.name === element.name);

    workspaceFormState[element.name] = currentInput?.value;
  });
});

watch(
  workspaceFormState,
  debounce(() => {
    if (!props.form.conditionals) return;

    const condBody = buildConditionalsBody(props.form.conditionals, workspaceFormState);

    conditionalBody.value = condBody;

    /**
     * When the element gets hidden this lines set its value to null. If the value is an object
     * like PDP or Reminder, each value of the object is set as null
     */
    for (const key in condBody) {
      const attributes = condBody[key];

      if (Object.keys(attributes).includes("show") && !attributes.show) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let currentElement = workspaceFormState[key] as any;

        if (typeof currentElement === "object") {
          for (const elmKey in currentElement) {
            currentElement[elmKey] = null;
          }
        } else {
          currentElement = null;
        }
      }
    }
  }, 200)
);

provide("workspaceFormState", workspaceFormState);

const hasInputsFilled = computed(() => {
  if (!props.isPerProduct) return true;

  return validateObjectsArrayHasAtLeastOneKeyCompleted([workspaceFormState]);
});

const validateInputs = async () => {
  let fields: FormElement[] = [];

  await typificationFormRef.value
    ?.validateFields()
    .then(() => {
      const elementsList = formInputList.value
        .map((ref) => ref.element)
        .filter((input) => input.type !== ElementType.Header);

      fields = elementsList.map((input) => ({ ...input, value: workspaceFormState[input.name] }));
    })
    .catch(() => {
      throw new Error("Forms invalidas");
    });

  return {
    fields,
    code: typeof props.product !== "number" ? props.product.code : null,
    account_id: typeof props.product !== "number" ? props.product.id : null,
  };
};

defineExpose({ validateInputs, workspaceFormState });
</script>

<style scoped lang="sass">
.product
  color: $gray-8
  font-weight: 600
  margin-bottom: 10px
</style>
