import { RolTypes } from "@/app/shared/utils/enums";

const mapProfileGroup = (activeUsers) => {
  const profilesGroup = {};

  for (const user of activeUsers) {
    if (!profilesGroup[user.profile_name]) {
      profilesGroup[user.profile_name] = [
        {
          added: user.added,
          id: user.id,
          fullname: user.fullname,
          picture: user.picture,
          icon: user.icon,
          role: user.role,
        },
      ];
    } else {
      profilesGroup[user.profile_name].push({
        added: user.added,
        id: user.id,
        fullname: user.fullname,
        picture: user.picture,
        icon: user.icon,
        role: user.role,
      });
    }
  }

  return profilesGroup;
};

const getters = {
  getActiveUsers(state) {
    return state.filteredActiveUsers;
  },
  getProfilesGroup(state) {
    return mapProfileGroup(state.filteredActiveUsers);
  },
  getProfilesGroupContact(state) {
    // return mapProfileGroup(
    //   state.filteredActiveUsers.filter((item) => item.role.id === RolTypes.agent)
    // );
    return mapProfileGroup(state.filteredActiveUsers);
  },
  getAgentsPortfolio(state) {
    return state.agentsPortfolio;
  },
  getIdSelectedContact(state) {
    return state.selectedActiveUsersId;
  },
  getAllUser(state) {
    return state.activeUsers.filter((item) => item.role.id !== RolTypes.owner);
  },
};

export default getters;
