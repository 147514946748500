<template>
  <a-spin :spinning="loading">
    <div :class="{ 'not-allowed-cursor': disabled }">
      <section class="section">
        <ChatInput
          ref="inputRef"
          :file="fileMetaData"
          :files="filesMetaData"
          :tooltip-text="tooltipText"
          :disabled="disabled"
          @update:message="inputMessage = $event"
          @submit="handleSubmit"
          @delete-file="handleDeleteFile"
          @paste-file="setFile($event)"
          @handle-event-global="handleEventGlobal"
        />

        <div :class="{ 'not-allowed-cursor': hasFileOrRecordFile }">
          <div class="actions" :class="{ 'no-events': disabled || hasFileOrRecordFile }">
            <EmojiPicker @onChangeEmoji="handleSelectEmoji" @handleShowPicker="handleShowPicker" />

            <label for="file-input">
              <ActionButton
                :disabled="hasFilesLimit"
                type="file"
                prevent-event
                @click="handleEventWriting"
              />

              <input
                type="file"
                id="file-input"
                ref="fileInputRef"
                multiple
                :accept="supportedFileTypes.join()"
                @change="handleFileInputChange($event)"
              />
            </label>

            <label for="media-input">
              <ActionButton
                :disabled="hasFilesLimit"
                type="media"
                prevent-event
                @click="handleEventWriting"
              />

              <input
                ref="mediaInputRef"
                id="media-input"
                type="file"
                multiple
                :accept="supportedMediaTypes.join()"
                @change="handleFileInputChange($event)"
              />
            </label>

            <ActionButton
              v-if="channelId === ChannelType.Whatsapp"
              :disabled="hasFiles"
              type="template"
              @click="handleTemplateClick"
            />
            <ActionButton
              :disabled="hasFiles"
              type="quick-answers"
              @click="
                openQuickAnswersModal = true;
                handleEventWriting();
              "
            />
          </div>
        </div>
      </section>
    </div>
  </a-spin>

  <SendWhatsappTemplateModal
    ref="whatsappTemplateModal"
    chat-button
    :record="{ ...contactInfoForTemplate, id: client?.id, code_country: channel?.code_country }"
    :ticketQueue="ticket?.queue"
    :portfolio="ticket?.portfolio"
    :selectedInteraction="channel"
    :ticketId="ticket?.id"
  />

  <SelectQuickAnswerModal
    v-if="openQuickAnswersModal"
    v-model:open="openQuickAnswersModal"
    :queue="channel?.queue?.id ?? ticket?.queue?.id"
    @select:answer="handleAddQuickAnswer($event)"
    @handleClose="handleCloseQuickAnswer"
  />
</template>

<script setup lang="ts">
import { ref, reactive, computed, inject, onBeforeUnmount, watch, nextTick } from "vue";
import type { Ref } from "vue";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";

import type { Emoji, FileMetadata } from "@/@types/workspace";
import type { Ticket, Interaction } from "@/@types/global";
import { supportedMediaTypes, supportedFileTypes } from "./supportedFiles";
import { sendWorkspaceMessage } from "@/app/workspace/services";
import { ChannelType, MessageType } from "@/app/shared/utils/enums";
import { QuickAnswer } from "@/@types/platformSettings/quickAnswers";

import ChatInput from "./ChatInput.vue";
import ActionButton from "./ActionButton.vue";
import SendWhatsappTemplateModal from "@/app/workspace/components/modal/SendWhatsappTemplateModal.vue";
import EmojiPicker from "@/app/shared/components/pickers/EmojiPicker.vue";
import SelectQuickAnswerModal from "../../modal/SelectQuickAnswerModal.vue";
import { useChatStorage } from "@/app/workspace/store/chatStorage";

import { EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";

interface ContactInfo {
  phone: string;
  picture?: string | null;
  full_name: string;
}

const LIMIT_FILES = 5;
const OneMegaByte = 1_000_000;
const route = useRoute();
const store = useStore();

const props = defineProps<{
  channelId?: number;
  tooltipText: string;
  disabled: boolean;
}>();
const emit = defineEmits<{
  (event: "sended"): void;
}>();

const client = computed(() => store.getters["getWorkspaceClient"]);

const inputRef = ref<InstanceType<typeof ChatInput> | null>(null);
const fileInputRef = ref<HTMLInputElement | null>(null);
const mediaInputRef = ref<HTMLInputElement | null>(null);

const fileMetaData: FileMetadata = reactive({
  file: null,
  inputType: null,
});

// const fileInput: FileMetadata = reactive({
//   file: null,
//   inputType: null,
// });

const filesMetaData: FileMetadata[] = reactive([]);

const hasFileOrRecordFile = computed(
  () => fileMetaData.file || inputRef.value?.getRecordMetadata()?.recordingFile
);

const hasFiles = computed(() => filesMetaData.length > 0);

const hasFilesLimit = computed(() => filesMetaData.length == LIMIT_FILES);

const handleSelectEmoji = (emojiMetadata: Emoji) => {
  inputRef.value?.addCharToInputContent(emojiMetadata.i);
  registerEvent(EventManagement.Writing);
};

const handleEventWriting = () => {
  registerEvent(EventManagement.Writing);
};

const handleCloseQuickAnswer = (event) => {
  // Si es que no se agregó nada
  if (!event) handleEventGlobal();
};

// const globalState = computed(() => store.getters["getStateUser"]);
const handleEventGlobal = async () => {
  await nextTick();
  const message = inputRef.value?.textInputRef?.textContent;
  if ((message == "" || !message) && fileMetaData?.file == null && filesMetaData?.length == 0)
    registerEvent(EventManagement.Opened);
  // registerEvent(store.getters["getStateUser"]);
};

const setFile = (file: File | undefined) => {
  try {
    if (filesMetaData.length < LIMIT_FILES) {
      validateFile(file);
      const fileInput = { file: undefined, inputType: "" };
      fileInput.file = file;

      if (file?.type.includes("video")) fileInput.inputType = "video";
      else if (file?.type.includes("image")) fileInput.inputType = "image";
      else fileInput.inputType = "file";
      filesMetaData.push(fileInput);
      handleEventWriting();
    }
  } catch (error) {
    console.error(error);
    message.error(error as string);
  }
};

const handleFileInputChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  // const selectedFile = target.files?.[0];
  const fileList = target.files;
  const filesArray = Array.from(fileList);

  filesArray.forEach((file) => {
    setFile(file);
  });
};

const validateFile = (file: File | undefined) => {
  if (!file) throw "No se seleccionó ningun archivo";

  if (file.size > 5 * OneMegaByte) throw "Supera el tamaño máximo de 5MB";
};

const handleDeleteFile = (position?: number) => {
  if (position != undefined && position > -1) {
    filesMetaData.splice(position, 1);
  } else {
    filesMetaData.splice(0, filesMetaData.length);
    fileMetaData.file = null;
    fileMetaData.inputType = null;
    if (fileInputRef.value) fileInputRef.value.value = "";
    if (mediaInputRef.value) mediaInputRef.value.value = "";
  }
};

const ticket = inject("selectedTicket") as Ref<Ticket>;
const channel = inject("selectedChannel") as Ref<Interaction>;
const contactInfoForTemplate = reactive<ContactInfo>({
  phone: channel.value?.identifier || ticket.value.identifier,
  picture: ticket.value?.client?.profile_picture,
  full_name: ticket.value?.client?.name || "",
});

const whatsappTemplateModal = ref<InstanceType<typeof SendWhatsappTemplateModal> | null>(null);

const handleTemplateClick = () => {
  whatsappTemplateModal.value?.openWhatsModal();
  handleEventWriting();
};

// Quick Answers Logic
const openQuickAnswersModal = ref(false);

const handleAddQuickAnswer = (answer: QuickAnswer) => {
  // fileMetaData.file = answer.file_url;
  // fileMetaData.inputType = answer.type_file;
  const fileTempData = {
    file: answer.file_url,
    inputType: answer.type_file,
  };
  filesMetaData.push(fileTempData);
  inputRef.value?.addCharToInputContent(answer.message);
};

// Submit Logic
const loading = ref<boolean>(false);
const inputMessage = ref<string>("");

const getMessageType = (payload: { message: string }, file?: FileMetadata) => {
  if (inputRef.value?.getRecordMetadata()?.recordingFile) return MessageType.Audio;

  // const fileType = fileMetaData.inputType;
  const fileType = file ? file.inputType : filesMetaData?.[0]?.inputType;

  if (payload.message && !fileType) return MessageType.Text;
  if (fileType && !payload.message) {
    if (fileType === "image") return MessageType.Image;
    if (fileType === "video") return MessageType.Video;
    if (fileType === "file") return MessageType.File;
  }

  if (fileType === "image") return MessageType.TextWithImage;
  if (fileType === "video") return MessageType.TextWithVideo;
  if (fileType === "file") return MessageType.TextWithFile;
};

const handleSubmit = async () => {
  if (loading.value) return;

  loading.value = true;

  try {
    const ticket = route.query.ticket;
    const interaction = route.query.interaction;

    const audioFile = inputRef.value?.getRecordMetadata()?.recordingFile;
    const fileToSent = audioFile || fileMetaData.file || filesMetaData?.[0]?.file;

    const basePayload = {
      message: inputMessage.value,
      interaction,
      ticket,
    };

    const fileKey = typeof fileToSent === "string" ? "file_url" : "file";
    const messageType = getMessageType(basePayload);

    console.log({ file: fileToSent, type: typeof fileToSent });

    await sendWorkspaceMessage({ ...basePayload, [fileKey]: fileToSent, type: messageType });

    if (filesMetaData.length > 1) {
      basePayload.message = "";
      for (let i = 1; i < filesMetaData.length; i++) {
        const fileToSent = filesMetaData?.[i]?.file;
        const fileKey = typeof fileToSent === "string" ? "file_url" : "file";
        const messageType = getMessageType(basePayload, filesMetaData[i]);
        await sendWorkspaceMessage({ ...basePayload, [fileKey]: fileToSent, type: messageType });
      }
    }

    console.log(messageType, "messagetype");

    inputRef.value?.handleDeleteMessage(MessageType.Audio == messageType);
    handleDeleteFile();
    chatStorage.deleteInteractionInputData(interactionId.value);

    emit("sended");
  } catch (error) {
    console.error(error);
    console.log("error response", error.response.data.phone_number);
    if (error.response.status === 400) {
      // const fullErrorMessage = error.error.response.data;
      // const errorMessage = fullErrorMessage.replace(/^[^:]+:\s*/, "");
      message.error(error.response.data.phone_number);
    }
  }

  loading.value = false;
};

const dataUserState = computed(() => store.getters["getDataUserState"]);
const registerEvent = (event: number) => {
  if (
    !(
      event == EventManagement.Writing &&
      store.getters["getEventMessaging"] == EventManagement.Writing
    )
  ) {
    store.dispatch("setEventMessaging", event);
    const ticket = route.query.ticket;
    registerEventManagement(event, {
      open_ticket: ticket,
      menu: 23,
      state: dataUserState.value,
      interaction_id: event == EventManagement.Writing ? route.query.interaction : undefined,
    });
  }
};

const chatStorage = useChatStorage();
const interactionId = ref<number>();

watch(
  () => channel.value?.id,
  async (newInteraction) => {
    if (!newInteraction) return;

    interactionId.value = newInteraction;

    await nextTick();

    if (props.disabled) return;
    if (!interactionId.value || !inputRef.value) return;

    const inputData = chatStorage.getInteractionInputData(interactionId.value);

    if (inputData?.message) inputRef.value.addCharToInputContent(inputData.message);
    if (inputData?.file) Object.assign(fileMetaData, inputData.file);
    if (inputData?.record) inputRef.value.setRecordMetadata(inputData.record);
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  if (!interactionId.value) return;

  chatStorage.saveInteractionInputData(interactionId.value, {
    message: inputMessage.value,
    file: fileMetaData,
    record: inputRef.value?.getRecordMetadata(),
  });
});
</script>

<style lang="sass" scoped>
.not-allowed-cursor
  cursor: not-allowed

.no-events
  pointer-events: none
  opacity: 60%

.section
  padding: 12px 16px
  border-top: 1px solid $gray-5

.actions
  display: flex
  align-items: center
  gap: 12px
  margin-top: 8px
  max-height: 24px

#file-input, #media-input
  display: none
</style>

<style>
.emoji-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.v3-emoji-picker {
  box-shadow: none !important;
}

.v3-emoji-picker.v3-color-theme-light .v3-footer {
  display: none;
}
</style>
