import httpManager from "@/app/shared/utils/httpManager";
/**
 * Asignar agentes a una estrategia.
 *
 * @param {number} strategyId Identificador de estrategia.
 * @param {Array} users Listado de identificadores de agentes.
 *
 * @return {Promise<Object>} Estrategia con los usuarios editados.
 */

export async function assignDataToStrategy(strategyId, type, data) {
  const url = `/strategies/${strategyId}/`;

  let response = "";
  if (type === "assignAgents") {
    response = await httpManager.put(url, {
      users: data,
    });
  } else if (type === "assignTime") {
    response = await httpManager.put(url, data);
  }

  return response.data;
}
