import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener un canal
 *
 * @param {Object} channelId Id del canal a obtener
 *
 * @return {Promise<Object>} Datos del canal solicitado
 */
export default async function (channelId) {
  const response = await httpManager.get(`/channels/channel-customer/${channelId}`);
  return response?.data;
}
