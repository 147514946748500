<template>
  <a-row
    class="card"
    :class="isNew && bgColorNew ? `card--bg-${bgColorNew}` : `card--bg-${bgColor}`"
    align="middle"
  >
    <img
      :alt="`imagen de tipo ${download.extension}`"
      :src="getFileImage(download.extension)"
      class="card__image"
    />
    <div>
      <a-typography-text class="card__title" strong :delete="isCanceled">{{
        download.name
      }}</a-typography-text>
      <span v-if="numberRegisters"> - {{ download.number_registers }} registros</span>
      <p class="card__description">
        {{ date }} |
        <template v-if="download.menu"> Módulo: {{ download.menu.name }} </template>
      </p>
    </div>
    <div style="flex: 1 1 0%"></div>
    <!--Acciones-->
    <a-space :size="8">
      <a-button
        v-if="showActionDownload"
        type="link"
        :disabled="!download.is_file_available"
        @click="handleDownload"
        >Descargar
      </a-button>
      <a-button v-else-if="showActionCancel" type="link" @click="handleShowConfirmToCancel"
        >Cancelar descarga</a-button
      >
      <a-button v-else-if="showActionDelete" type="link" @click="handleShowConfirmToDelete"
        >Eliminar registro</a-button
      >

      <a-tooltip :title="tooltipStatus">
        <a-tag class="card__tag" :color="tagDownload.color"
          >{{ tagDownload.title }}
          <component v-if="iconStatus" :is="iconStatus" />
        </a-tag>
      </a-tooltip>
    </a-space>
  </a-row>
</template>

<script setup lang="ts">
import { computed, inject, createVNode } from "vue";
import {
  CardDownloadItem,
  FunctionsDownload,
  DownloadStatusNumber,
} from "@/@types/settingsMenu/downloads";
import { dateTimeToSlashFormat, getDifferenceBetweenTwoTimes } from "@/app/shared/utils/dates";
import { STATUS_DOWNLOAD } from "@/app/settingsMenu/utils/downloads.ts";
import {
  CheckCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
} from "@ant-design/icons-vue";
import { fetchDownload, deleteDownload, cancelDownload } from "@/app/settingsMenu/services";
import { downloadFile } from "@/app/shared/utils/file";
import { Modal, message } from "ant-design-vue";

const props = defineProps<{
  download: CardDownloadItem;
}>();

const provideFunctions = inject("functionsDownload") as FunctionsDownload;

const getFileImage = (extension: string) => {
  return new URL(`../../assets/file-${extension}.svg`, import.meta.url).href;
};

const date = computed(() => {
  return dateTimeToSlashFormat(props.download.created_at);
});
const stateDownload = computed(() => {
  return STATUS_DOWNLOAD[props.download.state];
});
const isCanceled = computed(() => {
  return stateDownload.value.is_canceled;
});

// ACCIONES DE LA DESCARGA
const showActionDownload = computed(() => {
  return stateDownload.value.actions.includes("download");
});
const showActionCancel = computed(() => {
  return stateDownload.value.actions.includes("cancel");
});
const showActionDelete = computed(() => {
  return stateDownload.value.actions.includes("delete");
});

// ETIQUETA DE LA DESCARGA
const tagDownload = computed(() => {
  return stateDownload.value.tag;
});

// ICONO DE ESTADO PARA LA ETIQUETA
const iconStatus = computed(() => {
  switch (tagDownload.value.icon) {
    case "check-circle":
      return CheckCircleOutlined;
    case "download":
      return DownloadOutlined;
    case "loading":
      return LoadingOutlined;
    case "stop":
      return StopOutlined;
    default:
      return null;
  }
});

const bgColorNew = computed(() => {
  return STATUS_DOWNLOAD[props.download.state]?.bg_color_new || null;
});

const bgColor = computed(() => {
  return STATUS_DOWNLOAD[props.download.state]?.bg_color || null;
});

const numberRegisters = computed(() => {
  return props.download.number_registers || 0;
});

const isAvailable = computed(() => {
  return props.download.is_file_available || false;
});

const tooltipStatus = computed(() => {
  if (props.download.state === DownloadStatusNumber.Finished)
    return isAvailable.value
      ? STATUS_DOWNLOAD[props.download.state]?.tooltip
      : STATUS_DOWNLOAD[props.download.state]?.tooltip_disabled;
  else return STATUS_DOWNLOAD[props.download.state]?.tooltip;
});

const isNew = computed(() => {
  if (!props.download.updated_at) return false;
  const isFinished = stateDownload.value.status === "finished";
  if (!isFinished) return false;
  const range = 5;
  // si la fecha de actualizacion está en el rango
  const dateNow = new Date();
  const difference = getDifferenceBetweenTwoTimes(dateNow, props.download.updated_at);
  return difference <= range;
});

// FUNCIONES
/**
 * Descarga el archivo completo
 */
const handleDownload = async () => {
  if (!isAvailable.value) return;
  const key = "download-mesage";
  message.loading({ key, content: "Descargando archivo..." });
  const response = await fetchDownload({ proccessId: props.download.id });
  downloadFile(response, `${props.download.name}.${props.download.extension}`, {
    useResponseFileName: true,
  });
  message.success({ key, content: "Archivo descargado" });
};
/**
 * Muestra una confirmacion antes de eliminar la descarga
 */
const handleShowConfirmToDelete = () => {
  Modal.confirm({
    title: "¿Estás seguro de eliminar esta descarga?",
    icon: createVNode(ExclamationCircleOutlined),
    content: "Recuerda que desaparecerá de tu listado y ya no podrás visualizarlo o descargarlo",
    async onOk() {
      return await handleDelete();
    },
    centered: true,
    okType: "danger",
    okText: "Eliminar registro",
    cancelText: "Cancelar",
  });
};
/**
 * Muestra una confirmacion antes de cancelar la descarga
 */
const handleShowConfirmToCancel = () => {
  Modal.confirm({
    title: "¿Estás seguro de cancelar esta descarga?",
    icon: createVNode(ExclamationCircleOutlined),
    content: "Podrás volver a intentar descargar este archivo más adelante. ",
    async onOk() {
      return await handleCancel();
    },
    centered: true,
    okText: "Sí, cancelar descarga",
    cancelText: "No, seguir esperando",
  });
};
/**
 * Elimina una descarga
 */
const handleDelete = async () => {
  const key = "message-delete";
  message.loading({ key, content: "Eliminando descarga..." });
  const response = await deleteDownload({ proccessId: props.download.id });
  console.log(response);
  message.success({ key, content: "Descarga eliminada" });
  provideFunctions.onDelete({
    proccessId: props.download.id,
  });
};
/**
 * Cancela una descarga
 */
const handleCancel = async () => {
  const key = "message-cancel";
  message.loading({ key, content: "Cancelando descarga..." });
  const response = await cancelDownload({ proccessId: props.download.id });
  console.log(response);
  message.success({ key, content: "Descarga cancelada" });
  provideFunctions.onUpdate({
    proccessId: props.download.id,
    state: DownloadStatusNumber.Canceled,
  });
};
</script>

<style lang="sass" scoped>
.card
  border-radius: 8px
  border: 1px solid $gray-5
  height: 96px
  padding: 24px 20px
  &__image
    margin-right: 20px
  &__title
    margin-bottom: 4px
  &__description
    margin-bottom: 0px
  &__tag
    width: 124px
    text-align: center
  &--bg
    &-green
      background-color: $green-1
      -webkit-animation: blink 1.33s ease-out 3s
      -moz-animation: blink 1.33s ease-out 3s
      animation: blink 1.33s ease-out 3s
    &-red
      background-color: $red-1
    &-orange
      background-color: $orange-1

@keyframes blink
  0%
    opacity: 1
  20%
    opacity: .3
  100%
    opacity: 1
</style>
