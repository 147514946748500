export const groupNames = {
  smileys_people: "Caras y personas",
  animals_nature: "Animales y naturaleza",
  food_drink: "Alimentos y bebidas",
  activities: "Actividades",
  travel_places: "Viajes y lugares",
  objects: "Objetos",
  symbols: "Símbolos",
  flags: "Banderas",
};
