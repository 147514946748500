import { fetchAllMenus } from "@/app/users/services";

import { SET_MENUS } from "./mutation-types";

const actions = {
  async fetchAllMenus({ commit }, roleId) {
    const menus = await fetchAllMenus(roleId);
    commit(SET_MENUS, menus);
  },
};

export default actions;
