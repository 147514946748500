import {
  ConditionedAttribute,
  LogicalType,
  ActionType,
  ConditionType,
} from "@/@types/entities/forms";
import { Evaluator } from "@/app/entities/utils/forms/conditionMaps/evaluationFunctions";

export const LogicPerType: Record<LogicalType, (list: boolean[]) => boolean> = {
  [LogicalType.Anyone]: (list: boolean[]) => list.some((result) => result),
  [LogicalType.Everyone]: (list: boolean[]) => list.every((result) => result),
};

export const AttributePerAction: Record<ActionType, (condition: boolean) => ConditionedAttribute> =
  {
    [ActionType.Show]: (condition: boolean) => ({ show: condition }),
    [ActionType.Hide]: (condition: boolean) => ({ show: !condition }),
    [ActionType.Require]: (condition: boolean) => ({ required: condition }),
    [ActionType.NoRequire]: (condition: boolean) => ({ required: !condition }),
    [ActionType.Enable]: (condition: boolean) => ({ disabled: !condition }),
    [ActionType.Disable]: (condition: boolean) => ({ disabled: condition }),
  };

export const EvaluationPerConditionType: Record<
  ConditionType,
  (inputValue: string, comparisonValue?: string) => boolean
> = {
  [ConditionType.Contains]: (inputValue: string, comparisonValue?: string) =>
    Evaluator.contains(inputValue, comparisonValue),

  [ConditionType.NoContains]: (inputValue: string, comparisonValue?: string) =>
    !Evaluator.contains(inputValue, comparisonValue),

  [ConditionType.BeginsWith]: (inputValue: string, comparisonValue?: string) =>
    Evaluator.beginsWith(inputValue, comparisonValue),

  [ConditionType.NoBeginsWith]: (inputValue: string, comparisonValue?: string) =>
    !Evaluator.beginsWith(inputValue, comparisonValue),

  [ConditionType.EndsWith]: (inputValue: string, comparisonValue?: string) =>
    Evaluator.endsWith(inputValue, comparisonValue),

  [ConditionType.NoEndsWith]: (inputValue: string, comparisonValue?: string) =>
    !Evaluator.endsWith(inputValue, comparisonValue),

  [ConditionType.IsEmpty]: (inputValue: string) => Evaluator.isEmpty(inputValue),

  [ConditionType.NoEmpty]: (inputValue: string) => !Evaluator.isEmpty(inputValue),

  [ConditionType.IsEqual]: (inputValue: string, comparisonValue?: string) =>
    Evaluator.isEqual(inputValue, comparisonValue),

  [ConditionType.NoEqual]: (inputValue: string, comparisonValue?: string) =>
    !Evaluator.isEqual(inputValue, comparisonValue),

  [ConditionType.IsBefore]: (inputValue: string | string[], comparisonValue?: string) =>
    Evaluator.isBefore(inputValue, comparisonValue),

  [ConditionType.IsAfter]: (inputValue: string | string[], comparisonValue?: string) =>
    Evaluator.isAfter(inputValue, comparisonValue),
};
