import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener Plantillas de WhatsApp.
 *
 * @return {Promise<Array>} Lista de colas.
 */
export async function fetchTemplates(queue, channelCustomerId, withoutParameters) {
  let url = `/channels/whatsapp-templates/queues/${queue}/?channel_customer=${
    channelCustomerId ?? ""
  }`;

  if (withoutParameters) {
    url += `&with_parameters=False&template_type=TEXT`;
  }

  const response = await httpManager.get(url);
  return response?.data;
}
