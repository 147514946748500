<template>
  <a-modal
    :open="open"
    title="Desuscribir nuevo correo"
    @cancel="handleCancel"
    width="550px"
    class="settings__block-modal"
  >
    <div class="information">
      <p>
        Completa los datos requeridos. Recuerda que estos correos ya no recibirán correos
        provenientes de estrategias masivas.
      </p>
      <a-form
        class="form form__appeal"
        layout="vertical"
        ref="formRef"
        :model="formState"
        :rules="rules"
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="Correo electrónico" name="email">
              <a-input
                v-model:value="formState.email"
                placeholder="Escribir nombre de la plantilla"
                type="email"
              />
            </a-form-item>
            <a-form-item label="Descripción" name="description">
              <a-textarea
                v-model:value="formState.description"
                placeholder="Escribir aquí..."
                maxLength="500"
                show-count
              ></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <template #footer>
      <div class="footer">
        <div>
          <a-button @click="handleCancel">Cancelar</a-button>
        </div>
        <div>
          <a-button type="primary" @click="handleSubmit" :disabled="isSubmitDisabled"
            >Desuscribir</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script lang="ts" setup>
import { ref, reactive, computed } from "vue";
import { message } from "ant-design-vue";
import { emailService } from "@/app/emails/services/Email.service";
import EventBus from "@/app/shared/utils/eventBus";

defineProps<{ open: boolean }>();

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const formState = reactive({
  email: null,
  description: null,
});

const initialFormState = { ...formState };

const formRef = ref();

const rules = {
  email: [
    { required: true, message: "Campo requerido" },
    {
      type: "email",
      message: "Debe ser un correo electrónico válido",
      trigger: "blur",
    },
  ],
  description: [{ required: true, message: "Campo requerido" }],
};

const isSubmitDisabled = computed(() => {
  return !formState.email || !formState.description;
});

const handleCancel = (e: MouseEvent) => {
  console.log(e);
  emit("update:open", false);
  resetForm();
};

const handleSubmit = async () => {
  try {
    await formRef.value.validateFields();
    await emailService.unsubscribeEmail(formState);
    emit("update:open", false);
    message.success("El correo fue desuscrito con éxito");
    EventBus.emit("updateUnsubscribeTable");
    resetForm();
  } catch (error) {
    console.log(error);
    message.error("El correo ingresado ya se encuentra desuscrito");
  }
};

const resetForm = () => {
  Object.assign(formState, initialFormState);
};
</script>
<style lang="sass" scoped>
.settings__block-modal
  .information
    padding: 20px
  .footer
    display: flex
    justify-content: flex-end
    gap: 10px
</style>
<style>
.settings__block-modal .information .campaign label {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: baseline;
}
</style>
