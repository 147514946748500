<template>
  <img v-if="floating" class="avatar-wave" :src="waveImage" alt="background wave" />

  <div class="avatar" :class="{ 'avatar--active': active, 'avatar-border': floating }">
    <img v-if="photo" :src="photo" class="avatar__photo" alt="photo" />
    <div v-else class="avatar__icon">
      {{ text }}
    </div>
  </div>
</template>

<script>
import waveImage from "@/packages/webphone/assets/avatar-wave.svg?url";

export default {
  props: {
    name: { required: true, type: String },
    photo: { type: String, default: null },
    active: { type: Boolean, default: false },
    floating: { type: Boolean, default: false },
  },
  computed: {
    text() {
      const words = this.name.split(" ");
      const firstLetter = words[0][0];
      if (firstLetter == undefined) {
        return "NI";
      }
      const secondLetter = words.length > 1 ? words[1][0] : "";
      if (secondLetter == undefined) {
        return "NI";
      }
      return `${firstLetter}${secondLetter}`.toUpperCase();
    },
  },
  setup() {
    return { waveImage };
  },
};
</script>

<style lang="sass" scoped>
@import '../styles/main'

.avatar
  width: 64px
  height: 64px
  color: $white
  user-select: none
  position: relative
  z-index: 2

  &__photo
    display: block
    width: 100%
    height: 100%
    border-radius: 50%
    position: relative
    z-index: 1

  &__icon
    font-size: 24px
    font-weight: 600
    border-radius: 50%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    background-color: #E55549
    position: relative
    z-index: 1

  &--active::before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    transform: scale(3.8)
    background: radial-gradient(50% 50% at 50% 50%, rgba(29, 24, 255, 0.34) 0%, rgba(29, 24, 255, 0.12) 50%, rgba(29, 24, 255, 0.06) 70%, transparent 100%)

  &--active::after
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    transform: scale(1.32)
    border-radius: 50%
    border: 3px solid white

  &-border
    border: 3px solid #5273E0
    border-radius: 50%
    padding: 2px
    background-color: #F0F5FF

  &-wave
    position: absolute
    top: 0
    z-index: 0
    width: 100%
    // height: calc(50% - 15px)
</style>
