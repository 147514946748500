import {
  fetchAllImports,
  importClientProductsTemplate,
  importMasiveProcessTemplate,
} from "@/app/imports/services";
import { ImportAssignmentType } from "@/app/imports/utils/enums";

import { SET_IMPORTS } from "./mutation-types";

const actions = {
  async fetchAllImports({ commit }, filters) {
    const entity = filters?.entity;
    const portfolio = filters?.portfolio;
    const entities = await fetchAllImports({ entity, portfolio });
    commit(SET_IMPORTS, entities);
  },
  async importClientProductsTemplate({ dispatch }, data) {
    const body = { ...data };

    if (body.type_assignment === ImportAssignmentType.AddNewByAssignment)
      body.type_assignment = ImportAssignmentType.ByAssignment;

    await importClientProductsTemplate(body);
    dispatch("fetchAllImports");
  },
  async importMasiveProcessTemplate({ dispatch }, data) {
    await importMasiveProcessTemplate(data);
    dispatch("fetchAllImports");
  },
};

export default actions;
