import { toFormData } from "@/app/shared/utils/helpers";
import httpManager from "@/app/shared/utils/httpManager";

/**
 * Enviar mensaje a workspace
 * @param {number} ticketId Identificador del ticket.
 * @param {number} channelId Identificador del channel_person.
 * @param {string} message Contentido del mensaje
 * @return {Promise<Object>}
 */
export async function sendWorkspaceMessage(payload) {
  const url = `/replies/send-reply/`;
  const body = {
    type_message: payload.type,
    ticket: Number(payload.ticket),
    interaction: Number(payload.interaction),
    message: payload.message,
  };
  if (payload.file) body.file = payload.file;
  if (payload.file_url) body.file_url = payload.file_url;

  const formData = toFormData(body);
  const response = await httpManager.upload(url, formData);
  return response.data;
}
