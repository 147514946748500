import { FormTypes } from "@/app/shared/utils/enums";

export const ContactDetailsTabs = {
  Product: 1,
  Personal: 2,
  Ticket: 3,
  Email: 4,
};

export const PhoneStatus = {
  Off: 1,
  Ringing: 2,
  Waiting: 3,
  InCall: 4,
};

export const CallStatus = {
  None: "none",
  Connecting: "connecting",
  Progress: "progress",
  Finished: "finished",
  NoAnwser: "no-anwser",
  Transfered: "transfered",
};

export const TypeDocument = {
  Dni: "1",
  ForeignCard: "2",
  Pasaporte: "3",
  Ruc: "4",
};

export const PaymentTypes = {
  2: "PDP",
  3: "PDP",
  4: "Pago",
};

export const FormsTypes = {
  [FormTypes.Reminder]: "Recordatorio",
  [FormTypes.PayPromiseReminder]: "Promesa de Pago (PDP) y Recordatorio",
  [FormTypes.PayPromise]: "Promesa de Pago (PDP)",
  [FormTypes.Pay]: "Pagos",
  [FormTypes.Sell]: "Ventas",
  [FormTypes.ATC]: "ATC",
};

export const SentIvrOptions = {
  Yes: "sent",
  No: "not-sent",
};

const ChatStatus = {
  Pending: 1,
  Sent: 2,
  Delivered: 3,
  Read: 4,
  Failed: 5,
};

export const ChatStatusPicture = {
  [ChatStatus.Pending]: "pending",
  [ChatStatus.Sent]: "sent",
  [ChatStatus.Delivered]: "delivered",
  [ChatStatus.Read]: "read",
  [ChatStatus.Failed]: "failed",
};

export const TypeBilling = {
  PrePayment: 1,
  PostPayment: 2,
};