import httpManager from "@/app/shared/utils/httpManager";
/**
 * Tranferir ticket a cola.
 *
 *  @param {Object} body Los tickets seleccionados y la cola a la que se le van asignar.
 *
 * @return {Promise<Array>}
 */
export async function tranferTicketQueues(body) {
  const url = `/tickets/transfer-to-queue/`;
  const response = await httpManager.post(url, { ...body, origin: 1 }); // Origin 1 is for workspace
  return response?.data;
}
