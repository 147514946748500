export const columns = [
  {
    title: "Colas",
    dataIndex: "queue_name",
    width: 300,
    ellipsis: true,
    slots: { customRender: "queue_name" },
  },
  {
    title: "Total de chats",
    dataIndex: "total_interactions",
  },
  {
    title: "N° NS",
    dataIndex: "sum_service_level",
    width: 140,
  },
  {
    title: "NS%",
    dataIndex: "mean_service_level",
    width: 140,
    slots: { customRender: "mean_service_level" },
  },
  {
    title: "TP espera",
    dataIndex: "mean_time_waiting",
    slots: { customRender: "time" },
  },
  {
    title: "TP primera respuesta",
    dataIndex: "mean_time_first_answer",
    slots: { customRender: "time" },
  },
  {
    title: "TP resolución",
    dataIndex: "mean_time_resolution",
    slots: { customRender: "time" },
  },
  {
    title: "N° asignados",
    dataIndex: "total_assigned",
  },
  {
    title: "Acciones",
    fixed: "right",
    width: 120,
    slots: { customRender: "actions" },
  },
];
