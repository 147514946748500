import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una entidad.
 *
 * @param {number} entityId Iedntificador de la entidad.
 * @param {FormData} entity Datos modificados de la entidad.
 *
 * @return {Promise<Object>} Entidad editada.
 */
export default async function (entityId, entity) {
  const response = await httpManager.uploadPut(`/entities/${entityId}/`, entity);
  return response?.data;
}
