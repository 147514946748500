<template>
  <div class="container">
    <CustomScrollbar height="480px">
      <div class="title">
        <h2>Datos generales de tu troncal</h2>
        <span>Completa los campos requeridos</span>
      </div>
      <a-form
        autocomplete="chrome-off"
        class="form"
        layout="vertical"
        ref="formRef"
        :rules="rules"
        :model="formState"
      >
        <!-- <div class="host">
          <span class="hostModel">Host: 10.0.0.0 a 10.255.255.255</span>
        </div> -->
        <a-form-item label="Nombre de la troncal" name="name">
          <a-input
            v-model:value="formState.name"
            placeholder="Escribir nombre de troncal"
            @input="handleInput"
          ></a-input>
        </a-form-item>

        <a-form-item name="host_server">
          <template #label>
            Host

            <a-tooltip title="Dirección o dominio para registrar la troncal con su servicio.">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-input
            v-model:value="formState.host_server"
            placeholder="IP(111.0.202.21) o Dominio(pbx.voip.com)"
            @input="handleInput"
          ></a-input>
        </a-form-item>

        <!-- <a-row :gutter="24">
          <a-col span="12">
            <a-form-item name="port">
              <template #label>
                Port

                <a-tooltip title="Puerto de señalización brindado por su proveedor. ">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input v-model:value="formState.port" placeholder="5060" @input="handleInput">
              </a-input>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item name="context">
              <template #label>
                Context

                <a-tooltip title="Indica dónde se recibirán las llamadas entrantes.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input
                v-model:value="formState.context"
                placeholder="Escribir context"
                @input="handleInput"
                required
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- <a-row :gutter="24">
          <a-col span="12">
            <a-form-item name="type_trunk">
              <template #label>
                Type

                <a-tooltip title="Tipo de troncal">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-select
:getPopupContainer="triggerNode => triggerNode.parentNode"                placeholder="Type"
                @change="handleInput"
                v-model:value="formState.type_trunk"
              >
                <a-select-option :key="1">User</a-select-option>
                <a-select-option :key="2">Peer</a-select-option>
                <a-select-option :key="3">Friend</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item name="dtmf_mode">
              <template #label>
                DTMF Mode

                <a-tooltip title="Modo en que se reconocerán las opciones marcadas.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-select
:getPopupContainer="triggerNode => triggerNode.parentNode"                placeholder="DTMF Mode"
                @change="handleInput"
                v-model:value="formState.dtmf_mode"
              >
                <a-select-option :key="1">rfc2833</a-select-option>
                <a-select-option :key="2">Inband</a-select-option>
                <a-select-option :key="3">info</a-select-option>
                <a-select-option :key="4">auto</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- <a-form-item name="qualify">
          <template #label>
            Qualify

            <a-tooltip title="Valida el estado de la troncal">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-radio-group v-model:value="formState.qualify" @change="handleInput">
            <a-radio :value="true">Si</a-radio>
            <a-radio :value="false">No</a-radio>
          </a-radio-group>
        </a-form-item> -->

        <a-form-item name="use_incoming_calls">
          <template #label>
            Uso de troncal para llamadas entrantes

            <a-tooltip title="Valida el estado de la troncal">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-radio-group v-model:value="formState.use_incoming_calls" @change="handleInput">
            <a-radio :value="true">Si</a-radio>
            <a-radio :value="false">No</a-radio>
          </a-radio-group>
        </a-form-item>

        <!-- <a-form-item class="form-items" name="codecs">
          <template #label>
            Codecs

            <a-tooltip
              title="Codificadores y decodificadores de audio brindados por su proveedor. "
            >
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-checkbox-group v-model:value="formState.codecs" @change="handleInput">
            <a-row>
              <a-col v-for="codec in codecs" :key="codec.id" :span="6" class="checkbox-codecs">
                <a-checkbox :value="codec.id">{{ codec.name }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item> -->

        <!-- <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Usuario de troncal (opcional)" name="username">
              <a-input
                v-model:value="formState.username"
                placeholder="Escribir nombre de usuario"
                @input="handleInput"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col span="12"
            ><a-form-item label="Contraseña de troncal (opcional)" name="password">
              <a-input-password
                autocomplete="new-password"
                v-model:value="formState.password"
                placeholder="Escribir contraseña de usuario"
                @input="handleInput"
              ></a-input-password> </a-form-item
          ></a-col>
        </a-row> -->

        <a-form-item name="observations">
          <template #label>
            <span>Observaciones <span class="optional"> (opcional)</span></span>
          </template>
          <a-textarea
            v-model:value="formState.observations"
            placeholder="Escribir observación"
            :maxlength="maxObservationName"
            class="textarea"
            @input="handleChangeName"
          />
          <span class="countername"> {{ name ? name.length : 0 }}/{{ maxObservationName }} </span>
        </a-form-item>

        <!-- <hr /> -->
        <!-- <div>
          <p>
            Precios destino (opcional)
            <a-tooltip
              title="En caso tu proveedor establezca diferentes precios según la zona de la llamada, puedes anotarlos de forma opcional."
            >
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </p>
        </div>

        <div v-for="(price, index) in prices" :key="index">
          <a-row :gutter="20">
            <a-col span="10">
              <a-form-item label="Nombre del destino" class="form-items">
                <a-input
                  v-model:value="price.name"
                  placeholder="Escribir destino"
                  @input="handleInput"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col span="2">
              <RightOutlined class="icon-menor" />
            </a-col>
            <a-col span="10">
              <a-form-item label="Monto" class="form-items">
                <a-input-number
                  :step="0.1"
                  v-model:value="price.value"
                  class="labelHalf"
                  placeholder="00.00"
                  :min="0"
                  @input="handleInput"
                ></a-input-number>
              </a-form-item>
            </a-col>
          </a-row>

          <div v-if="index > 0" span="2" style="text-align: right">
            <a-button type="text" danger size="small" @click="popPrice(index)">
              Eliminar sección
            </a-button>
          </div>

          <a-divider style="margin: 16px 0 20px" />
        </div>

        <div class="add-price">
          <span @click="handleAddPrice">
            <PlusOutlined />
            Agregar precio destino
          </span>
        </div> -->

        <div class="info">
          <InfoCircleOutlined style="margin-top: 4px" />

          <p>
            Recuerda que, para realizar llamadas salientes debes asociar un plan de marcación a esta
            troncal. Puedes crearla desde el módulo de Plan de marcación.
          </p>
        </div>
      </a-form>
    </CustomScrollbar>
    <div class="footer">
      <a-button class="footer__back" type="primary" ghost @click="handleCancel">
        Cancelar
      </a-button>
      <a-button class="footer__next" type="primary" @click="handleOk">
        <!-- {{ update ? "Guardar cambios" : "Integrar" }} -->
        {{ "Continuar" }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, toRaw, createVNode } from "vue";
import { useStore } from "vuex";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

import { fetchSearchHost } from "@/app/pbx/services";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    InfoCircleOutlined,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["formState"],
  setup() {
    const formRef = ref();
    const store = useStore();
    const trunk = store.getters["getTrunk"];
    // const formState = reactive({
    //   name: props.update ? trunk.name : "",
    //   observations: props.update ? trunk.observations : "",
    //   use_incoming_calls: props.update ? trunk.use_incoming_calls : true,
    //   host_server: props.update ? trunk.host_server : "",
    // });

    const rules = {
      name: [
        {
          pattern: /^[0-9a-zA-Z]+$/,
          message: "Solo se permiten letras y números",
        },
        { required: true, message: "Campo requerido", trigger: "blur" },
      ],
      observations: [],
      host_server: [
        {
          pattern:
            /^(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(?:(?:[a-zA-Z\u00A1-\uFFFF0-9-]+\.)+[a-zA-Z\u00A1-\uFFFF]{2,}))$/,
          message: "Ingresar formato correcto",
        },
        { required: true, message: "Campo requerido" },
      ],
      port: [
        { max: 5, message: "Ingresar maximo 5" },
        {
          pattern: /^[0-9]+$/,
          message: "No es un puerto válido",
        },
        { required: true, message: "Campo requerido" },
      ],
      use_incoming_calls: [{ required: true, message: "Completar los campos " }],
    };
    // const prices = reactive([
    //   {
    //     name: "",
    //     value: 0.0,
    //   },
    // ]);
    // if (props.update && trunk?.prices?.length > 0) {
    //   prices.length = 0;
    //   trunk.prices.forEach((price) => {
    //     prices.push({
    //       name: price.name,
    //       value: price.price,
    //     });
    //   });
    // }

    return {
      formRef,
      rules,
      store,
      trunk,
      // prices,
    };
  },
  data() {
    return {
      menor: ">",
      name: "",
      maxObservationName: 255, // Máxima cantidad de caracteres en el campo de "Observacion"
      moneyPattern: /^([0-9]+[.]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/,
    };
  },
  computed: {
    codecs() {
      return this.$store.getters["getCodecList"];
    },
  },
  methods: {
    // This is used by refs in the parent component
    resetForm() {
      this.formRef.resetFields();
    },
    // handleAddPrice() {
    //   const obj = {
    //     name: "",
    //     value: "",
    //   };
    //   this.prices.push(obj);
    // },
    showConfirm() {
      const self = this;
      this.$confirm({
        title: "¿Estás seguro de descartar la actualización de esta troncal?",
        content:
          "Recuerda que los cambios aplicados no serán guardados para la solcitud de actualización de esta troncal.",
        icon: createVNode(QuestionCircleOutlined),
        okText: "Descartar",
        calcelText: "Cancelar",
        onOk() {
          self.current = 0;
          self.$emit("handleCancel");
        },
        centered: true,
      });
    },
    handleCancel() {
      // if (this.update) {
      //   this.showConfirm();
      // } else this.$emit("handleCancel");
      this.$emit("handleCancel");
    },
    handleOk() {
      this.formRef.validate().then(() => {
        const bigArray = toRaw(this.formState);
        const obj = {
          ...bigArray,
          codecs_id: bigArray.codecs,
          // destination_prices: this.prices.map((price) => [price.name, price.value]),
        };
        // delete obj.codecs;
        console.log(this.formState);
        this.$store.dispatch("assignedTrunk", obj);
        this.$emit("handleOk");
      });
    },
    // popPrice() {
    //   this.prices.pop();
    // },
    handleInput() {
      this.$emit("edit");
    },
    handleChangeName(event) {
      this.name = event.target.value;
      this.$emit("edit");
    },
  },
  watch: {
    "formState.host_server": _.debounce(function (val) {
      const regex = this.rules.host_server?.[0]?.pattern;
      if (regex.test(val)) {
        fetchSearchHost(val).then((resp) => {
          this.formState.type_authentication = resp.trunk_type;
        });
      }
    }, 500),
  },
});
</script>

<style lang="sass" scoped>
.container
  height: 450px
  padding-top: 20px
.title
  text-align: center
  h2
    margin-bottom: 0.3em
  span
    font-size: 14px
.form
  display: block
  // max-width: 340px
  max-width: 95%
  margin: 0 auto
  margin-top: 20px
  // padding-right: 16px
  // padding-top: 16px
.form-items
  margin-bottom: 0px
.host
  margin-bottom: 20px
  .hostModel
    font-weight: 600
    font-size: $font-normal
    line-height: 22px
    color: $gray-9
.checkbox-codecs
  margin-bottom: 24px
.icon-menor
  margin-top: 8px
.add-price
  text-align: center
  color: $gray-9
  cursor: pointer
  span
    font-size: 14px
    // line-height: 20px
    color: $blue-6
.footer
  position: absolute
  background-color: $white
  left: 0
  bottom: 0
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  border-top: 1px solid $gray-5
  padding: 12px 0px
  border-radius: 0px 0px 4px 4px
  &__next
    margin-right: 1rem
  &__back
    margin-right: 1rem
.textarea
  height: 60px
.countername
  bottom: 8px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6

:deep(.ant-checkbox-wrapper)
  display: flex
  align-items: center

.labelHalf
  margin-bottom: 10px

.info
  display: flex
  color: $text-secondary
  align-items: flex-start
  gap: 5px
  font-style: italic
  margin-top: 0px
  font-size: 13px
.optional
  color: $gray-7
</style>
