<template>
  <label class="avatar-picker" :class="{ 'avatar-picker--error': error }">
    <div :class="['avatar-default', { 'avatar-empty': !avatarPreview }]">
      <div
        v-if="avatarPreview"
        class="avatar-default avatar-preview"
        :style="{ backgroundImage: `url(${avatarPreview})` }"
      ></div>
      <LoadingOutlined v-else-if="isLoading" style="font-size: 25px" />
      <EditOutlined v-else class="icon" />
    </div>

    <input type="file" @change="handleFileChange" :accept="allowedTypes.join(',')" />
  </label>
</template>

<script setup>
import { toRefs, ref, onMounted, watch } from "vue";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

import { uploadAvatar } from "@/app/channels/services";

const props = defineProps({
  avatarSrc: {
    type: String,
    default: "",
  },
});

const { avatarSrc } = toRefs(props);

const emit = defineEmits(["update:value"]);

const error = ref(false);

const maxSize = 5000000;
const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

const __validateType = (file) => {
  if (file.size > maxSize)
    return {
      error: "El tamaño máximo para tu avatar es de 5MB",
      type: "size",
    };

  if (!allowedTypes.includes(file.type))
    return {
      error: "El formato de la imagen no es compatible. Solo se admiten formatos JPG, JPEG o PNG.",
      type: "format",
    };

  return;
};

const avatarPreview = ref("");
const isLoading = ref(false);

const uploadFile = async (file) => {
  const previousAvatar = avatarPreview.value;
  try {
    isLoading.value = true;
    avatarPreview.value = null;

    const validationImg = __validateType(file);
    if (validationImg) throw validationImg;

    const resp = await uploadAvatar({ file });
    return resp.file;
  } catch (error) {
    console.error(error);
    avatarPreview.value = previousAvatar;

    if (error?.type) {
      message.error(error.error);
    } else {
      message.error("Hubo un error al cargar la imagen");
    }
  } finally {
    isLoading.value = false;
  }
};

const handleFileChange = async (event) => {
  const file = event.target.files[0];
  avatarPreview.value = await uploadFile(file);
  emit("update:value", avatarPreview.value);
};

onMounted(() => {
  avatarPreview.value = avatarSrc.value;
});

watch(avatarSrc, () => {
  avatarPreview.value = avatarSrc.value;
});
</script>

<style lang="sass" scoped>
.avatar-picker
  cursor: pointer

  &--error &__empty
    color: $red-6
    border: 1px dashed $red-6

.avatar-default
  width: 72px
  height: 72px
  margin: 0 auto
  border-radius: 50%
  position: relative

.avatar-empty
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  background-color: $gray-3
  border: 1px dashed $gray-6
  font-size: 12px
  color: $gray-7

.avatar-preview
  background-size: cover
  background-position: center

.avatar-empty,
.avatar-preview
  &:hover::after
    content: ''
    width: 100%
    height: 100%
    background-color: #0000004f
    background-image: url("../../../../../entities/assets/edit.svg")
    background-repeat: no-repeat
    background-position: center
    border-radius: 50%
    position: absolute
    left: 0
    right: 0
    top: 0

.icon
  font-size: 22px

label input[type="file"]
  display: none
</style>
