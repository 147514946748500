import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una base.
 *
 * @param {number} baseId Iedntificador de la base.
 * @param {FormData} entity Datos modificados de la base.
 *
 * @return {Promise<Object>} Base editada.
 */
export async function updateBase(baseId, base) {
  const response = await httpManager.put(`/entities/portfolios/bases/${baseId}/`, base);
  return response?.data;
}
