import httpManager from "@/app/shared/utils/httpManager";

class CustomerService {
  baseUrl = "/settings/customer";

  async getData() {
    const response = await httpManager.get(`${this.baseUrl}/`);

    return response.data;
  }

  async updateWorkspaceVisibility(isVisible: boolean): Promise<void> {
    const body = { is_visible: isVisible };

    await httpManager.put(`${this.baseUrl}/`, body);
  }
}

export const customerService = new CustomerService();
