import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { message } from "ant-design-vue";

export function useCopyToClipboard() {
  const handleCopy = async (text: undefined | null | string | number) => {
    const stringToCopy = String(text);

    try {
      await toClipboard(stringToCopy);
      message.success("Se copió correctamente");
    } catch (error) {
      message.error("No se pudo copiar");
    }
  };

  return handleCopy;
}
