import httpManager from "@/app/shared/utils/httpManager";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export function useValidateWorkspace() {
  const router = useRouter();
  const store = useStore();

  const workspaceData = ref<{ picture: string; name: string }>();

  let workspace = localStorage.getItem("workspace");
  const hostname = window.location.hostname;
  const inTenant = !(hostname.split(".").length <= 2 || hostname.split(".")[0] === "www");

  if (inTenant) {
    workspace = hostname.split(`.${import.meta.env.VITE_HOSTNAME}`)[0];
    localStorage.setItem("workspace", workspace);
  }

  httpManager.updateBaseURL(`https://${workspace}.backend.${import.meta.env.VITE_HOSTNAME}`);

  onMounted(async () => {
    try {
      if (!workspace) {
        router.push("/home");
      } else {
        const response = await store.dispatch("validateWorkspace", workspace);
        console.log("Workspace validado", response);
        workspaceData.value = response;
      }
    } catch {
      router.push("/not-found-workspace");
    }
  });

  return workspaceData;
}
