import common from "./common";
import auth from "./auth";
import register from "./register";
import shared from "./shared";
import users from "./users";
import contacts from "./contacts";
import pbx from "./pbx";
import entities from "./entities";
import queues from "./queues";
import dashboard from "./dashboard";
import channels from "./channels";
import imports from "./imports";
import schedule from "./schedule";
import supervision from "./supervision";
import workspace from "./workspace";
import strategies from "./strategies";
import reports from "./reports";
import settings from "./settings";
import settingsMenu from "./settingsMenu";
import platformSettings from "./platformSettings";
import ticketsManager from "./ticketsManager";
import notifications from "./notifications";
import crm from "./crm";
import chatbots from "./chatbots";
import ivr from "./ivr";
import reportsMenu from "./reportsMenu";
import flows from "./flows";
import emails from "./emails";
import emailsManager from "./emailsManager";
import manageVars from "./manageVars";
import onboarding from "./onboarding";
import integrations from "@/app/integrations";

const routes = [
  ...common.routes,
  ...auth.routes,
  ...register.routes,
  ...users.routes,
  // ...contacts.routes,
  ...pbx.routes,
  ...entities.routes,
  ...queues.routes,
  ...dashboard.routes,
  ...channels.routes,
  ...imports.routes,
  ...schedule.routes,
  ...supervision.routes,
  ...workspace.routes,
  ...strategies.routes,
  ...reports.routes,
  ...settings.routes,
  ...settingsMenu.routes,
  ...platformSettings.routes,
  // ...ticketsManager.routes,
  ...notifications.routes,
  ...crm.routes,
  ...chatbots.routes,
  ...ivr.routes,
  ...reportsMenu.routes,
  ...flows.routes,
  ...emails.routes,
  ...manageVars.routes,
  ...onboarding.routes,
  ...integrations.routes,
];
const store = {
  commonModule: common.store,
  registerModule: register.store,
  sharedModule: shared.store,
  authModule: auth.store,
  usersModule: users.store,
  contactsModule: contacts.store,
  pbxModule: pbx.store,
  entitiesModule: entities.store,
  queuesModule: queues.store,
  dashboardModule: dashboard.store,
  channelsModule: channels.store,
  importsModule: imports.store,
  scheduleModule: schedule.store,
  supervisionModule: supervision.store,
  workspaceModule: workspace.store,
  strategiesModule: strategies.store,
  reportsModule: reports.store,
  accountModule: settingsMenu.store,
  settingsModule: settings.store,
  platformSettingsModule: platformSettings.store,
  ticketsManagerModule: ticketsManager.store,
  // notificationsModule: notifications.store,
  chatbotsModule: chatbots.store,
  EmailsModule: emails.store,
  EmailsManagerModule: emailsManager.store,
  ivrModule: ivr.store,
};

export default {
  routes,
  store,
};
