<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.77521 7.17857H14.1044C14.3923 7.17857 14.625 6.93163 14.625 6.62737C14.625 6.32311 14.3923 6.07617 14.1044 6.07617L5.77521 6.07617C5.48733 6.07617 5.25464 6.32311 5.25464 6.62737C5.25464 6.93163 5.48733 7.17857 5.77521 7.17857Z"
      fill="black"
      fill-opacity="0.65"
    />
    <path
      d="M5.84827 9.3834H10.0129C10.3007 9.3834 10.5334 9.13646 10.5334 8.8322C10.5334 8.52794 10.3007 8.28101 10.0129 8.28101H5.84827C5.56039 8.28101 5.32769 8.52794 5.32769 8.8322C5.32769 9.13646 5.56039 9.3834 5.84827 9.3834Z"
      fill="black"
      fill-opacity="0.45"
    />
    <path
      d="M16.9037 17.3679C16.5462 17.3679 16.1888 17.0105 16.1888 17.0105C16.1888 17.0105 15.9745 16.789 15.8314 16.6531C15.4151 16.2575 14.693 15.7222 14.693 15.7222C14.0442 15.2233 13.4747 14.8688 12.7956 14.8659H9.39757H5.66597C4.53421 14.8638 3.76403 14.5823 2.96376 13.7936C2.16348 13.0049 1.66889 12.0543 1.66677 10.939L1.66675 6.42581C1.66887 5.31045 2.16348 4.2167 2.96376 3.42801C3.76403 2.63933 4.53421 2.50209 5.66597 2.5L14.2135 2.5C15.3453 2.50209 16.1034 2.63933 16.9037 3.42801C17.704 4.2167 18.2732 5.31045 18.2753 6.42581L18.3334 15.5808C18.3334 16.2956 18.3334 16.6531 17.976 17.0105C17.6185 17.3679 17.2611 17.3679 16.9037 17.3679ZM5.66597 3.89949C4.9861 3.89949 4.33407 4.16566 3.85333 4.63944C3.37258 5.11321 3.10251 5.75579 3.10251 6.42581L3.10251 10.9811C3.09933 11.3148 3.16329 11.6459 3.2907 11.9551C3.4181 12.2644 3.60642 12.5457 3.84478 12.7828C4.08314 13.0199 4.3668 13.2081 4.67939 13.3365C4.99198 13.465 5.3273 13.5311 5.66597 13.5311H12.7956C13.3566 13.5296 13.9124 13.6376 14.4307 13.849C14.9491 14.0604 15.4198 14.3709 15.8157 14.7627L16.777 15.71V6.42581C16.777 5.75579 16.5069 5.11321 16.0262 4.63944C15.5454 4.16566 14.8934 3.89949 14.2135 3.89949L5.66597 3.89949Z"
      fill="black"
      fill-opacity="0.45"
    />
  </svg>
</template>
