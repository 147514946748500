const getters = {
  getDialplanInfo(state) {
    return {
      items: state.dialplansInfo.items,
      pagination: state.dialplansInfo.pagination,
    };
  },
  getDialplanRules: (state) =>
    state.rules.map((item, index) => {
      return {
        index: index,
        ...item,
      };
    }),
  getPricesTrunk: (state) => state.pricesTrunk,
};
export default getters;
