<template>
  <div class="container">
    <p>
      Busca y descarga las grabaciones de tus llamadas.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer">Click aquí</a> para conocer más.
    </p>

    <records-controls @update="handleSetFilters" />

    <records-table @update="handleChangePage" />
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useStore } from "vuex";

import RecordsControls from "@/app/supervision/components/tables/RecordsControls.vue";
import RecordsTable from "@/app/supervision/components/tables/RecordsTable.vue";

const store = useStore();

const filtersState = reactive({
  filters: {},
  pagination: {},
});

const handleSetFilters = (filters) => {
  filtersState.filters = filters;
  fetchData();
};

const handleChangePage = (pagination) => {
  filtersState.pagination = pagination;
  fetchData();
};

const fetchData = async () => {
  await store.dispatch("fetchRecordList", filtersState);
};
const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/supervision/grabaciones/`;
</script>

<style lang="sass" scoped>
.container
  padding: 12px 20px
</style>
