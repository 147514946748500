<template>
  <section>
    <EditOutlined class="icon" @click="handleShowDrawer" />

    <a-drawer
      v-model:visible="visible"
      title="Editar email"
      destroy-on-close
      :mask-closable="false"
      placement="right"
      width="480"
      keyboard
      :body-style="{ padding: 0 }"
      @close="handleCloseDrawer"
    >
      <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
        <a-form
          class="form"
          layout="vertical"
          hide-required-mark
          ref="formRef"
          :model="formState"
          :rules="rules"
          @submit.prevent="handleSubmit"
        >
          <a-form-item label="Dirección" name="email">
            <a-input v-model:value="formState.email" placeholder="Escribe aquí" />
          </a-form-item>
          <a-form-item label="Origen" name="type_email">
            <a-input v-model:value="formState.type_email" placeholder="Escribe aquí" />
          </a-form-item>
        </a-form>
      </custom-scrollbar>

      <div class="footer">
        <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
        <a-button type="primary" @click="handleSubmit"> Guardar cambios </a-button>
      </div>
    </a-drawer>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import { EditOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    EditOutlined,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      email: "",
      type_email: "",
    });
    const rules = {
      email: [
        {
          type: "email",
          message: "El correo electrónico no es válido",
        },
      ],
    };
    return {
      rules,
      formState,
      formRef,
    };
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    handleShowDrawer() {
      this.visible = true;
      this.formState.email = this.record.email;
      this.formState.type_email = this.record.type_email;
    },
    handleCloseDrawer() {
      this.visible = false;
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          const body = { ...toRaw(this.formState) };
          const emailId = this.record.id;
          await this.$store.dispatch("updateEmailContact", { body, emailId });
          this.handleCloseDrawer();
          this.$message.success("Se ha editado información correctamente");
        } catch (error) {
          this.$message.error("Se ha producido un error al editar la información");
        }
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 12px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

:deep(.ant-calendar-picker)
  width: 100%

.icon
  color: $blue-6
</style>
