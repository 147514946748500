<template>
  <a-popover
    arrowPointAtCenter
    title="Selecciona etiquetas"
    placement="bottomRight"
    :trigger="['click']"
  >
    <a-tooltip title="Agregar etiquetas">
      <a-button v-if="!props.isActionTable" type="link" class="button" @click.stop>
        <template v-if="tagPosition === 'left'">Etiquetar</template>
        <TagOutlined class="icon" />
        <template v-if="tagPosition === 'right'">Etiquetar</template>
      </a-button>
      <a-button v-else type="text" @click.prevent>
        <EditOutlined />
        Etiquetar correo
      </a-button>
    </a-tooltip>

    <template #content>
      <div class="list__container" v-if="tags.length !== 0">
        <span class="list__detail"> {{ selectedTagsCount }} Actualmente seleccionados </span>
        <a-checkbox-group
          class="checkbox-group"
          v-model:value="selectedTags"
          @change="handleChangeSelectedTags"
        >
          <div class="list__tags">
            <a-checkbox v-for="tag in tags" :key="tag.id" :value="tag.id" :disabled="tag.is_permanent">
              <TagFilled :style="{ color: `#${tag.color}` }" />
              {{ tag.name }}
            </a-checkbox>
          </div>
        </a-checkbox-group>
      </div>

      <a-menu v-else>
        <div class="no-tags-data">
          Actualmente, no se cuenta con etiquetas.
          <span v-if="hasSettingsMenu">
            Puedes crearlas desde el
            <router-link :to="{ path: '/settings', query: { openAddModal: 'true' } }">
              módulo de Ajustes.
            </router-link>
          </span>
        </div>
      </a-menu>
    </template>
  </a-popover>
</template>

<script setup lang="ts">
import { ref, computed, watch, onUpdated, onMounted } from "vue";
import { useStore } from "vuex";
import { TagOutlined, TagFilled, EditOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

import { Tag, UserData } from "@/@types/global";
import { findIntersection } from "@/app/shared/utils/helpers";
import { fetchTags } from "@/app/platformSettings/services";

const props = defineProps<{
  simpleTag?: boolean;
  // current tags in the item
  currentTags?: number[];
  multipleElementTags?: number[][];
  tagPosition?: "left" | "right";
  isActionTable?: boolean | false;
  tagsDefault: Tag[];
}>();

const emit = defineEmits<{
  selectTags: [selectedTags: number[]];
}>();

const store = useStore();

const selectedTags = ref<number[]>([]);

onUpdated(() => {
  selectedTags.value = props.simpleTag ? props.currentTags : intersectionTags.value;
});

// COMPUTED
const hasSettingsMenu = computed<boolean>(() => {
  const user: UserData = store.getters["getDataUser"];
  const menus = user?.options_menu;

  return menus.some((menu) => menu.scope === "menu_settings");
});

// tags from the endpoint
const tags = ref<Tag[]>([]);

watch(
  () => props.tagsDefault,
  async (tagsDefault) => {
    tags.value = tagsDefault;
  },
  { immediate: true }
);

onMounted(async () => {
  console.log(props.tagsDefault);
  if (!props.tagsDefault) tags.value = await fetchTags();
  else tags.value = props.tagsDefault;
});

const intersectionTags = computed<number[]>(() => {
  const tagsId = props.multipleElementTags || [];
  return findIntersection(...tagsId);
});

const selectedTagsCount = computed<string>(
  () => `${selectedTags.value?.length}/${tags.value?.length}`
);

// WATCHERS
watch(
  intersectionTags,
  () => {
    selectedTags.value = _.cloneDeep(intersectionTags.value);
  },
  { immediate: true }
);

// FUNCTIONS
const handleChangeSelectedTags = _.debounce(async (selectedTagsId: number[]) => {
  const currentTagsId = props.currentTags;

  let tagsToAdd: number[] = [];
  let tagsToRemove: number[] = [];
  let promises: number[] = [];

  if (props.simpleTag) {
    tagsToAdd = selectedTagsId.filter((tagId) => !currentTagsId?.includes(tagId));
    tagsToRemove = currentTagsId.filter((tagId) => !selectedTagsId.includes(tagId));

    promises = [...tagsToAdd, ...tagsToRemove];
  } else {
    tagsToAdd = selectedTagsId.filter((tagId) => !intersectionTags.value.includes(tagId));
    tagsToRemove = intersectionTags.value.filter((tagId) => !selectedTagsId.includes(tagId));

    console.log(tagsToAdd, "tags a agregar");
    console.log(tagsToRemove, "tags a eliminar");
    // const addPromises = tagsToAdd;

    // const removePromises = tagsToRemove;

    // promises = [...addPromises, ...removePromises];
    promises = [...selectedTagsId];
  }

  console.log(promises, "promises");
  try {
    emit("selectTags", { tagsToAdd: tagsToAdd, tagsToRemove: tagsToRemove });
  } catch (error) {
    console.error(error);
  }
}, 800);
</script>

<style lang="sass" scoped>
.button
  padding: 0
  height: 22px

  .icon
    font-size: 18px

.ant-dropdown-menu
  width: 190px !important

.ant-dropdown-link
  height: 15px

.no-tags-data
  color: $gray-8
  text-align: center
  padding: 15px

:deep(.ant-message-notice-content)
  background: red !important
  width: 10px
:deep(.ant-badge-status-dot)
  width: 8px
  height: 7px
.color-icon
  color: $blue-6
  font-size: $font-medium
.color-icon-disabled
  color: $gray-6
  font-size: $font-medium
  cursor: not-allowed

.checkbox-group
  width: 100%

.list
  &__container
    display: flex
    flex-direction: column
    gap: 8px

  &__detail
    color: $gray-7

  &__tags
    display: flex
    flex-direction: column
    gap: 10px

:deep(.ant-checkbox-wrapper)
  margin: 0 !important
  color: $gray-8 !important

.popover-content
  ul
    list-style: none
    padding-left: 0
    margin: 0

    li
      button
        width: 100%
        text-align: left
        padding: 0 5px
        display: flex
        align-items: center
        gap: 5px

        &:hover
          background: #0000000A
        .icon
          height: 13px
</style>
