import { MutationTree } from "vuex";
import { State } from "@/@types/emails";
import {
  SET_SELECTED_MENU,
  SET_TOTAL_EMAILS,
  SET_SELECTED_CAMPAIGN,
  // SET_SELECTED_EMAIL,
  SET_THREAD_ACTUAL,
  REMOVE_THREAD_ACTUAL,
  SET_THREADS_INBOX,
  SET_TABLE_PAGE,
  SET_MENU_ITEMS,
  SET_OPEN_KEYS_MENU_ITEMS,
  SET_SELECTED_KEYS_MENU_ITEMS,
  SET_FETCHING_THREADS_INBOX,
  SET_SELECTED_THREADS_INBOX,
} from "./mutation-types";

const mutations: MutationTree<State> = {
  [SET_SELECTED_MENU](state, data) {
    state.selectedMenu = data;
  },
  [SET_SELECTED_CAMPAIGN](state, data) {
    state.selectedCampaign = data;
    // si es no esta abierta la campaña en el menu se debe abrir
    if (!state.openKeysMenuItems.includes(data.channelCustomerId)) {
      state.openKeysMenuItems.push(data.channelCustomerId);
    }
  },
  [SET_MENU_ITEMS](state, data) {
    state.menuItems = data;
  },
  [SET_OPEN_KEYS_MENU_ITEMS](state, data) {
    state.openKeysMenuItems = data;
  },
  [SET_SELECTED_KEYS_MENU_ITEMS](state, data) {
    state.selectedKeysMenuItems = data;
  },
  // [SET_SELECTED_EMAIL](state, data) {
  //   state.selectedEmail = data;
  // },
  [SET_TOTAL_EMAILS](state, data) {
    state.totalEmails = data;
  },
  [SET_TABLE_PAGE](state, data) {
    state.tablePage = data;
  },
  [SET_THREAD_ACTUAL](state, data) {
    state.threadActual = data;
  },
  [REMOVE_THREAD_ACTUAL](state) {
    state.threadActual = {
      key: null,
      id: null,
      details: [],
      tags: [],
      associatedClients: [],
      client: null,
      ticket: null,
    };
  },
  [SET_THREADS_INBOX](state, data) {
    state.threadsInbox = data;
  },
  [SET_FETCHING_THREADS_INBOX](state, data) {
    state.fetchingThreadsInbox = data;
  },
  [SET_SELECTED_THREADS_INBOX](state, data) {
    state.selectedThreadsInbox = data;
  },
};

export default mutations;
