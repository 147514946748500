<template>
  <a-form-item :required="required" :name="withName ? formName : ''" :label="label">
    <slot name="label"></slot>
    <a-select
      :getPopupContainer="triggerNode ? (triggerNode) => triggerNode.parentNode : null"
      v-model:value="selected"
      :disabled="(!entity && !withOutEntity && !isInHouse) || disabled"
      :mode="multiple ? 'multiple' : null"
      @change="change"
      :placeholder="placeholder"
      class="form__select"
      :loading="loading"
      :filter-option="filterOption"
    >
      <a-select-option v-if="showAllOption" :key="defaultBehavior ? '' : 0">
        Todas las campañas
      </a-select-option>
      <!-- (isDisabledBySequence(item.sequence_id) && item.id != includeItem?.id) -->
      <a-select-option
        v-for="(item, index) in !defaultItems ? portfolios : defaultItems"
        :id="`portfolio-select-option-${index}`"
        :disabled="
          isDisabledByTrunk(item.voip_provider) ||
          isDisabledByBlacklist(item?.id, item?.is_used_in_blacklist)
        "
        :key="item.id"
        :title="item.name"
      >
        {{ item.name }}
        {{ isDisabledByTrunk(item.voip_provider) ? " (Sin troncal)" : "" }}
        {{ validateItemSequence(item) == 1 ? " (Campaña vinculada a otra secuencia activa)" : "" }}
      </a-select-option>
    </a-select>

    <p class="help-text" v-if="helpText">{{ helpText }}</p>
    <p class="entity-name" v-if="showEntityLabel">
      Empresa: {{ portfolios.find((p) => p.id === selected)?.entity.name ?? "--" }}
    </p>
  </a-form-item>
</template>

<script>
import { fetchPortfolios } from "@/app/entities/services";
import httpManager from "@/app/shared/utils/httpManager";
import { TypeBusiness } from "@/app/shared/utils/enums";

export default {
  props: {
    showAll: {
      type: Boolean,
      default: true,
    },
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    entity: {
      required: false,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    defaultBehavior: {
      type: Boolean,
      default: false,
    },
    withOutEntity: {
      type: Boolean,
      default: false,
    },
    chatbotPicker: {
      type: Boolean,
      default: false,
    },
    withName: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    templatePicker: {
      type: Boolean,
      default: false,
    },
    webphone: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
    },
    showEntityLabel: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: "",
    },
    validateSequence: {
      type: Boolean,
      default: false,
    },
    defaultItems: {
      type: Array,
      default: null,
    },
    onlyWithIdentifiers: {
      type: Boolean,
      default: false,
    },
    hiddenIdentifier: {
      type: Number,
      default: undefined,
    },
    includeItem: {
      required: false,
    },
    triggerNode: {
      required: false,
      type: Boolean,
      default: false,
    },
    formName: {
      type: String,
      default: "portfolio",
      required: false,
    },
    blacklistCampaing: {
      type: Object,
      default: null,
      required: false,
    },
    blacklistExceptions: {
      type: Array,
      default: [],
      required: false,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Seleccionar campaña",
    },
  },
  data() {
    return {
      portfolios: [],
      loading: false,
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    userData() {
      return this.$store.getters["getDataUser"];
    },
    isInHouse() {
      return this.userData?.customer?.type_business === TypeBusiness.inHouse;
    },
    showAllOption() {
      return this.showAll && this.$route.path !== "/reports/documents";
    },
  },
  watch: {
    entity(newVal, oldVal) {
      if (!this.showAll || !newVal) this.selected = undefined;

      if (this.$route.path === "/reports/documents") {
        if (newVal !== oldVal) this.selected = undefined;
      }

      this.fetchPortfolios(newVal);
    },
    defaultItems(newVal) {
      console.log(newVal, "new val");
      portfolios.value = newVal;
    },
    blacklistCampaing() {
      if (
        this.blacklistCampaing.blacklist_phone &&
        this.blacklistCampaing.blacklist_code_phone &&
        this.blacklistCampaing.blacklist_channel
      ) {
        this.fetchPortfolios();
      }
    },
  },
  async created() {
    await this.fetchPortfolios();
    if (this.includeItem) this.addItemPortfolio();
  },
  methods: {
    validateItemSequence(item) {
      if (this.validateSequence && item?.sequences_data?.length > 0) {
        const sequenceActive = item?.sequences_data.find((el) => el.status == 1);
        if (sequenceActive) {
          return 1; // secuencia activa
        } else {
          return 2; // no hay secuencia activa con esa campaña
        }
      }
      return 0;
    },
    addItemPortfolio() {
      const index = this.portfolios.findIndex((el) => el.id == this.includeItem.id);
      if (index == -1) this.portfolios = [...this.portfolios, this.includeItem];
    },
    async fetchPortfolios() {
      httpManager.updateBaseURL(
        `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}/`
      );
      this.loading = true;
      const data = await fetchPortfolios(
        "",
        1,
        this.entity || "",
        {},
        {
          chatbotPicker: this.chatbotPicker,
          templatePicker: this.templatePicker,
        },
        this.blacklistCampaing
      );
      this.portfolios = data?.results || data;

      console.log(this.portfolios, "this.portfolios");
      // oculta los que no tienen identificadores o solo tiene el identificador a ocultar
      if (this.onlyWithIdentifiers == true) {
        console.log(this.hiddenIdentifier, "hiddenIdentifier");
        this.portfolios = this.portfolios.filter(
          (el) =>
            el.channel_customer?.length > 1 ||
            (el.channel_customer?.length == 1 &&
              el.channel_customer?.[0]?.id != this.hiddenIdentifier)
        );
      }
      this.loading = false;
      this.$emit("onSetPortfolios", this.portfolios);
    },
    isDisabledByTrunk(trunkId) {
      return this.webphone ? !trunkId : false;
    },
    isDisabledBySequence(sequenceId) {
      return this.validateSequence ? sequenceId : false;
    },
    isDisabledByBlacklist(id, isUsedInBlacklist) {
      if (isUsedInBlacklist) {
        const index = this.blacklistExceptions.findIndex((el) => el == id);
        return index < 0;
      }
      return false;
      // return this.blacklistCampaing ? isUsedInBlacklist : false;
    },
    filterOption(input, option) {
      const portfolio = this.portfolios.find((p) => p.id === option.value);

      return portfolio?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.form__select
  width: 100%

.entity-name
  color: $text-tertiary
  margin-top: 4px
  margin-bottom: 0

:deep(.ant-select-loading .ant-select-arrow-loading)
  left: 10px

:deep(.ant-select-loading .ant-select-selection-overflow)
  opacity: 0

.help-text
  color: $gray-7
  margin: 0
  margin-top: 2px
</style>
