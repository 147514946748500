<template>
  <a-modal :open="open" centered width="500px" class="subscribe-modal" @cancel="handleCancel">
    <div class="information">
      <div class="head">
        <img :src="resubscriptionImage" alt="Resuscribir logo" width="70" height="70" />
        <h2 style="margin: 25px 0 12px">
          ¿Estás seguro de resuscribir al correo “{{ record?.email }}”?
        </h2>
      </div>
      <div class="info" style="text-align: left">
        <p>Recuerda que:</p>
        <ul>
          <li>Para realizar esta acción debes contar con el consentimiento del contacto.</li>
          <li>
            Podrás resuscribir manualmente hasta 2 veces al mismo correo. Luego de ello, quedará
            bloqueado y no podrás volver a resuscribirlo.
          </li>
        </ul>
      </div>
      <a-form layout="vertical">
        <a-form-item required>
          <template #label>
            <span>Escribe la palabra <strong> "Desactivar"</strong></span>
          </template>

          <a-input v-model:value="confirmation" placeholder="Escribe aquí..." />
        </a-form-item>
      </a-form>
    </div>

    <template #footer>
      <footer class="confirmation footer">
        <a-button
          type="primary"
          :disabled="confirmation !== 'Desactivar'"
          @click="handleSubscripcion"
        >
          Resuscribir correo electrónico
        </a-button>
      </footer>
    </template>
  </a-modal>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { message } from "ant-design-vue";
import { emailService } from "@/app/emails/services/Email.service";
import { EmailRecordInfo } from "@/@types/emails";
import EventBus from "@/app/shared/utils/eventBus";

import resubscriptionImage from "@/app/platformSettings/assets/replay.png";

const props = defineProps<{ open: boolean; record: EmailRecordInfo }>();
const confirmation = ref("");

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const handleCancel = (e: MouseEvent) => {
  console.log(e);
  emit("update:open", false);
  confirmation.value = "";
};

const handleSubscripcion = async () => {
  try {
    const id = props?.record?.id;
    await emailService.subscribeEmail(id);
    emit("update:open", false);
    message.success("El correo fue resuscrito con éxito");
    confirmation.value = "";
    EventBus.emit("updateUnsubscribeTable");
  } catch (error) {
    message.error("No se pudo realizar la suscripción");
  }
};
</script>
<style lang="sass" scoped>
.subscribe-modal
  .information
    padding: 20px 20px 5px
    .head
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center
      align-items: center
  .footer
    display: flex
    justify-content: center
    padding-bottom: 15px
</style>
