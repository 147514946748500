import {
  fetchAccessToken,
  fetchCustomerByWorkspace,
  activateAnnex,
  disposeAnnex,
  //connectUser,
} from "../../services/";
import { tokenManager } from "@/app/shared/utils/tokenManager";
// import { registerEventManagement } from "@/app/shared/services";
// import { EventManagement } from "@/app/shared/utils/enums";
import { recoverPassword } from "../../services/requests/login/recoverPassword";
import { validateToken } from "../../services/requests/login/validateToken";
import { confirmPassword } from "../../services/requests/login/confirmPassword";
import { fetchAllWorkspace } from "../../services/requests/login/fetchAllWorkspace";

import {
  REMOVE_ACCESS_TOKEN,
  SET_ACCESS_TOKEN,
  SET_PROGRESS,
  SET_RECOVER_PASSWORD,
  ADD_TOKEN,
  SET_WORKSPACES,
  RESET_PASSWORD,
} from "./mutation-types";
import { disconnectAgentById } from "@/app/supervision/services";

const actions = {
  async fetchAccessToken({ commit }, credentials) {
    const { access: accessToken, refresh: refreshToken } = await fetchAccessToken(credentials);
    commit(SET_ACCESS_TOKEN, { accessToken });
    tokenManager.saveToken(accessToken, refreshToken);
    try {
      await activateAnnex(credentials["username"]);
    } catch (error) {
      console.error(error);
    } finally {
      // await connectUser() -> revisar este endpoint

      localStorage.removeItem("time");
      // await registerEventManagement(EventManagement.Login);
    }
  },

  async setAccessToken({ commit }, { accessToken, username }) {
    commit(SET_ACCESS_TOKEN, { accessToken });
    tokenManager.saveToken(accessToken);

    try {
      await activateAnnex(username);
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("time");
    }
  },

  /**
   * Obtener el cliente para validar el equipo de trabajo.
   *
   * @param {string} workspace Nombre de equipo de trabajo.
   */
  validateWorkspace(_, workspace) {
    return fetchCustomerByWorkspace(workspace);
  },

  /**
   * Cerrar sesión en la aplicación.
   */
  async logOut({ commit }, id) {
    try {
      await Promise.all([disposeAnnex(), disconnectAgentById(id)]);
      // await disposeAnnex();
      // await disconnectAgentById(id);
      commit(REMOVE_ACCESS_TOKEN);
      tokenManager.removeToken();
    } catch (e) {
      console.error(e);
    }
  },
  async logOutByAdmin({ commit }) {
    try {
      await disposeAnnex();

      commit(REMOVE_ACCESS_TOKEN);
      tokenManager.removeToken();
    } catch (e) {
      console.error(e);
    }
  },
  async setLoginProgress({ commit }, step) {
    commit(SET_PROGRESS, step);
  },
  async recoverPassword({ commit }, email) {
    await recoverPassword(email);
    commit(SET_RECOVER_PASSWORD, email);
  },
  async validateEmailToken({ commit }, token) {
    await validateToken(token);
    commit(SET_RECOVER_PASSWORD, token);
    commit(ADD_TOKEN, token);
  },
  async confirmPassword({ commit }, data) {
    await confirmPassword(data);
    commit(RESET_PASSWORD, data);
  },
  async fetchAllWorkspace({ commit }, workspace) {
    const data = await fetchAllWorkspace(workspace);

    if (data.length === 0) {
      commit(SET_WORKSPACES, []);
      throw Error("No se encontró co.");
    }
    commit(SET_WORKSPACES, data);
  },
  async clearWorkspaces({ commit }) {
    commit(SET_WORKSPACES, []);
  },
};

export default actions;
