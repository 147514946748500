import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza nuevo Referidos.
 * @param {number} emailId Identificador del referido
 * @param {Object} body Datos del referido.
 *
 * @return {Promise<Object>} Datos del referido
 */
export async function updateReferralsContact(referralsId, body) {
  const url = `/contacts/clients/contacts/${referralsId}/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
