export default [
  {
    path: "/onboarding",
    name: "onboarding",
    component: () =>
      import(/* webpackChunkName: "onboarding" */ "@/app/onboarding/pages/OnboardingPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
