<template>
  <div class="stepper">
    <a-steps progress-dot :current="value">
      <a-step
        v-for="(item, index) in items"
        :key="index"
        :title="item"
        @click="handleItemClick(index)"
      />
    </a-steps>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    /**
     * Indice del step seleccionado.
     * @model
     */
    value: { type: Number, default: 0 },
    /**
     * Array de string con los titulos de los pasos.
     */
    items: { required: true, type: Array },
    /**
     * Determina si no se puede mover entre componentes dando
     * click un paso específico.
     */
    readonly: { required: false, type: Boolean, default: false },
    /**
     * Ancho de cada step en pixeles.
     */
    itemWidth: { required: false, type: Number, default: 192 },
    progressDot: Boolean,
  },
  computed: {
    style() {
      return `--stepper-item-width: ${this.itemWidth}px`;
    },
  },
  methods: {
    /**
     * Retorna si un step específico está activo, comporandolo
     * con el `value`.
     */
    isItemActive(index) {
      return this.value === index;
    },
    /**
     * Retorna si un step debe estar con el check activo.
     */
    isItemChecked(index) {
      if (!this.readonly) return false;
      return index < this.value;
    },
    /**
     * Retorna las clases en BEM para saber si un step está
     * activo o si es clickeable.
     */
    getClasses(index) {
      return {
        "stepper__item--active": this.isItemActive(index),
        "stepper__item--editable": !this.readonly,
        "stepper__item--checked": this.isItemChecked(index),
      };
    },
    /**
     * Emite un evento con el index seleccionado cuando `readonly`
     * no está habilitado.
     */
    handleItemClick(index) {
      if (this.readonly) return;
      this.$emit("update:value", index);
    },
  },
};
</script>

<style lang="sass" scoped>
.stepper
  width: calc(100% - 80px)
  margin-left: auto
  margin-right: auto
  padding-top: 30px


:deep(.ant-steps-item-container)
  pointer-events: none
</style>
