import { fetchAllDids, createNewDid, fetchDidById, updateDid } from "@/app/pbx/services";

import { SET_DIDS, ADD_DID_TO_LIST, SET_DID, ADD_NEW_DID, CLEAR_DID } from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchAllDids({ commit }, { search, pagination } = {}) {
    const data = await fetchAllDids(search, pagination);
    commit(SET_DIDS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async createNewDid({ commit }, params) {
    const createdDid = await createNewDid(params);
    commit(ADD_DID_TO_LIST, createdDid);
  },
  async fetchDidById({ commit }, id) {
    const did = await fetchDidById(id);
    commit(SET_DID, did);
  },
  async updateDid({ commit }, { params, id }) {
    const updatedDid = await updateDid(id, params);
    commit(ADD_NEW_DID, { id, updatedDid });
  },
  clearDid({ commit }) {
    commit(CLEAR_DID);
  },
  assignedDid(context, did) {
    let stateDid = context.state.did;
    stateDid = { ...stateDid, ...did };
    context.commit(SET_DID, stateDid);
  },
};
export default actions;
