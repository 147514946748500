<template>
  <div class="emailPage">
    <!-- @fetchList="fetchList" -->
    <MenuTemplate
      ref="menuTemplateRef"
      :data="data"
      :isAssigned="isAssigned"
      @redirectToList="removeMessage"
    />
    <InboxTemplate
      ref="inboxTemplateRef"
      :data="data"
      @update="
        (event) => {
          fetchList(event);
          fetchMenuList();
        }
      "
      @update:menu="fetchMenuList"
      @toResolved="toResolved"
      :resolveSelected="resolveSelected"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, provide, computed } from "vue";
import MenuTemplate from "@/app/emails/components/templates/MenuTemplate.vue";
import InboxTemplate from "@/app/emails/components/templates/InboxTemplate.vue";
import { emailService } from "@/app/emails/services/Email.service";
import { DataType, IMenutem } from "@/@types/emails";
import { useStore } from "vuex";
import { UserData } from "@/@types/global";
import { debounce } from "lodash";

// const items = ref<IMenutem[]>([]);
// provide("menuItems", items);

const isAssigned = ref(false);
const replyEmail = ref(false);

provide("replyEmail", replyEmail);

// const data = ref<DataType[]>([]);

const store = useStore();

const user = computed<UserData>(() => store.getters["getDataUser"]);
const data = computed(() => store.getters["getThreadsInbox"]);

// const fetchingList = ref(false);

const menuTemplateRef = ref<InstanceType<typeof MenuTemplate>>();
const inboxTemplateRef = ref<InstanceType<typeof InboxTemplate>>();

const resolveSelected = computed(() => menuTemplateRef.value?.resolveSelected ?? false);

// const filterRead = ref<boolean | "">("");
// const filterSelectedTags = ref<Array<number | "">>([""]);
// const filterSearch = ref<string | null>(null);

// const channelCustomerId = ref(null);

// const fetchingList = computed(() => store.getters["getFetchingThreadsInbox"]);

// provide("fetchingList", fetchingList);
/*
  #TODO: Cambiar estructura de filtros de inbox para que use estados. 
  Usar vuex para estos valores ya que el listado del inbox depende de estos
*/
// provide("filterSearch", filterSearch);
// provide("filterRead", filterRead);
// provide("filterSelectedTags", filterSelectedTags);

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
const fetchList = debounce(
  // async (query: {
  //   channelCustomerId?: number;
  //   assigned: boolean;
  //   resolved?: boolean;
  //   read?: boolean;
  //   tagList?: number[];
  //   page?: number;
  //   resetPagination?: boolean;
  // }) => {
  async () => {
    // fetchingList.value = true;

    // const assignedUser = query?.assigned ? user.value?.id : undefined;
    // channelCustomerId.value = query.channelCustomerId;
    // store.commit("SET_THREADS_INBOX", []);
    await store.dispatch("updateThreadsInbox", {
      // ...query,
      // assignedUser: assignedUser,
      assignedUser: user.value?.id,
      resetPagination: true,
    });
    // const response = await emailService.getInboxList(
    //   !query.assigned,
    //   query.channelCustomerId,
    //   assignedUser,
    //   query.resolved,
    //   query.read,
    //   query.tagList,
    //   query.assigned ? 2 : 1
    // );
    // data.value = response.results;

    // fetchingList.value = false;
  },
  100
);

const toResolved = () => {
  menuTemplateRef.value?.showResolveEmail();
};

provide("fetchList", fetchList);

const fetchMenuList = () => {
  console.log("fetchMenuList");
  menuTemplateRef.value?.fetchMenuList();
};

const handleButtonMenuList = (item: any, subItem: any, fromMenu?: boolean) => {
  menuTemplateRef.value?.handleButtonClick(item, subItem, fromMenu);
};

provide("handleButtonMenuList", handleButtonMenuList);

const removeMessage = () => {
  console.log("remove message");
  inboxTemplateRef.value?.removeMessage();
};
</script>
<style lang="sass" scoped>
.emailPage
  background-color: #FFFFFF
  min-height: 100%
  max-height: 100%
  height: 100%
  display: flex
  .menu
    width: 20%
    z-index: 2
  .inbox
    width: 80%
</style>
