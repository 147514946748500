<template>
  <div>
    <a-form class="form" layout="vertical">
      <a-form-item label="Correo electrónico" class="recover__passowrd">
        <a-input
          v-model:value="getEmailUserSuccess.email"
          class="form__item"
          required="required"
          disabled
        />
      </a-form-item>
      <footer class="form__footer">
        <span class="success">Correo enviado</span>
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      </footer>
    </a-form>
  </div>
</template>

<script>
import { CheckCircleTwoTone } from "@ant-design/icons-vue";

export default {
  components: {
    CheckCircleTwoTone,
  },
  data() {
    return {
      username: "",
    };
  },
  computed: {
    getEmailUserSuccess() {
      return this.$store.getters["getEmail"];
    },
  },
  created() {
    this.$store.dispatch("setLoginProgress", 2);
  },
};
</script>

<style lang="sass" scoped>

.ant-form .ant-form-item
  margin-bottom: 12px

.form
  padding: 12px 0
  position: relative

.form__footer
  margin-top: 12px
  text-align: center

.success
  color: $green-6
  margin-right: 5px

.character
  position: absolute
  left: 173%
  top: -30%

.recover__passowrd
  margin-top: 10px
</style>
