import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener listado de grabaciones.
 *
 * @param {Object} filters Filtros
 * * @param {Object} pagination Paginación
 * @return {Promise<Object>} Listado de grabaciones filtrados.
 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export async function fetchRecordsList(filters = {}, pagination = {}) {
  const search = filters?.search || "";
  const entity = filters?.entity || "";
  const campaign = filters?.campaign || "";
  const agent = filters?.agent || "";
  const disposition = filters?.disposition || "";
  const typedialing = filters?.dialing || "";
  const date_start = filters?.call_start_date || "";
  const date_end = filters?.call_end_date || "";
  const hour_start = filters?.hour_start_date || "";
  const hour_end = filters?.hour_end_date || "";
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  const url = `/pbx/cdr/?offset=${offset}&limit=${pageSize}&phone=${search}&entity=${entity}&portfolio=${campaign}&user=${agent}&disposition=${disposition}&type_dialing=${typedialing}&date_start=${date_start}&date_end=${date_end}&hour_start=${hour_start}&hour_end=${hour_end}`;
  const response = await httpManager.get(url);
  return response?.data;
}
