<template>
  <a-form-item name="channel" :label="label">
    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      v-model:value="selected"
      class="select"
      data-test="CHANNELS_PICKER"
    >
      <a-select-option :value="defaultBehavior ? '' : 0"> Todos los canales </a-select-option>
      <a-select-option v-for="item in items" :key="item.id">
        <span>
          <icon-channel :type="item.id" :src="item.icon" :width="20" />
          {{ item.name }}
        </span>
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script>
import IconChannel from "@/app/shared/components/icons/IconChannel.vue";
import { ChannelType } from "@/app/shared/utils/enums";
import { fetchAllChannels } from "@/app/ticketsManager/services";

const channels = [
  { id: ChannelType.Whatsapp, name: "WhatsApp", icon: "whatsapp.png" },
  { id: ChannelType.Messenger, name: "Messenger", icon: "messenger.png" },
  { id: ChannelType.Inbound, name: "Web Chat", icon: "inbound.png" },
  // Hidden PoC
  // { id: ChannelType.Sms, name: "SMS", icon: "sms.png" },
  { id: ChannelType.Voice, name: "Voz", icon: "sms.png" },
  { id: ChannelType.Instagram, name: "Instagram", icon: "instagram.png" },
];

export default {
  components: {
    IconChannel,
  },
  props: {
    value: {
      required: true,
      type: [Number, String],
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    defaultBehavior: {
      type: Boolean,
      default: false,
    },
    toSequence: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  async mounted() {
    const response = await fetchAllChannels({ toSequence: this.toSequence });
    this.items = response?.filter((channel) => channel.name !== "IVR");
  },
};
</script>

<style lang="sass" scoped>
.select
  width: 100%
</style>
