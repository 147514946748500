<template>
  <a-modal
    :visible="open"
    width="820px"
    title="Conecta tu correo electrónico"
    centered
    :maskClosable="false"
    :bodyStyle="{ height: successIntegration ? '650px' : 'inherit' }"
    :footer="null"
    @cancel="handleClose"
    class="modal-email"
  >
    <template v-if="!successIntegration">
      <div class="email">
        <sidebar>
          <div class="content">
            <SocialIcon :type="'Correo'" />
            <h3>Correo Electrónico</h3>
            <div class="icon">
              <IconShieldCheck :active="true" />
              <span>Datos protegidos</span>
            </div>
            <p>
              Conecta tu correo electrónico mediante la configuración
              <strong>SMTP e IMAP</strong> para que gestiones tu comunicación desde
              {{ ProductName }}.
            </p>
          </div>
          <p class="sidebar-message">
            💡¿Tienes dudas sobre cómo conectar tu correo? Visita nuestro Centro de ayuda.
          </p>
        </sidebar>
        <div class="form">
          <a-form layout="vertical" ref="formRef" :model="formState" :rules="rules" required>
            <div class="">
              <a-form-item name="alias" required>
                <template #label>
                  Nombre (Alias)
                  <a-tooltip>
                    <template #title>
                      <div>Nombre visualizarán tus contactos al recibir un correo.</div>
                    </template>
                    <InfoCircleOutlined style="margin-left: 5px" />
                  </a-tooltip>
                </template>
                <a-input v-model:value="formState.alias" placeholder="Escribir nombre" />
              </a-form-item>
              <a-form-item name="email" required>
                <template #label>
                  Correo
                  <a-tooltip title="El correo que verán tus contactos">
                    <template #title>
                      <div>
                        La dirección de email que utilizarás para enviar correos electrónicos. Es la
                        misma de “SMPT username”.
                      </div>
                    </template>
                    <InfoCircleOutlined style="margin-left: 5px" />
                  </a-tooltip>
                </template>
                <a-input
                  v-model:value="formState.email"
                  type="email"
                  :disabled="isEdit ? true : false"
                  placeholder="ventas@score.com"
                />
              </a-form-item>
              <a-form-item name="password" required>
                <template #label>
                  Contraseña
                  <a-tooltip>
                    <template #title>
                      <div>La contraseña asociada a la dirección de correo utilizada.</div>
                    </template>
                    <InfoCircleOutlined style="margin-left: 5px" />
                  </a-tooltip>
                </template>
                <a-input
                  v-model:value="formState.password"
                  placeholder="Escribir aquí..."
                  type="password"
                  @change="handleChangePassword"
                />
              </a-form-item>
              <p>
                Importante: Recuerda que si cambias la contraseña de tu correo electrónico, la
                integración a través de SMTP y IMAP se desconectará, y deberás actualizarla.
              </p>
              <div class="server">
                <h3>Configuración SMTP para enviar correos</h3>
                <div class="server-smtp">
                  <a-form-item name="server_smtp" required>
                    <template #label>
                      Servidor SMTP
                      <a-tooltip>
                        <template #title>
                          <div>
                            Dirección del servidor SMTP que se utilizará para enviar correos desde
                            {{ ProductName }}.
                          </div>
                        </template>
                        <InfoCircleOutlined style="margin-left: 5px" />
                      </a-tooltip>
                    </template>
                    <a-input v-model:value="formState.server_smtp" placeholder="Escribir aquí..." />
                  </a-form-item>

                  <a-form-item name="port_smtp" required>
                    <template #label>
                      Puerto
                      <a-tooltip>
                        <template #title>
                          <div>
                            El número de puerto a través del cual se establecerá la conexión con el
                            servidor SMTP.
                          </div>
                        </template>
                        <InfoCircleOutlined style="margin-left: 5px" />
                      </a-tooltip>
                    </template>
                    <a-select
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                      v-model:value="formState.port_smtp"
                    >
                      <a-select-option v-for="option in options" :key="option">
                        {{ option }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="security">
                  <a-tooltip placement="right">
                    <template #title
                      >Establece el protocolo de seguridad para evitar que terceros accedan a tus
                      correos. Esta debe ser compatible con tu servidor. Recuerda validarlo con tu
                      técnico.
                    </template>
                    <span
                      >Conexión de seguridad <InfoCircleOutlined style="margin-left: 5px"
                    /></span>
                  </a-tooltip>
                  <a-checkbox v-model:checked="formState.secure_connection_smtp"
                    >Este servidor requiere una conexión segura (TLS/SSL)</a-checkbox
                  >
                </div>
              </div>
              <div class="server">
                <div class="inbound-title">
                  <h3>
                    Configuración para recibir correos
                    <a-switch
                      v-model:checked="formState.inbound_activate"
                      :disabled="data?.data_parameters?.inbound_activate === true"
                      size="small"
                    />
                  </h3>
                </div>
                <span>
                  Redirige los correos de tu proveedor hacia {{ ProductName }} mediante un correo de
                  asistencia. Recuerda que, este campo no es editable una vez creado.
                </span>

                <div>
                  <a-form-item name="imap_user" style="margin-top: 20px">
                    <template #label> Correo electrónico de asistencia </template>
                    <div style="display: flex" class="inbound-title">
                      <a-input
                        v-model:value="formState.imap_user"
                        type="imap_user"
                        :disabled="
                          !formState.inbound_activate ||
                          !props.data?.data_parameters?.imap_user?.length == 0
                        "
                        placeholder="Nombre del correo entrante"
                      />
                      <span style="margin-left: 10px" v-if="imapUserDomain"
                        >@{{ imapUserDomain }}</span
                      >
                    </div>
                    <span class="help_message">{{ imapUser }}</span>
                  </a-form-item>
                </div>

                <div>
                  ¿Cómo vincular tu correo del proveedor para recibir correos en {{ ProductName }}?
                  <br />
                  <a>Haz clic aquí.</a>
                </div>
                <!-- <div class="server-smtp">
                  <a-form-item name="server_smtp" required>
                    <template #label>
                      Servidor SMTP
                      <a-tooltip>
                        <template #title>
                          <div>
                            Dirección del servidor SMTP que se utilizará para enviar correos desde
                            Score.
                          </div>
                        </template>
                        <InfoCircleOutlined style="margin-left: 5px" />
                      </a-tooltip>
                    </template>
                    <a-input v-model:value="formState.server_smtp" placeholder="Escribir aquí..." />
                  </a-form-item>

                  <a-form-item name="port_smtp" required>
                    <template #label>
                      Puerto
                      <a-tooltip>
                        <template #title>
                          <div>
                            El número de puerto a través del cual se establecerá la conexión con el
                            servidor SMTP.
                          </div>
                        </template>
                        <InfoCircleOutlined style="margin-left: 5px" />
                      </a-tooltip>
                    </template>
                    <a-select :getPopupContainer="triggerNode => triggerNode.parentNode" v-model:value="formState.port_smtp">
                      <a-select-option v-for="option in options" :key="option">
                        {{ option }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="security">
                  <a-tooltip placement="right">
                    <template #title
                      >Establece el protocolo de seguridad para evitar que terceros accedan a tus
                      correos. Esta debe ser compatible con tu servidor. Recuerda validarlo con tu
                      técnico.
                    </template>
                    <span
                      >Conexión de seguridad <InfoCircleOutlined style="margin-left: 5px"
                    /></span>
                  </a-tooltip>
                  <a-checkbox v-model:checked="formState.secure_connection_smtp"
                    >Este servidor requiere una conexión segura (TLS/SSL)</a-checkbox
                  >
                </div> -->
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </template>
    <footer>
      <a-button @click="handleClose">Cancelar</a-button>
      <a-button
        type="primary"
        @click="handleConnection"
        :disabled="isDisabled"
        :loading="loading"
        >{{ primaryText }}</a-button
      >
    </footer>
    <div v-if="isIntegrationCompleted">
      <CompleteIntegration description="Tu nuevo correo electrónico se encuentra listo" />
    </div>
  </a-modal>
</template>
<script setup lang="ts">
import { reactive, ref, toRaw, computed } from "vue";
import { FormEmailInfo } from "@/@types/emails/forms";
import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import IconShieldCheck from "@/app/common/components/atoms/IconShieldCheck.vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { ProductName } from "@/app/shared/utils/constants";

const props = defineProps<{
  open: boolean;
  isEdit: boolean;
  data: FormEmailInfo;
}>();

const loading = ref(false);
const options = ["587", "465"];
const isIntegrationCompleted = ref(false);
const formRef = ref();
const successIntegration = ref(false);
const store = useStore();
const isPasswordEdited = ref(false);

const formState = reactive({
  alias: props.data ? props.data?.data_parameters.alias : "",
  email: props.data ? props.data?.data_parameters.email : "",
  password: props.data ? props.data?.data_parameters.password : "",
  server_smtp: props.data ? props.data?.data_parameters.server_smtp : "",
  port_smtp: props.data ? props.data?.data_parameters.port_smtp : "",
  secure_connection_smtp: props.data ? props.data?.data_parameters.secure_connection_smtp : false,
  inbound_activate: props.data ? props.data?.data_parameters.inbound_activate : false,
  imap_user: props.data ? props.data?.data_parameters.imap_user?.split("@")?.[0] : "",
});

const imapUserDomain = computed(() => {
  const subdomain = localStorage.getItem("workspace");
  return subdomain + "." + import.meta.env.VITE_HOSTNAME;
});

const validateDomain = (rule: any, value: string, callback: (error?: Error) => void) => {
  const domainRegex = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
  if (value && !domainRegex.test(value)) {
    callback(new Error("Por favor ingrese un dominio válido"));
  } else {
    callback();
  }
};

const rules = computed(() => {
  return {
    alias: [{ required: true, message: "Ingresar un nombre o alías válidos" }],
    email: [{ required: true, message: "Ingresar un email válido" }],
    password: [{ required: true, message: "Ingresar un password válido" }],
    server_smtp: [
      { required: true, message: "Por favor ingrese el servidor SMTP" },
      { validator: validateDomain, trigger: "blur" },
    ],
    port_smtp: [{ required: true, message: "Ingresar un puerto smtp válido" }],
    secure_connection_smtp: [{ required: true, message: "Campo requerido" }],
    imap_user: formState.inbound_activate
      ? [{ required: true, message: "Ingresar un correo válido" }]
      : [],
  };
});

const emit = defineEmits(["close", "reloadTable"]);
const handleClose = () => emit("close");

const primaryText = computed(() => {
  console.log(props.data, "props.data!!!!");
  if (props.isEdit) {
    return "Guardar Cambios";
  }
  return "Conectar";
});

const handleConnection = async () => {
  try {
    await formRef.value.validateFields();
    handleCreateNewChannel();
  } catch (error) {
    console.error("Validation failed:", error);
  }
};

const isDisabled = computed(() => {
  return (
    !formState.alias ||
    !formState.email ||
    !formState.password ||
    !formState.server_smtp ||
    !formState.port_smtp ||
    (formState.inbound_activate === true && !formState.imap_user)
  );
});

const handleChangePassword = () => {
  isPasswordEdited.value = true;
};

const imapUser = computed(() => {
  return formState.imap_user && imapUserDomain.value
    ? formState.imap_user + "@" + imapUserDomain.value
    : "";
});

const handleCreateNewChannel = async () => {
  try {
    loading.value = true;
    const data = {
      channel: 9,
      data_parameters: {
        ...toRaw({
          ...formState,
          imap_user: imapUser.value,
        }),
      },
      provider: 4,
    };

    if (props.isEdit) {
      console.log(props.data, "DATA INFOOO");
      if (!isPasswordEdited.value) {
        delete data.data_parameters.password;
      }
      const body = {
        id: props.data.id,
        data_parameters: data.data_parameters,
      };
      await store.dispatch("updateChannelCreate", body);
      handleClose();
      message.success("Se actualizaron tus datos correctamente");
    } else {
      await store.dispatch("createNewChannel", data);
      isIntegrationCompleted.value = true;
      message.success("Se ha integrado tu correo correctamente");
    }
    loading.value = false;
    emit("reloadTable");
  } catch (error) {
    const errorMessage =
      error?.getError().error ??
      (props.isEdit
        ? "No se pudo actualizar tus datos. Las credenciales ingresadas son incorrectas."
        : "No se pudo validar las credenciales SMTP. Por favor, verifica tus datos.");

    message.error(errorMessage);
    loading.value = false;
  }
};
</script>
<style lang="sass" scoped>
.email
  display: flex
  sidebar
    width: 35%
    text-align: center
    padding: 10px
    border-right: 1px solid #00000026
    display: flex
    flex-direction: column
    align-items: center
    .content
      flex-grow: 1
      .icon
        color: #389E0D
        display: flex
        align-items: center
        justify-content: center
        padding-bottom: 8px
    .message
      flex-grow: 0
  .form
    width: 65%
    padding: 10px 20px
    .server
      &-smtp,
      &-imap
        display: flex
        gap: 15px
        p
          margin-bottom: 10px
      .inbound-title
        display: flex
        align-items: center
        > *
          margin-right: 6px

.modal-email
  .ant-form-item
    margin-bottom: 10px
.security
  margin-bottom: 8px
footer
  display: flex
  justify-content: flex-end
  gap: 10px

.help_message
  color: rgba(0, 0, 0, 0.45)
</style>
