const getters = {
  getDidInfo(state) {
    return {
      items: state.didsInfo.items,
      pagination: state.didsInfo.pagination,
    };
  },
  getDid: (state) => state.did,
};
export default getters;
