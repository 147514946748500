<template>
  <section>
    <a-table
      row-key="id"
      size="middle"
      :columns="columnsTable"
      :data-source="paymentsClient"
      :scroll="{ x: 700 }"
      :pagination="false"
    >
      <template #summary v-if="paymentsClient.length != 0">
        <a-table-summary :fixed="'bottom'">
          <a-table-summary-row>
            <a-table-summary-cell :index="0" :col-span="2" class="footer-title"
              >Total
            </a-table-summary-cell>
            <a-table-summary-cell :index="2" :col-span="1" class="footer-title">{{
              totalPEN
            }}</a-table-summary-cell>
            <a-table-summary-cell :index="3" :col-span="1">Soles</a-table-summary-cell>
          </a-table-summary-row>
          <a-table-summary-row>
            <a-table-summary-cell :index="0" :col-span="2" class="footer-title"
              >Total
            </a-table-summary-cell>
            <a-table-summary-cell :index="2" :col-span="1" class="footer-title">{{
              totalUSD
            }}</a-table-summary-cell>
            <a-table-summary-cell :index="3" :col-span="1">Dolares</a-table-summary-cell>
          </a-table-summary-row>
        </a-table-summary>
      </template>
    </a-table>
  </section>
</template>

<script>
import { columns } from "@/app/contacts/components/tables/promises/ContactPromisesTableColumns";
// import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { ContactPromisesCurrencyTabsKey } from "@/app/contacts/utils/enums";
export default {
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    paymentsClient() {
      return this.$store.getters["getPromisesClient"]?.results;
    },
    contact() {
      return this.$store.getters["getContact"]?.operation_type;
    },
    columnsTable() {
      return this.columns;
    },
    totalPEN() {
      const total = this.paymentsClient
        .filter((payment) => payment.currency === ContactPromisesCurrencyTabsKey.PEN)
        .reduce((acc, payment) => acc + parseFloat(payment.amount_promise_to_pay) || 0, 0);
      return total.toFixed(2);
    },
    totalUSD() {
      const total = this.paymentsClient
        .filter((payment) => payment.currency === ContactPromisesCurrencyTabsKey.USD)
        .reduce((acc, payment) => acc + parseFloat(payment.amount_promise_to_pay) || 0, 0);
      return total.toFixed(2);
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px

.actions
  display: flex

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  width: 100px !important

.footer-title
  font-weight: 600
</style>
