<template>
  <section>
    <p>
      Solicita y administra tus planes de marcación.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para saber más.
    </p>
    <a-row class="inputs" type="flex" justify="space-between">
      <a-space :size="12">
        <a-input
          v-model:value="filters.search"
          class="search"
          placeholder="Buscar nombre..."
          @keypress.enter="handleFilterDialplans"
        >
          <template #prefix>
            <SearchOutlined />
          </template>
        </a-input>
        <selector-country
          class="search"
          showName
          v-model:value="filters.country"
          @selected="handleFilterDialplans"
          :allow-clear="true"
          showAll
        />
      </a-space>
      <a-button type="primary" @click="handleAddDialPlan"> Agregar plan de marcación </a-button>
    </a-row>
    <a-table
      class="table"
      :columns="DIAL_PLAN_TABLE_COLUMNS"
      :data-source="dialplansInfo.items"
      row-key="id"
      size="middle"
      :pagination="dialplansInfo.pagination"
      :scroll="{ y: '40em' }"
      @change="handleTableChange"
      :loading="loadingTable"
    >
      <template #statusTitle
        >Estado.
        <SwapOutlined :rotate="90" />
      </template>
      <template #planMarcationTitle>Nombre del plan de marcación </template>

      <template #status="{ record }">
        <a-tag :color="statusColor(record)">{{ statusName(record) }}</a-tag>
      </template>
      <template #actionScoped="{ record }">
        <a-space v-if="showAction(record)" size="middle">
          <a-switch
            size="small"
            :checked="statusState(record)"
            @change="handleStatusChange(record, $event)"
          ></a-switch>
          <EditOutlined class="iconEdit" @click="handleEditDialplan(record)" />
          <DeleteOutlined class="iconEdit" @click="confirmationDeleteDialPlan(record, record.id)" />
        </a-space>
      </template>
    </a-table>

    <dialplan-edit-modal
      v-if="visible.edit"
      :visible="visible.edit"
      :record="cloneDeep(record)"
      @onClose="handleCloseModal"
      @update="handleUpdate"
    />

    <dialplan-request-modal
      v-if="visible.request"
      :visible="visible.request"
      @onClose="handleCloseModal"
      @onUpdate="handleUpdate"
    />
  </section>
</template>

<script>
import { DIAL_PLAN_TABLE_COLUMNS } from "@/app/pbx/utils/constants";
import DialplanRequestModal from "@/app/pbx/components/modals/DialplanRequestModal.vue";
import DialplanEditModal from "@/app/pbx/components/modals/DialplanEditModal.vue";
import httpManager from "@/app/shared/utils/httpManager";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import {
  SearchOutlined,
  SwapOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { cloneDeep } from "lodash";

export default defineComponent({
  components: {
    DialplanRequestModal,
    DialplanEditModal,
    SearchOutlined,
    SwapOutlined,
    EditOutlined,
    DeleteOutlined,
    SelectorCountry,
  },
  setup() {
    const errorInactivePlanMarcation = () => {
      Modal.warning({
        title: "Este plan de marcación cuenta con dependencias por lo que no puede ser inactivado",
        content:
          "Para poder inactivarlo, primero debes desvincular las campañas asociadas a la troncal de este plan de marcación.",
        centered: true,
        okText: "Aceptar",
      });
    };
    const errorDeletePlanMarcation = () => {
      Modal.warning({
        title: "Este plan de marcación cuenta con dependencias por lo que no puede ser eliminado",
        content:
          "Para poder eliminarlo, primero debes desvincular las campañas asociadas a la troncal de este plan de marcación.",
        centered: true,
        okText: "Aceptar",
      });
    };
    return {
      errorInactivePlanMarcation,
      errorDeletePlanMarcation,
      cloneDeep,
    };
  },
  data() {
    const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/pbx/plan-de-marcacion/`;
    return {
      DIAL_PLAN_TABLE_COLUMNS,
      visible: {
        request: false,
        edit: false,
      },
      filters: {
        search: "",
        country: undefined,
      },
      pendient: false,
      timeData: true,
      record: null,
      loadingTable: false,
      DOCS_URL,
    };
  },
  computed: {
    dialplanLength() {
      return this.$store.getters["getDialplanInfo"].length;
    },
    dialplansInfo() {
      return this.$store.getters["getDialplanInfo"];
    },
    trunks() {
      return this.$store.getters["getTrunkList"];
    },
  },
  created() {
    this.handleFetchDialplan(this.pagination);
  },
  mounted() {
    this.loadInitialData();
  },
  methods: {
    async loadInitialData() {
      httpManager.updateBaseURL(
        `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
      );
      this.loadingTable = true;
      await this.$store.dispatch("fetchAllDialplans");
      this.loadingTable = false;
    },
    async handleUpdate() {
      await this.handleFetchDialplan(this.pagination);
    },
    handleTableChange(pagination) {
      this.handleFetchDialplan(pagination);
    },
    async handleFetchDialplan(pagination) {
      this.loadingTable = true;
      httpManager.updateBaseURL(
        `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
      );
      await this.$store.dispatch("fetchAllDialplans", { pagination });
      this.loadingTable = false;
    },
    async handleFilterDialplans() {
      this.loadingTable = true;
      httpManager.updateBaseURL(
        `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
      );
      await this.$store.dispatch("fetchAllDialplans", this.filters);
      this.loadingTable = false;
    },
    showAction(record) {
      let value = true;
      let idBig = 0;
      this.dialplansInfo.items.forEach((dialplan) => {
        if (dialplan.id > idBig) {
          idBig = dialplan.id;
        }
      });
      if (idBig == record.id && this.pendient) {
        value = false;
      } else {
        value = true;
      }
      return value;
    },
    async handleStatusChange(record, state) {
      let checked = 2;
      switch (state) {
        case true:
          checked = 1;
          break;

        default:
          checked = 2;
          break;
      }
      const obj = {
        ...record,
        status: checked,
        rules: record.dialing_rules,
      };
      if (state) {
        this.updateStatus(record, obj);
      } else {
        this.showConfirm(record, obj);
      }
    },
    showConfirm(record, obj) {
      const self = this;
      if (record.trunk.portfolios?.length) {
        Modal.warning({
          title: `Este plan de marcación cuenta con dependencias por lo que no puede ser inactivado`,
          content: `Para poder inactivarlo, primero debes desvincular la troncal “${record.trunk.name}” (asociada a este plan de marcación) de todas las campañas donde se encuentre.`,
          width: 470,
          okText: "Aceptar",
          centered: true,
        });
      } else {
        this.$confirm({
          title: `¿Estás seguro de inactivar el plan de marcación “${record.name}"? `,
          content: `Recuerda que no podrás asociar la troncal "${record.trunk.name}" de este plan de marcación a ninguna campaña.`,
          icon: createVNode(QuestionCircleOutlined),
          okText: "Inactivar",
          calcelText: "Cancelar",
          onOk() {
            self.updateStatus(record, obj);
          },
          centered: true,
        });
      }
    },
    async updateStatus(record, obj) {
      try {
        httpManager.updateBaseURL(
          `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
        );
        await this.$store.dispatch("updateDialplan", {
          id: record.id,
          params: obj,
        });
      } catch (error) {
        this.errorInactivePlanMarcation();
      }
    },
    statusState(record) {
      let value = false;
      switch (record.status) {
        case 1:
          value = true;
          break;

        default:
          value = false;
          break;
      }
      return value;
    },
    statusName(record) {
      let value = "";
      let idBig = 0;
      this.dialplansInfo.items.forEach((dialplan) => {
        if (dialplan.id > idBig) {
          idBig = dialplan.id;
        }
      });
      if (idBig == record.id && this.pendient) {
        value = "Pendiente";
      } else {
        switch (record.status) {
          case 1:
            value = "Activo";
            break;
          case 2:
            value = "Inactivo";
            break;
        }
      }
      return value;
    },
    statusColor(record) {
      let value = "";
      let idBig = 0;
      this.dialplansInfo.items.forEach((did) => {
        if (did.id > idBig) {
          idBig = did.id;
        }
      });
      if (idBig == record.id && this.pendient) {
        value = "orange";
      } else {
        switch (record.status) {
          case 1:
            value = "green";
            break;
          case 2:
            value = "red";
            break;
        }
      }
      return value;
    },
    handleAddDialPlan() {
      this.visible.request = true;
      // httpManager.updateBaseURL(
      //   `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
      // );
      // this.$store.dispatch("setDialplanRules", []);
    },
    handleEditDialplan(record) {
      this.record = record;
      this.visible.edit = true;
      httpManager.updateBaseURL(
        `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
      );
      this.$store.dispatch("setDialplanRules", record.dialing_rules);
    },
    handleCloseModal(value = false) {
      this.record = null;
      this.visible.request = false;
      this.visible.edit = false;

      if (value) {
        this.pendient = true;
        setTimeout(() => {
          this.pendient = false;
        }, 2000);
      }
    },
    confirmationDeleteDialPlan(record, id) {
      if (record.trunk.portfolios?.length) {
        Modal.warning({
          title: `Este plan de marcación cuenta con dependencias por lo que no puede ser eliminado`,
          content: `Para poder eliminarlo, primero debes desvincular la troncal “${record.trunk.name}” (asociada a este plan de marcación) de todas las campañas donde se encuentre.`,
          okText: "Aceptar",
          width: 470,
          centered: true,
        });
      } else {
        this.$confirm({
          title: `¿Estás seguro de eliminar el plan de marcación "${record.name}"? `,
          content:
            "Recuerda que ya no se visualizará este plan de marcación en la tabla. Si deseas volver a utilizarlo, deberás configurarlo nuevamente.",
          icon: createVNode(QuestionCircleOutlined),
          okText: "Eliminar",
          calcelText: "Cancelar",
          onOk: () => this.handleDeleteDialPlan(id),
          centered: true,
        });
      }
    },
    async handleDeleteDialPlan(id) {
      try {
        await this.$store.dispatch("deleteDialPlan", id);
        this.handleFetchDialplan(this.pagination);
      } catch (error) {
        this.errorDeletePlanMarcation();
      }
    },
  },
});
</script>

<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none
:deep(.ant-table-tbody > tr > td)
  padding: 8px 10px

:deep(.ant-table-thead > tr > th)
  // padding: 10px !important

.dialplan
  padding-top: 12px
  padding-right: 21px
  padding-left: 21px
.inputs
  width: 100%
  .search
    margin-bottom: 0.75rem
    width: 16.5625rem
.iconEdit
  color: $blue-6
</style>
