<template>
  <template v-if="customer">
    <main-logo-register />

    <FormCard
      :image-src="workspaceData?.picture"
      :title="`¡Inicia sesión en ${workspaceData?.name}!`"
      :description="location"
    >
      <login-form />
    </FormCard>
  </template>
</template>

<script>
import httpManager from "@/app/shared/utils/httpManager";

import FormCard from "../atoms/FormCard.vue";
import MainLogoRegister from "@/app/shared/components/molecules/MainLogoRegister.vue";
import LoginForm from "@/app/auth/components/organisms/LoginForm.vue";

export default {
  components: {
    MainLogoRegister,
    LoginForm,
    FormCard,
  },
  data() {
    return {
      customer: "",
      loading: false,
      workspaceData: null,
      location: window.location.origin,
    };
  },
  computed: {
    title() {
      return `¡Bienvenido a !`;
    },
    subtitle() {
      return "Recuerda completar los datos correctamente";
    },
  },
  async mounted() {
    let workspace = localStorage.getItem("workspace");
    const hostname = window.location.hostname;
    const inTenant = !(hostname.split(".").length <= 2 || hostname.split(".")[0] === "www");

    if (inTenant) {
      workspace = hostname.split(`.${import.meta.env.VITE_HOSTNAME}`)[0];
      localStorage.setItem("workspace", workspace);
    }

    this.customer = workspace;
    httpManager.updateBaseURL(`https://${workspace}.backend.${import.meta.env.VITE_HOSTNAME}`);

    try {
      if (!workspace) {
        this.$router.push("/home");
      } else {
        const response = await this.$store.dispatch("validateWorkspace", workspace);
        console.log("Workspace validado", response);
        this.workspaceData = response;
      }
    } catch {
      this.$router.push("/not-found-workspace");
    }
  },
};
</script>

<style lang="sass" scoped>
.container__info
  margin: 0 auto
  text-align: center
  margin-bottom: 50px

.drawing
  margin-top: 130px
  width: 50%
  text-align: center

.text--plan
  font-size: 20px
  font-weight: 400

.name
  font-weight: 600
  font-size: 30px

.subtitle
  font-size: 20px

.flex
  display: flex
  justify-content: space-between
</style>
