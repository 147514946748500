import PbxsPage from "@/app/pbx/pages/PbxsPage.vue";
import TrunkTemplate from "@/app/pbx/components/templates/TrunkTemplate.vue";
import DialplanTemplate from "@/app/pbx/components/templates/DialplanTemplate.vue";
// import DidsTemplate from "@/app/pbx/components/templates/DidsTemplate";

export default [
  {
    path: "/pbx",
    name: "pbx",
    component: PbxsPage,
    redirect: "/pbx/trunk",
    children: [
      {
        path: "trunk",
        component: TrunkTemplate,
        meta: { requiresAuth: true },
      },
      {
        path: "dialplan",
        component: DialplanTemplate,
        meta: { requiresAuth: true },
      },
      // {
      //   path: "did",
      //   component: DidsTemplate,
      //   meta: { requiresAuth: true },
      // },
    ],
    meta: { requiresAuth: true },
  },
];
