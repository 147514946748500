<template>
  <a-form-item :name="name" :required="required">
    <template v-if="$slots['label'] || label" #label>
      <slot name="label" />
      <div class="flex-gap-4" v-if="label || showTooltip">
        <span v-if="label">
          {{ label }}
        </span>
        <a-tooltip :title="tooltip" v-if="showTooltip">
          <InfoCircleOutlined class="info-icon" />
        </a-tooltip>
      </div>
    </template>

    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      :value="value"
      class="select"
      :placeholder="placeholder"
      @update:value="handleChange"
      @change="emit('change')"
      :loading="loading"
      :mode="multiple ? 'multiple' : null"
      :disabled="
        (!(Array.isArray(portfolio) ? portfolio.length : portfolio) && !withOutPortfolio) ||
        disabled
      "
      :filter-option="filterOption"
    >
      <a-select-option v-if="showAll" :value="defaultBehavior ? '' : 0">
        Todas las colas
      </a-select-option>

      <a-select-option v-for="item in items" :key="item.id" :title="item.name">
        {{ item.name }}
      </a-select-option>

      <template #dropdownRender v-if="items.length === 0">
        <div class="dropdown-default__wrapper">
          <template v-if="smsTemplatePicker">
            No se han encontrado colas disponibles con plantillas asociadas.
            <span v-if="hasQueuesMenu">
              Asocia tus plantillas
              <router-link to="/channels/board"> aquí.</router-link>
            </span>
          </template>

          <template v-else>
            No se han encontrado colas disponibles.
            <br />
            <span v-if="hasQueuesMenu">
              Crea tu cola
              <router-link to="/operations/queues"> aquí.</router-link>
            </span>
          </template>
        </div>
      </template>
    </a-select>

    <p v-if="help" class="help">{{ help }}</p>
  </a-form-item>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { intersection, debounce } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

import { fetchQueues } from "@/app/workspace/services";
import { useStore } from "vuex";

const props = defineProps({
  value: {
    required: false,
    type: [String, Number],
  },
  required: {
    type: Boolean,
    default: false,
  },
  label: {
    required: false,
    type: String,
    default: "",
  },
  portfolio: {
    required: false,
    type: [Number, null, Array],
  },
  defaultBehavior: {
    type: Boolean,
    default: false,
  },
  withOutPortfolio: {
    type: Boolean,
    default: true,
  },
  showAll: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  templatePicker: {
    type: Boolean,
    default: false,
  },
  smsTemplatePicker: {
    type: Boolean,
    default: false,
  },
  help: String,
  tooltip: {
    type: String,
    default: "Se listarán solo aquellas colas asociadas a la campaña seleccionada.",
  },
  showTooltip: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    default: "queue",
    required: false,
  },
  channelCustomer: {
    type: Number,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "Seleccionar cola",
  },
});

const store = useStore();

const emit = defineEmits(["update:value", "change"]);

const items = ref([]);

const handleChange = (newValue) => emit("update:value", newValue);

const hasQueuesMenu = computed(() => {
  const userMenus = store.getters["getDataUser"]?.options_menu || [];

  return userMenus.some((menu) => menu?.items.some((subMenu) => subMenu?.name === "Colas"));
});

const loading = ref(true);

const fetchQueuesByPortfolio = async () => {
  loading.value = true;

  const selectedPortfolios = Array.isArray(props.portfolio)
    ? [...props.portfolio]
    : [props.portfolio];
  const queuesPromises = selectedPortfolios.map((portfolio) =>
    fetchQueues({
      portfolio,
      templatePicker: props.templatePicker,
      smsTemplatePicker: props.smsTemplatePicker,
      channelCustomer: props.channelCustomer,
    })
  );

  const queuesPerPortfolio = await Promise.all(queuesPromises);
  items.value = queuesPerPortfolio.flat();

  const validQueuesId = intersection(
    [...items.value.map((item) => item.id), ""],
    props.multiple ? props.value : [props.value]
  );

  const newValue = props.multiple ? validQueuesId : validQueuesId[0];
  emit("update:value", validQueuesId.length ? newValue : undefined);

  loading.value = false;
};

const valueExistsInItems = computed(() => items.value.some((i) => i.id === props.value));

const filterOption = (input, option) => {
  const queue = items.value.find((i) => i.id === option.value);

  return queue?.name?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
};

watch(
  [() => props.portfolio, () => props.smsTemplatePicker, () => props.channelCustomer],
  debounce(() => fetchQueuesByPortfolio(), 200),
  { immediate: true }
);

defineExpose({ valueExistsInItems });
</script>

<style lang="sass" scoped>
.select
  width: 100%

.help
  color: $text-tertiary

:deep(.ant-select-loading .ant-select-arrow-loading)
  left: 10px

:deep(.ant-select-loading .ant-select-selection-overflow)
  opacity: 0

.flex-gap-4
  display: flex
  gap: 4px

.dropdown-default__wrapper
  margin: 20px auto
  // width: 190px
  text-align: center
  color: $gray-8
  font-size: 12px
</style>
