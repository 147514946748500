<template>
  <div class="channelsSetup">
    <p>
      Configura tus canales favoritos y comienza a comunicarte con tus clientes por un solo hilo
      omnicanal.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer">Click aquí</a> para conocer más.
    </p>

    <div class="container">
      <div class="section-channels-cards">
        <CardChannels
          v-for="channel in channelsCustomerConfig"
          :key="channel.id"
          :channel="channel"
          @openModal="handleOpenModal"
        />
      </div>
    </div>
  </div>

  <facebook-channel
    v-if="facebookModal"
    class="setup-modal"
    :open="facebookModal"
    @close="facebookModal = false"
  />
  <whatsapp-channels class="setup-modal" :open="whatsappModal" @close="whatsappModal = false" />

  <WebChatChannels
    class="setup-modal"
    :open="showWebChatModal"
    @close="showWebChatModal = false"
    v-if="showWebChatModal"
  />

  <voice-channel
    v-if="voiceModal"
    class="setup-modal"
    :open="voiceModal"
    @close="voiceModal = false"
  />
  <sms-channel class="setup-modal" :open="smsModal" @close="smsModal = false" />
  <EmailChannel
    v-if="emailModal"
    class="setup-modal"
    :open="emailModal"
    @close="emailModal = false"
  />

  <InstagramConfigurationModal
    v-if="instagramModal"
    class="setup-modal"
    :open="instagramModal"
    @close="instagramModal = false"
  />
</template>

<script>
import facebookChannel from "@/app/channels/components/modals/messengerChat/facebookChannel.vue";
import whatsappChannels from "@/app/channels/components/modals/whatsapp/WhatsappChannels.vue";
import WebChatChannels from "@/app/channels/components/modals/webChat/Index.vue";
import CardChannels from "@/app/channels/components/cards/CardChannel.vue";
import SmsChannel from "@/app/channels/components/modals/sms/SmsChannel.vue";
import VoiceChannel from "@/app/channels/components/modals/voice/VoiceChannel.vue";
import EmailChannel from "@/app/channels/components/modals/email/EmailChannel.vue";
import InstagramConfigurationModal from "@/app/channels/components/modals/instagram/InstagramConfigurationModal.vue";

import { channelsConfig } from "@/app/channels/utils/constants";

export default {
  name: "ChannelsSetupTemplate",
  components: {
    CardChannels,
    facebookChannel,
    whatsappChannels,
    WebChatChannels,
    SmsChannel,
    VoiceChannel,
    EmailChannel,
    InstagramConfigurationModal,
  },
  data() {
    const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/knowledgebase-category/conexiones`;

    return {
      voice: 1,
      sms: 2,
      whatsapp: 4,
      webchat: 5,
      facebook: 6,
      email: 9,
      channelsCustomerConfig: channelsConfig,
      facebookModal: false,
      whatsappModal: false,
      showWebChatModal: false,
      smsModal: false,
      voiceModal: false,
      emailModal: false,
      instagramModal: false,
      DOCS_URL,
    };
  },
  mounted() {
    this.$store.dispatch("fetchAllChannelsCustomers");
    this.$store.dispatch("fetchAllCountries");
    this.$store.dispatch("fetchLoggedBusiness");
  },
  computed: {
    channelsCustomer() {
      return this.$store.getters["getChannelsCustomersList"];
    },
  },
  methods: {
    handleOpenModal(event) {
      switch (event) {
        case "Messenger":
          this.facebookModal = true;
          break;
        case "WhatsApp":
          this.whatsappModal = true;
          break;
        case "Chat Inbound":
          this.showWebChatModal = true;
          break;
        case "SMS":
          break;
        case "Telefonica":
          this.voiceModal = true;
          break;
        case "Correo":
          this.emailModal = true;
          break;
        case "Instagram":
          this.instagramModal = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.channelsSetup
  padding: 8px 20px

p
  margin: 0

.container
  height: calc(100vh - 156px)
  overflow: auto

.section-channels-cards
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  align-items: center
  gap: 40px
  margin-top: 4%

.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  text-align: center
  color: $gray-9
</style>
