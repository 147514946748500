<template>
  <a-modal :title="title" :open="open" centered :maskClosable="false" @cancel="handleClose">
    <section class="wrapper">
      <p>
        Para usarlas, desde el módulo de Workspace, selecciona el ícono <ThunderboltOutlined /> de
        para añadir una respuesta rápida.
      </p>

      <a-form layout="vertical" :model="formState">
        <a-form-item label="Título" required>
          <a-input v-model:value="formState.title" placeholder="Escribir titulo" />
        </a-form-item>

        <a-form-item label="Mensaje de respuesta rápida" required>
          <a-textarea
            v-model:value="formState.message"
            placeholder="Escribir mensaje..."
            show-count
            :maxlength="980"
            :rows="4"
          />
          <InputActionButtons
            v-model:file="formState.file"
            @select-emoji="formState.message += $event"
          />
        </a-form-item>

        <QueuePicker
          v-model:value="formState.queues"
          label="Colas asignadas"
          help="Esta respuesta rápida aparecerá en los tickets asociados a las colas seleccionadas."
          :showAll="false"
          withOutPortfolio
          multiple
          defaultBehavior
        />
      </a-form>
    </section>

    <template #footer>
      <foooter>
        <a-button @click="handleClose">Cancelar</a-button>

        <a-button type="primary" :loading="loading" :disabled="isDisabled" @click="handlePrimary">
          {{ type === "create" ? "Crear respuesta rápida" : "Guardar cambios" }}
        </a-button>
      </foooter>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { computed, reactive, readonly, ref } from "vue";
import { message } from "ant-design-vue";
import { ThunderboltOutlined } from "@ant-design/icons-vue";

import {
  QuickAnswer,
  ModalActionType,
  QuickAnswerCreateBody,
} from "@/@types/platformSettings/quickAnswers";
import { quickAnswersService } from "../../services/QuickActionsService";
import { ResponseError } from "@/app/shared/utils/httpManager";

import QueuePicker from "@/app/shared/components/pickers/QueuePicker.vue";
import InputActionButtons from "@/app/shared/components/buttons/InputActionButtons.vue";
import { cloneDeep, isEqual } from "lodash";

const props = defineProps<{
  open: boolean;
  type: ModalActionType;
  record: QuickAnswer | undefined;
}>();

const emit = defineEmits<{ "update:open": [open: boolean]; update: [] }>();

const title = computed(() => {
  let prefix = "";

  if (props.type === "create") prefix = "Crear";
  if (props.type === "edit") prefix = "Editar";

  return `${prefix} respuesta rápida`;
});

const formState = reactive<QuickAnswerCreateBody>({
  title: props.record?.title || "",
  file: props.record?.file_url || undefined,
  message: props.record?.message || "",
  queues: props.record?.queues?.length ? props.record?.queues.map((q) => q.id) : undefined,
});

const baseForm = readonly(cloneDeep(formState));

const isDisabled = computed(() => isEqual(baseForm, formState));

const handleClose = () => emit("update:open", false);

const loading = ref(false);

const handlePrimary = async () => {
  loading.value = true;

  try {
    if (props.type === "create") await quickAnswersService.create(formState);
    if (props.type === "edit") await quickAnswersService.edit(props.record?.id, formState);

    emit("update");
    handleClose();
  } catch (error) {
    console.error(error);
    let errorMessage = "Ha ocurrido un error";

    if (error instanceof ResponseError) errorMessage = error.getErrorMessage();

    message.error(errorMessage);
  }

  loading.value = false;
};
</script>

<style scoped lang="sass">
.ant-form-item
  margin-bottom: 20px

:deep(.ant-input-textarea-show-count::after)
  position: absolute !important
  bottom: 2px
  right: 8px
  font-size: 12px
  color: $gray-6

footer
  width: 100%
  display: flex
  justify-content: flex-end
  gap: 8px

.wrapper
  padding: 20px

p
  color: $text-secondary
  margin-bottom: 20px
</style>
