/**
 * Crear un token para un inbound chat
 *
 * @return {Promise<String>} El token del inbound chat
 */

export default async function () {
  const response = await fetch(import.meta.env.VITE_WEBCHAT_WIDGET_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: import.meta.env.VITE_WEBCHAT_WIDGET_API_CLIENT_SECRET,
    },
  });
  const data = await response.json();

  if (data.code === 200) {
    return data.response.token;
  } else {
    throw Error("Error al crear token");
  }
}
