<template>
  <div>
    <section v-draggable v-if="isOpen" class="floating">
      <minimized-state v-if="isMinimized" @click:maximize="maximize" />
      <expanded-state v-else @click:minimize="minimize" @click:close="close" />
    </section>
  </div>
</template>

<script>
import ExpandedState from "./states/ExpandedState.vue";
import MinimizedState from "./states/MinimizedState.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { defineComponent, ref, provide } from "vue";

import { WebPhoneState } from "@/packages/webphone/utils/enums";

export default defineComponent({
  components: {
    ExpandedState,
    MinimizedState,
  },
  setup() {
    const state = ref(WebPhoneState.closed);

    provide("currentWebPhoneState", state);

    return { state };
  },
  computed: {
    isOpen() {
      return this.state !== WebPhoneState.closed;
    },
    isMinimized() {
      return this.state === WebPhoneState.minimized;
    },
  },
  created() {
    EventBus.$on(WorkspaceEvent.DIAL_CLOSE, () => this.handleDialClose());
    EventBus.$on(WorkspaceEvent.DIAL_OPEN, () => this.handleDialOpen());
  },
  methods: {
    maximize() {
      this.state = WebPhoneState.expanded;
    },
    minimize() {
      this.state = WebPhoneState.minimized;
    },
    close() {
      EventBus.$emit(WorkspaceEvent.DIAL_CLOSE);
      this.state = WebPhoneState.closed;
    },
    handleDialClose() {
      this.state = WebPhoneState.closed;
    },
    handleDialOpen() {
      this.state = WebPhoneState.expanded;
    },
  },
});
</script>

<style lang="sass" scoped>
@import '../../../styles/main'

.floating
  right: 108px
  top: 45px
  position: absolute
  border-radius: 12px
  overflow: hidden
  width: $webphone-width
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  z-index: 10
</style>
