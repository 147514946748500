import {
  REMOVE_DATA_USER,
  SET_DATA_USER,
  SET_DATA_USER_STATE,
  SET_GLOBAL_STATE,
  SET_DATA_MINUTES_EXPIRE,
  INCREMENT_UNREAD_NOTIFICATIONS_COUNT,
  DECREMENT_UNREAD_NOTIFICATIONS_COUNT,
  READ_ALL_NOTIFICATIONS,
  SET_ALLOW_SEND_TEMPLATE,
} from "./mutation-types";

const mutations = {
  /**
   * Setear los datos del usuario logueado en el Store y localStorage.
   *
   * @param {string} dataUser Usuario logueado
   */
  [SET_DATA_USER](state, { dataUser }) {
    state.data_user = dataUser;
  },
  /**
   * Remover los datos del usuario logueado del Store y del localStorage.
   */
  [REMOVE_DATA_USER](state) {
    state.data_user = null;
  },
  [SET_DATA_MINUTES_EXPIRE](state, minutesExpire = 30) {
    state.minutes_expire = minutesExpire;
  },
  [SET_GLOBAL_STATE](state, status) {
    state.stateUser = status;
  },
  [SET_DATA_USER_STATE](state, status) {
    state.dataUserState = status;
  },
  [SET_ALLOW_SEND_TEMPLATE](state, isAllowed) {
    state.allowSendTemplate = isAllowed;
  },

  // Notifications
  [INCREMENT_UNREAD_NOTIFICATIONS_COUNT](state, count) {
    state.data_user.unread_notifications += count;
  },
  [DECREMENT_UNREAD_NOTIFICATIONS_COUNT](state, count) {
    if (state.data_user.unread_notifications > 0) {
      state.data_user.unread_notifications -= count;
    }

    if (count > state.data_user.unread_notifications) {
      state.data_user.unread_notifications = 0;
    }
  },
  [READ_ALL_NOTIFICATIONS](state) {
    state.data_user.unread_notifications = 0;
  },
};

export default mutations;
