export const tagsTableColumns = [
  {
    title: "Nombre",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Color",
    key: "color",
    dataIndex: "color",
    width: 100,
  },
  {
    title: "Acciones",
    key: "actions",
    dataIndex: "actions",
    width: 120,
  },
];
