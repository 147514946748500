<template>
  <section>
    <a @click="handleDownloadReport"> Generar reporte histórico </a>

    <a-modal
      v-model:visible="visible"
      title="Descargar reporte histórico"
      :width="500"
      centered
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <h3>{{ campaign.name }}</h3>
      <a-form layout="vertical">
        <a-row :gutter="12">
          <a-col :span="12">
            <a-form-item label="Fecha de inicio">
              <a-date-picker
                class="date-picker"
                v-model:value="dateStart"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Fecha de fin">
              <a-date-picker
                class="date-picker"
                v-model:value="dateEnd"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template v-slot:footer>
        <a-button @click="handleCancel"> Cancelar </a-button>

        <a-tooltip :title="isGlobalDownloading ? 'Tiene una descarga pendiente' : null">
          <a-button
            type="primary"
            :disabled="disabled || isGlobalDownloading"
            @click="handleOk"
            :loading="loading"
          >
            Descargar
          </a-button>
        </a-tooltip>
      </template>
    </a-modal>
  </section>
</template>

<script>
import { downloadHistoricalReport } from "@/app/supervision/services";
import { dateToString } from "@/app/shared/utils/dates";
import { downloadFile } from "@/app/shared/utils/file";
import { defineComponent } from "vue";
import { useGlobalDownloadState } from "@/app/shared/composables/useGlobalDownloadState";

export default defineComponent({
  props: {
    campaign: { required: true, type: Object },
  },
  setup() {
    const { isGlobalDownloading } = useGlobalDownloadState();

    return { isGlobalDownloading };
  },
  data() {
    return {
      visible: false,
      dateStart: undefined,
      dateEnd: undefined,
      loading: false,
    };
  },
  computed: {
    disabled() {
      return !this.dateStart || !this.dateEnd;
    },
  },
  methods: {
    handleDownloadReport() {
      this.resetFields();
      this.visible = true;
    },
    async handleOk() {
      this.loading = true;

      try {
        const campaign = this.campaign.id;
        const dateStart = dateToString(this.dateStart, "YYYY-MM-DD");
        const dateEnd = dateToString(this.dateEnd, "YYYY-MM-DD");
        const response = await downloadHistoricalReport(campaign, dateStart, dateEnd);
        const filename = `ID${this.campaign.id}-${this.campaign.name}.csv`;
        downloadFile(response, filename, { useResponseFileName: true });
      } catch (error) {
        console.error(error);
        this.$message.error("Hubo un error");
      }

      this.visible = false;
      this.loading = false;
    },
    handleCancel() {
      this.visible = false;
    },
    resetFields() {
      this.dateStart = undefined;
      this.dateEnd = undefined;
    },
  },
});
</script>

<style lang="sass" scoped>
.ant-date-picker
  width: 100%

.date-picker
  width: 100%
</style>
