import { getPaginationQuery } from "@/app/shared/utils/helpers";
import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener tickets.
 *
 * @return {Promise<Array>} Lista de tickets.
 */
export async function fetchActiveTickets({
  queue = "",
  channel = "",
  identifier = "",
  search = "",
  read = "",
  tag = "",
  page,
} = {}) {
  // queue = queue == 0 ? "" : queue;
  // channel = channel == 0 ? "" : channel;
  const paginationQuery = getPaginationQuery(page);
  const response = await httpManager.get(
    `/tickets/?${paginationQuery}&search=${search || ""}&queue=${queue || ""}&channel=${
      channel || ""
    }&read=${read}&tag=${tag}&channel_customer=${identifier || ""}`
  );
  return { tickets: response?.data?.results, nextPage: response.data?.next };
}
