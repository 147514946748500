import httpManager from "@/app/shared/utils/httpManager";

export default async function (params) {
  let query = "?";
  query += params.entity ? `entity=${params.entity}&` : "";
  query += params.campaign ? `portfolio=${params.campaign}&` : "";
  query += params.base ? `base=${params.base}&` : "";
  query += params.form ? `form=${params.form}&` : "";
  query += params.startValue ? `start_date=${params.startValue + " " + "00:00:00"}&` : "";
  query += params.endValue ? `end_date=${params.endValue + " " + "23:59:00"}` : "";
  query += params.graphic ? `&graphic=${params.graphic}` : "";

  const url = `/reports/${params.type}/${query}`;
  const response = await httpManager.get(url);
  return response?.data;
}
