export default [
  {
    path: "/register",
    name: "signup",
    component: () => import("./pages/SignupPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: true,
    },
  },
  {
    path: "/register/:token",
    name: "register",
    component: () => import("./pages/RegisterPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: true,
    },
  },
  {
    path: "/user/register/:token",
    name: "registerNewUSer",
    component: () => import("./pages/RegisterNewUserPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: true,
    },
  },
  {
    path: "/token/expired",
    name: "TokenExpired",
    component: () => import("./components/templates/TokenExpired.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: false,
    },
  },
  {
    path: "/token/invalid",
    name: "TokenInvalid",
    component: () => import("./components/templates/TokenInvalid.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: false,
    },
  },
];
