export default [
  {
    path: "/users",
    name: "users",
    component: () => import(/* webpackChunkName: "users" */ "@/app/users/pages/UsersPage.vue"),
    // children: [
    //   {
    //     path: "board",
    //     component: () =>
    //       import(/* webpackChunkName: "users" */ "@/app/users/components/templates/UsersTemplate"),
    //     meta: {
    //       requiresAuth: true,
    //     },
    //   },
    //   {
    //     path: "profiles",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "users" */ "@/app/users/components/templates/ProfilesTemplate"
    //       ),
    //     meta: {
    //       requiresAuth: true,
    //     },
    //   },
    // ],
    meta: {
      requiresAuth: true,
    },
  },
];
