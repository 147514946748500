export default [
  {
    path: "/reports-old",
    name: "reports",
    redirect: "/reports/documents",
    // component: () => import(/* webpackChunkName: "Reports" */ "@/app/reports/pages/ReportsPage"),
    // meta: {
    //   requiresAuth: true,
    // },
  },
];
