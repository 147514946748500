import httpManager, { ResponseError } from "@/app/shared/utils/httpManager";
import { ConclusionResponse } from "@/@types/integrations/service";

class SummaryService {
  baseUrl = "/summaries";

  async getTypificationSummary(
    ticketId: number,
    interactionId: number,
    originSummary: string
  ): Promise<ConclusionResponse> {
    const url = `${this.baseUrl}/`;

    const response = await httpManager.post(url, {
      ticket: ticketId,
      message_interaction: interactionId,
      origin: originSummary,
    });

    return response.data;
  }

  async registerFeedback(
    id: number,
    ranking: number,
    ranking_description: string,
    observation: string
  ): Promise<any> {
    try {
      const url = `${this.baseUrl}/${id}/`;
      const response = await httpManager.put(url, {
        ranking,
        ranking_description,
        observation,
      });
      console.log(response, "RESPONSE FEEDBACK");
      return response.data;
    } catch (error: any) {
      throw new ResponseError(error.response);
    }
  }
}

export const summaryService = new SummaryService();
