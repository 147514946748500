import {
  SET_CONTACTS,
  SET_CONTACT,
  SET_CURRENT_TABLE_PAGE,
  SET_ADVANCED_FILTERS,
  SET_BASIC_FILTERS,
  SET_ADVANCED_FILTER,
  REMOVE_CONTACT,
  UPDATE_PHONES_CONTACT,
  SET_PERSONAL_INFORMATION,
  SET_CUSTOMER_PERSONAL_DATA,
  ADD_PHONES_CONCTACT,
  SET_CUSTOM_FIELDS,
  SET_CUSTOM_DATA,
  ADD_EMAIL_CONTACT,
  SET_CONTACT_EMAIL,
  UPDATE_EMAIL_CONTACT,
  SET_CONTACT_PHONE,
  SET_CONTACT_ADDRESS,
  ADD_ADDRESS_CONTACT,
  UPDATE_ADDRESS_CONTACT,
  REMOVE_PHONE_CONTACT,
  REMOVE_ADDRESS_CONTACT,
  REMOVE_EMAIL_CONTACT,
  SET_PRODUCTS_FIELDS_CONTACT,
  SET_PRODUCTS_DATA,
  SET_PAYMENTS_DATA,
  SET_PROMISES_DATA,
  SET_TYPIFICATIONS_DATA,
  SET_RECORDS_DATA,
  SET_REFERRALS_DATA,
  ADD_REFERRALS_CONTACT,
  REMOVE_REFERRALS_CONTACT,
  UPDATE_REFERRALS_CONTACT,
  UPDATE_INFORMATION_CONTACT,
  UPDATE_INFORMATION_PERSONAL,
  SET_FILTERS_TYPIFICATIONS,
  SET_RECORD_TICKET_CLIENT,
  SET_RECORD_MESSAGE_TICKET,
  RESET_STATE_TICKET,
  DETAIL_RECORD_TICKET,
  SET_EMAILS_TAB,
} from "./mutation-types";

const mutations = {
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [SET_CONTACT](state, item) {
    state.contact = item;
  },
  [SET_CURRENT_TABLE_PAGE](state, currentPage) {
    state.currentTablePage = currentPage;
  },
  [SET_ADVANCED_FILTERS](state, filters) {
    state.advancedFilters = filters;
  },
  [SET_BASIC_FILTERS](state, filters) {
    state.basicFilters = filters;
  },
  [SET_ADVANCED_FILTER](state, item) {
    state.filterAdvanced = item;
  },
  [REMOVE_CONTACT](state, contactId) {
    const index = state.contacts.items.findIndex((item) => contactId === item.id);
    state.contacts.items.splice(index, 1);
  },
  [UPDATE_PHONES_CONTACT](state, dataPhone) {
    const index = state.contactPhone.items.findIndex((item) => dataPhone.id === item.id);
    state.contactPhone.items.splice(index, 1, dataPhone);
  },
  [SET_PERSONAL_INFORMATION](state, item) {
    state.personalInformation = item;
  },
  [SET_CUSTOMER_PERSONAL_DATA](state, item) {
    state.customerPersonalData = item;
  },
  [SET_CUSTOM_FIELDS](state, item) {
    state.contactCustomFields = item;
  },
  [SET_CUSTOM_DATA](state, item) {
    state.contactCustomData = item;
  },
  [ADD_EMAIL_CONTACT](state, dataEmail) {
    state.contactEmail.items.push(dataEmail);
  },
  [SET_CONTACT_EMAIL](state, item) {
    state.contactEmail = item;
  },
  [UPDATE_EMAIL_CONTACT](state, dataEmail) {
    const index = state.contactEmail.items.findIndex((item) => dataEmail.id === item.id);
    state.contactEmail.items.splice(index, 1, dataEmail);
  },
  [SET_CONTACT_PHONE](state, item) {
    state.contactPhone = item;
  },
  [ADD_PHONES_CONCTACT](state, dataPhone) {
    state.contactPhone.items.push(dataPhone);
  },
  [REMOVE_PHONE_CONTACT](state, contactId) {
    const index = state.contactPhone.items.findIndex((item) => contactId === item.id);
    if (index > -1) {
      state.contactPhone.items.splice(index, 1);
    }
  },
  [SET_CONTACT_ADDRESS](state, item) {
    state.contactAddress = item;
  },
  [ADD_ADDRESS_CONTACT](state, dataAddress) {
    state.contactAddress.items.push(dataAddress);
  },
  [UPDATE_ADDRESS_CONTACT](state, dataAddress) {
    const index = state.contactAddress.items.findIndex((item) => dataAddress.id === item.id);
    state.contactAddress.items.splice(index, 1, dataAddress);
  },
  [REMOVE_ADDRESS_CONTACT](state, addressId) {
    const index = state.contactAddress.items.findIndex((item) => addressId === item.id);
    if (index > -1) {
      state.contactAddress.items.splice(index, 1);
    }
  },
  [REMOVE_EMAIL_CONTACT](state, emailId) {
    const index = state.contactEmail.items.findIndex((item) => emailId === item.id);
    if (index > -1) {
      state.contactEmail.items.splice(index, 1);
    }
  },

  [SET_PRODUCTS_FIELDS_CONTACT](state, item) {
    state.contactProductsFields = item;
  },
  [SET_PRODUCTS_DATA](state, item) {
    state.contactProductsData = item;
  },
  [SET_PAYMENTS_DATA](state, item) {
    state.paymentsData = item;
  },
  [SET_PROMISES_DATA](state, item) {
    state.promisesData = item;
  },
  [SET_TYPIFICATIONS_DATA](state, item) {
    state.typificationData = item;
  },
  [SET_RECORDS_DATA](state, item) {
    state.recordData = item;
  },
  [SET_REFERRALS_DATA](state, item) {
    state.referralsData = item;
  },
  [ADD_REFERRALS_CONTACT](state, dataReferrals) {
    state.referralsData.items.push(dataReferrals);
  },
  [REMOVE_REFERRALS_CONTACT](state, referralsId) {
    const index = state.referralsData.items.findIndex((item) => referralsId === item.id);
    if (index > -1) {
      state.referralsData.items.splice(index, 1);
    }
  },
  [UPDATE_REFERRALS_CONTACT](state, dataReferrals) {
    const index = state.referralsData.items.findIndex((item) => dataReferrals.id === item.id);
    state.referralsData.items.splice(index, 1, dataReferrals);
  },
  [UPDATE_INFORMATION_CONTACT](state, contactId) {
    const index = state.contactCustomData.results.findIndex((item) => contactId.id === item.id);
    state.contactCustomData.results.splice(index, 1, contactId);
  },
  [UPDATE_INFORMATION_CONTACT](state, contactId) {
    state.contactCustomData.results = contactId;
  },
  [UPDATE_INFORMATION_PERSONAL](state, contactId) {
    state.personalInformation.results = contactId;
  },
  [SET_FILTERS_TYPIFICATIONS](state, item) {
    state.filtersTypifications = item;
  },
  [SET_RECORD_TICKET_CLIENT](state, item) {
    state.recordTicketClient = item;
    state.numberChannels = [...item.interactions];
    // if (
    //   item?.interactions?.call_interactions &&
    //   Object.keys(item?.interactions?.call_interactions)?.length > 0
    // ) {
    //   const interactions = item.interactions.messaging_interactions;
    //   state.numberChannels = [...interactions, item.interactions.call_interactions];
    // } else {
    //   state.numberChannels = item.interactions.messaging_interactions;
    // }
  },
  [SET_RECORD_MESSAGE_TICKET](state, item) {
    state.recordTicketMessageClient = item;
  },
  [RESET_STATE_TICKET](state) {
    state.recordTicketMessageClient = [];
  },
  [DETAIL_RECORD_TICKET](state, item) {
    state.recordDetailTicket = item;
  },
  [SET_EMAILS_TAB](state, item) {
    state.emailsTab = item;
  },
};

export default mutations;
