import httpManager from "@/app/shared/utils/httpManager";
/**
 * Eliminar un recordatorio.
 *
 * @param {number} reminderId Identificador del recordatorio.
 */

export async function deleteReminder(reminderId) {
  await httpManager.delete(`/schedule/reminders/${reminderId}/`);
}
