<template>
  <div class="note" v-if="show">
    <div class="head" v-if="!isAssigned">
      <UserSwitchOutlined class="icon" />
      <span>Puedes responder a este correo únicamente asignándotelo a ti. </span>
      <a-button type="link">Asignarme</a-button>
    </div>
    <div class="content">
      <h1>Añadir nota interna</h1>
      <a-textarea v-model:value="content" placeholder="Escribir aquí..." allow-clear />
    </div>
    <div class="footer">
      <a-button @click="emitNoteEvent">Cancelar</a-button>
      <a-button :disabled="isButtonEnabled()" @click="addMessage">Añadir</a-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { UserSwitchOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  show: { type: Boolean, default: false },
  isAssigned: { type: Boolean, default: false },
  email: { type: Object, default: null },
  noteDefault: { type: String, default: "" },
});

const content = ref(props.noteDefault);

const userData = computed(() => store.getters["getDataUser"]);

const isButtonEnabled = () => {
  if (content.value.length > 0) return false;
  return true;
};
const addMessage = async () => {
  emit("emitSaveNoteEvent", {
    email: props.email.id,
    user: userData.value.id,
    note: content.value,
  });
};

const emit = defineEmits(["emitSaveNoteEvent", "emitCloseNoteEvent"]);

const emitNoteEvent = () => {
  emit("emitCloseNoteEvent");
};
</script>
<style lang="sass" scoped>
.note
  display: block
  margin: 0 auto
  padding: 10px 20px
  margin: 5px 8px 0px 8px
  border-radius: 4px
  border: 1px solid orange
  padding: 10px 20px
  background-color: $white
  .head
    span
      padding-left: 5px
      color: $gray-7

  .footer
    padding: 10px 0
    display: flex
    justify-content: flex-end
    gap: 10px
</style>
<style scoped>
.content .ant-input {
  height: 110px;
}
</style>
