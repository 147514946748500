const initialState = () => ({
  activeFields: [],
  defaults: [],
  portfolio: null,
  templates: null,
});

const state = initialState();

export default state;
