<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9355 11.7168C13.7226 11.6074 12.6621 11.0879 12.4648 11.0176C12.2676 10.9434 12.123 10.9082 11.9804 11.127C11.8359 11.3438 11.4258 11.8262 11.2969 11.9727C11.1719 12.1172 11.0449 12.1348 10.832 12.0273C9.56639 11.3945 8.73631 10.8984 7.90232 9.4668C7.68162 9.08594 8.12303 9.11328 8.53514 8.29102C8.60545 8.14649 8.57029 8.02344 8.5156 7.91406C8.46092 7.80469 8.03123 6.74609 7.85154 6.31445C7.67771 5.89453 7.49803 5.95312 7.36717 5.94531C7.24217 5.9375 7.09959 5.9375 6.95506 5.9375C6.81053 5.9375 6.5781 5.99219 6.38084 6.20508C6.18357 6.42188 5.62693 6.94336 5.62693 8.00196C5.62693 9.06055 6.39842 10.0859 6.50389 10.2305C6.61326 10.375 8.02146 12.5469 10.1836 13.4824C11.5508 14.0723 12.0859 14.1231 12.7695 14.0215C13.1855 13.959 14.0429 13.502 14.2207 12.9961C14.3984 12.4922 14.3984 12.0606 14.3457 11.9707C14.2929 11.875 14.1484 11.8203 13.9355 11.7168Z"
      fill="black"
      fill-opacity="0.65"
    />
    <path
      d="M18.0709 6.60937C17.6295 5.56055 16.9967 4.61914 16.1901 3.81055C15.3834 3.00391 14.442 2.36914 13.3912 1.92969C12.317 1.47852 11.1764 1.25 10.0006 1.25H9.96154C8.77794 1.25586 7.63146 1.49023 6.55333 1.95117C5.51232 2.39648 4.57872 3.0293 3.7799 3.83594C2.98107 4.64258 2.35411 5.58008 1.92052 6.625C1.4713 7.70703 1.24474 8.85742 1.2506 10.041C1.25646 11.3965 1.58068 12.7422 2.1881 13.9453V16.9141C2.1881 17.4102 2.59044 17.8125 3.08654 17.8125H6.05724C7.26037 18.4199 8.60607 18.7441 9.96154 18.75H10.0026C11.1725 18.75 12.3072 18.5234 13.3756 18.0801C14.4205 17.6445 15.36 17.0195 16.1647 16.2207C16.9713 15.4219 17.6061 14.4883 18.0494 13.4473C18.5104 12.3691 18.7447 11.2227 18.7506 10.0391C18.7565 8.84961 18.526 7.69531 18.0709 6.60937ZM15.1197 15.1641C13.7506 16.5195 11.9342 17.2656 10.0006 17.2656H9.9674C8.78966 17.2598 7.61974 16.9668 6.58654 16.416L6.42247 16.3281H3.67247V13.5781L3.58458 13.4141C3.0338 12.3809 2.74083 11.2109 2.73497 10.0332C2.72716 8.08594 3.4713 6.25781 4.83654 4.88086C6.19982 3.50391 8.02208 2.74219 9.96935 2.73438H10.0026C10.9791 2.73438 11.9264 2.92383 12.819 3.29883C13.6901 3.66406 14.4713 4.18945 15.1432 4.86133C15.8131 5.53125 16.3404 6.31445 16.7057 7.18555C17.0846 8.08789 17.274 9.04492 17.2701 10.0332C17.2584 11.9785 16.4947 13.8008 15.1197 15.1641Z"
      fill="black"
      fill-opacity="0.65"
    />
  </svg>
</template>
