<template>
  <div class="note__information" v-if="!edit">
    <div class="head">
      <div class="user">
        <img src="../../assets/avatar-score.png" width="40" height="40" alt="Avatar de contacto" />
        <div class="information">
          <div class="name container-beetween">
            <div class="title">
              <h3>{{ props.note.userData }}</h3>
              <p>{{ messageInfo }}</p>
            </div>
            <div class="actions">
              <div class="top">
                <div class="date">
                  <span>{{ props.note?.date }}</span>
                  <span>{{ props.note?.time }}</span>
                </div>
                <ActionsPopover :actions="noteActions" @handleAction="handleAction" />
              </div>
            </div>
          </div>
          <p>{{ props.note.note }}</p>
        </div>
      </div>
    </div>
  </div>
  <NoteCard
    v-else
    :show="true"
    :isAssigned="true"
    :noteDefault="props.note.note"
    :email="props.email"
    @emitSaveNoteEvent="handleSaveNoteEvent"
    @emitCloseNoteEvent="handleCloseNoteEvent"
  />
</template>
<script lang="ts" setup>
import { ref, createVNode } from "vue";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import ActionsPopover from "@/app/emails/components/popovers/ActionsPopover.vue";
import { EmailDetails, NoteEmail } from "@/@types/emails";
import NoteCard from "@/app/emails/components/cards/NoteCard.vue";
import { Modal, message } from "ant-design-vue";
import { emailService } from "@/app/emails/services/Email.service";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

const props = defineProps<{
  note: NoteEmail;
  email: EmailDetails;
}>();

const noteActions = [
  { label: "Editar nota", icon: EditOutlined, action: "edit" },
  { label: "Eliminar nota", icon: DeleteOutlined, action: "delete" },
];

const edit = ref(false);

const messageInfo = ref("<Nota interna>");

const handleAction = (action: any) => {
  if (action.action == "edit") {
    edit.value = true;
  } else {
    deleteNote();
  }
};

const deleteNote = () => {
  Modal.confirm({
    icon: createVNode(QuestionCircleOutlined),
    title: "¿Estás seguro de eliminar esta nota?",
    okText: "Aceptar",
    cancelText: "Cancelar",
    centered: true,
    onOk: async () => {
      await emailService.deleteNote(props.note.id);
      message.success("Se ha eliminado la nota con éxito");
      emit("emitRefreshThread");
    },
  });
};

const handleCloseNoteEvent = () => {
  edit.value = false;
};

const handleSaveNoteEvent = async (data: { email: number; user: number; note: string }) => {
  console.log(props.note, "note");
  const response = await emailService.editNote(props.note.id, data);
  if (response.status == 200) {
    message.success("Se ha editado la nota con éxito");
    edit.value = false;
    emit("emitRefreshThread");
  } else {
    message.success("No se pudo editar la nota");
  }
};

const emit = defineEmits(["emitRefreshThread"]);
</script>
<style lang="sass" scoped>
p,
h3
  margin: 0
.note
  &__information
    border: 1px solid $gray-5
    border-radius: 4px
    margin: 5px 8px 0px 8px
    background: #FFF7E6
    .head
      display: block
      align-items: start
      padding: 10px 34px 10px 10px
      .user
        display: flex
        gap: 10px
        width: 100%
        .information
          width: 100%
          .name
            display: flex
            align-items: center
            gap: 10px
            h3
              margin: 0
            .title
              display: flex
              gap: 10px
              p
                font-style: italic
          .addressee
            p
              margin: 0
            .content
              padding: 15px 0
              width: 100%
              overflow-x: hidden
      .container-beetween
        display: flex
        justify-content: space-between
        width: 100%
        .actions
          .top
            display: flex
            align-items: center
            gap: 7px
            .date
              color: $gray-7
              padding-right: 5px
              display: flex
              gap: 5px
            button
              padding: 0
              outline: none
              .icon
                color: #262626
                font-size: 17px
              svg
                outline: none
.note
  ul
    list-style: none
    padding-left: 0
    margin: 0
</style>
