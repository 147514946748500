<template>
  <div class="ticket__information">
    <span v-if="isStart">
      Se inicio una interacción ”Outbound” del número ”{{ ticket.identifier }}” el {{ date }} a las
      {{ hour }}
    </span>
    <span v-else> Interacción se finalizó el {{ date }} a las {{ hour }} </span>
  </div>
</template>

<script>
import { dateToHumanFormat, dateToHumanHour } from "@/app/shared/utils/dates";

export default {
  props: {
    ticket: { required: true, type: Object },
    type: {
      required: true,
      type: String,
      validator: (value) => ["start", "end"].includes(value),
    },
  },
  computed: {
    isStart() {
      return this.type === "start";
    },
    time() {
      const ticket = this.ticket;
      return this.isStart ? ticket.created_at : ticket.closed_at;
    },
    date() {
      return dateToHumanFormat(this.time);
    },
    hour() {
      return dateToHumanHour(this.time);
    },
  },
};
</script>

<style lang="sass">
.ticket
  &__information
    font-size: 10px
    padding: 8px 20px
    text-align: center
    border-radius: 6px
    background-color: #E3E2E2
    color: $gray-9
</style>
