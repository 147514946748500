const initialState = () => ({
  portfoliosInfo: {
    pagination: {},
    items: [],
  },
  selectedEntityId: 0,
  selectedPortfolioId: 0,
  typificationDefaultType: 1,
  portfolios: [],
});

const state = initialState();

export default state;
