const getters = {
  getFilteredPortfolios(state) {
    return state.portfoliosInfo.items;
  },
  getFilteredPortfoliosInfo(state) {
    return {
      items: state.portfoliosInfo.items,
      pagination: state.portfoliosInfo.pagination,
    };
  },
  getSelectedEntity(state) {
    return state.selectedEntityId;
  },
  getSelectedPortfolio(state) {
    return state.selectedPortfolioId;
  },
  getAllPortfolios(state) {
    return state.portfolios;
  },
};

export default getters;
