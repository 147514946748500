import { fetchViewerCampaigns } from "@/app/supervision/services";
import { SET_VIEWER_CAMPAIGNS } from "./mutation-types";

const actions = {
  async fetchViewerCampaigns({ commit }, params) {
    const entity = params.entity;
    const portfolio = params?.portfolio;
    const campaings = await fetchViewerCampaigns(entity, { portfolio });
    commit(SET_VIEWER_CAMPAIGNS, campaings);
  },
  resetViewerCampaigns({ commit }) {
    commit(SET_VIEWER_CAMPAIGNS, []);
  },
};

export default actions;
