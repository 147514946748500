<template>
  <section class="black-list-container">
    <p>
      Se bloquearán los números de los teléfonos que se registren a continuación, tanto para
      gestiones entrantes como salientes.
    </p>

    <div class="controls">
      <a-form class="form" layout="vertical" :model="formState">
        <a-form-item>
          <a-input
            v-model:value="formState.search"
            placeholder="Buscar número"
            style="width: 220px"
          >
            <template #suffix>
              <SearchOutlined />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item v-if="currentTab == BlackListTab.Whatsapp">
          <IdentifierPicker
            v-model:value="formState.identifier"
            hidden-label
            :disabled="false"
            showAll
            :channel-type="ChannelType.Whatsapp"
          />
          <!-- <a-select
            v-model:value="formState.identifier"
            class="input"
            :options="[...identifiersOptions]"
          /> -->
        </a-form-item>
        <CampaignPicker
          v-else
          v-model:value="formState.portfolio"
          with-out-entity
          default-behavior
          showAll
        />
      </a-form>
      <a-button type="primary" @click="openBlockNumberModal = true">
        Bloquear nuevo teléfono
        <PlusOutlined />
      </a-button>
    </div>
    <a-tabs v-model:activeKey="currentTab">
      <a-tab-pane :key="BlackListTab.Voice" :tab="BlackListTabText[BlackListTab.Voice]">
        <BlackListTable
          ref="refTable"
          :blackListTabSelection="BlackListTab.Voice"
          :filters="formState"
        />
      </a-tab-pane>

      <!-- <a-tab-pane :key="BlackListTab.Whatsapp" :tab="BlackListTabText[BlackListTab.Whatsapp]">
        <BlackListTable
          ref="refTable"
          :blackListTabSelection="BlackListTab.Whatsapp"
          :filters="formState"
        />
      </a-tab-pane> -->

      <a-tab-pane :key="BlackListTab.Sms" :tab="BlackListTabText[BlackListTab.Sms]">
        <BlackListTable
          ref="refTable"
          :blackListTabSelection="BlackListTab.Sms"
          :filters="formState"
        />
      </a-tab-pane>
    </a-tabs>
  </section>
  <BlockNumberModal
    v-model:open="openBlockNumberModal"
    :blackListTabSelection="currentTab"
    @fetchData="handleFetchData"
  />
</template>

<script setup lang="ts">
import { SearchOutlined } from "@ant-design/icons-vue";
import { ref, inject } from "vue";
import BlockNumberModal from "@/app/platformSettings/components/modal/BlockNumberModal.vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import BlackListTable from "@/app/platformSettings/components/table/BlackListTable.vue";
import { BlackListTab, BlackListTabText } from "@/app/platformSettings/utils/enums";
import { IBlackListFilters } from "@/@types/platformSettings/blacklist";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import IdentifierPicker from "@/app/shared/components/pickers/IdentifierPicker.vue";
import { ChannelType } from "@/app/shared/utils/enums";

// const currentTab = ref<number>(1);
const currentTab = inject<BlackListTab>("currentTabBlackList") || 1;
const openBlockNumberModal = ref<boolean>(false);

const formState = ref<IBlackListFilters>({
  search: "",
  portfolio: "",
  identifier: "",
});

const refTable = ref();

const handleFetchData = () => {
  refTable.value?.fetchDataBlackList();
};
</script>

<style scoped lang="sass">
.black-list-container
  .ant-tabs-nav-wrap
    .ant-tabs-nav-list
      justify-content: flex-start

.form
  display: flex
  gap: 12px
  margin-bottom: 12px
  .input
    min-width: 160px
.controls
  display: flex
  justify-content: space-between
</style>
