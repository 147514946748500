<template>
  <ckeditor
    class="editor"
    :editor="ClassicEditor"
    :model-value="editorData"
    :config="editorConfig"
    @input="handleUpdate"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
// import { Essentials } from "@ckeditor/ckeditor5-essentials";
// import { Bold, Italic, Underline } from "@ckeditor/ckeditor5-basic-styles";
// import { Font } from "@ckeditor/ckeditor5-font";
// import { AutoLink, Link } from "@ckeditor/ckeditor5-link";
// import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
// import { Indent } from "@ckeditor/ckeditor5-indent";
// import { List } from "@ckeditor/ckeditor5-list";
// import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
// import { Table, TableToolbar } from "@ckeditor/ckeditor5-table";
// import { TextTransformation } from "@ckeditor/ckeditor5-typing";
// import { Alignment } from "@ckeditor/ckeditor5-alignment";
// import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
// import { Mention } from "@ckeditor/ckeditor5-mention";
// import { Heading } from "@ckeditor/ckeditor5-heading";

import {
  ClassicEditor,
  Essentials,
  Bold,
  Italic,
  Underline,
  Font,
  AutoLink,
  Link,
  Indent,
  List,
  TextTransformation,
  Alignment,
  Autoformat,
  Mention,
  Heading,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

// import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
// import {
//   AutoImage,
//   Image,
//   ImageCaption,
//   ImageInsert,
//   ImageResize,
//   ImageStyle,
//   ImageToolbar,
//   ImageUpload,
//   PictureEditing,
// } from "@ckeditor/ckeditor5-image";
// import { FileRepository, SimpleUploadAdapter } from "@ckeditor/ckeditor5-upload";
// import { CKBox, CKBoxImageEdit } from "@ckeditor/ckeditor5-ckbox";
const props = defineProps({
  editorData: {
    type: String,
    default: "",
  },
  feed: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["update:editorData"]);

const handleUpdate = (newData: string) => {
  emit("update:editorData", newData);
};

const editorConfig = computed(() => ({
  plugins: [
    Essentials,
    Bold,
    Italic,
    Font,
    Link,
    // Paragraph,
    Mention,
    Indent,
    List,
    // PasteFromOffice,
    // Table,
    // TableToolbar,
    TextTransformation,
    Alignment,
    Autoformat,
    // Image,
    // AutoImage,
    // ImageCaption,
    // ImageStyle,
    // ImageToolbar,
    // ImageUpload,
    // ImageInsert,
    // ImageResize,
    // PictureEditing,
    AutoLink,
    // LinkImage,
    Underline,
    Heading,
    // FileRepository,
    // CloudServices,
    // SimpleUploadAdapter,
    // CKBox,
    // CKBoxImageEdit,
  ],
  toolbar: {
    items: [
      // "paragraph",
      "heading",
      "bold",
      "underline",
      "italic",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      // "undo",
      // "redo",
      // "indent",
      // "outdent",
      "alignment",
      "numberedList",
      "bulletedList",
      // "pasteFromOffice",
      // "table",
      // "autoformat",
      "|",
      "link",
      // "insertImage",
      // "imageUpload",
      // "attachDocument",
      // "customIcon",
      // "uploadImage",
      // "|",
      // "ckbox",
      // "ckboxImageEdit",
    ],
    shouldNotGroupWhenFull: true,
  },
  mention: {
    feeds: [
      {
        marker: "#",
        minimumCharacters: 1,
        feed: props.feed,
      },
    ],
  },
  heading: {
    options: [
      {
        model: "paragraph",
        name: "Párrafo",
        title: "Párrafo",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
    ],
  },
}));
</script>

<style>
:root {
  /* Make the mention background blue. */
  --ck-color-mention-background: white;

  /* Make the mention text dark grey. */
  --ck-color-mention-text: #fa8c16;
}

.ck.ck-editor__main {
  max-height: min(calc(100vh - 400px), 600px);
  overflow-y: auto;
}
</style>
