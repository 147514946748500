<template>
  <div class="container">
    <p>
      Monitorea en tiempo real las acciones de tus usuarios en {{ ProductName }}, incluyendo escucha
      de llamadas y seguimiento de chats.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer">Click aquí</a>
      para conocer más.
    </p>

    <supervision-table-controls />

    <supervision-table ref="tableSupervision" @onClick="handleAgentClick" />

    <supervision-agent-stats
      v-if="showStats"
      :id="monitorId"
      :record="record"
      @onClose="handleClose"
      @onLogout="handleLogout"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, provide, ref, watch } from "vue";
import { ProductName } from "@/app/shared/utils/constants";

import SupervisionTableControls from "../tables/SupervisionTableControls.vue";
import SupervisionTable from "../tables/SupervisionTable.vue";
import SupervisionAgentStats from "../agent/SupervisionAgentStats.vue";

export default defineComponent({
  components: {
    SupervisionTableControls,
    SupervisionTable,
    SupervisionAgentStats,
  },
  setup() {
    const detailedView = ref(false);

    watch(detailedView, (isDetailed) => {
      const rawData = { detailedView: isDetailed };

      localStorage.setItem("monitoring", JSON.stringify(rawData));
    });

    onMounted(() => {
      const rawConfig = localStorage.getItem("monitoring");
      const config = JSON.parse(rawConfig);

      detailedView.value = config?.detailedView;
    });

    provide("detailedView", detailedView);

    return { detailedView, ProductName };
  },
  data() {
    const DOCS_URL = `${
      import.meta.env.VITE_WEB_DOC
    }/docs/score/supervision/monitoreo-de-usuarios/`;

    return {
      monitorId: null,
      record: null,
      DOCS_URL: DOCS_URL,
    };
  },
  computed: {
    showStats() {
      return !!this.monitorId;
    },
  },
  methods: {
    handleAgentClick(record) {
      this.record = record;
      this.monitorId = record.user.id;
    },
    handleLogout() {
      this.$refs.tableSupervision.showModalLogout(this.record);
    },
    handleClose() {
      this.monitorId = null;
    },
  },
});
</script>

<style lang="sass" scoped>
.container
  padding: 12px 20px

  &--open
    padding: 12px 0 12px 20px

  &__body
    display: flex
    gap: 8px
</style>
