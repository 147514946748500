import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un telefono.
 *
 * @param {number} phoneId Identificador del telefono.
 */
export async function deletePhoneClient(phoneId, client) {
  await httpManager.delete(`/workspace/tickets/phones/${phoneId}/`, { client });
}
