import httpManager from "@/app/shared/utils/httpManager";
import { toFormData } from "@/app/shared/utils/helpers";
/**
 * Editar una capaña
 *
 * @param {Object} portfolio Datos de la campaña a editar
 *
 * @return {Promise<Object>} Datos de la campaña editada
 */
export async function updatePortfolio(portfolioId, portfolio) {
  console.log(portfolio, "portfolio");
  const body = portfolio;
  if (!body.is_enable_time_acw) delete body.time_acw;
  if (!body.is_enable_call_time) delete body.call_time;
  if (!body.description) delete body.description;
  const channelCustomer = [...portfolio.channel_customer];
  delete body.channel_customer;
  if (!(body.media_negotiation instanceof File)) {
    const mediaUrl = portfolio.media_negotiation;
    delete body.media_negotiation;
    body.media_url = mediaUrl;
  }

  body.text_negotiation = body.text_negotiation || "Sin speech";
  const formData = toFormData(body, { avoidEmpty: true, avoidFalse: false });
  channelCustomer.forEach((channel) => {
    formData.append("channel_customer", channel);
  });
  const url = `/portfolios/${portfolioId}/`;
  const response = await httpManager.uploadPut(url, formData);
  return response.data;
}
