import { Interaction, Ticket } from "@/@types/global";
import httpManager from "@/app/shared/utils/httpManager";

class TicketService {
  ticketsUrl = "/tickets";
  interactionsUrl = "/interactions";

  private getTicketInteractionsUrl(ticketId: number): string {
    return `${this.interactionsUrl}/ticket/${ticketId}`;
  }

  async getDetails(id: number): Promise<Ticket> {
    // if (!id) throw Error("No ticket id specified");

    const url = `${this.ticketsUrl}/${id}/`;
    const response = await httpManager.get(url);

    return response?.data;
  }

  async getInteractionsChannels(
    ticketId: number
  ): Promise<{ messages: Interaction[]; voice: Interaction[] }> {
    const url = `${this.getTicketInteractionsUrl(ticketId)}/channels/`;
    const response = await httpManager.get(url);

    return response?.data;
  }

  async getInteractionMessages(interactionId: number) {
    const url = `${this.interactionsUrl}/${interactionId}/messages/`;
    const response = await httpManager.get(url);

    return response?.data;
  }

  async getCallInteractionsByIdentifier(
    ticketId: number,
    identifier: string
  ): Promise<Interaction[]> {
    const url = `${this.getTicketInteractionsUrl(ticketId)}/calls/?identifier=${identifier}`;
    const response = await httpManager.get(url);

    return response?.data;
  }

  async updateTicket(id: number, body: Partial<Ticket>): Promise<void> {
    const url = `${this.ticketsUrl}/${id}/`;

    await httpManager.put(url, body);
  }
}

export const ticketService = new TicketService();
