import httpManager from "@/app/shared/utils/httpManager";

import {
  fetchChatbotsList,
  createChatbot,
  fetchNodes,
  fetchNodeDetails,
  createNode,
} from "@/app/chatbots/services";

import {
  SET_CHATBOTS_LIST,
  SET_CHATBOT_NODES,
  SET_NODE_SELECTED,
} from "@/app/chatbots/store/chatbotModule/mutation-types";

const actions = {
  async fetchChatbotsList({ commit }, filters) {
    try {
      const response = await fetchChatbotsList(filters);
      commit(SET_CHATBOTS_LIST, response);
    } catch (error) {
      console.error("error", error);
    }
  },

  /**
   * Añade un nuevo chatbot
   * @param {Object} body
   */
  async createChatbot(_, body) {
    return await createChatbot(body);
  },

  async fetchNodeDetails({ commit }, nodeId) {
    try {
      const response = await fetchNodeDetails(nodeId);

      commit(SET_NODE_SELECTED, response);
    } catch (error) {
      console.log("error", error);
    }
  },

  async fetchNodes({ commit }, chatbotId) {
    try {
      const response = await fetchNodes(chatbotId);

      commit(SET_CHATBOT_NODES, response);
    } catch (error) {
      console.error("error", error);
    }
  },

  /**
   * Obtiene un chatbot
   * @param {*} context
   * @param {String} chatbotId id de chatbot
   */
  async getChatbot(context, chatbotId) {
    try {
      const url = `/bot/tree/${chatbotId}?date=${new Date().getTime()}`;
      const response = await httpManager.get(url);
      context.commit("SET_CHATBOT", response.data);
      return response.data;
    } catch (error) {
      console.error("error", error);
    }
  },
  /**Limpia tree Nodes */
  resetTreeNodes(context) {
    context.commit("SET_TREE_NODES", null);
  },
  /**Limpia los Nodes */
  resetNodes(context) {
    context.commit("SET_NODES", []);
  },
  /**
   * Agrega un nodo de opciones al chatbot
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeOptions
   * @param {String} nodeOptions.message
   * @param {String} nodeOptions.parentNodeId
   * @param {String} nodeOptions.optionNodeId - si proviene de una opcion multiple
   * @param {Array} nodeOptions.options
   * @param {Number} nodeOptions.options[].order - numero de orden de la opcion
   * @param {String} nodeOptions.options[].text
   * @param {Object} nodeOptions.action
   * @param {String} nodeOptions.action.type - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   * @param {String} nodeOptions.errorMessage
   */
  async addNodeOptions(context, { chatbotId, nodeOptions }) {
    try {
      //   const url = `/bot/tree/question/multiple/${chatbotId}`;
      //   const response = await httpManager.post(url, { ...nodeOptions });
      // await context.dispatch('getListNodesChatbot', chatbotId)
      context.commit("ADD_NODE", { ...nodeOptions });
      return { ...nodeOptions };
    } catch (error) {
      console.error("error addNodeOptions", error);
      return error.response.data;
    }
  },
  /**
   * Agrega un nodo mensaje
   * @param {*} context
   * @param {String} chatbotId
   * @param {Object} nodeMessage
   * @param {String} nodeMessage.message
   * @param {String} nodeMessage.parentNodeId - id del nodo padre que lo contiene
   * @param {String} nodeMessage.optionNodeId - si proviene de una opcion
   * @param {String} nodeMessage.action - continue, transfer, none
   * @param {String} nodeOptions.action.nodeId
   */
  async createNode(context, { chatbotId, body }) {
    try {
      const createdNode = await createNode(chatbotId, body);
      return createdNode;
    } catch (error) {
      console.error("error addNodeMessage", error);
      throw Error(error.message?.Node || "");
    }
  },
  /**
   * Agregar opcion a opciones multiples
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   * @param {Object} newOption
   */
  async addOptionToNode(context, { chatbotId, nodeId, newOption }) {
    const node = context.getters.nodes.find((n) => n._id === nodeId);
    if (node !== undefined) newOption.order = node.drawer.options.length + 1;
    else return;
    try {
      const url = `/bot/tree/question/multiple/${chatbotId}/${nodeId}`;
      const response = await httpManager.post(url, { ...newOption });
      context.commit("ADD_OPTION", { nodeId, option: response.data });
      return response.data;
      // context.dispatch('generateTreeNodes', chatbotId)
    } catch (error) {
      console.error("error", error);
      return error.response.data;
    }
  },
  /**
   * Actualiza una opcion
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId - id del nodo que lo contien
   * @param {String} idOption - id de la opcion
   * @param {Object} optionEdited - opcion editada
   */
  async updateOption(context, { chatbotId, nodeId, idOption, optionEdited }) {
    try {
      const url = `/bot/tree/question/multiple/${chatbotId}/${nodeId}/${idOption}`;
      const response = await httpManager.put(url, { ...optionEdited });
      if (response.data.idOption) {
        context.commit("UPDATE_OPTION", { nodeId, newOption: response.data });
        return { success: true, ...response.data };
      }
    } catch (error) {
      console.error("error", error);
    }
  },
  /**
   * Elimina una opcion
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo padre
   * @param {String} idOption - id de la opcion
   */
  async deleteOption(context, { chatbotId, nodeId, idOption }) {
    try {
      const url = `/bot/tree/question/multiple/${chatbotId}/${nodeId}/${idOption}`;
      const response = await httpManager.delete(url);
      context.commit("DELETE_OPTION", { nodeId, idOption });
      if (response.data && response.data.drawer && response.data.drawer.options) {
        response.data.drawer.options.forEach((option) => {
          context.commit("UPDATE_OPTION", {
            nodeId: response.data._id,
            idOption: option.idOption,
            replaceAll: false,
            specificProperties: { order: option.order },
          });
        });
      }

      return response.data;
    } catch (error) {
      console.error("error", error);
      return { success: false };
    }
  },
  /**
   * Actualiza el nodo de tipo opciones
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeOptionsEdited - nodo editado
   */
  async updateNodeOptions(context, { chatbotId, nodeId, nodeOptionsEdited }) {
    try {
      const url = `/bot/tree/question/multiple/${chatbotId}/${nodeId}`;
      const response = await httpManager.put(url, { ...nodeOptionsEdited });
      context.commit("UPDATE_NODE", { nodeId, node: response.data });
      return response.data;
    } catch (error) {
      console.error("error", error);
      return { success: false };
    }
  },
  /**
   * Actualiza el nodo de tipo mensaje
   * @param {*} context
   * @param {String} chatbotId id del chatbot
   * @param {String} nodeId - id del nodo
   * @param {Object} nodeMessage - nodo editado
   */
  async updateNodeMessage(context, { chatbotId, nodeId, nodeMessage }) {
    try {
      const url = `/bot/tree/question/message/${chatbotId}/${nodeId}`;
      const response = await httpManager.put(url, { ...nodeMessage });
      if (response.data._id) context.commit("UPDATE_NODE", { nodeId, node: response.data });

      return response.data;
    } catch (error) {
      console.error("error updateNodeMessage", error);
      return error.response.data;
    }
  },
  /**
   * Elimina un nodo mensaje u opciones
   * @param {*} context
   * @param {String} chatbotId
   * @param {String} nodeId
   */
  async deleteNode(context, { chatbotId, nodeId }) {
    try {
      const response = await httpManager.delete(
        `/bot/tree/question/message/${chatbotId}/${nodeId}`
      );
      if (response.data.success) {
        context.commit("DELETE_NODE", nodeId);
      }
      return response.data;
    } catch (err) {
      console.error("[deleteNodeOptions]", err);
      return err.response.data;
    }
  },
  /**
   * Obtiene los tickets activos del chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async getTotalTicketsOfChatbot(context, chatbotId) {
    try {
      const response = await httpManager.delete(`/bot/tree/pre-inactive/${chatbotId}`);
      return response.data;
    } catch (err) {
      console.error("[getTotalTicketsOfChatbot]", err);
      return err.response.data;
    }
  },
  /**
   * Duplicar chatbot
   * @param {*} context
   * @param {String} chatbotId
   */
  async duplicateChatbot(context, chatbotId) {
    try {
      const response = await httpManager.post(`/bot/tree/clon/${chatbotId}`);
      if (response.data.success) {
        const chatbot = response.data.chatbot;
        chatbot.isCopy = true;
        context.commit("ADD_CHATBOT", response.data.chatbot);
      }
      return response.data;
    } catch (err) {
      console.error("[duplicateChatbot]", err);
      return err.response.data;
    }
  },
};

export default actions;
