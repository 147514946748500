const initialState = () => ({
  strategyTypes: [
    { id: 1, name: "Predictivo" },
    { id: 2, name: "Progresivo" },
    { id: 3, name: "SMS" },
    { id: 4, name: "IVR" },
    { id: 5, name: "Whatsapp" },
    { id: 6, name: "Correo Masivo" },
  ],
});

const state = initialState();

export default state;
