import httpManager, { ResponseError } from "@/app/shared/utils/httpManager";

class IntegrationManager {
  private resource = "/settings/customer/";

  async getCustomerSetting() {
    try {
      const response = await httpManager.get(this.resource);
      console.log(response.data, "INTEGRATION RESPONSE");
      return response.data;
    } catch (error: any) {
      throw new ResponseError(error.response);
    }
  }

  async updateCustomerSetting(gpt_token: string, enable_summary: boolean) {
    try {
      const body = {
        gpt_token,
        enable_summary,
      };
      const response = await httpManager.put(this.resource, body);
      return response.data;
    } catch (error: any) {
      throw new ResponseError(error.response);
    }
  }
}

export const integrationManager = new IntegrationManager();
