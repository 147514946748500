export const defaultColors = [
  "4F0174",
  "74080A",
  "E00E14",
  "FE3B75",
  "FE75A0",
  "F612E1",
  "E676F6",
  "B64CE3",
  "904FA9",
  "7045F8",
  "002CDE",
  "76A8FB",
  "2E8CD4",
  "2C76AB",
  "109FEE",
  "13B3CC",
  "20B393",
  "2EBD67",
  "F8D432",
  "FA991A",
  "FE6E20",
  "E14919",
  "ACB4BA",
  "5B6B78",
];
