<template>
  <div class="tags-container">
    <p>
      Crea etiquetas. Estas podrán ser asignadas a tus tickets para ayudarte a organizarlos mejor.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para conocer
      más.
    </p>

    <a-row justify="space-between" align="center">
      <a-col>
        <a-form layout="inline">
          <a-form-item>
            <a-input v-model:value="searchValue" class="search-input" placeholder="Buscar nombre">
              <template #prefix>
                <SearchOutlined class="search-input__icon" />
              </template>
            </a-input>
          </a-form-item>
        </a-form>
      </a-col>

      <a-col>
        <a-button type="primary" @click="handleOpenAddModal">Crear nueva etiqueta</a-button>
      </a-col>
    </a-row>

    <tags-table
      style="margin-top: 16px"
      :data="data"
      :loading="loading"
      @on-tag-edit="handleOpenEditModal"
      @update="handleSearch"
    />
  </div>

  <AddNewTagModal :visible="addModalVisible" @update="handleSearch" @on-close="handleCloseModal" />

  <EditTagModal
    v-if="editModalConfig.visible"
    :visible="editModalConfig.visible"
    :tagData="editModalConfig.tagData"
    @update="handleSearch"
    @on-close="handleCloseModal"
  />
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { SearchOutlined } from "@ant-design/icons-vue";

import _ from "lodash";

import TagsTable from "../table/TagsTable.vue";
import AddNewTagModal from "../modal/AddNewTagModal.vue";
import EditTagModal from "../modal/EditTagModal.vue";

defineProps({
  data: Array,
});
const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/ajustes-en-la-plataforma/workspace/`;

const route = useRoute();
const router = useRouter();
const store = useStore();

const loading = ref(false);
const searchValue = ref("");
const openAddModal = route.query.openAddModal;

const handleSearch = async () => {
  loading.value = true;

  await store.dispatch("fetchTags", searchValue.value);

  loading.value = false;
};

const addModalVisible = ref(openAddModal);
const editModalConfig = reactive({ visible: false, tagData: null });

const handleOpenAddModal = () => {
  addModalVisible.value = true;
};

const handleOpenEditModal = (tagData) => {
  editModalConfig.visible = true;
  editModalConfig.tagData = tagData;
};

const handleCloseModal = () => {
  addModalVisible.value = false;
  editModalConfig.visible = false;

  if (openAddModal) router.push("/settings");
};

watch(
  searchValue,
  _.debounce(() => handleSearch(), 500)
);
</script>

<style lang="sass" scoped>
p
  color: $gray-8
  margin-bottom: 16px

.tags-container
  // border: 1px solid $gray-5
  // border-radius: 4px
  // padding: 12px 20px
  // width: 615px

.tags-title
  font-size: 16px
  font-weight: 600

.search-input
  width: 265px

  &__icon
    color: $gray-6
</style>
