import axios from "@/plugins/axios";
import { tokenManager } from "@/app/shared/utils/tokenManager";

export const fetchKeepAlive = (path = "", method = "GET", data = {}) => {
  const axiosBaseUrl = axios.defaults.baseURL;
  const bearerToken = tokenManager.getToken();

  console.log("This should keep alive the request even if the tab es closed", {
    path,
    method,
    data,
  });

  fetch(`${axiosBaseUrl}${path}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(data),
    keepalive: true,
  });
};
