<template>
  <a-table
    rowClassName="table"
    :data-source="data"
    :columns="columns"
    row-key="id"
    size="middle"
    :pagination="false"
    :scroll="{ x: 1500 }"
  >
    <template #queue_name="{ text, record }">
      <span> {{ text || `Cola ${record.queue}` }} </span>
    </template>

    <template #mean_service_level="{ text }">
      <span> {{ text }}% </span>
    </template>

    <template #time="{ text: time }">
      {{ formatTime(time) }}
    </template>

    <template #actions="{ record }">
      <ViewerChatsDrawer
        v-if="record.queue_name !== 'Total'"
        :portfolio="campaign.id"
        :record="record"
      />
    </template>
  </a-table>
</template>

<script>
// TODO-JOHN: Refactor, remover drawer de la tabla
import { defineComponent } from "vue";

import { columns } from "./ViewerChatsTableColumns";
import { formatChatQueueTableData } from "@/app/supervision/utils/helpers";

import ViewerChatsDrawer from "@/app/supervision/components/drawer/ViewerChatsDrawer.vue";

export default defineComponent({
  components: {
    ViewerChatsDrawer,
  },
  props: {
    campaign: { required: true, type: Object },
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    data() {
      return this.campaign?.queues?.chats.length
        ? formatChatQueueTableData({ rawData: this.campaign?.queues?.chats })
        : [];
    },
  },
  methods: {
    formatTime(time) {
      return time?.split(".")?.[0];
    },
  },
});
</script>

<style lang="sass" scoped>
:deep(.table.ant-table-row:last-child)
  background-color: $gray-2

:deep(.table.ant-table-row:last-child .ant-table-cell-fix-right)
  background-color: $gray-2 !important

:deep(.table.ant-table-cell-fix-left)
  background-color: $gray-2 !important
</style>
