const getters = {
  getEntityList(state) {
    return state.entitiesInfo.items;
  },
  getEntityInfo(state) {
    return {
      items: state.entitiesInfo.items,
      pagination: state.entitiesInfo.pagination,
    };
  },
};

export default getters;
