<template>
  <a-modal
    class="modal-sent"
    v-model:visible="showModal"
    title="Enviar instrucciones"
    width="600px"
    centered
    closable
    :footer="null"
  >
    <div class="modal-content">
      <h3 class="text-center">¡Listo, correo enviado!</h3>
      <img src="@/app/channels/components/image/mail.png" alt="mail" />
    </div>
    <div class="close-button">
      <a-button @click="onClose"> Cerrar </a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { ref } from "vue";

const showModal = ref(false);

const onOpen = () => {
  showModal.value = true;
};

defineExpose({
  onOpen,
});

const onClose = () => {
  showModal.value = false;
};
</script>

<style lang="sass" scoped>
.modal-content
  display: flex
  flex-direction: column
  align-items: center

.modal-sent
  h3
    font-size: $font-medium
    font-weight: 600

.modal-content
  margin: 40px 0

.close-button
  display: flex
  justify-content: flex-end
</style>
