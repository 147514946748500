<template>
  <CustomScrollbar height="400px" style="padding: 12px">
    <a-form class="form" layout="vertical" ref="formRef" :model="formState">
      <template v-for="(item, index) in formState.rules" :key="`rule_${index}`">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              class="labelWhole"
              label="Nombre de la regla"
              :name="['rules', index, 'name']"
              :rules="rules.name"
            >
              <a-input
                v-model:value="item.name"
                placeholder="Escribir nombre de la regla"
                :disabled="!canEdit"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              class="labelHalf"
              :name="['rules', index, 'prefix_provider']"
              :rules="rules.prefix_provider"
            >
              <template #label>
                Prefijo del proveedor (opcional)
                <a-tooltip title="Este dato lo facilita tu troncal">
                  <InfoCircleOutlined class="mrg-left-4 form__label-icon" />
                </a-tooltip>
              </template>
              <a-input
                v-model:value="item.prefix_provider"
                placeholder="Escribir prefijo de proveedor"
                :disabled="!canEdit"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              class="labelHalf"
              :name="['rules', index, 'prefix_dial']"
              :rules="rules.prefix_dial"
            >
              <template #label>
                Prefijo de marcación (opcional)
                <a-tooltip title="Este dato lo facilita tu troncal">
                  <InfoCircleOutlined class="mrg-left-4 form__label-icon" />
                </a-tooltip>
              </template>
              <a-input
                v-model:value="item.prefix_dial"
                placeholder="Escribir prefijo de marcación"
                :disabled="!canEdit"
                :maxlength="4"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item class="labelHalf" :name="['rules', index, 'rule']" :rules="rules.rule">
              <template #label>
                Regla de marcación
                <a-popover title="Regla de marcación">
                  <template #content>
                    <p class="mrg-bottom-0" style="width: 200px">
                      Establezca la regla en base a cómo los usuarios de su plataforma realizarán la
                      marcación en el teclado (dialpad). Coloque el número de “X” según el número de
                      dígitos que se marcarán, agregando los dígitos normados por la región de
                      destino.
                      <br />
                      <br />
                      Ejm: Si los números a marcar siempre inician con 9 y cuentan con 9 digitos,
                      escriba así: 9XXXXXXXX
                    </p>
                  </template>
                  <InfoCircleOutlined class="mrg-left-4 form__label-icon" />
                </a-popover>
              </template>
              <a-input v-model:value="item.rule" placeholder="01 XXXXXXXXX" :disabled="!canEdit">
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item
              class="labelHalf"
              label="Precio del destino (opcional)"
              :name="['rules', index, 'price']"
              :rules="rules.price"
            >
              <a-input-number
                v-model:value="item.price"
                placeholder="Escribir precio destino"
                :disabled="!canEdit"
                :min="0"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="index > 0" type="flex" align="end">
          <a-button danger type="link" :disabled="!canEdit" @click="removeRule(index)">
            Eliminar regla
          </a-button>
        </a-row>
        <a-divider class="form__divider" />
      </template>
      <p class="text--center">
        <a-button type="link" :disabled="!canEdit" @click="addNewRule">
          Agregar otra regla de marcado
          <PlusOutlined class="mrg-left-4" />
        </a-button>
      </p>
    </a-form>
  </CustomScrollbar>
</template>
<script lang="ts" setup>
import { ref, reactive, computed, onMounted } from "vue";
import { DialRule, FormStateDialPlan, FormStateRule } from "@/@types/pbx/dialPlan";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { UserData } from "@/@types/global";
import { ProfileTypes } from "@/app/shared/utils/enums";
const store = useStore();

const formRef = ref();
const formState = reactive<Partial<FormStateDialPlan>>({ rules: [] });

const rules = {
  name: [{ required: true, message: "Campo requerido" }],
  prefix_provider: [{ required: false, message: "Campo requerido" }],
  prefix_dial: [
    { required: false, message: "Campo requerido" },
    { pattern: /[0-9]/g, message: "Solo números" },
  ],
  rule: [{ required: true, message: "Campo requerido" }],
  price: [{ required: false, message: "Campo requerido" }],
};

onMounted(() => {
  addNewRule();
});

const isSupportUser = computed(() => {
  const userData = store.getters["getDataUser"] as UserData | undefined;

  return userData?.profile === ProfileTypes.Support;
});

const isSecuritecTrunk = computed(() => Number(formState.trunk) === 1);

const canEdit = computed(() => (isSupportUser.value ? true : !isSecuritecTrunk.value));

const addNewRule = () => {
  const rule: DialRule = {
    name: "",
    prefix_provider: "",
    prefix_dial: undefined,
    rule: "",
    price: undefined,
  };
  if (!formState.rules) formState.rules = [];
  formState.rules.push(rule);
};

const removeRule = (index: number) => {
  formState?.rules?.splice(index, 1);
};

const onSubmit = (callback: (vals: FormStateRule, isLast: boolean) => void) => {
  formRef.value.validate().then((values: FormStateRule) => {
    console.log({ formState, values });
    callback({ ...formState, ...values }, true);
  });
};

const onReset = () => {
  console.log(formRef);
  formRef.value.resetFields();
};

const onSetValues = (values: FormStateRule) => {
  Object.assign(formState, values);
  if (!values.rules || !values.rules.length) addNewRule();
};
const onGetValues = () => {
  return formState;
};

defineExpose({ onSubmit, onReset, onSetValues, onGetValues });
</script>
<style lang="sass" scoped>
.form
  padding: 12px 0px
  &__divider
    margin: 0px 0px 12px 0px
  &__label-icon
    font-size: 12px!important
    color: $text-secondary
    margin-bottom: -3px
</style>
