const getters = {
  getQueueList(state) {
    return state.queuesInfo.items;
  },
  getQueueInfo(state) {
    return {
      items: state.queuesInfo.items,
      pagination: state.queuesInfo.pagination,
    };
  },
};

export default getters;
