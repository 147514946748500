import { SET_TEXT_CONNECTION_CALL, SET_TEXT_SEQUENCE } from "./mutation-types";

const mutations = {
  [SET_TEXT_CONNECTION_CALL](state, currentStrategyTag) {
    state.currentStrategyTag = currentStrategyTag;
  },
  [SET_TEXT_SEQUENCE](state, currentSequenceTag) {
    state.currentSequenceTag = currentSequenceTag;
  },
};

export default mutations;
