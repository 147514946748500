export const SET_TABLE_PAGE = "SET_TABLE_PAGE";
export const SET_BASIC_FILTERS = "SET_BASIC_FILTERS";
export const SET_TICKETS_ADVANCED_FILTERS = "SET_TICKETS_ADVANCED_FILTERS";
export const SET_TICKETS = "SET_MANAGER_TICKETS";
export const SET_TAG_TO_TICKETS = "SET_TAG_TO_TICKETS";
export const RESOLVE_TICKETS = "RESOLVE_TICKETS";
export const RESOLVE_SINGLE_TICKET = "RESOLVE_SINGLE_TICKET";
export const EDIT_TICKET_TAGS = "EDIT_TICKET_TAGS";
export const SET_TRANSFER_DATA = "SET_TRANSFER_DATA";
export const TRANSFER_TICKET_TO_AGENT = "TRANSFER_TICKET_TO_AGENT";
export const TRANSFER_TICKET_TO_QUEUE = "TRANSFER_TICKET_TO_QUEUE";
export const SET_ADVANCED_FILTERS_DATA = "SET_ADVANCED_FILTERS_DATA";
export const SET_USERS_DATA = "SET_USERS_DATA";
export const SET_TICKETS_MANAGER_CHANNELS = "SET_TICKETS_MANAGER_CHANNELS";
export const SET_LOADING = "SET_LOADING";
