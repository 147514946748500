export const inboxTableColumns = [
  {
    title: "ID",
    key: "number",
    dataIndex: "number",
    width: 40,
    // slots: { customRender: "number" },
    // fixed: "left",
  },
  {
    title: "Estado",
    dataIndex: "statusInbox",
    key: "statusInbox",
    width: 50,
    // fixed: "left",
  },
  {
    title: "Contacto",
    dataIndex: "email",
    key: "email",
    width: 100,
    // fixed: "left",
  },
  {
    title: "Asunto - vista previa del correo",
    dataIndex: "subject",
    key: "subject",
    width: 200,
    // fixed: "left",
    // customCell: (_, index) => ({
    //   colSpan: index == 0 ? 2 : 1,
    // }),
  },
  {
    title: "Hora",
    dataIndex: "time",
    key: "time",
    width: 90,
    align: "left",
    // customCell: (_, index) => ({
    //   colSpan: index == 0 ? 0 : 1,
    // }),
  },
];
