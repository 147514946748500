<template>
  <CustomScrollbar width="100%" height="calc(87vh - 0px)">
    <SettingProfile
      :profile="profile"
      @update="handleUpdatedProfile"
      v-if="profile"
      ref="form"
      @onChangeInput="handleChangeInput"
    />
    <SettingBusiness
      :business="business"
      v-if="user?.is_owner && profile && business"
      @update="handleUpdatedBusiness"
      ref="formRef"
      @onChangeInputBusiness="handleChangeInputBusiness"
    />
  </CustomScrollbar>
  <div class="footer">
    <a-button
      type="primary"
      @click="handleSave"
      :disabled="!changedInputProfile && !changedInputBusiness"
      :loading="loading"
      >Guardar</a-button
    >
    <a-button style="margin-right: 10px; margin-left: 10px" @click="handleConfirmationProfile"
      >Cancelar</a-button
    >
    <p
      style="font-style: italic; color: #bfbfbf; margin-top: -12px"
      v-if="changedInputProfile || changedInputBusiness"
    >
      No olvides guardar tus cambios
    </p>
  </div>
</template>
<script>
import SettingProfile from "@/app/settingsMenu/components/myAccount/SettingProfile.vue";
import SettingBusiness from "@/app/settingsMenu/components/myAccount/SettingBusiness.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { fetchLoggedUser, fetchLoggedBusiness } from "@/app/settings/services";
import { defineComponent, ref, computed, createVNode } from "vue";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
export default defineComponent({
  components: {
    SettingProfile,
    SettingBusiness,
    CustomScrollbar,
  },
  setup() {
    const store = useStore();
    const formRef = ref();
    const user = computed(() => store.getters["getDataUser"]);
    return {
      formRef,
      user,
    };
  },
  data() {
    return {
      profile: undefined,
      business: undefined,
      changedInputProfile: false,
      changedInputBusiness: false,
      loading: false,
    };
  },
  watch: {
    user: {
      handler: function () {
        this.fetchProfile();
        this.fetchBusiness();
      },
      immediate: true,
    },
  },
  methods: {
    handleChangeInput($event) {
      this.changedInputProfile = $event;
    },
    handleChangeInputBusiness($event) {
      this.changedInputBusiness = $event;
    },
    async fetchProfile() {
      this.profile = await fetchLoggedUser(this.user);
    },
    async fetchBusiness() {
      this.business = await fetchLoggedBusiness();
    },
    handleSave() {
      try {
        if (this.changedInputProfile) {
          this.loading = true;
          this.$refs.form.handleSubmit();
        }
        if (this.changedInputBusiness) {
          this.$refs.formRef.handleSubmit();
        }
        EventBus.$emit(WorkspaceEvent.HIDE_CONFIRM_MODAL);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleUpdatedProfile() {
      this.loading = false;
      if (this.changedInputProfile) {
        this.fetchProfile();
        this.$notification.success({
          message: "Perfil actualizado",
          description: "Se han aplicado los cambios exitosamente",
        });
      }
    },
    async handleUpdatedBusiness() {
      if (this.changedInputBusiness) {
        await this.fetchBusiness();
        this.$notification.success({
          message: "Datos de empresa actualizados",
          description: "Se han aplicado los cambios exitosamente",
        });
      }
    },
    handleConfirmationProfile() {
      if (this.changedInputProfile || this.changedInputBusiness) {
        Modal.confirm({
          title: "¿Deseas descartar los cambios realizados?",
          content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleCancel(),
          centered: true,
          okText: "Sí, descartar cambios",
          cancelText: "No, seguir editando",
        });
      } else {
        this.handleCancel();
      }
    },
    handleCancel() {
      this.$store.dispatch("setMyAccountViewStatus", false);
    },
  },
});
</script>
<style lang="sass" scoped>
.scroll-area
  margin: 0 !important

.footer
  display: flex
  flex-direction: row-reverse
  margin-right: 30px
  margin-left: 30px
  border-top: 1px solid $gray-4
  align-items: baseline
  height: 56px
  padding-top: 20px
  margin-bottom: 16px

.margin--top
  margin-top: 240px
</style>
