import { getCurrentDateTime } from "@/app/shared/utils/helpers";

export function downloadFile(data, fallbackFilename = "", { useResponseFileName = false } = {}) {
  let blob = useResponseFileName ? data?.data : data;

  if (typeof blob === "string") blob = new Blob([blob], { type: "text/plain" });

  const resposeFileName =
    data?.headers?.["content-disposition"]?.split("filename=")?.[1] ||
    `${getCurrentDateTime()}-${fallbackFilename}`;

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", resposeFileName);
  document.body.appendChild(link);
  link.click();
}
