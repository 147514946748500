const initialState = () => ({
  customer: null,
  temporalUser: null,
  newUserInvite: null,
  newUser: null,
  step: 0,
  form: null,
  registerStep: 0,
  registerWorkspaceStep: 1,
});

const state = initialState();

export default state;
