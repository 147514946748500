export const columns = [
  {
    dataIndex: "created_at",
    width: 180,
    slots: { title: "customDate", customRender: "created_at" },
    sorter: (a, b) => {
      if (a.created_at < b.created_at) return -1;
      if (a.created_at > b.created_at) return 1;
      return 0;
    },
  },
  {
    title: "Ticket ID",
    dataIndex: "id",
    width: 120,
    slots: { customRender: "ticketId" },
  },
  {
    title: "Última vez gestionado por:",
    dataIndex: "user_assigned",
    width: 200,
    slots: { customRender: "user_assigned" },
  },
  {
    title: "Canales interactuados",
    dataIndex: "phone_operator",
    width: 180,
    slots: { customRender: "channelInteractive" },
  },
  {
    title: "Acciones",
    key: "action",
    width: 100,
    fixed: "right",
    slots: { customRender: "actionScoped" },
  },
];
