<template>
  <div>
    <a-button type="text" @click.prevent="openModal" :disabled="disabled">
      <RobotOutlined class="icon" />
      Ver variables capturadas
    </a-button>

    <ModalTicketVars :visible="isVisible" :dataSource="props.ticketData" @onClose="closeModal" />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { RobotOutlined } from "@ant-design/icons-vue";
import ModalTicketVars from "@/app/workspace/components/modal/ModalTicketVars.vue";

const props = defineProps({
  ticketData: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isVisible = ref(false);

const openModal = () => {
  isVisible.value = true;
};

const closeModal = () => {
  isVisible.value = false;
};
</script>
