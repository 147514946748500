const initialState = () => ({
  contacts: {
    pagination: {},
    items: [],
  },
  contact: {},
  currentTablePage: 1,
  basicFilters: {},
  advancedFilters: {},
  filterAdvanced: {},
  personalInformation: [],
  customerPersonalData: {},
  contactCustomFields: [],
  contactCustomData: {},
  contactEmail: {},
  contactPhone: {},
  contactAddress: {},
  contactProductsFields: [],
  contactProductsData: {},
  paymentsData: [],
  promisesData: [],
  typificationData: {},
  recordData: {},
  referralsData: {},
  filtersTypifications: {},
  recordTicketClient: {},
  recordTicketMessageClient: {},
  numberChannels: [],
  recordDetailTicket: [],
  emailsTab: {},
});

const state = initialState();

export default state;
