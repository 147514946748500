<template>
  <div class="card">
    <a-button class="button" type="link" @click="open = true">
      <BarsOutlined />
      {{ payload.globalButtons[0].title }}
    </a-button>
  </div>

  <a-modal
    v-model:open="open"
    :title="payload.globalButtons[0].title"
    centered
    width="360px"
    :bodyStyle="{ padding: '20px 0 8px' }"
  >
    <div class="section" v-for="section in payload.items" :key="section.title">
      <h2>{{ section.title }}</h2>

      <div class="item" v-for="item in section.options" :key="item.title">
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </div>
    </div>

    <template #footer>
      <div class="footer">
        <a-button key="back" @click="open = false">Cerrar lista</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { BarsOutlined } from "@ant-design/icons-vue";

import { ListMessage } from "@/@types/workspace/chats/message";

defineProps<{ payload: ListMessage }>();

const open = ref(false);
</script>

<style scoped lang="sass">
h2, h3, p
  margin: 0

.button
  padding: 0
  height: 22px
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.card
  width: 100%
  direction: ltr
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.15)
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)

.section
  margin-bottom: 16px

h2
  color: $text-tertiary
  font-size: 14px
  font-weight: 600
  line-height: 22px
  width: 100%
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)

.item
  padding: 8px 4px
  border-bottom: 1px solid rgba(0, 0, 0, 0.15)

h3
  color: $gray-8
  font-size: 14px
  font-weight: 400
  line-height: 22px

p
  color: $gray-7
  font-size: 12px
  line-height: 20px

.footer
  display: flex
  justify-content: center
</style>
