export default [
  {
    path: "/strategies",
    name: "strategies",
    redirect: "/operations/strategies",
    // component: () =>
    //   import(/* webpackChunkName: "strategies" */ "@/app/strategies/pages/StrategiesPage"),
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/strategies/filter",
    redirect: "/operations/strategies/filter",
    // component: () =>
    //   import(/* webpackChunkName: "strategies" */ "@/app/strategies/pages/StrategiesFilterPage"),
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/strategies/template",
    redirect: "/operations/strategies/template",
    // component: () =>
    //   import(/* webpackChunkName: "strategies" */ "@/app/strategies/pages/StrategiesTemplatePage"),
    // meta: {
    //   requiresAuth: true,
    // },
  },
];
