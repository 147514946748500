import { uniqBy } from "lodash";

const getters = {
  getQueueState(state) {
    return state;
  },
  getAgentQueue(state) {
    return state.agentsQueue;
  },
  getAgentQueueProfile(state) {
    const profiles = state.agentsQueue.map((agent) => ({
      name: agent?.profile_name,
      icon: agent?.icon,
    }));

    return uniqBy(profiles, (obj) => obj.name);
  },
};

export default getters;
