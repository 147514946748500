import httpManager from "@/app/shared/utils/httpManager";

/**
 * Generar una estrategia por filtros.
 *
 * @param {Object} strategy Datos de la estrategia.
 *
 * @return {Promise<Object>} Estrategia creada.
 */
export async function generateStrategyByFilters(strategy) {
  strategy.type_origin = 1;
  const response = await httpManager.post("/strategies/", strategy);
  return response?.data;
}
