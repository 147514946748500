const getters = {
  getTypificationTree(state) {
    return state.typification;
  },
  getTypificationPortfolioName(state) {
    return state.portfolioName;
  },
  getTypificationPortfolioBusinessType(state) {
    return state.businessType;
  },
  getTypificationDefault(state) {
    return state.typeDefaultManagement;
  },
  getTypificationSaved(state) {
    return state.typificationSaved;
  },
  isTypificationTreeMutating(state) {
    return state.isTypificationTreeMutating;
  },
  getLastModification(state) {
    return state.lastModification;
  },
  getForms(state) {
    return state.forms;
  },
  getTypificationDisabled(state) {
    return state.typificationDisabled;
  },
};

export default getters;
