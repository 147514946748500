const initialState = () => ({
  dialplansInfo: {
    pagination: {},
    items: [],
  },
  rules: [],
  pricesTrunk: [],
});

const state = initialState();

export default state;
