import { fetchDataUser } from "@/app/auth/services";

import {
  SET_DATA_USER,
  SET_DATA_MINUTES_EXPIRE,
  SET_DATA_USER_STATE,
  SET_GLOBAL_STATE,
  SET_ALLOW_SEND_TEMPLATE,
} from "./mutation-types";

const actions = {
  /**
   * Obtener los datos del usuario logueado a la aplicación.
   */
  async fetchDataUser({ commit }) {
    const dataUser = await fetchDataUser();
    console.log(dataUser, "data user");
    commit(SET_DATA_USER, { dataUser });
    commit(SET_DATA_MINUTES_EXPIRE, dataUser.customer.minutes_to_expire);
    commit(SET_DATA_USER_STATE, dataUser.state);
    commit(SET_ALLOW_SEND_TEMPLATE, dataUser.allow_send_template);
  },
  updateGlobalState({ commit }, status) {
    if (!status) return;
    commit(SET_GLOBAL_STATE, status);
  },
  updateUserState({ commit }, status) {
    commit(SET_DATA_USER_STATE, status);
  },
  setMinutesExpire({ commit }, minutesExpire) {
    commit(SET_DATA_MINUTES_EXPIRE, minutesExpire);
  },
};

export default actions;
