<template>
  <a-modal
    v-model:visible="visible"
    width="936px"
    :bodyStyle="{ height: showFooter === undefined ? '592px' : '645px' }"
    style="top: 50px"
    :footer="showFooter"
    @cancel="handleConfirmationClose"
  >
    <section v-if="!isIntegrationCompleted" class="steps">
      <CustomStepper
        :value="current"
        @update:value="current = $event"
        :items="steps"
        readonly
        :itemWidth="250"
      />
    </section>
    <div v-if="!isIntegrationCompleted" class="channelsSetup">
      <div v-if="current == 0">
        <div class="container">
          <p class="title">Selecciona un proveedor para integrar WhatsApp Business API</p>
          <div class="section-channels-cards">
            <CardWhatsappProvider
              v-for="providerWhatsapp in providerWhatsappConfig"
              :key="providerWhatsapp.id"
              :provider-whatsapp="providerWhatsapp"
              @onNextStep="handleNext"
            />
          </div>
          <p class="description">
            ¿No encuentras el proveedor que deseas? Escribenos a
            <strong>feedback@beexcc.com</strong> y cuentanos sobre él
          </p>
        </div>
      </div>
      <div v-else>
        <TwilioWhatsapp
          ref="twilio"
          v-if="provider == 1"
          :step="current"
          @onIntegrationFinished="handleIntegrationFinished"
          @disabled="isDisabled = $event"
        />
        <SecuritecWhatsapp
          ref="securitec"
          v-else-if="provider == 4"
          :step="current"
          typemodal="crear"
          @close="handleClose"
          @onIntegrationFinished="handleIntegrationFinished"
          @disabled="isDisabled = $event"
        />
      </div>
    </div>
    <div v-else>
      <CompleteIntegration :text-complete="textComplete" type="whatsapp" />
    </div>
    <template v-slot:footer>
      <a-button @click="handleBack"> Volver </a-button>
      <a-button type="primary" :disabled="isDisabled" :loading="isLoading" @click="handleOk">
        {{ current == 1 ? "Continuar" : "Integrar" }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import CardWhatsappProvider from "@/app/channels/components/cards/CardWhatsappProvider.vue";
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import CustomStepper from "@/app/shared/components/organisms/CustomStepper.vue";
import TwilioWhatsapp from "@/app/channels/components/modals/whatsapp/TwilioWhatsap.vue";
import SecuritecWhatsapp from "@/app/channels/components/modals/whatsapp/SecuritecWhatsapp.vue";
import { whatsappProviders } from "@/app/channels/utils/constants";
import { landingLink } from "@/app/shared/utils/url";
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "WhatsappChannels",
  components: {
    CardWhatsappProvider,
    CompleteIntegration,
    CustomStepper,
    TwilioWhatsapp,
    SecuritecWhatsapp,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isIntegrationCompleted: false,
      showConfirmModal: false,
      isLoading: false,
      textComplete: {
        channel: "whatsapp",
        title: "WhatsApp Business API",
        description: "Tu canal de WhatsApp está listo para su implementación",
      },
      landingLink,
      providerWhatsappConfig: whatsappProviders,
      current: 0,
      steps: ["Selecciona", "Completa datos", "Finaliza"],
      provider: undefined,
      isDisabled: false,
    };
  },
  computed: {
    visible() {
      return this.open;
    },
    showFooter() {
      if (this.current == 0) {
        return null;
      } else if (this.current == 2 && this.provider == 4) {
        return null;
      } else if (this.isIntegrationCompleted) {
        return null;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    handleIntegrationFinished() {
      this.isLoading = false;
      this.isIntegrationCompleted = true;
    },
    async handleConfirmationClose() {
      let valid = false;
      if (this.provider == 1) {
        valid = await this.$refs.twilio.showConfirmModal;
      } else if (this.provider == 4) {
        valid = await this.$refs.securitec?.showConfirmModal;
      }
      if (valid) {
        Modal.confirm({
          title: "¿Deseas descartar los cambios realizados?",
          content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleClose(),
          centered: true,
          okText: "Sí, descartar cambios",
          cancelText: "No, seguir editando",
        });
      } else {
        this.handleClose();
      }
    },
    handleClose() {
      this.provider = undefined;
      this.isIntegrationCompleted = false;
      this.showConfirmModal = false;
      this.current = 0;
      this.$emit("close");
    },
    handleNext(provider) {
      this.provider = provider;
      this.current = 1;
    },
    async handleOk() {
      try {
        if (this.current == this.steps.length - 1) {
          if (this.provider == 1) {
            this.isLoading = true;
            this.$refs.twilio.handleOk();
          }
          if (this.provider == 2) {
            this.$refs.gupshup.handleOk();
          }
          return;
        }
        if (this.current == 1) {
          let valid = false;
          if (this.provider == 1) {
            valid = await this.$refs.twilio.validateStep1();
          }
          if (this.provider == 2) {
            valid = await this.$refs.gupshup.validateStep1();
          }
          if (this.provider == 4) {
            this.isLoading = true;
            valid = await this.$refs.securitec.validateStep1();
          }
          if (valid) {
            this.current++;
          }
        }
      } catch (error) {
        this.$message.error("Ha ocurrido un error, vuelva a intentarlo");
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleBack() {
      if (this.current > 0) {
        this.current--;
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.channelsSetup
  padding: 0px 20px 8px
.section-channels-cards
  display: grid
  place-content: center
  // grid-template-columns: repeat(1, 1fr)
  // grid-gap: 24px
  // width: 100%
  margin-top: $margin-large
.container
  display: flex
  flex-direction: column
  align-items: center
.link
  color: $blue-6
  margin-bottom: 0px
  margin-left: $margin-tiny
  font-weight: normal
.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: $margin-tiny
.description
  font-size: $font-normal
  line-height: 22px
  text-align: center
  color: $gray-8
  margin-top: $margin-large
</style>
