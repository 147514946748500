import httpManager from "@/app/shared/utils/httpManager";
/**
 * Tranferir ticket agente.
 *
 *  @param {Object} body Los tickets seleccionados y el agente al que se le van asignar.
 *
 * @return {Promise<Array>}
 */
export async function tranferTicketAgent(body) {
  const url = `/tickets/transfer-to-agent/`;
  const response = await httpManager.post(url, { ...body, origin: 1 }); // Origin 1 is for workspace
  return response?.data;
}
