import WorkspacePage from "@/app/workspace/pages/WorkspacePage.vue";

export default [
  {
    path: "/workspace/:ticket?/:interaction?/:client?",
    name: "workspace",
    component: WorkspacePage,
    meta: {
      requiresAuth: true,
    },
  },
];
