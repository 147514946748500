const getters = {
  getEndpointData(state) {
    return {
      totalCount: state.notifications?.count,
      pageCount: state.notifications?.results.length,
      nextPageUrl: state.notifications?.next,
    };
  },
  getAllNotifications(state) {
    return state.notificationsData;
  },
  getLimitedNotifications(state) {
    return state.limitedNotifications?.results;
  },
  getUnreadNotificationsId(state) {
    return state.notifications?.results
      .filter((notification) => !notification?.is_read)
      .map((notification) => notification.id);
  },
};

export default getters;
