<template>
  <div class="trunk">
    <p>
      Añade tus troncales de telefonía con proveedores de voz. {{ CompanyName }} brinda una por
      defecto para la salida de tus llamadas.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer">Click aquí</a> para saber más.
    </p>
    <div class="inputs">
      <a-form @submit.prevent="handleSearchTrunks">
        <a-form-item>
          <a-input v-model:value="search" class="search" placeholder="Buscar nombre de troncal">
            <template #prefix>
              <SearchOutlined />
            </template>
          </a-input>
        </a-form-item>
      </a-form>
      <a-button class="button" type="primary" @click="handleRequestTroncal">
        Integrar troncal
      </a-button>
    </div>
    <trunk-edit-modal
      v-if="visible.edit"
      :update="update"
      :visible="visible.edit"
      @emitVisible="handleHideModal"
      @update="handleSearchTrunks"
    />

    <a-table
      :columns="columnsTable"
      :data-source="trunksInfo.items"
      :loading="loading"
      row-key="id"
      size="middle"
      :pagination="trunksInfo.pagination"
      :scroll="scrollStyle"
      @change="handleTableChange"
    >
      <template #dialingPlan="{ text }">
        <span v-if="text.length">{{ text.at(0).name }}</span>
        <span v-else class="warn">
          Ninguno
          <a-tooltip
            title="Recuerda que para realizar llamadas salientes, debes asociar un plan de marcación."
          >
            <ExclamationCircleOutlined class="icon" />
          </a-tooltip>
        </span>
      </template>

      <template #portfolios="{ text }">
        <span v-if="!text.length">-</span>
        <span v-else-if="text.length === 1">{{ text.at(0).name }}</span>
        <span v-else>
          {{ `${text.length} campañas asociadas` }}

          <a-popover>
            <template #content>
              <ul>
                <li v-for="p in text" :key="p.id">{{ p.name }}</li>
              </ul>
            </template>

            <InfoCircleOutlined />
          </a-popover>
        </span>
      </template>

      <template #dids="{ text }">
        <span v-if="!text.length">-</span>
        <span v-else-if="text.length === 1">{{ text.at(0).did_number }}</span>
        <span v-else>
          {{ `${text.length} números asociadas` }}

          <a-popover>
            <template #content>
              <ul>
                <li v-for="p in text" :key="p.id">{{ p.did_number }}</li>
              </ul>
            </template>

            <InfoCircleOutlined />
          </a-popover>
        </span>
      </template>

      <template #dtmf_mode="{ record }">
        {{ dmtfMode(record.dtmf_mode) }}
      </template>

      <template #status="{ record }">
        <a-tag :color="statusColor(record)">{{ statusName(record) }}</a-tag>
      </template>

      <template #actionScoped="{ record }">
        <a-space v-if="actionShow(record)" size="middle" align="center">
          <a-switch
            size="small"
            :checked="statusState(record)"
            @change="handleStatusChange(record, $event)"
          />
          <!-- :disabled="record.dialing_plans?.length > 0 || record.dids?.length > 0" -->
          <a-button @click="handleEditTroncal(record.id)" type="text">
            <EditOutlined class="icon" />
            <!-- <DeleteOutlined class="form__icon" @click="handleDelete" /> -->
          </a-button>
          <DeleteOutlined class="icon" @click="confirmationDeleteTrunk(record, record.id)" />
        </a-space>
      </template>
    </a-table>

    <trunk-resquest-modal
      :visible="visible.request"
      @emitVisible="handleHideModal"
      @record="record"
      @update="handleSearchTrunks"
    />
  </div>
</template>

<script>
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import {
  SearchOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";

import { trunksTableColumns } from "@/app/pbx/utils/constants";
import _ from "lodash";

import TrunkResquestModal from "@/app/pbx/components/modals/TrunkResquestModal.vue";
import TrunkEditModal from "@/app/pbx/components/modals/TrunkEditModal.vue";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { CompanyName } from "@/app/shared/utils/constants";

export default defineComponent({
  name: "Trunk",
  components: {
    TrunkResquestModal,
    TrunkEditModal,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
  },
  setup() {
    const { scrollStyle } = useScrollResize({ headerMargin: 300, width: 2000 });

    const errorInactiveTrunk = () => {
      Modal.warning({
        title: "Esta troncal cuenta con dependencias por lo que no puede ser desactivada",
        content:
          "Para poder desactivarla, primero debes desvincular los números para llamadas entrantes (DIDs), planes de marcación y campañas activas asociadas a esta troncal.",
        icon: createVNode(ExclamationCircleOutlined),
        centered: true,
        okText: "OK",
      });
    };
    const errorDeleteTrunk = () => {
      Modal.warning({
        title: "Esta troncal cuenta con dependencias por lo que no puede ser eliminada",
        content:
          "Para poder eliminarla, primero debes desvincular los números para llamadas entrantes (DIDs), planes de marcación y campañas activas asociadas a esta troncal.",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "OK",
        centered: true,
      });
    };
    return {
      scrollStyle,
      errorInactiveTrunk,
      errorDeleteTrunk,
      CompanyName,
    };
  },
  data() {
    const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/pbx/troncales/`;

    return {
      search: "",
      columns: trunksTableColumns,
      visible: {
        request: false,
        edit: false,
      },
      showData: false,
      pageTable: 1,
      loading: false,
      DOCS_URL: DOCS_URL,
      update: false,
    };
  },
  computed: {
    trunksInfo() {
      let value = [];
      value = this.$store.getters["getTrunkInfo"];
      return value;
    },
    columnsTable() {
      // const column = columnsFixedTableLeft(this.columns);
      // return column;
      return this.columns;
    },
  },
  mounted() {
    this.$store.dispatch("fetchAllTrunks");
    this.$store.dispatch("fetchAllCodecs");
  },
  created() {
    this.handleFetchTrunk(this.pagination);
  },
  methods: {
    handleFetchTrunk(pagination) {
      this.$store.dispatch("fetchAllTrunks", { pagination });
    },
    handleTableChange(pagination) {
      this.handleFetchTrunk(pagination);
    },
    async handleStatusChange(record, state) {
      let checked = 2;
      switch (state) {
        case true:
          checked = 1;
          break;

        default:
          checked = 2;
          break;
      }
      const obj = {
        status: checked,
      };
      if (state) {
        this.updateStatus(record, obj);
      } else {
        this.showConfirm(record, obj);
      }
    },
    showConfirm(record, obj) {
      const self = this;
      this.$confirm({
        title: `¿Estás seguro de desactivar la troncal "${record.name}"? `,
        content:
          "Recuerda que no podrás asociar números para llamadas entrantes (DIDs), planes de marcación o campañas a esta troncal.",
        icon: createVNode(QuestionCircleOutlined),
        okText: "Inactivar troncal",
        calcelText: "Cancelar",
        onOk() {
          self.updateStatus(record, obj);
        },
        centered: true,
      });
    },
    async updateStatus(record, obj) {
      try {
        await this.$store.dispatch("updateTrunk", {
          id: record.id,
          params: obj,
        });
        this.handleSearchTrunks();
      } catch (error) {
        this.errorInactiveTrunk();
      }
    },
    statusState(record) {
      let value = false;
      switch (record.status) {
        case 1:
          value = true;
          break;

        default:
          value = false;
          break;
      }
      return value;
    },
    statusName(record) {
      let value = "";
      let idBig = 0;
      this.trunksInfo.items.forEach((trunk) => {
        if (trunk.id > idBig) {
          idBig = trunk.id;
        }
      });
      if (idBig == record.id && this.showData) {
        value = "Pendiente";
      } else {
        switch (record.status) {
          case 1:
            value = "Activo";
            break;
          case 2:
            value = "Inactivo";
            break;
        }
      }
      return value;
    },
    statusColor(record) {
      let value = "";
      let idBig = 0;
      this.trunksInfo.items.forEach((trunk) => {
        if (trunk.id > idBig) {
          idBig = trunk.id;
        }
      });
      if (idBig == record.id && this.showData) {
        value = "orange";
      } else {
        switch (record.status) {
          case 1:
            value = "green";
            break;
          case 2:
            value = "red";
            break;
        }
      }
      return value;
    },
    dmtfMode(dtmf_mode) {
      let value = "";
      switch (dtmf_mode) {
        case 1:
          value = "rfc4733";
          break;
        case 2:
          value = "Inband";
          break;
        case 3:
          value = "info";
          break;
        case 4:
          value = "auto";
          break;
      }
      return value;
    },
    actionShow(record) {
      let value = true;
      let idBig = 0;
      this.trunksInfo.items.forEach((trunk) => {
        if (trunk.id > idBig) {
          idBig = trunk.id;
        }
      });
      if ((idBig == record.id && this.showData) || record.name == "Securitec") {
        value = false;
      } else {
        value = true;
      }
      return value;
    },
    async handleSearchTrunks() {
      this.loading = true;

      await this.$store.dispatch("fetchAllTrunks", {
        search: this.search,
      });

      this.loading = false;
    },
    async handleRequestTroncal() {
      // this.visible.request = true;
      this.update = false;
      this.visible.edit = true;
    },
    async handleEditTroncal(id) {
      await this.$store.dispatch("fetchTrunkById", id);
      this.update = true;
      this.visible.edit = true;
    },
    handleHideModal(value = false) {
      this.visible.request = false;
      this.visible.edit = false;
      if (value) {
        this.showData = true;
        setTimeout(() => {
          this.showData = false;
        }, 2000);
      }
    },
    async handleDeleteTrunk(id) {
      try {
        await this.$store.dispatch("deleteTrunk", id);
        this.handleSearchTrunks();
      } catch (error) {
        this.errorDeleteTrunk();
      }
    },
    confirmationDeleteTrunk(record, id) {
      this.$confirm({
        title: `¿Estás seguro de eliminar la troncal "${record.name}"? `,
        content:
          "Recuerda que ya no se visualizará esta troncal en la tabla. Si deseas volver a utilizarla, deberás configurarla nuevamente.",
        icon: createVNode(QuestionCircleOutlined),
        okText: "Eliminar",
        calcelText: "Cancelar",
        onOk: () => this.handleDeleteTrunk(id),
        centered: true,
      });
    },
  },
  watch: {
    search: _.debounce(function () {
      this.handleSearchTrunks();
    }, 500),
  },
});
</script>

<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

.inputs
  display: flex
  justify-content: space-between
  height: 32px
  margin-bottom: 10px

.search
  margin-bottom: 0.75rem
  width: 16.5625rem
  .ant-input
    border: 1px solid $gray-5
    border-radius: 2px
    font-size: $font-normal
    line-height: 22px
    color: $gray-8
.button
  margin-left: 15.5rem
.sort-icon
  margin-left: 5px
:deep(.ant-table-placeholder)
  display: none
.icon
  color: $blue-6

.warn
  color: $text-cuaternary
  font-style: italic

  .icon
    color: $orange-6
    margin-left: 5px

.ant-btn:disabled
  .anticon.anticon-edit.icon
    color: #bfbfbf
</style>
<style lang="sass">
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td
  background: #feffe6 !important
</style>
