/**
 * Eliminar un inbound chat
 *
 */

export default async function (token) {
  const response = await fetch(`${import.meta.env.VITE_WEBCHAT_WIDGET_API}${token}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: import.meta.env.VITE_WEBCHAT_WIDGET_API_CLIENT_SECRET,
    },
  });
  return response.json();
}
