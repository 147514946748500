export const initialState = () => ({
  client: null,
  ticketsFilters: {},
  tickets: [],
  ticketListPage: 1,
  hasNextTicketPage: false,
  filteredTickets: [],
  history: null,
  channel_person: null,
  channels: [],
  selectedTickets: [],
  clientsWithSameNumberFound: [],
  clientsFound: [],
  callEnded: false,
  ticketDetail: {},
  dialingType: null,
  speechConfig: { visible: false, portfolio: null },
  portfolioDetail: null,
  typificationsTableData: [],
  isCaptureded: false,
  isSchedule: false,
  lastTicketOpened: {
    ticket: null,
    channel_person: null,
  },
  idTicketDrawerContact: null,
  eventMessaging: null,
});

const state = initialState();

export default state;
