export const ClientInformationTabsKey = {
  PersonalData: "1",
  Phones: "2",
  Emails: "4",
};

export const ContactPaymentsCurrencyTabsKey = {
  PEN: "PEN",
  USD: "USD",
};

export const ContactPromisesCurrencyTabsKey = {
  PEN: "SOLES",
  USD: "DOLARES",
};

export const ContactStatus = {
  Active: 1,
  Inactive: 2,
};