<template>
  <a-modal
    v-model:visible="visibleModal"
    width="470px"
    :destroy-on-close="true"
    :mask-closable="false"
    :body-style="bodyStyle"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img
        class="image__inactive"
        src="@/app/users/assets/profiles/question-circle.png"
        alt="inactivar"
      />
      <p class="text__alert">¿Estás seguro de transferir el rol de Owner a un nuevo usuario?</p>
    </div>
    <div class="container__info">
      <p class="text">
        Recuerda que debes seleccionar el perfil a cual será asignado tu usuario luego de la
        transferencia. Tu cuenta pasará a tener los permisos asignados a ese perfil.
      </p>
    </div>
    <a-form class="form" layout="vertical" :model="formState" ref="formRef" :rules="rules">
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item label="Nuevo perfil asignado" name="profile">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.profile"
              placeholder="Seleccionar nuevo perfil"
              @change="disabled = false"
            >
              <a-select-option v-for="profile in profiles" :key="profile.id">
                {{ profile.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <p class="infoRol">Selecciona el usuario al cual le transferirás el rol de Owner</p>
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item label="Nombre del usuario" name="new_owner">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.new_owner"
              placeholder="Seleccionar usuario"
              @change="disabled = false"
              show-search
              :filter-option="false"
              :not-found-content="null"
              @search="searchAllUsers"
              @dropdownVisibleChange="handleChange"
              @keypress="handleSearchUsers($event)"
            >
              <a-select-option v-for="profile in allUsers" :key="profile.id">
                {{ profile.fullname }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item class="form__item">
            <template #label>
              <span> Escribe la palabra <span class="strong">"Transferir"</span> </span>
            </template>
            <a-auto-complete
              v-model:value="confirmText"
              placeholder="Escribe aquí..."
              :class="{ 'ant-form-item-has-error': isTextIncorrect }"
            />
            <p v-if="isTextIncorrect" class="form__error">Palabra inválida</p>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="button__disabled">
      <a-button
        style="width: 100%"
        :disabled="confirmText !== 'Transferir'"
        class="footer__delete"
        type="primary"
        @click="handleSubmit"
      >
        Transferir Owner
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, ref, computed, reactive, toRaw, watch } from "vue";
import { UserStatus } from "@/app/shared/utils/enums";
import { useStore } from "vuex";
import { transferOwner } from "@/app/settings/services";

export default defineComponent({
  name: "InactiveUserModal",
  props: {
    record: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const confirmText = ref("");
    const store = useStore();
    const profiles = computed(() => store.getters["getProfileList"]);
    const users = computed(() => store.getters["getAllUser"]);
    const allUsers = ref([]);
    const bodyStyle = {
      height: "650px",
      padding: "30px 37px 37px",
      loading: false,
    };
    const formState = reactive({
      profile: null,
      new_owner: null,
    });
    const rules = {
      profile: [{ required: true, message: "Campo requerido" }],
      new_owner: [{ required: true, message: "Campo requerido" }],
    };
    watch(users, (newValue) => {
      allUsers.value = newValue;
    });
    return {
      confirmText,
      bodyStyle,
      profiles,
      formState,
      rules,
      users,
      allUsers,
    };
  },
  data() {
    return {
      UserStatus,
    };
  },
  computed: {
    isTextIncorrect() {
      return this.confirmText != "Transferir" && this.confirmText.length > 0;
    },
    visibleModal() {
      return this.visible;
    },
  },
  methods: {
    handleSearchUsers(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async handleSubmit() {
      try {
        await transferOwner(toRaw(this.formState));
        this.$emit("onCloseModal");
        this.$message.success("Se ha transferido su rol de Owner correctamente");
        setTimeout(() => {
          location.reload();
        }, 3000);
      } catch (error) {
        this.$emit("onCloseModal");
        this.$message.warning("Algo salió mal. Por favor, vuelve a intentarlo");
      }
    },
    handleCloseModal() {
      this.$emit("onCancel");
      this.updateStatus();
    },
    handleCloseModalHeader() {
      this.$emit("onCloseModal");
    },
    searchAllUsers(value) {
      value = value.toLowerCase();
      this.allUsers = this.users.filter((item) => {
        return item.fullname.toLowerCase().includes(value);
      });
    },
    handleChange() {
      this.allUsers = this.users;
    },
  },
});
</script>

<style lang="sass" scoped>
.image__inactive
  margin-bottom: 10px
  margin-top: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin-bottom: 0
  color: $gray-13

.text
  margin-bottom: 20px
  margin-top: 20px

.button__disabled
  margin-top: 10px

.strong
  font-weight: 600

.footer__delete
  margin-bottom: 20px

.container__inactive
  text-align: center
  color: $gray-13

.container__info
  margin-top: 20px
  margin-bottom: 30px

.form__error
  color: $red-6
  margin-top: 5px
  margin-bottom: -30px

.infoRol
  margin-top: 0px
  margin-bottom: 10px
</style>
