const getters = {
  getTagConnectionCall(state) {
    return state.currentStrategyTag;
  },
  getStrategyStatus(state) {
    return state.currentStrategyTag.status;
  },
  getCurrentActiveStrategyType(state) {
    return state.currentStrategyTag.strategyType;
  },
  getSequenceTag(state) {
    return state.currentSequenceTag;
  },
};

export default getters;
