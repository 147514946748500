<template>
  <a-popover v-model:open="moreOptionsOpen">
    <template #content>
      <div class="popover-content">
        <ul>
          <li>
            <a-tooltip placement="left">
              <template #title v-if="validateBlacklist(ChannelType.Sms)">
                Este Número se encuentra bloqueado para mandar mensaje de SMS
              </template>
              <a-button
                type="text"
                :disabled="!record.country?.code_phone || validateBlacklist(ChannelType.Sms)"
                @click="
                  emit('click:sms');
                  moreOptionsOpen = false;
                "
              >
                <MessageOutlined />
                Enviar SMS
              </a-button>
            </a-tooltip>
          </li>
          <li>
            <a-tooltip placement="left">
              <template #title v-if="validateBlacklist(ChannelType.Voice)">
                Este Número se encuentra bloqueado para realizar llamadas saliente
              </template>
              <a-button
                type="text"
                @click="
                  emit('click:phone');
                  moreOptionsOpen = false;
                "
                :disabled="validateBlacklist(ChannelType.Voice)"
              >
                <PhoneOutlined style="transform: scaleX(-1)" />
                Llamar
              </a-button>
            </a-tooltip>
          </li>
          <li v-if="getUserData?.allow_send_template">
            <!-- <a-tooltip placement="left">
              <template #title v-if="validateBlacklist(ChannelType.Whatsapp)">
                Este Número se encuentra bloqueado para mandar mensajes de Whatsapp
              </template> -->
            <SendWhatsappTemplateModalVue
              :popover-button="true"
              :record="{ ...record, ...contact }"
              :disabled="!record.country?.code_phone || validateBlacklist(ChannelType.Whatsapp)"
              :validate-blacklist="validateBlacklist(ChannelType.Whatsapp)"
            />
            <!-- </a-tooltip> -->
          </li>
        </ul>
      </div>
    </template>
    <!-- @click="handleConfirmationCall(record)" -->
    <a class="phone" @click="handleConfirmationCall" v-if="userDataInfo">{{ text }}</a>
    <span disabled v-if="!userDataInfo"> {{ text }}</span>
    <a-button style="padding: 0; height: 22px" type="link" @click="moreOptionsOpen = true">
      <EllipsisOutlined :rotate="90" />
    </a-button>
  </a-popover>
</template>

<script setup>
import { EllipsisOutlined, MessageOutlined, PhoneOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";

import SendWhatsappTemplateModalVue from "@/app/workspace/components/modal/SendWhatsappTemplateModal.vue";
import { ChannelType } from "@/app/shared/utils/enums";

const props = defineProps({
  record: { type: Object, default: () => ({}) },
  contact: { type: Object, default: () => ({}) },
  getUserData: { type: Object, default: () => ({}) },
  userDataInfo: { type: Object, default: () => undefined },
  text: { type: String, default: () => undefined },
  portfolio: { type: Object, default: () => ({}) },
});

const emit = defineEmits(["click:phone", "click:sms", "handleConfirmationCall"]);

const moreOptionsOpen = ref(false);

const handleConfirmationCall = () => {
  emit("handleConfirmationCall");
};
const validateBlacklist = (channel) => {
  const index = props.record.blacklist?.findIndex((el) => {
    if (el.channel?.id === channel) {
      if (channel === ChannelType.Voice) {
        return el.portfolios?.some((portfolio) => portfolio.id === props.portfolio.id);
      }
      return true;
    }
    return false;
  });
  return index > -1;
};
</script>

<style scoped lang="sass">
.popover-content
  ul
    list-style: none
    padding-left: 0
    margin: 0

    li
      button
        width: 100%
        text-align: left
        padding: 0 5px
        display: flex
        align-items: center
        gap: 5px

        &:hover
          background: #0000000A
        .icon
          height: 13px
</style>
