<template>
  <SubmenuMainContainer v-model:currentMenu="currentMenu" :menus="menus">
    <template #[SubsectionKey.Password]>
      <SecurityPassword />
    </template>

    <template #[SubsectionKey.Logout]>
      <InactivityConfig />
    </template>

    <template #[SubsectionKey.Delete]>
      <DeleteWorkspace />
    </template>

    <template #[SubsectionKey.Owner]>
      <TransferOwner />
    </template>

    <template #[SubsectionKey.AgentMode]>
      <MassManagement />
    </template>

    <template #[SubsectionKey.Visibility]>
      <WorkspaceVisibility />
    </template>
  </SubmenuMainContainer>
</template>

<script setup lang="ts">
import { onMounted, provide, ref, computed } from "vue";
import {
  SearchOutlined,
  DeleteOutlined,
  LogoutOutlined,
  UserOutlined,
  CrownOutlined,
} from "@ant-design/icons-vue";

import { SubmenuData } from "@/@types/shared/pickers";
import { Customer } from "@/@types/global";
import { CustomerKey } from "@/@types/settingsMenu/symbols";
import { customerService } from "@/app/settingsMenu/services/CustomerService";
import { RolTypes } from "@/app/shared/utils/enums";
import { useUserNeedPermissions } from "@/app/shared/composables/useUserNeedPermissions";

import SubmenuMainContainer from "@/app/shared/components/organisms/SubmenuMainContainer.vue";
import SecurityPassword from "@/app/settingsMenu/components/securityPermits/SecurityPassword.vue";
import InactivityConfig from "@/app/settingsMenu/components/securityPermits/InactivityConfig.vue";
import DeleteWorkspace from "@/app/settingsMenu/components/securityPermits/DeleteWorkspace.vue";
import TransferOwner from "@/app/settingsMenu/components/securityPermits/TransferOwner.vue";
import MassManagement from "@/app/settingsMenu/components/securityPermits/MassManagement.vue";
import WorkspaceVisibility from "@/app/settingsMenu/components/securityPermits/WorkspaceVisibility.vue";

import keyImage from "@/app/settingsMenu/assets/key.svg";

enum MenuKey {
  Account = "account",
  Workspace = "workspace",
}

enum SubsectionKey {
  Password = "password",
  AgentMode = "agent-mode",
  Owner = "owner",
  Visibility = "visibility",
  Logout = "logout",
  Delete = "delete",
}

const menus = computed<SubmenuData<MenuKey, SubsectionKey>[]>(() => [
  {
    name: "Mi cuenta",
    key: MenuKey.Account,
    sections: [
      {
        key: SubsectionKey.Password,
        title: "Contraseña",
        icon: keyImage,
      },
      {
        key: SubsectionKey.AgentMode,
        title: "Modo agente",
        icon: UserOutlined,
        needPermissions: useUserNeedPermissions([RolTypes.administrator, RolTypes.owner]),
      },
      {
        key: SubsectionKey.Owner,
        title: "Rol Owner",
        icon: CrownOutlined,
        needPermissions: useUserNeedPermissions([RolTypes.owner]),
      },
    ],
  },
  {
    name: "Ambiente de trabajo",
    key: MenuKey.Workspace,
    needPermissions: useUserNeedPermissions([RolTypes.administrator, RolTypes.owner]),
    sections: [
      {
        key: SubsectionKey.Visibility,
        title: "Visibilidad en el buscador de Inicio de Sesión",
        icon: SearchOutlined,
      },
      {
        key: SubsectionKey.Logout,
        title: "Cierre de sesión por inactividad",
        icon: LogoutOutlined,
      },
      {
        key: SubsectionKey.Delete,
        title: "Eliminar ambiente de trabajo permanentemente",
        icon: DeleteOutlined,
        needPermissions: useUserNeedPermissions([RolTypes.owner]),
      },
    ],
  },
]);

const currentMenu = ref(MenuKey.Account);

const customerData = ref<Customer>();

onMounted(async () => {
  customerData.value = await customerService.getData();
});

provide(CustomerKey, customerData);
</script>

<style lang="sass" scoped>
.page
  display: flex
  height: 100%

.menu
  margin: 12px 0 !important
  padding-top: 10px !important
  border: 1px solid $gray-5 !important
  border-left: none !important
  border-radius: 0 10px 10px 0
  flex: 0 0 220px

.main
  flex: 1 1 auto
  padding: 20px

  .title
    font-size: 20px
    font-weight: 600
    line-height: 20px
    margin: 0

.divider
  margin: 20px 0
  background-color: $gray-4
</style>
