<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :required="isRequired"
  >
    <p class="instruction">{{ input.instruction }}</p>

    <a-radio-group
      class="list-group"
      :value="workspaceFormState?.[input.name]"
      :disabled="conditionedAttributes?.disabled"
      @update:value="handleChange"
    >
      <a-radio v-for="option in input.options" :key="option.id" :value="option.value">
        {{ option.value }}
      </a-radio>

      <a-radio v-if="input.has_other_option" :value="otherValue">
        Otro

        <a-input
          v-model:value="otherValue"
          :disabled="workspaceFormState?.[input.name] !== otherValue"
          placeholder="Escribir otra opción aquí"
        />
      </a-radio>
    </a-radio-group>
  </a-form-item>
</template>

<script setup lang="ts">
import { inject, computed, ref, watch } from "vue";

import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";

const props = defineProps<{
  input: FormElementMap[ElementType.SingleOption];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const workspaceFormState = inject("workspaceFormState") as Record<string, string | null>;

const otherValue = ref("");

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const handleChange = (value: string | null) => {
  if (!props.isWorkspace) return;

  workspaceFormState[props.input.name] = value;
};

watch(otherValue, (newVal, prevVal) => {
  if (workspaceFormState[props.input.name] === prevVal) {
    workspaceFormState[props.input.name] = newVal;
  }
});

const initialValue = null;

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px

.list-group
  display: flex
  flex-direction: column
  gap: 5px
</style>
