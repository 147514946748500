import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener link de descarga de plantilla por campaña.
 *
 * @param {string} type Tipo de plantilla [client, product].
 * @param {number} portfolioId Identificador de la campaña.
 * @returns
 */
export async function downloadTemplateByPortfolio(type, portfolioId) {
  const newType = type == "contacts" ? "clients" : "accounts";
  const response = await httpManager.getBlob(`/portfolios/${portfolioId}/template/csv/${newType}/`);
  return response.data;
}
