import httpManager from "@/app/shared/utils/httpManager";

/**
 * Muestra la data de la tabla de historial de ticket.
* @param {number} ticketId Identificador del ticket.

* @return {Promise<Object>} Datos de la tabla historial de tickets
 */
export default async function (ticketId) {
  const response = await httpManager.get(`/workspace/tickets/${ticketId}/history-detail/`);
  return response?.data;
}
