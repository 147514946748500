import httpManager from "@/app/shared/utils/httpManager";

/**
 * Crear un nuevo cliente
 * @param {Object} body Datos del nuevo cliente
 *
 * @return {Promise<Object>} Datos del nuevo cliente
 */
export async function createNewClient(body) {
  const response = await httpManager.post(`/workspace/tickets/clients/`, body);
  return response?.data;
}
