<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :required="isRequired"
  >
    <p class="instruction">{{ input.instruction }}</p>

    <a-time-range-picker
      v-if="input.is_range"
      :value="workspaceFormState?.[input.name]"
      class="input"
      format="HH:mm"
      valueFormat="HH:mm"
      :disabled="conditionedAttributes?.disabled"
      @update:value="handleChange"
    />
    <a-time-picker
      v-else
      :value="workspaceFormState?.[input.name]"
      class="input"
      format="HH:mm"
      valueFormat="HH:mm"
      :disabled="conditionedAttributes?.disabled"
      @update:value="handleChange"
    />
  </a-form-item>
</template>

<script setup lang="ts">
import { inject, computed } from "vue";
import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";

const props = defineProps<{
  input: FormElementMap[ElementType.Hour];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const workspaceFormState = inject("workspaceFormState") as Record<string, string | null>;

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const handleChange = (value: string | null) => {
  if (!props.isWorkspace) return;

  workspaceFormState[props.input.name] = value;
};

const initialValue = null;

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px

.input
  max-width: 350px
  min-width: 200px
</style>
