<template>
  <a-form-item :label="hiddenLabel === true ? '' : label || 'Identificador'" :name="name">
    <slot name="label"></slot>
    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      :value="value"
      @update:value="(newValue: number) => handleChange(newValue)"
      :mode="multiple ? 'multiple' : null"
      placeholder="Seleccionar identificador"
      :disabled="(!withOutPortfolio && portfolio) || withOutPortfolio ? disabled : true"
      :loading="loading"
    >
      <a-select-option v-if="showAll === true" :key="''" :value="defaultBehavior ? '' : 0">
        Todos los identificadores
      </a-select-option>
      <a-select-option
        v-for="channelCustomer in channelCustomers"
        :key="channelCustomer.id"
        :disabled="
          isDisabledByBlacklist(channelCustomer?.id, channelCustomer?.is_used_in_blacklist)
        "
      >
        <div v-if="showAll || aliasWithAssociate">
          {{
            (channelCustomer.alias ? channelCustomer.alias : "Sin Alias") +
            (channelCustomer.associate ? " (" + channelCustomer.associate + ")" : "")
          }}
        </div>
        <div v-else>
          {{ channelCustomer.alias || channelCustomer.associate }}
        </div>
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

import { ChannelCustomer } from "@/@types/global";
import {
  fetchAllChannelsCustomers,
  fecthAllChannelsCustomerBlackList,
} from "@/app/channels/services";
import { ChannelType } from "@/app/shared/utils/enums";

const props = withDefaults(
  defineProps<{
    channelType: (typeof ChannelType)[keyof typeof ChannelType];
    value: number | string | undefined;
    disabled?: boolean;
    portfolio: number | undefined;
    withOutPortfolio?: boolean;
    label?: string;
    hiddenIdentifier?: number | undefined;
    defaultBehavior?: boolean;
    showAll?: boolean;
    multiple?: boolean;
    hiddenLabel?: boolean;
    name?: string;
    aliasWithAssociate?: boolean;
    blacklistIdentifiers?: any;
    blacklistExceptions?: Array<any>;
  }>(),
  {
    withOutPortfolio: false,
    defaultBehavior: false,
    showAll: false,
    value: "",
    name: "identifier",
  }
);

const emit = defineEmits<{
  "update:value": [values: number | number[]];
  "update:channelType": [values: number];
}>();

const channelCustomers = ref<ChannelCustomer[]>([]);
const loading = ref(false);

watch(
  () => props.portfolio,
  async () => {
    await loadChannelsCustomers();
  }
);

watch(
  () => props.blacklistIdentifiers,
  async () => {
    if (
      props.blacklistIdentifiers?.blacklist_phone &&
      props.blacklistIdentifiers?.blacklist_code_phone &&
      props.blacklistIdentifiers?.blacklist_blocked_type
    ) {
      await loadChannelsCustomers();
    }
  }
);
watch(
  () => props.channelType,
  async () => {
    loadChannelsCustomers();
  }
);

const loadChannelsCustomers = async () => {
  loading.value = true;
  channelCustomers.value = [];
  if (props.blacklistIdentifiers) {
    channelCustomers.value = await fecthAllChannelsCustomerBlackList(props.blacklistIdentifiers);
  } else {
    channelCustomers.value = await fetchAllChannelsCustomers(props.channelType, props.portfolio);
  }
  if (props.hiddenIdentifier) {
    channelCustomers.value = channelCustomers.value.filter((el) => el.id != props.hiddenIdentifier);
  }
  loading.value = false;
};

const isDisabledByBlacklist = (id: number, isUsedInBlacklist: Boolean | undefined) => {
  if (isUsedInBlacklist) {
    const index: number = props.blacklistExceptions?.findIndex((el) => el == id);
    return index < 0;
  }
  return false;
};

onMounted(async () => {
  loadChannelsCustomers();
});

const handleChange = (newValue: number) => {
  emit("update:value", newValue);
  const item = channelCustomers.value.find((item) => item.id === newValue);
  if (item) emit("update:channelType", item?.channel_id);
};
</script>

<style scoped lang="sass">
.button
  padding: 0

.ant-form-item
  margin: 0
  flex: 1 1 auto

.row
  display: flex
  align-items: flex-end
  gap: 12px
  margin-bottom: 16px

.action
  width: 100%
  border-top: 1px solid $gray-4
  text-align: center
</style>
