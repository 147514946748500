<template>
  <section ref="scrollContainerRef" class="history-scroll">
    <WorkspaceChatTicket :item="history" :readonly="true" />
  </section>
</template>

<script>
import { inject, ref, onUpdated, onMounted } from "vue";

import WorkspaceChatTicket from "@/app/workspace/components/chat/WorkspaceChatTicket.vue";

export default {
  components: {
    // CustomScrollbar,
    WorkspaceChatTicket,
  },
  props: {
    history: { required: true, type: Array },
    expanded: { type: Boolean, default: false },
    channelId: { type: Number },
  },
  setup() {
    const showBanner = inject("showBanner");
    const scrollContainerRef = ref(null);

    const scrollToBottom = () => {
      scrollContainerRef.value.scroll({
        top: scrollContainerRef.value.scrollHeight,
        behavior: "smooth",
      });
    };

    onUpdated(() => scrollToBottom());
    onMounted(() => scrollToBottom());

    return { showBanner, scrollContainerRef };
  },
  computed: {
    heightStyle() {
      const size = this.expanded ? 255 : 180;
      return this.showBanner ? `calc(100vh - ${size}px - 35px` : `calc(100vh - ${size}px`;
    },
  },
  methods: {
    isLast(index) {
      return index === this.history.length - 1;
    },
  },
};
</script>

<style lang="sass" scoped>
.history-scroll
  // height: calc(100% - 96px)
  overflow-y: auto
  scrollbar-width: thin
  scrollbar-color: #c1c1c1 transparent
  margin-right: 4px
  flex: 1 1 auto

  &::-webkit-scrollbar
    position: absolute
    width: 4px

  &::-webkit-scrollbar-track
    background: transparent

  &::-webkit-scrollbar-thumb
    background-color: #c1c1c1
    border-radius: 2px
</style>
