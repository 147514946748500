import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener arbol de tipificaciones por campaña.
 *
 * @param {number} portfolioId Identificador de la campaña.
 *
 * @return {Promise<Any>} Datos de la campaña y el arbol. `level_palette` `groups`
 */

export default async function (portfolioId, { sequences = false } = {}) {
  let url = `/portfolios/${portfolioId}/agents-available/`;

  if (sequences) url += `?with_sequence=False`;

  const response = await httpManager.get(url);
  return response.data;
}
