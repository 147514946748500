import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener un usuario mediante su id.
 *
 * @param {Number} id Datos del usuario a crear.
 *
 * @return {Promise<Object>} Usuario por id
 */
export default async function (id, channelData) {
  const body = {
    ...channelData,
  };
  const response = await httpManager.put(`/channels/${id}/`, body);
  return response;
}
