const getters = {
  getTablePage(state) {
    return state.tablePage;
  },
  getTicketsBasicFilters(state) {
    return state.ticketsBasicFilters;
  },
  getTicketsAdvancedFilters(state) {
    return state.ticketsAdvancedFilters;
  },
  getAdvancedFiltersData(state) {
    return state.advancedFiltersData;
  },
  getTickets(state) {
    return state.ticketsData;
  },
  getTransferData(state) {
    return state.transferData;
  },
  getUsersData(state) {
    return state.usersData;
  },
  getTicketManagerChannels(state) {
    return state.ticketManagerChannels;
  },
  getLoading(state) {
    return state.loading;
  }
};

export default getters;
