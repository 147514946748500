import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener arbol de tipificaciones por campaña.
 *
 * @param {number} portfolioId Identificador de la campaña.
 *
 * @return {Promise<Object>} Datos de la campaña y el arbol. `level_palette` `groups`
 */

export async function fetchTypificationByPortfolio(portfolioId) {
  const campaign = await httpManager.get(`/portfolios/${portfolioId}/`);
  const response = await httpManager.get(
    `/entities/portfolios/${portfolioId}/tree_tipificactions_ru/`
  );
  return {
    ...response.data,
    business_type: campaign.data.business_type,
    type_default_management: campaign.data.type_default_management,
  };
}
