export const initialState = () => ({
  name: "",
  channels_customer: [],
  portfolios: [],
  users: [],
  level_service: null,
  waiting_threshold: null,
  allowed_queues: [],
  distribution_type: null,
  max_tickets_agents: null,
  agentsQueue: [],
  whatsapp_template: null,
  distribution_time: "00:00:00",
  enable_custom_ticket_distribution: false,
  tickets_to_distribute: 10
});

const state = initialState();

export default state;
