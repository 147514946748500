import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los troncales registrados.
 *
 * @return {Promise<Array>} Lista de troncales.
  * @param {Object} pagination Paginación

 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export default async function (search, pagination = {}, country) {
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  let url = `/pbx/dialing-plans/`;
  if (search || country) {
    url += "?";

    const params = { search, country, offset, limit: pageSize };
    Object.keys(params).forEach((keyParam, index) => {
      if (params[keyParam] || keyParam === "offset") {
        if (index > 0) url += "&";
        url += `${keyParam}=${params[keyParam]}`;
      }
    });
  }
  const response = await httpManager.get(url);
  return response?.data;
}
