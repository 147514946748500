export const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    width: 220,
  },
  {
    title: "Tipo de documento",
    dataIndex: "type_document",
    width: 150,
  },
  {
    title: "Documento",
    dataIndex: "number_document",
    width: 150,
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    width: 150,
    slots: { customRender: "phone" },
  },
  {
    title: "Email",
    dataIndex: "email",
    width: 250,
  },
  {
    title: "Dirección",
    dataIndex: "address",
    width: 230,
    slots: { customRender: "address" },
  },
  {
    title: "Observación",
    dataIndex: "observation",
    width: 200,
    slots: { customRender: "observation" },
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 150,
    fixed: "right",
  },
];
