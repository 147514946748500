<template>
  <div class="text__profile">
    <p class="name__profile">Mi perfil</p>
  </div>
  <section class="flex">
    <div class="information">
      <div class="container__imagen">
        <img :src="ellipseImage" style="width: 160px" alt="perfil" />
        <div
          class="bottom__profile"
          :style="backGroundImage"
          :class="selectedImage === true ? 'backgroundDefault' : ''"
        >
          <image-picker
            :value="imageFile"
            @update:value="imageFile = $event"
            @change="handleChangeInputProfile"
            :src="imageSrc || imageDefault"
            @onSelectedImage="handleSelectedImagen"
            :selectedImage="selectedImage"
            :isProfile="isProfile"
            @onCancelProfile="handleChangeColor"
          />
        </div>
      </div>
      <p class="info--name">{{ profile?.fullname }}</p>
      <p class="info">
        <img src="@/app/users/assets/profiles/icon-user.png" alt="nombre" />{{
          profile?.profile_name
        }}
      </p>
      <p class="info">{{ profile.email }}</p>
      <div class="line-yellow"></div>
    </div>
    <div class="form">
      <a-form layout="vertical" ref="formRef" :model="formState" :rules="rules">
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Nombres" name="first_name">
              <a-input v-model:value="formState.first_name" @input="handleChangeInputProfile" />
            </a-form-item>
          </a-col>
          <a-col span="6">
            <a-form-item label="Tipo de documento" name="type_document">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-model:value="formState.type_document"
                placeholder="Tipo de documento"
                @change="handleChangeInputProfile"
              >
                <a-select-option :value="1"> DNI </a-select-option>
                <a-select-option :value="2"> Carnet de Extranjeria </a-select-option>
                <a-select-option :value="3"> Pasaporte </a-select-option>
                <a-select-option :value="4"> Cédula de identidad </a-select-option>
                <a-select-option :value="5"> Otro </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="6">
            <a-form-item label="Número de documento" name="document_number">
              <a-input
                v-model:value="formState.document_number"
                placeholder="Escribir número"
                @input="handleChangeInputProfile"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Apellidos" name="last_name">
              <a-input v-model:value="formState.last_name" @input="handleChangeInputProfile" />
            </a-form-item>
          </a-col>
          <a-col span="6">
            <a-form-item label="Sexo" name="sex">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-model:value="formState.sex"
                placeholder="Seleccionar sexo"
                @change="handleChangeInputProfile"
              >
                <a-select-option :value="1">Hombre</a-select-option>
                <a-select-option :value="2">Mujer</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="6">
            <a-form-item label="Fecha de nacimiento" name="birthdate">
              <a-date-picker
                v-model:value="formState.birthdate"
                :format="humanDateFormat"
                placeholder="-- / -- / --"
                :show-today="false"
                :default-picker-value="defaultYounger"
                :disabled-date="disabledDate"
                @change="handleChangeInputProfile"
                style="width: 100%"
              ></a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Correo" name="email">
              <a-input disabled v-model:value="formState.email" @input="handleChangeInputProfile" />
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item label="País de residencia" name="business_country">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-model:value="formState.country"
                show-search
                :filter-option="false"
                :not-found-content="null"
                placeholder="Seleccionar país"
                @search="searchCountries"
                @dropdownVisibleChange="handleChangeCountry"
                @keypress="handleSearchCountryProfile($event)"
                @change="handleChangeInputProfile"
              >
                <a-select-option v-for="country in allCountries" :key="country.id">
                  <div>
                    <img class="flag" :src="country.url_flag" alt="país" />
                    <span> {{ country.name }}</span>
                  </div>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12" type="flex">
          <a-col :span="12">
            <a-form-item label="Teléfono de contacto" name="phone">
              <a-row :gutter="24" type="flex">
                <a-col :span="7">
                  <a-form-item-rest>
                    <selector-country
                      :code="code_phone"
                      @selected="code_phone = $event"
                      @change="handleChangeInputProfile"
                    />
                  </a-form-item-rest>
                </a-col>
                <a-col :span="17">
                  <a-input
                    v-model:value="formState.phone"
                    placeholder="Número de teléfono"
                    @input="handleChangeInputProfile"
                  />
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Dirección" name="address">
              <a-input
                v-model:value="formState.address"
                placeholder="Escribir dirección"
                @input="handleChangeInputProfile"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </section>
</template>

<script>
import { TypeDocument } from "@/app/workspace/utils/enums";
import { humanDateFormat } from "@/app/shared/utils/constants";
import { updateLoggedUser } from "@/app/settings/services";
import { stringToDate, defaultYounger, disabledYounger } from "@/app/shared/utils/dates";
import { defineComponent, ref, reactive, toRaw, watch, computed } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { useStore } from "vuex";
import ImagePicker from "@/app/shared/components/molecules/ImagePicker.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import crownImage from "@/app/settings/assets/profile/crown.svg?url";
import ellipseImage from "@/app/settings/assets/profile/ellipse.svg?url";

export default defineComponent({
  components: {
    SelectorCountry,
    ImagePicker,
  },
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const formRef = ref();
    const code_phone = ref(props.profile?.code_phone || undefined);
    const store = useStore();
    const countries = computed(() => store.getters["getAllCountries"]);
    const showCountries = ref(false);
    const isProfile = ref(true);
    const allCountries = ref([]);
    const formState = reactive({
      first_name: props.profile?.first_name,
      last_name: props.profile?.last_name,
      email: props.profile?.email,
      phone: props.profile?.phone,
      document_number: props.profile?.document_number,
      business_country: `${props.profile?.business_country}`,
      birthdate: stringToDate(props.profile?.birthdate),
      address: props.profile?.address,
      country: props.profile?.country || undefined,
      type_document: props.profile.type_document || undefined,
      sex: props.profile.sex || undefined,
    });
    const rules = {
      first_name: [{ required: true, message: "Campo requerido" }],
      last_name: [{ required: true, message: "Campo requerido" }],
      email: [
        {
          type: "email",
          required: true,
          message: "Correo inválido",
        },
        { required: true, message: "Campo requerido" },
      ],
      phone: [{ required: true, message: "Campo requerido" }],
      country: [{ required: true, message: "Campo requerido" }],
    };

    watch(countries, (newValue) => {
      allCountries.value = newValue;
    });
    return {
      rules,
      formState,
      formRef,
      code_phone,
      showCountries,
      allCountries,
      countries,
      isProfile,
      ellipseImage,
    };
  },
  data() {
    return {
      humanDateFormat,
      defaultYounger,
      disabledYounger,
      imageSrc: "",
      imageFile: undefined,
      selectedImage: false,
    };
  },
  computed: {
    imageDefault() {
      const profile = this.profile?.icon;
      let url = "";
      if (profile === "") {
        url = crownImage;
      } else {
        url = this.getProfileImage(profile);
      }
      return url;
    },
    backGroundImage() {
      const background = this.profile?.icon;
      const typeProfile = this.profile?.profile_name;
      const role = this.profile?.role;
      if (background === "") {
        return "background: #E6F7FF";
      } else if (typeProfile === "Supervisor") {
        return "background: #E6F7FF";
      } else if (typeProfile === "Agente") {
        return "background: #FFF7E6";
      } else if (role === "Agente") {
        return "background: #FFF7E6";
      }
      return "background: #E6F7FF";
    },
  },
  created() {
    this.$store.dispatch("fetchAllCountries");
    this.setFieldsValue();
  },
  methods: {
    getProfileImage(profile) {
      return new URL(`../../../users/assets/profiles/${profile}`, import.meta.url).href;
    },
    handleChangeInputProfile() {
      this.$emit("onChangeInput", true);
      EventBus.$emit(WorkspaceEvent.SHOW_CONFIRM_MODAL);
    },
    // This method is used by $refs
    handleSearchCountryProfile(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          const values = toRaw(this.formState);
          const formData = new FormData();
          if (this.imageFile) formData.append("picture_file", this.imageFile);
          if (this.formState.document_number)
            formData.append("document_number", this.formState.document_number);
          formData.append("first_name", values.first_name);
          formData.append("last_name", values.last_name);
          if (this.code_phone) formData.append("code_phone", this.code_phone);
          formData.append("phone", values.phone);
          if (this.formState.country) formData.append("country", values.country);
          if (this.formState.sex) formData.append("sex", values.sex);
          formData.append("imageSrc", this.imageSrc);
          formData.append("address", values.address);
          if (this.formState.birthdate)
            formData.append("birthdate", values.birthdate.format("YYYY-MM-DD"));
          if (this.formState.type_document) formData.append("type_document", values.type_document);
          formData.append("business_country", values.business_country);
          await updateLoggedUser(formData);
          this.$emit("update");
          this.$emit("onChangeInput", false);
          if (this.isProfile === false || values.first_name !== this.profile.first_name) {
            location.reload();
          }
        } catch (error) {
          const errorMessage = error?.errors?.[0] || "Error la imagen de Avatar excede 3MB";
          this.$message.error(errorMessage);
          throw new Error(error);
        }
      });
    },

    setFieldsValue() {
      this.imageSrc = this.profile.picture;
    },

    searchCountries(value) {
      value = value.toLowerCase();
      this.allCountries = this.countries.filter((item) => {
        return item.name.toLowerCase().includes(value);
      });
    },
    handleChangeCountry(data) {
      this.showCountries = data;
      this.allCountries = this.countries;
    },

    handleValidateDocument(rule, value, callback) {
      const { getFieldValue } = this.form;
      const documentType = getFieldValue("type_document");

      if (TypeDocument.Dni === documentType && value.length !== 8) {
        callback("Máximo 8 dígitos");
      } else if (TypeDocument.ForeignCard === documentType && value.length !== 12) {
        callback("Máximo 12 dígitos");
      } else if (TypeDocument.Pasaporte === documentType && value.length !== 12) {
        callback("Máximo 12 dígitos");
      }
      callback();
    },
    disabledDate(current) {
      return disabledYounger(current);
    },
    handleSelectedImagen(e) {
      this.selectedImage = e;
    },
    handleChangeColor(e) {
      this.isProfile = e;
    },
  },
});
</script>

<style lang="sass" scoped>
.form
  margin-left: 100px
  width: 60%
  margin-top: 30px
  margin-right: 30px

.flag
  width: 20px
  margin-bottom: 2px

.label
  margin-bottom: 8px

.text__profile
  font-size: 24px
  font-weight: 600
  margin-left: 20px

.information
  margin-top: 8px
  margin-left: 70px
  text-align: center
  margin-bottom: 30px

.info
  margin-bottom: -27px
  margin-top: 36px
  &--name
    font-size: 16px
    font-weight: 600
    margin-bottom: -28px
    margin-top: 40px

.line-yellow
  height: 10px
  background: $yellow-6
  width: 350px
  border-radius: 5px
  margin-top: 50px

.flex
  display: flex

.flag
  width: 20px
  margin-bottom: 2px
  margin-right: 4px

.bottom__profile
  margin-top: -126px
  width: 95px
  margin-inline: auto
  border-radius: 50%

.container__imagen
  margin-top: 30px

.name__profile
  margin-top: 22px

.backgroundDefault
  filter: none !important
  background: none !important
</style>
