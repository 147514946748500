import {
  SET_STATUS,
  SET_PHONE_NUMBER,
  SET_STRATEGY_PHONE,
  SET_CALL_FINISH,
  SET_CALL_MUTED,
  SET_CALL_HOLD,
} from "./mutation-types";

const actions = {
  setWorkspaceStrategyPhone({ commit }, strategyPhone) {
    commit(SET_STRATEGY_PHONE, strategyPhone);
  },
  setWorkspacePhoneNumber({ commit }, phoneNumber) {
    commit(SET_PHONE_NUMBER, phoneNumber);
  },
  setWorkspacePhoneStatus({ commit }, status) {
    commit(SET_STATUS, status);
  },
  setCallFinish({ commit }, finish) {
    commit(SET_CALL_FINISH, finish);
  },
  setCallMutated({ commit }, muteded) {
    commit(SET_CALL_MUTED, muteded);
  },
  setCallHold({ commit }, hold) {
    commit(SET_CALL_HOLD, hold);
  },
};

export default actions;
