import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los tickets.
 *
 * @return {Promise<Object>} Lista de tickets.
 */
export async function fetchAdvancedFiltersData(params) {
  const { entity, portfolio, channel, startDate, endDate } = params;

  const response = await httpManager.get(
    `/tickets/manager/advanced-filters/?entity=${entity ?? ""}&portfolio=${
      portfolio || ""
    }&channel=${channel}&start_date=${startDate}&end_date=${endDate}`
  );
  return response?.data;
}
