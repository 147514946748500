<template>
  <!-- <a-button
    type="primary"
    class="button button__info"
    @click="visible = true"
    :disabled="isDisabled"
  >
    Elegir
  </a-button> -->

  <a-drawer
    :visible="props.visible"
    class="assign-contact-drawer"
    placement="right"
    :closable="false"
    :width="660"
  >
    <!-- <template #title>
      <header>
        <span>Crear/Buscar Contacto</span>

        <a-button type="text" class="button button__close" @click="visible = false">
          <CloseOutlined class="icon" />
        </a-button>
      </header>
    </template>
    <div>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="Buscar Contacto">
          <h3>Contactos Encontrados</h3>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Crear Nuevo Contacto" force-render
          >Content of Tab Pane 2</a-tab-pane
        >
      </a-tabs>
    </div> -->
    <template #title>
      <header>
        <h1>Crear/Buscar Contacto</h1>

        <a-button type="text" class="button button__close" @click="emit('close')">
          <CloseOutlined class="icon" />
        </a-button>
      </header>
    </template>
    <NoClientSection fromEmail @clientReady="handleClientReady" :email="currentMessage" />
    <template #footer>
      <footer>
        <a-button @click="emit('close')"> Ocultar </a-button>
      </footer>
    </template>
  </a-drawer>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { EmailDetails } from "@/@types/emails";
import NoClientSection from "@/app/workspace/components/sections/NoClientSection.vue";
import { emailService } from "@/app/emails/services/Email.service";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import { useStore } from "vuex";

const props = defineProps<{
  isDisabled: boolean;
  currentMessage?: EmailDetails | null;
  email?: string;
  visible: boolean;
  hasClient: boolean;
}>();

const emit = defineEmits<{ close: []; closeAssign }>();

// const visible = ref(false);

const route = useRoute();
const store = useStore();

const handleClientReady = async ({
  actionName,
  requestBody,
}: {
  actionName: "assignClient" | "createNewClient";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody: any;
}) => {
  const emailId = Number(route.query.emailId);

  if (actionName === "assignClient") {
    const response = await emailService.editThreadEmail(emailId, {
      person: requestBody.person as number,
      to_email: props.email,
      relate_person_thread: !props.hasClient, // true: asignar | false: asociar
    });
    if (response.status == 200) message.success("Se ha asignado el contacto correctamente");
  }

  if (actionName === "createNewClient") {
    const response = await emailService.createClientToEmail(emailId, {
      ...requestBody,
      to_email: props.email,
    });
    if (response.status == 200) message.success("Se ha creado el cliente correctamente");
  }

  const messageDetail = await emailService.getThreadDetails(emailId);
  store.dispatch("updateThreadActual", messageDetail);
  emit("close");
  emit("closeAssign");
  // visible.value = false;
};
</script>

<style scoped lang="sass">
.button
  width: 100%

  &__close
    padding: 0
    width: 32px

.icon
  font-size: 16px

header
  display: flex
  justify-content: space-between
  align-items: center

footer
  text-align: right

h1
  font-size: 16px
  margin-bottom: 0
  margin-left: 0
</style>

<style>
.assign-contact-drawer .ant-drawer-header {
  padding: 10px 24px !important;
}
.assign-contact-drawer .ant-drawer-body {
  padding: 0px !important;
}
</style>
