import {
  SET_ACTIVE_USERS,
  SET_AGENTSPORTFOLIO,
  SELECT_ALL_ACTIVE_USERS,
  SELECT_SINGLE_ACTIVE_USER,
  SELECT_ACTIVE_USERS_BY_PROFILE,
  SET_SELECTED_ACTIVE_USERS,
  SET_CLEAN_LIST_AGENT,
} from "./mutation-types";

const mutations = {
  [SET_ACTIVE_USERS](state, users) {
    state.activeUsers = users;
    state.filteredActiveUsers = users;
  },
  [SET_SELECTED_ACTIVE_USERS](state, selectedUsers) {
    const selectedUsersId = selectedUsers.map((selectedUser) => selectedUser.id);
    state.activeUsers = state.activeUsers.map((activeUser) => ({
      ...activeUser,
      added: selectedUsersId.includes(activeUser.id),
    }));
    state.filteredActiveUsers = state.activeUsers;
  },
  [SELECT_ALL_ACTIVE_USERS](state, status) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({ ...item, added: status }));

    state.filteredActiveUsers = updatedItems;
    state.selectedActiveUsersId = status
      ? currentActiveUsers.map((currentActiveUser) => currentActiveUser.id)
      : [];
  },
  [SELECT_ACTIVE_USERS_BY_PROFILE](state, { status, profileName }) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({
      ...item,
      added: item.profile_name === profileName ? status : item.added,
    }));

    state.filteredActiveUsers = updatedItems;
    const seletedUsersIdByProfile = currentActiveUsers
      .filter((currentActiveUser) => currentActiveUser.profileName === profileName)
      .map((user) => user.id);

    if (status) {
      state.selectedActiveUsersId.concat(seletedUsersIdByProfile);
    } else {
      state.selectedActiveUsersId = state.selectedActiveUsersId.filter(
        (userId) => !seletedUsersIdByProfile.includes(userId)
      );
    }
  },
  [SELECT_SINGLE_ACTIVE_USER](state, { status, userId }) {
    const currentActiveUsers = [...state.filteredActiveUsers];
    const updatedItems = currentActiveUsers.map((item) => ({
      ...item,
      added: item.id === userId ? status : item.added,
    }));

    state.filteredActiveUsers = updatedItems;
    if (status) {
      state.selectedActiveUsersId.push(userId);
    } else {
      const listActive = currentActiveUsers.find(
        (currentActiveUser) => currentActiveUser.id === userId
      );
      const data = state.selectedActiveUsersId.filter((item) => {
        return item != userId;
      });
      state.selectedActiveUsersId = data;
      return listActive;
    }
  },
  [SET_AGENTSPORTFOLIO](state, agentsPortfolio) {
    state.agentsPortfolio = agentsPortfolio;
  },
  [SET_CLEAN_LIST_AGENT](state) {
    state.selectedActiveUsersId = [];
  },
};

export default mutations;
