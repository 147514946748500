<template>
  <a-space :size="12">
    <a-popover
      v-for="group in groupBy(props.channels, (channel: Interaction) => channel?.channel?.icon)"
      :key="group.icon"
      :title="`${group.title} ${extraTitle}`"
    >
      <template #content>
        <ul>
          <li v-for="channel in group.items" :key="channel.id">
            {{ showClientNumber ? getChannelIdentifier(channel) : getChannelAlias(channel) }}
          </li>
        </ul>
      </template>

      <a-button type="link" class="table-link-button">
        <img :height="props.size" :width="props.size" :src="group.icon" :alt="group.title" />
      </a-button>
    </a-popover>
  </a-space>
</template>
<script lang="ts" setup>
import { groupBy } from "@/app/ticketsManager/utils/groupBy";
import { Interaction } from "@/@types/global";

const props = defineProps({
  channels: { type: Array },
  size: { type: Number, default: 14 },
  extraTitle: { type: String, default: "" },
  showClientNumber: { type: Boolean, default: false },
});

const getChannelIdentifier = (channel: Interaction) => {
  const code_country = channel.code_country ? `+${channel.code_country} ` : "";
  const identifier = channel.identifier;
  // const alias = channel?.channel_customer?.alias;
  const alias = undefined;

  const formattedNumber = `${code_country}${identifier}`;

  return alias ? `${formattedNumber} - ${alias}` : formattedNumber;
};

const getChannelAlias = (channel: Interaction) => {
  const channelCustomer = channel.channel_customer;

  return channelCustomer.alias || channelCustomer.identifier || channel.channel.name;
};
</script>

<style lang="sass" scoped>
.table-link-button
  padding: 0
</style>
