import { getRecaptchaToken } from "@/app/auth/services/getRecaptchaToken";
import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener token de acceso a la aplicación.
 *
 * @param {object} credentials Credenciales para iniciar sesión.
 * @param {string} credentials.username Nombre de usuario.
 * @param {string} credentials.password Clave de seguridad privada.
 * @param {string} credentials.workspace Equipo de trabajo del usuario.
 *
 * @return {Promise<string>} Token de acceso.
 */
export async function fetchAccessToken({ username, password }) {
  const recaptchaToken = await getRecaptchaToken();

  const body = {
    username,
    password,
    recaptcha_token: recaptchaToken,
  };
  const response = await httpManager.post(`/token/`, body, { isPublic: true });
  return response?.data;
}
