<template>
  <CustomScrollbar height="500px" class="custom-scroll-web-chat-config">
    <div class="modal-top-section">
      <h3 class="modal-top-section__title margin-0">Instalación del código</h3>
      <p class="modal-top-section__subtitle">
        Copia el código generado e insertalo en tu sitio web justo antes del final de la etiqueta
        &#60;body&#62;
      </p>
    </div>
    <a-textarea v-model:value="code" disabled class="box-textarea mt-36" />

    <a-button
      class="copy-btn"
      type="primary"
      v-clipboard:copy="code"
      v-clipboard:success="successMsg"
      v-clipboard:error="errorMsg"
    >
      Copiar código
    </a-button>

    <a-form ref="formRef" :model="formState" :rules="rules">
      <p class="step-web-chat-form__label mt-36">Envía el código de instalación a un correo</p>
      <p>Envíaselo a tu desarrollador o al administrador de tu web.</p>
      <div class="send-config-input">
        <a-form-item name="email">
          <a-input v-model:value="formState.email" placeholder="Escribir correo electrónico" />
        </a-form-item>
        <a-form-item>
          <a-button class="send-email-btn" @click="submitForm">
            <LoadingOutlined v-if="isLoading" />
            <span v-else>Enviar</span>
          </a-button>
        </a-form-item>
      </div>
    </a-form>

    <ModalInstructionsSent ref="modalSent" />
  </CustomScrollbar>
</template>

<script setup>
import { toRefs, computed, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import { LoadingOutlined } from "@ant-design/icons-vue";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import ModalInstructionsSent from "../complements/ModalInstructionsSent.vue";

import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  token: {
    type: String,
    default: "",
  },
});

const { token } = toRefs(props);

const isLoading = ref(false);

const formState = reactive({
  email: "",
});

const code = computed(
  () => `<script data-id='widget-securitec' data-token='${token.value}' src='${
    import.meta.env.VITE_WEBCHAT_WIDGET_SRC
  }' 
/>`
);

const successMsg = () => {
  message.success("Copiado");
};

const errorMsg = () => {
  message.error("Falló el copiado");
};

// SEND EMAIL
const formRef = ref(null);
const modalSent = ref();

const payload = computed(() => {
  return {
    token: token.value,
    email: formState.email,
  };
});

const resetForm = () => {
  formRef.value.resetFields();
};

const submitForm = async () => {
  try {
    await formRef.value.validate();
    isLoading.value = true;
    await store.dispatch("sendInboundInstructions", payload.value);
    await modalSent.value.onOpen();
    resetForm();
  } catch (error) {
    if (error.errorFields !== undefined) {
      console.log(error);
    } else {
      message.error("Hubo un error al enviar el correo electrónico.");
    }
  } finally {
    isLoading.value = false;
  }
};

const rules = {
  email: [{ required: true, message: "Campo requerido", trigger: "change" }, { type: "email" }],
};
</script>

<style lang="sass" scoped>
@import '../sharedStyles'

.box-textarea
  min-height: 115px
  padding: 5px 12px
  border: 1px solid $gray-5
  border-radius: 4px

  // textarea
  &::-webkit-scrollbar
    width: 6px
    cursor: default !important

  &::-webkit-scrollbar-track
    background-color: #eee

  &::-webkit-scrollbar-thumb
    background: #999
    border-radius: 6px

.box-textarea.ant-input-disabled
  color: $gray-13
  background-color: $white
  cursor: text

.copy-btn
  width: 100%
  margin-top: 12px

.send-config-input
  display: grid
  grid-template-columns: 1fr 72px
  gap: 16px

.send-email-btn
  border-color: #4096FF
  color: #4096FF
</style>
