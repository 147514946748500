import {
  SET_BASES,
  ADD_BASE,
  REMOVE_BASE,
  SET_BASE,
  SET_BASE_STATUS,
  SET_ALL_BASES,
} from "./mutation-types";

const mutations = {
  [SET_BASES](state, data) {
    state.basesInfo = data;
  },
  [ADD_BASE](state, base) {
    state.basesInfo.items.unshift(base);
  },
  [SET_BASE](state, base) {
    const index = state.basesInfo.items.findIndex((item) => base.id == item.id);
    state.basesInfo.items.splice(index, 1, {
      ...base,
      entity: state.basesInfo.items[index].entity,
      portfolio: state.basesInfo.items[index].portfolio,
    });
  },
  [SET_BASE_STATUS](state, base) {
    const index = state.basesInfo.items.findIndex((item) => base.id == item.id);
    state.basesInfo.items[index].status = base.status;
  },
  [REMOVE_BASE](state, baseId) {
    const index = state.basesInfo.items.findIndex((item) => baseId == item.id);
    state.basesInfo.items.splice(index, 1);
  },
  [SET_ALL_BASES](state, data) {
    state.bases = data;
  },
};

export default mutations;
