import SupervisionPage from "@/app/supervision/pages/SupervisionPage.vue";
import MonitorTemplate from "@/app/supervision/components/templates/MonitorTemplate.vue";
import RecordsTemplate from "@/app/supervision/components/templates/RecordsTemplate.vue";
import ViewerTemplate from "@/app/supervision/components/templates/ViewerTemplate.vue";

export default [
  {
    path: "/monitoring",
    name: "supervision",
    component: SupervisionPage,
    redirect: "/monitoring/monitoring",
    children: [
      {
        path: "monitoring",
        component: MonitorTemplate,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "viewer",
        component: ViewerTemplate,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "records",
        component: RecordsTemplate,
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];
