import {
  REMOVE_ACCESS_TOKEN,
  SET_ACCESS_TOKEN,
  SET_PROGRESS,
  SET_RECOVER_PASSWORD,
  ADD_TOKEN,
  SET_WORKSPACES,
  RESET_PASSWORD,
} from "./mutation-types";

const mutations = {
  /**
   * Setear token de acceso en el Store y localStorage.
   *
   * @param {string} accessToken Token de acceso.
   */
  [SET_ACCESS_TOKEN](state, { accessToken }) {
    state.access_token = accessToken;
  },
  /**
   * Remover token de acceso del Store y del localStorage.
   */
  [SET_RECOVER_PASSWORD](state, email) {
    state.temporalEmail = email;
  },
  [REMOVE_ACCESS_TOKEN](state) {
    state.access_token = null;
  },
  [SET_PROGRESS](state, step) {
    state.step = step;
  },
  [ADD_TOKEN](state, token) {
    state.email = { ...state.email, token };
  },
  [SET_WORKSPACES](state, data) {
    state.workspaces = data;
  },
  [RESET_PASSWORD](state, email) {
    state.email = { ...email };
  },
};

export default mutations;
