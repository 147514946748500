<template>
  <div v-if="step == 1" class="title-container">
    <h2>Completa los datos para integrar con Twilio</h2>
    <div class="subtitle-text">
      Recuerda que los datos pedidos a continuación los podrás encontrar en tu cuenta con Twilio.
    </div>
    <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <a-form-item label="Número Twilio" name="phone_number">
        <a-row :gutter="24" type="flex">
          <a-col :span="7">
            <selector-country :code="code_phone" @selected="code_phone = $event" />
          </a-col>
          <a-col :span="17">
            <a-input
              v-model:value="formState.phone_number"
              placeholder="Escribir número"
              @input="showConfirmModal = true"
            />
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="Cuenta SID" name="account">
        <a-input
          v-model:value="formState.account"
          placeholder="Escribir el account SID"
          @input="showConfirmModal = true"
        />
      </a-form-item>
      <a-form-item label="Token" name="auth_token">
        <a-input
          v-model:value="formState.auth_token"
          placeholder="Escribir el token"
          @input="showConfirmModal = true"
        />
      </a-form-item>
    </a-form>
    <!-- Fotter Modal -->
  </div>
  <div v-if="step == 2" class="title-container">
    <h2>Finaliza tu integración copiando estas webhooks en Twilio</h2>
    <div class="subtitle-text">
      En tu cuenta de Twilio encontrarás una sección llamada webhooks, recuerda copiar las
      siguientes URL en dicha sección antes de finalizar.
    </div>
    <a-form
      class="form"
      layout="vertical"
      hide-required-mark
      ref="formRefStep2"
      :model="formStateStep2"
      :rules="rulesStep2"
    >
      <a-form-item label="Webhook de Mensajes" name="url_callback_messages">
        <a-input
          v-model:value="formStateStep2.url_callback_messages"
          :disabled="true"
          placeholder="Autogenerado"
        >
          <template #suffix>
            <a-tooltip title="Copiar">
              <CopyOutlined
                class="color-icon"
                v-clipboard:copy="formStateStep2.url_callback_messages"
                v-clipboard:success="handleCopySuccess"
                v-clipboard:error="handleCopyError"
              />
            </a-tooltip>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="Webhook de Status" name="url_callback_messages">
        <a-input
          v-model:value="formStateStep2.url_callback_messages"
          :disabled="true"
          placeholder="Autogenerado"
        >
          <template #suffix>
            <a-tooltip title="Copiar">
              <CopyOutlined
                class="color-icon"
                v-clipboard:copy="formStateStep2.url_callback_messages"
                v-clipboard:success="handleCopySuccess"
                v-clipboard:error="handleCopyError"
              />
            </a-tooltip>
          </template>
        </a-input>
      </a-form-item>
    </a-form>
    <!-- Fotter Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, watch } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { CopyOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";

export default defineComponent({
  props: {
    step: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  components: {
    SelectorCountry,
    CopyOutlined,
  },
  name: "TwilioWhatsapp",
  setup(_, { emit }) {
    const formRef = ref();
    const code_phone = ref(1);
    const disabled = ref(true);
    emit("disabled", disabled.value);
    const formState = reactive({
      account: "",
      auth_token: "",
      phone_number: "",
    });
    watch(formState, (newValue) => {
      const values = Object.values(newValue);
      disabled.value = false;
      for (const value of values) {
        if (value == "") {
          disabled.value = true;
        }
      }
      emit("disabled", disabled.value);
    });
    const rules = {
      account: [{ required: true, message: "Campo requerido" }],
      auth_token: [{ required: true, message: "Campo requerido" }],
      phone_number: [{ required: true, message: "Campo requerido" }],
    };
    const formRefStep2 = ref();
    const formStateStep2 = reactive({
      url_callback_messages: "",
      url_callback_status: "",
    });
    const rulesStep2 = {
      url_callback_messages: [{ required: true, message: "Campo requerido" }],
      url_callback_status: [],
    };
    return {
      rules,
      formState,
      formRef,
      rulesStep2,
      formStateStep2,
      formRefStep2,
      code_phone,
    };
  },
  data() {
    return {
      visible: false,
      providerId: 1, // Twilio
      showConfirmModal: false,
    };
  },
  mounted() {
    this.getWebhook();
  },
  watch: {
    showConfirmModal() {
      this.$emit("change", true);
    },
  },
  computed: {
    webhook() {
      return this.$store.getters["getWebhookProviderWhatsapp"];
    },
  },
  methods: {
    async validateStep1() {
      let valid = false;
      await this.formRef.validateFields().then(() => {
        valid = true;
      });
      return valid;
    },
    async getWebhook() {
      await this.$store.dispatch("fetchAllWebhookProviderWhatsapp", this.providerId);
      this.formStateStep2.url_callback_messages = this.webhook.webhook_url;
    },
    async handleOk() {
      const data = {
        channel: 3,
        data_parameters: {
          ...toRaw(this.formState),
          url_callback: this.formStateStep2.url_callback_messages,
        },
        // queue: 1,
        provider: this.providerId,
      };
      this.handleCreateNewChannel(data);
    },
    async handleCreateNewChannel(data) {
      try {
        await this.$store.dispatch("createNewChannel", data);
        this.$emit("onIntegrationFinished");
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        message.warning(errorMessage);
      }
    },
    handleCopyError() {
      message.error("Falló");
    },
    handleCopySuccess() {
      message.success("Copiado");
    },
  },
});
</script>

<style lang="sass" scoped>
.form
  display: block
  padding: 40px 195px
  margin: 0 auto

.space
  margin-bottom: $margin-tiny

.small
  max-height: 20px
.color-icon
  color: $blue-6
  font-size: $font-medium
.ant-btn
  border-radius: 2px
.ant-form-item
  margin-bottom: $margin-small

p
  margin-bottom: 2px
  font-weight: 600 !important
  color: $gray-9
.flag
  width: 20px
  margin-bottom: 2px
.textdisabled
  color: $gray-6
.title-container
  margin-top: 25px
  h2
    text-align: center
.subtitle-text
    text-align: center
    padding: 0 192px
</style>
