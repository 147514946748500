import httpManager from "@/app/shared/utils/httpManager";

export default async function (params) {
  let query = "?";
  query += params.startValue ? `start_date=${params.startValue}&` : "";
  query += params.endValue ? `end_date=${params.endValue}&` : "";
  query += `detailed=${true}`;
  const url = `/reports/4/${query}`;
  const response = await httpManager.get(url);
  return response?.data;
}
