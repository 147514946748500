<template>
  <a-popover trigger="click" placement="right">
    <template #content>
      <div class="popover-content">
        <ul>
          <li v-for="(action, index) in actions" :key="index">
            <a-button type="text" @click="handleAction(action)">
              <component :is="action?.icon" class="icon" />
              {{ action?.label }}
            </a-button>
          </li>
        </ul>
      </div>
    </template>
    <a-button type="text" @click.stop><MoreOutlined /></a-button>
  </a-popover>
</template>

<script setup lang="ts">
import { VueConstructor } from "vue";
import { MoreOutlined } from "@ant-design/icons-vue";
import { ActionsEmail } from "@/@types/emails";

interface IActions {
  icon: VueConstructor;
  label: string;
}

const props = defineProps<{
  actions: IActions[];
  show: boolean;
  showReply: boolean;
}>();

const emit = defineEmits(["handleAction", "handleNoteEvent", "handleReplyEvent"]);

const handleAction = (action: IActions) => {
  if (action.label === ActionsEmail.NotaInterna) {
    emit("handleNoteEvent", !props.show);
  } else if (
    action.label === ActionsEmail.Responder ||
    action.label === ActionsEmail.ResponderTodos ||
    action.label === ActionsEmail.Reenviar
  ) {
    emit("handleReplyEvent", action.label);
  } else {
    emit("handleAction", action);
  }
};
</script>

<style lang="sass" scoped>
.popover-content
  ul
    list-style: none
    padding-left: 0
    margin: 0

    li
      button
        width: 100%
        text-align: left
        padding: 0 5px
        display: flex
        align-items: center
        gap: 5px

        &:hover
          background: #0000000A
        .icon
          height: 13px
</style>
