<template>
  <a-modal
    title="Agregar respuesta rápida"
    :open="open"
    centered
    :bodyStyle="{ height: 'max(70vh, 700px)' }"
    :maskClosable="false"
    :width="620"
    @cancel="handleClose(false)"
  >
    <section class="wrapper">
      <p>
        Las respuestas que incluyan multimedia o documentos podrían ser enviadas en mensajes
        separados al del texto principal.
      </p>

      <a-input-search
        style="margin-bottom: 24px"
        v-model:value="search"
        placeholder="Buscar por título"
        @search="handleSearch"
      />

      <a-list
        size="small"
        item-layout="horizontal"
        :pagination="pagination"
        :data-source="quickAnswersData?.results"
        :loading="loading"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <template #actions>
              <a-button @click="handlePreview(item)">Previsualizar</a-button>

              <a-button type="primary" ghost @click="handleAdd(item)">Agregar</a-button>
            </template>

            <a-list-item-meta>
              <template #title>
                <span class="list-item header"> {{ getAnswerFileLabel(item) }} </span>
                <span class="list-item title">
                  <span class="ellipsis">
                    {{ item.title }}
                  </span>

                  <a-tooltip>
                    <template #title>
                      <strong>Respuesta rápida pertenece a:</strong>
                      <br />
                      Campaña: {{ getAnswerPortfolio(item)?.name }}
                      <br />
                      Cola: {{ getAnswerQueue(item)?.name }}
                    </template>

                    <InfoCircleOutlined style="margin-left: 4px" />
                  </a-tooltip>
                </span>
              </template>

              <template #description>
                <span class="list-item description">{{ item.message }}</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </section>

    <template #footer>
      <foooter class="footer">
        <a-button @click="handleClose(false)">Cerrar</a-button>
      </foooter>
    </template>
  </a-modal>

  <PreviewQuickAnswer
    v-if="previewModal.open"
    v-model:open="previewModal.open"
    :answer="previewModal.answer"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { debounce } from "lodash";

import type { QuickAnswer } from "@/@types/platformSettings/quickAnswers";
import type { Pagination } from "@/@types/utils";
import { quickAnswersService } from "@/app/platformSettings/services/QuickActionsService";

import PreviewQuickAnswer from "./PreviewQuickAnswer.vue";

const props = defineProps<{
  open: boolean;
  queue: number;
}>();

const emit = defineEmits<{
  "update:open": [open: boolean];
  "select:answer": [answer: QuickAnswer];
  handleClose;
}>();

const search = ref("");
const currentPage = ref(1);
const loading = ref(false);
const quickAnswersData = ref<Pagination<QuickAnswer>>();

const pagination = computed(() => ({
  total: quickAnswersData.value?.count,
  pageSize: 6,
  showSizeChanger: false,
  onChange: (page: number) => {
    currentPage.value = page;
    fetchData();
  },
}));

const fetchData = async () => {
  loading.value = true;

  quickAnswersData.value = await quickAnswersService.getList({
    page: currentPage.value,
    title: search.value,
    queue: props.queue,
  });

  loading.value = false;
};

onMounted(() => fetchData());

const handleSearch = debounce(() => {
  currentPage.value = 1;
  fetchData();
}, 200);

const handleClose = (add = false) => {
  emit("update:open", false);
  emit("handleClose", add); // Indica si se agregó algo: true | false
};

const getAnswerQueue = (item: QuickAnswer) => {
  return item.queues.find((q) => q.id === props.queue);
};

const getAnswerPortfolio = (item: QuickAnswer) => {
  const currentQueue = getAnswerQueue(item);

  return currentQueue?.portfolio;
};

const getAnswerFileLabel = (item: QuickAnswer) => {
  if (!item.type_file) return "Sin multimedia";

  let prefix = "Archivo: ";

  if (item.type_file === "image") prefix += "Imagen";
  if (item.type_file === "video") prefix += "Video";
  if (item.type_file === "file") prefix += "Documento";

  return prefix;
};

const previewModal: { open: boolean; answer: QuickAnswer | undefined } = reactive({
  open: false,
  answer: undefined,
});

const handlePreview = (item: QuickAnswer) => {
  previewModal.open = true;
  previewModal.answer = item;
};

const handleAdd = (item: QuickAnswer) => {
  emit("select:answer", item);
  handleClose(true);
};
</script>

<style scoped lang="sass">
.footer
  width: 100%
  display: flex
  justify-content: center
  gap: 8px

.wrapper
  padding: 20px

p
  color: $text-secondary
  margin-bottom: 20px

.list-item
  display: block

.header
  font-size: 12px
  color: $text-tertiary
  font-weight: 400

.title
  font-size: 14px
  color: $text-primary
  font-weight: 600
  display: flex

.ellipsis
  overflow: hidden
  text-wrap: nowrap
  text-overflow: ellipsis

.description
  font-size: 14px
  color: $text-secondary
  overflow: hidden
  text-wrap: nowrap
  text-overflow: ellipsis
</style>
