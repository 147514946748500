import { SET_TEXT_CONNECTION_CALL, SET_TEXT_SEQUENCE } from "./mutation-types";

const actions = {
  setTextTagConnectionCall({ commit }, currentStrategyTag) {
    commit(SET_TEXT_CONNECTION_CALL, currentStrategyTag);
  },
  setTextTagSequence({ commit }, currentSequenceTag) {
    commit(SET_TEXT_SEQUENCE, currentSequenceTag);
  },
  clearTextTgConnectionCall({ commit }) {
    commit(SET_TEXT_CONNECTION_CALL, { text: "", status: false, strategyType: null });
  },
  clearTextTagSequence({ commit }) {
    commit(SET_TEXT_SEQUENCE, { text: "", status: false });
  },
};

export default actions;
