<template>
  <a-table
    :columns="columns"
    size="middle"
    :data-source="typifications"
    :scroll="{ x: 1300 }"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #managementDateTitle>
      Fecha de gestión
      <a-tooltip :overlayStyle="{ width: '170px' }">
        <template #title> Fecha en que se realizó la tipificación. </template>
        <InfoCircleOutlined />
      </a-tooltip>
    </template>

    <template #managementDate="{ text: date }">
      {{ formatDate(date) }}
    </template>

    <template #pdpDateTitle>
      Fecha PDP
      <a-tooltip :overlayStyle="{ width: '150px' }">
        <template #title> Fecha de la promesa de pago. </template>
        <InfoCircleOutlined />
      </a-tooltip>
    </template>

    <template #pdpDate="{ text: date }">
      {{ formatDate(date) }}
    </template>

    <template #pdpAmountTitle>
      Monto PDP
      <a-tooltip :overlayStyle="{ width: '160px' }">
        <template #title> Monto de la promesa de pago. </template>
        <InfoCircleOutlined />
      </a-tooltip>
    </template>

    <template #pdpAmount="{ text: amount }">
      {{ amount }}
    </template>

    <template #observation="{ text }"> {{ text || "-" }} </template>
  </a-table>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from "vue";

import { fetchExternalTypifications } from "@/app/contacts/services";
import { columns } from "@/app/workspace/components/tables/recordTypifications/ExternalTypificationsTableColumns";
import { dateToString } from "@/app/shared/utils/dates";
import { humanDateWithSlashFormat } from "@/app/shared/utils/constants";

import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: {
    contactId: {
      required: true,
      type: Number,
    },
  },
  components: {
    InfoCircleOutlined,
  },
  watch: {
    ["props.contactId"](value) {
      console.log(contactId, "CONTACT ID EXTERNAL TYP");
      if (value) {
        this.fetchData();
      }
    },
  },
  setup(props) {
    const totalData = ref(0);
    const typifications = ref([]);
    const tablePage = ref(1);

    const pagination = computed(() => ({
      total: totalData.value,
      pageSize: 10,
      showSizeChanger: false,
    }));

    onMounted(async () => {
      await fetchData();
    });

    const formatDate = (date) => dateToString(date, humanDateWithSlashFormat);

    const fetchData = async () => {
      const response = await fetchExternalTypifications(props.contactId, tablePage.value);
      totalData.value = response?.count;
      typifications.value = response?.results;
    };

    const handleTableChange = async (page) => {
      tablePage.value = page.current;
      await fetchData();
    };

    return {
      columns,
      pagination,
      handleTableChange,
      formatDate,
      typifications,
    };
  },
});
</script>
