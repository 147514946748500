<template>
  <div>
    <floating-mode v-if="floating" />
    <fixed-mode
      v-else
      :inWorkspace="inWorkspace"
      @open-overlay="isOverlayOpen = true"
      @minimize-overlay="isOverlayOpen = false"
    />
  </div>
</template>

<script>
/**
 * Webphone component that represent the webphone floating when the user is in a call and the user is not been in workspace
 *
 */
import { computed } from "vue";

import FloatingMode from "./modes/floating/FloatingMode.vue";
import FixedMode from "./modes/fixed/FixedMode.vue";

const themes = ["light", "sky", "dark"];

const themeValidator = (value) => themes.includes(value);

export default {
  components: {
    FixedMode,
    FloatingMode,
  },
  props: {
    theme: {
      type: String,
      default: "light",
      validator: themeValidator,
    },
    height: {
      type: Number,
      default: 450,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    inWorkspace: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOverlayOpen: false,
    };
  },
  computed: {
    dynamicHeight() {
      const extraHeight = this.isOverlayOpen ? 22 : 0;

      return this.height + extraHeight;
    },
  },
  provide() {
    return {
      theme: this.theme,
      height: computed(() => this.dynamicHeight),
      floating: this.floating,
    };
  },
};
</script>
