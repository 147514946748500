export const columns = [
  {
    title: "Email",
    dataIndex: "email",
    width: 240,
  },
  {
    title: "Tipo",
    dataIndex: "type_email",
    width: 70,
  },

  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 100,
    fixed: "right",
  },
];
