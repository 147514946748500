import axios from "@/plugins/axios";

/**
 * Obtener todos los usuarios registrados.
 *
 * @return {Promise<Array>} Lista de usuarios.
 */
export async function fetchUsersByCampaign(campaignId = "") {
  const campaign = !campaignId ? "" : campaignId;
  const response = await axios.get(`/users/?portfolio=${campaign}`);
  return response?.data?.results;
}
