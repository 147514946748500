/* eslint-disable @typescript-eslint/no-explicit-any */
import { JssipManager } from "@/packages/vue-jssip/jssip";
import { App } from "vue";

export default interface VueJSSIP {
  jssip: JssipManager;
}
export default class VueJSSIP {
  constructor(options: any) {
    this.jssip = new JssipManager(options);
  }
  install(app: App) {
    app.config.globalProperties.$jssip = this.jssip;
    app.provide("$jssip", this.jssip);
  }
}
