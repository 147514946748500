import industriesStore from "@/app/common/store/industries";
import operationsStore from "@/app/common/store/operations";
import notificationsStore from "@/app/common/store/notifications";

export default {
  modules: {
    industriesStore,
    operationsStore,
    notificationsStore,
  },
};
