<template>
  <div>
    <main-logo-register />
    <div class="flex">
      <section class="container">
        <div class="container__info">
          <p class="name">¡Gracias por estar de vuelta! 🤗</p>
          <p class="text--plan">Ingresa tu correo electrónico con tu última</p>
          <p class="text--plan">contraseña para recuperar tu equipo de trabajo.</p>
        </div>

        <a-form ref="formRef" class="form" :model="formState" :rules="rules" layout="vertical">
          <a-row :gutter="24" type="flex">
            <a-col :span="24">
              <a-form-item label="Correo electrónico" name="username">
                <a-input
                  v-model:value="formState.username"
                  placeholder="Escribir correo electrónico"
                  required="required"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24" type="flex">
            <a-col :span="24">
              <a-form-item label="Contraseña" name="password">
                <a-input-password
                  v-model:value="formState.password"
                  placeholder="Escribir contraseña"
                  required="required"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="text__circle">
          <p>
            <InfoCircleOutlined class="info" /> Recuerda que esta acción solo la puede realizar el
            Owner
          </p>
        </div>
        <div class="container__buttom">
          <a-button
            type="primary"
            class="buttom__register"
            @click="handleSubmitData"
            :disabled="isDisabled"
            >Recuperar equipo de trabajo</a-button
          >
        </div>
      </section>
      <section class="drawing">
        <img src="@/app/shared/assets/characters/silvia.png" alt="imagen" />
        <p class="text--score">¡Volvamos a trabajar juntos!</p>
      </section>
    </div>
  </div>
</template>

<script>
import MainLogoRegister from "@/app/shared/components/molecules/MainLogoRegister.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { validateTokenWorkTeam } from "@/app/settingsMenu/services";

export default defineComponent({
  components: {
    MainLogoRegister,
    InfoCircleOutlined,
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      username: "",
      password: "",
    });
    const rules = {
      username: [{ required: true, message: "Campo requerido" }],
      password: [{ required: true, message: "Campo requerido" }],
    };
    return {
      formRef,
      formState,
      rules,
    };
  },
  created() {
    this.validateToken();
  },
  computed: {
    isDisabled() {
      return this.formState.username === "" || this.formState.password === "";
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    setPassword($event) {
      this.password = $event;
    },
    async handleSubmitData() {
      this.formRef.validateFields().then(async () => {
        this.loading = true;
        try {
          await this.$store.dispatch("fetchAccessToken", toRaw(this.formState));
        } catch (error) {
          this.$notification.error({
            message: "Los datos ingresados no pertenecen al Owner de tu empresa.",
          });
        } finally {
          this.loading = false;
        }
      });
    },
    async validateToken() {
      try {
        const token = this.$route.params.token;
        await validateTokenWorkTeam(token);
        this.$router.push("/login");
      } catch (error) {
        const firstError = error?.errors;
        if (firstError === "Token invalido") {
          this.$notification.error({
            message: "Token invalido",
          });
        } else if (firstError === "Equipo de trabajo eliminado permanentemente.") {
          this.$router.push("/deleted/account");
          this.$notification.error({
            message: "Equipo de trabajo eliminado permanentemente",
          });
        }
        console.log("ERROR");
      }
    },
  },
});
</script>

<style lang="sass" scoped>


.container
  margin: 0 auto

.drawing
  margin-top: 160px
  width: 50%
  text-align: center

.text--plan
  font-size: 20px
  font-weight: 400
  margin-bottom: 0

.text--score
  font-size: 20px
  font-weight: 400
  margin-bottom: 0

.container__info
  text-align: center
  margin-bottom: 50px
  margin-top: 110px

.name
  font-weight: 600
  font-size: 30px

.flex
  display: flex
  justify-content: space-around

.form
  margin: 0 auto

.info
  margin-top: 5px
  margin-right: 5px

.text__circle
  font-style: italic
  font-weight: normal
  font-size: 14px
  text-align: center

.container__buttom
  text-align: center
  margin-top: 30px

.buttom__register
  width: 100%
</style>
