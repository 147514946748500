<template>
  <a-modal
    :visible="visible"
    :title="`Actualizar troncal - ${trunk.name}`"
    :body-style="{ height: '640px' }"
    centered
    width="580px"
    :footer="null"
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
  >
    <CloseOutlined class="iconoCerrado" @click="handleCloseConfirmationModal" />
    <div class="formtrunk">
      <section class="steps">
        <CustomStepper :value="currentStep" @update:value="currentStep = $event" :items="steps" />
      </section>

      <div>
        <div v-show="currentStep == 0" class="modalPage">
          <trunk-one
            :update="update"
            ref="trunkOne"
            @handleCancel="handleCloseConfirmationModal"
            @handleOk="handleGoToNextStep"
            @edit="handleShowConfirmationModal"
          />
        </div>
        <div v-show="currentStep == 1" class="modalPage">
          <trunk-two
            :update="update"
            ref="trunkTwo"
            @handleCancel="handleGoToPreviousStep"
            @handleOk="handleSave"
            @edit="handleShowConfirmationModal"
          ></trunk-two>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { createVNode, reactive } from "vue";
import { Modal } from "ant-design-vue";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

import TrunkOne from "@/app/pbx/components/forms/TrunkOne.vue";
import TrunkTwo from "@/app/pbx/components/forms/TrunkTwo.vue";
import CustomStepper from "@/app/shared/components/organisms/CustomStepper.vue";

export default {
  components: {
    TrunkOne,
    TrunkTwo,
    CustomStepper,
    CloseOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    update: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const trunk = store.getters["getTrunk"];
    const updateProps = props.update;
    const formState = reactive({
      name: props.update ? trunk.name : "",
      observations: props.update ? trunk.observations : "",
      use_incoming_calls: props.update ? trunk.use_incoming_calls : true,
      host_server: props.update ? trunk.host_server : "",
      port: props.update ? trunk.port : "",
      // context: props.update ? trunk.context : "",
      // type_trunk: props.update ? trunk.type_trunk : "",
      dtmf_mode: props.update ? trunk.dtmf_mode : "",
      codecs: props.update ? trunk.codecs.map((codec) => codec.id) : [],
      qualify: props.update ? trunk.qualify : "",
      type_authentication: props.update ? trunk.type_authentication : 1,
      username: props.update ? trunk.username : null,
      password: props.update ? trunk.password : null,
      termination_sip: props.update ? trunk.termination_sip : null,
      contact_user: props.update ? trunk.contact_user : null,
    });

    return { formState, updateProps };
  },
  provide() {
    return {
      formState: this.formState,
    };
  },
  data() {
    return {
      antes: "Descartar",
      despues: "Continuar",
      currentStep: 0,
      steps: ["Conf. General", "Datos proveedor"],
      showConfirmModal: false,
    };
  },
  computed: {
    trunk() {
      let value = {};
      if (this.$store.getters["getTrunk"]) {
        value = this.$store.getters["getTrunk"];
      }
      return value;
    },
  },
  methods: {
    handleCloseConfirmationModal() {
      if (this.showConfirmModal) {
        Modal.confirm({
          title: "¿Deseas descartar los cambios realizados?",
          content: "Recuerda que si no guardan los cambios registrados, estos se perderán",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleCancel(),
          centered: true,
          okText: "Sí, descartar cambios",
          cancelText: "No, seguir editando",
        });
      } else {
        this.handleCancel();
      }
    },
    handleShowConfirmationModal() {
      this.showConfirmModal = true;
    },
    handleGoToNextStep() {
      this.currentStep++;
    },
    handleGoToPreviousStep() {
      this.currentStep--;
    },
    async handleSave(formState) {
      const update = this.updateProps;
      try {
        this.currentStep = 0;
        if (update) {
          const id = this.trunk.id;
          await this.$store.dispatch("updateTrunk", {
            id: id,
            params: { ...formState, codecs_id: formState.codecs },
          });
        } else {
          await this.$store.dispatch("createNewTrunk", {
            ...formState,
            codecs_id: formState.codecs,
          });
        }
        this.$emit("update");
        this.$emit("emitVisible");
        this.$store.dispatch("clearTrunk");
        this.$refs.trunkOne?.resetForm();
        this.$refs.trunkTwo?.resetForm();
        if (update) this.$message.success("Se modificó correctamente");
        else this.$message.success(`Se ha integrado la nueva troncal correctamente`);
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        this.$message.error(errorMessage);
      }
    },
    handleCancel() {
      this.showConfirmModal = false;
      this.$emit("emitVisible");
      this.$store.dispatch("clearTrunk");
      this.$refs.trunkOne?.resetForm();
      this.$refs.trunkTwo?.resetForm();
    },
  },
  /*watch: {
    trunk(val) {
      Object.assign(this.formState, val);
    },
  },*/
};
</script>

<style lang="sass" scoped>
.iconoCerrado
  position: absolute
  top: 22px
  right: 16px

.formtrunk
  display: block
  margin: 0 auto
  padding: 0 10px

:deep(.ant-modal-body)
  padding: 24px 0 !important

.stepper
  padding-top: 20px !important
</style>
