import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un correo.
 *
 * @param {number} emailId Identificador del correo.
 */
export async function deleteAddressContact(addressId) {
  await httpManager.delete(`/contacts/clients/addresses/${addressId}/`);
}
