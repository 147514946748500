<template>
  <template v-if="conclusion && type === 'management'">
    <label>
      <span class="management-title">
        <AISparksIcon /> Conclusión generada por {{ CompanyName }} AI</span
      >

      <div class="ai-textarea-wrapper ai-modal">
        <a-textarea :autosize="{ minRows: 3, maxRows: 6 }" class="textarea" :value="conclusion" />

        <div class="icons">
          <a-tooltip>
            <template #title>Buena conclusión </template>
            <IconHappyFace :border="false" @click="handleShowModal(1)" />
          </a-tooltip>

          <a-tooltip>
            <template #title>Mala conclusión </template>
            <IconSadFace :border="false" @click="handleShowModal(2)" />
          </a-tooltip>
        </div>
      </div>
    </label>

    <!-- <div class="rating-container">
      <p>¡Tu opinión nos importa! 💛 ¿Cómo calificarías la conclusión obtenida por Score AI?</p>

      <a-rate class="rating" v-model:value="rating" @change="emit('selectRating', $event)" />
    </div> -->
  </template>

  <div
    v-if="conclusion && ['chat', 'history'].includes(type)"
    style="position: relative; z-index: 1"
  >
    <div class="ai-textarea-wrapper text-box" :class="{ history: type === 'history' }">
      <div class="gradient">
        <span class="title"> <AISparksIcon /> Conclusión generada por {{ CompanyName }} AI </span>
        <a-tooltip>
          <template #title>Generado desde Gestor de tickets por {{ username }}</template>
          <InfoCircleOutlined />
        </a-tooltip>

        <p class="conclusion">
          <span>
            {{
              conclusion.length > CONTENT_LENGTH_LIMIT
                ? ellipsis
                  ? getConclusionWithEllipsis(conclusion)
                  : conclusion
                : conclusion
            }}
          </span>

          <a-button
            v-if="conclusion.length > CONTENT_LENGTH_LIMIT"
            type="link"
            class="text-button"
            @click="ellipsis = !ellipsis"
          >
            {{ ellipsis ? "Ver más" : "Ver menos" }}
          </a-button>
        </p>
        <div class="icons">
          <a-tooltip>
            <template #title>Buena conclusión </template>
            <IconHappyFace :border="false" @click="handleShowModal(1)" />
          </a-tooltip>
          <a-tooltip>
            <template #title>Mala conclusión </template>
            <IconSadFace :border="false" @click="handleShowModal(2)" />
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
  <AIFeedback
    v-if="openFeedbackModal"
    v-model:open="openFeedbackModal"
    :emojiFace="computedEmojiFace"
    :summaryId="summaryId"
  />
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import AISparksIcon from "@/app/integrations/components/icons/AISparksIcon.vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import IconHappyFace from "@/app/common/components/atoms/IconHappyFace.vue";
import IconSadFace from "@/app/common/components/atoms/IconSadFace.vue";
import AIFeedback from "@/app/common/components/organisms/AIFeedback.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { CompanyName } from "@/app/shared/utils/constants";

defineProps<{
  conclusion: string;
  type: "management" | "chat" | "history";
  summaryId: number;
  messages: Array<unknown>;
  username: string;
}>();

// const emit = defineEmits<{ selectRating: [rating: number] }>();

// const rating = ref(0);
const ellipsis = ref(true);
const openFeedbackModal = ref(false);
// const isFeedbackDisabled = ref(false);
const emojiFace = ref(0);

const CONTENT_LENGTH_LIMIT = 110;

const getConclusionWithEllipsis = (text: string) => {
  const subtext = text.substring(0, CONTENT_LENGTH_LIMIT);

  return `${subtext.trimEnd()}...`;
};

const handleShowModal = (face: number) => {
  openFeedbackModal.value = true;
  emojiFace.value = face;
};

EventBus.on("showFeedbackModal", () => {
  console.log("ITS CALLED");
  openFeedbackModal.value = true;
});
// EventBus.on("displayFeedback", () => {
//   console.log("IT WAS CALLING GENERATED");
//   openFeedbackModal.value = true;
// });

watch(emojiFace, (newValue) => {
  console.log("Emoji face changed:", newValue);
});
watch(openFeedbackModal, (newValue) => {
  console.log("Emoji face changed:", newValue);
});

const computedEmojiFace = computed(() => {
  return emojiFace.value;
});
</script>

<style scoped lang="sass">
label
  position: relative
  z-index: 1

.management-title
  color: $text-primary
  line-height: 22px
  display: flex
  align-items: center
  gap: 4px
  padding-bottom: 4px

.textarea
  border: none
  color: $text-primary

  &:hover
    border-color: transparent !important

.ai-textarea-wrapper
  // background-clip: padding-box
  background: white
  // border: 2px solid transparent
  position: relative
  border-radius: 6px

  &::before
    content: ''
    position: absolute
    display: block
    inset: -2px
    z-index: -1
    border-radius: 8px
    background: linear-gradient(180deg, #D62FA0, #762ECF)
    overflow: hidden
  .icons
    svg
      outline: none

.text-box
  background: white
  border: none
  border-width: 1px

  &.history
    border-radius: 0
    width: calc(100% - 1px)

    &::before
      border-radius: 0

  &::before
    inset: -1px
    border-radius: 7px

  .gradient
    background: linear-gradient(180deg, rgba(213, 47, 161, 0.20) 0%, rgba(125, 47, 204, 0.20) 100%)
    text-align: center
    padding: 8px 20px

    .title
      justify-content: center
      color: $text-primary
      font-weight: 600
      font-size: 12px
      gap: 8px
      margin-bottom: 2px

    .conclusion
      color: $text-primary
      font-size: 12px
      line-height: 20px
      margin: 0
      text-align: center

    .text-button
      font-size: 12px
      display: inline-block
      padding: 0
      height: auto
      margin-left: 5px
    .icons
      display: flex
      cursor: pointer
      svg
        height: 30px
        width: 30px
        outline: none
        &:hover
          path
            fill: black
            opacity: 1

.rating-container
  margin-top: 10px
  padding: 8px
  color: $text-primary
  background-color: rgba(242, 245, 247, 0.50)

  p
    margin-bottom: 4px

  .rating
    line-height: 20px
</style>
<style>
.gradient .icons svg:hover path {
  fill: black;
}
.ai-modal .icons {
  display: flex;
  cursor: pointer;
  background-color: white;
  padding-top: 10px;
}
.ai-modal .icons svg {
  height: 30px;
  width: 30px;
}
</style>
