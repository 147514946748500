import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener detalles de una etiqueta
 *
 * @param {number} tagId Identificador de una etiqueta
 *
 * @return {Promise<Object>} Detalles de la etiqueta.
 */
export async function getTagDetails(tagId) {
  const response = await httpManager.get(`/tickets/tags/${tagId}/`);
  return response?.data;
}
