<template>
  <main-logo-register />

  <FormCard
    :image-src="workspaceData?.picture"
    title="¿Has olvidado tu contraseña?"
    description="Si estás registrado, ingresa tu correo electrónico y te enviaremos instrucciones para restablecerla."
  >
    <recover-password-form v-if="firtScreen" @onFinish="handleOnFinish" />
    <recover-password-success v-if="secondScreen" @onFinish="handleOnFinish" />

    <div class="back">
      <a-button type="link" @click="$router.push('/login')">
        Regresar a la página de Iniciar sesión
      </a-button>
    </div>
  </FormCard>
</template>

<script>
import httpManager from "@/app/shared/utils/httpManager";

import MainLogoRegister from "@/app/shared/components/molecules/MainLogoRegister.vue";
import RecoverPasswordForm from "@/app/auth/components/organisms/RecoverPasswordForm.vue";
import RecoverPasswordSuccess from "@/app/auth/components/organisms/RecoverPasswordSuccess.vue";
import FormCard from "../atoms/FormCard.vue";
import { useValidateWorkspace } from "../../composables/useValidateWorkspace";

export default {
  components: {
    MainLogoRegister,
    RecoverPasswordForm,
    RecoverPasswordSuccess,
    FormCard,
  },
  setup() {
    const workspaceData = useValidateWorkspace();

    return { workspaceData };
  },
  data() {
    return {
      username: "",
      progresssLogin: 1,
      password: "",
    };
  },
  computed: {
    firtScreen() {
      return this.progresssLogin === 1;
    },
    secondScreen() {
      return this.progresssLogin === 2;
    },
    progress() {
      return this.$store.getters["getLoginProgress"];
    },
  },
  methods: {
    handleOnFinish($event) {
      this.progresssLogin = $event;
    },
    async handleSubmit() {
      try {
        const data = {
          token: this.token,
          password: this.password,
        };
        this.$store.dispatch("confirmPassword", data);
      } catch (error) {
        this.$message.error("Tienes que aceptar los Terminos y Condiciones para poder continuar");
      }
    },
    async validateToken() {
      try {
        const token = this.$route.query.token;
        httpManager.updateBaseURL(
          `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
        );
        await this.$store.dispatch("validateEmailToken", token);
      } catch {
        this.$message.error("error al validar el token");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.container
  width: 40%
  margin-top: 100px
  margin-left: 7%

.name
  font-weight: 600
  font-size: 30px

.subtitle
  font-size: 20px

.container__info
  margin: 0 auto
  width: 70%
  margin-top: 70px
  text-align: center
  margin-bottom: 50px

.flex
  display: flex
  justify-content: space-between

.drawing
  margin-top: 240px
  width: 50%
  text-align: center

.text__success
  font-size: 20px
  font-weight: 400
  margin-top: 30px

.back
  margin-top: 32px
  width: 100%
  text-align: center
</style>
