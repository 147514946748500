import SchedulePage from "@/app/schedule/pages/SchedulePage.vue";

export default [
  {
    path: "/schedule",
    name: "schedule",
    component: SchedulePage,
    meta: {
      requiresAuth: true,
    },
  },
];
