<template>
  <section>
    <EditOutlined class="icon" @click="handleShowModal" />
    <a-drawer
      v-model:visible="visible"
      title="Editar dirección"
      destroy-on-close
      :mask-closable="false"
      placement="right"
      width="480"
      keyboard
      :body-style="{ padding: 0 }"
      @close="handleCloseDrawer"
    >
      <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
        <a-form
          class="form"
          layout="vertical"
          hide-required-mark
          ref="formRef"
          :model="formState"
          :rules="rules"
          @submit.prevent="handleSubmit"
        >
          <a-form-item label="Dirección" name="address">
            <a-input v-model:value="formState.address" placeholder="Escribe aquí" />
          </a-form-item>
          <a-form-item label="Tipo" name="type_address">
            <a-input v-model:value="formState.type_address" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Departamento" name="department">
            <a-input v-model:value="formState.department" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Provincia" name="province">
            <a-input v-model:value="formState.province" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Distrito" name="district">
            <a-input v-model:value="formState.district" placeholder="Escribe aquí" />
          </a-form-item>
        </a-form>
      </custom-scrollbar>

      <div class="footer">
        <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
        <a-button type="primary" @click="handleSubmit"> Guardar cambios </a-button>
      </div>
    </a-drawer>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import { EditOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    EditOutlined,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      address: "",
      district: "",
      province: "",
      type_address: "",
      department: "",
    });

    return {
      formState,
      formRef,
    };
  },
  data() {
    return { visible: false };
  },
  methods: {
    handleShowModal() {
      this.visible = true;
      this.formState.address = this.record.address;
      this.formState.district = this.record.district;
      this.formState.province = this.record.province;
      this.formState.type_address = this.record.type_address;
      this.formState.department = this.record.department;
    },
    handleCloseDrawer() {
      this.visible = false;
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("updateAddressContact", {
            addressId: this.record.id,
            body: toRaw(this.formState),
          });
          this.handleCloseDrawer();
          this.$message.success("Se ha editado información correctamente");
        } catch (error) {
          this.$message.error("Se ha producido un error al editar la información");
        }
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

:deep(.ant-calendar-picker)
  width: 100%
.icon
  color: $blue-6
</style>
