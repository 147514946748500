<template>
  <section class="input-main-container">
    <FilePreview
      v-if="file.file"
      style="margin-bottom: 12px"
      :file="file"
      @remove="removeFile"
      can-delete
    />

    <div class="file-preview-chat-container">
      <FilePreview
        v-for="(file, pos) in files"
        style="margin-bottom: 12px"
        :file="file"
        :position="pos"
        @remove="removeFile"
        can-delete
      />
    </div>

    <div class="container">
      <div
        class="input__wrapper"
        :class="{
          transparent: showRecorder,
          'text-cursor': hasWrapperCursorText,
          'not-allowed-cursor': disabled,
        }"
      >
        <!-- Voice Recorder -->
        <VoiceRecorder
          :showRecorder="showRecorder"
          ref="voiceRecorderRef"
          @stop="isRecording = false"
          @delete="handleDeleteAudio"
        />

        <!-- Text Input -->
        <template v-if="!showRecorder">
          <span class="input__text input__placeholder" v-if="disabled">
            Campo bloqueado

            <a-tooltip :title="tooltipText">
              <InfoCircleOutlined />
            </a-tooltip>
          </span>

          <span class="input__text input__placeholder" v-else-if="showPlaceholder">
            Escribir aquí...
          </span>

          <div class="input__scroll" v-if="!disabled && !isRecording">
            <!-- @focus="handleEventWriting" -->
            <div
              ref="textInputRef"
              class="input input__text"
              contenteditable="true"
              @input="handleInput"
              @keydown.enter.exact.prevent="handleSetMessage($event, { isEnterKey: true })"
              @blur="handleSetMessage($event)"
              @paste="handlePaste"
            />
          </div>
        </template>
      </div>

      <a-button
        class="action"
        type="primary"
        :disabled="disabled"
        @click="handlePrimaryButtonClick"
      >
        <template #icon>
          <SendOutlined v-if="showSendButton" />

          <AudioOutlined v-else />
        </template>
      </a-button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, onMounted } from "vue";
import { AudioOutlined, SendOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";

import type { FileMetadata, RecordMetadata } from "@/@types/workspace";

import VoiceRecorder from "./VoiceRecorder.vue";
import FilePreview from "./FilePreview.vue";

import { EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const props = defineProps<{
  file: FileMetadata;
  files: FileMetadata[];
  tooltipText: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:message", message: string): void;
  (event: "delete-file", position: number | undefined): void;
  (event: "submit"): void;
  (event: "paste-file", file: File | undefined): void;
  (event: "handleEventGlobal"): void;
}>();

const voiceRecorderRef = ref<InstanceType<typeof VoiceRecorder>>();
const textInputRef = ref<HTMLDivElement | null>(null);

const showPlaceholder = ref<boolean>(true);
const hasValidMessage = ref<boolean>(false);

const route = useRoute();
const store = useStore();

const showSendButton = computed<boolean>(
  () => hasValidMessage.value || showRecorder.value || Boolean(props.file.file) || props.files.length > 0
);
const hasWrapperCursorText = computed<boolean>(() => !props.file.file && props.files.length == 0 && !showRecorder.value);

const dataUserState = computed(() => store.getters["getDataUserState"]);

// Just validates if the placeholder should be showed
const handleInput = (event: Event) => {
  const target = event?.target as HTMLInputElement;
  const textLength = target.textContent?.length;
  const trimmedText = target.textContent?.trim() || "";

  // console.log({ text: target.textContent, length: target.textContent?.length });

  // Avoid reassign showPlaceholder to true when it is already true;
  if (textLength === 0) showPlaceholder.value = true;
  else if (showPlaceholder.value) showPlaceholder.value = false;

  hasValidMessage.value = Boolean(trimmedText);

  handleEventWriting();
};

const addCharToInputContent = async (char: string) => {
  if (textInputRef.value) {
    textInputRef.value.textContent += char;

    textInputRef.value.focus();

    await nextTick();

    window.getSelection()?.selectAllChildren(textInputRef.value);
    window.getSelection()?.collapseToEnd();

    if (showPlaceholder.value) showPlaceholder.value = false;
    hasValidMessage.value = Boolean(char);
  }
};

const handlePaste = (event: ClipboardEvent) => {
  const copyFiles = event.clipboardData?.files;
  if (copyFiles?.length) {
    event.preventDefault();
    emit("paste-file", copyFiles?.[0]);
  }
};

const handleEventWriting = () => {
  registerEvent(EventManagement.Writing);
};

const handleGlobalEvent = () => {
  emit("handleEventGlobal", textInputRef.value?.textContent);
  // if (props.inputMessage == "" && props.file?.file == null) registerEvent(globalState.value);
};

const handleSetMessage = (event: Event, { isEnterKey = false } = {}) => {
  const target = event.target as HTMLInputElement;
  const message = target.textContent || "";

  if (store.getters["getEventMessaging"] == EventManagement.Writing) {
    handleGlobalEvent();
  }

  if (!message) return;
  // console.log(target.textContent);
  if (hasValidMessage.value) emit("update:message", message);
  const sendOptionEnterKey: boolean =
    localStorage.getItem("messageSendOption") == "true" ? true : false;
  if (isEnterKey && sendOptionEnterKey) handleSubmit();

  console.log(isEnterKey, "isEnterKey");
  console.log(localStorage.getItem("messageSendOption"), "messageSendOption");
};

// const handleSetAudio = (record: RecordMetadata) => {
//   console.log(record);
//   recordMetadata.value = record;
// };

const handleDeleteAudio = (changeEvent = true) => {
  console.log("handleDeleteAudio", changeEvent);
  showPlaceholder.value = true;
  isRecording.value = false;
  if (changeEvent == true) handleGlobalEvent();
};

const handleDeleteMessage = (isTypeAudio = false) => {
  if (textInputRef.value) {
    textInputRef.value.textContent = "";
    hasValidMessage.value = false;
    emit("update:message", "");
  }

  showPlaceholder.value = true;
  voiceRecorderRef.value?.deleteRecord(!isTypeAudio);
};

const handlePrimaryButtonClick = () => {
  if (showSendButton.value) handleSubmit();
  else startRecording();
};

const handleSubmit = async () => {
  if (isRecording.value) await voiceRecorderRef.value?.stopRecording();

  emit("submit");
  console.log("handle global event");
  handleGlobalEvent();
};

const isRecording = ref<boolean>(false);
// const recordMetadata = ref<RecordMetadata>();
const showRecorder = computed<boolean>(
  () => isRecording.value || Boolean(voiceRecorderRef.value?.audioMetadata.recordingSrc)
);

const startRecording = async () => {
  showPlaceholder.value = false;
  isRecording.value = true;

  handleEventWriting();
  await nextTick();

  voiceRecorderRef.value?.handleRecordClick();
};

const getRecordMetadata = () => voiceRecorderRef.value?.audioMetadata;

const setRecordMetadata = (record: RecordMetadata) => {
  if (!voiceRecorderRef.value?.audioMetadata) return;

  Object.assign(voiceRecorderRef.value.audioMetadata, record);
};

const registerEvent = (event: number) => {
  if (
    !(
      event == EventManagement.Writing &&
      store.getters["getEventMessaging"] == EventManagement.Writing
    )
  ) {
    const ticket = route.query.ticket;
    registerEventManagement(event, {
      open_ticket: ticket,
      menu: 23,
      state: dataUserState.value,
      interaction_id: event == EventManagement.Writing ? route.query.interaction : undefined,
    });
    store.dispatch("setEventMessaging", event);
  }
};

const removeFile = async (position?: number) => {
  emit("delete-file", position);
  await nextTick();
  handleGlobalEvent();
};

onMounted(async () => {
  await setTimeout(async () => {
    const element = textInputRef.value;
    console.log(element, "element?.innerHTML ant");
    await nextTick();
    console.log(element?.innerHTML, "element?.innerHTML");
    if (element?.innerHTML && element?.innerHTML?.length > 0) {
      handleEventWriting();
    }
  }, 1000);
});

defineExpose({
  getRecordMetadata,
  setRecordMetadata,
  addCharToInputContent,
  handleDeleteMessage,
  textInputRef,
});
</script>

<style lang="sass" scoped>
.container
  width: 100%
  display: flex
  align-items: flex-end
  gap: 8px

.input
  user-select: text
  white-space: pre-wrap
  overflow-wrap: break-word
  -webkit-user-modify: read-write-plaintext-only

  &__wrapper
    outline: none
    border-radius: 20px
    width: calc(100% - 40px)
    min-height: 40px
    max-height: 88px
    padding: 8px 12px
    background-color: white
    border: 1px solid $gray-5
    position: relative

    &.text-cursor
      cursor: text

    &.not-allowed-cursor
      cursor: not-allowed
      background-color: #0000000A

    &.transparent
      background-color: transparent

  &__scroll
    max-height: 70px
    overflow-y: auto
    scrollbar-width: thin
    scrollbar-color: #c1c1c1 transparent

    &::-webkit-scrollbar
      position: absolute
      width: 4px

    &::-webkit-scrollbar-track
      background: transparent

    &::-webkit-scrollbar-thumb
      background-color: #c1c1c1
      border-radius: 2px

  &__text
    font-weight: 400
    line-height: 24px
    font-size: 14px
    color: $gray-9
    position: relative
    z-index: 1

  &__placeholder
    color: $gray-6
    user-select: none
    position: absolute
    z-index: 1

  &:focus
    outline: none

.action
  color: white
  font-size: 12px
  padding: 0
  height: 32px
  width: 32px
  min-width: 32px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 5px

  &:disabled
    color: #00000040

.file-preview-chat-container
  display: grid
  grid-template-columns: repeat(2, 1fr)
  gap: 12px
</style>
