const initialState = () => ({
  groupTypes: [
    { id: 1, name: "Grupo contacto directo" },
    { id: 2, name: "Grupo contacto indirecto" },
    { id: 3, name: "Grupo no contacto" },
  ],
});

const state = initialState();

export default state;
