<template>
  <section class="container">
    <a-form layout="vertical" class="form">
      <a-input ref="searchInput" v-model:value="filterState.search" placeholder="Buscar nombre">
        <template #prefix>
          <SearchOutlined />
        </template>
      </a-input>

      <div class="row">
        <channels-picker
          defaultBehavior
          class="input-channel"
          :value="filterState.channel"
          @update:value="
            filterState.channel = $event;
            filterState.identifier = '';
          "
        />
        <TicketsFilterModal />
      </div>
    </a-form>
    <div class="select__contact--all">
      <a-checkbox
        v-model:checked="selectedAll"
        @change="handleChangeSelectAll"
        :disabled="ticketsWithOutVoice.length === 0"
      >
        Seleccionar todo
      </a-checkbox>

      <div class="container__options">
        <a-popover trigger="click">
          <template #content>
            <div class="more-popover">
              <tag-list
                :tickets="[...selectedTickets]"
                :simple-tag="selectedTickets.length === 1"
                :has-selected-tickets="selectedTickets.length > 0"
                @update:ticket="$emit('update:ticket')"
              />

              <Transfer :has-selected-tickets="selectedTickets.length > 0" />
            </div>
          </template>

          <a-button class="button" type="text">
            <MoreOutlined class="more-icon" />
          </a-button>
        </a-popover>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, inject, watch } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons-vue";
import _ from "lodash";

import { ChannelType } from "@/app/shared/utils/enums";

import ChannelsPicker from "@/app/shared/components/pickers/ChannelsPicker.vue";
import Transfer from "@/app/workspace/components/filters/Transfer.vue";
import TagList from "@/app/workspace/components/filters/TagList.vue";
import TicketsFilterModal from "@/app/workspace/components/filters/TicketsFilterModal.vue";

export default defineComponent({
  components: {
    ChannelsPicker,
    Transfer,
    TagList,
    SearchOutlined,
    TicketsFilterModal,
    MoreOutlined,
  },
  setup() {
    const store = useStore();

    const filterState = inject("filterState");
    const ticketsListLoading = inject("ticketsListLoading");

    watch(
      filterState,
      _.debounce(async () => {
        store.commit("SET_TICKETS_FILTERS", filterState);

        ticketsListLoading.value = true;

        await store.dispatch("fetchActiveWorkspaceTickets");

        ticketsListLoading.value = false;
      }, 500),
      { deep: false }
    );

    return { filterState };
  },
  data() {
    return {
      queue: 0,
      channel: 0,
      search: "",
    };
  },
  computed: {
    tickets() {
      return this.$store.getters["getWorkspaceTickets"];
    },
    selectedTickets() {
      return this.$store.getters["getSelectedTickets"] || [];
    },
    ticketsWithOutVoice() {
      return this.tickets.filter(
        (ticket) => ![ChannelType.Voice, ChannelType.VoiceInbound].includes(ticket.channel.id)
      );
    },
    selectedAll() {
      return (
        this.ticketsWithOutVoice.length > 0 &&
        this.ticketsWithOutVoice.length == this.selectedTickets.length
      );
    },
  },
  methods: {
    handleChangeSelectAll(event) {
      this.$store.commit("SELECT_ALL_TICKETS", event.target.checked);
    },
  },
});
</script>

<style lang="sass" scoped>
.container
  margin: 12px

  &__filters
    margin: 16px 0

  &__options
    gap: 8px
    display: flex
    justify-content: space-between

.select__contact--all
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 15px

.ant-form-item
  margin-bottom: 0

.button
  height: 24px
  padding: 0

.more
  &-icon
    font-size: 20px
    color: $gray-8

  &-popover
    display: flex
    flex-direction: column
    gap: 4px

.form
  .row
    width: 100%
    display: flex
    gap: 8px
    margin-top: 12px

    .input
      &-channel
        flex: 1 1 auto
</style>
