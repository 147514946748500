import { Commit } from "vuex";

export interface IvrVars {
  variable_name: string;
  answer: string;
}

interface IvrModuleState {
  ivr_answer: IvrVars[];
}

interface VuexContext {
  commit: Commit;
}

const ivrModule = {
  state: {
    ivr_answer: [],
  },
  getters: {},
  actions: {
    setIvrAnswer({ commit }: VuexContext, payload: IvrVars[]) {
      commit("SET_IVR_ANSWER", payload);
    },
    resetIvrAnswer({ commit }: VuexContext) {
      commit("SET_IVR_ANSWER", []);
    },
  },
  mutations: {
    SET_IVR_ANSWER(state: IvrModuleState, payload: IvrVars[]) {
      state.ivr_answer = payload;
    },
  },
};

export default ivrModule;
