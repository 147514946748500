const initialState = () => ({
  ticketsData: {},
  transferData: {},
  tablePage: 1,
  ticketsBasicFilters: null,
  ticketsAdvancedFilters: null,
  advancedFiltersData: null,
  usersData: null,
  ticketManagerChannels: [
    { value: "", label: "Todos los canales", id: "" },
    { value: "messaging", label: "Canales de mensajeria" },
    { value: "voice", label: "Voz" },
  ],
  loading: false,
});

const state = initialState();

export default state;
