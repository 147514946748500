import { DownloadStatus, Status, DownloadStatusNumber } from "@/@types/settingsMenu/downloads";

const STATUS_DOWNLOAD: Record<DownloadStatusNumber, Status> = {
  [DownloadStatusNumber.Pending]: {
    actions: ["cancel"],
    tag: {
      title: "En espera",
      color: "default",
      icon: "loading",
    },
    id: DownloadStatusNumber.Pending,
    status: DownloadStatus.Pending,
    tooltip:
      "El archivo ha sido seleccionado y está en espera para iniciar su proceso y poder ser descargado.",
  },
  [DownloadStatusNumber.Processing]: {
    actions: ["cancel"],
    tag: {
      title: "En proceso",
      color: "orange",
      icon: "download",
    },
    bg_color: "orange",
    id: DownloadStatusNumber.Processing,
    status: DownloadStatus.Processing,
    tooltip: "El archivo se está procesando para poder ser descargado.",
  },
  [DownloadStatusNumber.Finished]: {
    actions: ["download"],
    tag: {
      title: "Completado",
      color: "green",
      icon: "check-circle",
    },
    bg_color_new: "green",
    id: DownloadStatusNumber.Finished,
    status: DownloadStatus.Finished,
    tooltip: "El archivo está listo para ser descargado.",
    tooltip_disabled:
      "Descarga no disponible. El archivo solo se podrá descargar durante 24 horas después de su procesamiento completado.",
  },
  [DownloadStatusNumber.Failed]: {
    actions: ["delete"],
    tag: {
      title: "Error de descarga",
      color: "red",
    },
    bg_color: "red",
    id: DownloadStatusNumber.Failed,
    status: DownloadStatus.Failed,
    tooltip:
      "Se produjo un error al procesar el archivo. Por favor, inténtalo nuevamente para que pueda ser descargado correctamente.",
  },
  [DownloadStatusNumber.Canceled]: {
    actions: ["delete"],
    tag: {
      title: "Cancelado",
      color: "volcano",
      icon: "stop",
    },
    id: DownloadStatusNumber.Canceled,
    status: DownloadStatus.Canceled,
    tooltip: "La descarga ha sido cancelada antes de completarse.",
    is_canceled: true,
  },
};

const TYPES_DOWNLOADS = {
  for_strategy: {
    title: "Estrategia",
  },
  for_reports: {
    title: "Reporte",
  },
  for_contact: {
    title: "Todos los contactos",
  },
  for_dashboad: {
    title: "Dashboard",
  },
  for_template: {
    title: "Plantilla",
  },
};

export { STATUS_DOWNLOAD, TYPES_DOWNLOADS };
