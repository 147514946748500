<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 3.75C17.3096 3.75 16.75 4.30964 16.75 5C16.75 5.69036 17.3096 6.25 18 6.25C18.6904 6.25 19.25 5.69036 19.25 5C19.25 4.30964 18.6904 3.75 18 3.75ZM15.25 5C15.25 3.48122 16.4812 2.25 18 2.25C19.5188 2.25 20.75 3.48122 20.75 5C20.75 6.51878 19.5188 7.75 18 7.75C16.4812 7.75 15.25 6.51878 15.25 5ZM6 17.75C5.30964 17.75 4.75 18.3096 4.75 19C4.75 19.6904 5.30964 20.25 6 20.25C6.69036 20.25 7.25 19.6904 7.25 19C7.25 18.3096 6.69036 17.75 6 17.75ZM8.64648 18.25C8.32002 17.0957 7.25878 16.25 6 16.25C4.48122 16.25 3.25 17.4812 3.25 19C3.25 20.5188 4.48122 21.75 6 21.75C7.25878 21.75 8.32002 20.9043 8.64648 19.75H16.5C18.8472 19.75 20.75 17.8472 20.75 15.5C20.75 13.1528 18.8472 11.25 16.5 11.25H7.5C5.98122 11.25 4.75 10.0188 4.75 8.5C4.75 6.98122 5.98122 5.75 7.5 5.75H10.1893L9.46967 6.46967C9.17678 6.76256 9.17678 7.23744 9.46967 7.53033C9.76256 7.82322 10.2374 7.82322 10.5303 7.53033L12.5303 5.53033C12.8232 5.23744 12.8232 4.76256 12.5303 4.46967L10.5303 2.46967C10.2374 2.17678 9.76256 2.17678 9.46967 2.46967C9.17678 2.76256 9.17678 3.23744 9.46967 3.53033L10.1893 4.25H7.5C5.15279 4.25 3.25 6.15279 3.25 8.5C3.25 10.8472 5.15279 12.75 7.5 12.75H16.5C18.0188 12.75 19.25 13.9812 19.25 15.5C19.25 17.0188 18.0188 18.25 16.5 18.25H8.64648Z"
      :fill="active ? '#000000' : notification ? '#0083F5' : '#8C8C8C'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
    notification: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
