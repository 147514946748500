<template>
  <a-modal
    :visible="visible"
    width="470px"
    :body-style="bodyStyle"
    :destroy-on-close="true"
    :mask-closable="false"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img class="image__delete" src="@/app/users/assets/profiles/delete.png" alt="eliminar" />
      <p class="text__alert">¿Estás seguro de eliminar tu equipo de trabajo</p>
      <p class="text__alert">“{{ record?.customer.workspace }}”?</p>
    </div>
    <div class="container__info">
      <p class="text">
        Recuerda que tienes 7 días como máximo para recuperar tu cuenta. Luego de transcurrido ese
        tiempo, todos los datos de tu empresa serán eliminados permanentemente.
      </p>
    </div>
    <a-form class="form" layout="vertical">
      <a-row :gutter="24">
        <a-col span="24">
          <a-form-item class="form__item">
            <template #label>
              <span> Escribe la palabra <span class="strong">"Eliminar"</span> </span>
            </template>
            <a-auto-complete
              v-model:value="confirmationText"
              placeholder="Escribe aquí..."
              :class="{ 'ant-form-item-has-error': isTextIncorrect }"
            />
            <p v-if="isTextIncorrect" class="form__error">Palabra inválida</p>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="button__disabled">
      <a-button
        style="width: 100%"
        :disabled="confirmationText !== 'Eliminar'"
        class="footer__delete"
        type="primary"
        @click="handleDeleteWorkTeam"
        :loading="loading"
      >
        Eliminar equipo de trabajo
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, ref } from "vue";
import { UserStatus } from "@/app/shared/utils/enums";
import { deleteWorkTeam } from "@/app/settingsMenu/services";

export default defineComponent({
  name: "InactiveUserModal",
  props: {
    record: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const confirmationText = ref("");
    const bodyStyle = {
      height: "440px",
      padding: "30px 37px 37px",
      loading: false,
    };
    return {
      confirmationText,
      bodyStyle,
    };
  },
  data() {
    return {
      UserStatus,
      loading: false,
    };
  },
  computed: {
    isTextIncorrect() {
      return this.confirmationText != "Eliminar" && this.confirmationText.length > 0;
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("onCancel");
    },
    handleCloseModalHeader() {
      this.$emit("onCloseModal");
    },
    async handleDeleteWorkTeam() {
      try {
        this.loading = true;
        await deleteWorkTeam();
        this.handleCloseModalHeader();
        await this.$store.dispatch("logOut", this.user.id);
        this.$router.push("/home");
      } catch (error) {
        this.$message.error("ERROR");
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.image__delete
  margin-bottom: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin-bottom: 0
  color: $gray-13

.text
  margin-bottom: 20px
  margin-top: 20px
  text-align: center

.button__disabled
  margin-top: 10px

.strong
  font-weight: 600

.footer__delete
  margin-bottom: 20px

.container__inactive
  text-align: center

.container__info
  margin-top: 20px
  margin-bottom: 30px

.form__error
  color: $red-6
  margin-top: 5px
  margin-bottom: -30px
</style>
