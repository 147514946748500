<template>
  <div>
    <div>
      <h3>Contactos encontrados</h3>

      <p class="paragraph">Selecciona el contacto que deseas gestionar.</p>

      <a-table
        :columns="columnsTable"
        :data-source="clientsActive"
        size="middle"
        :scroll="{ x: 1400, y: 300 }"
        :pagination="false"
      >
        <template #manage="{ record }">
          <a @click="handleSelect(record, false)">Seleccionar</a>
        </template>

        <template #phones="{ text }">
          <a>
            <a-popover v-if="text.length > 0">
              <template #title>
                <span
                  >{{ text.length }}
                  {{ getPhoneText(text) }}
                </span>
              </template>
              <template #content>
                <ul class="list">
                  <li v-for="phone in text" :key="phone">{{ phone }}</li>
                </ul>
              </template>
              {{ text.length }}
              {{ getPhoneText(text) }}
            </a-popover>
            <span v-if="text.length === 0">0 teléfonos</span>
          </a>
        </template>
      </a-table>
    </div>

    <a-divider style="margin: 12px 0" />
    <div v-if="showInactives && clientsInactive.length > 0">
      <h3>Contactos inactivos encontrados</h3>

      <p class="paragraph">Selecciona la acción “Activar” contacto si deseas volver a activarlo.</p>

      <a-table
        :columns="columnsTable"
        :data-source="clientsInactive"
        size="middle"
        :scroll="{ x: 1400, y: 300 }"
        :pagination="false"
      >
        <template #manage="{ record }">
          <a @click="handleSelect(record, true)">Activar</a>
        </template>

        <template #phones="{ text }">
          <a>
            <a-popover v-if="text.length > 0">
              <template #title>
                <span
                  >{{ text.length }}
                  {{ getPhoneText(text) }}
                </span>
              </template>
              <template #content>
                <ul class="list">
                  <li v-for="phone in text" :key="phone">{{ phone }}</li>
                </ul>
              </template>
              {{ text.length }}
              {{ getPhoneText(text) }}
            </a-popover>
            <span v-if="text.length === 0">0 teléfonos</span>
          </a>
        </template>
      </a-table>
    </div>

    <!-- <p class="information">
      ¿Deseas gestionarlo en otro contacto? Para buscarlo,
      <a-button class="link-button" type="link" @click="handleClick"> haz click aquí </a-button>
    </p> -->
  </div>
</template>

<script>
import { columns } from "@/app/workspace/components/tables/Client/ClientTableColumn";
import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: {
    ticket: {
      type: Object,
      required: true,
      default: null,
    },
    clients: {
      type: Array,
      required: true,
      default: null,
    },
    showInactives: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleSelect = (record, activate) => {
      emit("client-select", { record, activate });
    };

    return {
      handleSelect,
    };
  },
  data() {
    return {
      columns,
    };
  },
  computed: {
    clientName() {
      return this.ticket.client.name;
    },
    columnsTable() {
      // const column = columnsFixedTableLeft(this.columns);
      // return column;
      return this.columns;
    },
    isInACall() {
      return this.$jssip.phone.status == CallStatus.InCall;
    },
    clientsActive() {
      return this.clients.filter((el) => el.client_status == 1);
    },
    clientsInactive() {
      return this.clients.filter((el) => el.client_status != 1);
    },
  },
  methods: {
    getPhoneText(text) {
      return text.length > 1 || text.length === 0 ? "teléfonos" : "teléfono";
    },
    handleClick() {
      this.$emit("go-to-search-component");
    },
  },
});
</script>

<style lang="sass" scoped>
h3
  margin: 0
  font-size: 16px
.paragraph
  margin: 0 0 8px 0
  color: $gray-7
  font-weight: 400

.information
  margin-top: 20px
  font-weight: 400
  color: $gray-9

.link-button
  padding: 0

.list
  padding-left: 28px
</style>
