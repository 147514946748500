import httpManager from "@/app/shared/utils/httpManager";
/**
 * Reenviar un correo al nuevo usuario
 *
 * @param {Object} emailUser Datos del  correo.
 *
 * @return {Promise<Object>} Reenviar un correo
 */
export default async function (emailUser) {
  const response = await httpManager.post("/users/resend-invitation/", emailUser);
  return response?.data;
}
