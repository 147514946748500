<template>
  <a-form-item
    class="principal"
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
  >
    <p class="instruction">{{ input.instruction }}</p>

    <div class="form">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item :name="[input.name, 'date']" label="Fecha de PDP" :rules="rules">
            <a-date-picker
              :value="workspaceFormState?.[input.name]?.date"
              style="width: 100%"
              placeholder="-- / -- / --"
              format="DD/MM/YYYY"
              valueFormat="YYYY-MM-DD"
              :disabled-date="disabledDate"
              :show-today="false"
              @update:value="handleChange($event, 'date')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item :name="[input.name, 'currency']" label="Moneda" :rules="rules">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              :value="workspaceFormState?.[input.name]?.currency"
              style="width: 100%"
              placeholder="Seleccionar"
              :options="coinSelectOptions"
              @update:value="handleChange($event, 'currency')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item :name="[input.name, 'amount']" label="Monto de PDP" :rules="rules">
            <a-input
              :value="workspaceFormState?.[input.name]?.amount"
              placeholder="Escribir aquí...."
              @update:value="handleChange($event, 'amount')"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </a-form-item>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";

import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";
import { coinSelectOptions } from "@/app/workspace/utils/selectOptions";
import { disabledDate } from "@/app/shared/utils/dates";

const props = defineProps<{
  input: FormElementMap[ElementType.Pdp];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

interface FormValue {
  date: string | null;
  currency: string | null;
  amount: string | null;
}

const workspaceFormState = inject("workspaceFormState") as Record<string, FormValue>;

const isRequired = computed(() => {
  if (!props.isPerProduct) return true;

  return props.hasInputsFilled;
});

const rules = computed(() => [
  {
    required: isRequired.value,
    trigger: props.isPerProduct ? ["blur", "change"] : "change",
  },
]);

const handleChange = (value: string | null, key: keyof FormValue) => {
  if (!props.isWorkspace) return;

  if (!workspaceFormState[props.input.name])
    workspaceFormState[props.input.name] = { date: null, currency: null, amount: null };
  workspaceFormState[props.input.name][key] = value;
};

const initialValue: FormValue = {
  date: null,
  currency: null,
  amount: null,
};

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.form
  margin-top: 12px

.instruction
  color: $gray-7
  line-height: 22px
</style>
