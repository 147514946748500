<template>
  <div
    class="ticket"
    :class="{
      'ticket--selected': isSelected,
      'ticket-hover': !isSelected,
      'ticket--calling': !isSelected && isRingingOrInCall,
    }"
    @click="isSelected ? () => {} : handleClick()"
  >
    <div class="info">
      <!--Canal y checkbox-->
      <div
        class="img-client"
        @mouseenter="handleMouseHoverChannelIcon"
        @mouseleave="handleMouseLeaveChannelIcon"
      >
        <transition name="icon">
          <img
            v-if="!showCheckbox"
            :src="ticket?.last_channel?.icon"
            :alt="ticket?.last_channel?.name"
          />

          <a-checkbox
            v-else-if="!isVoiceChannel && showCheckbox"
            class="checkbox"
            :checked="isTicketChecked"
            :disabled="isDisabledCheck"
            @change="handleChange"
            @click.stop
          />
        </transition>
      </div>

      <!--Nombre, último mensaje y etiquetas-->
      <div class="user-detail">
        <div class="user flex-center" :title="clientName">
          <!-- miniature icon section -->
          <img
            v-if="showCheckbox && ticket.channel.id !== isMessenger"
            class="channel-icon"
            :src="ticket.last_channel.icon"
            :alt="ticket.last_channel.name"
          />

          <!-- miniature icon fb messenger -->
          <div v-if="ticket.channel.id === isMessenger">
            <img
              v-if="showCheckbox && ticket?.client?.name"
              class="channel-icon"
              :src="ticket.last_channel.icon"
              :alt="ticket.last_channel.name"
            />

            <a-tooltip v-else-if="!ticket?.client?.name" placement="top">
              <template #title>
                <span class="text-center"
                  >Nombre proveniente <br />
                  de su cuenta de Facebook.</span
                >
              </template>
              <IconFacebook class="icon-facebook" />
            </a-tooltip>
          </div>

          <span>{{ clientName }}</span>
        </div>
        <StatusTicketWorkspace
          v-if="!titlePhoneStatus"
          :lastMessage="ticket.last_message"
          :lastStatusVoiceInteraction="$jssip.phone.status"
        />

        <span v-if="titlePhoneStatus" class="ticket__title-status"
          >{{ titlePhoneStatus }}
          <!--Icono de si esta en llamada-->
          <img v-if="isInCall" alt="Icono de llamada" :src="inCallImage" />
        </span>

        <TicketTagsList
          :class="{ 'tags-list__margin': ticket?.tags?.length }"
          :tags="ticket?.tags"
        />
      </div>
    </div>

    <div class="group">
      <span class="hour">{{ date }}</span>
      <div v-if="ticket.unread_messages > 0" data-testid="badge" class="unread">
        {{ ticket.unread_messages }}
      </div>
    </div>
  </div>
</template>

<script>
import { dateToDurationString } from "@/app/shared/utils/dates";
import { ChannelType, EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";
import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import { statuses } from "@/packages/webphone/common/statuses";
import StatusTicketWorkspace from "@/app/workspace/components/chat/StatusTicketWorkspace.vue";
import IconFacebook from "@/app/common/components/atoms/IconFacebook.vue";

import TicketTagsList from "@/app/shared/components/lists/TicketTagsList.vue";
import dayjs from "dayjs";
import { dateCalendar } from "@/app/shared/utils/dates";

import inCallImage from "@/app/workspace/assets/icons/icon-calling.svg?url";
import { ticketService } from "@/app/workspace/services/TicketService";

export default {
  components: {
    TicketTagsList,
    StatusTicketWorkspace,
    IconFacebook,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      size: {
        width: 40,
        height: 40,
      },
      checkboxVisible: false,
      isMessenger: ChannelType.Messenger,
      inCallImage,
    };
  },
  computed: {
    isVoiceChannel() {
      return [ChannelType.Voice, ChannelType.VoiceInbound].includes(this.ticket.last_channel.id);
    },
    isDisabledCheck() {
      if (this.selectedTickets.length === 0) {
        return false;
      } else if (
        this.selectedTickets[0].last_channel.id === ChannelType.Voice &&
        this.ticket.last_channel.id === ChannelType.Voice
      ) {
        return false;
      } else if (
        this.selectedTickets[0].last_channel.id !== ChannelType.Voice &&
        this.ticket.last_channel.id !== ChannelType.Voice
      ) {
        return false;
      } else {
        return true;
      }
    },
    isTicketChecked() {
      return this.selectedTicketsId.includes(this.ticket.id);
    },
    clientName() {
      const ticket = this.ticket;

      const isMessengerChannel = ticket.channel.id === ChannelType.Messenger;
      console.log("..........", Boolean(ticket.identifier) && isMessengerChannel);
      switch (true) {
        case Boolean(ticket.client):
          return ticket.client.name;
        case Boolean(ticket.extra_information):
          return ticket.extra_information.fullname;
        // case Boolean(ticket.identifier):
        //   return ticket.identifier;
        case Boolean(ticket.identifier) && isMessengerChannel:
          return "No identificado";
        default:
          return ticket.identifier;
      }
    },
    clientPreview() {
      return this.ticket.client?.name || "No identificado";
    },
    clientProfile() {
      return this.ticket.client?.profile_picture;
    },
    message() {
      return this.ticket.last_message?.message;
    },
    time() {
      return dateToDurationString(this.ticket.last_message?.datetime);
    },
    date() {
      if (!this.ticket.last_message) return "";
      const dateTime = this.ticket.last_message.datetime;
      const diff = dayjs(new Date()).diff(dateTime, "days");
      if (diff <= 1) {
        return dateCalendar(dateTime);
      } else return dayjs(dateTime).format("DD/MM/YY, HH:mm");
    },
    typeChannel() {
      return this.ticket.last_channel.id;
    },
    // ticketIdQuery() {
    //   return this.$route.query;
    // },
    isSelected() {
      return this.$route.query.ticket == this.ticket.id;
    },
    selectedTicketsId() {
      return this.$store.getters["getSelectedTicketsId"];
    },
    selectedTickets() {
      return this.$store.getters["getSelectedTickets"];
    },
    globalState() {
      return this.$store.getters["getStateUser"];
    },
    dialingType() {
      return this.$store.getters["getDialingType"];
    },
    isChatTicket() {
      return ![ChannelType.Voice, ChannelType.VoiceInbound].includes(this.ticket.last_channel.id);
    },
    dataUserState() {
      return this.$store.getters["getDataUserState"];
    },
    showCheckbox() {
      return this.checkboxVisible || this.isTicketChecked;
    },
    dataSourcePhone() {
      return this.$jssip.phone;
    },
    /**
     * @return {Boolean} - verifica si el telefono está en este ticket
     */
    phoneIsHere() {
      const meta = this.dataSourcePhone.meta;
      return meta.ticket === this.ticket?.id;
    },
    /**
     * @return {Boolean} - verifica si el telefono está en llamada
     */
    isInCall() {
      return this.phoneIsHere && this.dataSourcePhone?.status === CallStatus.InCall;
    },
    /**
     * Verifica si el ticket está en llamada o si se está llamando
     * al contacto de este ticket
     * @return {Boolean}
     */
    isRingingOrInCall() {
      return (
        this.phoneIsHere &&
        (this.dataSourcePhone?.status === CallStatus.InCall ||
          this.dataSourcePhone?.status === CallStatus.Ringing)
      );
    },
    /**
     * @return {String} - estado de la llamada
     */
    titlePhoneStatus() {
      return this.phoneIsHere && this.isRingingOrInCall && statuses[this.dataSourcePhone?.status];
    },
  },
  methods: {
    handleChange(e) {
      if (e.target.checked) {
        this.$store.commit("ADD_SELECTED_TICKET", this.ticket);
      } else {
        this.$store.commit("REMOVE_SELECTED_TICKET", this.ticket);
      }
    },
    getRedirectPath(ticket) {
      this.$router.push({
        path: "/workspace",
        query: {
          ticket: ticket.id,
          interaction: ticket.last_interaction?.id,
          client: ticket.client?.id,
        },
      });
    },
    async handleClick() {
      this.$emit("click");

      // const event = this.isChatTicket ? EventManagement.Opened : this.globalState;
      console.log('handle click');
      this.registerEventInteraction();
      this.getRedirectPath(this.ticket);
    },
    async registerEventInteraction() {
      const event = this.globalState;
      const { voice, messages } = await ticketService.getInteractionsChannels(this.ticket.id);
      if (messages.length == 0) {
        await registerEventManagement(event, {
          call_ticket: this.$jssip.phone?.meta?.ticket,
          open_ticket: this.isChatTicket ? this.ticket.id : null,
          current_ticket: !this.isChatTicket ? this.ticket.id : null,
          type_dialing: this.dialingType,
          menu: 23,
          state: this.dataUserState,
        });
        this.$store.dispatch("setEventMessaging", null);
      } 
      else if (messages.length > 0){
        console.log('registerEventInteraction');
        await this.registerEvent(EventManagement.Opened);
      }
    },
    async registerEvent(event) {
      await registerEventManagement(event, {
        open_ticket: this.ticket.id,
        menu: 23,
        state: this.dataUserState,
      });
      this.$store.dispatch("setEventMessaging", event);
    },
    getTagColor(color) {
      return `background-color: #${color}; width:${this.ticket.tags.length > 4 ? "20px" : "30px"}`;
    },
    handleMouseHoverChannelIcon() {
      if (!this.isVoiceChannel) this.checkboxVisible = true;
    },
    handleMouseLeaveChannelIcon() {
      if (!this.isVoiceChannel) this.checkboxVisible = false;
    },
  },
};
</script>

<style lang="sass" scoped>
p
  margin: 0

.checkbox-wrapper
  min-width: 16px
.ticket
  display: flex
  gap: 20px
  justify-content: space-between
  align-items: center
  border-left: 1px solid #D9D9D9
  border-bottom: 1px solid #D9D9D9
  box-sizing: border-box
  padding: $padding-tiny
  user-select: none
  min-height: 65px
  position: relative
  background-color: rgba(255, 255, 255, 0.4)

  &--selected
    background: $red-2
    border-left: 6px solid $gold-5
    padding-left: 2px
  &--calling
    background: $geekblue-4
  &__title-status
    color: $geekblue-5-1
    font-size: 12px

.ticket-hover
  transition: all 0.3s ease
  cursor: pointer

  &:hover
    background: $gray-4

.img-client
  display: flex
  justify-content: center
  align-items: center
  margin: 0 12px
  width: 20px
  height: 20px
  position: relative

  img
    width: 20px

.info
  display: flex
  align-items: center
  flex: auto
  min-width: 0px

.character
  border-radius: 50px
.icon-channel
  position: relative
  top: 13px
  right: 10px
.unread
  background: $red-6
  color: $white
  width: 18px
  height: 18px
  text-align: center
  border-radius: 50%
  font-weight: 600
  font-size: $font-small

.user
  font-weight: 600
  font-size: $font-normal
  color: $gray-9
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  line-height: 22px
  text-transform: capitalize
  text-overflow: ellipsis
  width: 64%

  &-detail
    flex: auto
    min-width: 0px

.message
  font-size: $font-small
  color: $gray-7
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  line-height: 20px

.tags-list__margin
  margin-top: 6px
  margin-bottom: 5px

.hour
  font-size: 12px
  color: $gray-7
  position: absolute
  top: 6px
  right: 8px

.hidden
  opacity: 0
  cursor: default

.channel-icon
  width: 14px
  height: 14px
  margin-right: 4px

.flex-center
  display: flex
  align-items: center

.icon-facebook
  width: 14px
  height: 14px
  margin-right: 4px

.text-center
  display: block
  text-align: center

// TRANSITION
.icon-enter-active,
.icon-leave-active
  transition: opacity 0.3s ease
  position: absolute

.icon-enter-from,
.icon-leave-to
  opacity: 0
</style>
