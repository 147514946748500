import httpManager from "@/app/shared/utils/httpManager";

export async function fetchFilterValuesByName(name, base, coverage, product = false) {
  const body = {
    base,
    coverage,
    name_filter: name,
    is_custom_product: product,
  };
  const response = await httpManager.post("/strategies/filters/available-field-custom/", body);
  return response.data;
}
