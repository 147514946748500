import httpManager from "@/app/shared/utils/httpManager";

/**
 * Validate an blacklist outbound call by the phone number
 * @param {number} phoneNumber
 *
 */

export async function validateBlacklistOutboundCall(body) {
  await httpManager.post("/blacklist/validate-phone/", body);
}
