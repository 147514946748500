import { ref, onMounted, onBeforeUnmount } from "vue";
import { debounce } from "lodash";

export function useScrollResize({
  xAxis = true,
  yAxis = true,
  headerMargin = 340,
  width = 1200,
} = {}) {
  const baseScroll = {};
  if (xAxis) baseScroll.x = width;
  if (yAxis) baseScroll.y = 500;

  const margin = ref(headerMargin);
  const scrollStyle = ref(baseScroll);

  const setNewMargin = (newMargin) => {
    margin.value = newMargin;
    onResize();
  };

  const onResize = debounce(() => {
    scrollStyle.value = { ...baseScroll, y: window.innerHeight - margin.value };
  }, 100);

  onMounted(() => {
    window.addEventListener("resize", onResize);
    scrollStyle.value = { ...baseScroll, y: window.innerHeight - margin.value };
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
  });

  return { scrollStyle, setNewMargin, onResize };
}
