import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener etiquetas.
 *
 * @return {Promise<Tag[]>} Lista de etiquetas.
 */
export async function fetchTags({ name = "" } = {}) {
  const response = await httpManager.get(`/tickets/tags/?name=${name}`);
  return response?.data?.results;
}
