import { fetchKeepAlive } from "@/app/shared/services/fetchKeepAlive";
import httpManager from "../../../utils/httpManager";
import * as Sentry from "@sentry/vue";

/**
 * Registrar eventos de gestion.
 * @param {number} eventManagement Identificador del evento.
 * @param {UserEvent} params Parametros opcionales.
 * @param {{keepAlive: boolean}} Object
//  * @param {number} params.ticket Identificador del ticket.
//  * @param {number} params.menu Identificador del menu.
//  * @param {any} params.type_dialing Tipo de marcacion.
 * @return {Promise<object>}
 */
export async function registerEventManagement(eventManagement, params, { keepAlive = false } = {}) {
  const url = "/monitoring/events/";
  const body = { event_management: eventManagement };
  if (params?.menu) body.menu = params.menu;
  if (params?.section) body.section = params.section;
  if (params?.call_ticket) body.call_ticket = params.call_ticket;
  if (params?.current_ticket) body.current_ticket = params.current_ticket;
  if (params?.open_ticket) body.open_ticket = Number(params.open_ticket);
  if (params?.type_dialing) body.type_dialing = params.type_dialing;
  if (params?.last_call_interaction_id)
    body.last_call_interaction_id = params.last_call_interaction_id;
  if (params?.state) body.state = params.state;
  if (params?.interaction_id) body.interaction_id = params.interaction_id;

  Sentry.captureMessage(`registerEventManagement ${JSON.stringify(body)}`);

  localStorage.setItem('eventManagement', JSON.stringify(body));
  localStorage.setItem('lastEventTime', Date.now().toString());
  console.log(keepAlive, "keep alive");
  if (keepAlive) {
    fetchKeepAlive(url, "POST", body);
  } else {
    const response = await httpManager.post(url, body, { keepAlive });
    return response.data;
  }
}
