<template>
  <section>
    <p class="paragraph">
      Puedes agregar o editar teléfonos.
      <a @click="handleOpenAddPhones()"><span>Añadir teléfono </span></a>
    </p>

    <add-phones-contact-drawer
      :visible="isAddPhonesVisible"
      @onCloseDrawerEdit="handleCloseDrawer"
      @update="handleUpdate"
      :itemId="itemId"
    />
    <div class="flex">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 500 }"
        :pagination="pagination"
        class="table__phone"
      >
        <template #country="{ text }">
          <template v-if="text">
            <img class="country" :src="text.url_flag" :alt="text.name" />
            +{{ text.code_phone }}
          </template>
        </template>

        <template #phone="{ text, record }">
          <!-- <a class="phone" @click="handleConfirmationCall(record)" v-if="userDataInfo">{{
            text
          }}</a>
          <span disabled v-if="!userDataInfo"> {{ text }}</span> -->
          <ActionsPopover
            :record="record"
            :contact="contact"
            :get-user-data="getUserData"
            :user-data-info="userDataInfo"
            :text="text"
            :portfolio="portfolio"
            @click:sms="handleOpenSmsModal(record)"
            @click:phone="handleConfirmationCall(record)"
            @handleConfirmationCall="handleConfirmationCall(record)"
          />
        </template>

        <template #type_phone="{ text }">
          <span>{{ typePhones(text) }}</span>
        </template>

        <template #contact_type="{ record }">
          <span>{{ record.contact_type }}</span>
        </template>
        <template #last_management_date="{ text }">
          <span>{{ text ? dateTimeToSlashFormat(text, true) : "--" }}</span>
        </template>

        <template v-slot:customDate
          >Fecha y hora
          <a-tooltip>
            <template v-slot:title> Ordenar </template>
            <SwapOutlined :rotate="90" />
          </a-tooltip>
        </template>

        <template #observation="{ text }">
          <div v-if="text?.length > 29">
            <a-popover :overlayStyle="{ width: '250px' }" placement="bottomRight">
              <template #content>
                <p>{{ text }}</p>
              </template>
              <div class="flex-center">
                <p class="observation__text">{{ text }}</p>
              </div>
            </a-popover>
          </div>
          <div v-else>
            <div class="flex-center">
              <p class="observation__text">{{ text }}</p>
            </div>
          </div>
        </template>

        <template #actionScoped="{ record }">
          <div class="actions">
            <!-- <a-tooltip>
              <template #title> Llamar </template>
              <a class="icon-phone" v-if="userDataInfo" @click="handleConfirmationCall(record)"
                ><img src="@/app/contacts/assets/icon-phone.svg" alt="phone"
              /></a>
              <a class="icon-phone" v-if="!userDataInfo" disabled
                ><img src="@/app/contacts/assets/icon-phone-disabled.svg" alt="phone disabled"
              /></a>
            </a-tooltip>
            <SendWhatsappTemplateModalVue
              :record="{ ...record, ...contact }"
              v-if="getUserData?.allow_send_template"
            />

            <a-divider type="vertical" class="divider" /> -->

            <a-tooltip>
              <template #title> Editar </template>
              <UpdatePhonesContactDrawer
                :record="record"
                :itemId="itemId"
                :contact="contact"
                @update="handleUpdate"
              />
            </a-tooltip>
            <a-tooltip>
              <template #title> Eliminar </template>
              <DeleteOutlined class="icon" @click="handleConfirmationDelete(record)" />
            </a-tooltip>
          </div>
        </template>
      </a-table>

      <step-scroll
        :information="phones"
        @dataInfo="handleInformationData"
        v-if="!openTable && getPhones?.length > 2"
      />

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getPhones?.length > 2"
          >ver más ({{ getPhones?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
  <DeletePhoneModal
    :record="infoPhone"
    v-if="isModalCallInactiveOpen"
    @onCancel="handleCloseModal"
    @delete="handleUpdate"
    :itemId="itemId"
  />
  <DeletePhoneCallActiveModal
    :record="infoPhone"
    v-if="isModalCallActiveOpen"
    @onCancel="handleCloseModal"
    @delete="handleUpdate"
    :contact="contact"
  />
  <!-- {{ :contact="client?.data_client?.at(0).full_name" }} -->
  <SendSmsTemplateModal
    v-if="smsModalOpen"
    v-model:open="smsModalOpen"
    :contact="contact.fullName"
    :phone="{ country: smsModalPhone.country, number: smsModalPhone.phone }"
    :portfolio="portfolio"
  />
</template>

<script>
// TODO-JOHN: Refactor, remover drawer de la tabla
import { columns } from "@/app/contacts/components/tables/phone/ContactPhonesTableColumns";
import AddPhonesContactDrawer from "@/app/contacts/components/drawers/phone/AddPhonesContactDrawer.vue";
import UpdatePhonesContactDrawer from "@/app/contacts/components/drawers/phone/UpdatePhonesContactDrawer.vue";
import { PhoneType, DialingType } from "@/app/shared/utils/enums";
import SendWhatsappTemplateModalVue from "@/app/workspace/components/modal/SendWhatsappTemplateModal.vue";
import DeletePhoneModal from "@/app/contacts/components/modal/DeletePhoneModal.vue";
import DeletePhoneCallActiveModal from "@/app/contacts/components/modal/DeletePhoneCallActiveModal.vue";
import SendSmsTemplateModal from "@/app/workspace/components/modal/SendSmsTemplateModal.vue";
import ActionsPopover from "@/app/contacts/components/tables/phone/ActionsPopover.vue";

import { DeleteOutlined, QuestionCircleOutlined, SwapOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { createVNode, defineComponent } from "vue";
import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";

import { confirmIsCallUser } from "@/app/contacts/services";
import { dateTimeToSlashFormat } from "@/app/shared/utils/dates";
import { debounce } from "lodash";

export default defineComponent({
  components: {
    AddPhonesContactDrawer,
    UpdatePhonesContactDrawer,
    DeleteOutlined,
    SwapOutlined,
    DeletePhoneModal,
    DeletePhoneCallActiveModal,
    SendSmsTemplateModal,
    ActionsPopover,
  },
  props: {
    itemId: {
      type: Number || String,
      required: true,
    },
    userDataInfo: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      columns,
      isAddPhonesVisible: false,
      position: 0,
      dataTable: [],
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      profile: undefined,
      openTable: false,
      infoPhone: undefined,
      isModalCallInactiveOpen: false,
      isModalCallActiveOpen: false,
      dateTimeToSlashFormat,
      smsModalOpen: false,
      smsModalPhone: {},
      moreOptionsOpen: false,
    };
  },
  computed: {
    columnsTable() {
      return this.columns;
    },
    phones() {
      return this.$store.getters["getContactPhone"].items;
    },
    getPhones() {
      return this.$store.getters["getContactPhone"].items;
    },
    contact() {
      return this.$store.getters["getContact"];
    },
    getUserData() {
      return this.$store.getters["getDataUser"];
    },
    isInCall() {
      return this.$jssip.phone.status == "in-call";
    },
  },

  watch: {
    phones() {
      this.dataTable = this.getPhones;
    },
  },
  created() {
    this.handleFetchPhones();
    this.fetchProfile();
  },
  methods: {
    handleUpdate() {
      this.$store.dispatch("fetchPhoneContact", { id: this.itemId });
      this.$emit("update-table");
    },
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getPhones.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      this.openTable = false;
    },
    handleCloseDrawer() {
      this.isAddPhonesVisible = false;
    },
    handleOpenAddPhones() {
      this.isAddPhonesVisible = true;
    },
    handleConfirmationDelete(value) {
      if (value.phone) this.handleValidationIsCall(value);
      this.infoPhone = value;
      // this.openModal = true;
    },
    async handleValidationIsCall(value) {
      try {
        const response = await confirmIsCallUser({
          client: this.contact?.id,
          phone: value.phone,
        });
        this.isModalCallInactiveOpen = true;
        if (response.detail === "Este cliente tiene una llamada en curso.") {
          this.isModalCallInactiveOpen = false;
          this.isModalCallActiveOpen = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async handlePhoneClick(record) {
      this.$store.commit("SET_DIALING_TYPE", 1);

      this.$jssip.clearData();
      await this.$jssip.call(
        record.phone,
        DialingType.Manual,
        this.getUserData.id,
        this.contact.id,
        record.country?.code_phone,
        this.portfolio?.id
      );
      this.$store.dispatch("setCallFinish", false);
    },
    typePhones(text) {
      if (text === PhoneType.landline) {
        return "Fijo";
      } else if (text === PhoneType.mobile) {
        return "Celular";
      } else return "Ambos";
    },
    handleConfirmationCall: debounce(function (record) {
      Modal.confirm({
        title: `¿Estás seguro de llamar al contacto “${this.contact.fullName}”?`,
        content:
          "Recuerda que se creará un nuevo ticket y al finalizar la llamada podrás colocar una tipificación.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.handlePhoneClick(record),
        centered: true,
        okText: "Llamar",
        cancelText: "Cancelar",
      });
    }, 50),
    handleFetchPhones() {
      this.$store.dispatch("fetchPhoneContact", { id: this.itemId });
    },
    async fetchProfile() {
      this.profile = this.getUserData;
    },
    handleCloseModal() {
      this.isModalCallInactiveOpen = false;
      this.isModalCallActiveOpen = false;
    },
    handleOpenSmsModal(record) {
      this.smsModalOpen = true;
      this.smsModalPhone = record;
    },
  },
});
</script>

<style lang="sass" scoped>
.divider
  margin: 2px
  border-color: $gray-6
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

.icon
  color: $blue-6

.actions
  display: flex
  align-items: center
  gap: 8px

.phone
  cursor: pointer

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 10px

.width
  width: 1px
  border: 1px solid $gray-4
  margin-left: 5px
  margin-right: 20px

.flex
  position: relative

.country
  width: 20px
  margin-right: 5px

.table__phone
  margin-right: 20px

:deep(.ant-pagination)
  display: none !important

.observation__text
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  margin: 0
  cursor: pointer

.flex-center
  display: flex
  align-items: center
</style>
