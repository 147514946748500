<template>
  <a-modal
    :open="open"
    centered
    :width="500"
    :footer="false"
    :maskClosable="false"
    wrapClassName="feedback-modal"
    title="¡Bríndanos un feedback adicional!"
    @cancel="emit('update:open', false)"
  >
    <section class="modal-content">
      <h3>¿Qué te pareció la conclusión generada por {{ CompanyName }} AI?</h3>
      <div class="icons">
        <IconHappyFace
          :class="isHappyFace && 'happy'"
          :active="isHappyFace"
          @click="isHappyEmojiActive(1)"
        />
        <IconSadFace :class="isSadFace && 'sad'" :active="isSadFace" @click="isSadEmojiActive(2)" />
      </div>

      <a-form ref="formRef" :model="formState" layout="vertical" hide-required-mark>
        <div v-if="isSadFace" class="group">
          <a-radio-group v-model:value="feedbackCheck">
            <a-radio :value="1">La conclusión no es verdad</a-radio>
            <a-radio :value="2">No se entiende</a-radio>
            <a-radio :value="3">Faltan datos importantes</a-radio>
          </a-radio-group>
        </div>
        <a-form-item name="feedback">
          <a-textarea
            v-model:value="formState.feedback"
            class="textarea"
            rows="5"
            placeholder="Déjanos tu opinión sobre la conclusión generada"
          />
        </a-form-item>
      </a-form>

      <footer>
        <a-button type="text" @click="handleSubmit"> Guardar feedback </a-button>
      </footer>
    </section>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from "vue";
import IconHappyFace from "@/app/common/components/atoms/IconHappyFace.vue";
import IconSadFace from "@/app/common/components/atoms/IconSadFace.vue";
import { message } from "ant-design-vue";
import { FeedbackIcon } from "@/app/integrations/utils/enums";
import { summaryService } from "@/app/workspace/services/SummaryService";
import { CompanyName } from "@/app/shared/utils/constants";

const props = defineProps<{
  open: boolean;
  emojiFace: number;
  summaryId: number;
}>();

const formState = reactive({
  feedback: "",
});

const feedbackCheck = ref(0);
const isHappyFace = ref(false);
const isSadFace = ref(false);

const emit = defineEmits<{
  "update:open": [open: boolean];
  update: [];
  isFeedbackDisabled: [open: boolean];
}>();

const handleRankingDescription = (ranking: number) => {
  if (ranking === 1) return "La conclusión no es verdad";
  if (ranking === 2) return "No se entiende";
  if (ranking === 3) return "Faltan datos importantes";
  return "";
};

const handleSubmit = async () => {
  const ranking = handleRankingDescription(feedbackCheck.value);
  let feedbackType = 0;

  if (isHappyFace.value) {
    feedbackType = 1;
  } else if (isSadFace.value) {
    feedbackType = 2;
  } else if (props.emojiFace) {
    feedbackType = props.emojiFace;
  }

  if (feedbackType !== 0) {
    await summaryService.registerFeedback(
      props.summaryId,
      feedbackType,
      ranking,
      formState.feedback
    );
    message.success("Se ha calificado el resumen inteligente con éxito");
    emit("update:open", false);
    emit("isFeedbackDisabled", true);
  } else {
    message.error("Debe seleccionar una opción de feedback");
  }
};

const isHappyEmojiActive = (emoji: number) => {
  if (emoji === FeedbackIcon.Happy) {
    isHappyFace.value = true;
    isSadFace.value = false;
  }
};

const isSadEmojiActive = (emoji: number) => {
  if (emoji === FeedbackIcon.Sad) {
    isSadFace.value = true;
    isHappyFace.value = false;
  }
};

onMounted(() => {
  isHappyEmojiActive(props.emojiFace);
  isSadEmojiActive(props.emojiFace);
});
</script>

<style scoped lang="sass">

.modal-content
  text-align: center
  .group
    padding-bottom: 20px
    .ant-radio-group
      display: flex
      flex-direction: column
      justify-content: flex-start
  .icons
    display: flex
    gap: 10px
    justify-content: center
    padding: 10px 10px 20px 10px
    .happy
      background: #F6FFED
      border-radius: 100px
    .sad
      background: #FFF1F0
      border-radius: 100px
  h3
    font-weight: normal
    padding-top: 20px
  p
    margin-bottom: 16px
    color: $text-primary
    font-size: 20px
    line-height: 28px

  img
    height: 120px
    margin-bottom: 18px

  footer
    display: flex
    justify-content: center
    gap: 8px
    margin-top: 20px
    button
      border-radius: 6px
      border: 1px solid #762ECF
      background: linear-gradient(180deg, rgba(118, 46, 207, 0.50) 0%, rgba(214, 47, 160, 0.50) 100%)
      box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.04)
      color: #FFFFFF
</style>
