const initialState = () => ({
  strategiesInfo: {
    pagination: {},
    items: [],
  },
  templatesWhatsapp: [],
  activeUsers: [],
  agents: [],
  emailChannel: {},
  filteredActiveUsers: [],
  selectedActiveUsersId: [],
});

const state = initialState();

export default state;
