const getters = {
  getAllTypesOperation(state) {
    return state.types.map((typeOperation, index) => {
      return {
        id: index + 1,
        name: typeOperation,
      };
    });
  },
  getHourPlatform(state) {
    return state.hourPlatform;
  },
  getPlatformDate(state) {
    return state.platformDate;
  },
  getAcwState(state) {
    return state.inAcw;
  },
  getWaitingChangeStateMessage(state) {
    return state.waitingChangeStateMessage;
  },
};

export default getters;
