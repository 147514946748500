import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener estrategias por nombre, entidad, campaña y tipo.
 *
 * @param {string} search Nombre a buscar.
 * @param {number} entity Identificador de la entidad.
 * @param {number} portfolio Identificador de la campaña.
 * @param {number} type Identificador del tipo de estrategia.
 *
 * @return {Promise<Object>} Listado de estrategias filtradas.
 * @param {Object} pagination Paginación

 */
import { TABLE_PAGE_SIZE_REDUCE } from "@/app/shared/utils/constants";

export async function fetchStrategies(filters = {}, pagination = {}) {
  const search = filters?.search || "";
  const entity = filters?.entity || "";
  const portfolio = filters?.portfolio || "";
  const type = filters?.type || "";

  const offset = (pagination?.current - 1 || 0) * TABLE_PAGE_SIZE_REDUCE;
  const pageSize = pagination?.pageSize || TABLE_PAGE_SIZE_REDUCE;

  const url = `/strategies/?name=${search}&entity=${entity}&portfolio=${portfolio}&type_strategy=${type}&offset=${offset}&limit=${pageSize}`;
  const response = await httpManager.get(url);
  return response?.data;
}
