<template>
  <div class="popover">
    <div class="popover__body">
      <div class="popover__content">
        <div v-if="information?.picture">
          <img class="user__icon" :src="information?.picture" alt="perfil" />
        </div>
        <div
          v-else
          class="background__profile"
          :class="information.role.name === 'Agente' ? 'background__agent' : ''"
        >
          <img
            v-if="information.icon"
            class="user__agente"
            :class="information.role.name != 'Agente' ? 'user__profile' : ''"
            :src="getProfileImage(information?.icon || iconDefault)"
            alt="perfil"
          />
        </div>

        <div>
          <p>Perfil: {{ profile }}</p>
          <p>Teléfono: {{ phone }}</p>
          <p>Correo: {{ email }}</p>
          <a-tag :color="color" v-if="eventManagement != 16"> {{ text }} </a-tag>
          <a-tag :colorInactive="colorInactive" v-else> Inactivo </a-tag>
        </div>
      </div>
      <a-divider />
      <div class="popover__footer">
        <a-button type="link" class="button" @click="$emit('logout')"> {{ logoutText }} </a-button>
        <a-button type="link" class="button" @click="$emit('monitoring')">
          Monitorear
          <DesktopOutlined style="font-size: 15px" />
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DesktopOutlined } from "@ant-design/icons-vue";

import { StateOptions, StateTexts, EventManagement } from "@/app/shared/utils/enums";

const stateColors = {
  [StateOptions.Connected]: "green",
  [StateOptions.Refreshment]: "orange",
  [StateOptions.Dinner]: "orange",
  [StateOptions.Occupied]: "red",
  [StateOptions.Services]: "blue",
  [StateOptions.Training]: "purple",
  [StateOptions.NotAvailable]: "cyan",
  [StateOptions.Disconnected]: "",
  [EventManagement.Inactive]: "#BFBFBF",
};
// const stateText = {
//   [StateOptions.Connected]: "Conectado",
//   [StateOptions.Refreshment]: "Refrigerio",
//   [StateOptions.Occupied]: "Ocupado",
//   [StateOptions.Services]: "En servicios",
//   [StateOptions.Training]: "En capacitación",
//   [StateOptions.NotAvailable]: "No disponible",
//   [StateOptions.Disconnected]: "Desconectado",
// };
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    DesktopOutlined,
  },
  props: {
    name: { type: String, default: "" },
    profile: { type: String, default: "" },
    phone: { type: String, default: "" },
    email: { type: String, default: "" },
    profilePicture: { type: String, default: "" },
    identifier: { type: String, default: null },
    state: { type: Number, default: null },
    information: { type: Array, requerid: true },
    eventManagement: { type: Array, requerid: true },
  },
  computed: {
    color() {
      return stateColors[this.state];
    },
    text() {
      return StateTexts[this.state];
    },
    logoutText() {
      // return this.state == StateOptions.Disconnected ? "" : "Cerrar su sesión";
      return "Cerrar su sesión";
    },
    iconDefault() {
      return "smile.svg";
    },
    colorInactive() {
      return stateColors[this.eventManagement];
    },
  },
  methods: {
    getProfileImage(profile) {
      return new URL(`../../../users/assets/profiles/${profile}`, import.meta.url).href;
    },
  },
});
</script>

<style lang="sass" scoped>
.popover
  font-size: 12px
  color: $gray-8
  padding: 3px
  width: 280px
  font-style: normal
  font-weight: 400
  line-height: 22px
  letter-spacing: 0em
  text-align: left

  &__content
    margin-bottom: 8px
    display: grid
    grid-template-columns: 0.1fr 1fr

    & p
      margin-bottom: 2px

  &__footer
    display: flex
    justify-content: space-between
    align-items: center
    // gap: 12px

    & .anticon
      color: $blue-6
      cursor: pointer

// :deep(.ant-btn)
//   font-size: 8px

:deep(.ant-tag)
  font-size: 10px
  margin-top: 8px
.ant-divider-horizontal
  margin-top: 12px
  margin-bottom: 6px
.name
  display: flex
  align-items: center
  position: relative
  &__logo
    padding: 5px 6px
    display: flex
    text-align: center
    background: #FFF7E6
    border-radius: 50%
    height: 30px
    margin-right: 5px
    width: 30px

    &--empty > img
      width: 19px
      height: 19px
      filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.user__icon
  width: 30px
  height: 30px
  margin: 6px
  border-radius: 50%

.user__agente
  width: 22px
  height: 22px
  margin: 6px
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.user__profile
  width: 22px
  height: 22px
  margin: 6px
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.background__profile
  background-color: rgb(230, 247, 255)
  border-radius: 50%
  display: flex
  align-items: center
  margin-right: 3px
  height: 36px

.background__agent
  background: #FFF6E2
  border-radius: 50%
  display: flex
  align-items: center
  margin-right: 3px
  height: 36px

.button
  padding: 0
  font-size: 12px
</style>
