import { FileMetadata, RecordMetadata } from "@/@types/workspace";
import { defineStore } from "pinia";
import { ref } from "vue";

interface InputData {
  message: string;
  file: FileMetadata;
  record: RecordMetadata | undefined;
}

export const useChatStorage = defineStore("chat", () => {
  const interactionInputSavedData = ref<Record<number, InputData>>({});

  const saveInteractionInputData = (interactionId: number, inputData: InputData) => {
    interactionInputSavedData.value[interactionId] = inputData;
  };

  const getInteractionInputData = (interactionId: number): InputData | undefined => {
    return interactionInputSavedData.value?.[interactionId];
  };

  const deleteInteractionInputData = (interactionId: number | undefined) => {
    if (!interactionId) return;

    delete interactionInputSavedData.value[interactionId];
  };

  return {
    interactionInputSavedData,
    saveInteractionInputData,
    getInteractionInputData,
    deleteInteractionInputData,
  };
});
