<template>
  <web-phone-template>
    <WorkspaceCallHistory v-if="!isInAcw" :identifier="receiver.number" :height="height" />

    <web-phone-content v-else :receiver="receiver" :readonly="status == 'None'" />

    <template #footer>
      <WebPhoneActionsEnded
        in-workspace
        :floating="true"
        :inAnotherCall="isBusyInACall"
        :anotherTicketInACW="isInAcw && !isCurrentTicket"
        stopPropagationEvent
        @click:recall="handleRedial"
      />
    </template>
  </web-phone-template>
</template>

<script>
/**
 * Webphone component thats represent the webphone in the section two inside of the workspace module
 *
 */
import { inject } from "vue";

import { statuses } from "../common/statuses";
import { DialingType } from "@/app/shared/utils/enums";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

import WebPhoneContent from "./core/WebPhoneContent.vue";
import WebPhoneTemplate from "./core/WebPhoneTemplate.vue";
import WebPhoneActionsEnded from "./core/WebPhoneActionsEnded.vue";
import WorkspaceCallHistory from "@/app/workspace/components/cards/WorkspaceCallHistory.vue";
import { validateBlacklistOutboundCall } from "@/app/workspace/services";

const themes = ["light", "sky", "dark"];

const themeValidator = (value) => themes.includes(value);

export default {
  components: {
    WebPhoneTemplate,
    WebPhoneContent,
    WebPhoneActionsEnded,
    WorkspaceCallHistory,
  },
  props: {
    theme: {
      type: String,
      default: "light",
      validator: themeValidator,
    },
    height: { type: Number, default: 380 },
    name: { type: String, default: "" },
    photo: { type: String, default: null },
    number: { type: String, default: "" },
    history: { type: Array, default: () => [] },
  },
  provide() {
    return {
      theme: this.theme,
      height: this.height,
    };
  },
  data() {
    return {
      showDial: false,
      number2: "",
    };
  },
  setup() {
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    return { ticket, channel };
  },
  computed: {
    // channelId() {
    //   return this.$route.params.channel;
    // },
    // channel() {
    //   if (this.channelId) {
    //     const data = this.$store.getters["getWorkspaceClientChannels"];
    //     return data.find((c) => c.id == this.channelId);
    //   }
    //   return null;
    // },
    receiver() {
      return {
        name: this.name,
        photo: this.photo,
        number: this.number,
      };
    },
    status() {
      return statuses[this.$jssip.phone.status];
    },
    dialingType() {
      return this.$store.getters["getDialingType"];
    },
    isBusyInACall() {
      const status = this.$jssip.phone.status;
      const busyStatus = ["connecting", "ringing", "in-call"];

      return this.dialingType === DialingType.Progressive || busyStatus.includes(status);
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    client() {
      return this.$store.getters["getWorkspaceClient"];
    },
    isInAcw() {
      return this.$store.getters["getAcwState"];
    },
    // ticket() {
    //   const tickets = this.$store.getters["getWorkspaceTickets"];
    //   const ticketId = this.$route.params.ticket;
    //   return tickets.find((item) => item.id == ticketId);
    // },
    isCurrentTicket() {
      const ticketId = this.$route.query.ticket;
      const interactionId = this.$route.query.interaction;
      // const { ticket, channel_person } = this.$jssip.phone.meta;
      // return ticketId == ticket && channelPerson == channel_person;
      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
  },
  methods: {
    async handleRedial(event) {
      try {
        console.log(validateBlacklistOutboundCall, "validateBlacklistOutboundCall");
        console.log("ticketon", this.ticket);
        console.log("channelon", this.channel);
        await validateBlacklistOutboundCall({
          code_country: this.channel?.code_country,
          portfolio: this.ticket?.portfolio?.id,
          phone_number: this.channel ? this.channel?.identifier : this.ticket?.identifier,
        });

        this.$store.dispatch("setCallFinish", false);
        if (event) {
          const metaData = {
            name: this.ticket?.client?.name,
            photo: this.ticket?.client?.profile_picture,
            number: this.channel ? this.channel?.identifier : this.ticket?.identifier,
            ticket: this.ticket?.id,
            channel: 1,
            // channel_person: this.channel ? this.channel?.id : this.ticket.channel_person?.id,
            client: this.$route.query.client,
            time_acw: this.ticket?.time_acw,
            type_default_management: this.ticket?.portfolio?.type_default_management,
            channel_customer: this.channel?.channel_customer?.id || this.ticket?.channel_customer,
          };
          this.$jssip.setData(metaData);
          this.$store.commit("SET_DIALING_TYPE", DialingType.Manual);
          // if (this?.channel?.identifier) {
          //   this.$jssip._call(
          //     this.channel.identifier,
          //     this.ticket?.client?.name,
          //     this.ticket?.client?.profile_picture,
          //     this.ticket,
          //     DialingType.Manual,
          //     this.user?.id
          //   );
          // } else {
          this.$jssip._call(
            this?.channel?.identifier ? this?.channel?.identifier : this.ticket?.identifier,
            this.ticket?.client?.name,
            this.ticket?.client?.profile_picture,
            this.ticket,
            DialingType.Manual,
            this.user?.id,
            "0",
            "0",
            {},
            this.channel?.code_country
          );
          // }
          this.$router.push({
            path: "/workspace",
            query: {
              ticket: this.ticket.id,
              interaction: this.$route.query.interaction,
              client: this.$route.query.client,
            },
          });
          EventBus.emit(WorkspaceEvent.RECALL);
        }
      } catch (error) {
        if (error.status === 400) this.$message.error(error.data?.phone_number);
      }
    },
    async handleClickCall(event) {
      if (event) {
        if (!this.number2) return;
        this.$store.commit("SET_DIALING_TYPE", 1);
        this.$jssip.clearData();
        await this.$jssip.call(this.number2, DialingType.Manual, this.user.id);
        this.$store.dispatch("setCallFinish", false);
        this.number2 = "";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.actions
  display: flex
  justify-content: center
  &__icon
    filter: grayscale(0)
    margin: 0 4px
</style>
