import {
  createNewUser,
  deleteUser,
  fetchAllUsers,
  fetchAllUsersNoAdmi,
  fetchUserById,
  searchUsers,
  updateUser,
  resendEmailUser,
  updateInvitationUser,
  fetchAllFilterProfile,
  agentAssignedContact,
} from "@/app/users/services";
import { CLEAR_USER, REMOVE_USER, SET_USER, SET_USERS } from "./mutation-types";

import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchAllUsers({ commit }, params = {}) {
    const { pagination } = params;
    const data = await fetchAllUsers(pagination);
    commit(SET_USERS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async fetchUserById({ commit }, id) {
    const user = await fetchUserById(id);
    commit(SET_USER, user);
  },
  async createNewUser(_, newUser) {
    const response = await createNewUser(newUser);

    return response;
  },
  async updateUser(_, { id, params }) {
    await updateUser(id, params);
  },
  async searchUsers({ commit }, params = {}) {
    const { search, portfolio, entity, pagination } = params;
    const data = await searchUsers(search, portfolio, entity, pagination);
    commit(SET_USERS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async fetchAllUsersNoAdmi({ commit }) {
    const data = await fetchAllUsersNoAdmi();

    commit(SET_USERS, {
      items: data,
      pagination: {
        showSizeChanger: false,
      },
    });
  },
  clearUser({ commit }) {
    commit(CLEAR_USER);
  },
  assignedUser(context, user) {
    let stateUser = context.state.user;
    stateUser = { ...stateUser, ...user };
    context.commit(SET_USER, stateUser);
  },
  async deleteUser({ commit }, userId) {
    await deleteUser(userId);
    commit(REMOVE_USER, userId);
  },
  async resendEmailUser(_, emailUser) {
    await resendEmailUser(emailUser);
  },
  async updateInvitationUser(_, { id, data }) {
    await updateInvitationUser(id, data);
  },
  async fetchAllFilterProfile({ commit }, payload) {
    const data = await fetchAllFilterProfile(payload?.profile, payload?.search);
    commit(SET_USERS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async agentAssignedContact(_, data) {
    await agentAssignedContact(data);
  },
};

export default actions;
