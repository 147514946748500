export enum CallbackEventStatus {
  Active = 1,
  Inactive = 2,
}

export interface CallbackEvent {
  id: number;
  name: string;
  description: string;
  status: CallbackEventStatus;
}
