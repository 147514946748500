const getters = {
  getSavedCards(state) {
    return state.cards;
  },
  getInfoBussiness(state) {
    return state.enable_bases;
  },
  getBussiness(state) {
    return state.business;
  },
};

export default getters;
