import { SET_MY_ACCOUNT_VIEW_STATUS, SET_SECURITY_PERMITS } from "./mutation-types";

const actions = {
  async setMyAccountViewStatus({ commit }, settings) {
    commit(SET_MY_ACCOUNT_VIEW_STATUS, settings);
  },
  async setSecurityPermits({ commit }, settings) {
    commit(SET_SECURITY_PERMITS, settings);
  },
};

export default actions;
