import dayjs from "dayjs";

export const columns = [
  {
    title: "Código de cuenta",
    dataIndex: "account_code",
    width: 120,
    customRender: ({ text }) => text || "-",
  },
  {
    title: "Fecha de PDP",
    dataIndex: "date_promise_to_pay",
    width: 150,
    customRender: ({ text }) => dayjs(text).format("DD/MM/YYYY"),
  },
  {
    title: "Monto del PDP",
    dataIndex: "amount_promise_to_pay",
    width: 150,
  },
  {
    title: "Tipo de moneda",
    dataIndex: "currency",
    width: 150,
  },
  {
    title: "Observación",
    dataIndex: "observation",
    width: 150,
  },
];
