import httpManager from "@/app/shared/utils/httpManager";
import { clearArray } from "@/app/queues/utils/helpers";

export async function updateQueue(queueId, body, onlyUsers = false) {
  console.log("UPDATE_QUEUE", body);

  const url = `/queues/${queueId}/`;
  const request = {
    status: 1,
    // portfolios: body.portfolios,
    // channels_customer: clearArray(body.channels_customer),
  };
  if (!onlyUsers) request.portfolios = body.portfolios;
  if (!onlyUsers) request.channels_customer = clearArray(body.channels_customer);
  if (body.name) request.name = body.name;
  if (body.users) request.users = body.users;
  if (body.level_service) request.level_service = body.level_service;
  if (body.waiting_threshold) request.waiting_threshold = body.waiting_threshold;
  if (body.allowed_queues) request.allowed_queues = body.allowed_queues;
  if (body.distribution_type) request.distribution_type = body.distribution_type;
  if (body.max_tickets_agents) request.max_tickets_agents = body.max_tickets_agents;
  if (!onlyUsers) request.whatsapp_template = body.whatsapp_template ?? null;
  if (body.distribution_time) request.distribution_time = body.distribution_time;
  if (!onlyUsers) request.whatsapp_template = body.whatsapp_template ?? null;
  request.enable_custom_ticket_distribution = body.enable_custom_ticket_distribution;
  if (body.tickets_to_distribute) request.tickets_to_distribute = body.tickets_to_distribute;
  const response = await httpManager.put(url, request);
  return response?.data;
}
