<template>
  <div>
    <span>{{ title }}</span>
    &nbsp;&nbsp;<span v-if="show" class="acw-style">ACW {{ seconds }}</span>
  </div>
</template>

<script>
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { defineComponent, ref, computed } from "vue";

import moment from "moment-timezone";
export default defineComponent({
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isChat: {
      required: false,
      type: Boolean,
    },
    transferUser: { required: false, type: Boolean, default: false },
    transferQueue: { required: false, type: Boolean, default: false },
  },
  setup(props) {
    const availableTime = ref(0);
    const seconds = computed(() => {
      return moment(availableTime.value * 1000).format("mm:ss");
    });
    const title = computed(() => {
      console.log(props.transferUser);
      if (!props.transferUser && !props.transferQueue)
        return props.isChat ? "Tipificación de mensaje" : "Tipificación de llamada";
      return "Tipificar y transferir a " + (props.transferUser ? "un usuario" : "cola");
    });
    return {
      seconds,
      availableTime,
      title,
    };
  },
  mounted() {
    EventBus.on(WorkspaceEvent.SHOW_ACW, (data) => {
      if (data.ticket == this.$jssip.phone?.meta.ticket) this.availableTime = data.time;
    });
  },
});
</script>

<style lang="sass" scoped>
.info-section
    margin-bottom: 20px
    text-align: justify
    color: $gray-7
    font-size: 14px
    font-style: italic

.label
    display: block
    margin-bottom: 8px

.form
    display: block
    padding: 32px 40px

.textarea
    position: relative

.counter
    position: absolute
    right: 12px
    bottom: 12px
    color: $gray-6
    font-size: $font-small
.acw-style
    color: #F5222D
</style>
