const getters = {
  getRecordsList(state) {
    return state.recordsInfo.items;
  },
  getRecordsInfo(state) {
    return {
      items: state.recordsInfo.items,
      pagination: state.recordsInfo.pagination,
    };
  },
};

export default getters;
