<template>
  <div>
    <a-table
      :columns="columnsTable"
      :data-source="recordsInfo.items"
      row-key="id"
      :scroll="scrollStyle"
      size="middle"
      :pagination="recordsInfo.pagination"
      @change="handleTableChange"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.key === 'date'">
          {{ formatDate(record.call_start_date) }}
        </template>

        <template v-if="column.key === 'hour'">
          {{ formatTime(record.call_start_date) }}
        </template>

        <template v-if="column.key === 'user'">
          {{ text?.fullname }}
        </template>

        <template v-if="['entity', 'portfolio'].includes(column.key)">
          {{ text?.name }}
        </template>

        <template v-if="column.key === 'action'">
          <a-tooltip v-if="record.disposition == 'ANSWERED'" title="Descargar">
            <DownloadRecord :record="record" />
          </a-tooltip>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { columns } from "./RecordsTableColumns";
import { hourReminder, dateTimeToSlashFormat } from "@/app/shared/utils/dates";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { DialingType } from "@/app/shared/utils/enums";
import DownloadRecord from "../buttons/DownloadRecord.vue";

export default defineComponent({
  name: "RecordsTable",
  components: {
    DownloadRecord,
  },
  setup() {
    const { scrollStyle } = useScrollResize({ headerMargin: 440 });

    return { scrollStyle };
  },
  data() {
    return {
      columns,
      typedialing: "",
    };
  },
  computed: {
    recordsInfo() {
      return this.$store.getters["getRecordsInfo"];
    },
    columnsTable() {
      const column = columnsFixedTableLeft(this.columns);
      return column;
    },
  },
  methods: {
    handleTableChange(pagination) {
      this.handleFetchRecords(pagination);
    },
    handleFetchRecords(pagination) {
      this.$emit("update", pagination);
    },
    formatDate(date) {
      return dateTimeToSlashFormat(date, false);
    },
    formatTime(time) {
      return hourReminder(time);
    },
    type_number_dialing(value) {
      if (value == "Manual") {
        this.typedialing = DialingType.Manual;
      } else if (value == "Predictive") {
        this.typedialing = DialingType.Predictive;
      } else if (value == "Progressive") {
        this.typedialing = DialingType.Progressive;
      } else if (value == "Ivr") {
        this.typedialing = DialingType.Ivr;
      } else {
        this.typedialing = DialingType.Inbound;
      }
    },
  },
});
</script>

<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

:deep(.ant-table-thead > tr > th)
  // padding: 10px !important

.switch-status
  margin-right: $margin-tiny
  margin-top: 2px

.iconEdit
  color: $blue-6
  margin-right: 10px
  margin-left: 6px
  margin-top: 3px
.channel
  display: flex
  align-items: center
  position: relative
  &__logo
    display: block
    width: 30px
    height: 24px
    background-size: cover
    background-position: center
    border-radius: 2px
    margin-right: 12px
  &--empty
    background: linear-gradient(to right, #ccc, #777)
</style>
