<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.53017 1.46967C6.82306 1.76256 6.82306 2.23744 6.53017 2.53033L4.47716 4.58333L6.93567 4.58333H6.96868C8.05998 4.58333 8.92487 4.58332 9.6221 4.64033C10.3351 4.69862 10.9385 4.82025 11.4896 5.10105C12.3834 5.55645 13.1101 6.28311 13.5655 7.17688C13.8463 7.72807 13.9679 8.33175 14.0262 9.04522C14.0832 9.74294 14.0832 10.6086 14.0832 11.7009V11.7339V12.6667C14.0832 13.0809 13.7474 13.4167 13.3332 13.4167C12.919 13.4167 12.5832 13.0809 12.5832 12.6667V11.7339C12.5832 10.6012 12.5826 9.79684 12.5312 9.16735C12.4805 8.54661 12.3841 8.16233 12.2289 7.85787C11.9174 7.24634 11.4202 6.74915 10.8086 6.43756C10.5041 6.2824 10.12 6.18604 9.49988 6.13534C8.87093 6.08392 8.06732 6.08333 6.93567 6.08333H4.47716L6.53017 8.13634C6.82306 8.42923 6.82306 8.9041 6.53017 9.197C6.23727 9.48989 5.7624 9.48989 5.46951 9.197L2.13617 5.86366C1.84328 5.57077 1.84328 5.0959 2.13617 4.803L5.46951 1.46967C5.7624 1.17678 6.23727 1.17678 6.53017 1.46967Z"
      :fill="disabled ? 'gray' : color"
      fill-opacity="0.88"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: "#262626",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
