import { store } from "@/store/index";
import items from "@/app/common/components/organisms/TheSidebar/items";
import { OptionsMenu } from "@/@types/global";
import { fetchDataUser } from "@/app/auth/services";

function getPathScope(path: string) {
  const menu = items.find((item) => path.includes(item.route));

  const subMenu = menu?.subitems?.find((subItem) => path.includes(subItem?.route));

  return subMenu?.scope || menu?.scope;
}

export async function validateUserProfileScopes(path: string) {
  const user = store.getters["getDataUser"] || (await fetchDataUser());

  const userMenus: OptionsMenu[] = user ? [...user.options_menu, ...user.options_section] : [];

  const destinyScope = getPathScope(path);

  console.log({ userMenus, destinyScope });

  return userMenus.some((menu) => {
    if (menu.scope === destinyScope) return true;

    if (menu.items.length) return menu.items.some((submenu) => submenu.scope === destinyScope);

    return false;
  });
}
