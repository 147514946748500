<template>
  <div class="actions" :class="{ 'actions-floating': !inWorkspace }">
    <div class="actions-floating__row">
      <!--Desmutear-->
      <icon-button
        v-if="isMuted"
        active
        floating
        label="Silencio"
        :disabled="isConnecting"
        icon="micno"
        @click="toggleUnMute"
      />
      <!--Mutear-->
      <icon-button
        v-else
        floating
        label="Silencio"
        icon="mic"
        :disabled="isConnecting"
        @click="toggleMute"
      />

      <!--Reanudar-->
      <icon-button
        v-if="isHold"
        active
        floating
        label="Pausar"
        :disabled="!isInActiveCall"
        icon="pause"
        @click="toggleUnHold"
      />
      <!--Pausar-->
      <icon-button
        v-else
        floating
        label="Pausar"
        icon="pause"
        :disabled="!isInActiveCall"
        @click="toggleHold"
      />

      <!--Dial Pad-->
      <icon-button
        floating
        label="Teclado"
        :active="showDial && inWorkspace"
        :disabled="!isInActiveCall"
        icon="numbers"
        @click="toggleDialPad"
      />

      <!-- Transferir -->
      <icon-button
        floating
        label="Transferir"
        :active="transfer && inWorkspace"
        :disabled="!isInActiveCall"
        icon="forwarded"
        @click="handleTransfer"
      />
    </div>

    <WebPhoneFooterActions
      :inWorkspace="inWorkspace"
      button-type="hold"
      @click:primary="handleHungUp"
      @click:history="handleHistory"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import { CallStatus } from "@/packages/vue-jssip/jssip/phone";

import IconButton from "../buttons/IconButton.vue";
import WebPhoneFooterActions from "./WebPhoneFooterActions.vue";

export default defineComponent({
  components: {
    IconButton,
    WebPhoneFooterActions,
  },
  props: {
    showDial: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    inWorkspace: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const showVideo = ref(false);
    const showNumbers = ref(false);

    return {
      showVideo,
      showNumbers,
    };
  },
  computed: {
    isMuted() {
      return this.$store.getters["getCallMuted"];
    },
    isHold() {
      return this.$store.getters["getCallHold"];
    },
    isConnecting() {
      return this.$jssip.phone.status == CallStatus.Connecting;
    },
    isStrategyOn() {
      return this.$store.getters["getStrategyStatus"];
    },
    // isMuted() {
    //   return this.$jssip.phone.muted
    // },
    isInActiveCall() {
      return this.$jssip.phone.status == CallStatus.InCall;
    },
  },
  methods: {
    handleHungUp() {
      if (!this.isConnecting) {
        this.$jssip.end();
        this.$emit("onHold");
      }
    },
    toggleMute() {
      this.$store.dispatch("setCallMutated", true);
      this.$jssip.mute();
    },
    toggleUnMute() {
      this.$jssip.unmute();
      this.$store.dispatch("setCallMutated", false);
    },
    toggleHold() {
      this.$jssip.hold();
      this.$store.dispatch("setCallHold", true);
    },
    toggleUnHold() {
      this.$jssip.unhold();
      this.$store.dispatch("setCallHold", false);
    },
    handleTransfer(event) {
      if (event) {
        this.$emit("click:transfer");
      }
    },
    toggleVideo(event) {
      if (event) {
        this.showVideo = !this.showVideo;
      }
    },
    toggleDialPad(event) {
      if (event) {
        this.showNumbers = !this.showNumbers;
        this.$emit("click:keyboard");
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.actions
  margin-top: 30px

.actions-floating
  margin-top: 0

  &__row
    display: flex
    justify-content: center
    align-items: center
    gap: 4px
    margin: 5px 0 40px
</style>
