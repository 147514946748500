<template>
  <web-phone
    :mode="'fixed'"
    @open-overlay="$emit('open-overlay')"
    @minimize-overlay="$emit('minimize-overlay')"
  />
</template>

<script>
import WebPhone from "../../core/WebPhone.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    WebPhone,
  },
});
</script>
