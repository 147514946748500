export const DescriptionByEvent: Record<number, string> = {
  1: "Incluye la recopilación de datos clave en la tipificación como grupo, motivo, submotivo, resultado, ID del ticket, datos generales del contacto, fecha y hora de la tipificación.",
  2: "Permite la actualización de datos tanto generales como personalizados de los contactos. Los datos personalizados se pueden modificar el módulo de plantillas. Mientras que los datos generales incluyen nombres, apellidos, código y número de documento del contacto.",
  3: "Proporciona información detallada sobre el estado de recepción de las plantillas enviadas a los contactos. Registra si las plantillas fueron enviadas con éxito, si fueron recibidas por los destinatarios y si fueron leídas.",
  4: "Esta función registra las actualizaciones realizadas en las colas de atención. Proporciona información sobre el nombre de la cola, los usuarios asignados a ella y los canales de comunicación asociados.",
  5: "Este registro documenta las actualizaciones de los recordatorios programados. Incluye detalles como la nueva fecha asignada, la fecha de la cita original y una breve descripción del evento.",
  6: "Este registro incluye detalles como el ID de ticket asociado, la fecha y hora de la llamada, el número de teléfono, los datos del cliente si están disponibles y identificador de la llamada o DID.",
  7: "Este registro incluye detalles como el mensaje recibido, la fecha y hora de recepción, el canal desde el cual se recibió el mensaje y los detalles de contacto si están disponibles.",
  8: "Registra la transferencia de tickets entre usuarios, incluyendo el ID del ticket, el usuario de origen y el usuario de destino.",
  9: "Registra el ID del ticket al que se le ha asignado una etiqueta, junto con el nombre y el color de la etiqueta correspondiente.",
  10: "Registra la fecha de lanzamiento de una estrategia junto con su ID correspondiente.",
  11: "Registra las llamadas realizadas manualmente, incluyendo detalles como el ID de ticket asociado, la fecha y hora de la llamada, el número de teléfono del contacto, la campaña asociada y los datos del contacto si están disponibles.",
  12: "Registra el estado de la importación de datos, incluyendo el número total de registros importados con error y el número total de registros importados exitosamente.",
  13: "Registra el envío de plantillas, mostrando el ID de la plantilla enviada, el usuario que la envió, el contenido de la plantilla y la fecha y hora del envío.",
  14: "Registra las grabaciones de llamadas, incluyendo la fecha y hora de la llamada, el número de teléfono del contacto, el ID de la grabación, la duración de la llamada y la URL o archivo asociado.",
  15: "Registra las actualizaciones de usuarios, mostrando la fecha de la actualización, los nombres y apellidos del usuario, su ID, y todos los campos modificados durante su edición.",
};

export enum TypeSendOption {
  EnterOption = "enter",
  ClicOption = "clic",
}
