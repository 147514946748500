import { toFormData } from "@/app/shared/utils/helpers";
import httpManager from "@/app/shared/utils/httpManager";

/**
 * Editar un canal
 *
 * @param {number} channelId Identificador del canal.
 * @param {Object} channel Datos del canal a editar
 *
 * @return {Promise<Object>} Datos del canal editado
 */
export default async function (channelId, channel) {
  const body = channel.picture ? toFormData(channel) : channel;

  const response = await httpManager.put(`/channels/channel-customer/${channelId}/`, body);
  return response?.data;
}
