<template>
  <div class="head">
    <div class="head--title">
      <ArrowLeftOutlined @click="toRoutePrevious" />
      <h1>Asunto: {{ message?.subject }}</h1>
    </div>
    <div class="head--title">
      <IconEmail />
      <!-- <span>Score Ventas</span> -->
      <span>{{ message?.sender }} </span>
    </div>
    <!-- <img
      src="https://atg-prod-scalar.s3.amazonaws.com/studentpower/media/user%20avatar.png"
      width="40"
      height="40"
    /> -->
    <ProfileImage
      v-if="currentEmailId"
      :photo="threadActual?.client?.profile_picture"
      :name="threadActual?.client?.fullname"
      size="small"
    />
    <ActionsCard
      :role="isAdmin"
      :isAssigned="isAssigned"
      :isSubject="true"
      :currentMessage="message"
      :selectedRows="selectedRows"
      @emitAssignEvent="handleAssign"
    />
    <!-- @resolved="emit('update')" -->
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
// import { SwapOutlined, UserSwitchOutlined } from "@ant-design/icons-vue";
import ActionsCard from "./ActionsCard.vue";
import IconEmail from "@/app/common/components/atoms/IconEmail.vue";
import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
import { DataType, EmailDetails, Thread } from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";
import { computed } from "vue";
import { UserData } from "@/@types/global";
import { useStore } from "vuex";
import { message as antMessage } from "ant-design-vue";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";

const isAdmin = ref<boolean>(true);

const props = defineProps<{
  isAssigned: boolean;
  message: EmailDetails | null;
  currentEmailId: number | undefined;
  selectedRows: DataType[] | Thread[];
}>();

const store = useStore();

const user = computed<UserData>(() => store.getters["getDataUser"]);

const threadActual = computed(() => {
  if (props.selectedRows?.length == 1) {
    return props.selectedRows?.[0];
  }
  return null;
});

const emit = defineEmits<{ update: []; close }>();

const handleAssign = async () => {
  if (!props.currentEmailId) return;

  await emailService.assignEmailsToUser([props.currentEmailId], user.value.id);

  antMessage.success("Se te ha asignado el email");

  emit("update");
};

const toRoutePrevious = () => {
  console.log("close 1");
  emit("close");
};
</script>
<style lang="sass" scoped>
.head
  display: flex
  justify-content: space-between
  background-color: #F2F5F7
  padding: 10px
  align-items: center
  box-shadow: 0 4px 2px -3px #C5C5C5
  h1
    margin: 0
  .actions
    color: #1677FF
    background: inherit
    border: 0
  &--title
    display: flex
    justify-items: center
    gap: 5px
    h1
      margin-left: 10px
</style>
