<template>
  <a-modal
    :visible="visible"
    width="450px"
    :body-style="bodyStyle"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img
        class="image__delete"
        src="@/app/users/assets/profiles/question-circle.png"
        alt="icon-question"
      />
      <p class="text__alert">¿Estás seguro de cerrar la session del usuario</p>
      <p class="text__alert">“{{ record?.user.fullname }}”?</p>
    </div>
    <div class="container__info">
      <p class="text">Recuerda que se tomarán las siguientes acciones:</p>
      <ul>
        <li>Si se encuentra en una llamada, ésta se cortará.</li>
        <li>
          Sus chats asignados seguirán activos hasta que el usuario vuelva a ingresar a la
          plataforma.
        </li>
      </ul>
    </div>
    <div class="button__disabled">
      <a-button style="width: 100%" class="footer__delete" type="primary" @click="handleCloseModal">
        Cerrar sesión
      </a-button>
      <!-- <a-button
        style="width: 100%"
        class="footer__delete"
        type="secondary"
        @click="handleCloseModal({ unassign: true })"
      >
        Cerrar sesión y devolver tickets a su cola
      </a-button> -->
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";
import { disconnectAgentById } from "@/app/supervision/services";

export default defineComponent({
  name: "InactiveUserModal",
  components: {},
  props: {
    record: {
      type: Object,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const bodyStyle = {
      height: "450px",
      loading: false,
    };

    return {
      bodyStyle,
    };
  },
  computed: {
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleCloseModal({ unassign = false } = {}) {
      this.logOut({ unassign });
    },
    handleCloseModalHeader() {
      this.$emit("onClose");
    },
    async logOut({ unassign }) {
      try {
        await disconnectAgentById(this.record.user.id, { unassign });
        this.$socket.emit("disconnectUser", {
          customer_id: this.user.customer.id,
          user: this.record.user.id,
        });
        this.$message.success(`Se ha cerrado la sesión del usuario ${this.record.user.fullname}`);
      } catch (error) {
        this.$message.error("Error al eliminar el usuario");
      }
      this.$emit("onClose");
    },
  },
});
</script>

<style lang="sass" scoped>
.image__delete
  margin-bottom: 10px
  margin-top: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin: 0

.button__disabled
  margin-top: 10px

.footer__delete
  margin-bottom: 20px

.container__inactive
  text-align: center
  color: $gray-13

.container__info
  margin-bottom: 18px
  color: $gray-8

.text
  margin-top: 20px
  margin-bottom: 12px
</style>
