const RECAPTCHA_TOKEN = import.meta.env.VITE_RECAPTCHA_TOKEN;

export function getRecaptchaToken() {
  return new Promise((res) => {
    // eslint-disable-next-line no-undef
    grecaptcha.ready(async () => {
      // eslint-disable-next-line no-undef
      const token = await grecaptcha.execute(RECAPTCHA_TOKEN);
      res(token);
    });
  });
}
