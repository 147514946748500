import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar arbol de tipificaciones por campaña.
 *
 * @param {number} portfolioId Identificador de la campaña.
 *
 * @return {Promise<Object>} Datos de la campaña y el arbol. `level_palette` `groups`
 */

export async function updateTypificationToPortfolio(portfolioId, typificationTree) {
  const body = { groups: typificationTree };
  const response = await httpManager.put(
    `/entities/portfolios/${portfolioId}/tree_tipificactions_ru/`,
    body
  );
  return response.data;
}
