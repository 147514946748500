import countriesStore from "@/app/shared/store/countries";
import headersStore from "@/app/shared/store/headers";
import strategyStore from "@/app/shared/store/strategies";
import agentsStore from "@/app/shared/store/agents";
import tenantStore from "@/app/shared/store/tenant";

export default {
  modules: {
    countriesStore,
    headersStore,
    strategyStore,
    agentsStore,
    tenantStore,
  },
};
