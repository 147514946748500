<template>
  <a-modal
    centered
    style="width: 370px"
    title="Variables capturadas"
    :open="visible"
    @cancel="handleClose"
    v-if="dataSource"
  >
    <div v-if="dataSource">
      <a-typography-text strong> Ticket ID: </a-typography-text>
      <p class="modal__text--secondary">#{{ dataSource.id }}</p>
      <a-typography-text strong> Nombre del chatbot: </a-typography-text>
      <p class="modal__text--secondary">{{ chatbotName }}</p>
      <a-typography-text strong> Variables capturas por el chatbot: </a-typography-text>
      <ul class="modal__list">
        <li v-for="(varItem, index) in dataSource.ticket_variables" :key="`var_${index}`">
          <span class="modal__text--secondary">{{ varItem.bot_variable.variable.name }}:</span>
          <span class="mrg-left-8 modal__text--tertiary">{{ varItem.value }}</span>
        </li>
      </ul>
    </div>
    <template #footer>
      <a-row type="flex" align="middle" justify="center" class="modal__footer">
        <a-button @click="handleClose">Cerrar</a-button>
      </a-row>
    </template>
  </a-modal>
</template>
<script lang="ts" setup>
import { computed, toRefs } from "vue";

const props = defineProps({
  visible: { type: Boolean },
  dataSource: {
    type: Object,
    required: true,
  },
});

const { visible, dataSource } = toRefs(props);

const emits = defineEmits(["onClose"]);

const chatbotName = computed(() => {
  return dataSource.value ? dataSource.value.ticket_variables[0].bot_variable.bot.name : "";
});

const handleClose = () => {
  emits("onClose");
};
</script>

<style lang="sass" scoped>
.modal
  &__text--secondary
    color: $text-secondary
  &__text--tertiary
    color: $text-tertiary
  &__list
    padding-left: 24px
  &__footer
    margin-top: 32px
</style>
