import { fetchMonitorUsers, fetchStatus } from "@/app/supervision/services";
import { SET_MONITOR_USERS } from "./mutation-types";

const actions = {
  async fetchMonitorUsers({ commit }, filters) {
    const users = await fetchMonitorUsers(filters);
    commit(SET_MONITOR_USERS, users);
  },
  async fetchStatusMonitoring() {
    return fetchStatus();
  },
};

export default actions;
