<template>
  <div>
    <a-modal
      :visible="visible"
      width="400px"
      centered
      :body-style="{ padding: 0 }"
      title="Crear recordatorio"
      :mask-closable="false"
      :keyboard="false"
      @cancel="handleConfirmationBeforeToCloseModal"
    >
      <a-form class="form" layout="vertical" :model="formState" :rules="rules" ref="formRef">
        <a-form-item label="Título" name="title" required>
          <a-input
            v-model:value="formState.title"
            placeholder="Escribir titulo del recordatorio"
            :maxlength="maxObservationName"
            @input="handleFormChange"
          />

          <span class="countername"> {{ formState.title.length }}/{{ maxObservationName }} </span>
        </a-form-item>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Seleccione fecha" name="date_schedule" required>
              <a-date-picker
                v-model:value="formState.date_schedule"
                style="width: 100%"
                :disabled-date="disabledDate"
                placeholder="-- / -- / --"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
                :show-today="false"
                @change="handleFormChange"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Seleccione hora" name="time_schedule" required>
              <a-time-picker
                v-model:value="formState.time_schedule"
                v-model:open="open"
                placeholder="00:00"
                format="HH:mm"
                valueFormat="HH:mm:ss"
                style="width: 100%"
                @change="handleFormChange"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item name="description">
          <p>Descripción</p>
          <a-textarea
            v-model:value="formState.description"
            class="textarea"
            rows="5"
            placeholder="Escribir el mensaje inicial"
            :maxlength="maxDescriptionLength"
            @input="handleFormChange"
          />

          <span class="countername">
            {{ formState.description.length }}/{{ maxDescriptionLength }}
          </span>
        </a-form-item>
      </a-form>

      <!-- Fotter Modal -->
      <template #footer>
        <a-button key="back" @click="handleCloseModal"> Cancelar </a-button>
        <a-button key="submit" :disabled="!showConfirmModal" type="primary" @click="handleOk">
          Crear
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from "vue";
import { disabledDate } from "@/app/shared/utils/dates";

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedDate: {
      required: false,
      type: Date,
      default: null,
    },
  },
  setup() {
    const maxObservationName = 50; // Máxima cantidad de caracteres en el campo de "Titulo Recordatorio"
    const formRef = ref();
    const showConfirmModal = ref(false);
    const open = ref(false);
    const formState = reactive({
      title: "",
      date_schedule: "",
      time_schedule: "",
      description: "",
    });
    const rules = {
      title: [{ required: true, message: "Campo requerido" }],
      date_schedule: [{ required: true, message: "Campo requerido" }],
      time_schedule: [{ required: true, message: "Campo requerido" }],
      // description: [{ required: true, message: "Campo requerido" }],
    };
    return {
      rules,
      formState,
      formRef,
      maxObservationName,
      showConfirmModal,
      open,
      maxDescriptionLength: 100,
    };
  },
  watch: {
    selectedDate(value) {
      if (value) {
        this.formState.date_schedule = value?.substring(0, 10);
        this.formState.time_schedule = value?.substring(11);
      }
    },
  },
  methods: {
    handleOk() {
      this.formRef.validateFields().then(() => {
        const description = toRaw(this.formState).description || "-";

        const data = {
          title: toRaw(this.formState).title,
          date_schedule: toRaw(this.formState).date_schedule,
          time_schedule:
            toRaw(this.formState).date_schedule + " " + toRaw(this.formState).time_schedule,
          description,
        };
        try {
          this.$store.dispatch("addReminder", data);
          this.handleCloseModal();
          this.$message.success("Se ha creado un nuevo recordatorio correctamente");
        } catch (error) {
          this.$message.error("Hubo un error al crear un evento");
        }
      });
    },
    handleCloseModal() {
      this.$emit("handleCloseModal");
      this.formRef.resetFields();
      this.showConfirmModal = false;
    },
    disabledDate(current) {
      return disabledDate(current);
    },
    handleConfirmationBeforeToCloseModal() {
      if (this.showConfirmModal) {
        this.$confirm({
          title: "¿Estás seguro de cerrar el proceso?",
          content: "Al cerrar no se guardarán los datos previamente ingresados",
          onOk: () => this.handleCloseModal(),
          centered: true,
          okText: "Cerrar",
          cancelText: "Cancelar",
        });
      } else {
        this.handleCloseModal();
      }
    },
    handleFormChange() {
      this.showConfirmModal = true;
    },
  },
});
</script>

<style lang="sass" scoped>
.form
  padding: 20px
  p
    margin-bottom: 6px
    color: $gray-9
.space
  margin-bottom: 8px
.textarea
  height: 56px
  margin: auto
.date
  display: flex
  justify-content: space-between
  .picker
    width: 170px
.avatar
  display: flex
  align-items: center
  gap: 10px
.ant-tag
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 230px
.countername
  bottom: 0px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6
</style>
