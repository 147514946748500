import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un usuario.
 *
 * @param {number} userId Identificador del usuario.
 */
export async function deleteUser(userId) {
  await httpManager.delete(`/users/${userId}/`);
}
