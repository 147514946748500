import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener colas.
 *
 * @return {Promise<Array>} Lista de colas.
 */
export async function fetchQueues({
  search = "",
  portfolio = "",
  templatePicker = false,
  smsTemplatePicker = false,
  channelCustomer = undefined,
} = {}) {
  let url = `/queues/?`;

  if (search) url += `search=${search}&`;
  if (channelCustomer) url += `channel_customer_id=${channelCustomer}&`;
  if (portfolio) url += `portfolios=${portfolio}&`;
  if (smsTemplatePicker) url += `linked_to_sms_template=${smsTemplatePicker || ""}`;
  if (templatePicker) url += `linked_to_whatsapp_template=${templatePicker}&status=1`;

  const response = await httpManager.get(url);
  return response?.data?.results;
}
