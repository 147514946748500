<template>
  <section>
    <p class="paragraph">Puedes agregar o editar información acorde a los permisos configurados.</p>
    <a-table
      row-key="id"
      size="middle"
      :columns="columnsTable"
      :data-source="[customData]"
      :scroll="{ x: 700 }"
      :pagination="false"
    >
      <template #actionScoped="{ record }">
        <div class="actions" v-if="columnsTable?.length > 1">
          <a-tooltip>
            <template #title> Editar </template>
            <contact-data-custom-drawer :record="record" :itemId="itemId" />
          </a-tooltip>
        </div>
        <!-- <a-empty :image="simpleImage" v-else /> -->
      </template>
    </a-table>
  </section>
</template>

<script>
import ContactDataCustomDrawer from "@/app/contacts/components/drawers/ContactDataCustomDrawer.vue";
import { Empty } from "ant-design-vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ContactDataCustomDrawer,
  },
  props: {
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  data() {
    return {
      isEditInformationVisible: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
  watch: {
    itemId(value) {
      if (value) {
        this.$store.dispatch("fetchContactCustomFields", value);
        this.$store.dispatch("fetchContactCustomData", value);
      }
    },
  },
  computed: {
    getColumns() {
      return this.$store.getters["getContactCustomFields"];
    },
    customData() {
      return this.$store.getters["getContactCustomData"];
    },
    columnsTable() {
      const columns = this.getColumns;
      columns.push({
        title: "Acciones",
        key: "action",
        width: 100,
        fixed: "right",
        slots: { customRender: "actionScoped" },
      });
      return columns;
    },
  },
  created() {
    this.$store.dispatch("fetchContactCustomFields", this.itemId);
    this.$store.dispatch("fetchContactCustomData", this.itemId);
  },
  methods: {
    handleCloseDrawer() {
      this.isEditInformationVisible = false;
    },
  },
});
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  overflow-wrap: initial
  width: 9em

.actions
  display: flex
</style>
