export const columns = [
  {
    title: "Código",
    dataIndex: "code",
    key: "code",
    width: "100px",
  },
  {
    title: "Nº de documento",
    dataIndex: "document_number",
    key: "document_number",
    width: "150px",
  },
  {
    title: "Nombre",
    dataIndex: "first_name",
    key: "first_name",
    width: "200px",
  },
  {
    title: "Apellidos",
    dataIndex: "last_name",
    key: "last_name",
    width: "200px",
  },
  {
    title: "Teléfono",
    dataIndex: "phones",
    key: "phones",
    width: "140px",
    slots: { customRender: "phones" },
  },
  {
    title: "Acciones",
    key: "operation",
    fixed: "right",
    width: "100px",
    slots: { customRender: "manage" },
  },
];
