export const columns = [
  {
    title: "Dirección",
    dataIndex: "address",
    width: 270,
  },
  {
    title: "Tipo",
    dataIndex: "province",
    width: 130,
    key: "province",
    slots: { customRender: "province" },
  },
  {
    title: "Departamento",
    dataIndex: "district",
    width: 130,
    key: "district",
    slots: { customRender: "district" },
  },
  {
    title: "Provincia",
    dataIndex: "province",
    width: 130,
    key: "province",
    slots: { customRender: "province" },
  },
  {
    title: "Distrito",
    dataIndex: "type_address",
    width: 160,
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 100,
    fixed: "right",
  },
];
