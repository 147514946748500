const moduleName = "WORKSPACE";

export const WorkspaceEvent = Object.freeze({
  MANAGEMENT_SAVED: `${moduleName}-MANAGEMENT_SAVED`,
  TICKET_CLOSED: `${moduleName}-TICKET_CLOSED`,
  DIAL_CLOSE: `${moduleName}-DIAL_CLOSE`,
  TYPIFICATION_DEFAULT: `${moduleName}-TYPIFICATION_DEFAULT`,
  DIAL_OPEN: `${moduleName}-DIAL_OPEN`,
  DRAWER_TEMPLATE_OPEN: `${moduleName}-DRAWER_TEMPLATE_OPEN`,
  SHOW_CONFIRM_MODAL: `${moduleName}-SHOW_CONFIRM_MODAL`,
  HIDE_CONFIRM_MODAL: `${moduleName}-HIDE_CONFIRM_MODAL`,
  OPEN_DASHBOARD_SECURITY: `${moduleName}-OPEN_DASHBOARD_SECURITY`,
  OPEN_DASHBOARD_MY_PROFILE: `${moduleName}-OPEN_DASHBOARD_MY_PROFILE`,
  ACTIVATE_IDLE_TIME: `${moduleName}-ACTIVATE_IDLE_TIME`,
  DEACTIVATE_IDLE_TIME: `${moduleName}-DEACTIVATE_IDLE_TIME`,
  SHOW_ACW: `${moduleName}-SHOW_ACW`,
  TRANSFER_CALL: `${moduleName}-TRANSFER_CALL`,
  DISABLED_INPUT_WHATSAPP: `${moduleName}-DISABLED_INPUT_WHATSAPP`,
  RECALL: `${moduleName}-RECALL`,
  SESSION_CLOSED: `${moduleName}-SESSION_CLOSED`,
  START_IDENTIFIER_TIME: `${moduleName}-START_IDENTIFIER_TIME`,
  FINISH_IDENTIFIER_TIME: `${moduleName}-FINISH_IDENTIFIER_TIME`,
  TIME_SUPERVITION: `${moduleName}-TIME_SUPERVITION`,
  OPEN_MODAL_WORKSPACE: `${moduleName}-OPEN_MODAL_WORKSPACE`,
  CHECK_MIC: `${moduleName}-CHECK_MIC`,
  MIC_DISABLED: `${moduleName}-MIC_DISABLED`,
  STATE_SUPERVISION: `${moduleName}-STATE_SUPERVISION`,
  RESET_INACTIVITY_TIME: `${moduleName}-RESET_INACTIVITY_TIME`,
  ANOTHER_USER_HAS_THIS_TICKET_ACTIVE: `${moduleName}-ANOTHER_USER_HAS_THIS_TICKET_ACTIVE`,
  ENDED_BY_TYPIFICATION: `${moduleName}-ENDED_BY_TYPIFICATION`,
  MAKE_OUTBOUND_CALL: `${moduleName}-MAKE_OUTBOUND_CALL`,
  REDIRECT_AT_OUTBOUND_CALL: `${moduleName}-REDIRECT_AT_OUTBOUND_CALL`,
  CHANGED_GLOBAL_DOWNLOAD_STATE: `${moduleName}-CHANGED_GLOBAL_DOWNLOAD_STATE`,
  USER_SCREEN_MONITORING_INITIATED: `${moduleName}-USER_SCREEN_MONITORING_INITIATED`,
  USER_SCREEN_MONITORING_ENDED: `${moduleName}-USER_SCREEN_MONITORING_ENDED`,
  CALL_WITHOUT_BALANCE: `${moduleName}-CALL_WITHOUT_BALANCE`,
  RESET_LIST: `${moduleName}-RESET_LIST`,
});
