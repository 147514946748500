import httpManager from "@/app/shared/utils/httpManager";
/**
 * Etiquetar uno o varios tickets.
 *
 * @param {Object} params Objeto con ticketsId y tagId
 *
 */
export async function tagTickets(params) {
  const { ticketsId, tagId } = params;

  const body = {
    tickets: ticketsId,
    tag: tagId,
  };

  await httpManager.post(`/tickets/assign-tag/`, body);
}
