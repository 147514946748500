<template>
  <div class="drawer__user">
    <div class="text">
      <UserAvatar :record="info" />
      <div
        class="element"
        :style="{ '--bgColor': bgColorInactive }"
        v-if="info.event_management === 16"
      />
      <div class="element" :style="{ '--bgColor': bgColor }" v-else />
      <div>
        <div class="text-name">
          <strong>{{ name }}</strong>
        </div>
        <p class="text__caption">{{ subtitle }}</p>
      </div>
    </div>
    <span><strong>Tickets atendidos los últimos 3 dias</strong></span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { StateOptions } from "@/app/shared/utils/enums";
import UserAvatar from "@/app/shared/components/avatar/UserAvatar.vue";
import { EventManagement } from "@/app/shared/utils/enums";

const states = {
  [StateOptions.Connected]: "#73D13D",
  [StateOptions.Refreshment]: "#FFC53D",
  [StateOptions.Occupied]: "#FF4D4F",
  [StateOptions.Services]: "#36CFC9",
  [StateOptions.Training]: "#EB2F96",
  [StateOptions.NotAvailable]: "#40A9FF",
  [StateOptions.Disconnected]: "#BFBFBF",
  [EventManagement.Inactive]: "#BFBFBF",
};

export default defineComponent({
  components: { UserAvatar },
  props: {
    stats: { required: true, type: Object },
    status: { required: true, type: Number },
    info: { required: true, type: Object },
  },
  computed: {
    user() {
      return this.stats.user;
    },
    name() {
      return this.user.fullname;
    },
    photo() {
      return this.user.picture;
    },
    bgColor() {
      return states[this.status];
    },
    bgColorInactive() {
      return states[this.info.event_management];
    },
    subtitle() {
      let assignned =
        this.stats.tickets_statistics.total - this.stats.tickets_statistics.total_closed;
      return `${assignned} tickets asignados`;
    },
  },
});
</script>

<style lang="sass" scoped>
.text-name
  max-width: 190px

.text
  display: flex
  flex-direction: row
  gap: 10px

  &__caption
    font-size: 12px
    font-weight: unset

:deep(.avatar)
  margin-right: 16px
.element
  position: absolute
  top: 50px
  left: 50px
  $circle-size: 12px

  &:after
    content: ''
    display: inline-block
    border-radius: 50%
    width: $circle-size
    height: $circle-size
    margin-left: 4px
    background-color: var(--bgColor)
.name
  display: flex
  align-items: center
  position: relative
  &__logo
    padding: 5px 6px
    display: flex
    justify-content: center
    background: #FFF7E6
    border-radius: 50%
    height: 40px
    margin-right: 5px
    width: 40px

    &--empty > img
      margin: auto
      width: 19px
      height: 19px
      filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)
</style>
