import { GetterTree } from "vuex";
import { State } from "@/@types/emails";

const getters: GetterTree<State, any> = {
  getSelectedMenu: (state) => {
    return state.selectedMenu;
  },
  getMenuItems: (state) => {
    return state.menuItems;
  },
  getOpenKeysMenuItems: (state) => {
    return state.openKeysMenuItems;
  },
  getSelectedKeysMenuItems: (state) => {
    return state.selectedKeysMenuItems;
  },
  getSelectedCampaign: (state) => {
    return state.selectedCampaign;
  },
  getTotalEmails: (state) => {
    return state.totalEmails;
  },
  getTablePage: (state) => {
    return state.tablePage;
  },
  getFilters: (state) => {
    return state.filters;
  },
  getThreadActual: (state) => {
    return state.threadActual;
  },
  getThreadsInbox: (state) => {
    return state.threadsInbox;
  },
  getFetchingThreadsInbox: (state) => {
    return state.fetchingThreadsInbox;
  },
  getSelectedThreadsInbox: (state) => {
    return state.selectedThreadsInbox;
  },
};

export default getters;
