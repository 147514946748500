<template>
  <perfect-scrollbar ref="scrollbar" class="scroll-area" :style="style" :settings="settings">
    <slot />
  </perfect-scrollbar>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
// import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "vue3-perfect-scrollbar/style.css";

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    width: {
      required: false,
      type: String,
      default: "",
    },
    height: {
      required: false,
      type: String,
      default: "",
    },
    auto: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: true,
      },
    };
  },
  computed: {
    style() {
      const style = { width: this.width };
      if (this.auto) style.maxHeight = this.height;
      else style.height = this.height;
      return style;
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.scrollbar.$el.scrollTop = 0; // Resetea el scroll vertical al tope
    },
  }
};
</script>

<style lang="sass" scoped>
.scroll-area
  position: relative
  margin: auto

.scroll-shadows
  background: linear-gradient( white 30%, rgba(255, 255, 255, 0)) center 86px, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center 86px, radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom
  background-repeat: no-repeat
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px
  background-attachment: local, local, scroll, scroll
</style>
