<template>
  <div class="popover">
    <div class="popover__body">
      <div class="popover__userdata">
        <h4>Nombre: {{ name }}</h4>
        <div>Codigo de contacto: {{ code }}</div>
        <div v-if="data?.channel.id == 3">Teléfono: {{ identifier }}</div>
        <a-divider />
      </div>

      <div class="popover__strategy">
        <div>Tipo de interacción: {{ typeInteraction }}</div>
        <div>Canal: {{ channel }}</div>
        <p>Identificador: {{ associate }}</p>
        <a-tag v-if="state == EventManagement.Opened" color="blue">Abriendo</a-tag>
        <a-tag v-else-if="state == EventManagement.Writing" color="green">Escribiendo</a-tag>
        <a-tag v-else-if="state == EventManagement.Typifying" color="orange">Tipificando</a-tag>
        <a-tag v-else-if="state == EventManagement.Resolved" color="cyan">Resolviendo</a-tag>
        <a-divider />
      </div>

      <div class="popover__footer">
        <a-tooltip v-if="!getWorkspace">
          <template #title>
            Para capturar, tu perfil debe tener habilitado el módulo de Workspace
          </template>
          <a-button type="link" disabled @click="captureTicket()"> Capturar</a-button>
        </a-tooltip>
        <a-button v-else type="link" @click="captureTicket()"> Capturar</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventManagement } from "@/app/shared/utils/enums";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    identifier: { required: true, type: String },
    associate: { required: true, type: String },
    data: { required: false, type: Object, default: () => ({}) },
    state: { required: false, type: Number, default: null },
  },
  data() {
    return {
      EventManagement,
    };
  },
  computed: {
    typeInteraction() {
      return this.data.last_type_interaction == 1 ? "Inbound" : "Outbound";
    },
    userData() {
      return this.$store.getters["getDataUser"];
    },
    getWorkspace() {
      return !!this.userData?.options_menu.find((item) => item.scope === "menu_workspace");
    },
    name() {
      if (this.data.client) return this.data?.client?.fullname;
      return "Sin identificar";
    },
    code() {
      if (this.data?.client) return this.data.client?.code;
      return "Sin identificar";
    },
    channel() {
      if (this.data.channel) {
        if (this.data?.channel.name == "Chat Inbound") return "Web Chat";
        return this.data?.channel.name;
      }
      return "Sin identificar";
    },
  },
  methods: {
    captureTicket() {
      this.$emit("capture");
    },
  },
});
</script>

<style lang="sass" scoped>
.popover
  font-size: 12px
  color: $gray-8
  padding: 3px
  &__body
    min-width: 120px
    display: grid
    grid-template-rows: 80px 100px 30px
    gap: 5px

  &__footer
    font-size: 13px
    display: flex
    justify-content: flex-end

.ant-divider-horizontal
  margin: 12px 0
:deep(.ant-btn)
  font-size: 12px
</style>
