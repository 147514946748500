export default [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/not-found-workspace",
    component: () =>
      import(/* webpackChunkName: "noWorkspace" */ "@/app/common/pages/NoExistWorkspace.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  {
    path: "/loginFacebook",
    component: () =>
      import(/* webpackChunkName: "loginFacebook" */ "@/app/common/pages/LoginWithFacebook.vue"),
    meta: {
      requiresAuth: false,
      layout: "empty",
    },
  },
  {
    path: "/deleteFacebookPage",
    component: () =>
      import(/* webpackChunkName: "deleteFacebook" */ "@/app/common/pages/DeleteFacebookPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "empty",
    },
  },
  {
    path: "/loginInstagram",
    component: () =>
      import(/* webpackChunkName: "loginFacebook" */ "@/app/common/pages/LoginWithInstagram.vue"),
    meta: {
      requiresAuth: false,
      layout: "empty",
    },
  },
  {
    path: "/deleteInstagramPage",
    component: () =>
      import(/* webpackChunkName: "deleteFacebook" */ "@/app/common/pages/DeleteInstagramPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "empty",
    },
  },
  {
    path: "/support",
    component: () => import(/* webpackChunkName: "support" */ "@/app/common/pages/SupportPage.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
      hideClouds: true,
    },
  },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "NotFound",
  //   component: NotFoundPage,
  //   meta: {
  //     requiresAuth: false,
  //     layout: "footer",
  //   },
  // },
];
