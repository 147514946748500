import { fetchAllChannels, fetchChannelById, updateChannel } from "@/app/users/services";

import { SET_CHANNELS, SET_CHANNEL, ADD_NEW_CHANNEL } from "./mutation-types";

const actions = {
  async fetchAllChannels({ commit }) {
    const channels = await fetchAllChannels();
    commit(SET_CHANNELS, channels);
  },
  async fetchChannelById({ commit }, id) {
    const channel = await fetchChannelById(id);
    commit(SET_CHANNEL, channel);
  },
  async updateChannel({ commit }, { id, params }) {
    const channel = await updateChannel(id, params);
    const dataChannel = channel.data;
    commit(ADD_NEW_CHANNEL, { id, dataChannel });
  },
};

export default actions;
