<template>
  <supervision-drawer
    v-if="stats"
    :stats="stats"
    :record="record"
    @onClose="handleClose"
    @refresh="refresh"
    @onLogout="handleLogout"
  />
</template>

<script>
import SupervisionDrawer from "../drawer/SupervisionDrawer.vue";
import { fetchMonitorStats } from "@/app/supervision/services";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SupervisionDrawer,
  },
  props: {
    id: { required: true, type: Number || String },
    record: { required: true, type: Object },
  },
  data() {
    return {
      stats: null,
    };
  },
  watch: {
    id() {
      this.fetchStats();
    },
  },
  mounted() {
    this.fetchStats();
  },
  methods: {
    async fetchStats() {
      this.stats = await fetchMonitorStats(this.id);
    },
    handleClose() {
      this.$emit("onClose");
    },
    handleLogout() {
      this.$emit("onLogout");
    },
    refresh() {
      this.fetchStats();
    },
  },
});
</script>
