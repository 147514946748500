<template>
  <a-modal
    v-model:visible="visible"
    width="726px"
    centered
    :footer="null"
    :bodyStyle="{ height: isIntegrationCompleted ? '720px' : '600px' }"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <div v-if="isIntegrationCompleted == false" class="template">
      <iframe
        :src="modalSrc"
        title="Add facebook channel"
        id="inner"
        name="example"
        style="zoom: 0.8"
        height="99%"
        width="99.6%"
      />
    </div>
    <div v-else-if="isIntegrationCompleted">
      <CompleteIntegration
        description="Tu nueva página de F. Messenger se encuentra lista"
        @remove-listener="handleRemoveListener"
      />
    </div>
    <div v-else-if="isIntegrationCompleted == undefined">
      <IncompleteIntegration @remove-listener="handleRemoveListener" />
    </div>
  </a-modal>
</template>

<script>
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import IncompleteIntegration from "@/app/channels/components/templates/SocialChannels/IncompleteIntegration.vue";
import { tokenManager } from "@/app/shared/utils/tokenManager";

export default {
  name: "MessengerChannels",
  components: {
    CompleteIntegration,
    IncompleteIntegration,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isIntegrationCompleted: false,
    textComplete: {
      channel: "messenger",
      title: "Facebook Messenger",
      description: "Tu canal de Facebook Messenger está listo para su implementación",
    },
    modalSrc: "",
    iWindow: null,
    listener: null,
  }),
  computed: {
    visible() {
      return this.open;
    },
    userData() {
      return this.$store.getters["getDataUser"];
    },
  },
  mounted() {
    this.listener = () => {
      this.isIntegrationCompleted = !this.isIntegrationCompleted;
    };

    window.addEventListener("message", this.listener);

    this.handleLogin();
  },
  methods: {
    async handleLogin() {
      const token = tokenManager.getToken();
      const workspace = localStorage.getItem("workspace");
      const { id, fullname } = this.userData;

      if (window.location.hostname == "localhost") {
        this.modalSrc = `https://localhost:8080/loginFacebook?embed=true&access_token=${token}&workspace=${workspace}&userId=${id}&userFullname=${fullname}`;
      } else {
        this.modalSrc = `https://${
          import.meta.env.VITE_HOSTNAME
        }/loginFacebook?embed=true&access_token=${token}&workspace=${workspace}&userId=${id}&userFullname=${fullname}`;
      }
    },
    handleEvent(event) {
      this.isIntegrationCompleted = event.detail.integration;
      const channel = event.detail.response.data;
      this.$store.commit("ADD_CHANNEL", channel);
    },
    handleRemoveListener() {
      window.removeEventListener("message", this.listener, false);
    },
    handleClose() {
      this.handleRemoveListener();
      this.$emit("close");
    },
  },
};
</script>

<style lang="sass" scoped>
.template
  height: 100%
.container
  display: flex
  flex-direction: column
  align-items: center
.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  text-align: center
  color: $gray-9
.link
  color: $blue-6
  margin-bottom: 0px
  margin-left: $margin-tiny
  font-weight: normal

iframe
  border: none
</style>
