export const ErrorTypes = Object.freeze({
  NotMarchingFbUser: 1,
});

export const WhatsappTemplateCategories = Object.freeze({
  Marketing: "MARKETING",
  Transactional: "UTILITY",
  OTP: "AUTHENTICATION",
});

export const WhatsappTemplateStatus = Object.freeze({
  Active: 1,
  Inactive: 2,
});

export const WhatsappTemplateStates = Object.freeze({
  Approved: "APPROVED",
  Rejected: "REJECTED",
  Pending: "PENDING",
  Failed: "FAILED",
});

export const WhatsappTemplateTypes = Object.freeze({
  Text: "TEXT",
  TextAndImage: "IMAGE",
  TextAndDocument: "DOCUMENT",
  TextAndVideo: "VIDEO",
});

export const WhatsappTemplateButtons = Object.freeze({
  WithoutButton: "WITHOUT_BUTTON",
  WithButton: "WITH_BUTTON",
});
export const WhatsappTemplateTypeButtons = Object.freeze({
  CallToAction: "CALL_TO_ACTION",
  QuickReply: "QUICK_REPLY",
});
export const WhatsappTemplateTypeSpecificButtons = Object.freeze({
  PhoneNumber: "PHONE_NUMBER",
  Url: "URL",
  OTP: "OTP",
});
