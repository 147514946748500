<template>
  <!-- <contact-info-unknown /> -->

  <a-tabs v-model:activeKey="activeKey">
    <template #renderTabBar="{ DefaultTabBar, ...props }">
      <component :is="DefaultTabBar" v-bind="props" class="tabs-header" />
    </template>

    <a-tab-pane key="1" tab="Buscar contacto">
      <a-steps v-if="!portfolioId" :current="searchContactCurrentStep" size="small" class="steps">
        <a-step title="Seleccionar contacto" />
        <a-step title="Asignar campaña" />
      </a-steps>

      <a-divider type="horizontal" class="divider divider-header" />

      <section class="container">
        <!-- IVR VAR SECTION -->
        <section v-if="hasIvrVarSection && ivrVarsAnswer.length" class="ivr-var-section">
          <div class="mb-16">
            <a-button type="link" @click="onShowIvrVarSection" class="ivr-var-section__button">
              Variables IVR
              <RightOutlined :class="chevronIconClasses" />
            </a-button>
            <em class="ivr-var-section__em">{{ currentStatusLabel }}</em>
          </div>

          <Transition name="ivr-vars-fade" mode="in-out">
            <div v-if="showIVRVars">
              <div class="ivr-vars-row ivr-vars-row__header">
                <p>Nombre variable</p>
                <p>Variable capturada</p>
              </div>
              <div
                class="ivr-vars-row ivr-vars-row__cell"
                v-for="item in ivrVarsAnswer"
                :key="item?.variable_name"
              >
                <p>{{ item?.variable_name }}</p>
                <p>{{ item?.answer }}</p>
              </div>
            </div>
          </Transition>
        </section>

        <keep-alive>
          <component
            :is="SearchClientComponents[searchContactCurrentStep]"
            v-bind="{ ticket, foundClientsWithSameNumber }"
            @client-select="handleClientSelect"
            @assign="handleAssign"
            @go-back="handleBack"
            @move-to-create-tab="activeKey = '2'"
          />
        </keep-alive>
      </section>
    </a-tab-pane>

    <a-tab-pane key="2" tab="Crear nuevo contacto">
      <a-steps v-if="!portfolioId" :current="createContactCurrentStep" size="small" class="steps">
        <a-step title="Completar datos" />
        <a-step title="Asignar campaña" />
      </a-steps>

      <a-divider type="horizontal" class="divider divider-header" />

      <section class="container">
        <keep-alive>
          <component
            :is="CreateClientComponents[createContactCurrentStep]"
            @client-submit="handleClientSubmit"
            @assign="handleAssign"
            @go-back="handleBack"
          />
        </keep-alive>
      </section>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { computed, createVNode, defineComponent, inject, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { message, Modal } from "ant-design-vue";
import { CloseCircleFilled, QuestionCircleOutlined } from "@ant-design/icons-vue";

import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import { fetchTicketDetails } from "@/app/workspace/services";

import SearchClientTable from "@/app/workspace/components/tables/Client/steps/SearchClientTable.vue";
import CreateClientForm from "@/app/workspace/components/tables/Client/steps/CreateClientForm.vue";
import CampaignTable from "@/app/workspace/components/tables/Client/steps/CampaignTable.vue";

import { RightOutlined } from "@ant-design/icons-vue";

const SearchClientComponents = {
  0: "SearchClientTable",
  1: "CampaignTable",
};

const CreateClientComponents = {
  0: "CreateClientForm",
  1: "CampaignTable",
};

export default defineComponent({
  components: {
    CampaignTable,
    CreateClientForm,
    SearchClientTable,
    RightOutlined,
  },
  props: {
    hasIvrVarSection: {
      type: Boolean,
      default: false,
    },
    fromEmail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const $jssip = inject("$jssip");

    const activeKey = ref("1");

    const isInACall = computed(() => {
      return [CallStatus.Connecting, CallStatus.Ringing, CallStatus.InCall].includes(
        $jssip.phone.status
      );
    });

    const ticket = inject("selectedTicket");
    const fetchTicket = inject("fetchTicket");

    // const ticket = computed(() => {
    //   const tickets = store.getters["getWorkspaceTickets"];
    //   const ticketId = route.params.ticket;
    //   return tickets.find((item) => item.id == ticketId);
    // });

    const portfolioId = computed(() => {
      return props.fromEmail ? true : ticket?.value?.portfolio?.id;
    });

    const showClientsWithSameNumber = ref(true);
    const searchContactCurrentStep = ref(0);
    const createContactCurrentStep = ref(0);

    // const createClientComponent = computed(() => {
    //   return CreateClientComponents[createContactCurrentStep.value];
    // });

    const clientData = ref(null);

    const handleGoToSearchComponent = () => {
      showClientsWithSameNumber.value = false;
      store.dispatch("cleanResultsClient");
    };

    const handleBack = () => {
      searchContactCurrentStep.value = 0;
      createContactCurrentStep.value = 0;
    };

    const handleClientSelect = ({ client, activate }) => {
      clientData.value = client;
      if (!portfolioId.value) searchContactCurrentStep.value++;
      else handleAssign(undefined, activate);
    };

    const handleClientSubmit = (client) => {
      clientData.value = client;
      if (!portfolioId.value) createContactCurrentStep.value++;
      else handleAssign();
    };

    let modalInstance = undefined;

    const handleGoToTicketCall = async () => {
      const ticketId = $jssip.phone?.meta?.ticket;
      const interactionId = $jssip.phone?.meta?.interaction;
      const clientId = $jssip.phone?.meta?.client;
      console.log(interactionId, "interactionId");
      await router.push({
        path: "/workspace",
        query: { ticket: ticketId, interaction: interactionId, client: clientId },
      });
    };

    const assignClientToTicket = async ({ actionName, requestBody, ticketId } = {}) => {
      if (props.fromEmail) {
        emit("clientReady", { actionName, requestBody });
        modalInstance?.destroy();
        return;
      }

      try {
        const { client, ticket: assignedTicket } = await store.dispatch(actionName, requestBody);

        console.log({ client, assignedTicket, ticketId });

        // const tickets = store.getters["getWorkspaceTickets"];
        const urlTicket = assignedTicket?.id || ticketId;

        const shouldUpdatePhoneMetaData = $jssip.phone.meta.ticket === ticketId && isInACall.value;
        const isSameTicket = ticketId === urlTicket;

        const tickets = store.getters["getWorkspaceTickets"];
        const ticketCall = tickets.find((el) => el.id == $jssip.phone.meta.ticket);

        if (shouldUpdatePhoneMetaData || ticketCall?.client?.id == client.id) {
          const newTicket = await fetchTicketDetails({ tickedId: urlTicket });

          console.log({ newTicket, isSameTicket });
          $jssip.setData({
            time_acw: newTicket?.time_acw,
            ticket: newTicket?.id,
            // channel_person: channel_person,
            client: newTicket.client?.id,
            name: newTicket?.client?.name,
            photo: newTicket?.client?.profile_picture,
            number: newTicket?.identifier,
            type_default_management: newTicket?.portfolio?.type_default_management,
          });

          if (ticketCall.client?.id == client.id) {
            $jssip.setData({
              interactionId: ticketCall.last_interaction?.id,
            });
            await handleGoToTicketCall();
          }
        }

        // await router.push(`/workspace/${urlTicket}/${route.query.interaction}/${client || ""}`);
        console.log(route.query);
        await router.push({
          path: "/workspace",
          query: { ticket: urlTicket, interaction: route.query.interaction, client: client.id },
        });

        if (isSameTicket) {
          console.log("UPDATING TICKET DATA");
          await fetchTicket({ preventLoading: true });
        }
      } catch (error) {
        console.error(error, "errooor");
        if (error.status === 400) {
          const agentToTransfer = error.data?.ticket?.user;

          if (agentToTransfer) {
            Modal.confirm({
              centered: true,
              title: "Actualmente existe un ticket activo con este contacto",
              content: `El usuario ${agentToTransfer?.fullname} se encuentra gestionando este contacto en la misma campaña. Asigna tu ticket a otro contacto o transfiérelo a ${agentToTransfer?.fullname}. `,
              icon: createVNode(CloseCircleFilled, { style: "color: red" }),
              cancelText: "Transferir",
              onCancel: async () => {
                await store.dispatch("transferTicketToAgent", {
                  ticketIdList: [Number(route.query.ticket)],
                  agentId: agentToTransfer.id,
                });
                store.dispatch("fetchActiveWorkspaceTickets");
                await router.push("/workspace");
              },
            });
          } else {
            message.error(error?.data?.document_number?.[0] || "No se pudo crear el contacto");
          }
        } else {
          const error_message =
            error?.message?.document_number?.[0] || error?.data?.document_number?.[0];
          message.error(error_message || "No se pudo crear el contacto");
        }
      }
    };

    const handleAssign = async (record, activate = false) => {
      let actionName = "";
      let actionText = "";

      const ticketId = Number(route.query.ticket);
      const interactionId = Number(route.query.interaction);
      const clientPortfolioId = portfolioId.value || record?.portfolioId;

      const bodyBase = {
        ticket: ticketId,
        last_interaction_id: interactionId,
        portfolio: clientPortfolioId,
      };

      if (record?.baseId) bodyBase.base = record?.baseId;

      let requestBody = {};

      if (clientData.value.id) {
        requestBody = {
          ...bodyBase,
          person: clientData.value.id,
        };

        actionName = "assignClient";
        if (activate) {
          actionText = "Activar";
        } else {
          actionText = "Asignar";
        }
      } else {
        requestBody = {
          ...bodyBase,
          ...clientData.value,
        };

        actionName = "createNewClient";
        actionText = "Crear";
      }

      const { first_name, last_name, paternal_surname, maternal_surname } = clientData.value;
      const clientName = `${first_name} ${last_name || `${paternal_surname} ${maternal_surname}`}`;
      const selectName = record?.base || record?.portfolio || ticket?.value?.portfolio?.name;
      const selectText = record?.base ? "base" : "campaña";

      modalInstance = Modal.confirm({
        title: activate
          ? `¿Estás seguro(a) de ${actionText?.toLowerCase()} el contacto ${
              clientData.value.first_name
            }?`
          : props.fromEmail
          ? `¿Estás seguro(a) de ${actionText?.toLowerCase()} el contacto?`
          : `¿Estás seguro(a) de ${actionText?.toLowerCase()} el contacto en la ${selectText} ”${selectName}”?`,
        content: activate
          ? `Actualmente este contacto se encuentra inactivo en esta campaña y al volver a activarlo podrás incluirlo en futuras gestiones.`
          : props.fromEmail
          ? `Recuerda que se ${actionText?.toLowerCase()}á al contacto ${clientName}`
          : `Recuerda que se ${actionText?.toLowerCase()}á al contacto ${clientName} a la ${selectText} para que puedas gestionarlo.`,
        icon: createVNode(QuestionCircleOutlined),
        centered: true,
        cancelText: "Cancelar",
        okText: activate ? `Activar` : `${actionText} contacto`,
        onOk: () => {
          assignClientToTicket({ actionName, requestBody, ticketId });
        },
        onCancel: () => {
          modalInstance?.destroy();
        },
      });
    };

    // IVR VARS
    const showIVRVars = ref(true);
    const onShowIvrVarSection = () => {
      showIVRVars.value = !showIVRVars.value;
    };

    const chevronIconClasses = computed(() => ({
      "ivr-var-section__icon": true,
      "ivr-var-section__icon--rotated": showIVRVars.value,
    }));

    const currentStatusLabel = computed(() => (showIVRVars.value ? "Ocultar" : "Mostrar"));

    return {
      activeKey,
      ticket,
      portfolioId,
      showClientsWithSameNumber,
      clientData,
      // createClientComponent,
      SearchClientComponents,
      CreateClientComponents,
      handleGoToSearchComponent,
      searchContactCurrentStep,
      createContactCurrentStep,
      handleClientSelect,
      handleClientSubmit,
      handleAssign,
      handleBack,

      showIVRVars,
      onShowIvrVarSection,
      chevronIconClasses,
      currentStatusLabel,
    };
  },
  computed: {
    ivrVarsAnswer() {
      return this.$store.state.ivrModule.ivr_answer;
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    foundClientsWithSameNumber() {
      return this.$store.getters["getClientsWithSameNumberFound"];
    },
  },
  watch: {
    ticket() {
      if (this.ticket) {
        this.$store.dispatch("searchClientsWithSameNumber", this.ticket?.identifier);
        this.showClientsWithSameNumber = true;
      }
    },
  },
  methods: {
    handleClickTableTwo() {
      this.activeKey = "2";
    },
  },
});
</script>

<style lang="sass" scoped>
.container
  margin-top: 0
  padding: 12px 16px
  overflow-y: auto
  overflow-x: hidden
  max-height: calc(100vh - 110px)

.steps
  justify-content: center
  padding: 15px 15%

:deep(.ant-steps-item)
  // flex: 0 0 35%
  padding: 0 5px

.divider
  background-color: $gray-4
  // width: calc(100% + 32px)
  width: 100%
  position: relative
  // left: -16px

  &-header
    margin: 0

.tabs-header
  z-index: 1
  text-align: center
  background-color: #F2F5F7
  margin: 0

:deep(.ant-tabs-nav.tabs-header)
  margin: 0

:deep(.ant-tabs-nav-list)
  width: 100%

:deep(.ant-tabs-nav)
  width: 100%
  height: 56px
  display: grid

:deep(.ant-tabs-tab)
  width: 50%
  font-size: 16px
  justify-content: center


// IVR VAR SECTIONS
.ivr-var-section
  margin-bottom: 12px

  &__button
    padding-left: 0
    font-size: $font-medium
    font-weight: 600
    color: $gray-9
    &:hover
      color: $gray-8

  &__icon
    transition: all 0.3s ease

    &--rotated
      transform: rotate(90deg)

  &__em
    color: $gray-7
    font-size: $font-small
.ivr-vars-row
  display: grid
  grid-template-columns: repeat(2, 250px)
  color: $gray-8

  p
    margin-bottom: 8px

  &__header
    font-weight: 600

  &__cell
    text-transform: capitalize

// .ivr-vars-fade
  // TRANSITION
.ivr-vars-fade-enter-active,
.ivr-vars-fade-leave-active
  transition: opacity 0.2s ease

.ivr-vars-fade-enter-from,
.ivr-vars-fade-leave-to
  opacity: 0
</style>
