<template>
  <template v-if="foundClientsWithSameNumber.length !== 0">
    <client-with-same-number
      :ticket="ticket ?? emailTicket"
      :clients="foundClientsWithSameNumber"
      showInactives
      @client-select="handleClientSelect"
      @client-activate="handleConfirmActivate"
    />

    <a-divider type="horizontal" class="divider" />
  </template>

  <client-search-unknown-Table
    :ticket="ticket ?? emailTicket"
    @client-select="handleClientSelect"
    @client-activate="handleConfirmActivate"
    @onClick="emit('move-to-create-tab')"
  />
</template>

<script setup>
import ClientSearchUnknownTable from "@/app/workspace/components/tables/Client/ClientSearchUnknownTable.vue";
import ClientWithSameNumber from "@/app/workspace/components/tables/Client/ClientWithSameNumber.vue";
import { Modal } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { defineComponent, createVNode, computed, inject } from "vue";

defineProps({
  ticket: Object,
  emailTicket: Object,
  foundClientsWithSameNumber: Array,
});

const emit = defineEmits(["client-select", "move-to-create-tab"]);

const handleClientSelect = ({ record, activate }) =>
  emit("client-select", { client: record, activate });

const ticket = inject("selectedTicket");

const store = useStore();
const route = useRoute();

const portfolioId = computed(() => {
  return ticket?.value?.portfolio?.id;
});

const handleConfirmActivate = async (record) => {
  Modal.confirm({
    centered: true,
    title: "¿Estás seguro(a) de volver a activar al contacto “" + record.first_name + "”?",
    content: `Actualmente este contacto se encuentra inactivo en esta campaña y al volver a activarlo podrás incluirlo en futuras gestiones.`,
    icon: createVNode(QuestionCircleOutlined),
    cancelText: "Cancelar",
    okText: "Activar",
    onOk: async () => {
      const ticketId = Number(route.query.ticket);
      const interactionId = Number(route.query.interaction);
      const clientPortfolioId = portfolioId.value || record?.portfolioId;

      const bodyBase = {
        ticket: ticketId,
        last_interaction_id: interactionId,
        portfolio: clientPortfolioId,
      };
      console.log(record, "record");
      console.log(ticket, "ticket");
      const requestBody = {
        ...bodyBase,
        person: record.id,
        activate: true,
      };
      const response = await store.dispatch("assignClient", requestBody);
      console.log(response, "response----");
    },
  });
};
</script>

<style lang="sass" scoped>
.divider
  background-color: $gray-4
  width: calc(100% + 32px)
  position: relative
  left: -16px
</style>
