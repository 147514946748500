<template>
  <div class="container">
    <CustomScrollbar height="480px">
      <div class="title">
        <h2>Datos de tu proveedor</h2>
        <span>Completa los campos requeridos</span>
      </div>
      <a-form
        autocomplete="chrome-off"
        class="form"
        layout="vertical"
        ref="formRefTwo"
        :rules="rules"
        :model="formState"
      >
        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item name="port">
              <template #label>
                Port

                <a-tooltip title="Puerto de señalización brindado por su proveedor. ">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input v-model:value="formState.port" placeholder="5060" @input="handleInput">
              </a-input>
            </a-form-item>
          </a-col>

          <a-col span="12">
            <a-form-item name="dtmf_mode">
              <template #label>
                DTMF Mode

                <a-tooltip title="Modo en que se reconocerán las opciones marcadas.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placeholder="DTMF Mode"
                @change="handleInput"
                v-model:value="formState.dtmf_mode"
              >
                <a-select-option :key="1">rfc2833</a-select-option>
                <a-select-option :key="2">Inband</a-select-option>
                <a-select-option :key="3">info</a-select-option>
                <a-select-option :key="4">auto</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col span="12">
            <a-form-item name="context">
              <template #label>
                Context

                <a-tooltip title="Indica dónde se recibirán las llamadas entrantes.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-input
                v-model:value="formState.context"
                placeholder="Escribir context"
                @input="handleInput"
                required
              >
              </a-input>
            </a-form-item>
          </a-col> -->
        </a-row>

        <a-row :gutter="24">
          <!-- <a-col span="12">
            <a-form-item name="type_trunk">
              <template #label>
                Type

                <a-tooltip title="Tipo de troncal">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </template>

              <a-select
:getPopupContainer="triggerNode => triggerNode.parentNode"                placeholder="Seleccionar type"
                @change="handleInput"
                v-model:value="formState.type_trunk"
              >
                <a-select-option :key="1">User</a-select-option>
                <a-select-option :key="2">Peer</a-select-option>
                <a-select-option :key="3">Friend</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>

        <a-form-item class="form-items" name="codecs">
          <template #label>
            Codecs

            <a-tooltip
              title="Codificadores y decodificadores de audio brindados por su proveedor. "
            >
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-checkbox-group
            v-model:value="formState.codecs"
            @change="handleInput"
            style="width: 100%"
          >
            <a-row>
              <a-col v-for="codec in codecs" :key="codec.id" :span="4" class="checkbox-codecs">
                <a-checkbox :value="codec.id">{{ codec.name }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>

        <a-form-item name="qualify">
          <template #label>
            Qualify

            <a-tooltip title="Valida el estado de la troncal">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-radio-group v-model:value="formState.qualify" @change="handleInput">
            <a-radio :value="true">Si</a-radio>
            <a-radio :value="false">No</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item name="type_authentication">
          <template #label>
            Tipo de autenticación de la troncal

            <a-tooltip title="Tipo de troncal">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </template>

          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="Seleccionar type"
            @change="handleInputType"
            v-model:value="formState.type_authentication"
          >
            <a-select-option :key="1">Autenticado por IP</a-select-option>
            <a-select-option :key="2">Autenticado por usuario y contraseña</a-select-option>
          </a-select>
        </a-form-item>

        <a-row :gutter="24">
          <a-col span="12">
            <a-form-item label="Usuario de troncal" name="username">
              <a-input
                v-model:value="formState.username"
                placeholder="Escribir nombre de usuario"
                :disabled="formState.type_authentication != 2"
                @input="handleInput"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col span="12"
            ><a-form-item label="Contraseña de troncal" name="password">
              <a-input-password
                autocomplete="new-password"
                v-model:value="formState.password"
                placeholder="Escribir contraseña de usuario"
                :disabled="formState.type_authentication != 2"
                @input="handleInput"
              ></a-input-password> </a-form-item
          ></a-col>
        </a-row>

        <a-form-item
          name="termination_sip"
          :validateStatus="validationSipDefault"
          :help="helpSipDefault"
        >
          <template #label>
            <div class="label_link">
              <div>
                Terminación SIP URI

                <a-tooltip
                  title="Parámetro utilizado para la autenticación del registro de la troncal."
                >
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </div>
              <a-typography-link
                :disabled="formState.type_authentication != 2"
                @click="handleTerminationDefault"
              >
                Usar terminación por defecto

                <a-tooltip
                  title="Será determinado por el nombre de la troncal y el usuario colocado. Es importante que antes se completen estos datos"
                >
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </a-typography-link>
            </div>
          </template>

          <a-input
            v-model:value="formState.termination_sip"
            placeholder="Escribir terminación SIP URI"
            :disabled="formState.type_authentication != 2"
            @input="handleInput"
          >
          </a-input>
        </a-form-item>

        <a-form-item
          name="contact_user"
          :validateStatus="validationContactDefault"
          :help="helpContactDefault"
        >
          <template #label>
            <div class="label_link">
              <div>
                Contact user

                <a-tooltip title="Identificador de contacto para solicitudes SIP.">
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </div>
              <a-typography-link
                :disabled="formState.type_authentication != 2"
                @click="handleContactDefault"
              >
                Usar contact user por defecto

                <a-tooltip
                  title="Por defecto se usará el mismo nombre colocado en “Usuario de troncal”."
                >
                  <InfoCircleOutlined style="margin-left: 5px" />
                </a-tooltip>
              </a-typography-link>
            </div>
          </template>

          <a-input
            v-model:value="formState.contact_user"
            placeholder="Escribir el contact user"
            :disabled="formState.type_authentication != 2"
            @input="handleInput"
          >
          </a-input>
        </a-form-item>

        <!-- <hr /> -->
        <!-- <div>
          <p>
            Precios destino (opcional)
            <a-tooltip
              title="En caso tu proveedor establezca diferentes precios según la zona de la llamada, puedes anotarlos de forma opcional."
            >
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </p>
        </div>

        <div v-for="(price, index) in prices" :key="index">
          <a-row :gutter="20">
            <a-col span="10">
              <a-form-item label="Nombre del destino" class="form-items">
                <a-input
                  v-model:value="price.name"
                  placeholder="Escribir destino"
                  @input="handleInput"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col span="2">
              <RightOutlined class="icon-menor" />
            </a-col>
            <a-col span="10">
              <a-form-item label="Monto" class="form-items">
                <a-input-number
                  :step="0.1"
                  v-model:value="price.value"
                  class="labelHalf"
                  placeholder="00.00"
                  :min="0"
                  @input="handleInput"
                ></a-input-number>
              </a-form-item>
            </a-col>
          </a-row>

          <div v-if="index > 0" span="2" style="text-align: right">
            <a-button type="text" danger size="small" @click="popPrice(index)">
              Eliminar sección
            </a-button>
          </div>

          <a-divider style="margin: 16px 0 20px" />
        </div>

        <div class="add-price">
          <span @click="handleAddPrice">
            <PlusOutlined />
            Agregar precio destino
          </span>
        </div> -->
      </a-form>
    </CustomScrollbar>

    <div class="footer">
      <a-button class="footer__back" type="primary" ghost="ghost" @click="handleGoBack">
        Atras
      </a-button>
      <a-button class="footer__next" type="primary" @click="handleOk">
        {{ next }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, createVNode } from "vue";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

export default defineComponent({
  props: {
    update: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomScrollbar,
    InfoCircleOutlined,
  },
  inject: ["formState"],
  setup() {
    const formRefTwo = ref();
    const form = ref();
    // const store = useStore();
    // const trunk = store.getters["getTrunk"];
    // const formState = reactive({
    //   port: props.update ? trunk.port : "",
    //   context: props.update ? trunk.context : "",
    //   type_trunk: props.update ? trunk.type_trunk : "",
    //   dtmf_mode: props.update ? trunk.dtmf_mode : "",
    //   codecs: props.update ? trunk.codecs.map((codec) => codec.id) : [],
    //   qualify: props.update ? trunk.qualify : "",
    //   type_authentication: props.update ? trunk.type_authentication : 1,
    //   username: props.update ? trunk.username : null,
    //   password: props.update ? trunk.password : null,
    //   termination_sip: props.update ? trunk.termination_sip : null,
    // });
    const validationSipDefault = ref("");
    const validationContactDefault = ref("");
    const helpSipDefault = ref("");
    const helpContactDefault = ref("");
    // const rules = {
    //   port: [{ required: true, message: "Campo requerido" }],
    //   context: [{ required: true, message: "Campo requerido" }],
    //   type_trunk: [{ required: true, message: "Campo requerido" }],
    //   dtmf_mode: [{ required: true, message: "Campo requerido" }],
    //   codecs: [{ required: true, message: "Completar los campos " }],
    //   qualify: [{ required: true, message: "Completar los campos " }],
    //   type_authentication: [{ required: true, message: "Campo requerido" }],
    //   username:
    //     formState.type_authentication == 2 ? [{ required: true, message: "Campo requerido" }] : [],
    //   password:
    //     formState.type_authentication == 2 ? [{ required: true, message: "Campo requerido" }] : [],
    // };
    return {
      // rules,
      form,
      // formState,
      formRefTwo,
      validationSipDefault,
      validationContactDefault,
      helpSipDefault,
      helpContactDefault,
    };
  },
  data() {
    return {
      menor: ">",
    };
  },
  computed: {
    next() {
      return this.update ? "Solicitar actualización" : "Finalizar";
    },
    currentTrunk() {
      return this.$store.getters["getTrunk"];
    },
    codecs() {
      return this.$store.getters["getCodecList"];
    },
    rules() {
      return {
        port: [{ required: true, message: "Campo requerido" }],
        // context: [{ required: true, message: "Campo requerido" }],
        // type_trunk: [{ required: true, message: "Campo requerido" }],
        dtmf_mode: [{ required: true, message: "Campo requerido" }],
        codecs: [{ required: true, message: "Completar los campos " }],
        qualify: [{ required: true, message: "Completar los campos " }],
        type_authentication: [{ required: true, message: "Campo requerido" }],
        username:
          this.formState.type_authentication == 2
            ? [{ required: true, message: "Campo requerido" }]
            : [],
        password:
          this.formState.type_authentication == 2
            ? [{ required: true, message: "Campo requerido" }]
            : [],
        termination_sip: [{ validator: this.validateTerminationSip, trigger: "blur" }],
        contact_user: [{ validator: this.validateContactUser, trigger: "blur" }],
      };
    },
  },
  watch: {
    currentTrunk(trunk) {
      if (trunk) {
        this.formState.contact_person = trunk.contact_person;
        this.formState.contact_number = trunk.contact_number;
      }
    },
  },
  methods: {
    // This is used by refs in the parent component
    resetForm() {
      this.formRefTwo.resetFields();
    },
    handleInput() {
      this.$emit("edit");
    },
    handleInputType() {
      if (this.formState.type_authentication != 2) {
        this.formState.username = null;
        this.formState.password = null;
        this.formState.termination_sip = null;
        this.formState.contact_user = null;
      }
      this.$emit("edit");
    },
    handleGoBack() {
      const { contact_person, contact_number } = toRaw(this.formState);
      this.$store.dispatch("assignedTrunk", {
        ...this.currentTrunk,
        contact_person,
        contact_number,
      });
      this.$emit("handleCancel");
    },
    handleTerminationDefault() {
      if (!this.formState.username || this.formState.username == "") {
        this.validationSipDefault = "error";
        this.helpSipDefault =
          "Completa el “Usuario de troncal” para usar la terminación por defecto";
        this.formState.termination_sip = "";
      } else {
        this.validationSipDefault = "";
        this.helpSipDefault = "";
        this.formState.termination_sip = `sip:${this.formState.username}_${this.formState.name}@${this.formState.host_server}:${this.formState.port}`;
      }
    },
    validateTerminationSip() {
      if (this.validationSipDefault == "error") {
        return Promise.reject(new Error("Campo requerido"));
      } else {
        return Promise.resolve();
      }
    },
    handleContactDefault() {
      if (!this.formState.username || this.formState.username == "") {
        this.validationContactDefault = "error";
        this.helpContactDefault =
          "Completa el “Usuario de troncal” para usar la terminación por defecto";
        this.formState.contact_user = "";
      } else {
        this.validationContactDefault = "";
        this.helpContactDefault = "";
        this.formState.contact_user = `${this.formState.username}`;
      }
    },
    validateContactUser() {
      if (this.validationContactDefault == "error") {
        return Promise.reject(new Error("Campo requerido"));
      } else {
        return Promise.resolve();
      }
    },
    showConfirm() {
      const self = this;
      Modal.confirm({
        title: "¿Estás seguro de solicitar la actualización de esta troncal?",
        content:
          "Recuerda que la troncal pasará a un estado de pendiente, hasta confirmar la actualización por parte del sistema.",
        icon: createVNode(QuestionCircleOutlined),
        okText: "Solicitar actualización",
        calcelText: "Cancelar",
        onOk() {
          self.$emit("handleOk", self.formState);
        },
        centered: true,
      });
    },
    handleOk() {
      this.formRefTwo.validate().then(() => {
        const { contact_person, contact_number } = toRaw(this.formState);
        this.$store.dispatch("assignedTrunk", {
          ...this.currentTrunk,
          contact_person,
          contact_number,
        });
        if (this.update) this.showConfirm();
        else this.$emit("handleOk", this.formState);
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.container
  height: 450px
  padding-top: 20px
.title
  text-align: center
  h2
    margin-bottom: 0.3em
  span
    font-size: 14px
.form
  display: block
  // max-width: 340px
  max-width: 95%
  margin: 0 auto
  margin-top: 20px
  // padding-right: 16px
  // padding-top: 16px
.form-items
  margin-bottom: 0px
.host
  margin-bottom: 20px
  .hostModel
    font-weight: 600
    font-size: $font-normal
    line-height: 22px
    color: $gray-9
.checkbox-codecs
  margin-bottom: 24px
.icon-menor
  margin-top: 8px
.add-price
  text-align: center
  color: $gray-9
  cursor: pointer
  span
    font-size: 14px
    // line-height: 20px
    color: $blue-6
.footer
  position: absolute
  background-color: $white
  left: 0
  bottom: 0
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  border-top: 1px solid $gray-5
  padding: 12px 0px
  border-radius: 0px 0px 4px 4px
  &__next
    margin-right: 1rem
  &__back
    margin-right: 1rem
.textarea
  height: 60px
.countername
  bottom: 8px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6

:deep(.ant-checkbox-wrapper)
  display: flex
  align-items: center

// :deep(.ant-form-item-label)
//   font-weight: 500 !important

:deep(.ant-form-item-label)
  width: 100%

:deep(.ant-form-item-label label)
  width: 100%

.labelHalf
  margin-bottom: 10px

.info
  display: flex
  color: $text-secondary
  align-items: flex-start
  gap: 5px
  font-style: italic
  margin-top: 0px
  font-size: 13px
.optional
  color: $gray-7

.label_link
  display: flex
  justify-content: space-between
  width: 100%
</style>
