<template>
  <section class="tabs" :class="{ 'with-banner': showBanner }">
    <div class="tabs__navigation">
      <a
        v-for="tab in filteredTabs"
        :key="tab.key"
        class="tab"
        :class="getClasses(tab)"
        @click="handleTabClick(tab)"
      >
        {{ tab.text }}
      </a>
    </div>

    <div class="tabs__body">
      <div class="tabs__content">
        <slot />
      </div>
    </div>
    <div v-if="$slots.footer" class="tabs__footer">
      <slot name="footer" />
    </div>
  </section>
</template>

<script>
import items from "@/app/common/components/organisms/TheSidebar/items";
import { inject } from "vue";

export default {
  props: {
    tabs: {
      required: true,
      type: Array,
    },
  },
  setup() {
    const showBanner = inject("showBanner");

    return { showBanner };
  },
  data() {
    return {
      items: items,
      permisos: [],
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters["getDataUser"];
    },
    loggedUserPermissions() {
      return this.loggedUser?.options_menu;
    },
    //Array de objetos Subpermisos
    subPermissios() {
      let array = [];
      this.loggedUserPermissions?.forEach((items) => (array = [...array, ...items.items]));
      return array;
    },

    //Array de objetos de Subitems
    subItems() {
      let array = [];
      const item = items.filter((item) => item.subitems);
      item.forEach((items) => (array = [...array, ...items.subitems]));
      return array;
    },

    allowedSubItems() {
      const permissios = this.subPermissios.map((item) => item.scope);
      return this.subItems.filter((items) => permissios.includes(items.scope));
    },

    filteredTabs() {
      return this.tabs.filter((tab) => {
        return this.allowedSubItems.some((subitem) => {
          return subitem.route == tab.key;
        });
      });
    },
  },
  methods: {
    getClasses(tab) {
      return { "tab--active": this.$route.path.includes(tab.key) };
    },
    handleTabClick(tab) {
      if (this.$route.path == tab.key) return;
      this.$router.push(tab.key);
    },
  },
};
</script>

<style lang="sass" scoped>
.tabs
  display: flex
  flex-direction: column
  margin: 11px
  min-height: calc(100vh - #{$header-height} - 24px)
  max-height: calc(100vh - #{$header-height} - 24px)
  height: calc(100vh - #{$header-height} - 24px)

  &__navigation
    height: 40px
    display: flex
    user-select: none

  &__body
    flex: auto
    border: $border-medium
    border-radius: 0 10px 10px 10px
    overflow: hidden
    background-color: $white
    display: flex
    flex-direction: column
    overflow: hidden

  &__content
    flex: auto

  &__footer
    height: 56px
    border-top: $border-medium
    display: flex
    justify-content: flex-end
    align-items: center

.with-banner
  min-height: calc(100vh - #{$header-height} - 57px)
  height: calc(100vh - #{$header-height} - 57px)

.tab
  height: 40px
  color: $gray-8
  background-color: $gray-2
  margin-right: $margin-tiny
  padding: 0 $padding-medium
  border: $border-medium
  border-bottom: none
  border-radius: 2px 2px 0 0
  display: flex
  justify-content: center
  align-items: center

  &--active
    padding: 0 $padding-medium - 2
    background-color: $white
    font-weight: 600
    color: $blue-6
</style>
