import { ChannelType } from "@/app/shared/utils/enums";

export enum BlackListTab {
  Voice = 1,
  Whatsapp = 2,
  Sms = 3,
}

export const BlackListTabName = {
  [BlackListTab.Voice]: "Voz",
  [BlackListTab.Whatsapp]: "Whatsapp",
  [BlackListTab.Sms]: "SMS",
};

export const BlackListTabText = {
  [BlackListTab.Voice]: "Voz (Llamadas)",
  [BlackListTab.Whatsapp]: "Whatsapp (Mensajería)",
  [BlackListTab.Sms]: "SMS (Mensajería)",
};

export const BlackListTabIcon = {
  [BlackListTab.Voice]: "voice.svg",
  [BlackListTab.Whatsapp]: "whatsapp.svg",
  [BlackListTab.Sms]: "sms.svg",
};

export const BlackListTabChannelId = {
  [BlackListTab.Voice]: ChannelType.Voice,
  [BlackListTab.Whatsapp]: ChannelType.Whatsapp,
  [BlackListTab.Sms]: ChannelType.Sms,
};

export const BlackListTabChannelName = {
  [BlackListTab.Voice]: "voice",
  [BlackListTab.Whatsapp]: "whatsapp",
  [BlackListTab.Sms]: "sms",
};

export enum BlockedType {
  Incoming = 1,
  Outgoing = 2,
  Both = 3,
}

export const BlockedTypeName = {
  [BlockedType.Incoming]: "Entrante",
  [BlockedType.Outgoing]: "Saliente",
  [BlockedType.Both]: "Saliente y entrante",
};

export const BlockedTypeVoiceName = {
  [BlockedType.Incoming]: "Llamadas entrantes",
  [BlockedType.Outgoing]: "Llamadas saliente",
  [BlockedType.Both]: "Llamadas saliente y entrante",
};

export const BlockedTypeWhatsappName = {
  [BlockedType.Incoming]: "Mensajes entrantes",
  [BlockedType.Outgoing]: "Mensajes saliente",
  [BlockedType.Both]: "Mensajes saliente y entrante",
};

export enum BlockedOrigin {
  Manual = 1,
  Api = 2,
}

export const BlockedOriginName = {
  [BlockedOrigin.Manual]: "Manual",
  [BlockedOrigin.Api]: "Api",
};
