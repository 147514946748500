export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACT = "SET_CONTACT";
export const SET_CURRENT_TABLE_PAGE = "SET_CURRENT_TABLE_PAGE";
export const SET_ADVANCED_FILTERS = "SET_ADVANCED_FILTERS";
export const SET_BASIC_FILTERS = "SET_BASIC_FILTERS";
export const SET_ADVANCED_FILTER = "SET_ADVANCED_FILTER";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const UPDATE_PHONES_CONTACT = "UPDATE_PHONES_CONTACT";
export const SET_PERSONAL_INFORMATION = "SET_PERSONAL_INFORMATION";
export const SET_CUSTOMER_PERSONAL_DATA = "SET_CUSTOMER_PERSONAL_DATA";
export const ADD_PHONES_CONCTACT = "ADD_PHONES_CONCTACT";
export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";
export const SET_CUSTOM_DATA = "SET_CUSTOM_DATA";
export const ADD_EMAIL_CONTACT = "ADD_EMAIL_CONTACT";
export const SET_CONTACT_EMAIL = "SET_CONTACT_EMAIL";
export const UPDATE_EMAIL_CONTACT = "UPDATE_EMAIL_CONTACT";
export const SET_CONTACT_PHONE = "SET_CONTACT_PHONE";
export const SET_CONTACT_ADDRESS = "SET_CONTACT_ADDRESS";
export const ADD_ADDRESS_CONTACT = "ADD_ADDRESS_CONTACT";
export const UPDATE_ADDRESS_CONTACT = "UPDATE_ADDRESS_CONTACT";
export const REMOVE_PHONE_CONTACT = "REMOVE_PHONE_CONTACT";
export const REMOVE_EMAIL_CONTACT = "REMOVE_EMAIL_CONTACT";
export const REMOVE_ADDRESS_CONTACT = "REMOVE_ADDRESS_CONTACT";
export const SET_PRODUCTS_FIELDS_CONTACT = "SET_PRODUCTS_FIELDS_CONTACT";
export const SET_PRODUCTS_DATA = "SET_PRODUCTS_DATA";
export const SET_PAYMENTS_DATA = "SET_PAYMENTS_DATA";
export const SET_PROMISES_DATA = "SET_PROMISES_DATA";
export const SET_TYPIFICATIONS_DATA = "SET_TYPIFICATIONS_DATA";
export const SET_RECORDS_DATA = "SET_RECORDS_DATA";
export const SET_REFERRALS_DATA = "SET_REFERRALS_DATA";
export const ADD_REFERRALS_CONTACT = "ADD_REFERRALS_CONTACT";
export const UPDATE_REFERRALS_CONTACT = "UPDATE_REFERRALS_CONTACT";
export const REMOVE_REFERRALS_CONTACT = "REMOVE_REFERRALS_CONTACT";
export const UPDATE_INFORMATION_CONTACT = "UPDATE_INFORMATION_CONTACT";
export const UPDATE_INFORMATION_PERSONAL = "UPDATE_INFORMATION_PERSONAL";
export const SET_FILTERS_TYPIFICATIONS = "SET_FILTERS_TYPIFICATIONS";
export const SET_RECORD_TICKET_CLIENT = "SET_RECORD_TICKET_CLIENT";
export const SET_RECORD_MESSAGE_TICKET = "SET_RECORD_MESSAGE_TICKET";
export const RESET_STATE_TICKET = "RESET_STATE_TICKET";
export const DETAIL_RECORD_TICKET = "DETAIL_RECORD_TICKET";
export const SET_EMAILS_TAB = "SET_EMAILS_TAB";
