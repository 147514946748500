import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un telefono.
 *
 * @param {number} phoneId Identificador del telefono.
 */
export async function deletePhoneContact(phoneId, client) {
  await httpManager.delete(`/contacts/clients/phones/${phoneId}/`, { client });
}
