import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener canales de cliente dado un ticket.
 * @param {number} ticketId Identificador de ticket.
 * @return {Promise<Array>} Listado de canales
 */
export async function fetchChannelsByTicket(ticketId) {
  const url = `/tickets/${ticketId}/channels/`;
  const response = await httpManager.get(url);
  return response.data?.channels;
}
