<template>
  <section>
    <EditOutlined class="icon" @click="handleShowModal" />
    <a-drawer
      v-model:visible="visible"
      title="Editar teléfono"
      destroy-on-close
      :mask-closable="false"
      placement="right"
      width="480"
      keyboard
      :body-style="{ padding: 0 }"
      @close="handleCloseDrawer"
    >
      <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
        <a-form
          class="form"
          layout="vertical"
          ref="formRef"
          :model="formState"
          :rules="rules"
          @submit.prevent="handleSubmit"
        >
          <a-form-item label="Número telefónico" name="phone">
            <a-row :gutter="24">
              <a-col span="7">
                <selector-country
                  v-model:value="formState.country"
                  @selected="code_phone = $event"
                />
              </a-col>
              <a-col span="17">
                <a-input
                  v-model:value="formState.phone"
                  placeholder="Ingresar número de teléfono"
                  @keypress="handleNumberPhone($event)"
                  @paste="handlePaste"
                />
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="Tipo de teléfono" name="type_phone">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState.type_phone"
              placeholder="Seleccionar aquí"
            >
              <a-select-option value="1"> Fijo </a-select-option>
              <a-select-option value="2"> Celular </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="type_origin">
            <template #label>
              <span class="label">
                Origen de subida a la plataforma
                <a-tooltip>
                  <template v-slot:title>
                    Medio por el que ingresa el teléfono a la plataforma: Importación o Manual
                    (desde el módulo de workspace o de contactos).
                  </template>
                  <InfoCircleOutlined />
                </a-tooltip>
              </span>
            </template>
            <a-input v-model:value="formState.type_origin" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Operador telefónico" name="phone_operator">
            <a-input v-model:value="formState.phone_operator" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item name="priority">
            <template #label>
              <span class="label">
                Prioridad para la llamada
                <a-tooltip>
                  <template v-slot:title>
                    Orden en que se debería llamar a los teléfonos.
                  </template>
                  <InfoCircleOutlined />
                </a-tooltip>
              </span>
            </template>
            <a-input
              v-model:value="formState.priority"
              placeholder="Escribe aquí"
              v-mask="'##########'"
            />
          </a-form-item>

          <a-form-item label="Observación" name="observación">
            <a-textarea
              v-model:value="formState.observation"
              placeholder="Escribe aquí"
              :maxlength="commentLimit"
              class="textarea"
            />
            <span class="counter"> {{ formState.observation?.length }}/{{ commentLimit }} </span>
          </a-form-item>
        </a-form>
      </custom-scrollbar>

      <div class="footer">
        <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
        <a-button type="primary" @click="messageCallOpen"> Guardar cambios </a-button>
      </div>
    </a-drawer>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw, h } from "vue";
import { QuestionCircleOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { confirmIsCallUser } from "@/app/contacts/services";
import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import { mapIcon } from "@/app/notifications/utils/icons";
import { usePhoneNumberValidation } from "@/app/shared/composables/usePhoneNumberValidation";

export default defineComponent({
  components: {
    CustomScrollbar,
    EditOutlined,
    SelectorCountry,
    InfoCircleOutlined,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      phone: "",
      type_phone: 2,
      type_origin: undefined,
      phone_operator: "",
      priority: undefined,
      observation: "",
      country: 1,
    });

    const rules = {
      phone: [{ required: true, message: "Campo requerido" }],
      type_phone: [{ required: true, message: "Campo requerido" }],
    };

    const { handlePaste } = usePhoneNumberValidation();

    return {
      formState,
      formRef,
      rules,
      handlePaste,
    };
  },
  data() {
    return {
      visible: false,
      commentLimit: 200,
    };
  },
  computed: {
    usersInfo() {
      return this.$store.getters["getDataUser"];
    },
    modifiedPhone() {
      return this.formState.phone != this.record.phone;
    },
  },
  methods: {
    handleNumberPhone(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleShowModal() {
      this.visible = true;
      this.formState.phone = this.record.phone;
      this.formState.type_phone = `${this.record.type_phone}`;
      this.formState.type_origin = this.record.type_origin;
      this.formState.phone_operator = this.record.phone_operator;
      this.formState.priority = this.record.priority;
      this.formState.observation = this.record.observation;
      this.formState.country = this.record.country?.id || 1;
    },
    handleCloseDrawer() {
      this.visible = false;
    },

    ModalCallActive() {
      Modal.confirm({
        title: `El número "${this.record.phone}" se encuentra en una llamada activa. ¿Estás seguro de editarlo?`,
        content:
          "Recuerda que la llamada actual se cortará. Además, este número también se editará en las estrategias y recordatorios en los que se encuentre vinculado.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => {
          this.$jssip.end();
          this.handleSubmit();
          setTimeout(() => {
            this.handleModalNotification();
          }, 1000);
        },
        centered: true,
        okText: "Guardar cambios",
        cancelText: "Cancelar",
      });
    },
    async messageCallOpen() {
      if (this.modifiedPhone && this.record.phone) {
        try {
          const response = await confirmIsCallUser({
            client: this.contact.id,
            phone: this.record.phone,
          });
          if (response.detail === "Este cliente tiene una llamada en curso.") {
            this.ModalCallActive();
          } else if (response.status === "OK") {
            this.handleSubmit();
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          const values = toRaw(this.formState);
          const formData = new FormData();
          if (this.modifiedPhone) formData.append("phone", values.phone);
          if (this.formState.type_phone) formData.append("type_phone", values.type_phone);
          if (this.formState.type_origin) formData.append("type_origin", values.type_origin);
          if (this.formState.phone_operator)
            formData.append("phone_operator", values.phone_operator);
          if (this.formState.priority) formData.append("priority", values.priority);
          if (this.formState.observation) formData.append("observation", values.observation);
          if (this.formState.country) formData.append("country", values.country);
          await this.$store.dispatch("updatePhonesContact", {
            id: this.record.id,
            phones: formData,
          });
          this.$emit("update");
          this.handleCloseDrawer();
          this.$message.success("Se han guardado los cambios correctamente");
        } catch (error) {
          this.$message.warning(`El número "${this.formState.phone}" ya existe en este contacto.`);
        }
      });
    },
    handleModalNotification() {
      this.$notification.open({
        message: `Se ha editado un número telefónico del contacto ${this.contact?.fullName}`,
        description: `${this.usersInfo?.fullname} a modificado un número telefónico. Se actualizará la información de contacto en tu módulo de Workspace.`,
        icon: h(mapIcon("contactBlue"), { notification: true }),
        duration: 5,
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

.ant-form-item
  .textarea
    height: 85px

  .counter
    position: absolute
    right: 12px
    bottom: 2px
    color: $gray-6
    font-size: $font-small

:deep(.ant-calendar-picker)
  width: 100%
.icon
  color: $blue-6
</style>
