<template>
  <section>
    <add-addresses-contact-drawer
      :visible="isAddAddressesVisible"
      @onCloseDrawerEdit="handleCloseDrawer"
      :itemId="itemId"
    />
    <a-table
      row-key="id"
      size="middle"
      :columns="columnsTable"
      :data-source="paymentsClient"
      :scroll="{ x: 1400 }"
      :pagination="false"
    >
      <template #summary v-if="paymentsClient.length != 0">
        <a-table-summary :fixed="'bottom'">
          <a-table-summary-row>
            <a-table-summary-cell :index="1" :col-span="3" class="footer-title"
              >Total
            </a-table-summary-cell>
            <a-table-summary-cell :index="4" :col-span="1" class="footer-title">{{
              totalPEN
            }}</a-table-summary-cell>
            <a-table-summary-cell :index="5" :col-span="2" class="footer-title"
              >Soles</a-table-summary-cell
            >
          </a-table-summary-row>
          <a-table-summary-row>
            <a-table-summary-cell :index="1" :col-span="3" class="footer-title"
              >Total</a-table-summary-cell
            >
            <a-table-summary-cell :index="4" :col-span="1" class="footer-title">{{
              totalUSD
            }}</a-table-summary-cell>
            <a-table-summary-cell :index="5" :col-span="2" class="footer-title"
              >Dolares</a-table-summary-cell
            >
          </a-table-summary-row>
        </a-table-summary>
      </template>
    </a-table>
  </section>
</template>

<script>
import { columns } from "@/app/contacts/components/tables/payments/ContactPaymentsTableColumns";
import AddAddressesContactDrawer from "@/app/contacts/components/drawers/addresses/AddAddressesContactDrawer.vue";
import { columnsFixedTableLeft } from "@/app/shared/utils/columnTable";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ContactPaymentsCurrencyTabsKey } from "@/app/contacts/utils/enums";

export default {
  components: {
    AddAddressesContactDrawer,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      isAddAddressesVisible: false,
    };
  },
  computed: {
    paymentsClient() {
      return this.$store.getters["getPaymentsClient"]?.results;
    },
    columnsTable() {
      const column = columnsFixedTableLeft(this.columns);
      return column;
    },
    totalPEN() {
      const total = this.paymentsClient
        .filter((payment) => payment.currency_payment === ContactPaymentsCurrencyTabsKey.PEN)
        .reduce((acc, payment) => acc + parseFloat(payment.amount_payment) || 0, 0);
      return total.toFixed(2);
    },
    totalUSD() {
      const total = this.paymentsClient
        .filter((payment) => payment.currency_payment === ContactPaymentsCurrencyTabsKey.USD)
        .reduce((acc, payment) => acc + parseFloat(payment.amount_payment) || 0, 0);
      return total.toFixed(2);
    },
  },
  methods: {
    handleCloseDrawer() {
      this.isAddAddressesVisible = false;
    },
    handleOpenAddAddresses() {
      this.isAddAddressesVisible = true;
    },
    handleDelete(value) {
      Modal.confirm({
        title: `¿Estás seguro de Eliminar esta direccion?`,
        content: "Recuerda que las interacciones asociadas a esta direccion serán finalizadas.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.$store.dispatch("deleteAddressContact", value.id),
        centered: true,
        okText: "Eliminar",
        cancelText: "Cancelar",
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px

.actions
  display: flex

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  width: 100px !important

.footer-title
  font-weight: 600
</style>
