import {
  fectAllShedule,
  addReminder,
  deleteReminder,
  updateReminder,
  addEventSchedule,
} from "@/app/schedule/services";

import {
  SET_REMINDER,
  ADD_REMINDER,
  REMOVE_REMINDER,
  // UPDATE_REMINDER,
  SET_CHECK_NOTIFICATION_INTERVAL,
} from "./mutation-types";

const actions = {
  async fetchAllReminder({ commit }, filters) {
    const reminders = await fectAllShedule(filters);
    commit(SET_REMINDER, reminders);
  },
  async addReminder({ commit }, reminders) {
    const reminder = await addReminder(reminders);
    commit(ADD_REMINDER, reminder);
  },
  async addEventSchedule({ commit }, reminders) {
    const reminder = await addEventSchedule(reminders);
    commit(ADD_REMINDER, reminder);
  },
  async deleteReminder({ commit }, reminderId) {
    await deleteReminder(reminderId);
    commit(REMOVE_REMINDER, reminderId);
  },
  async updateReminder(_, { reminderId, reminder }) {
    await updateReminder(reminderId, reminder);
    // console.log(newReminder);
    // commit(UPDATE_REMINDER, newReminder);
  },
  setCheckNotificationInterval({ commit }, { intervalId }) {
    commit(SET_CHECK_NOTIFICATION_INTERVAL, intervalId);
  },
};

export default actions;
