import { updatePasswordWork } from "@/app/settingsMenu/services";

import { UPDATE_PASSWORD_WORK, SET_TIME_INACTIVE } from "./mutation-types";

const actions = {
  async updatePasswordWork({ commit }, data) {
    await updatePasswordWork(data);
    commit(UPDATE_PASSWORD_WORK, data);
  },
  async setMyTimeInactive({ commit }, timeInactive) {
    commit(SET_TIME_INACTIVE, timeInactive);
  },
};

export default actions;
