const getters = {
  getChannelList(state) {
    return state.channels;
  },
  getChannel(state) {
    return state.channel;
  },
};

export default getters;
