<template>
  <div class="controls">
    <div class="container__filter">
      <div class="form__pickers">
        <a-form-item>
          <template v-slot:label> Buscar teléfono </template>
        </a-form-item>
      </div>
      <div class="container__phone">
        <a-input v-model:value="search" placeholder="Buscar teléfono..." class="search">
          <template v-slot:prefix>
            <SearchOutlined />
          </template>
        </a-input>
        <entity-picker
          :value="entity"
          @update:value="entity = $event"
          v-if="user?.customer?.type_business == 1"
        />
        <campaign-picker :value="campaign" @update:value="campaign = $event" :entity="entity" />
        <agent-picker :value="agent" @update:value="agent = $event" :campaign="campaign" />
        <disposition-picker :value="disposition" @update:value="disposition = $event" />
        <type-dialing-picker :value="dialing" @update:value="dialing = $event" />
      </div>
    </div>
    <p class="form__text">Selecciona un rango de fechas y hora</p>
    <div class="container__date">
      <a-form-item label="Fecha de inicio">
        <a-date-picker
          v-model:value="startValue"
          class="form__select"
          :disabled-date="disabledStartDate"
          format="DD/MM/YYYY"
          placeholder="-- / -- / ----"
          @openChange="handleStartOpenChange"
        />
      </a-form-item>
      <a-form-item label="Fecha de fin">
        <a-date-picker
          v-model:value="endValue"
          class="form__select"
          :disabled-date="disabledEndDate"
          format="DD/MM/YYYY"
          placeholder="-- / -- / ----"
          :open="endOpen"
          @openChange="handleEndOpenChange"
        />
      </a-form-item>
      <a-form-item label="Hora de inicio">
        <a-time-picker
          v-model:value="hourstartValue"
          format="HH:mm"
          class="form__select"
          placeholder="-- : --"
          @change="handleHourStartOpenChange"
        />
      </a-form-item>
      <a-form-item label="Hora de fin">
        <a-time-picker
          v-model:value="hourendValue"
          format="HH:mm"
          class="form__select"
          placeholder="-- : --"
          @change="handleHourEndOpenChange"
        />
      </a-form-item>
      <div />
      <div class="container__buttons">
        <span class="total-records"> Actualmente {{ dataRecords }} audios </span>
        <!-- <a-button type="primary" @click="handleDownload()"> Descargar todos los audios </a-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { dateReminder, hourReminder } from "@/app/shared/utils/dates";

import EntityPicker from "@/app/shared/components/pickers/EntityPicker.vue";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import AgentPicker from "@/app/supervision/components/picker/records/AgentPicker.vue";
import DispositionPicker from "@/app/supervision/components/picker/records/DispositionPicker.vue";
import TypeDialingPicker from "@/app/supervision/components/picker/records/TypeDialingPicker.vue";
import { SearchOutlined } from "@ant-design/icons-vue";

// import { downloadFilteredRecords } from "@/app/supervision/services";

// import { downloadFile } from "@/app/shared/utils/file";

import { defineComponent, ref, computed, watch } from "vue";

import { useStore } from "vuex";
import { debounce } from "lodash";
export default defineComponent({
  components: {
    EntityPicker,
    CampaignPicker,
    AgentPicker,
    DispositionPicker,
    TypeDialingPicker,
    SearchOutlined,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["getDataUser"]);
    const entity = ref(0);
    if (user.value) {
      if (user.value.customer.type_business == 2) {
        entity.value = 1;
      }
    }
    watch(user, (newValue) => {
      if (newValue.customer.type_business == 2) {
        entity.value = 1;
      }
    });
    return {
      entity,
      user,
    };
  },
  data() {
    return {
      search: "",
      // entity: 0,
      campaign: 0,
      agent: 0,
      disposition: 0,
      dialing: 0,
      startValue: null,
      endValue: null,
      endOpen: false,
      hourstartValue: null,
      hourendValue: null,
    };
  },
  computed: {
    dataRecords() {
      return this.$store.getters["getRecordsInfo"].pagination?.total;
    },
  },
  watch: {
    search: debounce(function () {
      this.handleSearch();
    }, 1000),
    entity() {
      this.handleSearch();
    },
    campaign() {
      this.handleSearch();
    },
    agent() {
      this.handleSearch();
    },
    disposition() {
      this.handleSearch();
    },
    dialing() {
      this.handleSearch();
    },
    startValue() {
      this.handleSearch();
    },
    endValue() {
      this.handleSearch();
    },
    hourstartValue() {
      this.handleSearch();
    },
    hourendValue() {
      this.handleSearch();
    },
  },
  methods: {
    handleSearch() {
      this.$emit("update", {
        search: this.search,
        entity: this.entity,
        campaign: this.campaign,
        agent: this.agent,
        disposition: this.disposition,
        dialing: this.dialing,
        call_start_date: dateReminder(this.startValue),
        call_end_date: dateReminder(this.endValue),
        hour_start_date: hourReminder(this.hourstartValue),
        hour_end_date: hourReminder(this.hourendValue),
      });
    },
    disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    handleHourStartOpenChange(time) {
      this.hourstartValue = time;
    },
    handleHourEndOpenChange(time) {
      this.hourendValue = time;
    },
    // async handleDownload() {
    //   const filters = {
    //     search: this.search,
    //     entity: this.entity,
    //     portfolio: this.campaign,
    //     user: this.agent,
    //     disposition: this.disposition,
    //     type_dialing: this.dialing,
    //     call_start_date: dateReminder(this.startValue),
    //     call_end_date: dateReminder(this.endValue),
    //     hour_start_date: hourReminder(this.hourstartValue),
    //     hour_end_date: hourReminder(this.hourendValue),
    //   };
    //   const blob = await downloadFilteredRecords(filters);
    //   downloadFile(blob, "audios.zip");
    // },
  },
});
</script>

<style lang="sass" scoped>
.controls
  margin-bottom: 16px
  // display: grid
  // grid-template-rows: 1fr 15px 1fr
  // gap: 10px

.search
  height: 32px

.form__text
  font-weight: 600
  font-size: $font-normal
  color: $gray-1
  margin-top: 10px

.form__pickers
  display: flex
  align-items: flex-end

.form__select
  width: 100% !important
.btn-download
  display: flex
  gap: 8px
  align-items: center
  justify-content: flex-end
.total-records
  font-size: $font-small
  line-height: 20px
  color: $gray-8

:deep(.ant-form-item-label)
  line-height: 10px

:deep(.ant-form-item)
  margin-bottom: 0

:deep(.ant-time-picker)
  width: 100%

.left
  margin-left: 5px

.container__filter
  display: flex
  flex-direction: column

.container__phone
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
  gap: 10px

.container__buttons
  display: flex
  justify-content: flex-end
  align-items: center
  gap: 10px

  & > button
    margin-top: -7px

.container__date
  display: grid
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr
  gap: 10px
</style>
