import { Commit } from "vuex";
import {
  SET_SELECTED_MENU,
  SET_SELECTED_CAMPAIGN,
  SET_TOTAL_EMAILS,
  SET_THREAD_ACTUAL,
  REMOVE_THREAD_ACTUAL,
  SET_THREADS_INBOX,
  SET_TABLE_PAGE,
  SET_MENU_ITEMS,
  SET_OPEN_KEYS_MENU_ITEMS,
  SET_SELECTED_KEYS_MENU_ITEMS,
  SET_FETCHING_THREADS_INBOX,
  SET_SELECTED_THREADS_INBOX,
} from "./mutation-types";
import {
  DataType,
  ICampaignEmail,
  IMenuSubItem,
  IMenutem,
  State,
  TypeInbox,
} from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";

const actions = {
  async updateSelectedMenu({ commit }: { commit: Commit }, menu: string) {
    commit(SET_SELECTED_MENU, menu);
  },
  async updateSelectedCampaign({ commit }: { commit: Commit }, data: ICampaignEmail) {
    commit(SET_SELECTED_CAMPAIGN, data);
  },
  async updateMenuItems(
    { state, commit }: { state: State; commit: Commit },
    userId: number | undefined
  ) {
    const user = state.selectedMenu == TypeInbox.BandejaEntrada ? undefined : userId;
    const items = await emailService.getEmailsMenuList(user);
    commit(SET_MENU_ITEMS, items);
  },
  async updateOpenKeysMenuItems({ commit }: { commit: Commit }, data: string[]) {
    commit(SET_OPEN_KEYS_MENU_ITEMS, data);
  },
  async updateSelectedKeysMenuItems({ commit }: { commit: Commit }, data: number[]) {
    commit(SET_SELECTED_KEYS_MENU_ITEMS, data);
  },
  async updateTotalEmails({ commit }: { commit: Commit }, data: number) {
    commit(SET_TOTAL_EMAILS, data);
  },
  async updateTablePage({ commit }: { commit: Commit }, data: number) {
    commit(SET_TABLE_PAGE, data);
  },
  async updateThreadActual({ commit }: { commit: Commit }, data: number) {
    commit(SET_THREAD_ACTUAL, data);
  },
  async removeThreadActual({ commit }: { commit: Commit }) {
    commit(REMOVE_THREAD_ACTUAL);
  },
  async updateThreadsInbox(
    { state, commit }: { state: State; commit: Commit },
    data: {
      assignedUser: number | undefined;
      // channelCustomerId: number | undefined;
      // assigned: number | undefined;
      // resolved?: boolean;
      // read?: boolean;
      // tagList?: Array<number | "">;
      // search?: string | undefined;
      // page?: number;
      resetPagination: boolean;
    }
  ) {
    let assigned, resolved;
    if (state.selectedMenu == TypeInbox.MisAsignados || state.selectedMenu == TypeInbox.Resueltos)
      assigned = true;
    if (state.selectedMenu == TypeInbox.Resueltos) resolved = true;
    if ((state.selectedCampaign?.id && !resolved) || resolved) {
      commit(SET_FETCHING_THREADS_INBOX, true);
      commit(SET_THREADS_INBOX, []);
      commit(SET_SELECTED_THREADS_INBOX, {
        selectedRowKeys: [],
        countSelectedRow: 0,
      });
      if (data.resetPagination) commit(SET_TABLE_PAGE, 1);
      const response = await emailService.getInboxList(
        // -!data.assigned,
        !assigned,
        // data.channelCustomerId,
        state.selectedCampaign?.id,
        // data.assignedUser,
        assigned || resolved ? data.assignedUser : undefined,
        // data.resolved,
        resolved,
        // data.read,
        state.filters.read,
        // data.tagList,
        state.filters.tagList,
        assigned ? 2 : 1,
        // data.search,
        state.filters.search,
        // data.page,
        state.tablePage
      );
      if (response.results) {
        commit(SET_THREADS_INBOX, response.results);
        commit(SET_TOTAL_EMAILS, response.count);
      }
      commit(SET_FETCHING_THREADS_INBOX, false);
    }
  },
  async updateSelectedThreadsInbox({ commit }: { commit: Commit }, data: DataType[]) {
    commit(SET_SELECTED_THREADS_INBOX, data);
  },
};

export default actions;
