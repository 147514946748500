<template>
  <main-logo-register />

  <FormCard :image-src="workspaceData?.picture" title="Restablecer contraseña">
    <a-form layout="vertical">
      <a-form-item label="Nueva contraseña" required>
        <a-input-password
          v-model:value="password"
          placeholder="Escribir contraseña"
          @input="handleChangeNewPassword"
        />
        <template #help>
          <div class="help">
            <div v-for="help of helps" :key="help.text" class="help__item">
              <CheckCircleOutlined
                v-if="help.active"
                class="help__icon"
                :class="getClass(help.active)"
              />
              <CloseCircleOutlined v-else class="help__icon" :class="getClass(help.active)" />
              <span>{{ help.text }}</span>
            </div>
          </div>
        </template>
      </a-form-item>

      <a-form-item
        label="Confirmar contraseña"
        :validate-status="getPasswordValidationStatus"
        required
      >
        <a-input-password v-model:value="confirmation" placeholder="Confirmar contraseña" />
        <template #help>
          <span v-if="!thereIsMatch"> La contraseña no es la misma</span>
        </template>
      </a-form-item>
      <footer class="form__footer">
        <a-button
          class="button"
          type="primary"
          :loading="loading"
          :disabled="!formIsReady"
          @click="handleClick"
        >
          Continuar
        </a-button>
      </footer>
    </a-form>
  </FormCard>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

import { useValidateWorkspace } from "../../composables/useValidateWorkspace";

import MainLogoRegister from "@/app/shared/components/molecules/MainLogoRegister.vue";
import httpManager from "@/app/shared/utils/httpManager";
import FormCard from "../atoms/FormCard.vue";

export default defineComponent({
  name: "PasswordReset",
  components: {
    MainLogoRegister,
    CheckCircleOutlined,
    CloseCircleOutlined,
    FormCard,
  },
  setup() {
    const store = useStore();
    const email = computed(() => store.getters["getTemporalUserEmail"]);
    const password = ref("");
    const confirmation = ref("");
    const thereIsMatch = computed(() => {
      return password.value === confirmation.value;
    });

    const workspaceData = useValidateWorkspace();

    return {
      email,
      store,
      password,
      confirmation,
      thereIsMatch,
      workspaceData,
    };
  },
  data() {
    return {
      loading: false,
      initial: true,
      helps: [
        {
          active: false,
          text: "Al menos 8 caracteres de longitud",
          rule: (value) => value.length >= 8,
        },
        {
          active: false,
          text: "Usar mínimo un caracter en mayúscula",
          rule: (value) => /[A-Z]/.test(value),
        },
        {
          active: false,
          text: "Usar mínimo un caracter en minúscula",
          rule: (value) => /[a-z]/.test(value),
        },
        {
          active: false,
          text: "Un número, símbolo o espacio en blanco",
          rule: (value) => /[<\d\-\\[\]{|}()/¡!¿?'"@#$%&*+,.:;^~_=`> ]/.test(value),
        },
      ],
    };
  },
  computed: {
    isPasswordStrong() {
      const actives = this.helps.map((help) => help.active);
      return actives.reduce((previous, current) => previous && current, true);
    },
    formIsReady() {
      return this.isPasswordStrong && this.thereIsMatch && !!this.confirmation;
    },
    getPasswordValidationStatus() {
      return !this.thereIsMatch ? "error" : "";
    },
  },

  watch: {
    password(value) {
      this.helps.forEach((help) => {
        help.active = help.rule(value);
      });
    },
    // formIsReady(value) {
    //   if (value) this.$emit("input", this.password);
    //   else this.$emit("input", "");
    // },
  },
  created() {
    this.$store.dispatch("setLoginProgress", 3);
    this.validateToken();
  },

  methods: {
    async handleClick() {
      if (!this.password) return;
      this.validateToken();
      try {
        const data = {
          token: this.$route.query.token,
          password: this.confirmation,
        };
        this.loading = true;
        await this.$store.dispatch("confirmPassword", data);
        this.$router.push("/login/");
      } catch (error) {
        if (error?.data?.password?.[0]) {
          this.$message.error(error?.data?.password?.[0]);
        } else {
          this.$message.error("Ingrese los campos correctamente");
          // this.$notification.error({
          //   message: "Ingrese los campos correctamente",
          // });
        }
      } finally {
        this.loading = false;
      }
    },
    getType(active) {
      return active ? "check-circle" : "close-circle";
    },
    getClass(active) {
      const initial = this.initial ? "help__icon--initial" : "help__icon--error";
      return active ? "help__icon--success" : initial;
    },
    handleChangeNewPassword() {
      this.initial = false;
    },
    async validateToken() {
      try {
        const token = this.$route.query.token;
        httpManager.updateBaseURL(
          `https://${localStorage.getItem("workspace")}.backend.${import.meta.env.VITE_HOSTNAME}`
        );
        await this.$store.dispatch("validateEmailToken", token);
      } catch {
        this.$router.push("/login");
      }
    },
    areAllHelpsActive() {
      return this.helps.every((help) => help.active);
    },
  },
});
</script>

<style lang="sass" scoped>
.help
  margin: 0.75rem 0

  &__item
    padding: 0.325rem 0

  &__icon
    margin-right: 0.5rem

    &--initial
      color: $gray-4

    &--success
      color: #52C41A

    &--error
      color: $red-6

.container
  width: 32%
  margin-top: 30px
  margin-left: 11%

.drawing
  margin-top: -10px
  width: 50%
  text-align: center

.drawing__img
  height: 380px

.button
  width: 100%

.container__info
  text-align: center
  margin-top: 100px
  width: 54%

.name
  font-weight: 600
  font-size: 30px

.subtitle
  font-size: 20px
  width: 50%
  margin: 0 auto
  padding-top: 10px

.flex
  display: flex
  justify-content: space-between

.form__footer
  margin-top: 32px
  text-align: center
</style>
