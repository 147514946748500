<template>
  <a-tooltip>
    <template #title> Ver contactos asociados </template>
    <a-button type="text" class="button button__info" @click="visible = true">
      <IconContacts class="icon" />
    </a-button>
  </a-tooltip>

  <a-drawer
    v-model:visible="visible"
    class="assign-drawer"
    placement="right"
    :closable="false"
    :width="350"
  >
    <template #title>
      <header>
        <span>Contactos asociados</span>

        <a-button type="text" class="button button__close" @click="visible = false">
          <CloseOutlined class="icon" />
        </a-button>
      </header>
    </template>
    <div>
      <h3>Contacto asignado</h3>
      <p v-if="thread?.client">El historial de este correo quedará registrado en este contacto</p>
      <p v-else>
        Elige qué correo asignar a un contacto. La asignación te permitirá guardar el historial en
        el contacto.
      </p>
      <ContactCard
        v-if="thread?.client"
        :contact="{
          id: thread?.client?.id,
          name: thread?.client?.fullname,
          email: thread?.client?.email_assigned,
          imageSrc: thread?.client?.profile_picture,
        }"
        :currentMessage="currentMessage"
        :hasClient="thread?.client"
      />
      <div v-if="associatedTo?.length > 0">
        <h3>Otros correos en “Para”:</h3>
        <div class="card" v-for="contact in associatedTo" :key="contact.id">
          <ContactCard
            :contact="contact"
            :currentMessage="currentMessage"
            :hasClient="thread?.client"
          />
        </div>
      </div>
      <div v-if="associatedCC?.length > 0">
        <h3>Otros correos en “CC:”</h3>
        <div class="card" v-for="contact in associatedCC" :key="contact.id">
          <ContactCard
            :contact="contact"
            :currentMessage="currentMessage"
            :hasClient="thread?.client"
          />
        </div>
      </div>
      <!-- <div class="card">
        <h3>Otros correos en: "Para":</h3>
        <ContactCard
          v-for="contact in contacts.recipients"
          :key="contact.email"
          :contact="contact"
        />
      </div>
      <div class="card">
        <h3>Otros correos en: "CC":</h3>
        <ContactCard v-for="contact in contacts.cc" :key="contact.email" :contact="contact" />
      </div> -->
    </div>
    <template #footer>
      <footer>
        <a-button @click="visible = false">Cerrar</a-button>
      </footer>
    </template>
  </a-drawer>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import IconContacts from "@/app/common/components/atoms/IconContactBlack.vue";
import ContactCard from "@/app/emails/components/cards/ContactCard.vue";
import { EmailDetails, Thread } from "@/@types/emails";

const props = defineProps<{
  isDisabled?: boolean;
  currentMessage?: EmailDetails;
  thread?: Thread;
}>();

const visible = ref(false);

const associatedTo = computed(() => {
  if (props.thread?.associatedClients)
    return props.thread?.associatedClients.filter((c: any) => {
      if (c.type == "to_emails") {
        return {
          id: c.client_id,
          name: c.alias,
          email: c.email,
          imageSrc: c.profile_picture,
          type: c.type,
        };
      }
    });
  else return [];
});

const associatedCC = computed(() => {
  if (props.thread?.associatedClients)
    return props.thread?.associatedClients.filter((c: any) => {
      if (c.type == "cc_emails") {
        return {
          id: c.client_id,
          name: c.alias,
          email: c.email,
          imageSrc: c.profile_picture,
          type: c.type,
        };
      }
    });
  else return [];
});
// const contacts = {
//   id: 1,
//   name: "Camilo Fernandez Tank",
//   email: "<camilo@rimac.seguros.com>",
//   recipients: [
//     {
//       name: "Camilo Fernandez Tank",
//       email: "<camilo@rimac.seguros.com>",
//     },
//     {
//       name: "Camilo Fernandez Tank",
//       email: "<camilo@rimac.seguros.com>",
//     },
//   ],
//   cc: [
//     {
//       name: "Camilo Fernandez Tank",
//       email: "<camilo@rimac.seguros.com>",
//     },
//     {
//       name: "No identificado",
//       email: "<camilo@rimac.seguros.com>",
//     },
//   ],
// };
</script>

<style scoped lang="sass">
.card
  padding: 10px 0
  margin-bottom: 0
h3
  margin-bottom: 10px
.button
  padding: 0
  width: 100%

  &__info
    height: 33px

  &__close
    width: 32px

.icon
  font-size: 16px

header
  display: flex
  justify-content: space-between
  align-items: center

footer
  text-align: right
</style>

<style>
.info-drawer .ant-drawer-header,
.info-drawer .ant-drawer-body {
  padding: 10px 20px !important;
}
</style>
