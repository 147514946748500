<template>
  <div class="column" :class="{ floating: isPhoneFloating }">
    <web-phone-avatar :name="name" :photo="photo" :active="active" :floating="isPhoneFloating" />
    <!-- <span class="title">{{ name }} </span> -->
    <div class="title__container">
      <a-typography-text class="title" :ellipsis="{ tooltip: name }" :content="name" />
    </div>

    <span class="subtitle"> {{ number }} </span>
    <web-phone-elapsed-time :floating="isPhoneFloating" />
    <!-- <span v-else class="status">00:00</span> -->
    <span class="status" :class="{ 'status-floating': isPhoneFloating }">
      <span v-if="isPhoneFloating && isOverlayOn"> {{ name }} |</span> {{ status }}
    </span>
    <!-- <workspace-acw-card v-if="!isPhoneFloating" /> -->
  </div>
</template>

<script>
import WebPhoneElapsedTime from "./WebPhoneElapsedTime.vue";
import WebPhoneAvatar from "../WebPhoneAvatar.vue";
import { statuses } from "../../common/statuses";
import EventBus from "@/app/shared/utils/eventBus";
// import WorkspaceAcwCard from "@/app/workspace/components/cards/WorkspaceAcwCard";
import { defineComponent, inject } from "vue";
import { CallStatus } from "@/packages/vue-jssip/jssip/phone";

import { WebPhoneState } from "@/packages/webphone/utils/enums";

export default defineComponent({
  components: {
    WebPhoneAvatar,
    // WorkspaceAcwCard,
    WebPhoneElapsedTime,
  },
  props: {
    receiver: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
    isOverlayOn: { type: Boolean, default: false },
  },
  setup() {
    const flotante = inject("floating");
    const currentWebPhoneState = inject("currentWebPhoneState");

    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    return {
      currentWebPhoneState,
      flotante,
      WebPhoneState,
      ticket,
      channel,
    };
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    isPhoneFloating() {
      return this.currentWebPhoneState === WebPhoneState.expanded;
    },
    phoneMetadata() {
      return this.$jssip.phone.meta;
    },
    isCurrentTicket() {
      const ticketId = this.$route.query?.ticket;
      const interactionId = this.$route.query?.interaction;
      // const { ticket, channel_person } = this.$jssip.phone?.meta;
      // return ticketId == ticket && channelId == channel_person;
      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
    number() {
      return this.receiver.number;
    },
    name() {
      return this.ticket?.client?.name || this.receiver?.name || "No identificado";
    },
    photo() {
      if (this.isCurrentTicket || this.flotante) {
        return this.receiver?.photo;
      }
      return this.ticket?.client?.profile_picture;
    },
    status() {
      if ((this.isCurrentTicket && this.$jssip.phone.status != "none") || this.flotante) {
        return statuses[this.$jssip.phone.status];
      }

      return "Finalizada";
    },
    isCallEnded() {
      return this.$jssip.phone.status === "finished" || this.$jssip.phone.status === "failed";
    },
    isInACall() {
      return (
        this.$jssip.phone.status == CallStatus.InCall ||
        this.$jssip.phone.status == CallStatus.Connecting ||
        this.$jssip.phone.status == CallStatus.Ringing
      );
    },
  },
  mounted() {
    EventBus.on("MIC_ON", () => {
      this.handleEnter();
    });
    EventBus.on("MIC_OFF", () => {
      this.handleLeave();
    });
  },
  methods: {
    handleEnter() {
      if (this.readonly) return;
      this.active = true;
    },
    handleLeave() {
      if (this.readonly) return;
      this.active = false;
    },
  },
});
</script>

<style lang="sass" scoped>
@import '../../styles/main'

.column
  color: $black
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  gap: 4px
  text-align: center

.floating
  margin: 0

.title
  font-size: 16px
  font-weight: 600
  margin-top: 10px

  &__container
    max-width: 90%

.subtitle
  font-size: 14px

.status
  &-floating
    position: absolute
    top: 40px
    z-index: 2
</style>
