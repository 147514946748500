export const agentsTableColumns = [
  {
    title: "Nombres",
    dataIndex: "fullname",
    slots: { customRender: "fullname" },
    width: 150,
  },
  {
    title: "Estado",
    dataIndex: "fullname",
    slots: { customRender: "customState" },
    width: 120,
  },
  {
    title: "Eventos de llamadas",
    dataIndex: "event_management",
    slots: { customRender: "event_management" },
    width: 140,
  },
  {
    title: "Eventos de mensajería",
    dataIndex: "event_management_messaging",
    slots: { customRender: "event_management_messaging" },
    width: 140,
  },
  {
    title: "Acciones",
    width: 120,
    slots: { customRender: "actions" },
  },
];

export const callOnHoldTableColumns = [
  {
    title: "Teléfono",
    dataIndex: "phone",
    key: "phone",
    customRender: ({ text, record }) => {
      const code_country = record.code_country || "";
      return `${code_country} ${text}`;
    },
  },
  {
    title: "Tiempo",
    dataIndex: "time",
    key: "time",
  },
];

export const chatOnHoldTableColumns = [
  {
    title: "Canal",
    dataIndex: "channel",
    key: "channel",
    slots: { customRender: "channel" },
    width: 50,
  },
  {
    title: "Nombre",
    dataIndex: "client",
    slots: { customRender: "client" },
    width: 120,
  },
  {
    title: "Tiempo",
    dataIndex: "time",
    width: 100,
  },
];
