<template>
  <div class="information">
    <div>
      <span class="input">
        Activado

        <a-switch :checked="checked" @update:checked="handleChange" :loading="loading" />
      </span>

      <p class="details">
        Personaliza la visibilidad de tu ambiente de trabajo y decide si deseas aparecer en el
        buscador de inicio de sesión público. Recuerda que, también puedes acceder directamente a tu
        ambiente de trabajo mediante la URL personalizada.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, createVNode, inject } from "vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";

import { CustomerKey } from "@/@types/settingsMenu/symbols";
import { customerService } from "@/app/settingsMenu/services/CustomerService";
import { ResponseError } from "@/app/shared/utils/httpManager";
import { VisibilityModalText } from "@/app/settingsMenu/utils/texts";

const customerData = inject(CustomerKey);

const checked = ref(customerData?.value?.is_visible);

const loading = ref(false);

const handleChange = async (isVisible: boolean) => {
  const modalText = VisibilityModalText[isVisible ? "toActive" : "toInactive"];

  Modal.confirm({
    centered: true,
    icon: createVNode(QuestionCircleOutlined),
    title: modalText.title,
    content: modalText.content,
    okText: modalText.button,
    onOk: async () => {
      loading.value = true;

      try {
        await customerService.updateWorkspaceVisibility(isVisible);
        checked.value = isVisible;
      } catch (error) {
        const messageError =
          error instanceof ResponseError ? error.getErrorMessage() : "Ha ocurrido un error";

        message.error(messageError);
      }

      loading.value = false;
    },
  });
};
</script>

<style lang="sass" scoped>
.text__strong
  font-weight: 600
  font-size: 16px

.flex
  display: flex

.input
  display: flex
  align-items: center
  gap: 40px
  margin-bottom: 12px

.details
  margin-bottom: 0

.disabled
  opacity: 0.30
</style>

<style></style>
