<template>
  <div class="dialer" :style="getStyles">
    <header>
      <div class="dialer__time">
        <span class="elapsed__time" v-if="isDefaultModeCall && elapsedTime">
          {{ hour(elapsedTime) }}
        </span>
      </div>

      <div class="dialer__move">
        <img draggable="false" class="dialer__mover-img" alt="move dots" :src="moveDotsImage" />
      </div>

      <div class="dialer__minimize" @click="$emit('click:minimize')">
        <img class="dialer__minimize-img" alt="minimize icon" :src="minimizeImage" />
      </div>
    </header>

    <web-phone :mode="'expanded'" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { secondsToHHMMSS } from "@/packages/webphone/utils/time";
import { CallMode } from "@/packages/vue-jssip/jssip/phone";

import WebPhone from "../../../core/WebPhone.vue";
import moveDotsImage from "../../../../assets/move.svg?url";
import minimizeImage from "../../../../assets/minus.svg?url";

export default defineComponent({
  components: {
    WebPhone,
  },
  inject: {
    height: { default: "height" },
  },
  computed: {
    elapsedTime() {
      return this.$jssip.phone.time.elapsedTime;
    },
    isDefaultModeCall() {
      return this.$jssip.phone.mode === CallMode.Default;
    },
    isInACall() {
      return (
        this.$jssip.phone.status !== "none" &&
        this.$jssip.phone.status !== "finished" &&
        this.$jssip.phone.status !== "failed"
      );
    },
    getStyles() {
      return {
        minHeight: `${this.height}px`,
      };
    },
  },
  methods: {
    hour(seconds) {
      return secondsToHHMMSS(seconds);
    },
  },
  setup() {
    return {
      moveDotsImage,
      minimizeImage,
    };
  },
});
</script>

<style lang="sass" scoped>
@import '../../../../styles/main'

.dialer
  position: relative

  header
    position: absolute
    left: 0
    right: 0
    display: flex
    justify-content: space-between
    padding: 5px 12px 0
    z-index: 10

  &__minimize,
  &__close,
  &__move,
  &__time
    width: 40px
    display: grid
    place-content: center

  &__time
    font-size: 12px

  &__minimize
    cursor: pointer

  &__move
    cursor: move
</style>
