<template>
  <section>
    <div class="flex">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        class="table__typifications"
        :loading="loading"
        @change="handleTableChange"
      >
        <template #submotive_name="{ record }">
          <span>{{ record.submotive_name || "--" }}</span>
        </template>
        <template #customDate>
          Fecha y hora
          <a-tooltip>
            <template #title> Ordenar </template>
            <SwapOutlined :rotate="90" />
          </a-tooltip>
        </template>

        <template #identifierTitle>
          Identificador

          <a-tooltip>
            <template #title> Teléfono o cuenta asociada a tu empresa. </template>

            <InfoCircleOutlined />
          </a-tooltip>
        </template>

        <template #created_at="{ text }">
          {{ dateTimeToSlashFormat(text, true) }}
        </template>

        <template #channel="{ text: iconSrc }">
          <img v-if="iconSrc" class="channel-icon" :src="iconSrc" alt="channel icon" />
          <span v-else> - </span>
        </template>

        <template #identifier="{ text }">
          {{ text || "-" }}
        </template>

        <template #phone="{ text }">
          {{ text || "-" }}
        </template>
      </a-table>

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getTypifications?.length > 2"
          >ver más ({{ getTypifications?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
</template>

<script>
// TODO-JOHN: Refactor, remover drawer de la tabla
import { columns } from "@/app/contacts/components/tables/typifications/ContactTypificationsTableColumns";
import { SwapOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";

import { dateTimeToSlashFormat } from "@/app/shared/utils/dates";

export default defineComponent({
  components: {
    SwapOutlined,
    InfoCircleOutlined,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      isAddPhonesVisible: false,
      position: 0,
      dataTable: [],
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      openTable: false,
      dateTimeToSlashFormat,
    };
  },
  computed: {
    typifications() {
      return this.$store.getters["getTypificationsClient"].items;
    },
    getTypifications() {
      return this.$store.getters["getTypificationsClient"].items;
    },
    filterTypifications() {
      return this.$store.getters["getFiltersTypifications"];
    },
    filterGroup() {
      return this.filterTypifications.groups?.map((group) => {
        return {
          text: group.name,
          value: group.name,
        };
      });
    },
    filterResult() {
      return this.filterTypifications.results?.map((result) => {
        return {
          text: result.name,
          value: result.name,
        };
      });
    },

    columnsTable() {
      const items = [...this.columns];
      items.forEach((column) => {
        if (column.dataIndex === "result_name") {
          column.filters = this.filterResult;
        } else if (column.dataIndex === "group_name") {
          column.filters = this.filterGroup;
        }
      });
      return items;
    },
  },
  watch: {
    typifications() {
      this.dataTable = this.getTypifications;
    },
  },
  created() {
    this.$store.dispatch("fetchTypificationsClient", this.itemId);
    this.$store.dispatch("filtersTypifications", this.itemId);
  },
  methods: {
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getTypifications.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      this.openTable = false;
    },
    handleTableChange(_, filters) {
      console.log("params", _, filters);
    },
  },
});
</script>

<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px

.actions
  display: flex

.phone
  cursor: pointer

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 36px

:deep(.ant-pagination)
  display: none !important

.flex
  position: relative

.table__typifications
  margin-right: 20px

.channel-icon
  width: 20px
</style>
