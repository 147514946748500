<template>
  <!-- :getPopupContainer="(triggerNode) => triggerNode.parentNode" -->
  <a-select
    v-model:value="code_phone"
    show-search
    :filter-option="false"
    :not-found-content="null"
    placeholder="Código del pais"
    @search="filterCountries"
    @dropdownVisibleChange="handleChangeDropdownVisible"
    @select="selectCountry"
    @change="selectCountry"
    :disabled="disabled"
    :allowClear="allowClear"
    :loading="loading"
    class="selector"
    :class="{ webphoneSelector: webphone }"
  >
    <a-select-option v-if="hasMixOption" :value="''">
      <img src="../../assets/world-wide-web.svg" alt="word wide web" />
      Mixto (múltiples países)
    </a-select-option>

    <a-select-option v-if="showAll" :value="''"> Todos los paises </a-select-option>

    <a-select-option v-for="country in copyCountries" :key="country.id" :title="country.name">
      <div
        v-show="webphone ? true : !showCountry"
        :style="{ color: webphone ? '#0F0F0F' : '#bfbfbf' }"
        class="country"
      >
        <img class="flag" :src="country.url_flag" alt="flag" />
        <span v-if="showName">{{ country.name + " " }}</span>
        <span>+{{ country.code_phone }}</span>
      </div>
      <div v-show="webphone ? false : showCountry" :style="{ color: '' }" class="country">
        <img class="flag" :src="country.url_flag" alt="flag" />
        <span>{{ country.name + " " }}</span>
        <span>+{{ country.code_phone }}</span>
      </div>
    </a-select-option>
  </a-select>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    code: {
      type: Number,
      default: undefined,
    },
    hasMixOption: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    webphone: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const showCountry = ref(false);
    const store = useStore();
    const countries = computed(() => store.getters["getAllCountries"]);
    const getDataUser = computed(() => store.getters["getDataUser"]);
    const code_phone = ref(props.code ?? getDataUser.value?.customer?.country ?? 1);
    const loading = ref(false);
    const copyCountries = ref([]);

    watch(countries, (newValue) => {
      copyCountries.value = newValue;
      
      const code_phone = props.code ?? getDataUser.value?.customer?.country ?? 1;
      if (!props.code)
        emit("selected", code_phone);
      if (!code_phone) return;

      emit("codePhone", newValue.find((c) => c.id === code_phone)?.code_phone);
    });

    watch(
      () => props.code,
      (newValue) => {
        if (newValue)
          code_phone.value = newValue;
      }
    );

    return {
      code_phone,
      countries,
      copyCountries,
      showCountry,
      getDataUser,
      loading,
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("fetchAllCountries");
    this.loading = false;
  },
  methods: {
    filterCountries(value) {
      const searchValue = value.toLowerCase();
      this.copyCountries = this.countries.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchValue) || item.code_phone.startsWith(searchValue)
        );
      });
    },
    handleChangeDropdownVisible(data) {
      this.showCountry = data;
      this.copyCountries = this.countries;
    },
    selectCountry(data) {
      this.$emit("selected", data);
      const country = this.copyCountries.find((c) => c.id === data);
      this.$emit("codePhone", country?.code_phone || 0);
    },
  },
});
</script>

<style lang="sass" scoped>
.flag
  width: 14px
  margin-bottom: 2px
  margin-right: 4px

.country
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

:global(.webphoneSelector .ant-select-selector .ant-select-selection-item)
  padding-inline-end: 0px !important

:global(.webphoneSelector .ant-select-selector)
  background-color: transparent !important
  border-color: #ADC6FF !important

.selector
  min-width: 95px
</style>
