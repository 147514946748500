<template>
  <SheduleTemplate />
</template>

<script>
import SheduleTemplate from "@/app/schedule/components/template/SheduleTemplate.vue";
export default {
  components: {
    SheduleTemplate,
  },
};
</script>
