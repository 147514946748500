import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo referido
 * @param {number} ticketId Identificador del ticket.
 * @param {Object} contact Datos de direccion.
 *
 * @return {Promise<Object>} Datos de la direccion creada
 */
export async function addContactClient(ticketId, contact) {
  const url = `/workspace/tickets/${ticketId}/contacts/`;
  const response = await httpManager.post(url, contact);
  return response.data;
}
