<template>
  <footer class="actions-floating__footer">
    <a-tooltip v-if="tooltipText" :title="tooltipText">
      <div class="anchor" />
    </a-tooltip>

    <a-divider class="divider" />

    <!--Colgar-->

    <IconButton
      large
      class="icon-hangup"
      :disabled="isConnecting || primaryDisabled"
      :type="buttonType"
      :icon="buttonType"
      :active="!isConnecting && !primaryDisabled"
      :stopPropagationEvent="stopPropagationEvent"
      @click="$emit('click:primary', buttonType)"
    />

    <div class="text-buttons" v-if="!inWorkspace">
      <a-button type="text" class="text-button" @click="$emit('click:history')" disabled>
        Ver historial
      </a-button>

      <a-button
        type="text"
        class="text-button"
        @click="handleGoToTicket"
        :disabled="!webphoneHasTicket"
      >
        Ir al ticket
      </a-button>
    </div>
  </footer>
</template>

<script setup>
import { inject, computed } from "vue";
import { useRouter } from "vue-router";

import { CallStatus } from "@/packages/vue-jssip/jssip/phone";

import IconButton from "../buttons/IconButton.vue";

defineProps({
  buttonType: {
    type: String,
    default: "hold",
  },
  inWorkspace: {
    type: Boolean,
    default: false,
  },
  primaryDisabled: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: null,
  },
  stopPropagationEvent: {
    type: Boolean,
    default: false,
  },
});

const $jssip = inject("$jssip");
const router = useRouter();

const isConnecting = computed(() => $jssip.phone.status == CallStatus.Connecting);
const webphoneHasTicket = computed(() => Boolean($jssip.phone?.meta?.ticket));

const handleGoToTicket = () => {
  const ticketId = $jssip.phone?.meta?.ticket;
  const interactionId = $jssip.phone?.meta?.interaction;
  const clientId = $jssip.phone?.meta?.client;

  router.push({
    path: "/workspace",
    query: { ticket: ticketId, interaction: interactionId, client: clientId },
  });
};
</script>

<style lang="sass" scoped>
.anchor
  width: 100%
  height: 48px
  transform: translateY(30px)
  position: relative
  z-index: 3

.actions-floating
  &__footer
    position: relative

    .icon-hangup
      position: absolute
      transform: translateY(-40%)
      left: 0
      right: 0
      margin: auto
      min-height: 0
      height: 2px
      z-index: 2

    .text-button
      height: 50px
      width: 50%

.divider
  margin: 0
  background-color: rgba(0, 21, 41, 0.2)
</style>
