export enum ProductType {
  WhatsApp = 1,
  Voice = 2,
  Recharge = 3,
  Slot = 4,
  Summary = 5,
  Sms = 6,
}

export enum BillingType {
  Prepaid = 1,
  Postpaid = 2,
}
