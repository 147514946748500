<template>
  <div class="viewer">
    <a-row class="viewer__header" type="flex" justify="space-between">
      <a-col class="title"> {{ campaign.name }} </a-col>
      <a-col>
        <historical-report-modal :campaign="campaign" />
      </a-col>
    </a-row>

    <div class="viewer__stats">
      <div v-for="stat of stats" :key="stat.text">
        <span>
          {{ stat.text }}: <strong> {{ stat.value }} </strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import HistoricalReportModal from "../modal/HistoricalReportModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    HistoricalReportModal,
  },
  props: {
    campaign: { required: true, type: Object },
  },
  computed: {
    stats() {
      return [
        {
          text: "Usuarios disponibles",
          value: this.campaign.total_conected_agents,
        },
        {
          text: "Total de llamadas",
          value: this.campaign.total_calls || 0,
        },
        {
          text: "Llamadas en espera",
          value: this.campaign.total_calls_waiting,
        },
        {
          text: "Llamadas abandonadas",
          value: this.campaign.total_dropped_calls,
        },
        {
          text: "Total de chats",
          value: this.campaign.total_chats || 0,
        },
        {
          text: "Chats en espera",
          value: this.campaign.total_chats_waiting,
        },
      ];
    },
  },
});
</script>

<style lang="sass" scoped>
.viewer
  &__header
    padding: 8px 12px
    background-color: $blue-1


  &__stats
    color: $gray-8
    padding: 8px 12px
    display: flex
    justify-content: space-between
    background-color: transparentize($blue-1, 0.5)

    & strong
      font-weight: 600

.title
  font-size: 16px
  font-weight: 600
</style>
