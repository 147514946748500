import {
  fetchPortfolios,
  createNewPortfolio,
  updatePortfolio,
  deletePortfolio,
  fetchPortfolioById,
  fetchDashboardByPortfolio,
  fetchAllPortfolios,
} from "@/app/entities/services";
import {
  SET_PORTFOLIOS,
  SET_SELECTED_PORTFOLIO,
  SET_SELECTED_ENTITY,
  SET_ALL_PORTFOLIOS,
} from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchPortfolioById(idPortfolio) {
    return await fetchPortfolioById(idPortfolio);
  },
  async fetchDashboardByPortfolio(idPortfolio) {
    return await fetchDashboardByPortfolio(idPortfolio);
  },
  async fetchAllPortfoliosByEntity({ commit }, params = {}) {
    const { filters, page } = params;
    const data = await fetchPortfolios(
      filters?.search || "",
      filters?.status || 0,
      filters?.entity || "",
      page
    );
    commit(SET_PORTFOLIOS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async createNewPortfolio(_, portfolio) {
    const response = await createNewPortfolio(portfolio);

    return response;
  },
  async updatePortfolioById(_, { portfolioId, portfolio }) {
    await updatePortfolio(portfolioId, portfolio);
  },
  async deletePortfolioById(_, portfolioId) {
    await deletePortfolio(portfolioId);
  },
  setSelectedEntity({ commit }, entityId) {
    commit(SET_SELECTED_ENTITY, entityId);
  },
  setSelectedPortfolio({ commit }, entityId) {
    commit(SET_SELECTED_PORTFOLIO, entityId);
  },
  async fetchAllPortfolios({ commit }, search) {
    const data = await fetchAllPortfolios(search);

    if (data.length === 0) {
      commit(SET_ALL_PORTFOLIOS, []);
    }
    commit(SET_ALL_PORTFOLIOS, data);
  },
  async clearInputEntity({ commit }) {
    commit(SET_ALL_PORTFOLIOS, []);
  },
};

export default actions;
