import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener listado de agentes habilitados para estrategias.
 *
 * @param {number} campaignId Identificador de campaña.
 *
 * @return {Promise<Array>} Listado de agentes.
 */
export async function fetchAgentsInStrategy() {
  const response = await httpManager.get(`/strategies/agents/active`);
  return response.data;
}
