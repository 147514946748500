<template>
  <div class="section-schedule-container">
    <ScheduleCalendar />
  </div>
</template>
<script>
import ScheduleCalendar from "@/app/schedule/components/calendar/ScheduleCalendar.vue";

export default {
  name: "SectionSchedule",
  components: {
    ScheduleCalendar,
  },
};
</script>
<style lang="sass" scoped>
.section-schedule-container
  background-color: $white
  padding: 0 10px
  height: calc(100% - 24px)
  margin: $margin-small
  border-radius: 10px
  border: 1px solid #D9D9D9
  position: relative
</style>
