<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_319_13721)">
      <path
        d="M8.48495 2.45264C8.41394 4.23832 8.79386 5.55937 9.61717 6.38268C10.4406 7.20607 11.7615 7.58601 13.5473 7.51495L8.48495 2.45264Z"
        fill="black"
        fill-opacity="0.25"
      />
      <path
        d="M13.1389 8.52534C11.2821 8.52541 9.86195 8.04459 8.90858 7.09125C7.81299 5.99566 7.34185 4.28264 7.50706 1.99985C4.42316 2.25567 2 4.83932 2 7.98925C2 11.3088 4.69105 13.9998 8.01059 13.9998C11.1605 13.9998 13.7442 11.5766 14 8.49271C13.7035 8.51416 13.416 8.52534 13.1389 8.52534Z"
        fill="black"
        fill-opacity="0.25"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_13721">
        <rect width="12" height="12" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
</template>
