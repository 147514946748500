<template>
  <div class="item-agente">
    <span v-if="item.fullname.length > 15">
      <a-tooltip>
        <template #title>
          {{ item.fullname }}
        </template>
        {{ item.fullname.substring(0, 13) + "..." }}
      </a-tooltip>
    </span>
    <span v-else>
      {{ item.fullname }}
    </span>
    <div class="item-text">{{ getText(item.state) }}</div>
    <a-badge :color="handleStatusAgent(item.state)" />
  </div>
</template>

<script>
import { StatusAgent } from "@/app/shared/utils/enums";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleStatusAgent(status) {
      if (status === StatusAgent.success) {
        return "green";
      } else if (status === StatusAgent.processing) {
        return "blue";
      } else if (status === StatusAgent.acw) {
        return "volcano";
      } else if (status === StatusAgent.capacitacion) {
        return "pink";
      } else if (status === StatusAgent.servicios) {
        return "yellow";
      } else if (status === StatusAgent.disconnect) {
        return "gray";
      } else return "yellow";
    },
    getText(status) {
      if (status === StatusAgent.success) {
        return "Disponible";
      } else if (status === StatusAgent.processing) {
        return "En llamada";
      } else if (status === StatusAgent.acw) {
        return "ACW";
      } else if (status === StatusAgent.capacitacion) {
        return "En capacitación";
      } else if (status === StatusAgent.servicios) {
        return "En servicios";
      } else if (status === StatusAgent.disconnect) {
        return "Desconectado";
      } else return "Pendiente";
    },
  },
};
</script>

<style lang="sass" scoped>
.item-agente
  margin-left: 1px
  display: flex
  justify-content: space-between
  gap: 6px
  span:first-child
    width: 140px
.item-text
  text-align: left
</style>
