<template>
  <div>
    <a-dropdown placement="bottomRight" :trigger="['click']" @click="handleTransferClick">
      <a-tooltip title="Transferir ticket">
        <a-button type="text" :disabled="!portfolio" class="button-transfer" @click.prevent>
          <SwapOutlined class="icon" />
          Transferir ticket
        </a-button>
      </a-tooltip>

      <template #overlay>
        <a-menu @click="handleDropdownClick" :style="{ width: '220px !important' }">
          <a-menu-item key="queues-users">
            <a-popover placement="right" :overlayClassName="'popover-option'">
              <template #content>
                <span>Transfiere el ticket a un usuario de esta</span><br />
                <span>u otra cola de la misma campaña</span>
              </template>
              <template #title>
                <span>Transferir por cola y usuario</span>
              </template>
              <span>Transferir por cola y usuario</span>
            </a-popover>
          </a-menu-item>
          <a-menu-item key="users" :disabled="!queue">
            <a-popover
              placement="right"
              title="Transferir a un usuario"
              :overlayClassName="'popover-option'"
            >
              <template #content>
                <span>Transfiere el ticket a otra usuario de la</span><br />
                <span>misma campaña</span>
              </template>
              <template #title>
                <span>Transferir a un usuario</span>
              </template>
              <span>Transferir a un usuario</span>
            </a-popover>
          </a-menu-item>
          <a-menu-item key="queues">
            <a-popover
              placement="right"
              title="Transferir a cola"
              :overlayClassName="'popover-option'"
            >
              <template #content>
                <span>Transfiere el ticket a otra cola de la</span><br />
                <span>misma campaña</span>
              </template>
              <template #title>
                <span>Transferir a cola</span>
              </template>
              <span>Transferir a cola</span>
            </a-popover>
          </a-menu-item>
          <TypificationModal
            ref="refTypificationModalUser"
            transferUser
            class="button button-typification"
            :defaultFormState="formStateTypification"
            :isChat="isChat"
            @handleNext="nextTransfer"
          />
          <TypificationModal
            ref="refTypificationModalQueues"
            transferQueue
            class="button button-typification"
            :defaultFormState="formStateTypification"
            :isChat="isChat"
            @handleNext="nextTransfer"
          />
        </a-menu>
      </template>
    </a-dropdown>

    <QueueUserTransferModal
      v-if="openQueueUserTransferModal"
      v-model:open="openQueueUserTransferModal"
      :origin="1"
      :queues="transferData.queues"
      :ticketIdList="[ticket.id]"
      :portfolio="ticket?.portfolio"
      :entity="ticket?.portfolio?.entity"
      :defaultSelected="queueIdSelected"
      :inCall="inCall"
      @update="handleTransferredTicket"
      @transferCall="transferCall"
    />

    <QueueTransferModal
      v-if="openQueueTransferModal"
      v-model:open="openQueueTransferModal"
      :origin="1"
      :queues="transferData.queues"
      :ticketIdList="[ticket.id]"
      :portfolio="ticket?.portfolio"
      :entity="ticket?.portfolio?.entity"
      :fromTypification="fromTypification"
      :defaultSelected="queueIdSelected"
      :inCall="inCall"
      @update="handleTransferredTicket"
      @handleBack="backTypification"
      @transferCall="transferCall"
    />

    <UserTransferModal
      v-if="openUserTransferModal"
      v-model:open="openUserTransferModal"
      :origin="1"
      :users="transferData.agents"
      :queue="queue"
      :ticketIdList="[ticket.id]"
      :fromTypification="fromTypification"
      :defaultSelected="userIdSelected"
      :isLoading="isLoadingUsers"
      @update="handleTransferredTicket"
      @handleBack="backTypification"
      @transferCall="transferCall"
    />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { SwapOutlined } from "@ant-design/icons-vue";
import { computed, ref, toRaw, onMounted, watch } from "vue";
import { useJssip } from "@/app/shared/composables/useJssip";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

import QueueUserTransferModal from "@/app/ticketsManager/components/modals/transfer/QueueUserTransferModal.vue";
import QueueTransferModal from "@/app/ticketsManager/components/modals/transfer/QueueTransferModal.vue";
import UserTransferModal from "@/app/ticketsManager/components/modals/transfer/UserTransferModal.vue";
import TypificationModal from "@/app/workspace/components/modal/TypificationModal.vue";
import { getChannelCustomerIdentifier } from "@/app/shared/utils/helpers";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { saveManagement } from "@/app/workspace/services";
import { ticketService } from "@/app/workspace/services/TicketService";

const props = defineProps({
  ticket: { type: Object, required: true },
  isWorkspace: { type: Boolean, required: false, default: false },
  isChat: { type: Boolean, required: false, default: false },
  inCall: { type: Boolean, required: false },
});

const $jssip = useJssip();
const store = useStore();
const route = useRoute();
const router = useRouter();

const interval = ref(null);
const isLoadingUsers = ref(false);

const refTypificationModalUser = ref(null);
const refTypificationModalQueues = ref(null);

const transferData = computed(() => store.getters["getTransferData"]);

const portfolio = computed(() => props.ticket.portfolio?.id);

const queue = computed(() => props.ticket.queue?.name);

const usersMonitoring = computed(() => store.getters["getMonitorUserList"]);

const openQueueUserTransferModal = ref(false);
const openQueueTransferModal = ref(false);
const openUserTransferModal = ref(false);

const emit = defineEmits(["modalIsOpen"]);

const fromTypification = ref(false);

const handleTransferClick = async () => {
  // await store.dispatch("fetchTransferData", { ticket: props.ticket.id, origin: 1 });
};

const handleCreateReminder = async () => {
  const formData = toRaw(formStateTypification.value.reminderRef.formState);

  const data = {
    title: formData.title,
    date_schedule: formData.date_schedule,
    time_schedule: formData.date_schedule + " " + formData.time_schedule,
    description: formData.description || "-",
    client: Number(route.query.client),
    ticket: Number(route.query.ticket),
    interaction: Number(route.query.interaction),
  };

  try {
    await store.dispatch("addReminder", data);
    message.success("Se creó el recordatorio correctamente");
  } catch (error) {
    message.error("Hubo un error al crear el recordatorio");
  }
};

const saveTypificationManagement = async () => {
  if (formStateTypification.value.hasReminder) await handleCreateReminder();

  const typification = formStateTypification.value;
  const ticket = Number(route.query.ticket);
  if (!typification.channel) return;

  const management = {
    phone_number: typification.channel?.channel?.id == 1 ? typification.channel?.identifier : null,
    identifier: typification.channel?.identifier,
    channel_customer_identifier: getChannelCustomerIdentifier({
      channel: typification.channel?.channel,
      channelCustomer: typification.channel?.channel_customer,
    }),
    channel_customer: typification.channel?.channel_customer?.id,
    asterisk_id: typification.channel?.asterisk_id ?? undefined,
    strategy_phone: typification.$jssip.phone.meta.strategyPhone,
    ticket,
    interaction_id: route.query.interaction,
    type_interaction: typification.channel?.type_interaction,
    type_service: typification.type_service,
    group_pallete: typification.formState.group_pallete,
    result_pallete: typification.formState.result_pallete,
    motive_pallete: typification.formState.motive_pallete,
    submotive_pallete: typification.formState.submotive_pallete,
    comment: typification.formState.comment,
    form: typification.formFields,
    strategy: typification.isStrategyOn,
    ivr: typification.$jssip.phone.meta.ivr?.ivr?.id,
    summary: typification.conclusion,
  };

  await saveManagement(management);

  ticketService.updateTicket(ticket, { enabled_to_transfer: true });

  message.success("Se ha guardado la tipificación correctamente");
};

const handleTransferredTicket = async () => {
  if (fromTypification.value) {
    await saveTypificationManagement();
  }
  store.dispatch("fetchActiveWorkspaceTickets");
  EventBus.$emit(WorkspaceEvent.RESET_LIST);
  router.push("/workspace");
};

const formStateTypification = ref(null);
const userIdSelected = ref(null);
const queueIdSelected = ref(null);

const nextTransfer = ({ key, typificationRef, formState, fromTypificationParam = true }) => {
  console.log(formState, "nextTransfer");
  typificationRef.value = typificationRef;
  console.log(typificationRef.value, "typificationRef set");
  formStateTypification.value = { ...formState };
  handleDropdownClick({ key: key, fromTypificationParam: fromTypificationParam });
};

const backTypification = async ({ key, selected }) => {
  if (key == "users") {
    await refTypificationModalUser.value?.handleShowModal();
    await refTypificationModalUser.value?.fetchFormFields();
    userIdSelected.value = selected;
  } else if (key == "queues") {
    await refTypificationModalQueues.value?.handleShowModal();
    await refTypificationModalQueues.value?.fetchFormFields();
    queueIdSelected.value = selected;
  }
};

const transferCall = async ({ itemId, type, queueId, userId }) => {
  if (props.inCall) {
    console.log("TRANSFERIR CALL", itemId, type);

    let annex = "";

    // transferencia a agente o transferencia a cola y agente seleccionado
    if (type === "agent" || (type === "queue-user" && itemId)) {
      const item = usersMonitoring.value.find((el) => el.user.id == itemId);
      annex = `r${item.annex.split("@")[0]}`;
    }

    // transferencia a cola
    if (type === "queue") {
      annex = `q${itemId}`;
    }

    // transferencia a cola y agente aleatorio
    if (type === "queue-user" && !itemId) {
      annex = `q${queueId};`;
    }

    try {
      $jssip.transfer(annex, type === "agent" || type === "queue-user" ? itemId : undefined);
      $jssip.end();
      $jssip.setCallStatus(false);
      $jssip.reset();
      EventBus.$emit(WorkspaceEvent.TRANSFER_CALL);
    } catch (error) {
      message.error("Ocurrió un error al transferir la llamada");
      console.log(error);
    }
  }
};

const fetchTransferData = async (onlyUsers = false) => {
  await store.dispatch("fetchTransferData", {
    ticket: props.ticket.id,
    origin: 1,
    onlyUsers: onlyUsers,
  });
};

watch(
  openUserTransferModal,
  async (value) => {
    if (value) {
      isLoadingUsers.value = true;
      await fetchTransferData(true);
      isLoadingUsers.value = false;
      interval.value = setInterval(async () => {
        await fetchTransferData(true);
        isLoadingUsers.value = false;
      }, 7000);
    } else {
      clearInterval(interval.value);
    }
  },
  { immediate: true }
);

const handleDropdownClick = async ({ key, fromTypificationParam = false } = {}) => {
  fromTypification.value = fromTypificationParam;
  if (key === "users") {
    openUserTransferModal.value = true;
    openQueueTransferModal.value = false;
    openQueueUserTransferModal.value = false;
  } else if (key === "queues") {
    openUserTransferModal.value = false;
    openQueueTransferModal.value = true;
    openQueueUserTransferModal.value = false;
  } else if (key === "queues-users") {
    openUserTransferModal.value = false;
    openQueueTransferModal.value = false;
    openQueueUserTransferModal.value = true;
  }

  const isOpen =
    key === "users" ||
    key === "queues" ||
    key === "userstype" ||
    key === "queuestype" ||
    key === "queues-users";

  if (isOpen) {
    emit("modalIsOpen", isOpen);

    setTimeout(() => {
      emit("modalIsOpen", false);
    }, 2000);
  }

  if (
    key === "queues" ||
    key === "queues-users" ||
    (fromTypificationParam && key === "queuestype")
  ) {
    await fetchTransferData();
  }
};

const fetchMonitorUsers = () => {
  store.dispatch("fetchMonitorUsers");
};

onMounted(() => {
  fetchMonitorUsers();
});
</script>

<style lang="sass" scoped>
.button-transfer
  width: 100%
  display: flex
  align-items: center

  .icon
    font-size: 18px

.ant-dropdown-menu
  width: 190px !important
.color-icon
  color: $blue-6
  font-size: $font-medium
.color-icon-disabled
  color: $gray-6
  font-size: $font-medium
  cursor: not-allowed
:global(.ant-popover.popover-option)
  z-index: 1800 !important
</style>
