<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :required="isRequired"
  >
    <ArchivePicker
      v-model:value="file"
      allTypes
      preUpload
      :maxMBSize="3"
      @update:url="handleChange"
      :inputName="input.name"
      :disabled="conditionedAttributes?.disabled"
      :modalKey="modalKey"
    />

    <p class="instruction">{{ input.instruction }}</p>
  </a-form-item>
</template>

<script setup lang="ts">
import { inject, ref, computed } from "vue";
import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";

import ArchivePicker from "@/app/shared/components/molecules/ArchivePicker.vue";

const props = defineProps<{
  input: FormElementMap[ElementType.File];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  modalKey: string;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const file = ref<File | undefined>(undefined);

const workspaceFormState = inject("workspaceFormState") as Record<string, string | undefined>;

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const handleChange = (value: string | undefined) => {
  if (!props.isWorkspace) return;

  workspaceFormState[props.input.name] = value;
};

const initialValue = null;

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px
</style>
