<template>
  <main-container class="main-container">
    <notifications-template />
  </main-container>
</template>

<script>
import MainContainer from "@/app/shared/components/organisms/MainContainer.vue";
import NotificationsTemplate from "../components/templates/NotificationsTemplate.vue";

export default {
  components: {
    MainContainer,
    NotificationsTemplate,
  },
};
</script>

<style lang="sass" scoped>
.main-container
  padding: 16px 20px 24px
  max-height: calc(100% - 24px)
</style>
