<template>
  <div>
    <div>
      <a-row type="flex" justify="space-between">
        <p>Resumen del día</p>
        <a-button type="link" size="small" @click="refresh">Refrescar</a-button>
      </a-row>
      <hr />
    </div>
    <div>
      <p class="title">Tiempos en estados</p>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Connected] }}</p>
        <p>{{ stats.connected_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Refreshment] }}</p>
        <p>{{ stats.snack_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Dinner] }}</p>
        <p>{{ stats.dinner_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Services] }}</p>
        <p>{{ stats.toilet_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Training] }}</p>
        <p>{{ stats.training_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.Occupied] }}</p>
        <p>{{ stats.busy_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>{{ StateTexts[StateOptions.NotAvailable] }} No</p>
        <p>{{ stats.not_available_time }}</p>
      </a-row>
    </div>

    <div>
      <p class="title">Tiempos de eventos en llamada</p>
      <a-row type="flex" justify="space-between">
        <p>En línea</p>
        <p>{{ stats.online_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Esperando</p>
        <p>{{ stats.waiting_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Timbrando</p>
        <p>{{ stats.ringing_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Hablando</p>
        <p>{{ stats.spoken_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>ACW</p>
        <p>{{ stats.acw_time }}</p>
      </a-row>
    </div>
    <div>
      <p class="title">Tiempo de eventos en chats</p>
      <a-row type="flex" justify="space-between">
        <p>Abriendo</p>
        <p>{{ stats.opening_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Escribiendo</p>
        <p>{{ stats.writing_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Tipificando</p>
        <p>{{ stats.typification_time }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Resolviendo</p>
        <p>{{ stats.closing_time }}</p>
      </a-row>
    </div>

    <div>
      <p class="title">Atención de tickets</p>
      <a-row type="flex" justify="space-between">
        <p>Total</p>
        <p>{{ stats.tickets_statistics.total }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Resueltos</p>
        <p>{{ stats.tickets_statistics.total_closed }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Asignados</p>
        <p>{{ stats.tickets_statistics.total_assigned }}</p>
      </a-row>
    </div>

    <div>
      <p class="title">Módulo de Agenda</p>
      <a-row type="flex" justify="space-between">
        <p>Total</p>
        <p>{{ stats.reminder_statistics.total_reminders }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Completados</p>
        <p>{{ stats.reminder_statistics.total_closed }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Pendientes</p>
        <p>{{ stats.reminder_statistics.total_pending }}</p>
      </a-row>
      <a-row type="flex" justify="space-between">
        <p>Vencidos</p>
        <p>{{ stats.reminder_statistics.total_expired }}</p>
      </a-row>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import { StateOptions, StateTexts } from "@/app/shared/utils/enums";

export default defineComponent({
  props: {
    stats: { required: true, type: Object },
  },
  setup() {
    return { StateOptions, StateTexts };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
});
</script>

<style lang="sass" scoped>
.title
  font-weight: 600
p
  font-size: 12px
  line-height: 20px
  color: #262626
  font-weight: 300
</style>
